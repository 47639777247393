import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridColumnsSettingService } from '../ag-grid-columns-setting.service';
import { TabsService } from '../../tabs/tabs.service';
import { DisplayName } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-sidebar-cell',
  templateUrl: './sidebar-cell.component.html',
  styleUrls: ['./sidebar-cell.component.scss']
})
export class SidebarCellComponent implements OnInit,ICellRendererAngularComp {
currentTab = ''
  params!: any;
  enumParams = DisplayName
  constructor(public agGridColumnsSettingService: AgGridColumnsSettingService,public tabService: TabsService ) {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  ngOnInit(): void {
    this.tabService.currentTab.subscribe(res => {
      this.currentTab = res
    })
    if(!this.currentTab){
      this.currentTab = this.tabService.thisTab
    }
  }

  hideShowColumns(event, columnsName) {
    this.agGridColumnsSettingService.sidebarRow.next([{checked:event?.target?.checked,name: columnsName}]);
  }
  shouldDisplayDefault(): boolean {
    if (this.currentTab === 'vendor-list') {
      return this.isVendorListConditionMet();
    } else if (this.currentTab === 'company-recycle-bin' ||this.currentTab === 'companies') {
      return this.isCompanyConditionMet();
    }else if (this.currentTab === 'leads') {
      return this.isLeadConditionMet();
    }else if (this.currentTab === 'prospects') {
      return this.isProspectConditionMet();
    }else if (this.currentTab === 'contact-recycle-bin' ||this.currentTab === 'contacts' || this.currentTab === 'vendor-contact' ) {
      return this.isContactConditionMet();
    } else if (this.currentTab === 'requirements') {
      return this.isRequirementConditionMet();
    } else {
      return this.defaultList();
    }
  }

  defaultList(): boolean {
    return  this.params.value === this.enumParams.STAGE ||
            this.params.value === this.enumParams.Active ||
            this.params.value === this.enumParams.RATING ||
            this.params.value === this.enumParams.TYPE ||
            this.params.value === this.enumParams.PHONE ||
            this.params.value === this.enumParams.DATECONTACTED ||
            this.params.value === this.enumParams.REVENUE ||
            this.params.value === this.enumParams.QUICKNOTE;
  }
  isVendorListConditionMet(): boolean {
    return  this.params.value === this.enumParams.Active ||
            this.params.value === this.enumParams.RATING ||
            this.params.value === this.enumParams.PHONE ||
            this.params.value === this.enumParams.VENDORTYPE ||
            this.params.value === this.enumParams.AVL ||
            this.params.value === this.enumParams.QUICKNOTE;
  }
  isCompanyConditionMet(): boolean {
    return  this.params.value === this.enumParams.STAGE ||
            this.params.value === this.enumParams.Active ||
            this.params.value === this.enumParams.RATING ||
            this.params.value === this.enumParams.TYPE ||
            this.params.value === this.enumParams.PHONE ||
            this.params.value === this.enumParams.DATECONTACTED ||
            this.params.value === this.enumParams.REVENUE ||
            this.params.value === this.enumParams.QUICKNOTES;
  }
  isLeadConditionMet(): boolean {
    return  this.params.value === this.enumParams.STAGE ||
            this.params.value === this.enumParams.Active ||
            this.params.value === this.enumParams.RATING ||
            this.params.value === this.enumParams.TYPE ||
            this.params.value === this.enumParams.PHONE ||
            this.params.value === this.enumParams.LEADSCORE ||
            this.params.value === this.enumParams.DATECONTACTED ||
            this.params.value === this.enumParams.REVENUE ||
            this.params.value === this.enumParams.QUICKNOTE;
  }
  isRequirementConditionMet(): boolean {
    return  this.params.value === this.enumParams.REQUIREMENT ||
    this.params.value === this.enumParams.REQDATE ||
    this.params.value === this.enumParams.BUYTARGET ||
    this.params.value === this.enumParams.TARGETPRICE 

  }
  isProspectConditionMet(): boolean {
    return  this.params.value === this.enumParams.STAGE ||
            this.params.value === this.enumParams.Active ||
            this.params.value === this.enumParams.RATING ||
            this.params.value === this.enumParams.TYPE ||
            this.params.value === this.enumParams.PHONE ||
            this.params.value === this.enumParams.INTENTTOBUY ||
            this.params.value === this.enumParams.DATECONTACTED ||
            this.params.value === this.enumParams.REVENUE ||
            this.params.value === this.enumParams.QUICKNOTE;
  }
  isContactConditionMet(): boolean {
    return  this.params.value === this.enumParams.RATING ||
            this.params.value === this.enumParams.DEPARTMENT ||
            this.params.value === this.enumParams.LOCATION ||
            this.params.value === this.enumParams.TIMEZONE ||
            this.params.value === this.enumParams.TITLE ||
            this.params.value === this.enumParams.OFFICENUMBER ||
            this.params.value === this.enumParams.DIRECTNUMBER ||
            this.params.value === this.enumParams.MOBILENUMBER ||
            this.params.value === this.enumParams.EMAILADDRESS ||
            this.params.value === this.enumParams.DONOTCALL ||
            this.params.value === this.enumParams.DONOTEMAIL ||
            this.params.value === this.enumParams.LASTCONTACT ||
            this.params.value === this.enumParams.COMPANYNAME ||
            this.params.value === this.enumParams.Active ||
            this.params.value === this.enumParams.NEXTTASKDUEDATE ||
            this.params.value === this.enumParams.VENDOR_NAME

            ;
  }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddEditCodeGroupComponent } from '../pop-ups/add-edit-code-group/add-edit-code-group.component';
import { Button, CurrentTab, FooterTitle, Path, PopUpTitles, TabNames, Url } from 'src/app/shared/model/enum';
import { AgGridI, PaginationBaseModel } from 'src/app/shared/components/ag-grid/ag-grid.model';
import { AgGridService } from 'src/app/shared/components/ag-grid/ag-grid.service';
import { AgGridDataModelCodeCrosswalk, AgGridDataModelVendorContacts } from 'src/app/components/crm/crm.model';
import { CodeCrosswalkService } from '../code-crosswalk.service';
import { ToastrService } from 'ngx-toastr';
import { Id } from './code-crosswalk.model';
import { CommonModelPopUpComponent } from 'src/app/shared/components/common-model-pop-up/common-model-pop-up.component';
import { DockService } from 'src/app/shared/components/footer/dock.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CrmService } from 'src/app/components/crm/crm.service';
import { Menu } from 'src/app/shared/services/nav.service';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { Router } from '@angular/router';
import { ImportDocumentsComponent } from 'src/app/components/import-documents/import-documents.component';
import { ImportCodeCrosswalkComponent } from '../pop-ups/import-code-crosswalk/import-code-crosswalk.component';
import { AgGridComponent } from 'src/app/shared/components/ag-grid/ag-grid.component';


@Component({
  selector: 'app-code-crosswalk',
  templateUrl: './code-crosswalk.component.html',
  styleUrls: ['./code-crosswalk.component.scss']
})
export class CodeCrosswalkComponent implements OnInit {
  paginationBaseModel    = new PaginationBaseModel();
  pinned                 :boolean=false;
  idList                 :any;
 // enumPopUpTitles: any;
 @ViewChild(AgGridComponent) agGridComponent: AgGridComponent;
  @Input() footerIcon    : string;
//  @Input() footerTitle   : string;
 // @Input() footerPath    : string;
  enumPopUpTitles        = PopUpTitles;
  tabName = TabNames;
  sicName: string[] = [];
  selectedData: any[] = [];
  ResMsgFromCompanyOrContact: string;
  tabNames = CurrentTab;
  enumButtonName = Button;


  constructor(public modalService             : NgbModal,
              private agGridService           :AgGridService,
              public codeCrosswalkService     :CodeCrosswalkService,
              public toaster                  : ToastrService,
              private dockService             : DockService,
              public crmService               : CrmService,
              public storage                  : StorageService,
              public tabsService              : TabsService,
              private router                  : Router,
          
    ) { }

  enumUrl     = Url;
  footerTitle = FooterTitle
  enumPath    = Path;
  agGridDataModelContacts: AgGridI;

  ngOnInit(): void {
    this.agGridDataModelContacts = AgGridDataModelCodeCrosswalk;
    // this.agGridService.setStage("All");
    // this.agGridService.dragColumn('code-crosswalk');



    this.agGridService.selectionChanged.subscribe((res)=>{
   this.idList =   this.agGridService.getGridApi()?.getSelectedRows()?.map(a=>a.id);
    });
    this.pinned = this.dockService?.pinnedlist?.find((a) => a?.path == this.enumPath.CROSSWALK) ?.pinned ?? false;
    if (!this.pinned) {
     this.getPinnedValue()
    }

    this.codeCrosswalkService.openAddModal.subscribe((res)=>{
      if(res==true){
        this. addEditCodeGroupPopup()
      }
    });

    this.agGridService.selectionChanged.subscribe((res) => {
      if (res)  {
        this.selectedData = this.agGridService.getGridApi().getSelectedRows();
        this.sicName = this.selectedData.map((items) => {return items.sic; });
      }
    });

  }
  searchRoleByText(event){
    this.paginationBaseModel.searchText=event;
    this.codeCrosswalkService.valueSearchtext.next(this.paginationBaseModel.searchText);
  }


  addEditCodeGroupPopup(){
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size:'md',
      windowClass:'manage-manufacturer'
    };
    const modalRef = this.modalService.open(AddEditCodeGroupComponent,modalOption);
    modalRef.result.then((result) => {
      if(result){
      }
  })
  } 
  openDelteModal() {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "markAsActiveInactive",
    };
    if (this.idList.length != 0) {
      const modalRef = this.modalService.open(
        CommonModelPopUpComponent,
        modalOption
      );
      modalRef.componentInstance.title = this.enumPopUpTitles.DELETE;
      let companyName = this.sicName[1] != undefined ? ", " + this.sicName[1] : "";
      modalRef.componentInstance.fullName = `${this.sicName[0]}${companyName} ${this.sicName.length > 2? "&" + " " + (this.sicName.length - 2 + " ") + "others" : ""} `;
      modalRef.componentInstance.currentTab = this.tabNames.CODECROSSWALK_TAB;
      modalRef.componentInstance.pageType = this.tabName.CODECROSSWALKPOPUP;
      modalRef.componentInstance.delPopupforAdmin.subscribe((res) => {
        if (res){
            this.deletedCodeGroupPopup();
            setTimeout(() => {
              modalRef.componentInstance.ResMsgFromCompanyOrContact = this.ResMsgFromCompanyOrContact;
              }, 1000);
        }
      });
    }
  }


  deletedCodeGroupPopup(){
    if (this.idList.length > 0) {
      const idInstance = new Id(this.idList);
      this.codeCrosswalkService.deleteCodeCrosswalk(idInstance).subscribe((res) => {
        if (res.isSuccess) {
          this.toaster.success(res.message);
          this.codeCrosswalkService.valuePass.next(true);
          this.agGridService.getGridApi().updateRowData({ remove: this.selectedData });
        this.ResMsgFromCompanyOrContact = res.message;
        } else {
          this.toaster.error(res.message);
        }
      });
      }

  }
  openFavouriteDockPopup(pinned) {
    if (!pinned) {
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "markAsActiveInactive",
      };
      const modalRef = this.modalService.open(CommonModelPopUpComponent, modalOption );
      modalRef.componentInstance.title = this.enumPopUpTitles.FAVOURITE_DOCKS;
      modalRef.result.then((result) => {
        if (result.confirm) {
          this.pinActive(pinned);
        }
        if (!result.confirm) {
          this.pinned = !this.pinned;
        }
        });
       }
       else if (pinned) {
      this.pinActive(pinned);
    }
  }
    //#region pinned functionality
    pinActive(pinned) {
      if (pinned) {
        this.dockService.addFavouriteDocks(this.enumUrl.CROSSWALK, pinned,this.footerTitle.CROSSWALK);
      }
      if (!pinned) {
        this.dockService.removeFavouriteDocks();
      }
    }
  
    //#region filter code start
    getPinnedValue() {
      this.crmService.getUserSetting() .subscribe(res => {
          if (res?.isSuccess) {
              // let json = JSON.parse(res?.response?.userSetting) ?? {}
              let json = JSON.parse(res?.response) ?? {}
              this.pinned =  json.pinnedValues?.find((a) => a?.path == this.enumPath.CROSSWALK)  ?.pinned ?? false;
            if (json.pinnedValues && !this.dockService.pinnedlist) {
               this.storage.store('pinnedValues', json.pinnedValues);
               this.dockService.pinnedlist = json.pinnedValues
            }
          }
        })
    }
    openImport(){
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "codeCrosswalk",
      };
      const modalRef = this.modalService.open(ImportCodeCrosswalkComponent,modalOption);
     // this.navigateToimportCodecrosswalk(buttonImport);
     
      modalRef.result.then((result) => {
        this.codeCrosswalkService.valuePopup.next(false)
        if(result){
          this.agGridComponent.getCodeCrossWalkTableData();
        }
        // if (!result.confirm) {
        // }
        });

    }
    navigateToimportCodecrosswalk(buttonImport){
      if(buttonImport === 'Import Codecrosswalk'){
        const item : Menu = {path:  this.enumPath.IMPORT_CROSSWALK, icon: '', title: 'Import', type: 'link'}
        this.tabsService.addTab(item);
        this.router.navigateByUrl(this.enumPath.IMPORT_CROSSWALK);
        this.crmService.sendImportId.next(3)
      }
    
    }
    
    
}

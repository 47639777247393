import { Routes } from '@angular/router';


export const content: Routes = [
  {
    
    path: "admin",
    loadChildren: () => import("../../components/admin/admin.module").then((m) => m.AdminModule)
  },
  {
    path: "crm",
    loadChildren: () => import("../../components/crm/crm.module").then((m) => m.CrmModule)
  },
  {
    path: "parts-and-inventory",
    loadChildren: () => import("../../components/parts-and-inventory/parts-and-inventory.module").then((m) => m.PartsAndInventoryModule)
  },
  
  {
    path: "procurement",
    loadChildren: () => import("../../components/procurement/procurement.module").then((m) => m.ProcurementModule)
  },
  {
    path: "quality",
    loadChildren: () => import("../../components/quality/quality.module").then((m) => m.QualityModule)
  },
  {
    path: "sales",
    loadChildren: () => import("../../components/sales/sales.module").then((m) => m.SalesModule)
  },
  {
    path: "shipping-and-receiving",
    loadChildren: () => import("../../components/shipping-and-receiving/shipping-and-receiving.module").then((m) => m.ShippingAndReceivingModule)
  },
  {
    path: "import-documents",
    loadChildren: () => import("../../components/import-documents/import-documents.module").then((m) => m.ImportDocumentsModule)
  },
];



export class PartDetailsInfoModel{
    mpn              : string;
    mfr              : string;
    qty              : number;
    dcRestrictions   : string;
    roHS             : string;
    conditions       : string;
    needDate         : string;
    cpn              : string;
    nsn              : string;
    revisions        : string;
    description      : string;
    itemPackaging    : string;
    coo              : string;
    customerTargetPrice: number;
    testing           : string;
    urgency           : string;
    targetBuyPrice    : number;
    spq               : string;
    moq               : string;
    hts               : string;
    scheduleB         :string;
    eccn              : string;
    categories        : string;
    tempRange         : string;
    partStatus        :string;
    isProjectApproved : any;
    estStartDate      : string;
    bidDeadline       : string;
    endCustomer       : string;
    projectName       : string;
    closeProbability  : number;
    application       : string;
    opportunityNotes  :string;
    type              : string;
    opportunityTypeId : number;

}

export class AddRequirementModel{
    requirementInfo         = new  RequirementInfo();
    requirementDetails      = new  RequirementDetails();
    requirementParts        = new  RequirementParts();
    opportunityDetails      = new  OpportunityDetails();

  
}

export class RequirementInfo {
  id                   : string = "";
  requirementNo        : number;
  requirementDate      : string;
  companyId            : string = "";
  contactId            : string;
  requirementSource    : string;
  customerRFQ          : string;
  currency             : string;
  exchangeRate         : string;
  opportunityTypeId    : number = 2;
  customerNotes        : string;
}
export class RequirementDetails {
    id                 : string = "";
    requirementId      : string;
    mpn                : string;
    mfr                : string;
    qty                : number;
    dcRestrictions     : string;
    roHS               : string;
    conditions         : string;
    needDate           : any;
    cpn                : string;
    nsn                : string;
    revisions          : string;
    description        : string;
    itemPackaging      : string;
    coo                : string;
    customerTargetPrice: number;
    testing            : string;
    urgency            : string;
    targetBuyPrice     : number;
  }
  export class RequirementParts {
    id                 : string = "";
    requirementId      : string;
    spq                : string;
    moq                : string;
    hts                : string;
    scheduleB          : string;
    eccn               : string;
    categories         : string;
    tempRange          : string;
    partStatus         : string;
  }
  export class OpportunityDetails {
    id                 : string = "";
    requirementId      : string;
    isProjectApproved  : boolean = true;
    estStartDate       : string;
    bidDeadline        : string;
    endCustomer        : string;
    projectName        : string;
    closeProbability   : number = 0;
    application        : string;
    opportunityNotes   : string;
    type               : DropdownModel[]  = [];
  }
  export class DropdownModel {
    id: string;
    name: string;
    colorCode: string;
    labelName: string;
    isActive: boolean = false;
  }
  

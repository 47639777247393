<div class="modal-header d-flex border-0 mt-3 pb-0 mx-3">
  <h6 class="text-primary-color mb-0 ml-3 font-15">Filters</h6>
  <div class="d-flex align-items-center clear-color" (click)="clearAllFilter()">
    <app-svg-icon [src]="'./assets/SVGIcons/Clearicon.svg'" class="m-r-10">
    </app-svg-icon>
    <span class="text-nowrap font-13" type="button">Clear Filters</span>
  </div>
</div>
<div class="modal-body row mx-3 pb-0 justify-content-between main-body">
  <div class="ps-3 col-4 div-two">
    <div class="row row-width" style="width: 361px">
      <div class="col-12 mini-div-1">
        <div class="row pe-1">
          <div class="col-12 d-flex justify-content-between p-0">
            <div class="d-flex">
              <div class="text-light-gray nowrap me-4">
                {{currentTab === "vendor-list" || currentTab === "vendor-contact" ?"Vendor Rating": filterPopupVM.companyRating }}:
              </div>
              <div class="text-primary-color nowrap font-11">
                {{
                  getAllDropDownModel?.endRating > 0
                    ? getAllDropDownModel?.startRating +
                      " to " +

                      getAllDropDownModel?.endRating +
                      " stars "
                    : "No Range Selected"
                }}
              </div>
            </div>
            <div class="nowrap p-0 clear-color">
              <div class="hand d-flex" (click)="clearStausBar()">
                <app-svg-icon
                  [src]="'../../../../../../../assets/SVGIcons/smallClaer.svg'"
                  class="d-flex align-items-center me-2"
                >
                </app-svg-icon>
                <span class="pe-0 font-11">
                  {{ filterPopupVM.clear }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-xl-12 p-0">
            <ngx-slider
              [(value)]="getAllDropDownModel.startRating"
              [(highValue)]="getAllDropDownModel.endRating"
              [options]="options"
            >
            </ngx-slider>
            <div class="row mx-1 text-light-gray">
              <div class="col-6 text-start p-0 me-1">
                <span>0</span>
              </div>
              <div class="col d-flex justify-content-end p-0">
                <span>5</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end range slider -->
      <div class="col-12 mt-3 mb-2 mini-div-2">
        <div class="row pe-1">
          <div class="col-8 p-0 text-start">
            <span class="text-light-gray font-12"
              > {{
                currentTab === "vendor-list" || currentTab === "vendor-contact"
                  ? filterPopupVM.vendorType
                  : filterPopupVM.type
              }}:
            </span>
          </div>
          <div class="col-4 d-flex justify-content-end px-0">
            <div
              class="hand d-flex clear-color justify-content-center align-items-center"
              (click)="clearSelectedItems(filterPopupVM.class)"
            >
              <app-svg-icon
                [src]="'../../../../../../../assets/SVGIcons/smallClaer.svg'"
                class="d-flex align-items-center me-2"
              >
              </app-svg-icon>
              <span class="font-11">
                {{ filterPopupVM.clear }}
              </span>
            </div>
          </div>
          <div
            *ngIf="
              currentTab !== this.enumTabNames.VENDOR_LIST && currentTab !== this.enumTabNames.VENDOR_CONTACT
            "
            class="col-12 mt-2 p-0 d-flex flex-wrap lable-div align-div"
          >
            <app-button
              *ngFor="let items of classList; let i = index"
              [ngClass]="items.isActive ? 'selected' : ''"
              [buttonType]="'button'"
              (click)="items.isActive = !items.isActive"
              [buttonClass]="
                'button-lable-primary font-12 mx-0 ps-2 text-center w-5em btn-hover d-flex justify-content-center align-items-center'
              "
              [spanClass]="'text-truncate'"
              [button_2]="true"
              [buttonLable]="items.name"
            >
            </app-button>
          </div>
          <div
            *ngIf="
              currentTab == this.enumTabNames.VENDOR_LIST || currentTab == this.enumTabNames.VENDOR_CONTACT
            "
            class="col-12 mt-2 p-0 d-flex flex-wrap lable-div align-div"
          >
            <app-button
              *ngFor="let items of vendorTypeList; let i = index"
              [ngClass]="items.isActive ? 'selected' : ''"
              [buttonType]="'button'"
              (click)="items.isActive = !items.isActive"
              [buttonClass]="
                'button-lable-primary font-12 mx-0 ps-2 text-center w-5em btn-hover d-flex justify-content-center align-items-center'
              "
              [spanClass]="'text-truncate'"
              [button_2]="true"
              [buttonLable]="items.name"
            >
            </app-button>
          </div>
        </div>
      </div>
      <!-- end class -->
      <div class="col-12 mt-3 mb-2 mini-div-3">
        <div class="row pe-1">
          <div class="col-6 ps-0 d-flex justify-content-between">
            <div class="">
              <span class="text-light-gray">{{ filterPopupVM.active }}: </span>
            </div>
            <div class="">
              <div
                class="hand d-flex clear-color pe-3 justify-content-center"
                (click)="clearSelectedItems(filterPopupVM.active)"
              >
                <app-svg-icon
                  [src]="'../../../../../../../assets/SVGIcons/smallClaer.svg'"
                  class="d-flex align-items-center me-2"
                >
                </app-svg-icon>
                <span class="font-11">
                  {{ filterPopupVM.clear }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6 d-flex justify-content-end">
              <div class="hand text-end  includeArchived"   *ngIf="currentTab !== this.enumTabNames.VENDOR_LIST">
                <app-checkbox
                  [lableClass]="'text-light-gray'"
                  [lableName]="'Include Archived'"
                  [status]="getAllDropDownModel.archived"
                  (check)="isCheckedInclude()"
                ></app-checkbox>
              </div>
          </div>
          <div class="col-6 ps-0">
            <div class="col-12 p-0 mt-1 lable-div">
              <div class="d-flex" style="gap: 1.3rem">
                <app-button
                  *ngFor="let items of activeList; let i = index"
                  [ngClass]="items.isActive ? 'selected' : ''"
                  [buttonType]="'button'"
                  [button_2]="true"
                  (click)="filterStatus(items)"
                  [buttonClass]="
                    'button-lable-primary font-12 mx-0 ps-2  text-center w-5em actInactive btn-hover d-flex align-items-center justify-content-center'
                  "
                  [buttonLable]="items.name"
                >
                </app-button>
              </div>
            </div>
          </div>
          <div class="col-6 d-flex justify-content-end">
              <div class="hand text-end mt-1 includeArchived"   *ngIf="currentTab !== this.enumTabNames.VENDOR_LIST">
                <app-checkbox
                  [lableClass]="'text-light-gray'"
                  [lableName]="'Include Excluded'"
                  [status]="getAllDropDownModel.excludedFromVendorlist"
                  (check)="isCheckedExclude($event)"
                ></app-checkbox>
              </div>
          </div>

          <!-- <div class="col-2 col-md-3 col-lg-3 col-xl-2 p-0">
            <span class="text-light-gray">{{ filterPopupVM.active }}: </span>
          </div>
          <div class="col-4 col-md-3 col-lg-2 col-xl-4">
            <div
              class="hand d-flex clear-color justify-content-center"
              (click)="clearSelectedItems(filterPopupVM.active)"
            >
              <app-svg-icon
                [src]="'../../../../../../../assets/SVGIcons/smallClaer.svg'"
                class="d-flex align-items-center me-2"
              >
              </app-svg-icon>
              <span class="font-11">
                {{ filterPopupVM.clear }}
              </span>
            </div>
          </div>
          <div class="col-12 p-0 mt-2 d-flex justify-content-between lable-div">
            <div class="d-flex" style="gap: 1.3rem">
              <app-button
                *ngFor="let items of activeList; let i = index"
                [ngClass]="items.isActive ? 'selected' : ''"
                [buttonType]="'button'"
                [button_2]="true"
                (click)="filterStatus(items)"
                [buttonClass]="
                  'button-lable-primary font-12 mx-0 ps-2  text-center w-5em actInactive btn-hover d-flex align-items-center justify-content-center'
                "
                [buttonLable]="items.name"
              >
              </app-button>
            </div>
            <div class="hand text-end pe-2 includeArchived"   *ngIf="currentTab !== this.enumTabNames.VENDOR_LIST">
              <app-checkbox
                [lableName]="'Include Archived'"
                [status]="getAllDropDownModel.archived"
                (check)="isCheckedInclude()"
              ></app-checkbox>
            </div>
          </div> -->
        </div>
      </div>
      <!-- end active and inactive  -->
      <div class="col-12 mt-3 mini-div-4">
        <div class="row pe-1">
          <div class="col-8 col-md-8 col-lg-9 col-xl-9 p-0">
            <span class="text-light-gray font-12">
              {{ filterPopupVM.tags }}:
            </span>
          </div>
          <div
            class="col-4 col-md-4 col-lg-3 col-xl-3 d-flex justify-content-end p-0"
          >
            <div
              class="hand clear-color d-flex justify-content-center align-items-center"
              (click)="clearSelectedItems(filterPopupVM.tags)"
            >
              <app-svg-icon
                [src]="'../../../../../../../assets/SVGIcons/smallClaer.svg'"
                class="d-flex align-items-center me-2"
              >
              </app-svg-icon>
              <span class="font-11">
                {{ filterPopupVM.clear }}
              </span>
            </div>
          </div>
          <div class="container p-0 mt-2 scrollbar">
            <div class="col-12 p-0 d-flex flex-wrap labl-div align-div">
              <app-button
                *ngFor="let items of tagsList; let i = index"
                [ngClass]="items.isActive ? 'selected' : ''"
                [buttonType]="'button'"
                [button_2]="true"
                (click)="items.isActive = !items.isActive"
                [buttonClass]="
                  'button-lable-primary font-12 mx-0 ps-2 text-center w-5em btn-hover d-flex justify-content-center align-items-center'
                "
                [spanClass]="'text-truncate'"
                [buttonLable]="items.name"
              >
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-7 col-lg-7 col-md-12 pe-0 div-str">
    <div class="d-flex">
      <div class="col-6 pe-3">
        <div
          *ngIf="
            currentTab !== this.enumTabNames.VENDOR_LIST && currentTab !== this.enumTabNames.VENDOR_CONTACT
          "
          class="mb-3"
        >
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.SALESPERSON"
            [list]="getAllDropDownModel?.salesPersonList"
            [checkedList]="filterCheckList?.salesPerson_CheckList"
          >
          </app-multiselect-dropdown>
        </div>
        <div
          *ngIf="
            currentTab !== this.enumTabNames.VENDOR_LIST && currentTab !== this.enumTabNames.VENDOR_CONTACT
          "
          class="mb-3"
        >
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.ALT_SALESPERSON"
            [list]="getAllDropDownModel?.altSalesPersonList"
            [checkedList]="filterCheckList?.alt_SalesPerson_CheckList"
          >
          </app-multiselect-dropdown>
        </div>
        <div class="mb-3">
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.BUYERS_REP"
            [list]="getAllDropDownModel?.buyerRepList"
            [checkedList]="filterCheckList?.buyers_Rep_CheckList"
          >
          </app-multiselect-dropdown>
        </div>
        <div
          *ngIf="
            currentTab !== this.enumTabNames.VENDOR_LIST && currentTab !== this.enumTabNames.VENDOR_CONTACT
          "
          class="mb-3"
        >
          <!-- <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.LAST_CONTACTED"
          >
          </app-multiselect-dropdown> -->
          <app-date-picker
          [time]="false"
          [inputLabel]="enumFilterLabel?.LAST_CONTACTED"
          [inputValue]="contactedDate"
          (inputValueEmit)="contactedDate = $event"
        ></app-date-picker>
        </div>

        <!-- this belongs to vendor-list only -->
        <div
          *ngIf="currentTab == this.enumTabNames.VENDOR_LIST || currentTab == this.enumTabNames.VENDOR_CONTACT"
          class="mb-3"
        >
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.COUNTRY"
            [list]="getAllDropDownModel?.countryList"
            [checkedList]="filterCheckList?.country_CheckList"
          >
          </app-multiselect-dropdown>
        </div>
        <div
          *ngIf="currentTab == this.enumTabNames.VENDOR_LIST || currentTab == this.enumTabNames.VENDOR_CONTACT"
          class="mb-3"
        >
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.STATE"
            [list]="getAllDropDownModel?.stateList"
            [checkedList]="filterCheckList?.state_CheckList"
          >
          </app-multiselect-dropdown>
        </div>
        <div *ngIf="currentTab == this.enumTabNames.VENDOR_LIST || currentTab == this.enumTabNames.VENDOR_CONTACT" class="mb-3">
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.TIME_ZONE"
            [list]="getAllDropDownModel?.timeZoneList"
            [checkedList]="filterCheckList?.time_Zone_CheckList"
          >
          </app-multiselect-dropdown>
        </div>
        <div
          *ngIf="currentTab == this.enumTabNames.VENDOR_LIST || currentTab == this.enumTabNames.VENDOR_CONTACT"
          class="mb-3"
        >
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.MANUFACTURER"
            [list]="getAllDropDownModel?.manufacturerList"
            [checkedList]="filterCheckList?.manufacturerList_CheckList"
          >
          </app-multiselect-dropdown>
        </div>
        <!-- this belongs to vendor-list only => Ended -->
      </div>

      <div
        *ngIf="currentTab !== this.enumTabNames.VENDOR_LIST && currentTab !== this.enumTabNames.VENDOR_CONTACT"
        class="col-6 ps-3"
      >
        <div class="mb-3">
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.COUNTRY"
            [list]="getAllDropDownModel?.countryList"
            [checkedList]="filterCheckList?.country_CheckList"
          >
          </app-multiselect-dropdown>
        </div>
        <div class="mb-3">
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.STATE"
            [list]="getAllDropDownModel?.stateList"
            [checkedList]="filterCheckList?.state_CheckList"
          >
          </app-multiselect-dropdown>
        </div>

        <div class="mb-3">
          <app-multiselect-dropdown
            [listLabel]="enumFilterLabel?.INDUSTRY"
            [list]="getAllDropDownModel?.industryList"
            [checkedList]="filterCheckList?.industryList_CheckList"
          >
          </app-multiselect-dropdown>
        </div>

        <div class="mb-3">
          <!-- [listLabel]="enumFilterLabel?.STAGE_STATUS" -->
          <!-- [list]="getAllDropDownModel?.stageStatusList" -->
          <!-- [checkedList]="filterCheckList?.stageStatusList_CheckList" -->

          <app-multiselect-dropdown
            [listLabel]="
              saleStagedata == 'All'
                ? enumFilterLabel?.STAGE_STATUS
                : saleStagedata == 'Lead'
                ? enumFilterLabel?.LEAD_SCORE
                : saleStagedata == 'Prospect'
                ? enumFilterLabel?.PROSPECT_SCORE
                : saleStagedata == 'Customer'
                ? enumFilterLabel?.CUSTOMER_SCORE
                : ''
            "
            [list]="
              saleStagedata == 'All'
                ? getAllDropDownModel.stageStatusList
                : saleStagedata == 'Lead'
                ? getAllDropDownModel?.leadScoreList
                : saleStagedata == 'Prospect'
                ? getAllDropDownModel?.prospectScoreList
                : ''
            "
            [checkedList]="
              saleStagedata == 'All'
                ? filterCheckList.stageStatusList_CheckList
                : saleStagedata == 'Lead'
                ? filterCheckList?.leadScoreList_CheckList
                : saleStagedata == 'Prospect'
                ? filterCheckList.prospectScoreList_CheckList
                : ''
            "
          >
          </app-multiselect-dropdown>
        </div>
      </div>
      <div
        *ngIf="currentTab == this.enumTabNames.VENDOR_LIST || currentTab == this.enumTabNames.VENDOR_CONTACT"
        class="col-6 ps-3"
      >
        <div class="hand text-end pe-2 mb-3">
          <app-checkbox
            [lableClass]="'text-light-gray hand'"
            [lableName]="'Include “Excluded from Vendor List”'"
            [status]="getAllDropDownModel.excludedFromVendorlist"
            (check)="isCheckedExclude($event)"
          ></app-checkbox>
        </div>

        <div class="hand text-end pe-2 includeArchived mb-3">
          <app-checkbox
            [lableClass]="'text-light-gray hand'"
            [lableName]="'Approved Vendor List (AVL)'"
            [status]="getAllDropDownModel.avl"
            (check)="AVLId($event)"
          ></app-checkbox>
        </div>
        <div class="hand text-end pe-2 includeArchived mb-3">
          <app-checkbox
            [lableClass]="'text-light-gray hand'"
            [lableName]="'Non Approved Vendor List (Non AVL)'"
            [status]="getAllDropDownModel.navl"
            (check)="NAVLId($event)"
          ></app-checkbox>
        </div>
      </div>
    </div>

    <div
      *ngIf="currentTab !== this.enumTabNames.VENDOR_LIST && currentTab !== this.enumTabNames.VENDOR_CONTACT"
      class="mb-3 clear-color d-flex justify-content-end"
    >
      <span
        class="text-nowrap font-13"
        type="button"
        (click)="showMore = !showMore"
      >
        {{ showMore ? "Show More Filters" : "Hide Filters" }}
      </span>
    </div>

    <ng-container
      *ngIf="currentTab !== this.enumTabNames.VENDOR_LIST && currentTab !== this.enumTabNames.VENDOR_CONTACT"
    >
      <div class="d-flex {{ showMore === true ? 'invisible' : 'visible' }}">
        <div class="col-6 pe-3">
          <div class="mb-3">
            <app-multiselect-dropdown
              [listLabel]="enumFilterLabel?.TIME_ZONE"
              [list]="getAllDropDownModel?.timeZoneList"
              [checkedList]="filterCheckList?.time_Zone_CheckList"
            >
            </app-multiselect-dropdown>
          </div>
          <div class="mb-3">
            <app-multiselect-dropdown
              [listLabel]="enumFilterLabel?.SCOPE"
              [list]="getAllDropDownModel?.scopeList"
              [checkedList]="filterCheckList?.scope_CheckList"
            >
            </app-multiselect-dropdown>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="mb-3">
            <app-multiselect-dropdown
              [listLabel]="enumFilterLabel?.SIC_CODE"
              [list]="getAllDropDownModel?.sicCodeList"
              [checkedList]="filterCheckList?.sicCodeList_CheckList"
            >
            </app-multiselect-dropdown>
          </div>
          <div class="mb-3">
            <app-multiselect-dropdown
              [listLabel]="enumFilterLabel?.NAICS_CODE"
              [list]="getAllDropDownModel?.naicsCodeList"
              [checkedList]="filterCheckList?.naicsCodeList_CheckList"
            >
            </app-multiselect-dropdown>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer mb-2 mx-3 border-0">
  <app-button
    [buttonType]="'button'"
    [buttonClass]="'border-grey'"
    [buttonLable]="filterPopupVM.cancel"
    (click)="activeModal.close(isSuccess)"
  >
  </app-button>
  <app-button
    [buttonType]="'button'"
    [buttonClass]="'border-theme'"
    [buttonLable]="filterPopupVM.apply"
    (click)="applyChanges()"
  >
  </app-button>
</div>

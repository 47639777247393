import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit {
  @Input() isSmall = false
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Input () inputValue : string;
  @Input () inputReqValidation:boolean = false;
  selectedTime = '';
  timeIcon:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  changeTime(queryDate) {
    this.selectedTime  = queryDate;;
    this.timeIcon = false;
   }
   

}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-common-dropdown',
  templateUrl: './common-dropdown.component.html',
  styleUrls: ['./common-dropdown.component.scss']
})
export class CommonDropdownComponent implements OnInit ,AfterViewInit{
  @ViewChild("mySelect", { static: true }) mySelect: ElementRef;
  @Input() data: any[] = [];
  @Input() required : boolean = false;
  @Output() selectValueEmit:EventEmitter<any> = new EventEmitter();
  @Input() selectValue;
  @Input() disabled:boolean =false;


  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void{
  }
  selectValueChange(val){
    this.selectValueEmit.emit(val);
  }
}

<p *ngIf="header == 'fileName' || header === 'certificationName'" class=" position-top text-truncate  {{header === 'certificationName'? '':''}}">{{params.value}}</p>
<p *ngIf="header == 'expirationDate'" class=" position-top">{{dateRendererValue}}</p>


<div class="certification-tab">
  <div class="d-flex align-items-center">
    <div *ngIf="cellRendererParams?.download == 'download'" 
    [ngClass]="{'not-allowed': params.data.guId === '', 'hand': params.data.guId !== ''}"
    (click)="params.data.guId === '' ? null : download()">
    <img src="./assets/SVGIcons/grayDownload.svg" alt="">
    <span class="m-l-10">Download</span>
  </div>
  </div>
  <div class="d-flex align-items-center">
    <div *ngIf="cellRendererParams?.preview == 'preview'"
    [ngClass]="{'not-allowed': params.data.guId === '', 'hand': params.data.guId !== ''}"
    (click)="params.data.guId === '' ? null : change()">
    <img src="./assets/SVGIcons/grayPreview.svg" alt="">
    <span class="m-l-10">Preview</span>
  </div>
  </div>
  <div class="d-flex align-items-center " *ngIf="cellRendererParams?.edit"  (click)="edit()">
    <div class="hand">
      <img src="./assets/SVGIcons/edit.svg" alt="">
      <span class="m-l-10 hand">Edit</span>
    </div>
  </div>
  <div class="d-flex align-items-center"  *ngIf="cellRendererParams?.delete"  (click)="delete()">
    <div class="hand">
      <img src="./assets/SVGIcons/delete (1).svg" alt="">
      <span class="m-l-10">Delete</span>
    </div>
  </div>
</div>

<div *ngIf="header == 'categoryName'">
  <button class="button px-4-5">{{params.value}}</button>
</div>

<!-- for code-crosswalk page -->
  <div class="hand" *ngIf="header == 'editCodeCrosswalk'">
    <!-- <app-svg-icon
    (click)="editCodeCrosswalk()"
    class="py-2"
    [src]="'./assets/SVGIcons/edit.svg'"></app-svg-icon> -->
<img src="./assets/SVGIcons/edit.svg" alt="" class="py-2" (click)="editCodeCrosswalk()">
  </div>

  <div class="hand"  *ngIf="header == 'deleteCodeCrosswalk'"  (click)="openDelteModal()">
    <img src="./assets/SVGIcons/delete (1).svg" alt="">
  </div>
<!-- for code-crosswalk page  -->

<div *ngIf="header == 'isExpired' && params.value !== ''" >
  <button class="button">{{params.value}}</button>
</div>
<!-- import-companies page -->
<div  *ngIf="header == 'status'">
  <app-svg-icon *ngIf="selectedDropdownValues"
  class="ps-3 mt-3 pe-none"
  [src]="'../../../../assets/SVGIcons/check-mark (1).svg'"
  ></app-svg-icon>
  <app-svg-icon *ngIf="!selectedDropdownValues"
  class="ps-3 mt-3 pe-none"
  [src]="'../../../../assets/SVGIcons/Group 41672.svg'"
  ></app-svg-icon>
</div>


<div *ngIf="header == 'svgSuccess' && params.data.isValid==true" class="svg-success-container">
  <app-svg-icon
  class="ps-3 mt-2"
  [src]="'../../../../assets/SVGIcons/check-mark (1).svg'"
  ></app-svg-icon>
</div>
<!-- import-companies page -->
<div *ngIf="params.colDef.field === 'fullName'">
  <span class="hand" (click)="openContactInfo(params)">{{params.value}}</span>
</div>
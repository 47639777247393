import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { CompanyTradeReferencePost, GetDocumentsByCompanyRequestModel, InsertUpdateCompanyFulfillmentRequirementModel, SettingRequestModel, UpdateCreditTerm, UpdateResellerIdPostvm, UpdateTaxIdPostvm, UpdateVATRFCCNPGPostvm, UploadModel } from './settings-tab.model';
import { DataService } from '../../../../../../shared/services/data.service';
import { Result } from '../../../../../../shared/model/response.model';
import { DownloadDocuments } from '../../companies/companies.model';
import { URLS } from 'src/app/components/crm/crm-api-constants';

@Injectable({
  providedIn: 'root'
})
export class SettingsTabService {
  constructor(private service: DataService) { }


  // UpdateTaxIdData service-1
  insertUpdateTradeInfo(updateTaxIdPostvm: UpdateTaxIdPostvm): Observable<Result> {
    let url;
    if (updateTaxIdPostvm.id == null || updateTaxIdPostvm.id == '') {
      updateTaxIdPostvm.id = ''
      url = URLS.companyTradeInfo.insertCompanyTradeInfo
    } else {
      url = URLS.companyTradeInfo.updateCompanyTradeInfo
    }
    return this.service.post(url, updateTaxIdPostvm).pipe<Result>(tap((response: any) => {
      return response;
    })
    );
  }

  getSettingData(settingRequestModel: SettingRequestModel): Observable<Result> {
    let url = URLS.companySetting.getCompanyDetailsSettingsByCompanyId;
    return this.service.post(url, settingRequestModel).pipe<Result>(tap((response: any) => {
      return response;
    })
    );
  }


  updateCreditTerms(updateCreditTerm: UpdateCreditTerm): Observable<Result> {
    let url;
    if (updateCreditTerm.id == null || updateCreditTerm.id == '' || updateCreditTerm.id == undefined) {
      url = URLS.companyCreditTerm.insertCompanyCreditTerm;
    } else {
      url = URLS.companyCreditTerm.updateCompanyCreditTerm;
    }
    return this.service.post(url, updateCreditTerm).pipe<Result>(tap((response: any) => {
      return response;
    })
    );
  }


  insertUpdateCompanyTradeReference(companyTradeReferencePost: CompanyTradeReferencePost): Observable<Result> {
    const Json = JSON.stringify(companyTradeReferencePost)
    let url;
    if (companyTradeReferencePost.id == null || companyTradeReferencePost.id == '') {
      companyTradeReferencePost.id = '';
      url = URLS.companyTradeReference.insertCompanyTradeReference;
    } else {
      url = URLS.companyTradeReference.updateCompanyTradeReference;
    }
    return this.service.post(url, companyTradeReferencePost).pipe<Result>(tap((response: any) => {
      return response;
    })
    );
  }

  InsertUpdateFullfillmentData(insertUpdateCompanyFulfillmentRequirementModel: InsertUpdateCompanyFulfillmentRequirementModel): Observable<Result> {
    let url;
    if (insertUpdateCompanyFulfillmentRequirementModel.id == null || insertUpdateCompanyFulfillmentRequirementModel.id == '') {
      insertUpdateCompanyFulfillmentRequirementModel.id = ''
      url = URLS.companyFulfillment.insertCompanyFulfillment;
    } else {
      url = URLS.companyFulfillment.updateCompanyFulfillment;
    }
    return this.service.post(url, insertUpdateCompanyFulfillmentRequirementModel).pipe<Result>(tap((response: any) => {
      return response;
    })
    );
  }

  insertDocument(uploadModel: UploadModel): Observable<Result> {
    // let url = "SourceMatrixApi/Document/InsertDocument";
    let url = URLS.companyDocuments.uploadCompanyDocument;
    return this.service.post(url, uploadModel, true).pipe<Result>(tap((response: any) => {
      return response;
    })
    );
  }

  getTradeReferenceDocuments(getDocumentsByCompanyRequestModel: GetDocumentsByCompanyRequestModel) {
    let url = URLS.companyDocuments.getDocumentByCompanyOrTypeId;
    return this.service.post(url, getDocumentsByCompanyRequestModel, true).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }


  downloadAndPreviewTradeReference(downloadDocuments: DownloadDocuments) {
    let url = URLS.companyDocuments.downloadDocuments;
    return this.service.post(url, downloadDocuments).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

}




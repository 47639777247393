<div class="mt-5 container-fluid d-flex justify-content-center text-center" [style]="{'height': flexHeight}">
  <button #toEnd id="toEnd" style="display: none;"   progressStepNext></button>
  <div class="box">
    <div class="mb-2">
      <app-svg-icon 
      *ngIf="failed"
      class="mt-3 justify-content-center text-center"
      [src]="'../../../../assets/SVGIcons/uploadesuccess.svg'"
      ></app-svg-icon>
      <app-svg-icon 
      *ngIf="!failed"
      class="mt-3 justify-content-center text-center"
      [src]="'../../../../assets/SVGIcons/uploadFaild.svg'"
      ></app-svg-icon>
    
    </div>
    <div *ngIf="failed" class="font-15 text-semi-primary mb-2">{{this.fileName}}</div>
    <div  *ngIf="failed" class="font-15 text-gray mb-3">Import was completed successfully</div>    
    <div  *ngIf="!failed" class="font-15 text-gray mb-3">Import could not be completed. Please try again.</div>    
    <div *ngIf="failed" class="font-13 mb-4 text-light-gray">Added Records: {{totalRecords}}
      <span class="font-13 text-light-gray" *ngIf="currentTab !== tabName.CODECROSSWALK_TAB && routeUrl !== routePath.IMPORTVENDOR_ROUTEPATH">Companies</span>
      <span class="font-13 text-light-gray"*ngIf="routeUrl == routePath.IMPORTVENDOR_ROUTEPATH">Vendors</span></div>

    <div>
      <app-button *ngIf="currentTab !== tabName.CODECROSSWALK_TAB"
      [button_2]="true"
      [buttonClass]="'button button-primary mt-1'"
      [buttonLable]="enumButtonName.NEW_IMPORT"
     (click)="backToImport()"
    >
    </app-button>
    <app-button *ngIf="currentTab == tabName.CODECROSSWALK_TAB"
    [button_2]="true"
    [buttonClass]="'button button-info mt-1'"
    [buttonLable]="enumButtonName.CLOSE"
   (click)="backToImport()"
  >
  </app-button>


    </div>
  </div>
</div>
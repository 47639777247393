import { Options } from '@angular-slider/ngx-slider';
import { Component, Input, OnInit } from '@angular/core';
import { AllStaticText, TypeList } from '../add-company/add-company.model';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { LabelsName, RoutePath, TabNames } from 'src/app/shared/model/enum';
import { ToastrService } from 'ngx-toastr';
import { CrmService } from '../../../crm.service';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { state } from "@angular/animations";

import {
  CommonFilterModel,
  CompanyFilterList,
  FilterCheckList,
  GetAllDropDownModel,
} from "../../pages/companies/companies.model";
import { StorageService } from "src/app/shared/services/storage.service";
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { UserSetting } from '../../../crm.model';
import { SaveUserSetting } from 'src/app/shared/components/ag-grid/ag-grid.model';
import { AgGridService } from 'src/app/shared/components/ag-grid/ag-grid.service';
import { Router } from '@angular/router';
import { StingToDateService } from 'src/app/shared/services/stringToDate.service';

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
  @Input() stagesName: string;
  @Input() getAllDropDownModel = new GetAllDropDownModel();
  saleStageList: TypeList[] = [];
  enumFilterLabel = LabelsName;
  filterCheckList = new FilterCheckList();
  tagsList: CommonFilterModel[] = [];
  classList: CommonFilterModel[] = [];
  vendorTypeList: CommonFilterModel[] = [];
  filterPopupVM = new AllStaticText;
  isSuccess: boolean = false;
  showMore: boolean = true;
  companyFiltersList = new CompanyFilterList();
  currentTab = ''
  saleStagedata: any
  userSetting = new UserSetting();
  saveUserSetting = new SaveUserSetting();
  activeList = [
    { id: 1, name: "Active", labelName: "Active", isActive: false },
    { id: 0, name: "Inactive", labelName: "InActive", isActive: false },
  ];
  enumTabNames            = TabNames;
  //#region ngx slider region start
  options: Options = {
    floor: 0.0,
    ceil: 5.0,
    step: 0.5,
    showSelectionBar: true,
  };
  contactedDate:any;
  //#endregion ngx slider region start
route;
routerPath = RoutePath
  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    public crmService: CrmService,
    public commonTabService: CommonTabService,
    public storage: StorageService,
    public tabsService: TabsService,
    public agGridService: AgGridService,
    private router: Router,
    public stringToDateService: StingToDateService,
  ) { }

  ngOnInit(): void {
    this.route = this.router.url
    // this.fillCheckedList();
    this.tabsService.currentTab.subscribe(res => {
      if (res) {
        this.currentTab = res;
      } else {
        this.currentTab = this.tabsService.thisTab
      }
    })
    this.saleStagedata = (this.stagesName == '2' ? 'Lead' : (this.stagesName == '3' ? 'Prospect' : this.stagesName));
    // this.saleStagedata = this.stagesName
    this.tagsList = this.getAllDropDownModel?.tagsList;
    this.classList = this.getAllDropDownModel?.classList;
    this.vendorTypeList = this.getAllDropDownModel?.vendorTypeList;
    this.activeList
      ?.filter((a) => a.id === this.getAllDropDownModel?.companyStatusId)
      ?.map((a) => (a.isActive = true));
    this.fillCheckedList();
    if (this.contactedDate) {
      this.contactedDate = this.stringToDateService.convertStringToDate(this.contactedDate)
    } else if(this.getAllDropDownModel?.fromDate){
      this.contactedDate  = this.stringToDateService.convertStringToDate(this.getAllDropDownModel?.fromDate)
    }
    // else {
    //   this.contactedDate = this.convertStringToDate(this.filterCheckList.last_Contacted_CheckList)
      
    // }


  }


  fillCheckedList() {
    // check list from storage
    let checked
    if (this.currentTab == this.enumTabNames.COMPANIES) {
      checked = this.storage.retrieve(this.enumTabNames.COMPANIES);
    }
    if (this.currentTab == this.enumTabNames.LEADS ) {
      checked = this.storage.retrieve(this.enumTabNames.LEADS );
    }
    if (this.currentTab == this.enumTabNames.CUSTOMERS) {
      checked = this.storage.retrieve(this.enumTabNames.CUSTOMERS);
    }
    if (this.currentTab == this.enumTabNames.PROSPECTS) {
      checked = this.storage.retrieve(this.enumTabNames.PROSPECTS);
    }
    if (this.currentTab == this.enumTabNames.CONTACTS) {
      checked = this.storage.retrieve(this.enumTabNames.CONTACTS);
    }
    if (this.currentTab == this.enumTabNames.VENDOR_LIST) {
      checked = this.storage.retrieve('vendorList')
    }
    if (this.currentTab == this.enumTabNames.VENDOR_CONTACT) {
      checked = this.storage.retrieve('vendorContact');
    }
    let drops = checked?.allDropDowns;
    // updating the dropdown from local storage
    if (drops?.endRating) {
      this.getAllDropDownModel.endRating = drops?.endRating
      this.getAllDropDownModel.startRating = drops?.startRating
    }
   if(drops?.fromDate){
    this.getAllDropDownModel.fromDate = drops?.fromDate
   }
    this.tagsList.map(value => {
      drops?.tagsList.map(tl => {
        if (tl.id == value.id) {
          value.isActive = tl.isActive
        }
      })
    })
    if (drops?.archived) {
      this.getAllDropDownModel.archived = drops?.archived
    }

    if (drops?.avl == true) {
      this.getAllDropDownModel.avl = true
      this.getAllDropDownModel.avlId = 1
    }
    if (drops?.navl == true) {
      this.getAllDropDownModel.navl = true
      this.getAllDropDownModel.avlId = 0
    }
    if(drops?.navl == true && drops?.avl == true){
      this.getAllDropDownModel.navl = true;
      this.getAllDropDownModel.avl = true
      this.getAllDropDownModel.avlId = 2

    } 
    if(drops?.navl == false && drops?.avl == false){
      this.getAllDropDownModel.navl = false;
      this.getAllDropDownModel.avl = false
      this.getAllDropDownModel.avlId = 3
    }

    // if (drops?.avlId == 2 && (drops?.avl && drops?.navl)) {
    //   this.getAllDropDownModel.avl = true
    //   this.getAllDropDownModel.navl = true
    // }

    if (drops?.excludedFromVendorlist) {
      this.getAllDropDownModel.excludedFromVendorlist = drops?.excludedFromVendorlist
    }
    this.classList.map(value => {
      drops?.classList.map(tl => {
        if (tl.id == value.id) {
          value.isActive = tl.isActive
        }
      })
    })
    this.vendorTypeList.map(value => {
      drops?.vendorTypeList.map(tl => {
        if (tl.id == value.id) {
          value.isActive = tl.isActive
        }
      })
    })
    if (checked?.activeList) {
      this.activeList = checked?.activeList
    }
    this.getAllDropDownModel?.altSalesPersonList?.map((gs) => {
      drops?.altSalesPersonList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });
    this.getAllDropDownModel?.salesPersonList?.map((gs) => {
      drops?.salesPersonList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });
    this.getAllDropDownModel?.buyerRepList.map((gs) => {
      drops?.buyerRepList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });
    this.getAllDropDownModel?.countryList.map((gs) => {
      drops?.countryList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });

    this.getAllDropDownModel?.leadScoreList.map((gd) => {
      drops?.leadScoreList.map((value) => {
        if (gd.id == value.id) {
          gd.isActive = value.isActive
        }
      })
    })
    this.getAllDropDownModel?.stateList.map((gs) => {
      drops?.stateList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });
    this.getAllDropDownModel?.industryList.map((gs) => {
      drops?.industryList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });

    this.getAllDropDownModel?.manufacturerList.map((gs) => {
      drops?.manufacturerList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });

    this.getAllDropDownModel?.prospectScoreList.map(gd => {
      drops?.prospectScoreList?.map(value => {
        if (gd.id == value.id) {
          gd.isActive = value.isActive
        }
      })
    })
    this.getAllDropDownModel?.stageStatusList.map((gs) => {
      drops?.stageStatusList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });
    this.getAllDropDownModel?.scopeList.map((gs) => {
      drops?.scopeList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });
    this.getAllDropDownModel?.timeZoneList.map((gs) => {
      drops?.timeZoneList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });
    this.getAllDropDownModel?.sicCodeList?.map((gs) => {
      drops?.sicCodeList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });
    this.getAllDropDownModel?.naicsCodeList?.map((gs) => {
      drops?.naicsCodeList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });
    this.getAllDropDownModel?.salesPersonList?.map((gs) => {
      drops?.salesPersonList?.map((value) => {
        if (gs.id == value.id) {
          gs.isActive = value.isActive;
        }
      });
    });

    // extracting the names of  slected filters from local storage
    this.filterCheckList.salesPerson_CheckList = drops?.salesPersonList.map(
      (value) => value.name
    );
    this.filterCheckList.alt_SalesPerson_CheckList =
      drops?.altSalesPersonList?.map((value) => value.name);
    this.filterCheckList.buyers_Rep_CheckList = drops?.buyerRepList?.map(
      (value) => value.name
    );
    this.filterCheckList.scope_CheckList = drops?.scopeList?.map(
      (value) => value.name
    );
    this.filterCheckList.leadScoreList_CheckList = drops?.leadScoreList?.map((value) => value.name)
    this.filterCheckList.state_CheckList = drops?.stateList?.map(
      (value) => value.name
    );
    this.filterCheckList.country_CheckList = drops?.countryList?.map(
      (value) => value.name
    );
    this.filterCheckList.sicCodeList_CheckList =
      drops?.sicCodeList?.map((value) => value.name);
    this.filterCheckList.intendToBuyList_CheckList =
      drops?.intendToBuyList?.map((value) => value.name);
    this.filterCheckList.industryList_CheckList =
      drops?.industryList?.map((value) => value.name);
    
    this.filterCheckList.manufacturerList_CheckList =
    drops?.manufacturerList?.map((value) => value.name);
      
    this.filterCheckList.leadsList_CheckList = drops?.leadsList?.map(
      (value) => value.name
    );
    this.filterCheckList.naicsCodeList_CheckList =
      drops?.naicsCodeList?.map((value) => value.name);
    this.filterCheckList.prospectScoreList_CheckList =
      drops?.prospectScoreList?.map((value) => value.name)
    this.filterCheckList.stageStatusList_CheckList =
      drops?.stageStatusList?.map((value) => value.name);
    this.filterCheckList.time_Zone_CheckList = drops?.timeZoneList?.map(
      (value) => value.name
    );
  }

  clearStausBar() {
    this.getAllDropDownModel.startRating = 0;
    this.getAllDropDownModel.endRating = 0;
  }

  clearSelectedItems(data) {
    switch (data) {
      case this.filterPopupVM.class:
        this.getAllDropDownModel?.classList?.map((a) => (a.isActive = false));
        this.getAllDropDownModel?.vendorTypeList?.map((a) => (a.isActive = false));
        break;
      case this.filterPopupVM.active:
        this.activeList?.map((a) => (a.isActive = false));
        break;
      case this.filterPopupVM.tags:
        this.getAllDropDownModel?.tagsList.map((a) => (a.isActive = false));
        break;
      case this.filterPopupVM.type:
        this.getAllDropDownModel?.typeList.map((a) => (a.isActive = false));
        break;
    }
  }

  filterStatus(item) {
    this.activeList.map((a) => {
      if (a === item) {
        a.isActive = true;
      } else {
        a.isActive = false;
      }
    });
  }

  clearAllFilter() {
    this.crmService.isActive.next(false);
    this.crmService.isRating.next(false)
    this.crmService.isSelected.next(false);
    this.getAllDropDownModel.startRating = 0;
    this.getAllDropDownModel.endRating = 0;
    this.getAllDropDownModel.archived = false;
    this.getAllDropDownModel.avl = false;
    this.getAllDropDownModel.navl = false;
    this.getAllDropDownModel.excludedFromVendorlist = false;
    this.getAllDropDownModel?.classList.map((a) => (a.isActive = false));
    this.getAllDropDownModel?.vendorTypeList.map((a) => (a.isActive = false));
    this.getAllDropDownModel?.tagsList.map((a) => (a.isActive = false));
    this.activeList?.map((a) => (a.isActive = false));
    this.getAllDropDownModel.altSalesPersonList.map(
      (value) => (value.isActive = false)
    );
    this.getAllDropDownModel.buyerRepList.map(
      (value) => (value.isActive = false)
    );
    this.getAllDropDownModel.prospectScoreList.map(
      (value) => (value.isActive = false)
    );
    this.getAllDropDownModel.leadScoreList.map(value => {
      value.isActive = false
    })
    this.getAllDropDownModel.countryList.map(
      (value) => (value.isActive = false)
    );
    this.getAllDropDownModel.industryList.map(
      (value) => (value.isActive = false)
    );
   
    this.getAllDropDownModel.manufacturerList.map(
      (value) => (value.isActive = false)
    );

    this.getAllDropDownModel.intendToBuyList.map(
      (value) => (value.isActive = false)
    );
    this.getAllDropDownModel.salesPersonList.map(
      (value) => (value.isActive = false)
    );
    this.getAllDropDownModel.stateList.map((value) => (value.isActive = false));
    this.getAllDropDownModel.stageStatusList.map(
      (value) => (value.isActive = false)
    );
    this.getAllDropDownModel.timeZoneList.map(
      (value) => (value.isActive = false)
    );
    this.getAllDropDownModel.sicCodeList.map(
      (value) => (value.isActive = false)
    );
    this.getAllDropDownModel.naicsCodeList.map(
      (value) => (value.isActive = false)
    );
    this.filterCheckList.leadScoreList_CheckList = []
    this.filterCheckList.alt_SalesPerson_CheckList = []
    this.filterCheckList.buyers_Rep_CheckList = []
    this.filterCheckList.country_CheckList = []
    this.filterCheckList.industryList_CheckList = []
    this.filterCheckList.intendToBuyList_CheckList = []
    this.filterCheckList.last_Contacted_CheckList = []
  // this.filterCheckList.last_Contacted_CheckList = ''
    this.filterCheckList.leadsList_CheckList = []
    this.filterCheckList.manufacturerList_CheckList = []
    this.filterCheckList.naicsCodeList_CheckList = []
    this.filterCheckList.salesPerson_CheckList = []
    this.filterCheckList.scope_CheckList = []
    this.filterCheckList.sicCodeList_CheckList = []
    this.filterCheckList.stageStatusList_CheckList = []
    this.filterCheckList.state_CheckList = []
    this.filterCheckList.time_Zone_CheckList = []
    this.filterCheckList.prospectScoreList_CheckList = []
    this.getAllDropDownModel.scopeList.map((value) => (value.isActive = false));
    this.contactedDate = '';
    this.filterCheckList.last_Contacted_CheckList = [];
    this.getAllDropDownModel.fromDate = '';
  }

  applyChanges() {
    this.agGridService.toggleHeaderExpandIcon.next(false)
    this.getAllDropDownModel.endRating = this.getAllDropDownModel?.endRating?.toString() === "NaN"? 0 : this.getAllDropDownModel?.endRating;
    this.getAllDropDownModel.startRating = this.getAllDropDownModel?.startRating?.toString() === "NaN" ? 0  : this.getAllDropDownModel?.startRating;
    this.getAllDropDownModel.companyStatusId =
      this.activeList?.filter((a) => a?.isActive === true)[0]?.id ?? 2;
    this.getAllDropDownModel.salesPersonList =
      this.getAllDropDownModel?.salesPersonList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.altSalesPersonList =
      this.getAllDropDownModel?.altSalesPersonList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
      if(this.contactedDate){
        this.getAllDropDownModel.fromDate =  this.stringToDateService.dateConvertedToString(this.contactedDate);
        this.filterCheckList.last_Contacted_CheckList = this.contactedDate;
      }else if(this.contactedDate == ''){
        this.getAllDropDownModel.fromDate =  '';
        this.filterCheckList.last_Contacted_CheckList = [];

      }
    this.getAllDropDownModel.buyerRepList =
      this.getAllDropDownModel?.buyerRepList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.classList =
      this.classList?.filter((a) => a?.isActive === true) ?? [];
    this.getAllDropDownModel.vendorTypeList =
      this.vendorTypeList?.filter((a) => a?.isActive === true) ?? [];
    this.getAllDropDownModel.countryList =
      this.getAllDropDownModel?.countryList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.tagsList =
      this.tagsList?.filter((a) => a?.isActive === true) ?? [];
    this.getAllDropDownModel.typeList =
      this.getAllDropDownModel?.typeList?.filter((a) => a?.isActive === true) ??
      [];
    this.getAllDropDownModel.timeZoneList =
      this.getAllDropDownModel?.timeZoneList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.stateList =
      this.getAllDropDownModel?.stateList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.scopeList =
      this.getAllDropDownModel?.scopeList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.prospectScoreList =
      this.getAllDropDownModel?.prospectScoreList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.industryList =
    this.getAllDropDownModel?.industryList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    
    this.getAllDropDownModel.manufacturerList =
    this.getAllDropDownModel?.manufacturerList?.filter(
        (a) => a?.isActive === true
      ) ?? [];  

    this.getAllDropDownModel.intendToBuyList =
      this.getAllDropDownModel?.intendToBuyList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.naicsCodeList =
      this.getAllDropDownModel?.naicsCodeList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.sicCodeList =
      this.getAllDropDownModel?.sicCodeList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.leadsList =
      this.getAllDropDownModel?.leadsList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.stageStatusList =
      this.getAllDropDownModel?.stageStatusList?.filter(
        (a) => a?.isActive === true
      ) ?? [];
    this.getAllDropDownModel.leadScoreList = this.getAllDropDownModel?.leadScoreList?.filter(a => a?.isActive == true)
    this.getAllDropDownModel.filterTypeId = this.currentTab == this.enumTabNames.VENDOR_CONTACT || this.currentTab == this.enumTabNames.VENDOR_LIST ? 2 : 1
    this.creatingFilterList()

    //--------------- Start ------------------------------------- 
    // this will create companyFilterResponse
    // the data of this array will get show on header
    let newData = [];
    if (this.getAllDropDownModel.endRating) {
      newData.push({
        id: '1',
        labelName: 'rating',
        colorCode: 'string',

        name:
          this.getAllDropDownModel?.startRating + ' to ' +
          this.getAllDropDownModel?.endRating + ' stars',
        isActive: this.getAllDropDownModel?.endRating > 0 ? false : true,
      },)
    }
    if (this.getAllDropDownModel.archived) {
      newData.push({
        id: '101',
        labelName: 'archived',
        colorCode: 'string',

        name: "Archived",
        isActive: this.getAllDropDownModel.archived,
      },)
    }
    if (this.getAllDropDownModel.excludedFromVendorlist) {
      newData.push({
        id: '102',
        labelName: 'excludedFromVendorlist',
        colorCode: 'string',
       // name: "Excluded From Vendor List",
       name:this.route == this.routerPath.COMPANY_ROUTEPATH ?"Include Excluded" : "Excluded From Vendor List",
        isActive: this.getAllDropDownModel.excludedFromVendorlist,
      },)
    }
    if (this.getAllDropDownModel.avl) {
      newData.push({
        id: '103',
        labelName: 'AVL',
        colorCode: 'string',
        name: "AVL",
        isActive: true,
      },)
    }
    if (this.getAllDropDownModel.navl) {
      newData.push({
        id: '104',
        labelName: 'NAVL',
        colorCode: 'string',
        name: "NAVL",
        isActive: true,
      },)
    }
    if (this.getAllDropDownModel.fromDate) {
      newData.push({id:'',
      labelName: 'Contacted Date',
      name:this.getAllDropDownModel?.fromDate})
    }



    newData.push(
    //  ...this.getAllDropDownModel?.lastContactedDate,
      ...this.classList?.filter((a) => a?.isActive === true),
      ...this.vendorTypeList?.filter((a) => a?.isActive === true),
      ...this.getAllDropDownModel?.buyerRepList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel?.leadScoreList?.filter(a => a?.isActive == true),
      ...this.getAllDropDownModel?.altSalesPersonList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel?.salesPersonList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel?.stateList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel.prospectScoreList?.filter(a => a?.isActive === true),
      ...this.getAllDropDownModel?.industryList?.filter(
        (a) => a?.isActive === true
      ),

      ...this.getAllDropDownModel.prospectScoreList?.filter(a => a?.isActive === true),
      ...this.getAllDropDownModel?.manufacturerList?.filter(
        (a) => a?.isActive === true
      ),

      ...this.getAllDropDownModel?.intendToBuyList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel?.countryList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.tagsList?.filter((a) => a?.isActive === true),
      ...this.getAllDropDownModel?.scopeList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel?.timeZoneList?.filter(a => a?.isActive == true),
      ...this.getAllDropDownModel?.typeList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel?.naicsCodeList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel?.stageStatusList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel?.leadsList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.getAllDropDownModel?.sicCodeList?.filter(
        (a) => a?.isActive === true
      ),
      ...this.activeList.filter((a) => a?.isActive === true)
    );
    //--------------- End ------------------------------------- 
    if (this.currentTab == this.enumTabNames.COMPANIES) {
      this.storage.store(this.enumTabNames.COMPANIES, {
        allDropDowns: this.getAllDropDownModel,
        companyFilterResponse: newData,
        companyFilterList: this.companyFiltersList,
        activeList: this.activeList
      });
    }
    if (this.currentTab == this.enumTabNames.LEADS ) {
      this.storage.store(this.enumTabNames.LEADS , {
        allDropDowns: this.getAllDropDownModel,
        companyFilterResponse: newData,
        companyFilterList: this.companyFiltersList,
        activeList: this.activeList
      });
    }
    if (this.currentTab == this.enumTabNames.CUSTOMERS) {
      this.storage.store(this.enumTabNames.CUSTOMERS, {
        allDropDowns: this.getAllDropDownModel,
        companyFilterResponse: newData,
        companyFilterList: this.companyFiltersList,
        activeList: this.activeList
      });
    }
    if (this.currentTab == this.enumTabNames.PROSPECTS) {
      this.storage.store(this.enumTabNames.PROSPECTS, {
        allDropDowns: this.getAllDropDownModel,
        companyFilterResponse: newData,
        companyFilterList: this.companyFiltersList,
        activeList: this.activeList
      });
    }
    if (this.currentTab == this.enumTabNames.CONTACTS) {
      this.storage.store(this.enumTabNames.CONTACTS, {
        allDropDowns: this.getAllDropDownModel,
        companyFilterResponse: newData,
        companyFilterList: this.companyFiltersList,
        activeList: this.activeList
      });
    }
    if (this.currentTab == this.enumTabNames.VENDOR_LIST) {
      this.storage.store("vendorList", {
        allDropDowns: this.getAllDropDownModel,
        companyFilterResponse: newData,
        companyFilterList: this.companyFiltersList,
        activeList: this.activeList
      });
    }
    if (this.currentTab == this.enumTabNames.VENDOR_CONTACT) {
      this.storage.store("vendorContact", {
        allDropDowns: this.getAllDropDownModel,
        companyFilterResponse: newData,
        companyFilterList: this.companyFiltersList,
        activeList: this.activeList
      });
    }
    // this.crmService
    //   .insertFilterRecord(this.getAllDropDownModel)
    //   .subscribe((res) => {
    //     if (res.isSuccess) {
    //     } else {
    //       this.toastr.info(res.message);
    //     }
    //   });
    if (this.getAllDropDownModel.companyStatusId != 2) {
      this.crmService.isActive.next(true);
    }
    if (this.getAllDropDownModel.endRating != 0) {
      this.crmService.isRating.next(true)
    }
    this.getDataFromStorage()
    this.toastr.success("Filter inserted successfully.");
    this.activeModal.close(true);
  }

  getDataFromStorage() {
    let grids = this.storage.retrieve("userSetting")
    // stored grids
    this.userSetting.companies = grids.companies;
    this.userSetting.contacts = grids.contacts;
    this.userSetting.customers = grids.customers;
    this.userSetting.leads = grids.leads;
    this.userSetting.prospects = grids.prospects;
    this.userSetting.vendorLists = grids.vendorList
    this.userSetting.vendorContacts = grids.vendorContact
    this.userSetting.pinnedValues = grids.pinnedValues;


    // stored filter
    this.userSetting.companiesFilter = this.storage.retrieve(this.enumTabNames.COMPANIES)
    this.userSetting.contactFilter = this.storage.retrieve(this.enumTabNames.CONTACTS)
    this.userSetting.customersFilters = this.storage.retrieve(this.enumTabNames.CUSTOMERS)
    this.userSetting.leadsFilter = this.storage.retrieve(this.enumTabNames.LEADS )
    this.userSetting.prospectsFilter = this.storage.retrieve(this.enumTabNames.PROSPECTS)
    this.userSetting.vendorListFilters = this.storage.retrieve(this.enumTabNames.VENDOR_LIST)
    this.userSetting.vendorContactsFilter = this.storage.retrieve(this.enumTabNames.VENDOR_CONTACT)

    this.saveUserSetting.userSetting = JSON.stringify(this.userSetting);
    this.agGridService.saveUserSetting(this.saveUserSetting).subscribe(res => {
      const json = JSON.parse(res?.response);
      this.storage.store("userSetting", json)
    })
  }
  creatingFilterList() {
    this.companyFiltersList.fromDate = this.getAllDropDownModel.fromDate;


    //--------------- Start ------------------------------------- 
    //  this will create companyFilterList
    // we will pass the result of this to api to filter that grid
     this.companyFiltersList.startRating = this.getAllDropDownModel.startRating;
     this.companyFiltersList.endRating = this.getAllDropDownModel.endRating;
    this.companyFiltersList.excludedFromVendorlist = this.getAllDropDownModel.excludedFromVendorlist
    if (this.activeList[0].isActive) {
      this.companyFiltersList.companyStatusId = 1;
    }
    if (this.activeList[1].isActive) {
      this.companyFiltersList.companyStatusId = 0;
    }
    if (this.activeList[0].isActive && this.activeList[1].isActive) {
      this.companyFiltersList.companyStatusId = 2;
    }


    let leadScoreListIds: any[] = this.getAllDropDownModel.leadScoreList.map((object) => object.id);
    this.companyFiltersList.leadScoreList = leadScoreListIds.join(',');
    let salesPersonListIds: any[] =
      this.getAllDropDownModel.salesPersonList.map((object) => object.id);
    this.companyFiltersList.salesPersonList = salesPersonListIds.join(",");

    let classListIds: any[] =
      this.getAllDropDownModel.classList.map((object) => object.id);
    this.companyFiltersList.classList = classListIds.join(",");

    let vendorTypeListIds: any[] =
      this.getAllDropDownModel.vendorTypeList.map((object) => object.id);
    this.companyFiltersList.vendorTypeList = vendorTypeListIds.join(",");


    this.companyFiltersList.archived = this.getAllDropDownModel.archived
    this.companyFiltersList.avlId = this.getAllDropDownModel.avlId
    let altSalesPersonListIds: any[] =
      this.getAllDropDownModel.altSalesPersonList.map((object) => object.id);
    this.companyFiltersList.altSalesPersonList =
      altSalesPersonListIds.join(",");

    let prospectListIds: any[] =
      this.getAllDropDownModel.prospectScoreList.map((object) => object.id);
    this.companyFiltersList.prospectScoreList =
      prospectListIds.join(",");

    let buyerRepListIds: any[] = this.getAllDropDownModel.buyerRepList.map(
      (object) => object.id
    );
    this.companyFiltersList.buyersRepList = buyerRepListIds.join(",");

    let countryListIds: any[] = this.getAllDropDownModel.countryList.map(
      (object) => object.id
    );
    this.companyFiltersList.countryList = countryListIds.join(",");

    let tagsListIds: any[] = this.getAllDropDownModel.tagsList.map(
      (object) => object.id
    );
    this.companyFiltersList.tagsList = tagsListIds.join(",");

    let typeListIds: any[] = this.getAllDropDownModel.typeList.map(
      (object) => object.id
    );

    let timezoneListIds: any[] = this.getAllDropDownModel.timeZoneList.map(
      (object) => object.id
    );
    this.companyFiltersList.timeZoneList = timezoneListIds.join(",");

    let stageListIds: any[] = this.getAllDropDownModel.stageStatusList.map(
      (object) => object.id
    );
    this.companyFiltersList.stageStatusList = stageListIds.join(",");

    let stateListIds: any[] = this.getAllDropDownModel.stateList.map(
      (object) => object.id
    );
    this.companyFiltersList.stateList = stateListIds.join(',')
    let scopeListIds: any[] = this.getAllDropDownModel.scopeList.map(
      (object) => object.id
    );
    this.companyFiltersList.scopeList = scopeListIds.join(",");

    let industryListIds: any[] = this.getAllDropDownModel.industryList.map(
      (object) => object.id
    );
    this.companyFiltersList.industryList = industryListIds.join(",");

    let manufacturerListIds: any[] = this.getAllDropDownModel.manufacturerList.map(
      (object) => object.id
    );
    this.companyFiltersList.manufacturerList = manufacturerListIds.join(",");

    let naicsCodeListIds: any[] = this.getAllDropDownModel.naicsCodeList.map(
      (object) => object.name
    );
    this.companyFiltersList.naicsCodeList = naicsCodeListIds.join(",");

    let sicCodeListIds: any[] = this.getAllDropDownModel.sicCodeList.map(
      (object) => object.name
    );
    this.companyFiltersList.sicCodeList = sicCodeListIds.join(",");
    //--------------- End ------------------------------------- 
  }
  isCheckedInclude() {
    this.getAllDropDownModel.archived = !this.getAllDropDownModel.archived;
  }
  isCheckedExclude(event) {
    this.getAllDropDownModel.excludedFromVendorlist = event;
  }


  AVLId(event) {
    this.getAllDropDownModel.avl = !this.getAllDropDownModel.avl
    if (event) {
      this.getAllDropDownModel.avlId = 1
    }
    if (!event && this.getAllDropDownModel.navl) {
      this.getAllDropDownModel.avlId = 0
    }
    if (!event && !this.getAllDropDownModel.navl) {
      this.getAllDropDownModel.avlId = 3
    }
    if (event && this.getAllDropDownModel.navl) {
      this.getAllDropDownModel.avlId = 2
    }
  }
  NAVLId(event) {
    this.getAllDropDownModel.navl = !this.getAllDropDownModel.navl
    if (event) {
      this.getAllDropDownModel.avlId = 0
    }
    if (!event && this.getAllDropDownModel.avl) {
      this.getAllDropDownModel.avlId = 1
    }
    if (!event && !this.getAllDropDownModel.avl) {
      this.getAllDropDownModel.avlId = 3
    }
    if (event && this.getAllDropDownModel.avl) {
      this.getAllDropDownModel.avlId = 2
    }
  }


}

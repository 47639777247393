export const URLS = {
    companyList: {
        deleteCompany: "app/company/deleteCompanies",
        restoreCompany: "app/company/restoreCompany",
        deleteContact: "app/companyContact/deleteCompanyContact",
        restoreContact: "app/companyContact/restoreContact",
        updateRatingStatusLeadScoreIntendToBuy: "app/company/stageStatusUpdate",
        updateRatingStatus: "app/company/statusUpdate",
        updateOverallRatingStatus: "app/company/ratingsUpdate",
        updateQuickNote: "app/company/quickNotesUpdate",
        updateVendorStatus: "app/vendor/vendorStatusUpdate",
        getAllFilterDropDownList: "app/dropDown/getAllFilterDropDownList",
        insertFilterRecord: "app/company/insertCompanyFilter",
        removeCompanyFilter: "app/company/removeCompanyFilter",
        getActiveCompanyFilterData: "app/company/getCompanyFilterData",
        getUserSetting: "identity/users/getUserSetting",
    },
    archivedCompanies: {
        archiveCompanies: "app/company/archiveCompanies",
        unarchiveCompanies: "app/company/unArchiveCompanies",
    },
    company: {
        getAllCompanies: 'app/company/getAllCompanyWithFilter',
        addAssignee: 'app/company/addAssignee',
        exportMailingList: 'app/company/exportMailingList',
        deleteCompanies: 'app/company/deleteCompanies',
        getCompanyDetailsById: 'app/company/getCompanyDetailsByCompanyId',
        insertCompany: 'app/company/insertCompany',
        updateCompany: 'app/company/updateCompany',
        getAllCompanyAddressContactInfo: 'app/company/getAllCompanyAddressContactInfo',
    },
    dropDown: {
        getAllSalesStageList: 'app/dropDown/getAllSalesStageList',
        getContactByCompanyId: 'app/dropDown/getContactByCompanyId',
        getCompaniesByContactId: 'app/dropDown/getCompaniesByContactId',
        getTagsByStageId: 'app/dropDown/getTagsByStageId',
        getCodeCrosswalkList: 'app/dropDown/getCodeCrosswalkList',
        importCompanyDropDownList: 'app/dropDown/importCompanyDropDownList',
    },
    companyAddress: {
        getCompanyAddressByCompanyId: 'app/companyAddress/getCompanyAddressByCompanyId',
        insertCompanyAddress: 'app/companyAddress/insertCompanyAddress',
        updateCompanyAddress: 'app/companyAddress/updateCompanyAddress',
        deleteCompanyAddress: 'app/companyAddress/deleteCompanyAddress',
    },
    companyContact: {
        getCompanyContactsByCompanyId: 'app/companyContact/getCompanyContactsByCompanyId',
        getCompanyContactByContactId: 'app/companyContact/getCompanyContactByContactId',
        deleteCompanyContact: 'app/companyContact/deleteCompanyContact',
        insertCompanyContact: 'app/companyContact/insertCompanyContact',
        updateCompanyContact: 'app/companyContact/updateCompanyContact',
        contactStatusUpdate: 'app/companyContact/contactStatusUpdate',
    },
    companyHistory: {
        getCompanyHistoryByContactId: 'app/companyHistories/getCompanyHistoryByContactId',
        getAllCompanyHistories: 'app/companyHistories/getAllCompanyHistories',
    },
    vendorCertification: {
        insertVendorCertification: 'app/vendorCertification/insertVendorCertification',
        updateVendorCertification: 'app/vendorCertification/updateVendorCertification',
        deleteVendorCertification: 'app/vendorCertification/deleteVendorCertification',
        getAllVendorCertification: 'app/vendorCertification/getAllVendorCertification',
    },
    companyDocuments: {
        getDocumentByCompanyOrTypeId: 'app/companyDocuments/getDocumentByCompanyOrTypeId',
        deleteDocumentById: 'app/companyDocuments/deleteDocumentById',
        downloadDocuments: 'app/companyDocuments/downloadDocuments',
        uploadCompanyDetailsSettingSectionDocument: 'app/companyDocuments/uploadCompanyDetailsSettingSectionDocument',
        uploadCompanyDocument: 'app/companyDocuments/uploadCompanyDocument',
    },
    manageManufacturer: {
        insertUpdateManageManufacturer: 'app/manageManufacturer/insertUpdateManageManufacturer',
        getAllManageManufacturer: 'app/manageManufacturer/getAllManageManufacturer',
    },
    activityManager: {
        getAllActivities: "app/activityManager/getAllActivities",
        insertComment: "app/activityManager/insertActivityComment",
        updateComment: "app/activityManager/updateActivityComment",
        deleteComment: "app/activityManager/deleteActivityComment",
        markAsComplete: "app/activityManager/markActivityComplete",
        cancelActivity: "app/activityManager/markActivityCancelled",
        snoozeTasks: "app/activityManager/snoozeActivity",
        getActivityCalendarList: "app/activityManager/getActivityCalendarList",
        getAllMentionData: "identity/Users/getUserDetailsForActivityComment",
        insertToDoActivity: "app/activityManager/insertActivity",
        updateToDoActivity: "app/activityManager/updateActivity",
        insertActivityForMultipleCompanies: "app/activityManager/insertActivityForMultipleCompanies",
        insertActivityForMultipleContacts: "app/activityManager/insertActivityForMultipleContacts",
        getAllActivityByContactId: 'app/activityManager/getAllActivityByContactId',
        getAllActivityByCompanyId: 'app/activityManager/getAllActivityByCompanyId',
        getActivityDetailsByActivityId: 'app/activityManager/getActivityDetailsByActivityId',
        getActivityDayWeekMonthWise: 'app/activityManager/getActivityDayWeekMonthWise',
    },
    companyTradeInfo: {
        insertCompanyTradeInfo: 'app/companyTradeInfo/insertCompanyTradeInfo',
        updateCompanyTradeInfo: 'app/companyTradeInfo/updateCompanyTradeInfo',
    },
    companyCreditTerm: {
        insertCompanyCreditTerm: 'app/companyCreditTerm/insertCompanyCreditTerm',
        updateCompanyCreditTerm: 'app/companyCreditTerm/updateCompanyCreditTerm',
    },
    companyTradeReference: {
        insertCompanyTradeReference: 'app/companyTradeReference/insertCompanyTradeReference',
        updateCompanyTradeReference: 'app/companyTradeReference/updateCompanyTradeReference',
    },
    companyFulfillment: {
        insertCompanyFulfillment: 'app/companyFulfillment/insertCompanyFulfillment',
        updateCompanyFulfillment: 'app/companyFulfillment/updateCompanyFulfillment',
    },
    companySetting: {
        getCompanyDetailsSettingsByCompanyId: 'app/companySetting/getCompanyDetailsSettingsByCompanyId',
    },
    vendor: {
        insertVendor: 'app/Vendor/insertVendor',
        updateVendor: 'app/vendor/updateVendor',
    },
    importCompany: {
        deleteTemplateByTemplateId: 'app/importCompany/deleteTemplateByTemplateId',
        getTemplateByTemplateId: 'app/importCompany/getTemplateByTemplateId',
        validateFileWithTemplate: 'app/importCompany/validateFileWithTemplate',
        validateFileHasData: 'app/importCompany/validateFileHasData',
        getHeaderNameListFromFile: 'app/importCompany/getHeaderNameListFromFile',
        importCompanyTemplate: 'app/importCompany/importCompanyTemplate',
        importCompany: 'app/importCompany/importCompany',
    },
    masterCodeCrosswalk: {
        importCodeCrosswalkTemplate: 'app/masterCodeCrosswalk/importCodeCrosswalkTemplate',
        importCodeCrosswalk: 'app/masterCodeCrosswalk/importCodeCrosswalk',
        insertCodeCrosswalk: 'app/masterCodeCrosswalk/insertCodeCrosswalk',
        updateCodeCrosswalk: 'app/masterCodeCrosswalk/updateCodeCrosswalk',
        updateCodeCrosswalkStatus: 'app/masterCodeCrosswalk/updateCodeCrosswalkStatus',
        deleteCodeCrosswalk: 'app/masterCodeCrosswalk/deleteCodeCrosswalk',
    },
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportDocumentsRoutingModule } from './import-documents-routing.module';
import { ImportDocumentsComponent } from '../import-documents/import-documents.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ImportTabComponent } from './pages/import-tab/import-tab.component';
import { MapTabComponent } from './pages/map-tab/map-tab.component';
import { DetailsTabComponent } from './pages/details-tab/details-tab.component';
import { PreviewTabComponent } from './pages/preview-tab/preview-tab.component';
import { ErrorsTabComponent } from './pages/errors-tab/errors-tab.component';
import { SummaryTabComponent } from './pages/summary-tab/summary-tab.component';
import { StepProgressbarComponent } from './pages/step-progressbar/step-progressbar.component';
import { ProgressStepComponent } from './pages/step-progressbar/progress-step/progress-step.component';
import { ProgressStepDirective } from './pages/step-progressbar/progress-step.directive';
import { DuplicatesTabComponent } from './pages/duplicates-tab/duplicates-tab.component';
import { ActionsComponent } from './pages/duplicates-tab/actions/actions.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    ImportDocumentsComponent,
    ImportTabComponent,
    MapTabComponent,
    DetailsTabComponent,
    PreviewTabComponent,
    ErrorsTabComponent,
    SummaryTabComponent,
    StepProgressbarComponent,
    ProgressStepComponent,
    ProgressStepDirective,
    DuplicatesTabComponent,
    ActionsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ImportDocumentsRoutingModule,
  ],
  providers: [
    NgbActiveModal
  ],
  exports:[ImportDocumentsComponent]
})
export class ImportDocumentsModule { }

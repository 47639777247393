import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AgCustomHeaderComponent } from 'src/app/shared/components/ag-grid/ag-custom-header/ag-custom-header.component';
import { AgGridI, AgGridPaginationConfig } from 'src/app/shared/components/ag-grid/ag-grid.model';
import{AgGridImportCodecrosswalkErrors, AgGridImportCompaniesErrors} from "../errors-tab/errors-tab.model"
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { ImportDocumentsService } from '../../import-documents.service';
import { Subscription } from 'rxjs';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { Button, CurrentTab, LabelsName, RoutePath } from 'src/app/shared/model/enum';
import { ToastrService } from 'ngx-toastr';
import { ImportCompanyCommand } from '../../import-documents.model';
import { ColDef } from 'ag-grid-community';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { CodeCrosswalkService } from 'src/app/components/admin/code-crosswalk/code-crosswalk.service';


@Component({
  selector: 'app-errors-tab',
  templateUrl: './errors-tab.component.html',
  styleUrls: ['./errors-tab.component.scss']
})
export class ErrorsTabComponent implements OnInit {
  enumButtonName = Button;
  enumErrorFiledName = LabelsName;
  agGridPaginationConfig = new AgGridPaginationConfig();
  dataModel: AgGridI;
  isDuplicate = false
  defaultView = true
  public components: {
    [p: string]: any;
  } = {
      agColumnHeader: AgCustomHeaderComponent,
    };
    rowData=[];
    flexHeight;
    columnList=[]
    companyData=[]
    sub:Subscription;
    importCompanyCommand= new ImportCompanyCommand();
    private gridApi;
    required = []
    isRecall: boolean = false;
    importError: boolean = false;
    potentialError: boolean = false;
    data:any
    public defaultColDef: ColDef = {
      resizable: true,
    //  maxWidth:250
    };
    overlayNoRowsTemplate = '<div class="ag-overlay-no-rows-center">No Records Available</div>';
    currentTab:string;
    isPreviewTabOpen:boolean=false;
    @ViewChild ("prevCallButton") prevCallButton:ElementRef
    routeUrl:string;
    routePath = RoutePath;
   tabName = CurrentTab;
  constructor(  
      public commonTabService     : CommonTabService,
      private router              : Router,
      public commonService        : CommonService,
      public toaster              : ToastrService,
      public importDocumentsService:ImportDocumentsService,
      public tabsService: TabsService,
      public codeCrosswalkService     :CodeCrosswalkService,

    ) {
      this.routeUrl = this.router.url;
      this.sub= this.importDocumentsService.dataPassAccordingToTrackingBar.subscribe(res=>{
        if(this.importDocumentsService.indexActive==3){
          // this.data= this.importDocumentsService.dataPassAccordingToTrackingBar.getValue();
          this.data = res?.response;
          if(res?.response){
            this.columnList = res?.response?.importError;
            if(res?.response?.importError.length<1 && (this.currentTab == this.tabName.CODECROSSWALK_TAB?(res?.response?.potentialCodeCrosswalk.length<1):(res?.response?.potentialCompanies.length<1))){
              this.isDuplicate = true;
              this.potentialError = true;
              this.defaultView = false;
            }else if(res?.response?.importError.length>0){
             this.defaultView = true;
             this.importError = false;
             this.filllist();
            }else{
              this.importError = true;
              this.filllist();
            }
            // this.errorMessage();
            //   if(this.columnList.length==0){
            //   this.importError = true;
            // }else{
            //   this.importError = false;
            //   this.filllist();
            // }
          }
        }
      })

     }

     ngAfterViewInit() {
      console.log(this.prevCallButton.nativeElement);
      // You can access the button element here
    }
  

     fn(){
        if(this.data?.importError.length<1 && (this.currentTab == this.tabName.CODECROSSWALK_TAB?(this.data?.potentialCodeCrosswalk.length<1):(this.data?.potentialCompanies.length<1))){
          this.isDuplicate = true;
          this.potentialError = true;
          this.defaultView = false;
        }else if(this.data?.importError.length>0){
         this.defaultView = true;
         this.importError = false;
         this.filllist();
        }else{
          this.importError = true;
          this.filllist();
        }
        // this.errorMessage();
        //   if(this.columnList.length==0){
        //   this.importError = true;
        // }else{
        //   this.importError = false;
        //   this.filllist();
        // }
      
     }

  ngOnInit(): void {
    // this.errorMessage();
    this.importDocumentsService.errorValidation.next(true);
    this.commonService.headerHeight.subscribe(res =>{
     // this.flexHeight = "calc(100vh - 270px - " + res +"px" + ")" 
     this.flexHeight = "calc(100vh - 290px - " + res +"px" + ")" 

  })
  this.tabsService.currentTab.subscribe(res => {
    if (res !== undefined) {
      this.currentTab = res;
    } 
  })
  if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
    this.dataModel = AgGridImportCodecrosswalkErrors;
  }else{
    this.dataModel = AgGridImportCompaniesErrors;
  }

  }
  errorMessage(){
    const data=this.importDocumentsService.dataPassAccordingToTrackingBar.getValue();
    if(data?.response){
      if(data?.response?.importError?.length<1 && (this.currentTab == this.tabName.CODECROSSWALK_TAB?(data?.response?.potentialCodeCrosswalk?.length<1):(data?.response?.potentialCompanies?.length<1))){
        this.isDuplicate = true
        this.potentialError = true;
        this.defaultView = false;
        // this.importError = true;
      }else if(data?.response?.importError?.length<1 && (this.currentTab ==this.tabName.CODECROSSWALK_TAB?(data?.response?.potentialCodeCrosswalk?.length>0):(data?.response?.potentialCompanies?.length>0))){
        {
          this.isDuplicate = true
          this.potentialError = false;
        // this.importError = false;
      }
      }else{
        this.isDuplicate=false
        this.defaultView=false;
        this.isPreviewTabOpen = true;
        this.importDocumentsService.errorValidation.next(true);
      }
    }
}

  filllist(){
    this.rowData = this.columnList
  let aa=  this.commonTabService.getAllDropdownList.getValue()
    this.rowData.map((a)=>{
      switch(a.errorField){
        case 'Stage':
        // a.map = this.commonTabService.getAllDropdownList.getValue().typeList.map(a=>a.name);
        a.map = this.commonTabService.getAllDropdownList.getValue().stageList.map(a=>a.name);
        break;
        case "Rating":
        a.map =  ['5','4.5','4','3.5','3','2.5','2','1.5','1','0.5'];
        break;
        case "Active":
        a.map =  ['yes','no'];
        break;
        case this.enumErrorFiledName.STATE:
        a.map = this.commonTabService.getAllDropdownList.getValue().stateList.map(a=>a.name);
        break;
        case 'Type':
        // a.map = this.commonTabService.getAllDropdownList.getValue().classList.map(a=>a.name);
        a.map = this.commonTabService.getAllDropdownList.getValue().typeList.map(a=>a.name);
        break;
        case this.enumErrorFiledName.COUNTRY:
        a.map =  this.commonTabService.getAllDropdownList.getValue().countryList.map(a=>a.name);
        break;
        case this.enumErrorFiledName.SALESPERSON:
        a.map = this.commonTabService.getAllDropdownList.getValue().salesPersonList.map(a=>a.name);
        break;
        case "Alternate Salesperson":
        a.map = this.commonTabService.getAllDropdownList.getValue().altSalesPersonList.map(a=>a.name);
        break;
        case "Time Zone":
          a.map = this.commonTabService.getAllDropdownList.getValue().timezoneList.map(a=>a.name);
        break;
        case "Tags":
          a.map = this.commonTabService.getAllDropdownList.getValue().tagsList.map(a=>a.name);
          break;
          case "Currency":
            a.map = this.commonTabService.getAllDropdownList.getValue().currencyList.map(a=>a.name);
          break;
        case this.enumErrorFiledName.BUYERS_REP:
        a.map = this.commonTabService.getAllDropdownList.getValue().buyerRepList.map(a=>a.name);
        default:

      }
      // if(a.errorField=='Sale Stage')
      // {
      //   a.map = this.commonTabService.getAllDropdownList.getValue().typeList.map(a=>a.name);
      // }
      // a.map = this.columnList.map(a=>a.name);
      a.mappedColumn = '';
      a.status = a.status
    })
  }

  onSelectionChanged($event){}
  closeTab(){
    if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
      this.importDocumentsService.closeVendorTab();
      this.router.navigate([this.routePath.VENDORLIST_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.VENDORLIST_TAB)
    }else{
      this.importDocumentsService.closeTab();
      this.router.navigate([this.routePath.COMPANY_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.COMPANY_TAB)
    }
  }
  close(){ 
    this.codeCrosswalkService.valuePopup.next(true)
   }
  
  applyChanges() {
    // setTimeout(() => {
    //   this.importDocumentsService.applyChanges.next(true);
    // }, 1000);
    const data=this.importDocumentsService.dataPassAccordingToTrackingBar.getValue();
    this.importCompanyCommand.companyData=data?.response?.companyData;
    this.importCompanyCommand.importError=data?.response?.importError;
    this.importCompanyCommand.duplicateCompanies=data?.response?.potentialCompanies;
    this.importCompanyCommand.templateId=data?.response?.templateId;
    if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
      this.importCompanyCommand.isVendor = true;
      }
    this.importDocumentsService.insertImportOnlyCompany(this.importCompanyCommand).subscribe(res=>{
      if(res.isSuccess){
        this.importDocumentsService.applyChanges.next(res);
        this.importDocumentsService.toEnd.next(res);
        this.toaster.success(res.message);
      }else{
        this.toaster.error(res.message);
        this.importDocumentsService.applyChanges.next(res);
        this.importDocumentsService.toEnd.next(res);
      }
    })

  }


duplicateCall(){
  const data=this.importDocumentsService.dataPassAccordingToTrackingBar.getValue();
  const importError = data?.response?.importError.some((x) => x.correctField === '');
  if(importError && !this.importError){
    this.passDataToDetails();
  }else{
    this.defaultView = false;
    this.isDuplicate = true;

    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      if(data?.response?.potentialCodeCrosswalk?.length>0){
        this.potentialError = false;
       }
       if(data?.response?.potentialCodeCrosswalk?.length==0){
        this.potentialError = true;
        // this.applyChanges()
       }
    }else{
      if(data?.response?.potentialCompanies?.length>0){
        this.potentialError = false;
       }
       if(data?.response?.potentialCompanies?.length==0){
        this.potentialError = true;
        // this.applyChanges()
       }
    }
    
  }
}


passDataToDetails() {
  if (this.importDocumentsService.submitted) {
    this.isRecall = false;
    const list = [];
    this.gridApi.forEachNode((a) => {
      list.push(a.data)
    });
    list.map((b) => {
      if (b.correctField === b.companyName) {
        b.status = true
      }
    })
    list.map((a) => {
      if (this.required.includes(a.companyName)) {
        a.isRequired = true;
      } else {
        a.isRequired = false;
      }
    })
    this.importCompanyCommand.importError=list;
    this.importDocumentsService.dataPassAccordingToTrackingBar.next(this.importCompanyCommand)
  } else {
    this.importDocumentsService.errorValidation.next(false);
    this.toaster.error("please insert required field");
  }
}

reloadData(event){
  if(this.data?.importError.length<1 && (this.currentTab == this.tabName.CODECROSSWALK_TAB?(this.data?.potentialCodeCrosswalk.length<1):(this.data?.potentialCompanies.length<1))){
    this.prev();
    setTimeout(() => {
      this.isDuplicate = true;
    this.potentialError=true;
    this.defaultView=false;
    }, 500);
  }else{
    this.importError = false;
     this.defaultView=event;
  this.isDuplicate =!event
  this.potentialError=!event;
  }
  // if(this.columnList.length==0){
  //   this.importError = true;
  // }else{
  //   this.importError = false;
  // }
  // this.defaultView=event;
  // this.isDuplicate =!event
  // this.potentialError=!event;

  // this.importDocumentsService.errorValidation.next(true);
  // this.isDuplicate =!event
  // this.potentialError=!event;
}

prev(){
  if(this.data?.importError.length<1 && (this.currentTab == this.tabName.CODECROSSWALK_TAB?(this.data?.potentialCodeCrosswalk.length<1):(this.data?.potentialCompanies.length<1))){
    this.defaultView=true;
    setTimeout(() => {
      this.prevCallButton?.nativeElement.click();
    }, 200);
    this.isDuplicate=false;
      this.importError=true;
    }
}

previousCall() {
  this.importDocumentsService.errorValidation.next(true);
}
}

import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import flatpickr from 'flatpickr';
import { Options as FlatpickrOptions } from 'flatpickr/dist/types/options';
import { Instance as FlatpickrInstance } from 'flatpickr/dist/types/instance';
import { UserManagementService } from 'src/app/components/admin/user-management/pages/user-management/user-management.service';

@Component({
  selector: 'app-flatpickr-calender',
  templateUrl: './flatpickr-calender.component.html',
  styleUrls: ['./flatpickr-calender.component.scss']
})
export class FlatpickrCalenderComponent implements OnInit {



  @ViewChild('flatpickrInput', { static: true }) flatpickrInput!: ElementRef<HTMLInputElement>;
  private flatpickrInstance!: FlatpickrInstance;
  clearSelectedDate:boolean = false;
  @Input()isActivity:boolean=false;
  selectedDateFormat:string;
  constructor(private renderer: Renderer2,
    public userManagementService :UserManagementService,
    ) { }

  ngOnInit(): void {
    this.initFlatpickr();
  }

  private initFlatpickr(): void {
    let selectedDateStr = "";
    const options: FlatpickrOptions = {
      mode: "range",
      dateFormat: 'm/d/Y' ,//"M d, Y", //"d M Y",
      onClose : (selectedDates:  Date[], dateStr: string, instance: flatpickr.Instance) => {
        console.log(selectedDates, dateStr, instance);
      },
      onChange: (selectedDates, dateStr, instance) => {
        if (Array.isArray(selectedDates) && selectedDates.length >= 2) {
          const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          };
          const startDateStr = selectedDates[0].toLocaleDateString('en-US', options);
          const endDateStr = selectedDates[1].toLocaleDateString('en-US', options);
          console.log(startDateStr, endDateStr);
          this.selectedDateFormat = startDateStr + " - " + endDateStr;

          selectedDateStr = selectedDates[0].toLocaleDateString() + " - " + selectedDates[1].toLocaleDateString();
          if(this.isActivity == true ){
            const truncatedStartDateStr = startDateStr.length > 5 ? (startDateStr.substring(0, 5) + '...') : startDateStr;
            const truncatedEndDateStr = endDateStr.length > 5 ? (endDateStr.substring(0, 5) + '...') : endDateStr;
            selectedDateStr = truncatedStartDateStr + " - " + truncatedEndDateStr;
          }else{
            selectedDateStr = startDateStr + " - " + endDateStr;
          }
        } else {
          selectedDateStr = ""; 
          this.clearSelectedDate = false;
        }
        this.flatpickrInput.nativeElement.value = selectedDateStr;
        if(selectedDateStr !=''){
          this.userManagementService.selectedDateRange.next(this.selectedDateFormat);
          this.clearSelectedDate = true;
        }
        console.log(selectedDates);
      }
    };
    this.flatpickrInstance = flatpickr(this.flatpickrInput.nativeElement, options); 
   }
   openDatepicker(): void {
    // this.flatpickrInstance.open();
    this.renderer.selectRootElement(this.flatpickrInput.nativeElement).click();
  }
  clearSeletedDate(){
    this.flatpickrInstance.clear(); 
    this.flatpickrInput.nativeElement.value = '';
    this.userManagementService.selectedDateRange.next( this.flatpickrInput.nativeElement.value);
    this.clearSelectedDate = false;
  }
}

import { CustomeTooltipComponent } from './components/ag-grid/custome-tooltip/custome-tooltip.component';
import { CommonModule,DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepicker, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { CrmCommonHeaderComponent } from '../components/crm/crm-common-header/crm-common-header.component'
// Header Elements Components
import { SearchComponent } from './components/header/elements/search/search.component';
import { MegaMenuComponent } from './components/header/elements/mega-menu/mega-menu.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { BookmarkComponent } from './components/header/elements/bookmark/bookmark.component';
import { CartComponent } from './components/header/elements/cart/cart.component';
import { MessageBoxComponent } from './components/header/elements/message-box/message-box.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
import {NumberFormatDirective} from './directives/number-format.directive'
import {CommaSeparatedDirective} from './directives/comma-separated.directive'
// Services

import { SearchService } from './services/search.service';
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { AgGridComponent } from './components/ag-grid/ag-grid.component';
import { AgGridColumnsSettingComponent } from './components/ag-grid-columns-setting/ag-grid-columns-setting.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { InputComponent } from './components/input/input.component';
import { MultiselectDropdownComponent } from './components/multiselect-dropdown/multiselect-dropdown.component';
import { SwitchComponent } from './components/switch/switch.component';
import { CommonModelPopUpComponent } from './components/common-model-pop-up/common-model-pop-up.component';
import { SearchInputComponent } from './components/searchInput/search-input/search-input.component';
import { ImageComponent } from './components/image/image/image.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TabsComponent } from './components/tabs/tabs.component';
import { LoginComponent } from '../components/authentication/login/login.component';
import { PhoneFormatDirective } from './directives/phone-format.directive';
import { InputBogieFormatDirective } from './directives/input-bogie-format.directive';
import { AgGridModule } from 'ag-grid-angular';
import { HeaderIconComponent } from './components/ag-grid/header-icon/header-icon.component';
import { CellIconComponent } from './components/ag-grid/cell-icon/cell-icon.component';
import { RatingComponent } from './components/ag-grid/rating/rating.component';
import { AgSwitchComponent } from './components/ag-grid/ag-switch/ag-switch.component';
import { SidebarCellComponent } from './components/ag-grid-columns-setting/sidebar-cell/sidebar-cell.component';
import { AgCustomHeaderComponent } from './components/ag-grid/ag-custom-header/ag-custom-header.component';
import { SidebarNotificationComponent } from './components/sidebar-notification/sidebar-notification.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopulateEnumPipe } from './pipes/populate-enum.pipe';
import { AgDropdownComponent } from './components/ag-grid/ag-dropdown/ag-dropdown.component';
import { IntentToBuyComponent } from './components/ag-grid/intent-to-buy/intent-to-buy.component';
import { IntentToBuyEditorComponent } from './components/ag-grid/intent-to-buy-editor/intent-to-buy-editor.component';
import { CustomLoaderComponent } from './loader/custom-loader/custom-loader.component';
import { CommonUseComponent } from './common/common-use/common-use.component';
import { FilterPipe } from './pipes/filter.pipe';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { CustomTooltipFeature } from 'ag-grid-community';
import { CompanyRendererComponent } from './components/ag-grid/company-renderer/company-renderer.component';
import { HyphenatePipe } from './pipes/hyphenate.pipe';
import { CustomeRatingComponent } from './components/custome-rating/custome-rating.component';
import { VendorRendererComponent } from './components/ag-grid/vendor-renderer/vendor-renderer.component';
import{ OrderDetailsComponent} from '../components/crm/activity-manager/common-activity-cards/order-details/order-details.component'
import { DescriptionComponent } from '../components/crm/activity-manager/common-activity-cards/description/description.component';
import { CommentComponent } from '../components/crm/activity-manager/common-activity-cards/comment/comment.component';
import { ContactCardComponent } from '../components/crm/activity-manager/common-activity-cards/contact-card/contact-card.component';
import { DateRangePikerComponent } from './components/date-range-piker/date-range-piker.component';
import { ScrollSpyDirective } from '../shared/directives/scroll-spy.directive';
import { DatePickerComponent, NgbDateCustomParserFormatter } from './components/date-picker/date-picker.component';
import { FlatpickrCalenderComponent } from './components/flatpickr-calender/flatpickr-calender.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { ActivityCommenHeaderComponent } from './components/activity-commen-header/activity-commen-header.component';
import { TaskListComponent } from './components/sidebar-notification/task-list/task-list.component';
import { AgDropdownCorrectFieldComponent } from './components/ag-dropdown-correct-field/ag-dropdown-correct-field.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { SingleDateSelectPickerComponent } from './components/single-date-select-picker/single-date-select-picker.component';
import { OnlyAlphabetsAndPunctuationDirective } from './directives/only-alphabets-and-punctuation.directive';
import {TabNextDirective} from './directives/tab-next.directive';
//  import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { EmailEditorModule } from 'angular-email-editor';
import { CommonDropdownComponent } from './components/common-dropdown/common-dropdown.component';
import {ClickOutsideDirective} from '../shared/directives/clickOutside.directive';
import { CommonTabComponent } from './components/common-tab/common-tab.component';
import { CommonPartInfoExpandedTabComponent } from './components/common-part-info-expanded-tab/common-part-info-expanded-tab.component';
import { NgbTimepickerComponent } from './components/ngb-timepicker/ngb-timepicker.component'
import { PartsButtonComponent } from './components/parts-button/parts-button.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    TabNextDirective,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    NumberFormatDirective,
    CommaSeparatedDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    AgGridComponent,
    AgGridColumnsSettingComponent,
    ButtonComponent,
    CheckboxComponent,
    DropdownComponent,
    SvgIconComponent,
    InputComponent,
    MultiselectDropdownComponent,
    SwitchComponent,
    CommonModelPopUpComponent,
    SearchInputComponent,
    ImageComponent,
    TabsComponent,
    LoginComponent,
    PhoneFormatDirective,
    InputBogieFormatDirective,
    HeaderIconComponent,
    CellIconComponent,
    RatingComponent,
    AgSwitchComponent,
    SidebarCellComponent,
    AgCustomHeaderComponent,
    SidebarNotificationComponent,
    DragDropDirective,
    FileUploadComponent,
    PopulateEnumPipe,
    AgDropdownComponent,
    IntentToBuyComponent,
    IntentToBuyEditorComponent,
    CustomLoaderComponent,
    CommonUseComponent,
    FilterPipe,
    StarRatingComponent,
    CustomeTooltipComponent,
    CompanyRendererComponent,
    HyphenatePipe,
    CustomeRatingComponent,
    CrmCommonHeaderComponent,
    VendorRendererComponent,
    OrderDetailsComponent,
    DescriptionComponent,
    CommentComponent,
    ContactCardComponent,
    DateRangePikerComponent,
    ScrollSpyDirective,
    DatePickerComponent,
    FlatpickrCalenderComponent,
    TimepickerComponent,
    ActivityCommenHeaderComponent,
    TaskListComponent,
    AgDropdownCorrectFieldComponent,
    CustomSelectComponent,
    InputSearchComponent,
    SingleDateSelectPickerComponent,
    OnlyAlphabetsAndPunctuationDirective,
    CommonDropdownComponent,
    ClickOutsideDirective,
    CommonTabComponent,
    CommonPartInfoExpandedTabComponent,
    NgbTimepickerComponent,
    PartsButtonComponent
],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbDatepickerModule,
    DragulaModule.forRoot(),
    TranslateModule,
    ColorPickerModule,
    AgGridModule,
    TabsModule,
    EmailEditorModule
    //  FroalaEditorModule.forRoot(), FroalaViewModule.forRoot()
  ],
  providers: [
    NavService,
    LayoutService,
    SearchService,
    DatePipe,
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ],
  exports: [
    NgbModule,
    NgbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    TabNextDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    NumberFormatDirective,
    CommaSeparatedDirective,
    PhoneFormatDirective,
    InputComponent,
    SvgIconComponent,
    CheckboxComponent,
    DropdownComponent,
    SearchInputComponent,
    SwitchComponent,
    ButtonComponent,
    MultiselectDropdownComponent,
    AgGridComponent,
    AgGridColumnsSettingComponent,
    HeaderIconComponent,
    CellIconComponent,
    RatingComponent,
    AgSwitchComponent,
    AgCustomHeaderComponent,
    SidebarNotificationComponent,
    FileUploadComponent,
    TabsModule,
    LoginComponent,
    PopulateEnumPipe,
    IntentToBuyComponent,
    CustomLoaderComponent,
    FilterPipe,
    HyphenatePipe,
    StarRatingComponent,
    AgGridModule,
    CustomeRatingComponent,
    CrmCommonHeaderComponent,
    OrderDetailsComponent,
    DescriptionComponent,
    CommentComponent,
    ContactCardComponent,
    DateRangePikerComponent,
    ScrollSpyDirective,
    DatePickerComponent,
    FlatpickrCalenderComponent,
    TimepickerComponent,
    ActivityCommenHeaderComponent,
    CustomSelectComponent,
    InputSearchComponent,
    SingleDateSelectPickerComponent,
    OnlyAlphabetsAndPunctuationDirective,
    EmailEditorModule,
    ClickOutsideDirective,
    CommonTabComponent,
    CommonPartInfoExpandedTabComponent,
    NgbTimepickerComponent,
    PartsButtonComponent
    // FroalaEditorModule,
    // FroalaViewModule

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SharedModule { }

import { PaginationModel } from "../../../../../shared/model/pagination.model";

export class UserManagement {
}

export class SystemSetting
{
    primaryMenuColorVm = new PrimaryMenuColor();
}
export class PrimaryMenuColor
{
    primaryBgColor:string;
    primaryTextColor:string;
    primaryHoverActiveColor:string;
    primaryIconColor:string;
}

//region start Addedit user Model
// export class CreateNewUserModel 
//   {
//     firstName               : string;
//     lastName                : string;
//     email                   : string;
//     phoneNumber             : string;
//     username                : string;
//     password                : string;
//     confirmPassword         : string;
//     title                   : string;
//     suiteApt                : string;
//     roleName                : any[];
//     city                    : string;
//     state                   : string;
//     country                 : string;
//     zipCode                 : string;
//     saleQuota               : string;
//     saleCommissionRate      : string;
//     isProfitBasedCommission : boolean;
//     purchaseQuota           : string;
//     purchaseCommissionRate  : string;
//     profilePictureFile      : string;
//     coverPictureUrl         :string;
//     emergencyContactName    : string;
//     directPhoneNumber       : string;
//     numberExtension         : string;
//     emergencyContactNumber  : string;
//     officePhoneNumber       : string;
//     userId                  : string;
//     id                      : string;
//     description             : string;
//     street                  : string;
//     userStatus              : boolean;
//     imageZoomRatio          : number;
//     eligibleForSalesCommission  :boolean = false;
//     eligibleForPurchaseCommission:boolean = false
//   }
  export class CreateNewUserModel {
    userId                         : string;
    firstName                      : string;
    lastName                       : string;
    title                          : string;
    workEmail                      : string;
    userName                       : string;
    officePhoneNumber              : string;
    officePhoneExt                 : string;
    directPhoneNumber              : string;
    mobilePhoneNumber              : string;
    location                       : string;
    salesCommissionRate            : string;
    purchaseCommissionRate         : string;
    profilePictureUrl:string
    isProfileChanged:boolean = false
    saleBogie                      : string;
    purchaseBogie                  : string;
    emergencyContactName           : string;
    emergencyContactNumber         : string;
    eligibleForSaleCommission      : boolean = false;
    eligibleForPurchaseCommission  : boolean = false;
    profileImgPath                 : string;
    imageZoomRatio                 : number;
    roleNames                      :any[];
  }
  export class  RoleListModel {
    roleName                : string
    roleId                  : string
    isActive                : boolean
    totalUsers              : string
    allUsers                : number
    selected                : boolean=false
  }
  //region end

export class UserRequestModel extends PaginationModel {
    date        : string='';
    id          : string='';
    userstatus  : number=1;
    roleId      : string='';
 //   exportAsPdf : boolean=true;
   //  selectedId  : string[ ];
  }

  export class RoleRequestModel extends PaginationModel{
    id          : string='';
    userstatus  : number=1;
    selectedId  : string[ ];
    roleId      : string='';
 //   exportAsPdf : boolean;
//  date        : string='';
  }

 export class UpdateUserStatus{
  userId                  : string;
  userStatus              : boolean;
  }
  // for reset password......
  export class  RestPasswordRequestmodel 
  {
    email                 :  string;
    newPassword           :  string;
    confirmNewPassword    :  string;
    throughEmail          :  boolean=false;
}

export class AssignCommissionRoleModel{
  
    userIds                 : string[];
    saleCommissionId        : string;
    purchaseCommissionId    : string;
  
}

export class AssignCommissionVm{
  saleCommissionsList: CommonCommissionsList[]=[];
  purchaseCommissionsList: CommonCommissionsList[]=[];
}

export class CommonCommissionsList{
  id        : string;
  name      : string;
  labelName : string;
  isActive  : boolean;
  colorCode : string;
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { TablePaginationModel, UpdateCellData, UpdateCellDataIntentToBuy, UpdateOverallRatingStatus, UpdateQuickNotes, UpdateStatus, UpdateVendorStatus } from 'src/app/shared/components/ag-grid/ag-grid.model';
import { Result } from 'src/app/shared/model/response.model';
import { DataService } from 'src/app/shared/services/data.service';
import { DeleteCompany, DeleteContactOrCompanyModel, GetAllDropDownModel, RemoveCompanyFilterModel } from './companies/pages/companies/companies.model';
import { Operations } from 'src/app/shared/model/filter.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Router } from '@angular/router';
import {URLS }   from 'src/app/components/crm/crm-api-constants';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  dropdownAllList = new BehaviorSubject(undefined);

  constructor(private service:DataService,
    public router: Router, 
    public storageService:StorageService) { }
  
  public isSelected = new Subject<boolean>();
  public swichChanged = new Subject<boolean>();
  public isActive = new Subject<boolean>();
  public isRating = new Subject<boolean>();
  public sendImportId = new Subject<number>()

//   archivedCompanies(data:DeleteCompany): Observable<Result> {
//     data.userId   = this.storageService.retrieve('userId');
//    let url = "app/Company/archiveCompanies";
//    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
//    return response;
//      })
//    );
//  }
//  unArchivedCompanies(data:DeleteCompany): Observable<Result> {
//   data.userId   = this.storageService.retrieve('userId');
//  let url = "app/Company/unArchiveCompanies";
//  return this.service.post(url, data).pipe<Result>(tap((response: any) => {
//  return response;
//    })
//  );
// }


  deleteCompany(data:DeleteCompany): Observable<Result> {
     data.userId   = this.storageService.retrieve('userId');
    let url = URLS.companyList.deleteCompany;
    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }

  // deleteCompanyPermanently(companyIds): Observable<Result> {
  //   let url = "SourceMatrixApi/Companies/DeleteCompanyPermanently";
  //   return this.service.post(url, companyIds).pipe<Result>(
  //     tap((response: any) => {
  //       return response;
  //     })
  //   );
  // }

  restoreCompany(companyIds): Observable<Result> {
    let url = URLS.companyList.restoreCompany;
    return this.service.post(url, companyIds).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }

  deleteContact(data:DeleteContactOrCompanyModel): Observable<Result> {
    let url = URLS.companyList.deleteContact;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  

  restoreContact(contactId): Observable<Result> {
    let url = URLS.companyList.restoreContact;
    return this.service.post(url, contactId).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }




  updateRatingStatusLeadScoreIntendToBuy(data:UpdateCellDataIntentToBuy): Observable<Result>{
    let url = URLS.companyList.updateRatingStatusLeadScoreIntendToBuy;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  updateRatingStatus(data:UpdateStatus): Observable<Result>{
    let url = URLS.companyList.updateRatingStatus;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  updateOverallRatingStatus(data:UpdateOverallRatingStatus): Observable<Result>{
    data.currentUrl = this.router.url;
    let url = URLS.companyList.updateOverallRatingStatus;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  updatequicknote(data:UpdateQuickNotes): Observable<Result>{
    let url = URLS.companyList.updateQuickNote;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateVendorStatus(data:UpdateVendorStatus): Observable<Result>{
    let url = URLS.companyList.updateVendorStatus;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }


  public getAllFilterDropDownList(): Observable<Result> {
    let url = `${URLS.companyList.getAllFilterDropDownList}?UserId=${this.storageService.retrieve('userId')}`;
    return this.service.get(url, '').pipe<Result>(tap((response: any) => {
    return response;
    })
    );
  }

  public insertFilterRecord(getAllDropDownModel:GetAllDropDownModel){
    let url = URLS.companyList.insertFilterRecord;
    getAllDropDownModel.userId=this.storageService.retrieve('userId');
    return this.service.post(url, getAllDropDownModel).pipe<Result>(tap((response: any) => {
    return response;
    })
    );
  }

  
  removeCompanyFilter(removeCompanyFilterModel:RemoveCompanyFilterModel){
    removeCompanyFilterModel.userId=this.storageService.retrieve('userId')
    let url=URLS.companyList.removeCompanyFilter;
    return this.service.post(url,removeCompanyFilterModel,true).pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }

 

  public getActiveCompanyFilterData():Observable<Result>{
    const userId=this.storageService.retrieve('userId');
    let url=`${URLS.companyList.getActiveCompanyFilterData}?UserId=${userId}`;
    return this.service.get(url,true).pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }

  public modifyFilter(filters, data, field, operation) {
    if (!data && field != 'companyStatusId'&& field != 'avlId') {
      return filters;
    }
    var group, values;
    if (operation === null || operation === undefined || operation === "") {
      operation = Operations.eq;
    }
    if (field == "PtrTrans") {
      if (data !== null && data !== undefined && data !== "") {
        filters.rules.push({
          data: data,
          op: Operations.eq,
          field: field
        });
      }
    }
    else {
      if (field === "toDate" || field === "fromDate" || field === "createdAt" ||  field ==="endRating" || field ==="companyStatusId"
      || field ==="startRating"  || field === "isActive" ||field === "archived" || 'excludedFromVendorlist' || 'AVL' || 'NAVL') {
        if (data !== null && data !== undefined && data !== "") {
          filters.rules.push({
            data: data,
            op: operation,
            field: field
          });
        }
      } else {
        values = data?.split(",");
        if (values.length <= 1) {
          if (values[0] !== "") {
            data = values[0];
            if (data !== null && data !== undefined) {
              filters.rules.push({
                data: data,
                op: operation,
                field: field
              });
            }
          }
        } else {
          group = {
            groupOp: "OR",
            rules: []
          };
          filters.groups.push(group);
          for (var i = 0; i < values.length; i++) {
            var company = values[i];
            if (company !== null && company !== undefined && company !== "") {
              group.rules.push({
                data: company,
                op: operation,
                field: field
              });
            }
          }
        }
      }
    }
    return filters;
  }

  getUserSetting(): Observable<Result> {
    const userId = this.storageService.retrieve('userId')
    let url = `${URLS.companyList.getUserSetting}?userId=${userId}`;
    return this.service.get(url, true).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }
}

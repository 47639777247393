import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {
  @Input()data :any;
  constructor() { }

  ngOnInit(): void {
    this.data;
  }
  formatComment(text: string): string {
    const paragraphs = text?.split(/\n\s*\n/);
  const formattedParagraphs = paragraphs?.map((paragraph, index) => {
    return `<div class="textColorClass" >${paragraph}</div><br>`;
  });

  return formattedParagraphs?.join('');
  }

}

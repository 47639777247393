import { AgColDefsI, AgGridI,} from "../../../../shared/components/ag-grid/ag-grid.model";
import { DocumentCellRendererComponent } from "src/app/components/crm/companies/pages/company-info-tabs/document-cell-renderer/document-cell-renderer.component";
import { AgDropdownCorrectFieldComponent } from "src/app/shared/components/ag-dropdown-correct-field/ag-dropdown-correct-field.component";



const RowNumberColumn: AgColDefsI = {
  headerName: "Row No",
  field: "rowNumber",
  minWidth: 100,
  width:110,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
 // cellClass: "padding-left-38px",
 cellStyle:{'padding-left':"35px",'color':'#646E76'},
 // flex:1,
  //  cellRenderer: DocumentCellRendererComponent
};


const ColumnNameColumn: AgColDefsI = {
  headerName: "Column Name",
  field: "errorField",
  minWidth: 250,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",
  'color':'var(--sitewide_primary_accent_color)',
},
  //cellClass: "padding-left-38px",
  //  cellRenderer: DocumentCellRendererComponent
};

const CompanyNameColumn: AgColDefsI = {
  headerName: "Company Name",
  field: "companyName",
  minWidth: 250,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",
  'color':'var(--sitewide_primary_accent_color)',
},
  //cellClass: "padding-left-38px",
  //  cellRenderer: DocumentCellRendererComponent
};
const ErrorFieldColumn: AgColDefsI = {
  headerName: "Error Field",
  field: "errorData",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  //  cellRenderer: DocumentCellRendererComponent
};

const ErrorFieldCompanyColumn: AgColDefsI = {
  headerName: "Error Field",
  field: "errorField",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  //  cellRenderer: DocumentCellRendererComponent
};

const ErrorDataColumn: AgColDefsI = {
  headerName: "Error Data",
  field: "errorData",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  //  cellRenderer: DocumentCellRendererComponent
};


const CorrectFieldColumn: AgColDefsI = {
  headerName: "Correct Field",
  field: "correctField",
  minWidth: 300,
  width:300,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  //cellClass: "padding-left-38px",
   cellRenderer: AgDropdownCorrectFieldComponent,
};

const ErrorMessageColumn: AgColDefsI = {
  headerName: "Error Message",
  field: "errorMessage",
  minWidth: 350,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  //  cellRenderer: DocumentCellRendererComponent
};

const SuccessSvgColumn: AgColDefsI = {
  headerName: "",
  field: "status",
  minWidth: 100,
  width:100,
  lockPinned: true,
  suppressMovable: true,
 // flex:1,
  cellStyle:{'padding-left':"22px"},
  cellRenderer: DocumentCellRendererComponent,
//   cellRenderer: function(params) {
//     return `<span> <img src='../../../../assets/SVGIcons/${params.value == true ? 'check-mark (1)' : 'Group 41672'}.svg'> </span>`
// }
  
};



export enum ErrorFiledName
{
  SALE_STAGE      = 'Sale Stage',
  STATE           = 'State',
  COUNTRY         = 'Country',
  SALESPERSON     = 'Salesperson',
  ALT_SALESPERSON = 'Alt Salesperson',
  BUYERS_REP      = 'Buyer’s Rep'
}

export const ColDefsDocumentsCode: AgColDefsI[] = [
  RowNumberColumn,
  ColumnNameColumn,
  ErrorFieldColumn,
  CorrectFieldColumn,
  ErrorMessageColumn,
  SuccessSvgColumn,
];

export const ColDefsDocuments: AgColDefsI[] = [
  RowNumberColumn,
  CompanyNameColumn,
  // ErrorFieldColumn,
  ErrorFieldCompanyColumn,
  ErrorDataColumn,
  CorrectFieldColumn,
  ErrorMessageColumn,
  SuccessSvgColumn,
];

export const AgGridImportCompaniesErrors: AgGridI = {
  colDefs: ColDefsDocuments,
  rowSelection: "multiple",
};
export const AgGridImportCodecrosswalkErrors: AgGridI = {
  colDefs: ColDefsDocumentsCode,
  rowSelection: "multiple",
};


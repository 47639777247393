import { Injectable } from '@angular/core';
import { StorageService } from '../../../shared/services/storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserSetting } from 'src/app/components/crm/crm.model';
import { SaveUserSetting } from '../ag-grid/ag-grid.model';
import { AgGridService } from '../ag-grid/ag-grid.service';
@Injectable({
  providedIn: 'root'
})
export class DockService {
  pinnedlist = []
  userSetting = new UserSetting();
  saveUserSetting = new SaveUserSetting();
  constructor(private storageService: StorageService,
    public toaster: ToastrService,
    public agGridService: AgGridService,
    private router: Router) { }

  addFavouriteDocks(icon, pinned, title) {
    const pinnedValues = this.storageService.retrieve('pinnedValues') ?? [];
    pinnedValues.push({ path: this.router.url.replace('/', ''), icon: icon, title: title, pinned: pinned })
    this.storageService.store('pinnedValues', pinnedValues)
    this.pinnedlist = pinnedValues;
    this.userSetting = this.storageService.retrieve('userSetting')
    this.userSetting.pinnedValues = pinnedValues;
    this.saveUserSetting.userSetting = JSON.stringify(this.userSetting);
    this.agGridService.saveUserSetting(this.saveUserSetting).subscribe(res => {
      const json = JSON.parse(res?.response);
      this.storageService.store("userSetting", json)
    })
    this.toaster.success("This page has been added to your Favorites Dock.");
  }

  removeFavouriteDocks() {
    const pinnedValues = this.storageService.retrieve('pinnedValues') ?? [];
    const index: number = pinnedValues.findIndex(x => x.path === this.router.url.replace('/', ''));
    if (index !== -1) {
      pinnedValues.splice(index, 1);
    }
    this.storageService.store('pinnedValues', pinnedValues);
    this.pinnedlist = pinnedValues;
    this.userSetting = this.storageService.retrieve('userSetting')
    this.userSetting.pinnedValues = pinnedValues
    this.saveUserSetting.userSetting = JSON.stringify(this.userSetting);
    this.agGridService.saveUserSetting(this.saveUserSetting).subscribe(res => {
      const json = JSON.parse(res?.response);
      this.storageService.store("userSetting", json)
    })
    this.toaster.success("This page has been removed from your Favorites Dock.");
  }
}


 <div class="sideBarContainer" >
    <div class="w-100 sideBar">
        <tabset #tabset>
            <tab heading="Notifications" (selectTab)="changeTab($event)">
                <div class="">
                    <div class="d-flex justify-content-between font-13 mx-3 my-2">
                        <span class="text-info-color ps-md-2 ms-md-4 ps-xl-0 ms-xl-0">See All Notifications</span>
                        <span class="text-info-color">Mark All as Read</span>
                    </div>
                    <div class="notificationList"  [style]="{'height': flexHeight}">
                        <div class="text-center b_radius_24 mx-xl-3 ms-md-5 m-b-10 me-md-3 px-3 py-2 bg-light-color"  *ngFor="let item of notificationList ; ">
                            <div class="row mx-0 mb-1" >
                                <div class="d-flex justify-content-between col  mb-1 p-0" >
                                    <span class="text-primary-color font-14">{{item.name}}</span>
                                    <span class="text-danger-color align-self-center  f-11">{{item.time}}</span>
                                </div>
                                <div class="text-start text-light-gray line-h-1 font-11 p-0">
                                    <span class="break d-block ">
                                        {{item.message}}
                                    </span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab  heading="Tasks" (selectTab)="changeTab($event)">
                <div class="">
                    <div class="text-start mx-3 font-13 my-2">
                        <span class="text-info-color ps-md-2 ms-md-4 ps-xl-0 ms-xl-0 hand" (click)="openActivityManager()">See All Tasks</span>
                    </div>
                    <div class="taskList"  [style]="{'height': flexHeight}" (scroll)="scrollHandler($event)"  #contentWrapper>
                        <div  *ngFor="let task of tasksList">
                            <app-task-list
                            [data]="task" 
                            [tasksList]="tasksList"
                            [iconHide]="false" 
                            (orderId)="getDescription($event)"
                            [getclass]="{'slected-div': task.id == selecteddata}"
                            [tooltipPosition]="isOverflowed"
                            ></app-task-list>
                        </div>
                            <div *ngIf="tasksList?.length === 0" class=" text-center  bg-light-color mx-xl-3 ms-md-5 me-md-3 m-b-10 popup-width">
                                <div class="font-12 color-dark-gray">You have completed</div>
                                <div class="font-12 mb-3 color-dark-gray">Today’s scheduled tasks.</div>
                                <div class="font-12 color-dark-gray">Please go to the Activity Manager to view all tasks.</div>
                          </div>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>
   </div>
<div class="closeSidebar">
    <app-svg-icon  (click)="toggelNotificationSideBar()" [src]="'./assets/SVGIcons/crossCircleIcon.svg'"></app-svg-icon>
</div>
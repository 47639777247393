<app-switch
  class="h-100 position-relative"
  (switchValueEmit)="checkedHandler($event)"
  [ngClass]="{
    'top-2px': !params.data.isExpandable ||  params.data.isExpandable,
    'centerDiv':currentTab == enumTabNames.CODECROSSWALK,
    'pe-none':
      currentTab == enumTabNames.CONTACT_RECYCLEBIN ||
      currentTab == enumTabNames.COMPANY_RECYCLEBIN ||
      currentTab == enumTabNames.IMPORT_COMPANIES
  }"
  [isEditable]="false"
  [status]="params.value"
></app-switch>
<!-- 'top-6px': params.data.isExpandable, -->

<div class="container-fluid container-box">
  <div  *ngIf="potentialError" [style]="{'height': messageflexHeight}">
    <div class="row">
      <div class="wrapper ipadWrapper">
        <div *ngIf="!importError" class="footer-box justify-content-start">
          <span  class="text-semi-primary no-error">No potential duplicates detected.</span>
      </div>
      <div *ngIf="importError" class="footer-box justify-content-start">
        <span  class="text-semi-primary no-error">No errors detected.</span>
    </div>
    </div>
   </div>
  </div>
<div *ngIf="!potentialError">
  <div class="my-1 mx-3">
    <p class="text-primary-color"> Potential Duplicates</p>
    <span class="font-11 text-gray">
      A record(s) you added looks like a duplicate. Do you want to proceed with adding this record(s)?</span>
  </div>
<ng-container *ngIf="data?.length !== 0">
  <div class="overflow-auto" [style]="{'height': flexHeight}">
    <div class="" *ngFor="let item of data;let i=index">
      <div class="my-2 mx-3 text-semi-primary font-13">
        Duplicate {{i+1}}
      </div>
      <ag-grid-angular [style]="{height:this.currentTab == tabName.CODECROSSWALK_TAB?(item?.duplicateCodeCrosswalk?.length*42+60+'px'):(item?.duplicateCompanies?.length*42+60+'px')}" class=" ag-theme-alpine" [rowData]="this.currentTab == tabName.CODECROSSWALK_TAB?(item?.duplicateCodeCrosswalk):(item?.duplicateCompanies)"
        [components]="components" [rowSelection]="dataModel.rowSelection" [suppressRowClickSelection]="true"
        [suppressDragLeaveHidesColumns]="true" [columnDefs]="dataModel.colDefs" [pagination]="true"
        [defaultColDef]="defaultColDef"
        (gridReady)="onGridReady($event)"
        [suppressPaginationPanel]="true"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        >
      </ag-grid-angular>
    </div>
  </div>
</ng-container>
  <div *ngIf="data?.length === 0" class="text-center position-relative top-50">
    No Records Available
        </div>
      </div>
  <div class="row position-relative mt-3 mx-2">
    <div *ngIf="!potentialError" class="col-4 position-absolute">
      <div class="font-11 text-red fw-bold">You have {{data?.length}} potential duplicates.</div>
      <div class="font-11 text-light-gray">Potential duplicates do not get imported unless Import is checked.</div>
    </div>
    <div class="wrapper">
      <div class="footer-box">
        <app-button *ngIf="currentTab !== tabName.CODECROSSWALK_TAB"
         class="px-2" [button_2]="true" [buttonClass]="'button button-info mx-0'"
          [buttonLable]="enumButtonName.CANCEL" (click)="closeTab()">
        </app-button>
        <app-button *ngIf="currentTab === tabName.CODECROSSWALK_TAB"
        class="px-2" [button_2]="true" [buttonClass]="'button button-info mx-0'"
         [buttonLable]="enumButtonName.CANCEL" (click)="close()">
       </app-button>
        <app-button
        (click)="navigateToErrorTab()"
       class="px-2"
       [button_2]="true"
       [buttonClass]="'button  dark-info mx-0'"
       [buttonLable]="enumButtonName.PREVIOUS"
      >
        
      </app-button>
        <app-button  class="ps-2 pe-10" [button_2]="true"
         [buttonClass]="'button  dark-info mx-0'"
         [buttonClass]="(!potentialError)?'button button-primary mx-0':'button dark-info mx-0'"
          [buttonLable]="enumButtonName.COMPLETEIMPORT" (click)="moveToEnd()">
        </app-button>
      </div>
    </div>
  </div>
</div>
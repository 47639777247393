<div class="container-fluid container-box">
  <div>
    <span 
     *ngIf="currentTab !== tabName.CODECROSSWALK_TAB && routeUrl !== routePath.IMPORTVENDOR_ROUTEPATH"
      class="font-11 text-light-gray">
      <i class="fst-normal ps-2 p-0 text-danger">*</i>Properties required to create company: Company ,Stage ,Type ,Country .
    </span>
    <span *ngIf="currentTab == tabName.CODECROSSWALK_TAB" class="font-11 text-light-gray">
        <i class="fst-normal ps-2 p-0 text-danger">*</i>Properties required.
    </span>
    <span 
      *ngIf="routeUrl == routePath.IMPORTVENDOR_ROUTEPATH" 
       class="font-11 text-light-gray">
          <i class="fst-normal ps-2 p-0 text-danger">*</i>
          Properties required to create vendor: Vendor Name, Type, Country.
    </span>
  
  </div>
  <div class="ps-2">
    <ag-grid-angular
    [style]="{'height': flexHeight}"
    class="ag-theme-alpine"
    [rowData]="rowData"
    [components]="components"
    [rowSelection]="dataModel.rowSelection"
    [suppressRowClickSelection]="true"
    [suppressDragLeaveHidesColumns]="true"
    [columnDefs]="dataModel.colDefs"
    [pagination]="true"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged($event)"
    [suppressPaginationPanel]="true"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  >
</ag-grid-angular>

  </div>
  <div class="row ps-2 mt-2">
    <div class="col-4">
      <div class="font-11 text-red fw-bold">You have {{importDocumentsService.count}} unmapped columns</div>
      <div  class="font-11 text-light-gray">Unmapped columns do not get imported.</div>
    </div>
    <div class="col-8 d-flex justify-content-end">
          <app-button *ngIf="currentTab !== tabName.CODECROSSWALK_TAB "
           class="px-2"
           [button_2]="true"
           [buttonClass]="'button button-info mx-0'"
           [buttonLable]="enumButtonName.CANCEL"
           (click)="closeTab()"
           >
          </app-button>
          <app-button *ngIf="currentTab === tabName.CODECROSSWALK_TAB "
          class="px-2"
          [button_2]="true"
          [buttonClass]="'button button-info mx-0'"
          [buttonLable]="enumButtonName.CANCEL"
          (click)="close()"
          >
         </app-button>
          <app-button
            progressStepPrev
           class="px-2"
           [button_2]="true"
           [buttonClass]="'button  dark-info mx-0'"
           [buttonLable]="enumButtonName.PREVIOUS"
          >
          </app-button>
          <app-button
          *ngIf="!importDocumentsService.submit"
            class="ps-2 pe-0"
            (click)="passDataToDetails()"
            [button_2]="true"
            [buttonClass]="'button  dark-info mx-0'"
            [buttonLable]="enumButtonName.NEXT"
            >
         </app-button>
          <app-button
          *ngIf="importDocumentsService.submit"
            progressStepNext
            class="ps-2 pe-0"
            (click)="passDataToDetails()"
            [button_2]="true"
            [buttonClass]="importDocumentsService.submit?'button button-primary mx-0':'button  dark-info mx-0'"
            [buttonLable]="enumButtonName.NEXT"
            >
         </app-button>
    </div>
  </div>
</div>

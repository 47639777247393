<div class="card bg-semi-light m-0">
  <div class="card-body p-3">
    <div class="row">
      <div class="col-4">
        <ul class="d-flex flex-column gap-2">
          <li>
            <p class="title f-10 text-light-info">Email</p>
            <span class="f-11 text-info-color text-truncate">
              {{ data?.email == "" ? "-" : data?.email }}
            </span>
          </li>
          <li>
            <p class="title f-10 text-light-info">Main Office Number + Ext</p>
            <span class="f-11 text-info-color text-truncate">
              <!-- {{ data?.mainOfficeNumber == "" ? "-" : data?.mainOfficeNumber }} -->
              {{ data?.mainOfficeNumber !== undefined && data?.mainOfficeNumber !== null && data?.mainOfficeNumber !== '' ? data?.mainOfficeNumber : '-' }}
            </span>
          </li>
          <li>
            <p class="title f-10 text-light-info">Mobile Number</p>
            <span class="f-11 text-info-color text-truncate">
              <!-- {{ data?.mobileNumber == "" ? "-" : data?.mobileNumber }} -->
              {{ data?.mobileNumber !== undefined && data?.mobileNumber !== null && data?.mobileNumber !== '' ? data?.mobileNumber : '-' }}
            </span>
          </li>
        </ul>
      </div>
      <div class="col-4 ">
        <ul class="d-flex flex-column gap-2">
          <li>
            <p class="title f-10 text-light-info">Direct Number</p>
            <span class="f-11 text-info-color text-truncate">
              <!-- {{ data?.directNumber == "" ? "-" : data?.directNumber }} -->
              {{data?.directNumber !== undefined && data?.directNumber !== null && data?.directNumber !== '' ? data?.directNumber :'-'}}
            </span>
          </li>
          <li>
            <p class="title f-10 text-light-info">Location</p>
            <span class="f-11 text-info-color my-auto">
              <!-- {{ data?.location == "" ? "-" : data?.location }} -->
              {{data?.location !== undefined && data?.location !== null && data?.location !== '' ? data?.location : '-'}}
            </span>
          </li>
          <li >
            <p class="title f-10 text-light-info">Time Zone</p>
            <p class="f-11 text-info-color text-truncate">
              {{ data?.timeZone == "" ? "-" : data?.timeZone }}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-4">
        <ul class="d-flex flex-column gap-2">
          <li>
            <p class="title f-10 text-light-info">Address</p>
            <p
              class="f-11 text-info-color fst-italic"
              ngbTooltip="{{ data?.address }}"
            >
              <!-- {{data?.address==''?'-':data?.address}} -->
              {{
                data?.address?.split(",")?.join("\n") == ""
                  ? "-"
                  : data?.address.split(",").join("\n")
              }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SvgPreloadService {

  private cache: { [key: string]: string } = {};

  constructor(private http: HttpClient) { }

  preloadSvg(url: string): Promise<string> {
    if (this.cache[url]) {
      return Promise.resolve(this.cache[url]);
    } else {
      return this.http.get(url, { responseType: 'text' }).toPromise()
        .then(svg => {
          this.cache[url] = svg;
          return svg;
        });
    }
  }

}

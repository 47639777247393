import { Component, Input, OnInit } from '@angular/core';
import { Button } from 'src/app/shared/model/enum';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-record-added',
  templateUrl: './record-added.component.html',
  styleUrls: ['./record-added.component.scss']
})
export class RecordAddedComponent implements OnInit {
  enumButtonName = Button;
  @Input() record: string;

  constructor(public  activeModal:NgbActiveModal, 
              public modalService: NgbModal,
    ) { }


  ngOnInit(): void {
  }
  applyChanges(){}
}

import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs';
import { CommonDropdownModel, TypeList } from 'src/app/components/crm/companies/pop-ups/add-company/add-company.model';
import {CommonTabService} from 'src/app/shared/services/common-tab.service'
import { Result } from '../../model/response.model';

@Component({
  selector: 'app-common-use',
  template: `
    <p>
      common-use works!
    </p>
  `,
  styles: [
  ]
})
export class CommonUseComponent implements OnInit {
  timezoneList                    : CommonDropdownModel[] = [];
  classList                       : CommonDropdownModel[] = [];
  countryList                     : CommonDropdownModel[] = [];
  salesPersonList                 : CommonDropdownModel[] = [];
  altSalesPersonList              : CommonDropdownModel[] = [];
  buyerRepList                    : CommonDropdownModel[] = [];
  tagsList                        : CommonDropdownModel[] = [];
  intendToBuyList                 : CommonDropdownModel[] = [];
  leadsList                       : CommonDropdownModel[] = [];
  saleStageList                   : TypeList[] = [];

  constructor(public commonTabService:CommonTabService) {}
  ngOnInit(): void {
  }

  getAllDropdownListData(){
    this.commonTabService.getAllFilterDropDownList().pipe(catchError((err) => this.handleError(err))).subscribe((res:Result) => {
      this.timezoneList           = res.response.timezoneList;
      this.countryList            = res.response.countryList;
      this.tagsList               = res.response.tagsList;
      this.classList              = res.response.classList;
      this.salesPersonList        = res.response.salesPersonList;
      this.altSalesPersonList     = res.response.altSalesPersonList;
      this.buyerRepList           = res.response.buyerRepList;
      // this.saleStageList          = res.response.typeList;
      this.intendToBuyList        = res.response.intendToBuyList;
      this.leadsList              = res.response.leadsList;
      });
  }
  
  handleError(err: any): any {
    console.log(err);
  }

}

import { PaginationBaseModel } from "src/app/shared/model/common.model";

export class SettingsTab 
{
}

export class SettingRequestModel{
  companyId         : string='';
 }

export class UpdateCreditTerm
  {
    id: string='';
    companyId: string='';
    creditTermTypeId: string='';
    terms: string='';
    creditLimit: string='';
    comments: string='';
    dateUpdated: string='';
  }


  export class CompanyTradeReferencePost
  {
    id: string='';
    companyId: string='';
    companyName: string='';
    contactName: string='';
    title: string='';
    contactNumber: string='';
    terms: string='';
    creditLimit: string = '0';
    comments: string='';
 }
 

 export class SettingDataVm{
  companyFulfillmentData   = new CompanyFulfillmentVm();
  tradeInformationData     = new TradeInformationVm();
  creditTermsData          : CreditTermsVm[]=[];
  tradeReferenceData       : TradeReferenceVm[]=[];
 }

 export class CompanyFulfillmentVm{
  id: string='';
  companyId: string='';
  doNotInsureOrDeclarenyTypesId: boolean=false;
  freeShipping: boolean=false;
  noPartialShip: boolean=false;
  logisticsField1: string='';
  logisticsField2: string='';
  logisticsField3: string='';
  manufacturerCOC: boolean=false;
  companyCOC: boolean=false;
  requiresDatasheet: boolean=false;
  requiredImages: boolean=false;
  documentationField1: string='';
  documentationField2: string='';
  documentationField3: string='';
  requiresBarcodeLabels: boolean=false;
  poNumberOnBox: boolean=false;
  requiredRFIDLabel: boolean=false;
  packagingField1: string='';
  packagingField2: string='';
  packagingField3: string='';
  requiredInspectionReport: boolean=false;
  required3rdPartyTestReport: boolean=false;
  requiredTestAuthToShip: boolean=false;
  dateCodeRestriction: string='';
  qualityField1: string='';
  qualityField2: string='';
  qualityField3: string='';

}

 export class TradeInformationVm {
  id  : string='';
  companyId: string='';
  creditLimit: string='';
  code: string='';
  tradeEntity: string='';
  revenue: string='';
  taxId: string='';
  taxComment: string='';
  taxUpdatedDate: string='';
  vat: string='';
  vatComment: string='';
  vatUpdatedDate: string='';
  resellerId: string='';
  resellerExpiration: string='';
  resellerUpdatedDate: string='';
  resellerComments: string='';
  documentsId: string='';
 }

 export class CreditTermsVm {
  id: string='';
  companyId: string='';
  creditTermTypeId: string='';
  terms: string='';
  creditLimit: string='';
  comments: string='';
  dateUpdated: string='';
 }

 export class TradeReferenceVm
 {
  id: string='';
  companyId: string='';
  companyName: string='';
  contactName: string='';
  title: string='';
  contactNumber: string='';
  terms: string='';
  creditLimit: string='0'
  comments: string='';
 }



//  export class CommonCompanyFulfillment{
//    id                : number;
//    companyId         : number;
//    fulfillmentName   : string;
//    textFields        = new TextFields();
//  }

export class TextFields{
   textField1     : string;
   textField2     : string;
   textField3     : string;
 }

export class GetSettingPostVM
{
    
  companyId     : number;
  companyName   : string;
      
}

// 2--model
export class UpdateTaxIdPostvm 
{
  id  : string='';
  tradeInformationId: number=0;
  companyId: string='';
  creditLimit: string='';
  code: string='';
  tradeEntity: string='';
  revenue: string='';
  taxId: string='';
  taxComment: string='';
  taxUpdatedDate: string='';
  vat: string='';
  vatComment: string='';
  vatUpdatedDate: string='';
  resellerId: string='';
  resellerExpiration: string='';
  resellerUpdatedDate: string='';
  resellerComments: string='';
  documentsId: string='';
}
// 3--model
export class UpdateResellerIdPostvm 
{
  companyId                 : number;
  resellerId                : string;
  resellerIdExpirationDate  : string;
  resellerComments          : string;
};
// 4--model
export class UpdateVATRFCCNPGPostvm
{
  companyId           : number;
  vatrfccnpg          : string;
  vatrfccnpgComments  : string;
}

export enum InsertUpdateTradeInformationTypeId
{
    Tax = 1,
    Vat = 2,
    Reseller = 3
}

export class InsertUpdateCompanyFulfillmentRequirementModel
{
  id: string='';
  companyId: string='';
  doNotInsureOrDeclarenyTypesId: boolean=false;
  freeShipping: boolean=false;
  noPartialShip: boolean=false;
  logisticsField1: string='';
  logisticsField2: string='';
  logisticsField3: string='';
  isLogisticsField1: boolean=false;
  isLogisticsField2: boolean=false;
  isLogisticsField3: boolean=false;
  manufacturerCOC: boolean=false;
  companyCOC: boolean=false;
  requiresDatasheet: boolean=false;
  requiredImages: boolean=false;
  documentationField1: string='';
  documentationField2: string='';
  documentationField3: string='';
  isDocumentationField1: boolean=false;
  isDocumentationField2: boolean=false;
  isDocumentationField3: boolean=false;
  requiresBarcodeLabels: boolean=false;
  poNumberOnBox: boolean=false;
  requiredRFIDLabel: boolean=false;
  packagingField1: string='';
  packagingField2: string='';
  packagingField3: string='';
  isPackagingField1: boolean=false;
  isPackagingField2: boolean=false;
  isPackagingField3: boolean=false;
  requiredInspectionReport: boolean=false;
  required3rdPartyTestReport: boolean=false;
  requiredTestAuthToShip: boolean=false;
  dateCodeRestriction: string='';
  qualityField1: string='';
  qualityField2: string='';
  qualityField3: string='';
  isQualityField1: boolean=false;
  isQualityField2: boolean=false;
  isQualityField3: boolean=false;
  isdcRestriction: boolean=false;
}

export class TradeInfoModel
{
  Id                 : string;
  Comments           : string;
  UpdateDate         : string;
  ExpirationDate      : string;
}

  
export enum FullFillmentNames{
  DOCUMENTATION   ='Documentation',
  LOGISTICS       ='Logistics',
  PACKAGING       = 'Packaging',
  QUALITY_CONTROL ='Quality Control'
}

export class UploadModel{
  companyOrVendorId: string=''
  categoryId: string=''
  sourceId: string=''
  file: string=''
  documentTypeId: number=0;
  fileName: string='';
}

export enum UploadDocumentsEnum{
  RESELLERDOCUMENTS = 'Reseller_Documents',
  DOCUMENTSTABS     = 'documents_tabs',
  TRADEREFERENCES   = 'Trade_References',
  CERTIFICATION   = 'Certification'

}

export class GetDocumentsByCompanyRequestModel extends PaginationBaseModel{
  companyOrVendorId: string='';
  documentTypeId: number=0;
  categoryId: string='';
}
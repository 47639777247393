import { Component, Input, OnInit } from '@angular/core';
import { AddiconList } from 'src/app/components/authentication/login/login.model';
import { Button, LablesNames, RoutePath, TabNames } from '../../model/enum';

@Component({
  selector: 'app-parts-button',
  templateUrl: './parts-button.component.html',
  styleUrls: ['./parts-button.component.scss']
})
export class PartsButtonComponent implements OnInit {
  showlist:boolean = false;
  showNewlist:boolean = false;
  @Input() name: string;
  @Input() optionList: any[];
  @Input() classActive: string;
  @Input() buttonClass:string;

  
  enumButtonName          = Button;
  enumItemLablesNames     = LablesNames;
  enumTabNames            = TabNames;
  routerPath = RoutePath;
  currentTab = ''
  route;
 
  constructor() { }

  ngOnInit() {
  }

  closeList(){
    this.showlist = false
   } 
   closeNewList(){
    this.showNewlist = false
   } 


}

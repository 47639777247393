import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'populateEnum'
})
export class PopulateEnumPipe implements PipeTransform {

  transform(enumObj: Object) : any {
var a=   Object.keys(enumObj).filter(e => !isNaN(+e)).map(o => { return {key: +o, value: enumObj[o]}});
 return a
}

}

<div class="text-center b_radius_24 bg-light-color mx-xl-3 ms-md-5 me-md-3 px-3 py-2 box-width"   #contentWrapper>
  <div class="row mx-0 mb-1">
      <div class="d-flex justify-content-between col mb-1 p-0" >
          <div class="d-flex justify-content-between title-div">
            <div class="text-primary-color font-11 pe-2 text-truncate" ngbTooltip="{{ data?. title }}">{{ data?.title }}</div>
          </div>
       
            <div class="svg" (click)="add_EditActivityComponent(data,'Edit')"><app-svg-icon [src]="'./assets/SVGIcons/edit.svg'" ></app-svg-icon></div>
            <div class="svg"  (click)="addComment({},true)"><app-svg-icon [src]="'./assets/SVGIcons/comment.svg'"></app-svg-icon></div>
          <div *ngIf="data?.statusName === 'Open'"  (click)="markAsComplete(data?.id)" class="text-danger-color f-11 align-self-center hand"> <img class=" width-img" src="./assets/SVGIcons/grey-circle.svg" alt=""> </div>
          <div *ngIf="data?.statusName === 'Closed'" class="text-danger-color f-11 align-self-center"><img  class=" width-img" src="./assets/SVGIcons/circleTick.svg" alt=""></div>
          <div *ngIf="data?.statusName === 'Cancelled'" class="text-danger-color f-11 align-self-center"><img  class=" width-img" src="./assets/SVGIcons/disabled-circle-tick.svg" alt=""></div>
      </div>
      <div class="textStart font-11 p-0 text-light-gray">
          <button class="button me-3">{{ data?.statusName }}</button>
          <div class="d-flex align-items-center me-3">
            <i *ngIf="data?.priorityName=='High'" class="fa fa-circle pe-2 orange-circle"></i>
            <i *ngIf="data?.priorityName=='Critical'" class="fa fa-circle pe-2 red-circle"></i>
            <i *ngIf="data?.priorityName=='Low'" class="fa fa-circle pe-2 blue-circle"></i>
            <i *ngIf="data?.priorityName=='Standard'" class="fa fa-circle pe-2 yellow-circle"></i>
            <span class="text-info-color">{{ data?.priorityName }}</span>
          </div>
          <!-- <span class=""><span class="badge"></span>{{ data?.priority }}</span> -->
          <div class="d-flex icon">
         <app-svg-icon
        *ngIf=" data?.typeName === 'Call'"
        class="pe-2"
        [src]="'./assets/SVGIcons/phone-call.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf=" data?.typeName === 'Email'"
        class="pe-2"
        [src]="'./assets/SVGIcons/Emails.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf=" data?.typeName === 'Quote'"
        class="pe-2"
        [src]="'./assets/SVGIcons/Quotes.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf=" data?.typeName === 'Reminder'"
        class="pe-2"
        [src]="'./assets/SVGIcons/Reminders.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf=" data?.typeName === 'Note'"
        class="pe-2"
        [src]="'./assets/SVGIcons/Notes.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf=" data?.typeName === 'Task'"
        class="pe-2"
        [src]="'./assets/SVGIcons/Tasks.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf=" data?.typeName === 'Meeting'"
        class="pe-2"
        [src]="'./assets/SVGIcons/Meeting.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf=" data?.typeName === 'File'"
        class="pe-2"
        [src]="'./assets/SVGIcons/File.svg'"
        ></app-svg-icon>
            <!-- <div  *ngIf=" data?.typeName === 'Task'" class="pe-2"><img width="13px" height="13px" src="./assets/SVGIcons/Tasks.svg" alt=""></div>
            <div  *ngIf=" data?.typeName === 'Call'" class="pe-2"><img width="13px" height="13px" src="./assets/SVGIcons/phonecall.svg" alt=""></div>
            <div  *ngIf=" data?.typeName === 'Email'" class="pe-2"><img width="13px" height="13px" src="./assets/SVGIcons/Emails.svg" alt=""></div>
            <div  *ngIf=" data?.typeName === 'Quote'" class="pe-2"><img width="13px" height="13px" src="./assets/SVGIcons/Quotes.svg" alt=""></div>
            <div  *ngIf=" data?.typeName === 'Reminder'" class="pe-2"><img width="13px" height="13px" src="./assets/SVGIcons/Reminders.svg" alt=""></div>
            <div  *ngIf=" data?.typeName === 'Note'" class="pe-2"><img width="13px" height="13px" src="./assets/SVGIcons/Notes.svg" alt=""></div>
            <div  *ngIf=" data?.typeName === 'Meeting'" class="pe-2"><img width="13px" height="13px" src="./assets/SVGIcons/Meeting.svg" alt=""></div>
            <div  *ngIf=" data?.typeName === 'File'" class="pe-2"><img width="13px" height="13px" src="./assets/SVGIcons/File.svg" alt=""></div> -->
            <span>{{ data?.typeName }}</span>
          </div>
        </div>
        <div class="text-start font-10 p-0 text-light-gray text-nowrap my-1 d-flex">
          <div class="text-decoration-underline text-semi-primary overflow-ellipsis hand" href=""  ngbTooltip="{{ data?. contactName }}" (click)="openContactInfo(data)">{{ data?. contactName }}</div>
          <span *ngIf="data?.contactName" class="text-light-info mx-1">/</span>
          <div class="text-decoration-underline text-semi-primary overflow-ellipsis hand" href=""  ngbTooltip="{{ data?. companyName }}" (click)="openCompanyInfo(data)">{{ data?.companyName }}</div>
          <span *ngIf="data?.companyName" class="text-light-info mx-2">/</span>
          <div class="text-decoration-underline text-light-info overflow-ellipsis" href=""  ngbTooltip="{{ data?. salesStageName  }}">{{ data?.salesStageName  }}</div>
        </div>
        <div class="text-start p-0 text-light-gray mb-1">
          <p class="text-overflow-example font-10"  ngbTooltip="{{ data?. description }}">{{ data?. description }}</p>
      </div>
      <div class="text-start font-10 p-0  d-flex justify-content-between"
      [ngClass]="{
        'text-red-color': data?.aging?.toLowerCase().includes('past due'),
        'text-light-gray': !data?.aging?.toLowerCase().includes('past due')
      }"
>
          <span class="font-style">{{ data?.aging }}</span>
          <span (click)="datePicker(data.id)" class="font-style color-pink hand">{{ data?.dueDateInFormat }}</span>
      </div>
  </div>
</div>

<div class="tooltip-container">
  <div *ngIf="data.datePickerShow" class="custom-tooltip" [ngClass]="{ 'add-top':tooltipPosition}">
    <div class=" with-arrow text-center datepicker bg-light-color mx-xl-3 ms-md-5 me-md-3 m-b-10 px-3 py-2" >
      <span class="font-11 text-light-info">Snooze</span>
       <div class="d-flex justify-content-center align-item-center  mt-1 mb-2">
         <span  class="font-11 hand text-gray text-nowrap" (click)="addUpdateSnooz(1)">24 Hours</span><div class="vr"></div>
         <span  class="font-11 hand text-gray text-nowrap" (click)="addUpdateSnooz(2)">48 Hours</span><div class="vr"></div>
         <span  class="font-11 hand text-gray text-nowrap" (click)="addUpdateSnooz(3)">1 Week</span>
       </div><hr>
    <div class="my-2">
      <span class="font-11 text-light-info">Change Due Date</span>
      <form  class="theme-form needs-validation novalidate" #f="ngForm"  novalidate  >
         <!-- <div class="mt-2">
              <div class="date-container d-flex">
                <input
                  ngModel
                  #dateExpiration="ngModel"
                  class=" px-1 hand form-control"
                  type="date"
                  name="dateExpiration"
                  id="date"
                  [(ngModel)]="selectedDate"
                  [ngClass]="{ 'borders': dateExpiration.invalid && submit }" 
                  (change)="changeDateExpiration($event.target.value)"
                  (click)="onClick()"
                  required
                />
                <label
                  for="date"
                  class="label1"
                  [ngClass]="{'label2' : selectedDate != '' && hideIcon == false}">
                   Date 
                  <i class="fst-normal ps-1 p-0 text-danger"></i>
                </label>
              </div>
              <span
              [ngClass]="{'d-none': hideIcon == true ||  selectedDate == ''}"
              (click)="clear(f); "
              class="refresh-icon"><app-svg-icon
                class="svgIcon"
                [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
              ></app-svg-icon
            ></span>
            </div>   -->


            <div class="mt-2 taskdate orderDetail detail2">
              <app-date-picker
              [time]="false"
              [inputLabel]="'Date'"
              [inputValue]="selectedDate"
              (inputValueEmit)="changeDateExpiration($event);onClick()"
            ></app-date-picker> 


            </div>

            <!-- <div class="mt-3">
              <div class="date-container d-flex">
                <input
                 ngModel
                  #timeExpiration="ngModel"
                  class=" px-1 hand form-control"
                  type="time"
                  name="timeExpiration"
                  id="time"
                  [(ngModel)]="selectedTime"
                  [ngClass]="{ 'borders': timeExpiration.invalid && submit,'nobg': selectedTime =='' }" 
                  (change)="changeTimeExpiration($event.target.value)"
                  required
                />
                <label
                  for="time"
                  class="label1"
                  [ngClass]="{'label2' : selectedTime != '' && hideIcon == false}">
                   Time 
                  <i class="fst-normal ps-1 p-0 text-danger"></i>
                </label>
              </div>
              <span
              [ngClass]="{'d-none': hideIcon == true ||  selectedTime == ''}"
              (click)="clearTime(f); "
              class="refresh-icon"><app-svg-icon
                class="svgIcon"
                [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
              ></app-svg-icon
            ></span>
            </div> -->
           
            <div class="mt-3">
              <div class="date-container d-flex">
                <input
                  [(ngModel)]="selectedTimeValue"
                  readonly
                  class="px-1 hand form-control time-input"
                  required

                />
                <label
                for="time"
                class="label1"
                [ngClass]="{'label2' : selectedTime != '' && hideIcon == false}">
                 Time 
                <i class="fst-normal ps-1 p-0 text-danger"></i>
              </label>
                <!-- <i class="fas fa-clock" (click)="togglePopover()"></i> -->
                <img width="14px" src="../../../assets/SVGIcons/clockIcon.svg"  (click)="togglePopover()" alt="" class="hand clock">
              </div>
              <span
              [ngClass]="{'d-none': hideIcon == true ||  selectedTime == ''}"
              (click)="clearTime(f); "
              class="refresh-icon"><app-svg-icon
                class="svgIcon"
                [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
              ></app-svg-icon
            ></span>
              <ng-container *ngIf="isPopoverOpen">
                <ngb-popover placement="bottom" [autoClose]="false">
                  <ngb-timepicker 
                   [id]="'timePicker1'" 
                   [name]="'timePicker1'" 
                   [(ngModel)]="ngbTimePickerValue"
                   (ngModelChange)="changeTimeExpiration($event)"
                   [meridian]="true" class="time-picker-custom timepicker"></ngb-timepicker>
                </ngb-popover>
              </ng-container>
            </div>
                   </form>
     </div>
    </div>
      </div>
</div>



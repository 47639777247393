import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {
  passwordsMatch: boolean = true;
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Input () inputLabel :string = 'password';
  @Input () inputType:string;
  @Input () inputValue : string = "";
  @Input () inputDefaultShow:boolean=false
  @Input () inputReqValidation:boolean = false;
  showHide:boolean=true
  @Input() shouldPhoneFormat:boolean=false;
  @Input() shouldBogieFormat:boolean=false;  
  @Input() inputMaxlength:boolean=false;
  @Input() inputMinlength:boolean=false;
  @Input() inputReadonly : boolean  = false;
  @Input() passwordFormat:boolean = false;
  @Input() isInputDisabled = false;
  @Input() isValid: boolean =false;
  @Input() isSvgVisible : boolean = false;
  @Input () ngbTypeahead:any;
  @Input () resultFormatter:any;

  @Output() keydownSearchLedger = new EventEmitter<KeyboardEvent>();
  @Output() blur:EventEmitter<undefined> = new EventEmitter(undefined);
  @Output() selectItem:EventEmitter<undefined> = new EventEmitter(undefined);

  inputPattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$"
 
  constructor(

  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    document.getElementById('hidden').focus()
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === ' ' && this.inputLabel.includes('Password')) {
      event.preventDefault();
    }
  }
  passwordPattern(){
    if(this.inputLabel.includes('Password')){
      return this.inputPattern;
    }
  }

  clearInput(){
    if(!this.inputReadonly){
      this.inputValue = ''
    }
  }
  onKeydownSearchLedger(event: KeyboardEvent): void {
    this.keydownSearchLedger.emit(event);
  }
  onInput($event){}
  clearInputText(event){
    this.blur.emit(event);
  }
  handleSelectItem(event){
    this.selectItem.emit(event.item);
  }
}

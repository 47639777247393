import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CodeCrosswalkComponent} from './pages/code-crosswalk.component'
import { ImportDocumentsComponent } from '../../import-documents/import-documents.component';
import { ImportDocumentsRoutingModule } from '../../import-documents/import-documents-routing.module';

const routes: Routes = [
  {
    path:"",
    component: CodeCrosswalkComponent,
  },
  // {
  //   path:'import',
  //   component: ImportDocumentsComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes),ImportDocumentsRoutingModule],
  exports: [RouterModule,ImportDocumentsRoutingModule]
})
export class CodeCrosswalkRoutingModule { }

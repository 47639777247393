<div >
  <div class="date-container d-flex ">
    <input
      ngModel
      class="px-1 hand form-control"
      type="time"
      name="time"
      id="time"
      [(ngModel)]="inputValue"
      (ngModelChange)="inputValueEmit.emit(inputValue)"
      [ngClass]="{nobg:!inputValue , 'small-input':isSmall}"
      [required]="inputReqValidation"/>
    <label
      for="time"
      class="label1"
      [ngClass]="{label2: inputValue != '' && timeIcon == false, 'small-label':isSmall}">
      Time Due
      <i *ngIf="inputReqValidation" class="fst-normal ps-1 p-0 text-danger">*</i>
    </label>
  </div>
  <span
    [ngClass]="{'d-none': timeIcon == true || inputValue == ''}"
    (click)="this.inputValue = '' ;  inputValueEmit.emit(inputValue)"
    class="refresh-icon">
    <app-svg-icon
      class="svgIcon"
      [src]="'../../../../assets/SVGIcons/undoSVG.svg'">
    </app-svg-icon>
    </span>
</div>
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CodeCrosswalkService } from '../../code-crosswalk.service';

@Component({
  selector: 'app-import-code-crosswalk',
  templateUrl: './import-code-crosswalk.component.html',
  styleUrls: ['./import-code-crosswalk.component.scss']
})
export class ImportCodeCrosswalkComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, public codeCrosswalkService:CodeCrosswalkService,) { }

  ngOnInit(): void {
    this.codeCrosswalkService.valuePopup.subscribe(res=>{
      if(res == true){
        this.activeModal.close()
      }
    })

  }

}

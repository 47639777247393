import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Button, PopUpTitles } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-delete-company-popup',
  templateUrl: './delete-company-popup.component.html',
  styleUrls: ['./delete-company-popup.component.scss']
})
export class DeleteCompanyPopupComponent implements OnInit {
  @Input() fullName: string;
  @Input() title: string;
  enumButtonName = Button;
  enumPopUpTitles = PopUpTitles;
  @Output() delPopup: EventEmitter<any> = new EventEmitter();
  deleteSuccessfulMessage:boolean = false;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {}
  deleteValue(){
    this.delPopup.emit(true);
    this.deleteSuccessfulMessage = !this.deleteSuccessfulMessage;
  }
}

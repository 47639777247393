<div class="position-relative">
<div class="card bg-semi-light m-0 data-calsss">
    <div class="card-body p-3">
        <div class="mb-1 d-flex justify-content-between align-items-center">
            <div class="row align-items-center">
                <div class="f-10 text-light-info col-10">Comment by {{comment?.userName ==''?'-':comment?.userName}}
                </div>
                <div class="col-2">
                    <img src="./assets/SVGIcons/editIcon.svg" alt="" class="hand {{hideIcon? '': 'not-allowed'  }}" (click)="hideIcon?  getComment.emit(comment) : '' "
                        height="10" width="10" />
                </div>
            </div>
            <div class="f-10 text-info-color" *ngIf="!forContact"> {{comment?.createdAt==''?'-':comment?.createdAt}}</div>
        </div>
        <div class="f-11 text-info-color commentBox" (click)="getTagDetail($event, comment)" [innerHTML]="formatComment(comment?.comment)"></div>
        <div class="d-flex gap-5 fst-italic text-light-info mt-2" *ngIf="forContact">
            <p class="f-10">Added: {{comment?.createdAt==''?'-':comment?.createdAt}}</p>
            <p class="f-10">Created by {{comment?.userName ==''?'-':comment?.userName}}</p>
        </div>
    </div>
</div>


<div class="overlay-2 tooltip-container"  *ngIf="comment.isTooltip && isTooltipData">
    <div  class="custom-tooltip" [ngStyle]="tooltipPosition">
        <div class="with-arrow ">
            <div *ngFor="let item of data">
                <div class="d-flex rounded mx-0 px-0 d-flex align-items-center "> 
                  <div class="mx-0 px-0 pe-2">
                    <img class="mr-3 my-0 rounded-circle" [src]="item?.profilePictureUrl" alt="" width="30"  height="30"
                    onerror="this.src='../../../../../../assets/SVGIcons/default.png'">
                  </div>
                  <div class="mx-0 px-0">
                    <span class="f-12 my-0 py-0 mentioned-name text-semi-primary text-nowrap">{{item?.fullName}}</span><br>
                    <span class="font-10 my-0 py-0 text-nowrap">{{item?.workEmail}}</span>
                  </div>
                </div>
              </div>
        </div>
    </div>
</div>
</div> 
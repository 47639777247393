import { Injectable } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { AllActivitysModel, InsertUpdateTaskCommentModel, InsertSnooz, InsertUpdateToDoActivity, CancelActivity, MarkComplete, DeleteComment, GetTaskCalendarListModel, AddActivitiesMultipleCompanymodel, AddActivitiesMultipleContactmodel } from './activit-manager.model';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { Result } from 'src/app/shared/model/response.model';
import {URLS }   from 'src/app/components/crm/crm-api-constants';
@Injectable({
  providedIn: 'root'
})


export class ActivityManagerService {

  constructor(private service: DataService,) { }
  public getAll = new Subject<boolean>();
  public getMentionList = new BehaviorSubject<any>(undefined);
  public comment = new Subject<any>();
  public addActivity = new Subject<any>();


  getAllActivities(data: AllActivitysModel): Observable<Result> {
    let url = URLS.activityManager.getAllActivities;
    const json =JSON.stringify(data)
    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }


  insertComment(data: InsertUpdateTaskCommentModel): Observable<Result> {
    let url = URLS.activityManager.insertComment;
    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }


  updateComment(data: InsertUpdateTaskCommentModel): Observable<Result> {
    let url = URLS.activityManager.updateComment;
    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }


  deleteComment(data: DeleteComment): Observable<Result> {
    let url = URLS.activityManager.deleteComment;
    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }
  markAsComplete(data:MarkComplete): Observable<Result> {
    let url = URLS.activityManager.markAsComplete;;
    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }
  cancelActivity(data: CancelActivity): Observable<Result> {
    let url = URLS.activityManager.cancelActivity;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  snoozeTasks(insertSnooz: InsertSnooz): Observable<Result> {
    let url = URLS.activityManager.snoozeTasks;
    return this.service.post(url, insertSnooz).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getActivityCalendarList(data: GetTaskCalendarListModel): Observable<Result> {
    let url = URLS.activityManager.getActivityCalendarList;
    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }


  getAllMentionData(data:''): Observable<Result>{
    let url =URLS.activityManager.getAllMentionData
    return this.service.get(url, data).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
    }


    insertToDoActivity(data: InsertUpdateToDoActivity): Observable<Result> {
      let url = URLS.activityManager.insertToDoActivity;
      return this.service.post(url, data).pipe<Result>(tap((response: any) => {
      return response;
        })
      );
    }


    updateToDoActivity(data: InsertUpdateToDoActivity): Observable<Result> {
      let url = URLS.activityManager.updateToDoActivity;
      return this.service.post(url, data).pipe<Result>(tap((response: any) => {
      return response;
        })
      );
    }


    insertActivityForMultipleCompanies(addActivitiesMultipleCompanymodel:AddActivitiesMultipleCompanymodel): Observable<Result>{
      let url = URLS.activityManager.insertActivityForMultipleCompanies;
      return this.service.post(url, addActivitiesMultipleCompanymodel,true).pipe<Result>(tap((response: any) => {
      return response;
        })
      );
    }

    insertActivityForMultipleContacts(addActivitiesMultipleContactmodel:AddActivitiesMultipleContactmodel):Observable<Result>{
      let url = URLS.activityManager.insertActivityForMultipleContacts;
      return this.service.post(url, addActivitiesMultipleContactmodel,true).pipe<Result>(tap((response: any) => {
      return response;
        })
      );
    }

}

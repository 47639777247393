<div class="modal-content">
  <app-custom-loader
    class="position-absolute top-50 start-50 translate-middle"
    style="z-index: 10 "
    [isLoaderLoading]="isLoadings"
  ></app-custom-loader>
 <div class="">
  <button class="close-button" (click)="activeModal.close(false)">
    x
  </button>
 </div> 
  <iframe class="doc"  [src]="cleanUrl(fileUrl)" frameborder="0" width="100%"  allowtransparency="true"></iframe>
</div>

export class Contacts {
}
export class ContactUpdate {
  contactId: string;
  active:boolean;
}

export class AddContact {
  id: string='';
  companyId: string='';
  firstName: string='';
  lastName: string='';
  title: string='';
  genderId: string='';
  departmentId: string='';
  location: string='';
  timeZoneId: string='';
  email: string='';
  mainOfficeNumber: string='';
  directNumber: string='';
  phoneNumber: string='';
  mobileNumber: string='';
  faxNumber: string='';
  skype: string='';
  xing: string='';
  linkedIn: string='';
  markAsPrimaryContact: boolean=false;
  markAsAccountingContact: boolean=false;
  doNotCall: boolean=false;
  doNotEmail: boolean=false;
  markAsPrimaryPurchasingContact: boolean=false;
  notes: string='';
  isActive: boolean=false;
  currentUrl: string;

  }


<div class="card mb-0">
  <div class="card-body p-4 m-2">
    <div class="d-flex justify-content-between mb-4">
      <span class="text-primary-color font"> {{ isAddValue == true ? " Edit Certification" : " Add  Certification" }} </span>
      <div class="d-flex align-items-center px-3" (click)="clearAllDetails(f)">
        <app-svg-icon
          [src]="'./assets/SVGIcons/Clearicon.svg'"
          class="m-r-5"
        ></app-svg-icon>
        <span class="text-nowrap clear-color font-13 m-t-2" type="button"
          >Clear Details</span
        >
      </div>
     </div>
      <form  class="theme-form needs-validation novalidate" #f="ngForm"  novalidate  >
        <app-dropdown
        [selectLabel]="generalInfoLables?.CERTIFICATIONS"
        [data]="certificationList"
        [selectValue]="certification?.Id"
        [ngClass]="{'border-red': !certification.Id && submit }"
        (selectValueEmit)="certification.Id = $event"
        [required]="true">
      </app-dropdown>
      <div class="mt-4">
        <app-date-picker
        [time]="false"
        [inputLabel]="'Date Issued'"
        [inputValue]="dateIssued"
        (inputValueEmit)="dateIssued = $event"
        [ngClass]="{'border-red':!dateIssued && submit}"
        [inputReqValidation]="true"

      ></app-date-picker>

      </div>
      <div class="mt-4">
        <!-- <app-date-picker
        [time]="false"
        [inputLabel]="'Expiration Date'"
        [inputValue]="expirationDate"
        (inputValueEmit)="expirationDate = $event"
        [ngClass]="{'border-red':!expirationDate && submit}"
        [inputReqValidation]="true"

      ></app-date-picker> -->
      <app-date-picker class="addCertification"
      [time]="false"
      [inputLabel]="'Expiration Date'"
      [inputValue]="expirationDate"
      (inputValueEmit)="expirationDate = $event"
      [ngClass]="{'border-red':!expirationDate && submit}"
      [inputReqValidation]="true"

    ></app-date-picker>
   </div>

      <!-- <div class="mt-4">
        <div class="date-container d-flex">
          <input
          ngModel
            #dateIssued="ngModel"
            class=" px-1 hand form-control"
            type="date"
            name="dateIssued"
            id="date"
            [(ngModel)]="selectedDate"
            [ngClass]="{ 'borders': dateIssued.invalid && submit }" 
          (change)="changeDateIssued($event.target.value)"
            required
          />
          <label
            for="date"
            class="label1 "
            [ngClass]="{'label2' : selectedDate != '' && hideIcon == false}">
            Date Issued
            <i class="fst-normal ps-1 p-0 text-danger">*</i>
          </label>
        </div>
        <span
        [ngClass]="{'d-none': hideIcon == true ||  selectedDate == ''}"
        (click)="clear(f); "
        class="refresh-icon"><app-svg-icon
          class="svgIcon"
          [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
        ></app-svg-icon
      ></span>
      </div> 
     <div class="mt-4">
        <div class="date-container d-flex">
          <input
          ngModel
            #dateExpiration="ngModel"
            class=" px-1 hand form-control"
            type="date"
            name="dateExpiration"
            id="date"
            [(ngModel)]="selectedDate1"
            [ngClass]="{ 'borders': dateExpiration.invalid && submit }" 
            (change)="changeDateExpiration($event.target.value)"
            required
          />
          <label
            for="date"
            class="label1"
            [ngClass]="{'label2' : selectedDate1 != '' && hideIcon == false}">
            Expiration Date 
            <i class="fst-normal ps-1 p-0 text-danger">*</i>
          </label>
        </div>
        <span
        [ngClass]="{'d-none': hideIcon == true ||  selectedDate1 == ''}"
        (click)="clear1(f); "
        class="refresh-icon1"><app-svg-icon
          class="svgIcon"
          [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
        ></app-svg-icon
      ></span>

      </div>  -->
    </form>       
  </div>
  <div class="card-footer border-0 pb-4 mb-1 pt-0 px-4 mx-2">
    <div class="row">
      <div class="col-6 ">
          <app-button
          class="me-3"
          [buttonType]="'button'"
          [button_2]="true"
          [buttonClass]="'button button-primary'"
          (click)="openUploadFilePopups()"
          [buttonLable]="enumButtonName.UPLOAD">
      </app-button>
      </div>
      <div class=" col-6 d-flex justify-content-end">
      <app-button
      class="me-1"
      [buttonType]="'button'"
      [button_2]="true"
      [buttonClass]="'button button-info'"
      (click)="activeModal.close()"
      [buttonLable]="enumButtonName.CANCEL">
      </app-button>
       <app-button
      class="ms-3"
      [buttonType]="'submit'"
      [button_2]="true"
      (click)="isAddValue ? editCertification(): addCertification()"
      [buttonClass]="'button button-primary'"
      [buttonLable]="isAddValue ? enumButtonName.UPDATE : enumButtonName.ADD"
       >
  </app-button>
    </div>
  </div>
  </div>
</div>
<div>
      <div class="select-wrapper">
        <select
         tabNext
         #mySelect class="w-100 p-l-15 text-truncate p-r-25"
         [disabled]="disabled"
         [(ngModel)]="selectValue"
          (ngModelChange)="selectValueChange($event)">
          <option value=""></option>
          <option *ngFor="let item of data" [value]="item "> {{ item }} </option>
        </select>
      </div>
  </div>
  
  
  
<!-- <form #testForm="ngForm" class="dropdown-cont"> -->
<!-- <div >
  <div class="form-floating d-flex input-container">
    <input
    (click)="toggleDropdown()"
      type="text"
      class="form-control"
      id="floatingInput"
      autocomplete="off"
      [(ngModel)]="dropdown"
      placeholder="name@example.com"
      readonly
    />
    <label
      for="floatingInput"
      class="label-transition d-flex align-items-center"
      >Select Template
    </label>
    <span
      style="padding: 1.4em 0.8em"
      [ngClass]="{ invisible: !dropdown }"
      (click)="clearInput(); select.emit('')"
      class="icon-con position-absolute top-0 start-0 hand"
      ><app-svg-icon
        class="svgIcon"
        [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
      ></app-svg-icon
    ></span>
    <app-svg-icon
      *ngIf="!isDropDownOpen"
      class="svg-position"
      [src]="'../../../../assets/SVGIcons/down-arrow-dropdown.svg'"
    ></app-svg-icon>
    <app-svg-icon
      *ngIf="isDropDownOpen"
      class="svg-position"
      [src]="'../../../../assets/SVGIcons/up-arrow-dropdow.svg'"
    ></app-svg-icon>
   
  </div>
</div>

<div class="ul-container" *ngIf="isDropDownOpen">
  <ul class="list-group" *ngIf="isDropDownOpen">
    <li
      [ngClass]="item.isActive ? 'activeOption' : ''"
      (click)="selectOption($event, index, item.id)"
      *ngFor="let item of options; let index = index"
      class="item list-group-item d-flex text-semi-primary justify-content-between hand"
    >
      {{ item.name }}
      <app-svg-icon
        [src]="'../../../../assets/SVGIcons/deleteicon.svg'"
        (click)="deleteOption($event, item.id, item.name)"
      ></app-svg-icon>
    </li>
  </ul>
</div> -->
<!-- </form> -->

		<div ngbDropdown #myDropdown="ngbDropdown" class="d-inline-block w-100" style="height: 45.2px;">
			<button type="button" class="btn font-10 p-r-12 custom-select align-items-center justify-content-between w-100 h-100" id="dropdownBasic1" style="display: flex;overflow: hidden;" ngbDropdownToggle>
        <span *ngIf="importCodecrosswalk"  class="text-light-gray text-truncate code">{{ selctedValue?.name || 'Select Template' }}</span>
        <span *ngIf="!importCodecrosswalk" class="text-light-gray text-truncate">{{selctedValue?.name || 'Select Template'}} </span>
        <span>
          <app-svg-icon *ngIf="myDropdown?.isOpen()"
              [src]="'../../../../assets/SVGIcons/bigdown-arrow.svg'">
            </app-svg-icon> 
            <app-svg-icon *ngIf="!myDropdown?.isOpen()"
              [src]="'../../../../assets/SVGIcons/bigup-arrow.svg'">
            </app-svg-icon> 
          </span>
			</button>
			<div ngbDropdownMenu class="p-0 dropDown_menu" aria-labelledby="dropdownBasic1">
				<button class="font-12" (click)="selectedData('')"  ngbDropdownItem > 
          <p class="text-truncate m-0 p-0">&nbsp;</p>
         </button>
         <button class="font-12" (click)="selectedData(item)" *ngFor="let item of options; let index = index" ngbDropdownItem > 
          <div class="d-flex justify-content-between">
            <p class="text-truncate m-0 p-0" >{{item.name}} </p>
            <span><app-svg-icon
              [src]="'../../../../assets/SVGIcons/deleteicon.svg'"
              (click)="deleteOption($event, item.id, item.name)"
            ></app-svg-icon> </span>
          </div>
       </button>
				<!-- <button ngbDropdownItem>Another Action</button>
				<button ngbDropdownItem>Something else is here</button> -->
			</div>

import { BehaviorSubject, Observable, Subject, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { AgGridPaginationConfig, PaginationBaseModel, TablePaginationModel, TablePaginationModelContact, TablePaginationModelVendorList } from "./ag-grid.model";
import { Result } from "../../model/response.model";
import { DataService } from "../../services/data.service";
import { SaveUserSetting} from './ag-grid.model';
import { StorageService } from "../../services/storage.service";
import { UserSetting } from "src/app/components/crm/crm.model";
import { CrmService } from "src/app/components/crm/crm.service";
import { ColumnApi } from "ag-grid-community";
@Injectable({
  providedIn: "root",
})
export class AgGridService {
  public starInfo: [{id:number, stars:number}] = [{id:0, stars:0}];
  public agGridPaginationConfigRes = new BehaviorSubject(undefined);
  public gridColumnApi = new BehaviorSubject(undefined);
  public gridApi = new BehaviorSubject(undefined);
  public agGridPaginationConfig = new AgGridPaginationConfig();
  onColumnDragStopped = new BehaviorSubject(undefined);
  ratingChanged = new BehaviorSubject(undefined);
  userSetting = new UserSetting();
  // public servic=new BehaviorSubject<boolean>(false)
  tablePaginationData = new BehaviorSubject<TablePaginationModel>(undefined);
  tablePaginationContactData = new BehaviorSubject<TablePaginationModelContact>(undefined);

  selectionChanged = new Subject();
  indexToSkip = 2
  toggleHeaderExpandIcon = new BehaviorSubject(undefined);
  importCompaniesValue = new BehaviorSubject(undefined);
  userList = new BehaviorSubject(undefined);
  unChecked = new BehaviorSubject(undefined);
  public dataInput = new BehaviorSubject(undefined);


commentId = 0

  constructor(private service: DataService, private crmService: CrmService,
    public storageService:StorageService) {}

  getGridApi() {
    return this.gridApi.getValue();
  }

  setGridApi(gridApi) {
    this.gridApi.next(gridApi);
  }

  getGridColumnApi() {
    return this.gridColumnApi.getValue();
  }

  setGridColumnApi(gridColumnApi) {
    this.gridColumnApi.next(gridColumnApi);
  }

  public onPageChanged(pageNum: number) {
    this.agGridPaginationConfig.currentPageNumber = pageNum;
    this.agGridPaginationConfig.tableOffset = pageNum - 1;
  }

  public onPageSizeChanged(pageSize: number) {
    this.agGridPaginationConfig.pageSize = pageSize;
    this.onPageChanged(1);
  }

  public onSort(pageNum: number) {
    this.onPageChanged(1);
  }

  public updateTableData(res: any): any {
    this.agGridPaginationConfigRes.next(undefined);
   // this.agGridPaginationConfig.rows = res?.response;
    this.agGridPaginationConfig.rows = res?.response ? res.response : [];
    this.agGridPaginationConfig.totalRecords = res?.totalRecords;
    return this.agGridPaginationConfig;
  }
  private columnState: any;

  saveColumnState(columnApi: ColumnApi) {
    this.columnState = columnApi.getColumnState();
  }

  restoreColumnState(columnApi: ColumnApi) {
    if (this.columnState) {
      columnApi.setColumnState(this.columnState);
    }
  }

  // GetAllAccountTypes dropdown list
  accountTypeSelectDropdown(): Observable<Result> {
    let url = "app/DropDown/getAllSalesStageList";
    return this.service.get(url,true).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }
  GetAllArchivedCompanies(getAllCompanyArchiveList: TablePaginationModel): Observable<Result> {
    console.log('the JSOn', JSON.stringify(getAllCompanyArchiveList))
    let url = "app/Company/getAllCompanyArchiveList";
    return this.service.post(url, getAllCompanyArchiveList).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }


  // get All Crm Data
  GetAllCRM(getAllCrmData: TablePaginationModel): Observable<Result> {
    console.log('the JSOn', JSON.stringify(getAllCrmData))
    let url = "app/Company/getAllCompanyWithFilter";
    return this.service.post(url, getAllCrmData).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }

  GetAllContactsRecybinData(getAllContactsData: TablePaginationModel): Observable<Result> {
    let url = "app/CompanyContact/getAllContactRecycleBin";
    return this.service.post(url, getAllContactsData).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  GetAllCompanyRecybinData(getAllContactsData: TablePaginationModel): Observable<Result> {
    let url = "app/Company/getAllCompanyRecycleBin";
    return this.service.post(url, getAllContactsData).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  GetAllRequirementData(getAllRequirementData: TablePaginationModel): Observable<Result> {
    let url = "app/Requirement/getAllRequirements";
    return this.service.post(url, getAllRequirementData).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // get All Contacts Data in Crm
  GetAllContacts(getAllContactsData: TablePaginationModel): Observable<Result> {
    console.log('the JSOn', JSON.stringify(getAllContactsData))
   // let url = "SourceMatrixApi/People/GetAllPersonalInfos";
    let url = "app/CompanyContact/getAllCompanyContactWithFilter";
    return this.service.post(url, getAllContactsData).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  GetAllVendorContacts(getAllContactsData: TablePaginationModelContact): Observable<Result> {
    console.log('the JSOn', JSON.stringify(getAllContactsData))
   // let url = "SourceMatrixApi/People/GetAllPersonalInfos";
    let url = "app/VendorContact/getAllVendorContactWithFilter";
    return this.service.post(url, getAllContactsData).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }


  saveUserSetting( saveUserSetting:SaveUserSetting): Observable<Result> {
    // console.log('to api:' ,JSON.stringify(saveUserSetting))
    saveUserSetting.userId=this.storageService.retrieve('userId')
    let url = "identity/Users/saveUserSetting";
    return this.service.post(url, saveUserSetting).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }

  //get all code-crosswalk data
  getAllCodeCrosswalk(getAllCodeCrosswalkData: PaginationBaseModel): Observable<Result> {
    console.log('the JSOn', JSON.stringify(getAllCodeCrosswalkData))
    let url = "app/MasterCodeCrosswalk/getAllCodeCrosswalk";
    return this.service.post(url, getAllCodeCrosswalkData).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getVendorList(getVendorList: TablePaginationModelVendorList): Observable<Result> {
    console.log('the JSOn', JSON.stringify(getVendorList))
    let url = "app/Vendor/getAllVendorWithFilter";
    return this.service.post(url, getVendorList).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  public stage = new BehaviorSubject("All");
  getStage() {
    return this.stage.getValue();
  }

  setStage(stage) {
    this.stage.next(stage);
  }

  public filter = new BehaviorSubject("");

  getFilter(){
    return this.filter.getValue()
  }
  setFilter(filter){
    this.filter.next(filter)
  }
  dragColumn(page:string){
    console.log('the page is ', page)
    if(page == 'contacts'){
      this.indexToSkip = 1
    }else{
      this.indexToSkip = 2
    }
    // this.userSetting=this.storageService?.retrieve("userSetting");
    this.crmService.getUserSetting()
    .subscribe(res => {
      if (res?.isSuccess) {
        // let json = JSON.parse(res?.response?.userSetting) ?? {}
        let json = JSON.parse(res?.response) ?? {}

        setTimeout(() => {
         json?.[page]?.map(value => {
            this.getGridColumnApi().moveColumn(value.name, value.index+this.indexToSkip);
          })
        }, 20);
      }
    })
  }

}

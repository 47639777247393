import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-vendor-expandable',
  templateUrl: './vendor-expandable.component.html',
  styleUrls: ['./vendor-expandable.component.scss']
})
export class VendorExpandableComponent implements OnInit,ICellRendererAngularComp {


  constructor() { }

  registerRowDragger: (rowDraggerElement: HTMLElement, dragStartPixels?: number, value?: string, suppressVisibilityChange?: boolean) => void;
 show:boolean=false;
 showSics:boolean=false;
 message: any;  
 params:any;

  agInit(params: any) : void {
    this.params = params;
    console.log('the params ', params)
}
  refresh(params: ICellRendererParams<any, any>): boolean {
   return true;
  }

  ngOnInit(): void {
  
  }
  getResponse($event) {  
    this.message = $event;  
  } 

  clickIcon(){
    this.show =!this.show;
  }
  clickSicsIcon(){
    this.showSics =!this.showSics;
  }
  toggleHover(isHovered: boolean): void {
    this.show = isHovered;
  }
  toggleHoverSic(isHovered: boolean): void {
    this.showSics = isHovered;
  }

}

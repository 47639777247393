import { Injectable } from '@angular/core';
import { DataService } from "./data.service";
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { StorageService } from './storage.service';
import { Result } from '../../shared/model/response.model';
import {CommonDropdown} from "../../shared/model/common.model"
@Injectable({
  providedIn: 'root'
})
export class CommonTabService {
  getAllDropdownList = new BehaviorSubject(undefined)

  constructor(public service: DataService ,public storageService :StorageService) { }

  getAllFilterDropDownList(): Observable<Result> {
    const userId = this.storageService.retrieve('userId')
    let url = "app/dropDown/getAllFilterDropDownList?UserId="+userId;
    return this.service.get(url, '').pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllDropDownList(): Observable<Result> {
    // let url = "SourceMatrixApi/Companies/GetAllDropDownList";
    let url="app/dropDown/getAllDropDownList"
    return this.service.get(url, '').pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getAllManufacturerDropDownList(): Observable<Result> {
    let url="app/manufacturer/getAllManufacturerDropDownList"
    return this.service.get(url, '').pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }


  getUserSetting(): Observable<Result> {
    const userId = this.storageService.retrieve('userId')
    let url = "identity/users/getUserSetting?UserId="+userId;
    return this.service.get(url, true).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  public getAccessPermission():Observable<Result>{
    const userId = this.storageService.retrieve('userId')
    let url = ""+userId;
    return this.service.get(url,true).pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }


  checkAccess(access){
    const accessList= this.storageService.retrieve('getAccessPermissions')
    return accessList.includes(access);
    }

}

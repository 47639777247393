<div class="container-fluid">
  <div class="col-xl-6 col-md-12 box" [style]="{'height': flexHeight}">
    <div class="mb-3">
      <label class="font-12 text-gray" for="">Import File Name</label>
      <div class="font-12 text-primary-color">{{fileName}}</div>
    </div>
    <div class="mb-3">
      <label class="font-12 text-gray" for="">Template Details<i class="fst-normal ps-1 p-0 text-danger">*</i></label>
      <div class="form-check">
        <input class="form-check-input hand" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
          [(ngModel)]="selectedOption" value="1">
        <label class="form-check-label font-12 hand " for="flexRadioDefault1">
          Take no mapping template action.
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input {{mappingTemplateId=='0' ?'': 'hand'}}" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
          [(ngModel)]="selectedOption" [attr.disabled]="mappingTemplateId=='0' ?'': null" value="2">
        <label class="form-check-label font-12 {{mappingTemplateId=='0' ?'': 'hand'}}" for="flexRadioDefault2">
          Update current mapping template.
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input hand" type="radio" name="flexRadioDefault" id="flexRadioDefault3"
          [(ngModel)]="selectedOption" value="3">
        <label class="form-check-label font-12 hand" for="flexRadioDefault3">
          Save as new mapping template.
        </label>
      </div>
    </div>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <label class="font-12 text-gray" for="templateName">Template Name<i
            class="fst-normal ps-1 p-0 text-danger">*</i></label><br>
        <input class="form-control font-12 text-light-gray" autocomplete="off" type="text" id="templateName"
          formControlName="templateName"
          [ngClass]="{'border-red':!myForm.controls.templateName.valid && submit && insertUpdateImportDocumnets?.templateData?.isTemplateCreate || myForm.value.templateName.trim() == '' && this.selectedOption !=='1'}"
          [attr.disabled]="this.selectedOption =='1' ? '' : null" />
      </div>
      <div class="mb-3">
        <label class="font-12 text-gray" for="description">Template Description</label><br>
        <textarea class="form-control font-12 text-light-gray" id="description" formControlName="description" rows="4"
          [attr.disabled]="this.selectedOption =='1' ? '' : null"></textarea>
      </div>
    </form>
  </div>
  <div class="col-12 d-flex justify-content-end">
    <app-button  *ngIf="currentTab !== tabName.CODECROSSWALK_TAB "
     class="px-2" [button_2]="true" [buttonClass]="'button button-info mx-0'"
      [buttonLable]="enumButtonName.CANCEL" (click)="closeTab()">
    </app-button>
    <app-button  *ngIf="currentTab === tabName.CODECROSSWALK_TAB "
     class="px-2" [button_2]="true" [buttonClass]="'button button-info mx-0'"
    [buttonLable]="enumButtonName.CANCEL" (click)="close()">
  </app-button>
    <app-button progressStepPrev class="px-2" [button_2]="true" [buttonClass]="'button  dark-info mx-0'"
      [buttonLable]="enumButtonName.PREVIOUS">
    </app-button>
    <app-button class="ps-2 pe-0" (click)="insertOrShowingError()" [button_2]="true"
      [buttonClass]="( selectedOption && myForm.controls.templateName.valid  || selectedOption =='1' )?'button button-primary mx-0':'button dark-info mx-0'"
       [buttonLable]="enumButtonName.IMPORT" [buttonType]="'submit'">
    </app-button>
  </div>
</div>
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompaniesService } from '../../../companies/companies.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UploadDocumentsEnum, UploadModel } from '../../settings-tab/settings-tab.model';
import { ImportCompanyVM, UploadCertificationDocuments, UploadDocuments} from '../../../companies/companies.model';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { baseUrl } from 'src/environments/environment';
import { SettingsTabService } from '../../settings-tab/settings-tab.service';
import { Button, DocumentTypes, PopUpTitles } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {
  @Input()pageType:string=''
  uploadModel   = new UploadModel();
  resellerUploadDocuments   = new UploadDocuments();
  tradeReferenceUploadDocuments   = new UploadDocuments();
  certificationUploadDocuments   = new UploadCertificationDocuments();
  enumButtonName = Button;
  popupTitle:string;
  enumPopUpTitles = PopUpTitles;
  file          : File[];
  selectedFile  : File;
  enumUploadDocuments=UploadDocumentsEnum
  firstContainer: boolean = true;
  progress: number = 0;
  hideProgressbar: boolean = false;
  base64OfFile: any;
  uploadSuccess: boolean = false;
  percentDone: number = 0;
  hideErrordiv: boolean = false;
  buttonDisable:boolean=true;
  interval: any;
  importCompanyVM = new ImportCompanyVM();
  categoryList=[];
  fileName:string=''

  constructor(
    public activeModal   : NgbActiveModal,
    public companiesServices: CompaniesService,
    public commonTabService: CommonTabService,
    public settingsTabService: SettingsTabService,
    public http:HttpClient,
    private toastr: ToastrService) { }


    @ViewChild("bar") bar: ElementRef;
      progressPercentage() {
      return Math.floor(this.progress * 100);
    }


    ngOnInit(): void {
      this.categoryList = this.commonTabService.getAllDropdownList.getValue().categoryList;
    }


    upload(event: any) {
      this.progress = 0;
      this.readThis(event.target);
      this.file = event.target.files;
      var aceept = event.target.accept;
      this.onFileSelected(event);
      if (this.file.length > 0) {
        this.hideProgressbar = true;
        this.firstContainer = false;
        this.uploadSuccess = true;


          // this.uploadAndProgress(this.file);
        // this.interval = setInterval(() => {
        //   this.progress += 0.001;
        //   if (this.progress >= 1) {
        //     this.progress = 1;
        //     this.hideProgressbar = false;
        //     this.uploadSuccess = true;
        //     console.log(this.uploadSuccess);
        //     clearInterval(this.interval);
        //     this.firstContainer = false;
        //     this.uploadAndProgress(this.file);
        //   }
        // }, 13);
      }
    }
  
    uploadAndProgress(files: File[]) {
      // this.firstContainer = false;
      var formData = new FormData();
      Array.from(files).forEach((f) => formData.append("file", f));
      this.http
        .post("https://file.io", formData, {
          reportProgress: true,
          observe: "events",
        })
        .subscribe((event) => {
          if (event.type === HttpEventType["UploadProgress"]) {
            this.percentDone = Math.round((100 * event.loaded) / event.loaded);
          } else if (event instanceof HttpResponse) {
            // this.uploadSuccess = true;
          }
        });
    }
  
    readThis(inputValue: any): void {
      var file: File = inputValue.files[0];
      this.fileName=file.name;
      var myReader: FileReader = new FileReader();
  
      myReader.onloadend = (e) => {
        // this.image = myReader.result;
        // this.base64OfFile = myReader.result.toString().split(',')[1];
        this.base64OfFile  = myReader.result.toString();
      };
      myReader.readAsDataURL(file);
    }
  
    hideUploadingdiv() {
      this.hideErrordiv = true;
      this.hideProgressbar = false;
      clearInterval(this.interval);
    }
  
    showuploadefile() {
      this.hideErrordiv = false;
      this.firstContainer = true;
    }
  
    newImport() {
      this.uploadSuccess = false;
      this.firstContainer = true;
      this.file = null;
    }



    onFileSelected($event){
      this.selectedFile = $event.target.files[0];
     
    }


    uploadFile(){
      if(this.pageType==this.enumUploadDocuments.DOCUMENTSTABS){
        this.uploadModel.documentTypeId=DocumentTypes.companyDocuments;
        this.uploadFileDocumentsTab();
      }
      if(this.pageType==this.enumUploadDocuments.RESELLERDOCUMENTS){
        this.resellerUploadDocuments.documentTypeId=DocumentTypes.resellerDocument;
        this.uploadResellerDocuments();
      }
      if(this.pageType==this.enumUploadDocuments.TRADEREFERENCES){
        this.tradeReferenceUploadDocuments.documentTypeId=DocumentTypes.tradeReferenceDocument;
        this.uploadTradeReferenceDocuments();
      }
      if(this.pageType==this.enumUploadDocuments.CERTIFICATION){
        this.certificationUploadDocuments.documentTypeId=DocumentTypes.certificationDocument;
        this.uploadCertificationDocuments();
      }

    }
    uploadCertificationDocuments(){
      this.certificationUploadDocuments.companyOrVendorId=this.companiesServices.id;
      this.certificationUploadDocuments.file= this.base64OfFile;
      this.certificationUploadDocuments.fileName= this.fileName;
      this.companiesServices.uploadCerticationDocument(this.certificationUploadDocuments).subscribe(res=>{
        if(res.isSuccess){
          this.companiesServices.setResponseData(res);
          this.toastr.success(res.message);
          this.activeModal.close(res.isSuccess);
        }
      })

    }
    // uploadFileDocumentsTab() {
    //   if (this.uploadModel.file) {
    //     this.buttonDisable=false;
    //     const formData = new FormData();
    //     console.log('.........',this.companiesServices.id)
    //     formData.append('file', this.uploadModel.file);
    //     formData.append('companyId', this.companiesServices.id.toString());
    //     formData.append('categoryId', this.uploadModel.categoryId==''?'0':this.uploadModel.categoryId);
    //     formData.append('sourceId', '0');
    //     formData.append('isActive', this.uploadModel.isActive.toString());
    //     this.http.post('http://3.18.144.73:6082/api/v1/SourceMatrixApi/Document/InsertDocument', formData, { reportProgress: true, observe: 'events' }).subscribe(
    //     (event) => {
    //         if (event.type === HttpEventType.UploadProgress) {
        
    //         } else if (event.type === HttpEventType.Response) {
    //           console.log('File uploaded successfully:', event.body);
    //           const response = event.body as { message: string };
    //           if (response && response.message) {
    //             this.toastr.success(response.message);
    //             this.activeModal.close(true);
    //             this.buttonDisable=true;
    //           } else {
    //             this.toastr.error(response.message);
    //           }
    //         }
    //       },
    //       (error) => {
    //         console.error('Error uploading file:', error);
    //         if (error?.error?.message) {
    //           this.toastr.error(error.error.message);
    //         } else {
    //           this.toastr.error('Error uploading file. Please try again.');
    //         }
    //       }
    //     );
    //   }
    // }

    uploadFileDocumentsTab(){
      this.uploadModel.file=this.base64OfFile;
      if (this.uploadModel.file) {
        this.buttonDisable=false;
        this.uploadModel.companyOrVendorId  =this.companiesServices.id;
        this.uploadModel.fileName=this.fileName;
        this.settingsTabService.insertDocument(this.uploadModel).subscribe(res=>{
          if (res && res.message) {
              this.toastr.success(res.message);
              this.activeModal.close(true);
              this.buttonDisable=true;
            } else {
              this.toastr.error(res.message);
            }
        })
      }
    }



    uploadResellerDocuments(){
    this.resellerUploadDocuments.companyOrVendorId=this.companiesServices.id;
    this.resellerUploadDocuments.file= this.base64OfFile;
    this.resellerUploadDocuments.fileName= this.fileName;
    
    this.companiesServices.uploadResellerDocument(this.resellerUploadDocuments).subscribe(res=>{
      if(res.isSuccess){
        this.toastr.success(res.message);
        this.activeModal.close(res);
      }
    })
    }

    uploadTradeReferenceDocuments(){
      if (this.fileName) {
      this.tradeReferenceUploadDocuments.companyOrVendorId=this.companiesServices.id;
      this.tradeReferenceUploadDocuments.file= this.base64OfFile;
      this.tradeReferenceUploadDocuments.fileName= this.fileName;
      this.companiesServices.uploadTradeReferenceDocument(this.tradeReferenceUploadDocuments).subscribe(res=>{
        if(res.isSuccess){
          this.toastr.success(res.message);
          this.activeModal.close(res.isSuccess);
        }
      });
    } 
  }
}

import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class StingToDateService {

  private storage: any;
    constructor() {
        this.storage = sessionStorage; // localStorage;
    }


convertStringToDate(inputDate: string): NgbDate | null {
    const dateParts = inputDate.split('/');
    
    if (dateParts.length === 3) {
      const year = parseInt(dateParts[2], 10);
      const month = parseInt(dateParts[0], 10);
      const day = parseInt(dateParts[1], 10);
    
      if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
        return { year, month, day } as NgbDate;
      }
    }
    return null;
  }  

  dateConvertedToString(date) {
    return  (date.month < 10 ? '0' : '') + date.month + '/' +(date.day < 10 ? '0' : '') + date.day + '/'  + date.year;
  }

  dateConvertToString(date) {
    return (date.day < 10 ? '0' : '') + date.day + '/' + (date.month < 10 ? '0' : '') + date.month + '/' + date.year;
  }
  changeTimeExpiration(timeValue: any): string | null {
    if (timeValue) {
      const formattedTimeValue = moment(timeValue, ['h:mm A', 'HH:mm']).format('HH:mm');
      return formattedTimeValue;
    }
    return null;
  }

  convertTimeStringToObject(timeString) {
    const timeParts = timeString.match(/(\d{2}):(\d{2})\s?(am|pm)/i);
    if (!timeParts) {
      throw new Error('Invalid time format');
    }

    let hour = parseInt(timeParts[1], 10);
    const minute = parseInt(timeParts[2], 10);
    const period = timeParts[3].toLowerCase();
    if (period === 'pm' && hour !== 12) {
      hour += 12;
    } else if (period === 'am' && hour === 12) {
      hour = 0;
    }

    return { hour, minute, second: 0 };
  }

  convertToFormattedTimeString(timeString) {
    const timeParts = timeString.match(/(\d{2}):(\d{2})\s?(am|pm)/i);
    if (!timeParts) {
      throw new Error('Invalid time format');
    }

    let hour = parseInt(timeParts[1], 10);
    const minute = timeParts[2];
    const period = timeParts[3].toUpperCase();

    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    } else if (hour === 12) {
    }

    return `${hour}:${minute}${period}`;
  }
}
<fieldset class="rate" [id]="dynamicId">
  <ng-container *ngFor="let items of ratingStarList">
    <input
      type="radio"
      [id]="dynamicId + items.id"
      [name]="rating + dynamicId"
      [(ngModel)]="ratingValue"
      [value]="items.value"
      (click)="onRatingChange(items.value)"
    />
    <label class='elabel'  for="{{dynamicId + items.id }}" [class]="items.className"> </label>
  </ng-container>
</fieldset>

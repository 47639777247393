import { AddCompanyService } from './../add-company.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonDropdownModel, ContactInfoList, DeleteContactAddress, InsertContactInfo } from '../add-company.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommonModelPopUpComponent } from 'src/app/shared/components/common-model-pop-up/common-model-pop-up.component';
import { PopUpTitles } from 'src/app/shared/model/enum';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent implements OnInit {
  contactInfoAddvm = new ContactInfoList();
  contactInfoAddList: ContactInfoList[] = [];
  timezoneList: CommonDropdownModel[] = [];
  genderList: CommonDropdownModel[] = [];
  departmentList: CommonDropdownModel[] = [];
  insertContactInfo = new InsertContactInfo();
  comapanyIdFor: number;
  accountTypeId: any;
  enumPopUpTitles = PopUpTitles;
  contactID:any[]=[];
  getContactInfoData = [];
  @Input() contactHeadName:string=''
  submitcontcat:boolean =false; 
  deleteContact = new DeleteContactAddress();
  emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  isEmailInvalid: boolean = false;

  constructor(public companiesService: AddCompanyService,     private toaster: ToastrService, private modalService: NgbModal, public commonTabService: CommonTabService) { }

  ngOnInit(): void {
    this.contactHeadName
    this.addAnotherContact();
    this.timezoneList = this.commonTabService.getAllDropdownList.getValue().timezoneList;
    this.genderList = this.commonTabService.getAllDropdownList.getValue().genderList;
    this.departmentList = this.commonTabService.getAllDropdownList.getValue().departmentList;
  }

  addAnotherContact(): void {
    this.contactInfoAddvm = new ContactInfoList();
    this.contactInfoAddList = [...this.contactInfoAddList, this.contactInfoAddvm]
  }

  getvalues(event) {
    this.accountTypeId = event;
  };

  openReplacePrimaryPopup(contactInfo, index: number) {
    const primaryContacts = this.contactInfoAddList.filter(x => x.markAsPrimaryContact === true);
    const allHaveNames = primaryContacts.every(contact => contact.firstName && contact.lastName);
    if(allHaveNames){
     if(primaryContacts.length > 1 ){
    const modalOption: NgbModalOptions =
    {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: 'markAsActiveInactive'
    }
    const modalRef = this.modalService.open(CommonModelPopUpComponent, modalOption);
    modalRef.componentInstance.title = this.enumPopUpTitles.REPLACE_PRIMARY_CONTACT;
    modalRef.result.then((result) => {
      if (result.confirm) {
        this.contactInfoAddList.map(x => x.markAsPrimaryContact = false)
        contactInfo.markAsPrimaryContact = result.confirm;
      } else {
        contactInfo.markAsPrimaryContact = result.confirm;
      }
    })
   }else{
   
   }
  }else{
    setTimeout(() => {
      contactInfo.markAsPrimaryContact = false;      
    }, 100);

    this.toaster.error("Fill All Required Fields")
  }
  }
  deleteAddress(value, index)
  {
    if(value.id){
    this.contactID.push(value.id);
    this.deleteContact.ids = this.contactID;
    this.companiesService.DeleteContact(this.deleteContact).subscribe((res) =>{
      if(res.isSuccess){
        this.contactInfoAddList.splice(index, 1);
       this.toaster.success(res.message);
      }
    })
  }else
  {
    this.contactInfoAddList.splice(index, 1);
  }

  }

  checkRequiredContactValue(): string 
  {
    if (this.contactInfoAddList.every(check => check.firstName && check.lastName)) 
    {
      if (this.isEmailInvalid) {
        this.submitcontcat = true
        return "Contact Email";
       }
      return "Contact Success";
    } else 
    {
      this.submitcontcat = true;
    }
  }
  onEmailChange(index: number): void {
    this.isEmailInvalid = false;
    this.submitcontcat = false;
    const email = this.contactInfoAddList[index].email;
    if (email !== '' && email !== undefined) {
      this.isEmailInvalid = !this.emailPattern.test(email);
    }
  }
}
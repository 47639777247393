import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Path } from 'src/app/shared/model/enum';
import { TabsService } from '../../tabs/tabs.service';
import { Router } from '@angular/router';
import { CompaniesService } from 'src/app/components/crm/companies/pages/companies/companies.service';
import { Menu } from 'src/app/shared/services/nav.service';

@Component({
  selector: 'app-vendor-renderer',
  templateUrl: './vendor-renderer.component.html',
  styleUrls: ['./vendor-renderer.component.scss']
})
export class VendorRendererComponent implements OnInit,ICellRendererAngularComp {
  dataToShow = ''
  params: any
  enumPath = Path;
  currentTab = ''
  url:any;
  constructor(private tabsService: TabsService, private router: Router, public companiesService: CompaniesService) { }
  agInit(params: ICellRendererParams<any, any>): void {
    this.dataToShow = params.value
    this.params = params
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false
  }

  ngOnInit(): void {
    this.tabsService.currentTab.subscribe(res => {
      this.currentTab = res;
    })

  }



  changeRoute(params) {
    if( this.currentTab =='contacts'){
    this.companiesService.id=params.data.companyId;
    this.url = `crm/companies/company-info/${this.companiesService.id}`
    }else if( this.currentTab =='vendor-contact'){
      this.companiesService.id=params.data.vendorId;
      this.url = `procurement/vendor-info/${this.companiesService.id}`
    }else if(params.data.saleStageId !=='4'){
      this.companiesService.id=params.data.id;
      this.url = `crm/companies/company-info/${this.companiesService.id}`
    }
    else{
      this.companiesService.id=params.data.id;
      this.url = `procurement/vendor-info/${this.companiesService.id}`

    }
    let id = params.value
    const item: Menu = { path:  this.url, icon: '', title: params.value, type: 'link',id:params.data.id}
    this.tabsService.addTab(item);
    this.router.navigateByUrl( this.url);
    if(params.data.fullName){
      this.companiesService.activeTab.next(3);
    }else{
      this.companiesService.activeTab.next(1);
    }

  }
}

<div>
    <div class="modal-header p-4 pb-0 border-0 justify-content-center">
      <h6 class="modal-title mt-3 text-center text-primary-color font-15">Mark Complete</h6>
    </div>
    <div class="modal-body p-4">
      <div class="text-center">
        <p class="m-0 text-light-gray font-12"> Are you sure you want to close this activity?</p>
      </div>
    </div>
    <div class="modal-footer p-4 pt-2 border-0 d-flex flex-column justify-content-center">
      <div class="mb-4">
        <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info font-11'" class="me-3" (click)="activeModal.close()" [buttonLable]="enumButtonName.CANCEL"></app-button>
        <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-primary font-11'" class="ms-3" (click)="activeModal.close('Confirm')" [buttonLable]="enumButtonName.CONFIRM"></app-button>
      </div>
      <div *ngIf="activityIdsList.length <= 1"  class="mb-3" (click)="activeModal.close('ConfirmandClone')" ><span class="hand text-semi-primary font-12">Confirm and Clone Activity</span></div>
      <div class="mb-3" (click)="activeModal.close('ConfirmandCreateNew')"><span class="hand text-semi-primary font-12">Confirm and Create New</span></div>
    </div>
  </div>
<div class="px-0 hand" style="font-size: 12px"   (click)="passId(data?.id)">
  <div
    class=" p-2 order-deatil-container {{
      chageBgcolor == true ? 'change-bgcolor' : ''
    }}"
    [ngClass]="getclass"
  >
    <div class="d-flex justify-content-between m-r-10">
      <div class="d-flex align-items-center text-truncate gap-3">
        <p class="text-primary-color m-l-10 hand text-truncate" ngbTooltip="{{data?.title}}">
        <!-- style="max-width: 150px" -->
          {{ data?.title ? data?.title : "Call For Order Details" }}
        </p>
        <img
          *ngIf="istrue && iconHide"
          (click)="toogleIcon = !toogleIcon; toggle.emit(toogleIcon)"
          class="m-l-5 hand"
          height="15"
          width="15"
          src="./assets/SVGIcons/{{ toogleIcon ? 'uparrow' : 'downarrow' }}.svg"
          alt=""
        />
      </div>
      <div class="d-flex align-items-center">
        <p *ngIf="istrue" class="px-2 font-10 text-danger-color detail-status">
          {{ data?.statusName }}
        </p>
        <div class="d-flex align-items-center mx-3">
          <i
            *ngIf="data?.priorityName == 'High'"
            class="fa fa-circle pe-2 orange-circle"
          ></i>
          <i
            *ngIf="data?.priorityName == 'Critical'"
            class="fa fa-circle pe-2 red-circle"
          ></i>
          <i
            *ngIf="data?.priorityName == 'Low'"
            class="fa fa-circle pe-2 blue-circle"
          ></i>
          <i
            *ngIf="data?.priorityName == 'Standard'"
            class="fa fa-circle pe-2 yellow-circle"
          ></i>
          <span class="text-info-color">{{ data?.priorityName }}</span>
        </div>
        <img
          *ngIf="istrue && data?.statusName === 'Open'"
          class="m-l-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/grey-circle.svg"
         (click)="chageBgcolor == true ?'': markAsComplete(data?.id)"
          alt=""
        />
        <img
          *ngIf="istrue && data?.statusName === 'Closed'"
          class="m-l-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/circleTick.svg"
          alt=""
        />
        <img
          *ngIf="istrue && data?.statusName === 'Cancelled'"
          class="m-l-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/disabled-circle-tick.svg"
          alt=""
        />
        <p *ngIf="!istrue" class="font-11 text-secondary">
          {{ data?.dueDateInFormat }}
        </p>
      </div>
    </div>
    <div class="m-l-10 m-t-5  text-truncate" *ngIf="istrue">
      <span class="text-decoration-underline text-semi-primary" href="" (click)="openContactInfo(data)">{{
        data?.contactName
      }} </span>
      <span *ngIf="data?.contactName" class="text-light-info mx-2">/</span>
      <span class="text-decoration-underline text-semi-primary" href="" (click)="openCompanyInfo(data)">{{
        data?.companyName
      }}</span>
      <span *ngIf="data?.companyName" class="text-light-info ms-3 me-2">/</span>
      <span class="text-light-info" href="">{{ data?.salesStageName }}</span>
    </div>
    <div class="m-l-10 m-t-5  text-truncate m-r-10">
      <i class="" *ngIf="hideData">
        <p class="text-truncate descriptionWidth font-10 text-light-info">
          {{ data?.description }}
        </p>
      </i>
    </div>
    <div class="d-flex justify-content-between m-l-10 m-t-10 m-r-10  text-truncate">
      <div class="d-flex">
        <p *ngIf="!istrue" class="text-decoration-underline text-semi-primary">
          {{ data?.contactName }}
        </p>
        <app-svg-icon
        *ngIf="istrue && data?.typeName === 'Call'"
        class="m-r-5"
        [src]="'./assets/SVGIcons/phone-call.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf="istrue && data?.typeName === 'Email'"
        class="m-r-5"
        [src]="'./assets/SVGIcons/Emails.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf="istrue && data?.typeName === 'Quote'"
        class="m-r-5"
        [src]="'./assets/SVGIcons/Quotes.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf="istrue && data?.typeName === 'Reminder'"
        class="m-r-5"
        [src]="'./assets/SVGIcons/Reminders.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf="istrue && data?.typeName === 'Note'"
        class="m-r-5"
        [src]="'./assets/SVGIcons/Notes.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf="istrue && data?.typeName === 'Task'"
        class="m-r-5"
        [src]="'./assets/SVGIcons/Tasks.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf="istrue && data?.typeName === 'Meeting'"
        class="m-r-5"
        [src]="'./assets/SVGIcons/Meeting.svg'"
        ></app-svg-icon>
        <app-svg-icon
        *ngIf="istrue && data?.typeName === 'File'"
        class="m-r-5"
        [src]="'./assets/SVGIcons/File.svg'"
        ></app-svg-icon>


        <!-- <img
          *ngIf="istrue && data?.typeName === 'Call'"
          class="m-r-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/phone-call.svg"
          alt=""
        /> 
        <img
          *ngIf="istrue && data?.typeName === 'Email'"
          class="m-r-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/Emails.svg"
          alt=""
        />
        <img
          *ngIf="istrue && data?.typeName === 'Quote'"
          class="m-r-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/Quotes.svg"
          alt=""
        />
        <img
          *ngIf="istrue && data?.typeName === 'Reminder'"
          class="m-r-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/Reminders.svg"
          alt=""
        />
        <img
          *ngIf="istrue && data?.typeName === 'Note'"
          class="m-r-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/Notes.svg"
          alt=""
        />
        <img
          *ngIf="istrue && data?.typeName === 'Task'"
          class="m-r-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/Tasks.svg"
          alt=""
        />
        <img
          *ngIf="istrue && data?.typeName === 'Meeting'"
          class="m-r-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/Meeting.svg"
          alt=""
        />
        <img
          *ngIf="istrue && data?.typeName === 'File'"
          class="m-r-5"
          height="15"
          width="15"
          src="./assets/SVGIcons/File.svg"
          alt=""
        /> -->
        <p *ngIf="istrue" class="font-10 text-light-info">{{ data?.typeName }}</p>
      </div>
      <div class="">
        <p *ngIf="istrue" class="font-10 text-light-info">
          Assigned: {{ data?.assignedUserName }}
        </p>
      </div>
      <div class="">
        <i
          *ngIf="istrue && data?.statusName == 'Open'"
          class="font-10"
          [ngClass]="{
            'text-red-color': data?.aging?.toLowerCase().includes('past due'),
            'text-light-info': !data?.aging?.toLowerCase().includes('past due')
          }"
          >{{ data?.aging }}</i>
          <i
          *ngIf="istrue && data?.statusName == 'Closed'"
          class="font-10"
          [ngClass]="{
            'text-red-color': data?.aging?.toLowerCase().includes('past due'),
            'text-light-info': !data?.aging?.toLowerCase().includes('past due')
          }"
          >{{ data?.aging }}</i>
          <i
          *ngIf="istrue && data?.statusName == 'Cancelled'"
          class="font-10"
          [ngClass]="{
            'text-red-color': data?.aging?.toLowerCase().includes('past due'),
            'text-light-info': !data?.aging?.toLowerCase().includes('past due')
          }"
          >{{ data?.aging }}</i>

      </div>
      <div class="">
        <p
          *ngIf="istrue && currentTab !== 'activityManager'"
          class="font-10  m-b-10 fst-italic text-secondary"
        >
          {{ data?.dueDateInFormat }}
        </p>
        <p
          *ngIf="currentTab == 'activityManager'"
          (click)="datePicker(data.id)"
          class="font-10 text-secondary m-b-10 fst-italic hand"
        >
          {{ data?.dueDateInFormat }}
        </p>

        <p *ngIf="!istrue" class="f-12 text-info-color">
          Completed by {{ data?.completedBy }}
        </p>
      </div>
    </div>
  </div>
  <div class="tooltip-container">

   <div *ngIf="data.isShowPopup" class="custom-tooltip">
      <div
        class="with-arrow text-center datepicker bg-light-color mx-xl-3 ms-md-5 me-md-3 m-b-10 px-3 py-2"
      >
        <span class="font-11 text-light-info">Snooze</span>
        <div class="d-flex justify-content-center align-item-center mt-1 mb-2">
          <span
            class="font-11 hand text-gray text-nowrap"
            (click)="addUpdateSnooz(1)"
            >24 Hours</span
          >
          <div class="vr"></div>
          <span
            class="font-11 hand text-gray text-nowrap"
            (click)="addUpdateSnooz(2)"
            >48 Hours</span
          >
          <div class="vr"></div>
          <span
            class="font-11 hand text-gray text-nowrap"
            (click)="addUpdateSnooz(3)"
            >1 Week</span
          >
        </div>
        <hr />
        <div class="my-2">
          <span class="font-11 text-light-info">Change Due Date</span>
          <form
            class="theme-form needs-validation novalidate"
            #f="ngForm"
            novalidate
          >
            <!-- <div class="mt-2">
              <div class="date-container d-flex">
                <input
                  ngModel
                  #dateExpiration="ngModel"
                  class="px-1 hand form-control"
                  type="date"
                  name="dateExpiration"
                  id="date"
                  [(ngModel)]="selectedDate"
                  [ngClass]="{ borders: dateExpiration.invalid && submit }"
                  (change)="changeDateExpiration($event.target.value)"
                  required
                />
                <label
                  for="date"
                  class="label1"
                  [ngClass]="{ label2: selectedDate != '' && hideIcon == false }"
                >
                  Date
                  <i class="fst-normal ps-1 p-0 text-danger"></i>
                </label>
              </div>
              <span
                [ngClass]="{ 'd-none': hideIcon == true || selectedDate == '' }"
                (click)="clear(f)"
                class="refresh-icon"
                ><app-svg-icon
                  class="svgIcon"
                  [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
                ></app-svg-icon
              ></span>
            </div> -->
            <div class="mt-2 datepicker-label orderDetail detail">
           <app-date-picker
              [time]="false"
              [inputLabel]="'Date'"
              [inputValue]="selectedDate"
              (inputValueEmit)="changeDateExpiration($event);onClick()"
            ></app-date-picker> 
            <!-- <app-single-date-select-picker
            (changeDateExpiration)="changeDateExpirations($event)"
            (clearDate)="clear(f)"
            ></app-single-date-select-picker>  -->
            </div>
            <!-- <div class="mt-3">
              <div class="date-container d-flex">
                <input
                  ngModel
                  #timeExpiration="ngModel"
                  class="px-1 hand form-control"
                  type="time"
                  name="timeExpiration"
                  id="time"
                  [(ngModel)]="selectedTime"
                  [ngClass]="{
                    borders: timeExpiration.invalid && submit,
                    nobg: selectedTime == ''
                  }"
                  (change)="changeTimeExpiration($event.target.value)"
                  required
                />
                <label
                  for="time"
                  class="label1"
                  [ngClass]="{ label2: selectedTime != '' && hideIcon == false }"
                >
                  Time
                  <i class="fst-normal ps-1 p-0 text-danger"></i>
                </label>
              </div>
              <span
                [ngClass]="{ 'd-none': hideIcon == true || selectedTime == '' }"
                (click)="clearTime(f)"
                class="refresh-icon"
                ><app-svg-icon
                  class="svgIcon"
                  [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
                ></app-svg-icon
              ></span>
            </div> -->
            <div class="mt-3">
              <div class="date-container d-flex">
                <input
                  [(ngModel)]="selectedTimeValue"
                  readonly
                  class="px-1 hand form-control time-input"
                  required

                />
                <label
                for="time"
                class="label1"
                [ngClass]="{'label2' : selectedTime != '' && hideIcon == false}">
                 Time 
                <i class="fst-normal ps-1 p-0 text-danger"></i>
              </label>
                <!-- <i class="fas fa-clock" (click)="togglePopover()"></i> -->
                <img width="14px" src="../../../assets/SVGIcons/clockIcon.svg"  (click)="togglePopover()" alt="" class="hand clock">
              </div>
              <span
              [ngClass]="{'d-none': hideIcon == true ||  selectedTime == ''}"
              (click)="clearTime(f); "
              class="refresh-icon"><app-svg-icon
                class="svgIcon"
                [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
              ></app-svg-icon
            ></span>
              <ng-container *ngIf="isPopoverOpen">
                <ngb-popover placement="bottom" [autoClose]="false">
                  <ngb-timepicker 
                   [id]="'timePicker1'" 
                   [name]="'timePicker1'" 
                   [(ngModel)]="ngbTimePickerValue"
                   (ngModelChange)="changeTimeExpiration($event)"
                   [meridian]="true" class="time-picker-custom timepicker"></ngb-timepicker>
                </ngb-popover>
              </ng-container>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>



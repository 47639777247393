<div class="container-fluid">
  <div class="d-flex justify-content-center">
    <div class="modal-content1">
      <div class="modal-body modal-wrapper px-5">
        <div class="row">
          <div class="col-12 file-div-wrap" [ngClass]="{ 'border-red-dropzone': !file && submit }">
            <button class="file-upload-button">
              <ng-container *ngIf="firstContainer">
                <svg class="file-icon" xmlns="http://www.w3.org/2000/svg" width="38.086" height="37"
                  viewBox="0 0 38.086 50">
                  <g id="document" transform="translate(-5.957)">
                    <path id="Path_970" data-name="Path 970"
                      d="M42.363,10.378,32.947,1.469A5.35,5.35,0,0,0,29.256,0H11.328A5.377,5.377,0,0,0,5.957,5.371V44.629A5.377,5.377,0,0,0,11.328,50H38.672a5.377,5.377,0,0,0,5.371-5.371V14.279a5.393,5.393,0,0,0-1.68-3.9Zm-2.845,1.341H32.227a.489.489,0,0,1-.488-.488V4.359ZM38.672,47.07H11.328a2.444,2.444,0,0,1-2.441-2.441V5.371A2.444,2.444,0,0,1,11.328,2.93h17.48v8.3a3.422,3.422,0,0,0,3.418,3.418h8.887v29.98A2.444,2.444,0,0,1,38.672,47.07Z"
                      fill="#8b9daa" />
                    <path id="Path_971" data-name="Path 971"
                      d="M35.449,19.531H13.965a1.465,1.465,0,0,0,0,2.93H35.449a1.465,1.465,0,0,0,0-2.93Z"
                      fill="#8b9daa" />
                    <path id="Path_972" data-name="Path 972"
                      d="M35.449,27.344H13.965a1.465,1.465,0,0,0,0,2.93H35.449a1.465,1.465,0,0,0,0-2.93Z"
                      fill="#8b9daa" />
                    <path id="Path_973" data-name="Path 973"
                      d="M21.066,35.156h-7.1a1.465,1.465,0,0,0,0,2.93h7.1a1.465,1.465,0,0,0,0-2.93Z" fill="#8b9daa" />
                  </g>
                </svg>
                <input title="" type="file" class="mx-auto" ID="fileSelect" (change)="upload($event)"
                  accept=".xlsx, .xls, .csv" />
                <label for="" class="file-lable1 mt-3">{{ importCompanyVM.lable1 }} </label>
                <label class="file-lable2 font-10">{{ importCompanyVM.lable2 }}</label>
              </ng-container>
              <div *ngIf="uploadSuccess" class="d-flex justify-content-end w-100 "><app-svg-icon (click)="newImport()"
                  [src]="'../../../../../assets/SVGIcons/circle-close.svg'"></app-svg-icon> </div>
              <ng-container *ngIf="uploadSuccess" class="success">
                <div class="row">
                  <div class="col-12">
                    <img src="./assets/SVGIcons/uploadesuccess.svg" alt="" width="40" height="40" />
                  </div>
                </div>
                <label class="sucess mt-3 text-gray">{{ importCompanyVM.lable4 }}</label>
                <div class="col-12" *ngFor="let item of file">
                  <span class="text-light-gray font-12">{{ item.name }}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="hideErrordiv">
                <div class="row">
                  <div class="col-12">
                    <img src="./assets/SVGIcons/Erroricon.svg" alt="" width="40" height="40" />
                  </div>
                  <div class="col-12 mt-3">
                    <p class="text-gray">Error! Please try uploading your files again.</p>
                    <p class="text-decoration-underline mt-3"
                      [ngClass]=" hideErrordiv === true  ? 'text-primary-color'  : 'text-gray' "
                      (click)="showuploadefile()">
                      Upload file
                    </p>
                  </div>
                  <div></div>
                </div>
              </ng-container>
            </button>
          </div>
          <div
            class="col-12  ps-0 d-flex  p-r-0 m-t-5 
            {{ errorMessageSupported == true && fileNotMatchError == false  ? 'mb-0' : 'mb-3' }}
            {{ fileNotMatchError == false || errorMessageSupported == true || dataBlank==true || headerValue  ? 'justify-content-between' : 'justify-content-end' }}">
            <div class="d-flex flex-column">
              <div *ngIf="errorMessage" class="text-red font-11 {{ errorMessageSupported == true  ? 'mb-2' : 'mb-0' }}">
                Error! Your file could not be uploaded.</div>
              <div *ngIf="errorMessageSupported" class="text-red font-11">Error! This file type is not supported.</div>
              <div *ngIf="!fileNotMatchError" class="text-red font-11">Error! File upload is incompatible with the
                selected template.</div>
                <div *ngIf="dataBlank" class="text-red font-11">Error!! No record available in imported file.</div>
            <div *ngIf="headerValue" class="text-red font-11">{{headerText}}</div>
              </div>
            <div class="download-svg">
              <a class="d-flex align-items-center"
               [href]="currentTab === tabName.CODECROSSWALK_TAB ? sourcePathCodecrosswalk : routeUrl == routePath.IMPORTVENDOR_ROUTEPATH? sourceVendorPath : sourcePath"
               [download]="fileName"
               [ngClass]="hideErrordiv === true ? 'pe-none' : ''">
                <app-svg-icon [ngClass]=" hideErrordiv === true ? 'svgicon' : 'download-svg-icon' "
                  [src]="'./assets/SVGIcons/download_greycolor.svg'">
                </app-svg-icon>
                <span class="ps-1 font-11"
                  [ngClass]=" !hideErrordiv === true   ? 'text-semi-primary'   : 'text-light-gray'">Download Sample
                  File</span>
              </a>
            </div>
          </div>
          <div class="col-12 ps-0 m-b-10" *ngIf="currentTab !== tabName.CODECROSSWALK_TAB">
            <h6 class="text-gray">Assign</h6>
            <span *ngIf="routeUrl == routePath.IMPORTVENDOR_ROUTEPATH"
            class="text-light-gray font-10">
              Salesperson is required for “Mark as Company” vendors.</span>
          </div>
          <div class="col-12 assign-box" [style]="{ height: flexHeight }">
            <div class="row" *ngIf="currentTab !== tabName.CODECROSSWALK_TAB">
              <div class="col-6 ps-0">
                <app-dropdown [selectLabel]="'Salesperson'" [data]="salesPersonList" [required]="true"
                  [ngClass]="{'border-red': !insertUpdateImportDocumnets.importCompanyModel.salesPersonId && submit }"
                  [optionDisable]="insertUpdateImportDocumnets.importCompanyModel.altSalesPersonId "
                  [selectValue]="insertUpdateImportDocumnets.importCompanyModel.salesPersonId "
                  (selectValueEmit)="insertUpdateImportDocumnets.importCompanyModel.salesPersonId = $event"></app-dropdown>
              </div>
              <div class="col-6 pe-1">
                <app-dropdown [selectLabel]="'Buyer’s Rep'" 
                [data]="buyerRepList"
                [required]="routeUrl == routePath.IMPORTVENDOR_ROUTEPATH?true:false"
                [ngClass]="{'border-red': !insertUpdateImportDocumnets.importCompanyModel.buyersId && submit && routeUrl == routePath.IMPORTVENDOR_ROUTEPATH }"
                  (selectValueEmit)="insertUpdateImportDocumnets.importCompanyModel.buyersId = $event "
                  [selectValue]="insertUpdateImportDocumnets.importCompanyModel.buyersId"
                  ></app-dropdown>
              </div>
            </div>
            <div class="row m-t-25" *ngIf="currentTab !== tabName.CODECROSSWALK_TAB">
              <div class="col-6 ps-0">
                <app-dropdown [selectLabel]="'Alt Salesperson'" [data]="altSalePersonList"
                  [optionDisable]="insertUpdateImportDocumnets.importCompanyModel.salesPersonId"
                  (selectValueEmit)="insertUpdateImportDocumnets.importCompanyModel.altSalesPersonId = $event"
                  [selectValue]="insertUpdateImportDocumnets.importCompanyModel.altSalesPersonId"
                  ></app-dropdown>
              </div>
              <div class="col-6 pe-0"></div>
            </div>
            <div class="row m-t-20">
              <div class="col-12 mt-2 ps-0">
                <h6 class="text-gray">Mapping Template</h6>
              </div>
              <div class="col-6 m-t-10 ps-0">
                <div>
                  <app-custom-select  [options]="mappingTemplateList"
                    (select)="selectmappingTemplateId($event); showDescription() ; base64OfFile ? checkingValidateFiles():''"
                    (inputValueEmit)="updateList()"
                    [importCodecrosswalk]="currentTab === tabName.CODECROSSWALK_TAB"
                    ></app-custom-select>
                </div>
                <div class="mt-1">
                  <p class="font-10 text-light-gray mt-1 p-0 m-0 text-truncate width-para"
                    ngbTooltip="{{ insertUpdateImportDocumnets?.templateData ?.templateDescription }}">
                    ({{ insertUpdateImportDocumnets.templateData .templateDescription == ""
                    ||insertUpdateImportDocumnets.templateData .templateDescription == null
                    ? "Template Description shows here" : insertUpdateImportDocumnets.templateData .templateDescription
                    }})
                  </p>
                </div>
              </div>
              <div class="col-6 pe-0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0 pb-10">
    <div class="justify-content-end">
      <app-button *ngIf="currentTab !== tabName.CODECROSSWALK_TAB "
       [button_2]="true" [buttonClass]="'button button-info'" [buttonLable]="enumButtonName.CANCEL"
        (click)="closeTab()">
      </app-button>
      <app-button *ngIf="currentTab === tabName.CODECROSSWALK_TAB "
      [button_2]="true" [buttonClass]="'button button-info'" [buttonLable]="enumButtonName.CANCEL"
      (click)="close()">
      </app-button>
      <app-button *ngIf="currentTab !== tabName.CODECROSSWALK_TAB"
       class="ms-4" (click)="navigateToMapTab()" [button_2]="true" [buttonClass]="( uploadSuccess && !headerValue && !emptyFile && fileNotMatchError && insertUpdateImportDocumnets?.importCompanyModel?.salesPersonId )?'button button-primary':'button dark-info'"
        [buttonLable]="enumButtonName.NEXT" >
      </app-button>
      <app-button *ngIf="currentTab == tabName.CODECROSSWALK_TAB"
       class="ms-4" (click)="navigateToMapTab()" [button_2]="true" [buttonClass]="( uploadSuccess && !headerValue && !emptyFile && fileNotMatchError)?'button button-primary':'button dark-info'"
      [buttonLable]="enumButtonName.NEXT" [inputReadonly]="buttonDisabled">
    </app-button>

    </div>
  </div>
</div>
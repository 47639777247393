import { StorageService } from 'src/app/shared/services/storage.service';
import { SidebarNotificationService } from './../sidebar-notification/sidebar-notification.service';
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TabsService } from "./tabs.service";
import { Path } from '../../model/enum';
import { RolesPermissionsService } from 'src/app/components/admin/user-management/pages/roles-and-permissions/roles-permissions.service';
import { CommonTabService } from '../../services/common-tab.service';
import { Result } from '../../model/response.model';
import { catchError } from 'rxjs';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { AgGridPaginationConfig, PaginationBaseModel, TablePaginationModel } from '../ag-grid/ag-grid.model';
import { CompaniesService } from 'src/app/components/crm/companies/pages/companies/companies.service';
import { ActivityManagerService } from 'src/app/components/crm/activity-manager/activity-manager.service';
import { CodeCrosswalkService } from 'src/app/components/admin/code-crosswalk/code-crosswalk.service';
import { SignalRService } from '../../services/signal-r.service';

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements OnInit {
  tabs = [];
  activeTabUrl;
  enumPath = Path;
  @Input() required: boolean = false;
  isSidebarNotificationShow: boolean;
  paginationBaseModel = new TablePaginationModel();
  agGridPaginationConfig = new AgGridPaginationConfig();

  constructor(private tabsService: TabsService,
    public codeCrosswalkService: CodeCrosswalkService,
    private router: Router,
    public agGridService: AgGridService,
    public companiesServices: CompaniesService,
    public rolesPermissionsService: RolesPermissionsService,
    public activityService: ActivityManagerService,
    public sidebarNotificationService: SidebarNotificationService, private commonTabService: CommonTabService,
    public storageService: StorageService,
    public signalRService: SignalRService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeTabUrl = event.urlAfterRedirects.substring(1);
        if (this.tabs.length === 0) {
          this.tabsService.addTab(this.activeTabUrl);
        }
      }
    });
  }

  deviceWidth: any;


  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.deviceWidth = event.target.innerWidth;
  }
  @HostListener("window:beforeunload", ["$event"])
  onReload() {
    this.storageService.store("allTabs", this.tabs);
    this.storageService.store("companyId", this.companiesServices.id);
  }

  ngOnInit(): void {
    this.companiesServices.id = this.storageService.retrieve("companyId");
    this.deviceWidth = window.innerWidth;
    this.getAllDropdownListData();
    this.getAllMentionList();
    this.tabsService.addedTabs.subscribe((res) => {
      this.tabs = this.storageService.retrieve("allTabs");
      if (this.tabs.length == 0) {
        this.tabs = res;
      }
      this.tabs.map((value) => {
        if (!this.tabsService.allOpenedTabs.includes(value.path + '/')) {
          this.tabsService.allOpenedTabs.push(value.path + '/');
        }
      });
    });

    this.sidebarNotificationService.isSidebarNotificationShow.subscribe((res: boolean) => {
      this.isSidebarNotificationShow = res;
      this.sidebarNotificationService.headerIconActive.next('')
    })
    // this.getAccessPermissions();
  }
  getAllDropdownListData() {
    this.commonTabService.getAllDropDownList().pipe(catchError((err) => this.handleError(err))).subscribe((res: Result) => {
      res.isSuccess == true ? this.commonTabService.getAllDropdownList.next(res.response) : console.log(res.message);
    });
  }

  getAllMentionList(){
    // this.activityService.getAllMentionData('').subscribe(res=>{
    //   this.activityService.getMentionList.next(res?.response)
    //     // this.items = res.response;
    // })
  }

  getAccessPermissions() {
    this.commonTabService.getAccessPermission().subscribe(res => {
      if (res?.isSuccess) {
        this.storageService.store("getAccessPermissions", res?.response);
      }
    })
  }



  handleError(err: any): any { console.log(err) }
  id: string;
  closeTab(index: number, event: Event) {  
    console.log('closeddddd')
    this.agGridService.onPageChanged(0)
    this.agGridService.agGridPaginationConfig.pageSize = 25
    if (index > 0) {

      if (this.activeTabUrl == this.tabs[index].path) {
        this.router.navigateByUrl(this.tabs[index - 1].path);
        this.id = this.tabs[index - 1].id;
        let cleanName = this.tabs[index - 1].path.substring(this.tabs[index - 1].path.lastIndexOf('/') + 1);
        // cleanName = cleanName.charAt(0).toUpperCase() + cleanName.slice(1);
        this.tabsService.currentTab.next(cleanName);
        console.log('the clearn name in$$$$$ ', cleanName)
      }

    }
    if (this.tabs.length > 0) {
      this.tabsService.allOpenedTabs = this.tabsService.allOpenedTabs?.filter(
        (value) => value != this.tabs[index]?.path + '/'
      );
    }
    this.tabsService.deleteTab(index);
    const data=  this.tabs.find(a=>a.path===this.activeTabUrl)??'';
    if (index == 0) {
      this.tabsService.allOpenedTabs = []
      if (this.tabs.length == 1) {
        this.router.navigateByUrl(this.tabs[index].path);
      } 
      //om add this code for indexing issue line no 124 to 129
      else if(data!=''){
        console.log("data.......",data)
      }
      else if (this.tabs.length > 1) {
        this.router.navigateByUrl(this.tabs[index].path);
      }
      // om comments yaseen code else if condition
      // else if (this.tabs.length > 1) {
      //   this.router.navigateByUrl(this.tabs[index + 1].path);
      // }
    }
    event.preventDefault();
    if (this.id !== undefined) {
      this.companiesServices.id = this.id;
      this.companiesServices.paramsss.next(true);
    }
  }


  onTabChange(event) {
    console.log('tab-changed ')
    this.agGridService.starInfo = [{id:0, stars:0}]
    this.agGridService.onPageChanged(0)
    this.agGridService.agGridPaginationConfig.pageSize = 25
    this.paginationBaseModel.searchText = '';
    this.codeCrosswalkService.valueSearchtext.next('');
    this.companiesServices.id = this.tabs?.find(a => a?.path === event.nextId)?.id
    if (event.activeId === this.enumPath.USER_ROLE_PATH && this.rolesPermissionsService.isEdited) {
      this.rolesPermissionsService.rolesPageChanged.next(event.nextId);
    }
    else {
      this.router.navigateByUrl(event.nextId);
    }

    // let cleanName = event.nextId.replace('crm/', '')
    let cleanName = event.nextId.substring(event.nextId.lastIndexOf('/') + 1);
    // cleanName = cleanName.charAt(0).toUpperCase() + cleanName.slice(1);
    this.tabsService.currentTab.next(cleanName);
    if (event.nextId.includes('crm/companies/company-info/')) {
      this.companiesServices.paramsss.next(true);
    }

  }

  closeAll(){
    this.tabs=[];
    this.tabsService.tabs=[];
    this.tabsService.allOpenedTabs = [];
    this.storageService.store("allTabs",[]);
    this.router.navigateByUrl('crm');
  }

  toggleIsSidebarNotificationShow(){
    this.isSidebarNotificationShow =false
    this.sidebarNotificationService.headerIconActive.next(false)
  }


}

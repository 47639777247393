import { TabsService } from './../../../shared/components/tabs/tabs.service';
import { Router } from "@angular/router";
import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { StorageService } from "src/app/shared/services/storage.service";
import { LoginService } from "./login.service";
import { LoginModel } from "./login.model";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit 
{
  imageUrl              = " ../../assets/bgImage.png";
  public show           : boolean = false;
  public loginFor       : FormGroup;
  public errorMessage   : any;
  userid                : any;
  formId                : number = 1;
  loginModel            = new LoginModel();
  defaultLoginData      : LoginModel = {
    email: "",
    password: "",
    rememberMe: true,
    timeZone: "",
    deviceToken: "",
  };
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private config: NgbModalConfig,
    public renderer: Renderer2,
    public loginService: LoginService,
    public storageservice: StorageService,
    public tabsService:TabsService
  ) {}

  ngOnInit(): void {
    this.loginModel = this.defaultLoginData;
  }

  //#region onLogin

  onLogin() {
    this.router.navigate(["/crm"]);
  }
  //#endregion

  authenticate() {
    this.loginService.SignIn(this.loginModel).subscribe((res) => {
      if (res.isSuccess) {
        this.storageservice.store( "authorizationDataIdToken", res.response.token.accessToken);
        this.storageservice.store("user", res.response.user);
        this.userid = res?.response?.user?.id;
        this.storageservice.store("userId", this.userid);
        this.router.navigate(["/crm"]);
        this.storageservice.store("allTabs",[]);
        this.tabsService.addedTabs.next([]);
      }
    });
  }
  logout() {
    this.loginService.SignOut().subscribe((res) => {
        if (res.isSuccess) {
          this.router.navigate(["auth/login"]);
        }
        this.toaster.success(res?.message);
      });
  }

  
}

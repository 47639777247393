<div #clockElement class="">
    <div class="time-container form-floating d-flex " id="timepicker_contain" (keydown.tab)="onTabPress()">
      <input
        [(ngModel)]="selectedTimeValue"
        readonly
        class="px-1 hand form-control time-input"
        (keydown.enter)="onEnterPress()"
        (focus)="onFocus()"
        (blur)="onBlur()"
        
      />
      <label
        for="time"
        class="label1 "
        [ngClass]="{'label2' : selectedTimeValue != '' && !hideIcon, 'focused-label':isFocused}">
        Time 
        <i class="fst-normal ps-1 p-0 text-danger"></i>
      </label>
      <img width="14px" src="../../../assets/SVGIcons/clockIcon.svg" (click)="togglePopover()" alt="" class="hand clock">
    </div>
    <span
      [ngClass]="{'d-none': hideIcon || selectedTimeValue == ''}"
      (click)="clearTime();"
      class="refresh-icon">
      <app-svg-icon class="svgIcon" [src]="'../../../../assets/SVGIcons/undoSVG.svg'"></app-svg-icon>
    </span>
    <ng-container *ngIf="isPopoverOpen" id="isPopoverOpen">
      <ngb-popover placement="bottom" [autoClose]="false" >
        <ngb-timepicker 
          [id]="'timePicker1'" 
          [name]="'timePicker1'" 
          [(ngModel)]="ngbTimePickerValue"
          (ngModelChange)="changeTimeExpiration($event);changeTimeExpiration11($event)"
          [meridian]="true"
          class="time-picker-custom timepicker"
          >
        </ngb-timepicker>
      </ngb-popover>
    </ng-container>
  </div>
  
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgGridColumnsSettingService {

  public isCustomizerLayoutOpen = new BehaviorSubject<string>('');
  sidebarRow = new Subject<[{checked:boolean, name:string}]>();
  constructor() { }
}

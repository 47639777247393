import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UserRequestModel } from 'src/app/components/admin/user-management/pages/user-management/user-management.model';
import { UserManagementService } from 'src/app/components/admin/user-management/pages/user-management/user-management.service';
import { AgGridService } from 'src/app/shared/components/ag-grid/ag-grid.service';
import { UserList } from 'src/app/shared/model/enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ActivityManagerService } from '../../activity-manager.service';
import { MentionList } from '../../activit-manager.model';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() comment: any;
  @Input() hideIcon: boolean = true;
  @Input() forContact: boolean = false;
  @Output() getComment: EventEmitter<any> = new EventEmitter();
  showTooltip: boolean = false;
  data: any[] = [];
  tooltipPosition = {
    left: '0px',
    top: '0px'
  };
  items: MentionList[] = [];
  userIdsAndUsernames: any;
  isRightPosition = false;
  isTooltipData = false
  userRequestModel= new UserRequestModel();
  constructor(public storageservice: StorageService,
    public agGridService: AgGridService,
    public activityService: ActivityManagerService,
    public userManagementService :UserManagementService,

  ) { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const isClickInsideDiv = event.target instanceof Element && event.target.closest('.data-calsss');
    if(!isClickInsideDiv){
      this.comment.isTooltip = false

    }
    const tooltipContainer = document.querySelector('.custom-tooltip');
    if (tooltipContainer && !tooltipContainer.contains(event.target as Node)) {
      this.showTooltip = false;
    }
  }

  allUsers
  formatComment(text: string): string {
    const paragraphs = text.split(/\n\s*\n/);
  const formattedParagraphs = paragraphs.map((paragraph, index) => {
    return `<div class="textColorClass" >${paragraph}</div><br>`;
  });

  return formattedParagraphs.join('');
  }
  ngOnInit(): void {
  //  this.items = this.activityService?.getMentionList?.getValue();
    // this.userIdsAndUsernames = this.items?.map(item => ({
    //   userId: item.userId,
    //   userName: item.userName
    // }));
    this.getAllMentionList()
  }
  getAllMentionList(){
    this.activityService.getAllMentionData('').subscribe(res=>{
        this.items = res.response;
        this.userIdsAndUsernames = this.items?.map(item => ({
          userId: item.userId,
          userName: item.userName
        }));
    
    })
  }

  getTagDetail(e, comment) {
    this.isTooltipData = false

    if (e.target.tagName === 'SPAN') {
      const leftPosition = e.offsetX - 40;
      // const topPosition = e.offsetY - 20; 
      const topPosition = e.layerY + 68; 

      this.tooltipPosition.left = leftPosition >= 0 ? `${leftPosition}px` : '0px';
     // this.tooltipPosition.top = topPosition >= 0 ? `${topPosition}px` : '0px';
     this.tooltipPosition.top =`${topPosition}px`;

      var re = /@/gi;
      const outerText = e.target.outerText;
      const searchText = outerText.replace(re, "");
      const matchingUser = this.userIdsAndUsernames?.find(user => user?.userName === searchText);
      const resultUserId = matchingUser?.userName ? matchingUser?.userId : "";
      // var a = this.storageservice.retrieve(UserList.USER_LIST);
   
      this.agGridService.userList.subscribe((res) => {
        var a = res;

        this.data = a?.filter((user) => user?.fullName.toLowerCase().includes(searchText.toLowerCase()) && user?.userId === resultUserId);
        //  this.data =  a.filter(a=>a.fullName.toLowerCase().match(searchText.toLowerCase()));
        if (this.data?.length > 0 && searchText !== "") {
          this.openTooltip(comment);
        }
      })
    }
  }

  openTooltip(comment) {
    this.showTooltip = !this.showTooltip;
    // comment.isTooltip = true
    let id = comment.commentId
    this.agGridService.commentId = id
    this.activityService.comment.next(true)
    // event.stopPropagation();
    this.isTooltipData = true

  }

}

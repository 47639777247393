import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-ag-dropdown",
  templateUrl: "./ag-dropdown.component.html",
  styleUrls: ["./ag-dropdown.component.scss"],
})
export class AgDropdownComponent implements OnInit, ICellRendererAngularComp {
  active: boolean;
  @ViewChild("mySelect", { static: true }) mySelect: ElementRef;
  selectlist: any[] = ["TBD", "Poor", "Good", "Great"];
  constructor() {}
  refresh(params: ICellRendererParams<any, any>): boolean {
    throw new Error("Method not implemented.");
  }
  ngOnInit(): void {}
  agInit(params: ICellRendererParams) {}
  @HostListener("document:click", ["$event.target"])
  onClickOutside(target: HTMLElement) {
    if (!this.mySelect.nativeElement.contains(target)) {
      this.active = false;
    }
  }
}

<div class="d-flex w-100 h-100 align-items-center">
  <div *ngIf="currentTab !== enumTabNames.CONTACTS && currentTab !== enumTabNames.VENDOR_CONTACT && currentTab !== 'contact-recycle-bin'">
    <img
      class="m-r-10"
      style="cursor: grab"
      src="./assets/SVGIcons/Gridicon.svg"
      alt=""
      width="13"
      height="12"
      *ngIf="
        params.displayName != enumDisplayName.FILE_NAME &&
        params.displayName != enumDisplayName.COMPANY &&
        params.displayName != enumDisplayName.VENDOR_NAME &&
        params.displayName != enumDisplayName.FULL_NAME &&
        params.displayName != enumDisplayName.CERTIFICATION_NAME &&
        params.displayName != enumDisplayName.MESSAGE &&
        params.displayName != enumDisplayName.REQUIREMENT &&
        params.displayName != enumDisplayName.VENDORCONTACT_NAME &&
        params.displayName != enumDisplayName.MPN &&
        params.displayName != '' "/>
    <span
      class="m-r-10"
      [ngClass]="{
        'company-name':  params.displayName == enumDisplayName.COMPANY || 
        params.displayName == enumDisplayName.VENDORCONTACT_NAME || 
        params.displayName == enumDisplayName.VENDOR_NAME || 
        params.displayName == enumDisplayName.FULL_NAME ||  
        params.displayName == enumDisplayName.FILE_NAME,
        'certification-name': params.displayName == enumDisplayName.CERTIFICATION_NAME} ">
      {{ params.displayName }}
    </span>
  </div>
  <div *ngIf="currentTab == enumTabNames.CONTACTS || currentTab == enumTabNames.VENDOR_CONTACT || currentTab == 'contact-recycle-bin'">
    <img
      class="m-r-10"
      style="cursor: grab"
      src="./assets/SVGIcons/Gridicon.svg"
      alt=""
      width="13"
      height="12"
      *ngIf="params.displayName != enumDisplayName.FULL_NAME  &&  params.displayName != enumDisplayName.VENDORCONTACT_NAME &&
       params.displayName != enumDisplayName.CONTACT_NAME && params.displayName != ''"
    />
    <span
      class="m-r-10"
      [ngClass]="params.displayName == enumDisplayName.FULL_NAME || params.displayName == enumDisplayName.VENDORCONTACT_NAME ? 'company-name' : ''"
    >
      {{ params?.displayName == "company" ? "Company" : params?.displayName }}
    </span>
  </div>
  <div class="d-flex flex-column sortIcon">
    <i
      *ngIf="params.enableSorting"
      (click)="onSortRequested('asc', $event)"
      [ngClass]="ascSort"
      class="fa fa-sort-up"
    ></i>
    <i
      *ngIf="params.enableSorting"
      (click)="onSortRequested('desc', $event)"
      [ngClass]="descSort"
      class="fa fa-sort-down"
    ></i>
  </div>
</div>

import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { ImportDocumentsService } from 'src/app/components/import-documents/import-documents.service';
import { CurrentTab, FieldTypeAndPlaceHolder, RoutePath } from '../../model/enum';
import { TabsService } from '../tabs/tabs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ag-dropdown-correct-field',
  templateUrl: './ag-dropdown-correct-field.component.html',
  styleUrls: ['./ag-dropdown-correct-field.component.scss']
})
export class AgDropdownCorrectFieldComponent implements OnInit,ICellRendererAngularComp {
  active: boolean;
  @ViewChild("mySelect", { static: true }) mySelect: ElementRef;
  @Input() selectlist: any[] = [];
  params: any;
  placevalue:string;
  submit:boolean = true;
  submitted:boolean = true;
  errorselectlist;
  currentTab:string;
  tabName = CurrentTab;
  isSicCode: boolean = false;
  routePath = RoutePath;


  enumFieldTypeAndPlaceHolder = FieldTypeAndPlaceHolder;
  inputArray = ["Company", "Phone", "Email","Company Email","Accounting Contact", "Revenue","Date Contacted","Account Number", "Recent Notes", "SIC","Fax", "NAICS", "Employees","Sourcing Email","Website","SIC Code","SIC Description","NAICS Code","NAICS Year","NAICS Description","Industry"];
 inputVendorArray =  ['Vendor Name','Email Address','Phone Number','Fax Number','Address 1','Address 2'];
  inputArrayCodeCrosswalk =  [ ]
  value: any = '';
  ratingStarList =[
    {id:'star5', value:'5' , },
    {id:'star4.5', value:'4.5' , className:'halff'},
    {id:'star4', value:'4' , },
    {id:'star3.5', value:'3.5', className:'halff'},
    {id:'star3', value:'3' , },
    {id:'star2.5', value:'2.5', className:'halff'},
    {id:'star2', value:'2' , },
    {id:'star1.5', value:'1.5' , className:'halff'},
    {id:'star1', value:'1' , },
    {id:'star0.5', value:'0.5' , className:'halff'},
  ];
  routeUrl:string;
  shouldApplyOnlyNumbers: boolean = false; 
  
  constructor(public agGridService:AgGridService ,    
      public importDocumentsService:ImportDocumentsService,
      public tabsService: TabsService,
      private router: Router,

    ) { }
  refresh(params: ICellRendererParams<any, any>): boolean {
    // throw new Error('Method not implemented.');
    return
  }


  ngOnInit(): void {
    this.routeUrl = this.router.url;

    this.importDocumentsService.mapValidation.subscribe((res)=>{
      if(res!==undefined){
        this.submit = res;
      }
    });
    this.importDocumentsService.errorValidation.subscribe((res)=>{
      if(res!==undefined){
        this.submitted = res;
      }
    });
    this.tabsService.currentTab.subscribe(res => {
      if (res !== undefined) {
        this.currentTab = res;
      } 
    })
  }
  agInit(params: ICellRendererParams) {
    this.shouldApplyOnlyNumbers = ['NAICS Code', 'SIC Code', 'NAICS Year'].includes(params.data.errorField);
    this.errorselectlist=params?.data?.companyName;
    console.log(this.errorselectlist);
    this.selectlist=params.data.map;
    if(params.data.errorField){
      this.value=params?.data?.correctField;
      this.placevalue= this.showPlaceHolder(params.data.errorField)
    }else{
      this.value=params?.data?.mappedColumn;
      this.placevalue= ' Field-'
    }
    this.params = params;
    this.getCount()
  }

showPlaceHolder(name):string{
  if(name =="Phone"){
  return  name+' Number';
  }else if(name =="Company"){
    return name+' Name';
  }else{
    return name;
  }
}

  getCount(){
    this.importDocumentsService.count=0
    this.params.api.forEachNode((a)=>{
      if(!a.data.status){
        this.importDocumentsService.count++
      }
    })
  }

  checkFields(){
    const list=[];
    this.params.api.forEachNode((a)=>{
      list.push(a.data)
    })
    if(list.length==6){
      this.importDocumentsService.submit= this.importDocumentsService.checkRequiredValueCodecrosswalk(list);
    }else if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
      this.importDocumentsService.submit= this.importDocumentsService.checkVendorRequiredValue(list);
    }else{
      this.importDocumentsService.submit=  this.importDocumentsService.checkRequiredValue(list);
    }
    this.importDocumentsService.submitted = this.importDocumentsService.checkRequiredValueError(list);
  }

  //#region  reduced code
  getType(): string {
    if (this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH ? 
      this.inputVendorArray?.includes(this.params.data.errorField):
      this.inputArray?.includes(this.params.data.errorField)) {
      return this.enumFieldTypeAndPlaceHolder.INPUT;
    } else {
      return this.enumFieldTypeAndPlaceHolder.DROPDOWN;
    }
  }


  valueChange(event) {
    this.value = event;
    this.params.setValue(this.value);
    this.params.data.status = this.value != '' || this.value != 0;
    this.params.api.refreshCells({ columns: ["status"] });

    let length= this.params?.data?.map?.length??0;
    if (length > 0) {

      this.getCount();
      this.checkFields();
      this.params.setValue(event);
    }
  }

  //#endregion reduced code
  checkIfSicCode() {
    if(this.params.data.companyName == 'SIC Code'){
      if(this.params.data.correctField.length == 8){
        this.isSicCode = true; 
      }
    }
    else{
        this.isSicCode = false; 
      }
  }
}




<div class="form-check form-switch mb-0 p-0">
  <input
    class="form-check-input checkbox hand"
    type="checkbox"
    role="switch"
    [id]="id"
    [(ngModel)]="status"
    (ngModelChange)="switchValueEmit.emit($event)"
    [checked]="status"
    [disabled]="isEditable"
  />
</div>

<!-- <div class="form-check form-switch mb-0">
    <input 
        class="form-check-input checkbox" 
        type="checkbox" 
        role="switch" 
        id="switch"
    >
</div> -->

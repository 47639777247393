<fieldset class="heart">
  <ng-container *ngFor="let items of ratingStarList">
    <input
      type="radio"
      [id]="items.id"
      name="hearting"
      [disabled]="isDisabled"
      [(ngModel)]="value"
      [value]="items.value"
      (click)="onRatingChange(items.value)"
    />
    <label class='labelDisalbed'  for="{{ items.id }}" [class]="items.className"> </label>
  </ng-container>
</fieldset>

<div class="{{isActivity == true ? 'col d-flex align-items-center gap-1':'row'}}">
  <div class="{{isActivity == true ? 'col-sm-12':'p-0  col-6 col-md-6 col-lg-10 col-xl-10 col-xxl-10'}}">
    <div class="wrapper {{isActivity == true ? 'wrapper-2':''}}">
      <input #flatpickrInput type="text" class="flatpick" placeholder=" ">
      <span class="calendar-icon" (click)="openDatepicker()">
        <img src="../../../../assets/SVGIcons/calendar.svg" alt="">
      </span>
    </div>
  </div>
  <div class="col-sm-2">
    <div>
      <img *ngIf="clearSelectedDate" class="crossIcon" width="15px"
        height="15px" src="./assets/SVGIcons/crossCircleIcon.svg" alt="" (click)="clearSeletedDate()">
      <!-- <app-svg-icon  [src]="'./assets/SVGIcons/crossCircleIcon.svg'"></app-svg-icon> -->
    </div>
  </div>
</div>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ImportCompanyVM, ImportModel } from '../../../crm/companies/pages/companies/companies.model'
import { CompaniesService } from '../../../crm/companies/pages/companies/companies.service';
import { CommonDropdownModel } from '../../../crm/companies/pop-ups/add-company/add-company.model';
import { CrmService } from '../../../crm/crm.service';
import { InsertUpdateImportDocumnets, TemplateData } from '../../import-documents.model';
import { ImportDocumentsService } from '../../import-documents.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { Button, CurrentTab, PopUpTitles, RoutePath } from 'src/app/shared/model/enum';
import { ProgressHelperService } from '../step-progressbar/progress-helper.service';
import { catchError } from 'rxjs';
import { Response, Result } from 'src/app/shared/model/response.model';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CodeCrosswalkService } from 'src/app/components/admin/code-crosswalk/code-crosswalk.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-import-tab',
  templateUrl: './import-tab.component.html',
  styleUrls: ['./import-tab.component.scss']
})
export class ImportTabComponent implements OnInit {
  errorMessageSupported: boolean = false;
  fileNotMatchError: boolean = true;
  errorMessage: boolean = false;
  enumPopUpTitles = PopUpTitles;
  enumButtonName = Button;
  file: File[];
  sourceVendorPath = "../../../../assets/templete-file/SampleVendorTemplate.xlsx";
  sourcePath = "../../../../assets/templete-file/SampleCompanyImport.xlsx";
  sourcePathCodecrosswalk = "../../../../assets/templete-file/SampleCodeCrosswalkImport.xlsx";
  fileName = "Sample Template.xlsx";
  firstContainer: boolean = true;
  progress: number = 0;
  hideProgressbar: boolean = false;
  base64OfFile: any;
  uploadSuccess: boolean = false;
  percentDone: number = 0;
  hideErrordiv: boolean = false;
  interval: any;
  buyerRepList: CommonDropdownModel[] = [];
  salesPersonList: CommonDropdownModel[] = [];
  importCompanyVM = new ImportCompanyVM();
  importModel = new ImportModel();
  salseValue = "Salesperson";
  buyerValue = "Buyer’s Rep";
  flexHeight;
  buttonDisabled: boolean = false;
  insertUpdateImportDocumnets = new InsertUpdateImportDocumnets();
  altSalePersonList = []
  mappingTemplateList = []
  sourcingTemplateList=[]
  submit: boolean = false;
  emptyFile: boolean = false;
  routeUrl:string;
  currentTab:string;
  headerText:string;
  headerValue:boolean = false;
  routePath = RoutePath;
  tabName = CurrentTab;

  constructor(
    public companiesService: CompaniesService,
    public importDocumentsService: ImportDocumentsService,
    public toaster: ToastrService,
    public crmService: CrmService,
    private router: Router,
    public commonService: CommonService,
    private progressHelper: ProgressHelperService,
    public tabsService: TabsService,
    public codeCrosswalkService     :CodeCrosswalkService,
  ) { 
    this.routeUrl = this.router.url;
    this.tabsService.currentTab.subscribe(res => {
      if (res !== undefined) {
        this.currentTab = res;
      } 
    })
  }

  @ViewChild("bar") bar: ElementRef;
  progressPercentage() {
    return Math.floor(this.progress * 100);
  }

  ngOnInit(): void {
    // this.tabsService.currentTab.subscribe(res => {
    //   if (res !== undefined) {
    //     this.currentTab = res;
    //   } 
    // })
    // this.getmappingTemplateId();
    this.commonService.headerHeight.subscribe(res => {
      this.flexHeight = "calc(100vh - 502px - " + res + "px" + ")"
    })
    this.crmService.getAllFilterDropDownList().subscribe(res => {
      console.log('the dropdowns are ', res);
      this.salesPersonList = res.response.salesPersonList;
      this.buyerRepList = res.response.buyerRepList
    })
    this.fillList();
    this.crmService.sendImportId.subscribe((res) => {
      console.log('import id is', res);
    })
  }

  upload(event: any) {
    const extensionType = ['.xlsx', '.csv', '.xls'];
    let found = false;
    for (const wordToFind of extensionType) {
      if (event.target.files[0].name.includes(wordToFind)) {
        found = true;
        break;
      }
    }

    if (found) {
      this.progress = 0;
      this.readThis(event.target);
      this.file = event.target.files;
      var aceept = event.target.accept;
      console.log(aceept);
      this.errorMessageSupported = false;
      if (this.file.length > 0) {
        this.hideProgressbar = true;
        this.firstContainer = false;
        this.uploadSuccess = true;
      }
    } else {
      this.errorMessageSupported = true
    }
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    this.insertUpdateImportDocumnets.templateData.fileName = file.name;
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      console.log("the result is ", myReader.result.toString());
      this.base64OfFile = myReader.result;
      if(this.currentTab== this.tabName.CODECROSSWALK_TAB){
        this.insertUpdateImportDocumnets.importCodeCrosswalkModel.file = this.base64OfFile;
      }else{
        this.insertUpdateImportDocumnets.importCompanyModel.file = this.base64OfFile;
      }
      this.checkingListAvailable(myReader.result)
      if (this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId || this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId) {
        this.checkingValidateFiles();
      }
    };
    myReader.readAsDataURL(file);
  }

  hideUploadingdiv() {
    this.file = null;
    this.hideErrordiv = true;
    this.hideProgressbar = false;
    clearInterval(this.interval);
  }

  showuploadefile() {
    this.hideErrordiv = false;
    this.firstContainer = true;
  }

  importData() {
    // this.importModel.buyerRepId = this.buyerValue;
    // this.importModel.saleRepId = this.salseValue;
    // console.log("the model is ... ", this.importModel);
    // this.companiesService.importData(this.importModel).subscribe(res => {
    //   console.log('the res ult is: ', res);
    //   if (res.isSuccess) {
    //     this.toaster.success(res.message);
    //   } else {
    //     this.toaster.error(res.message);
    //   }
    // });

  }

  newImport() {
    this.headerValue = false;
    this.uploadSuccess = false;
    this.firstContainer = true;
    this.file = null;
    this.fileNotMatchError = true;
    this.dataBlank = false;
    this.base64OfFile=''
    this.currentTab == this.tabName.CODECROSSWALK_TAB?(this.insertUpdateImportDocumnets?.importCodeCrosswalkModel?.file):(this.insertUpdateImportDocumnets.importCompanyModel.file) = this.base64OfFile;
  }

  closeTab(){
    if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
      this.importDocumentsService.closeVendorTab();
      this.router.navigate([this.routePath.VENDORLIST_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.VENDORLIST_TAB)
    }else{
      this.importDocumentsService.closeTab();
      this.router.navigate([this.routePath.COMPANY_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.COMPANY_TAB)
    }
  }
 close(){ 
  this.codeCrosswalkService.valuePopup.next(true)
 }
  fillList() {
    this.importDocumentsService.getImportCompanyDropDownList().subscribe(res => {
      this.salesPersonList = res?.response?.salesPersonList;
      this.altSalePersonList = res?.response?.altSalesPersonList;
      if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
        this.insertUpdateImportDocumnets.mappingTemplateList = this.mappingTemplateList = res?.response?.sourcingTemplateList;
      }else{
        if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
          this.insertUpdateImportDocumnets.mappingTemplateList = this.mappingTemplateList = res?.response?.vendorTemplateList;
        }else{
          this.insertUpdateImportDocumnets.mappingTemplateList = this.mappingTemplateList = res?.response?.mappingTemplateList;
        }
      }
      this.buyerRepList = res?.response?.buyerRepList;
    })
  }

  showDescription() {
    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      this.insertUpdateImportDocumnets.templateData.templateDescription = this.mappingTemplateList?.find(a => a?.id == this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId)?.description;
    }else{
      this.insertUpdateImportDocumnets.templateData.templateDescription = this.mappingTemplateList?.find(a => a?.id == this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId)?.description;
    }
  }

  updateList() {
    this.importDocumentsService.getImportCompanyDropDownList().subscribe(res => {
      if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
        this.insertUpdateImportDocumnets.mappingTemplateList = this.mappingTemplateList = res?.response?.vendorTemplateList;
      }else{
        this.insertUpdateImportDocumnets.mappingTemplateList = this.mappingTemplateList = res?.response?.mappingTemplateList;
      }
    })
  }

  checkRequiredValue(): boolean {
    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      if (this.file) {return true; }
       else {this.submit = true;}
    }else if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
      if (this.insertUpdateImportDocumnets.importCompanyModel.salesPersonId && this.file && this.insertUpdateImportDocumnets.importCompanyModel.buyersId) {
        return true;
      } else {this.submit = true; }
    }
    else{
      if (this.insertUpdateImportDocumnets.importCompanyModel.salesPersonId && this.file) {
        return true;
      } else { this.submit = true;}
    }
  }

  navigateToMapTab() {
    
    if (this.buttonDisabled) {
      return;
    }
    this.buttonDisabled = true;
    if (this.checkRequiredValue() && this.uploadSuccess && this.fileNotMatchError && !this.emptyFile  && !this.headerValue
    ) {
      this.progressHelper.eventHelper.next({ prev: false, next: true });
      if(!this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId || !this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId){
        this.insertUpdateImportDocumnets.templateData = new TemplateData();
      }
      this.importDocumentsService.dataPassAccordingToTrackingBar.next(this.insertUpdateImportDocumnets);
    }
    this.buttonDisabled = false;
  
  }

  checkingValidateFiles() {
    let data;
  
    if (this.insertUpdateImportDocumnets?.importCompanyModel?.mappingTemplateId == ""  || this.base64OfFile == '') {
    //  this.fileNotMatchError = true;
    }else if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      if( this.insertUpdateImportDocumnets?.importCodeCrosswalkModel?.mappingTemplateId == "" || this.base64OfFile == ''){

      }
    }
     else {
      data = { file: this.base64OfFile, templateId: this.currentTab == this.tabName.CODECROSSWALK_TAB?this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId:this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId };
      this.importDocumentsService.validateFile(data)
        .pipe(catchError(async (err) => this.handleError(err)))
        .subscribe((res:Result) => {
          if(res.isSuccess){
            this.fileNotMatchError = true;
          }
          // this.fileNotMatchError = res.isSuccess;
          else if (!res.isSuccess) {
            this.fileNotMatchError = false;
          } else {
          }
        });
    }
  }
  
  handleError(err: any) {
    console.error('Error:', err);
    this.fileNotMatchError = false;
  }
  
  selectmappingTemplateId(event){
    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId=event
    }else{
      this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId=event;
    }
  }

dataBlank:boolean=false;
  checkingListAvailable(base64OfFile){
  let data = { file: base64OfFile,sourceId:this.currentTab == this.tabName.CODECROSSWALK_TAB?3:this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH ? 2 : 1}
    this.importDocumentsService.validateFileHasData(data).pipe(catchError(async (err) => this.handleError(err)))
    .subscribe((res:Result) => {
      if(res?.isSuccess){
        if( res.message === "Error!! No record available in imported file."){
         // this.toaster.error(res?.message);
          this.emptyFile = true;
          this.dataBlank = true;
          this.buttonDisabled= true;

        }else if( res.message === "File invalid. Headers do not match the target headers."){
        this.headerText = res.message;
        this.headerValue = true;
        }
        else{
          this.headerValue = false;
          this.dataBlank = false;
          this.buttonDisabled= false;
          this.emptyFile = false;
          this.fileNotMatchError = true;
          this.importDocumentsService.fileName.next(this.insertUpdateImportDocumnets?.templateData?.fileName);
         }
      }
      // this.fileNotMatchError = res.isSuccess;
      else if (!res?.isSuccess) {
        this.fileNotMatchError = false;
      } else {
      }
     // this.dataBlank= this.buttonDisabled=  !res.isSuccess;
    });
}

getmappingTemplateId():string{
  if(this.currentTab== this.tabName.CODECROSSWALK_TAB){
   return this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId;
  }else{
    return this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId;
  }
}

}

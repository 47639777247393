import { Component, HostListener, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbModal, NgbModalOptions, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Options } from "@angular-slider/ngx-slider";
import { AddCompanyComponent } from 'src/app/components/crm/companies/pop-ups/add-company/add-company.component';
import { AddContactComponent } from 'src/app/components/crm/contacts/pop-ups/add-contact/add-contact.component';
import { TablePaginationModel } from 'src/app/shared/components/ag-grid/ag-grid.model';
import { AgGridService } from 'src/app/shared/components/ag-grid/ag-grid.service';
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { ActivityTabService } from 'src/app/components/crm/companies/pages/company-info-tabs/activity-tab/activity-tab.service';
import {RequirementsService} from '../../../requirements/pages/requirements/requirements.service'
import { AddRequirementModel, OpportunityDetails, RequirementDetails, RequirementInfo, RequirementParts } from './add-requirement.model';
import { ToastrService } from 'ngx-toastr';
import { SearchService } from 'src/app/shared/services/search.service';
import { CommonDropList } from 'src/app/shared/model/common.model';
import { StingToDateService } from 'src/app/shared/services/stringToDate.service';
import { Button } from 'src/app/shared/model/enum';
@Component({
  selector: 'app-add-requirement',
  templateUrl: './add-requirement.component.html',
  styleUrls: ['./add-requirement.component.scss']
})
export class AddRequirementComponent extends SearchService implements OnInit {
  paginationBaseModel              = new TablePaginationModel();
  @Input() headingsNames           : string= '';
  @Input() editName                : string = '';
  @Input() CompnayID               : string;
  @Input() companyInfoName           : string;

  disabledContact                  :boolean = true;
  disabledMPNfields                :boolean = false;
  ReqSource                        :any[]= [];
  // currencyList    : CommonDropList[]=[];
  addRequirementInfo               = new AddRequirementModel ();
  requirementInfo                  = new RequirementInfo ();
  requirementDetails               = new RequirementDetails ();
  requirementParts                 = new RequirementParts ();
  opportunityDetails               = new OpportunityDetails ();
  getRequirementDetailsByMPN       :any;
  startRating                      = 0;
  saleStageList                    :any[] =[{name:'Buy',id:1},{name:'Bid',id:2}];
  projectList                      :any[] =[{name:'Yes',active:true},{name:'No',active:false}];
  active                           = 1;
  options: Options = {
    floor: 0.0,
    ceil: 100.0,
    step: 0.5,
    showSelectionBar: true,
  };
 // mpnNameList: any[] = []; 
 // companyNameList                 = [];
  contactList                     = [];
  dcRestrictionsList              = [];
  referralSourceList              = [];
  currencyList                    = [];
  roHSList                        = [];
  urgencyList                     = [];
  oppurtunityTypeList             = [];
  todayDate                       : Date = new Date();
  submit                          :boolean = false;
  companyName                     :string;
  typeId                          :any[]=[];
  enumButtonName = Button;
 
  constructor(
    public modalService: NgbModal,
    public ngbActiveModal: NgbActiveModal,
    public activeModal: NgbActiveModal,
    public agGridService: AgGridService,
    public commonTabService: CommonTabService, 
    public activityTabService : ActivityTabService,
    public  requirementsService: RequirementsService,
    public toaster : ToastrService,
    private searchService: SearchService,
    private elRef: ElementRef,
    public stringToDateService: StingToDateService,
  ) { super()}


  ngOnInit(): void {
    this.currencyList  =   this.commonTabService.getAllDropdownList.getValue().currencyList;

    this.CompnayID
    this.companyNameList = this.commonTabService?.getAllDropdownList?.getValue()?.companyNameList;
    //   this.contactList = this.commonTabService?.getAllDropdownList?.getValue()?.contactNameList;
   if(this.companyInfoName){
    this.selectValueChange(this.companyInfoName)
     }

     this.allDropdownList();
     this.getRequirementNo();
     this.getAllMPNDropdownList();

  }
  getAllMPNDropdownList(){
    this.requirementsService.getAllMPNDropdownList().subscribe((res=>{
      if(res.isSuccess){
        this.mpnNameList = res?.response;
      }
    }))
  }
allDropdownList(){
  this.requirementsService.getAllRequirementDropdown().subscribe((res=>{
    if(res.isSuccess){
      console.log(res);
      this.dcRestrictionsList = res?.response?.dcRestrictionsList;
      this.referralSourceList =  res?.response?.referralSourceList;
      this.roHSList = res?.response?.roHSList;
      this.urgencyList = res?.response?.urgencyList;
      this.oppurtunityTypeList = res?.response?.oppurtunityTypeList
    }
  }))
}
getRequirementNo(){
  this.requirementsService.getRequirementNo().subscribe((res=>{
    if(res.isSuccess){
      console.log(res);
      this.requirementInfo.requirementNo = res?.response?.requirementNo;
      this.requirementInfo.requirementDate = res?.response?.requirementDate;
    }
  }))
}

selecteAccountClassId(item, index) {
  console.log('the itesm i s', item)
  this.opportunityDetails.type = item.id
}
selecteAccountTypesId(item, index) {
  console.log('the itesm i s', item)
  const data = this.typeId.find(a=>a.id == item.id);
  if(data != null && data != undefined){
    const indexToRemove = this.typeId.indexOf(data);
    if (indexToRemove !== -1) {
      this.typeId.splice(indexToRemove, 1);
      this.opportunityDetails.type = this.typeId
      }
    }
    else{
      this.typeId.push(item);
      this.opportunityDetails.type = this.typeId;
    }
 
  this.oppurtunityTypeList?.map((x) => {
    const data = this.typeId.find(a=>a.id == x.id);
    if(data != null && data != undefined){
      x.isActive = true;
    }
    else{
      x.isActive = false;
    }
  })
}

onSelectionChange(saleItem: any) {
    this.requirementInfo.opportunityTypeId = saleItem.id;

  }
  onSelectionProjectChange(saleItem: any) {
    this.opportunityDetails.isProjectApproved = saleItem.active;
  }
  activeTab(id) {
    this.active = id;
  }
  clearStausBar(){
     this.startRating = 0;
     this.opportunityDetails.closeProbability = 0;

  }
  openAddEditCompanyModal() {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      // size:'xl',
      windowClass: "addCompanyPopUP",
    };
    const modalRef = this.modalService.open(AddCompanyComponent, modalOption);
    modalRef.componentInstance.stagesName = this.paginationBaseModel.stage;
    modalRef.componentInstance.headingsNames = 'Add Company';

  }
  openAddContactModal() {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      // size:'xl',
      windowClass: "addContactPopup",
    };
    const modalRef = this.modalService.open(AddContactComponent, modalOption);
    modalRef.result.then((res) => {
      if (res.isSuccess == true) {
        this.agGridService.tablePaginationData.next(this.paginationBaseModel);
      }
    })

    // modalRef.componentInstance.stagesName = this.paginationBaseModel.stage;
  }

    selectValueChange(event) {
      this.companyName = event;
      this.disabledContact = !event; 
      if(event ==='' || event ==='No Records Found'){
        this.contactList = [];
        this.companyName = null;
        this.requirementInfo.companyId = null;
        if(this.contactList.length == 0){
          this.contactList.push({id:null,name: "No Records Found"});
         }
      }else{
        this.contactList = [];
        const matchingCompany = this.companyNameList?.find(company => company.name.trim().toLowerCase() === event.trim().toLowerCase());
        this.activityTabService.getContactList(matchingCompany?.id).subscribe((res) => {
          if (res.isSuccess) {
            this.contactList = res?.response?.contactList;
            if(this.contactList.length == 0){
             const optionValue = {id:null,name: "No Records Found"};
             this.contactList.push(optionValue);
            }
          }
        })
      }
  }
  selectMpnValueChange(event){
     if(event===''){
      this.disabledMPNfields = false; 
      this.requirementDetails =  new RequirementDetails ();
     }else{
       const matchingMPN = this.mpnNameList?.find(x => x.mpn.trim().toLowerCase() === event.trim().toLowerCase());
     // this.getRequirementByMPN(matchingMPN?.mpn);
     }

  }
  handleoutSideClick(event){
    console.log('Selected item:', event.currentTarget.value);
    this.handleSelectItem(event.currentTarget.value)

  }
  handleSelectItem(item: string) {
    console.log('Selected item:', item);
    const matchingMPN = this.mpnNameList?.find(x => x.mpn.trim().toLowerCase() === item.trim().toLowerCase());
    this.getRequirementByMPN(matchingMPN?.mpn);
  }
  getRequirementByMPN(MPN:string){
    if(MPN !== undefined && MPN !== ''){
    this.requirementsService.getRequirementByMPN(MPN).subscribe((res=>{
      if(res.isSuccess){
        console.log(res);
        this.getRequirementDetailsByMPN = res?.response ?? {};
      //  this.requirementDetails =  new RequirementDetails ();
       // this.requirementDetails.needDate = this.convertStringToDate(this.getRequirementDetailsByMPN.needDate) ?? '';
       this.requirementDetails.id            =   this.getRequirementDetailsByMPN.id;
        this.requirementDetails.mpn          =   this.getRequirementDetailsByMPN.mpn;
        this.requirementDetails.mfr          =   this.getRequirementDetailsByMPN.mfr;
        this.requirementDetails.description  =   this.getRequirementDetailsByMPN.description;
        this.requirementDetails.roHS         =   this.getRequirementDetailsByMPN.roHS;
        this.requirementDetails.nsn          =   this.getRequirementDetailsByMPN.nsn;
        this.requirementDetails.itemPackaging=   this.getRequirementDetailsByMPN.itemPackaging;
        this.requirementParts.id             =   this.getRequirementDetailsByMPN.partsId;
        this.requirementParts.scheduleB      =   this.getRequirementDetailsByMPN.scheduleB;
        this.requirementParts.eccn           =   this.getRequirementDetailsByMPN.eccn;
        this.requirementParts.categories     =   this.getRequirementDetailsByMPN.categories;
        this.requirementParts.tempRange      =   this.getRequirementDetailsByMPN.tempRange;
        this.requirementParts.spq            =   this.getRequirementDetailsByMPN.spq;
        this.disabledMPNfields               = this.requirementDetails ? true : false; 
      }
    }))
  }
  }
  clearInputText() {
    const matchingCompany = this.companyNameList?.find(company => company.name.trim().toLowerCase() === this.requirementInfo.companyId.trim().toLowerCase());
  if(matchingCompany !== undefined){
  this.companyName = matchingCompany?.name;
  this.requirementInfo.companyId = matchingCompany?.id;
 }else{
  const matchingCompany = this.companyNameList?.find(company => company.name.trim().toLowerCase() ===  this.companyName.trim().toLowerCase());
  if(matchingCompany?.name !== this.companyName){
    this.companyName = '';
    this.requirementInfo.companyId = '';
  }
}
  }
    insertUpdateReuirements(name:string){
    this.requirementDetails.needDate = this.requirementDetails.needDate? this.stringToDateService.dateConvertedToString(this.requirementDetails.needDate): '';
    this.opportunityDetails.estStartDate = this.opportunityDetails.estStartDate? this.stringToDateService.dateConvertedToString(this.opportunityDetails.estStartDate): '';
    this.opportunityDetails.bidDeadline = this.opportunityDetails.bidDeadline? this.stringToDateService.dateConvertedToString(this.opportunityDetails.bidDeadline): '';
    this.addRequirementInfo.requirementInfo = this.requirementInfo;
    this.addRequirementInfo.requirementDetails = this.requirementDetails;
    this.addRequirementInfo.requirementParts = this.requirementParts;
    this.addRequirementInfo.opportunityDetails = this.opportunityDetails;
      if(name == 'add'){
        this.requirementsService.insertRequirementData(this.addRequirementInfo).subscribe((res=>{
          console.log(res);
          if(res.isSuccess){
          this.toaster.success(res.message);
          this.activeModal.close();
          this.requirementsService.recordPass.next(true);
          }else{
            this.toaster.error(res.message)
          }
        }))
       
      }
      else if(name == 'update'){
        this.requirementsService.updateRequirementData(this.addRequirementInfo).subscribe((res=>{
          console.log(res);
          if(res.isSuccess){
          this.toaster.success(res.message)
          }else{
            this.toaster.error(res.message)
          }
        }))
  
      }
    }
}

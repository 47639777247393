import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphenate'
})
export class HyphenatePipe implements PipeTransform {

  transform(value: string): string {
    // Remove any non-digit characters
    const phoneNumber = value.replace(/\D/g, '');

    // Check if the phone number is empty or less than 10 digits
    if (!phoneNumber || phoneNumber.length < 10) {
      return value; // Return the original value
    }

    // Format the phone number into the desired format
    const areaCode = phoneNumber.substring(0, 3);
    const middleDigits = phoneNumber.substring(3, 6);
    const lastDigits = phoneNumber.substring(6);

    return `${areaCode}-${middleDigits}-${lastDigits}`;
  }

}

import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { RestPasswordRequestmodel } from "src/app/components/admin/user-management/pages/user-management/user-management.model";
import { UserManagementService } from "src/app/components/admin/user-management/pages/user-management/user-management.service";
import { Button } from "src/app/shared/model/enum";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
  providers: [NgbActiveModal]
})
export class ResetPasswordComponent implements OnInit {
  hidePassword1     : boolean = false;
  hidePassword2     : boolean = false;
  passwordForm      : FormGroup;
  submitted         = false;
  userEmail         : any;
  restPasswordRequestmodel   = new RestPasswordRequestmodel();
  passSuccess       :boolean = false;
  enumButtonName = Button;
  constructor(public activeModal : NgbActiveModal ,private fb: FormBuilder , private activateRouter: ActivatedRoute ,public userManagementService:UserManagementService , private toastr: ToastrService) {
    this.passwordForm = this.fb.group({
      password: new FormControl("",Validators.required),
      confirmPassword: new FormControl("",Validators.required),
    },{
      validators: this.match("password", "confirmPassword"),
    }
    )
  }

  ngOnInit(): void {
    this.activateRouter.queryParams.subscribe((params) => {
      this.userEmail = params["email"];
    });
  }

  //reset-password post
  resetPassword(){
    if(this.passwordForm.valid){
    this.restPasswordRequestmodel.email = this.userEmail;
      this.userManagementService.adminRestPassword(this.restPasswordRequestmodel).subscribe((data) => {
      if (data.isSuccess === true) {
        this.toastr.success(data.message);
        this.restPasswordRequestmodel = new RestPasswordRequestmodel();
        this.passSuccess = true;
        this.activeModal.close('success');
      }
    });}else{
      this.submitted = true;
    }
  }
   //reset-password

  match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl.errors && !checkControl.errors.match) {
        return null;
      }
      if (control.value !== checkControl.value) {
        controls.get(checkControlName).setErrors({ match: true });
        return { match: true };
      } else {
        return null;
      }
    };
  }
  
  get password() {
    return this.passwordForm.get("password") as FormControl;
  }
  get confirmPassword() {
    return this.passwordForm.get("confirmPassword") as FormControl;
  }

}
import { CommonService } from './../../../services/common.service';
import { AfterContentInit, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import * as feather from 'feather-icons';
import { LayoutService } from '../../../services/layout.service';
import { NavService } from '../../../services/nav.service';
import { fadeInAnimation } from '../../../data/router-animation/router-animation';
import { GetSystemSettingData } from 'src/app/components/admin/system-settings/pages/system-settings/system-settings.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SystemSettingsService } from 'src/app/components/admin/system-settings/pages/system-settings/system-settings.service';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {
  flexibleHeight: string;
  getSystemSettingData        = new GetSystemSettingData();
  @ViewChild('footer') footer: ElementRef;
  imageData: string;
  
  constructor(private route: ActivatedRoute, public navServices: NavService,   private systemSettingsService:SystemSettingsService, private commonTabService: CommonTabService,
    public storageservice: StorageService,
    public layout: LayoutService,public commonService:CommonService) {
      this.route.queryParams.subscribe((params) => {
        this.layout.config.settings.layout = params.layout ? params.layout : this.layout.config.settings.layout
      })
  }
    
  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  ngAfterViewChecked(){
    this.commonService.headerHeight.subscribe(headerHeight=>{
      this.flexibleHeight = "min-height: calc(100vh - "+headerHeight+"px - "+ this.footer.nativeElement.offsetHeight+"px) !important;max-height: calc(100vh - "+headerHeight+"px - "+ this.footer.nativeElement.offsetHeight+"px) !important;height: calc(100vh - "+headerHeight+"px - "+ this.footer.nativeElement.offsetHeight+"px) !important;margin-top:"+ headerHeight+"px !important";
  })
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  get layoutClass() {
    switch(this.layout.config.settings.layout){
      case "Dubai":
        return "compact-wrapper"
      case "London":
        return "only-body"
      case "Seoul":
        return "compact-wrapper modern-type"
      case "LosAngeles":
        return this.navServices.horizontal ? "horizontal-wrapper material-type" : "compact-wrapper material-type"
      case "Paris":
        return "compact-wrapper dark-sidebar"
      case "Tokyo":
        return "compact-sidebar"
      case "Madrid":
        return "compact-wrapper color-sidebar"
      case "Moscow":
        return "compact-sidebar compact-small"
      case "NewYork":
        return "compact-wrapper box-layout"
      case "Singapore":
        return this.navServices.horizontal ? "horizontal-wrapper enterprice-type" : "compact-wrapper enterprice-type"
      case "Rome":
        return "compact-sidebar compact-small material-icon"
      case "Barcelona":
        return this.navServices.horizontal ? "horizontal-wrapper enterprice-type advance-layout" : "compact-wrapper enterprice-type advance-layout"
    }
  }
  
  ngOnInit() {
    console.log("Content Componet here");
    this.getAPIData()
    
  }

  
  getAPIData() {
    const userId   = this.storageservice.retrieve('userId');
    this.systemSettingsService.GetAllSystemSettingData({userId:userId}).subscribe(res => {
      let dockDe = JSON.parse(res?.response?.favoriteDocks);
      let primaryDe = JSON.parse(res?.response?.primaryMenus);
      let secondaryDe = JSON.parse(res?.response?.secondaryMenus);
      let siteDe = JSON.parse(res?.response?.siteWides);

      let dock_color =dockDe?.backgroundColor;
      let icon_color =dockDe?.iconColor;

      let secondary_bg_color = secondaryDe?.backgroundColor;
      let secondary_text_icon_color = secondaryDe?.textAndIconColor;
      let secondary_hover_active_color =secondaryDe?.hoverAndActiveTextAndIconColor;
      let accent_line_color = secondaryDe?.accentLineColor;

      let primary_bg_color = primaryDe?.backgroundColor;
      let primary_text_icon_color = primaryDe?.textAndIconColor;
      let primary_hover_active_color = primaryDe?.hoverAndActiveTextAndIconColor;
      if(primaryDe?.siteLogo == undefined){
        this.imageData  = "../assets/SVGIcons/logo.svg"
      }else{
        this.imageData =   "data:image/png;base64," + primaryDe?.siteLogo
      }
      
      let sitewide_accent_color = siteDe?.primaryAccentColor;
      let sitewide_secondary_color = siteDe?.secondaryAccentColor;
      let sitewide_active_hover_color = siteDe?.hoverAndActiveColor;
      document.getElementById('onlyImage').setAttribute('src',  this.imageData);
      document.documentElement.style.setProperty('--primary-bg-color', primary_bg_color);
      document.documentElement.style.setProperty('--primary-text-color',primary_text_icon_color);
      document.documentElement.style.setProperty('--primary-icon-color', primary_text_icon_color);
      document.documentElement.style.setProperty('--primary-hover-active-color', primary_hover_active_color);

      document.documentElement.style.setProperty('--secondary-bg-color', secondary_bg_color);
      document.documentElement.style.setProperty('--secondary-text-icon-color',secondary_text_icon_color);
      document.documentElement.style.setProperty('--secondary-hover-active', secondary_hover_active_color);
      document.documentElement.style.setProperty('--secondary-accent-line-color', accent_line_color);

      document.documentElement.style.setProperty('--dock-bg-color', dock_color);
      document.documentElement.style.setProperty('--dock-icon-color', icon_color);

      document.documentElement.style.setProperty('--sitewide-active-hover-color', sitewide_active_hover_color);
      document.documentElement.style.setProperty('--sitewide_primary_accent_color', sitewide_accent_color);
      document.documentElement.style.setProperty('--sitewide_secondary_color', sitewide_secondary_color);

      
    })
  }
}

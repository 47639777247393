import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Result } from 'src/app/shared/model/response.model';
import { AddContact, ContactUpdate } from './contacts.model';
import { DataService } from 'src/app/shared/services/data.service';
import { Router } from '@angular/router';
import { URLS } from 'src/app/components/crm/crm-api-constants';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(public service: DataService,
    public router: Router, 
  ) { }

  addContactService(insertConatactModel:AddContact): Observable<Result> {
    insertConatactModel.currentUrl = this.router.url;
    let url = URLS.companyContact.insertCompanyContact;
    const myjson = JSON.stringify(insertConatactModel);
    return this.service.post(url, insertConatactModel, true).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  updateContactService(insertConatactModel:AddContact): Observable<Result> {
    let url = URLS.companyContact.updateCompanyContact;
    const myjson = JSON.stringify(insertConatactModel);
    return this.service.post(url, insertConatactModel, true).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  updateContactStatus(insertConatactModel:ContactUpdate): Observable<Result> {
    let url = URLS.companyContact.contactStatusUpdate;
    const myjson = JSON.stringify(insertConatactModel);
    return this.service.post(url, insertConatactModel, true).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

}

<div class="container-fluid">
  <div class="row footer-row">
    <div class="col-md-12 footer-copyright pl-0 d-flex align-items-center">      
       <ng-container *ngFor="let item of dockService.pinnedlist"> 
            <span class="px-2" *ngIf="item.pinned"  ngbTooltip="{{item.title}}" placement="top-left" (click)="routeToPage(item)">
            <app-svg-icon [src]="item.icon"></app-svg-icon>
            </span>
        </ng-container>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeCrosswalkRoutingModule } from './code-crosswalk-routing.module';
import { AddEditCodeGroupComponent } from './pop-ups/add-edit-code-group/add-edit-code-group.component';
import { SharedModule } from "../../../shared/shared.module";
import { ImportCodeCrosswalkComponent } from './pop-ups/import-code-crosswalk/import-code-crosswalk.component';
import { ImportDocumentsModule } from "../../import-documents/import-documents.module";

@NgModule({
    declarations: [
        AddEditCodeGroupComponent,
        ImportCodeCrosswalkComponent
    ],
    imports: [
        CommonModule,
        CodeCrosswalkRoutingModule,
        SharedModule,
        ImportDocumentsModule
    ]
})
export class CodeCrosswalkModule { }

<div
  *ngIf="!default"
  class="form-check mb-0 d-flex align-items-center {{
    isSelectedAdmin ? 'not-allowed' : ''
  }}"
>
  <input
    class="form-check-input my-0"
    [ngClass]="{ resize: checkboxResize }"
    type="checkbox"
    value=""
    role="checkbox"
    [checked]="status"
    [disabled]="isSelectedAdmin"
    [readonly]="readonly"
    (click)="check.emit($event.target.checked)"
    id="{{ dyanamicId ? dyanamicId : lableName }}"
    [name]="dynamicName"
  />
  <label
    class="form-check-label mb-0 font-12 text-truncate  {{readonly ? 'non-editable cursor-default' : 'hand'}}"
    [ngClass]="lableClass"
    for="{{ dyanamicId ? dyanamicId : lableName }}"
  >
    {{ lableName }}
  </label>
</div>
<div
  *ngIf="default"
  class="form-check mb-0 d-flex align-items-center {{
    isSelectedAdmin ? 'not-allowed' : ''
  }}"
>
  <input
    class="form-check-input default-check my-0"
    [ngClass]="{ resize: checkboxResize }"
    type="checkbox"
    value=""
    role="checkbox"
    [checked]="status"
    [disabled]="isSelectedAdmin"
    [readonly]="readonly"
    (click)="check.emit($event.target.checked); cliked($event.target.checked)"
    id="{{ dyanamicId ? dyanamicId : lableName }}"
    [name]="dynamicName"
  />
  <label
    class="form-check-label mb-0 font-12 text-truncate {{
      readonly ? 'non-editable cursor-default' : 'hand'
    }}"
    [ngClass]="{lableClass:isTrue, 'label-color':isTrue == true}"
    for="{{ dyanamicId ? dyanamicId : lableName }}"
  >
    {{ lableName}}
  </label>
</div>

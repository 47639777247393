import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { SidebarNotificationService } from './sidebar-notification.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { log } from 'console';
import { StorageService } from '../../services/storage.service';
import { UserIdModel } from './sidebar-notification.model';
import { ActivityTabService } from 'src/app/components/crm/companies/pages/company-info-tabs/activity-tab/activity-tab.service';
import { CommonService } from '../../services/common.service';
import { TaskListComponent } from './task-list/task-list.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-notification',
  templateUrl: './sidebar-notification.component.html',
  styleUrls: ['./sidebar-notification.component.scss']
})
export class SidebarNotificationComponent implements OnInit ,AfterViewInit {
  @Input () notificationAndTask;
  @ViewChild("tabset") tabset: TabsetComponent;
  userIdModel = new UserIdModel();
  data: any=undefined;
  selecteddata;
  tasksList:any[] = [];
  flexHeight;
  isOverflowed: boolean = false;
  @ViewChild('contentWrapper') contentWrapper!: ElementRef;
  @ViewChild(TaskListComponent) childComponent: TaskListComponent;


  notificationList=[{
    name:'New Sourcing Request' , time:'9.30 AM' ,message:'You have been assigned a new sourcing request for [COMPANY NAME]'
  },
  {
    name:'New Company Assigned' , time:'9.30 AM' ,message:'You have been assigned a new lead [COMPANY NAME]'
  },
  {
    name:'New Company Assigned' , time:'9.30 AM' ,message:'You have been assigned a new lead [COMPANY NAME]'
  },
  {
    name:'New Company Assigned' , time:'9.30 AM' ,message:'You have been assigned a new lead [COMPANY NAME]'
  }]
  constructor(public sidebarNotificationService:SidebarNotificationService,
    public storageservice: StorageService,
    public activityTabService: ActivityTabService,
    public commonService      : CommonService,
    private router: Router,


    ) { }
    @HostListener('scroll', ['$event'])
    scrollHandler(event: Event) {
      this.childComponent.functionInChild(this.data.id);
   }


    checkOverflow(index) {
      const element = this.contentWrapper.nativeElement;
      var a = element.clientHeight - index ;
      if(a < 200){
          this.isOverflowed = true;
      }else{
        this.isOverflowed = false;
      }
    }


  ngOnInit(): void {
    this.dataReceived()
    this.sidebarNotificationService.tooltip.subscribe((res)=>{
      if(res !== undefined){
        this. checkOverflow(res);
      }
    });


    this.commonService.headerHeight.subscribe(res =>{
      this.flexHeight = "calc(100vh - 150px - " + res +"px" + ")" 
  })

    this.sidebarNotificationService.loadData.subscribe((res)=>{
      if(res == true){
        this.getAllTaskList();
      }
    });

    this.getAllTaskList();

  }
  getAllTaskList(){
    this.userIdModel.userId =  this.storageservice.retrieve("userId");
    this.sidebarNotificationService.GetAllTaskList(this.userIdModel).subscribe((res)=>{
   if(res.isSuccess){
    this.tasksList = res?.response??[];
    this.data = undefined;
    this.getDescription(res?.response[0]?.id);
   }
   })
  }
  getDescription(id) {
    if(id !== undefined){
    this.selecteddata = id
    this.activityTabService.getActivityDetailsByActivityId(id).subscribe(res => {
      this.data = res?.response??undefined;
    })
  }
  }

  dataReceived(){
    this.commonService.getSelectedDate().subscribe(data => {
      if(data)
        this.getAllTaskList();
    });
  }



  ngAfterViewInit(): void {
    this.sidebarNotificationService.isSidebarNotificationShow.subscribe((res:any)=>{
      if(res.tab == 'notifications'){
        if(this.tabset.tabs.length >0){
            this.tabset.tabs[0].active = true;}
        }else if(res.tab =='tasks'){
          if(this.tabset.tabs.length >0){
          this.tabset.tabs[1].active = true;}}
    })
  }

  toggelNotificationSideBar(){
    this.sidebarNotificationService.isSidebarNotificationShow.next(false);
  }

  changeTab(event){
    let a = event.heading?.toLowerCase();
    this.sidebarNotificationService.headerIconActive.next(a)
  }
  openActivityManager(){
    // crm/activityManager
    this.router.navigateByUrl('crm/activityManager');
  }
  }

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompaniesService } from '../../../companies/companies.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Button } from 'src/app/shared/model/enum';
declare const PDFObject: any;

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  url                      = '';
  jpg       :boolean       = false;
  pdf       :boolean       = false;
  docx      :boolean       = false;
  excel     :boolean       = false;
  xlsx      :boolean       = false;
  csv       :boolean       = false;
  other                    = '';
  isLoadings: boolean      = false;
  enumButtonName = Button;
  constructor( public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}

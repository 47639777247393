import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {CompnayHeading, GetCompanySettingData} from './company-setting.model'
import { ToastrService } from 'ngx-toastr';
import {CompanySettingsService} from './company-setting.service'
import { StorageService } from 'src/app/shared/services/storage.service';
import { CommonDropdownModel } from '../../crm/companies/pop-ups/add-company/add-company.model';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { Button } from 'src/app/shared/model/enum';
@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit {
  companySettingData = new GetCompanySettingData();
  emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  isEmailInvalid: boolean = false;
  submit: boolean = false;
  enumButtonName = Button;
  countryList: CommonDropdownModel[] = [];
  stateList: CommonDropdownModel[] = [];
  companySettingHeading = CompnayHeading;


  constructor( public activeModal: NgbActiveModal,
               private toastr: ToastrService,
               private companySettingsService:  CompanySettingsService,
               public storageservice: StorageService,
               public commonTabService: CommonTabService,
            ) { }

  ngOnInit(): void {
    this.getUserId();
    this.countryList = this.commonTabService.getAllDropdownList.getValue().countryList;
    this.stateList = this.commonTabService.getAllDropdownList.getValue().stateList;

  }
  getUserId(){
    const userId   = this.storageservice.retrieve('userId');
    this.companySettingsService.getCompanySettingByUserId(userId).subscribe(res=>{
      if(res.response){
      this.companySettingData = res.response
      }else{
        if(res.message == 'No record found.'){
        }
      }

    })
  }
  onEmailChange(): void {
    this.isEmailInvalid = false;
    this.submit = false;
    const email = this.companySettingData.email;
    if (email !== '' && email !== undefined) {
      this.isEmailInvalid = !this.emailPattern.test(this.companySettingData.email);
    }
  }
  insertUpdateCompanySettingData(){
    if(this.companySettingData.companyName){
      if (this.isEmailInvalid) {
        this.submit = true
        this.toastr.error('Please enter a valid email address with the pattern: user@example.com')
       }else{
      this.companySettingData.userId = this.storageservice.retrieve('userId');
      if(this.companySettingData.postalCode){
        this.companySettingData.postalCode =  parseInt(this.companySettingData.postalCode);
      }
      this.companySettingsService.insertUpdateCompanySettingData(this.companySettingData).subscribe(res=>{
        if(res.isSuccess){
          this.toastr.success(res?.message);
          this.activeModal.close()
          }
      })
    }
    }else{
      this.submit = true;
      this.toastr.error("Fill All Required Fields");
    }
  }
}

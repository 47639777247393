<div class="modal-content">
  <div class="modal-header px-4 pb-0 pt-4 mt-2 mx-2 border-0">
    <div class="site-wide-primary">
      <h6 class="modal-title font-15">{{SystemHeadings.THEME_CUSTOMIZATION}}</h6>
      <p class="font-12 text-light-info m-t-5 mb-0">
        Select the colors to customize the platform
      </p>
    </div>
  </div>
  <!-- Modal Header End -->
  <div class="modal-body px-4 mx-2 pb-0">
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-6">
        <p class="font-12 text-gray">Primary Menu</p>
        <div class="row">
          <div class="col-9 mb-4">
            <div class="d-flex justify-content-between">
              <label class="font-11 mb-1">Site Logo</label>
              <div class="hand d-flex align-items-center"  *ngIf="colorVariables.base64textString != '' && colorVariables.base64textString != undefined "  (click)="colorVariables.base64textString = undefined">
                <div
               >
                  <app-svg-icon
                    [src]="'../../../../../../assets/SVGIcons/Clearicon.svg'">
                  </app-svg-icon>
                </div>
                <span class="d-block ps-2 m-r-10 font-13 clear-color">Remove</span>
              </div>
            </div>
            <div class="select_image-div mt-2">
              <div
                *ngIf="!colorVariables.base64textString"
                class="selet-image-icon-text text-center d-flex justify-content-center align-items-center">
              <button class="file-upload-button">
                <ng-container>
                <div class="dropzone"
                    (dragover)="onDragOver($event)"
                    (drop)="onDrop($event)">
                  <input
                    type="file"
                    ID="uploadImg"
                    (change)="selectFile($event)"/>
                  </div>
                  <label for="uploadImg" class="hand m-0">
                    <div class="d-flex justify-content-center mb-3">
                      <app-svg-icon
                        class="svgIcon"
                        [src]="'../../../../../../assets/SVGIcons/selectimage.svg' "
                      ></app-svg-icon>
                    </div>
                    <p class="mb-2 text-gray font-10">
                      Select a PNG or JPEG file
                    </p>
                    <p class="m-0 font-9 text-light-gray">
                      or drag and drop it here
                    </p>
                  </label>
                </ng-container>
              </button> 
              </div>
              <img
                *ngIf="colorVariables.base64textString"
                class="img-wid order-xl-1 order-lg-1 order-md-1 rounded-1"
                [src]=" 'data:image/png;base64,' + colorVariables.base64textString "
                id="onlyImag"
                alt="" />
            </div>
          </div>
          <div class="col-12 mb-4">
            <label for="">Background</label>
            <div
              class="box hand"
              [style.background]="colorVariables.primary_bg_color"
              [(colorPicker)]="colorVariables.primary_bg_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'primary_bg_color')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'primary_bg_color')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'primary_bg_color')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'primary_bg_color')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'primary_bg_color')
              "
            ></div>
          </div>
          <div class="col-12 mb-4">
            <label for="">Text and Icon</label>
            <div
              [style.background]="colorVariables.primary_text_icon_color"
              class="box hand"
              [(colorPicker)]="colorVariables.primary_text_icon_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'primary-text-color')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'primary-text-color')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'primary-text-color')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'primary-text-color')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'primary-text-color')
              "
            ></div>
          </div>

          <div class="col-xl-6">
            <label for="">Hover and Active Text and Icon</label>
            <div
              [style.background]="colorVariables.primary_hover_active_color"
              class="box hand"
              [(colorPicker)]="colorVariables.primary_hover_active_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'primary-hover')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'primary-hover')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'primary-hover')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'primary-hover')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'primary-hover')
              "
            ></div>
          </div>
        </div>
      </div>
      <!-- End Primary Menu -->
      <div class="col-xl-3 col-lg-3 col-md-6">
        <p class="font-12 text-gray">Secondary Menu</p>
        <div class="row">
          <div class="col-12 mb-4">
            <label for="">Background</label>
            <div
              class="box hand"
              [style.background]="colorVariables.secondary_bg_color"
              class="box hand"
              [(colorPicker)]="colorVariables.secondary_bg_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'secondary_bg_color')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'secondary_bg_color')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'secondary_bg_color')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'secondary_bg_color')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'secondary_bg_color')
              "
            ></div>
          </div>
          <div class="col-12 mb-4">
            <label for="">Text and Icon</label>
            <div
              [style.background]="colorVariables.secondary_text_icon_color"
              class="box hand"
              [(colorPicker)]="colorVariables.secondary_text_icon_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'secondary_text_color')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'secondary_text_color')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'secondary_text_color')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'secondary_text_color')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'secondary_text_color')
              "
            ></div>
          </div>
          <div class="col-xl-6 mb-4">
            <label for="">Hover and Active Text and Icon</label>
            <div
              [style.background]="colorVariables.secondary_hover_active_color"
              class="box hand"
              [(colorPicker)]="colorVariables.secondary_hover_active_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'secondary_hover_color')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'secondary_hover_color')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'secondary_hover_color')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'secondary_hover_color')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'secondary_hover_color')
              "
            ></div>
          </div>
          <div class="col-12">
            <label for="">Accent Line</label>
            <div
              [style.background]="colorVariables.accent_line_color"
              class="box hand"
              [(colorPicker)]="colorVariables.accent_line_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'accent_line_color')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'accent_line_color')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'accent_line_color')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'accent_line_color')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'accent_line_color')
              "
            ></div>
          </div>
        </div>
      </div>
      <!-- End Secondary Menu -->
      <div class="col-xl-3 col-lg-3 col-md-6">
        <p class="font-12 text-gray">Favorites Dock</p>
        <div class="row">
          <div class="col-12 mb-4">
            <label for="">Background</label>
            <div
              [style.background]="colorVariables.favoriteDock_color"
              class="box hand"
              [(colorPicker)]="colorVariables.favoriteDock_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'dock_color')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'dock_color')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'dock_color')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'dock_color')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'dock_color')
              "
            ></div>
          </div>
          <div class="col-12">
            <label for="">Icon</label>
            <div
              [style.background]="colorVariables.favoriteDockicon_color"
              class="box hand"
              [(colorPicker)]="colorVariables.favoriteDockicon_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'icon_color')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'icon_color')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'icon_color')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'icon_color')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'icon_color')
              "
            ></div>
          </div>
        </div>
      </div>
      <!-- End Favorites Dock  -->
      <div class="col-xl-3 col-lg-3 col-md-6">
        <p class="font-12 text-gray">Sitewide</p>
        <div class="row">
          <div class="col-12 mb-4">
            <label for="">Primary Accent</label>
            <div
              [style.background]="colorVariables.sitewide_accent_color"
              class="box hand"
              [(colorPicker)]="colorVariables.sitewide_accent_color"
              (colorPickerOpen)="
                onEventLog('colorPickerOpen', $event, 'sitewide_accent_color')
              "
              (colorPickerClose)="
                onEventLog('colorPickerClose', $event, 'sitewide_accent_color')
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'sitewide_accent_color')
              "
              (cpSliderDragStart)="
                onEventLog('cpSliderDragStart', $event, 'sitewide_accent_color')
              "
              (cpSliderDragEnd)="
                onEventLog('cpSliderDragEnd', $event, 'sitewide_accent_color')
              "
            ></div>
          </div>
          <div class="col-12 mb-4">
            <label for="">Secondary Accent</label>
            <div
              [style.background]="colorVariables.sitewide_secondary_color"
              class="box hand"
              [(colorPicker)]="colorVariables.sitewide_secondary_color"
              (colorPickerOpen)="
                onEventLog(
                  'colorPickerOpen',
                  $event,
                  'sitewide_secondary_color'
                )
              "
              (colorPickerClose)="
                onEventLog(
                  'colorPickerClose',
                  $event,
                  'sitewide_secondary_color'
                )
              "
              (cpInputChange)="
                onEventLog('cpInputChange', $event, 'sitewide_secondary_color')
              "
              (cpSliderDragStart)="
                onEventLog(
                  'cpSliderDragStart',
                  $event,
                  'sitewide_secondary_color'
                )
              "
              (cpSliderDragEnd)="
                onEventLog(
                  'cpSliderDragEnd',
                  $event,
                  'sitewide_secondary_color'
                )
              "
            ></div>
          </div>
          <div class="col-12">
            <label for="">Hover and Active</label>
            <div
              [style.background]="colorVariables.sitewide_active_hover_color"
              class="box hand"
              [(colorPicker)]="colorVariables.sitewide_active_hover_color"
              (colorPickerOpen)="
                onEventLog(
                  'colorPickerOpen',
                  $event,
                  'sitewide_active_hover_color'
                )
              "
              (colorPickerClose)="
                onEventLog(
                  'colorPickerClose',
                  $event,
                  'sitewide_active_hover_color'
                )
              "
              (cpInputChange)="
                onEventLog(
                  'cpInputChange',
                  $event,
                  'sitewide_active_hover_color'
                )
              "
              (cpSliderDragStart)="
                onEventLog(
                  'cpSliderDragStart',
                  $event,
                  'sitewide_active_hover_color'
                )
              "
              (cpSliderDragEnd)="
                onEventLog(
                  'cpSliderDragEnd',
                  $event,
                  'sitewide_active_hover_color'
                )
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal body End -->
  <div class="modal-footer px-4 mx-2 border-0 mb-2">
    <app-button
      style="margin-right: 13px"
      [button_2]="true"
      [buttonClass]="'button button-info font-11'"
      [buttonLable]="enumButtonName.CANCEL"
      (click)="activeModal.close()"
    >
    </app-button>
    <app-button
      [button_2]="true"
      [buttonClass]="'button button-primary font-11'"
      [buttonLable]="enumButtonName.APPLYCHANGES"
      (click)="insertUpdateSystemSetingData(); activeModal.close()"
    >
    </app-button>
  </div>
  <!-- Modal Footer End -->
</div>

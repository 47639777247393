import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Result } from 'src/app/shared/model/response.model';
import { DataService } from 'src/app/shared/services/data.service';
import { ActivityLogPostModel, ActivityRequestModel, GetConatactIdRequestModel } from './activity-tab.model';
import { URLS } from 'src/app/components/crm/crm-api-constants';

@Injectable({
  providedIn: 'root'
})
export class ActivityTabService {
  getConatactIdRequestModel = new GetConatactIdRequestModel()
  constructor(public dataService: DataService) { }

  public getAllTaskByCompanyId(activityRequestModel: ActivityRequestModel): Observable<Result> {
    let url = URLS.activityManager.getAllActivityByCompanyId;
    return this.dataService.post(url, activityRequestModel, true).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public getActivityDetailsByActivityId(activityId: string): Observable<Result> {
    let url = URLS.activityManager.getActivityDetailsByActivityId;
    return this.dataService.post(url, { activityId: activityId }, true).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public getContactList(CompanyId: string): Observable<Result> {
    let url = `${URLS.dropDown.getContactByCompanyId}?companyId=${CompanyId}`;
    return this.dataService.get(url, true).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }
  public getCompanyList(ContactId: string): Observable<Result> {
    let url = `${URLS.dropDown.getCompaniesByContactId}?ContactId=${ContactId}`;
    return this.dataService.get(url, true).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }
  GetActivityLogData(activityLogPostModel: ActivityLogPostModel): Observable<Result> {
    let url = URLS.activityManager.getActivityDayWeekMonthWise;
    return this.dataService.post(url, activityLogPostModel, true).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }


}

import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PreviewComponent } from '../pop-ups/preview/preview.component';
import { catchError } from 'rxjs';
import { CompaniesService } from '../../companies/companies.service';
import { AppPreviewComponent } from '../pop-ups/app-preview/app-preview.component';
import { AddCertificationsComponent } from '../certifications/popups/add-certifications/add-certifications.component';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CodeCrosswalkService } from 'src/app/components/admin/code-crosswalk/code-crosswalk.service';
import { Id } from 'src/app/components/admin/code-crosswalk/pages/code-crosswalk.model';
import { AddEditCodeGroupComponent } from 'src/app/components/admin/code-crosswalk/pop-ups/add-edit-code-group/add-edit-code-group.component';
import { AgGridService } from 'src/app/shared/components/ag-grid/ag-grid.service';
import { DownloadDocuments } from '../../companies/companies.model';
import { DocumentTypes, PopUpTitles, TabNames } from 'src/app/shared/model/enum';
import { Menu } from 'src/app/shared/services/nav.service';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { Router } from '@angular/router';
import { CommonModelPopUpComponent } from 'src/app/shared/components/common-model-pop-up/common-model-pop-up.component';
import { StingToDateService } from 'src/app/shared/services/stringToDate.service';

@Component({
  selector: 'app-document-cell-renderer',
  templateUrl: './document-cell-renderer.component.html',
  styleUrls: ['./document-cell-renderer.component.scss']
})
export class DocumentCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params:any;
  cellRendererParams:any;
  header = ''
  base64String;
  offcanvasActive = false;
  isAddValue:boolean = true;
  dateRendererValue;
  taskCrosswalkDetail;
  downloadDocuments= new DownloadDocuments();
  selectedDropdownValues:boolean= false;
  enumPopUpTitles        = PopUpTitles;
  ResMsgFromCompanyOrContact: string;
  tabName = TabNames;

  constructor(public modalService:NgbModal,
    public companiesService:CompaniesService,
    private toastr: ToastrService,
    public codeCrosswalkService:CodeCrosswalkService,
    public agGridService:AgGridService,
    private tabsService: TabsService, private router: Router, 
    public stringToDateService: StingToDateService,


    ) { }
  agInit(params: ICellRendererParams<any, any>): void {
    this.header = params.colDef.field;
    this.selectedDropdownValues = params?.data?.status??false;
    this.cellRendererParams = params.colDef.cellRendererParams;
    this.params = params
    if(params.data.expirationDate){
      this.dateRenderer(params.data.expirationDate);
    }
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false
  }

  ngOnInit(): void {
    this.taskCrosswalkDetail = this.params.data;
    this.importCompanies();
  }
  change(){
    switch (this.params.data.type) {
      case '.jpg':
      case '.png':
      case '.jpeg':
        this.previewImage();
      break;
      case '.pdf':
      //  this.previewData();
      this.previewPdfData();
      break;
      default:
        this.preview();
    }
  }
  previewImage() {
    const data = this.params.data;
     if(data.certificationId){
      this.downloadDocuments.guid=data.guId;
      this.downloadDocuments.documentTypeId=DocumentTypes.certificationDocument;
      this.companiesService.downloadCertificationDocument(this.downloadDocuments).subscribe((res) => {
        if (res.isSuccess) {
          this.openImagePreview(res);
        }
      });
    }
    else{
      this.downloadDocuments.guid=data.guId;
      this.downloadDocuments.documentTypeId=DocumentTypes.companyDocuments;
      this.companiesService.downloadDocument(this.downloadDocuments).subscribe((res) => {
        if (res.isSuccess) {
          this.openImagePreview(res);
        }
      });

    }
  }
  openImagePreview(res){
    this.base64String = res.response.base64String;
    const fileUrl = `data:image/jpeg;base64,${this.base64String}`;
    if (fileUrl) {
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.write('<html><head><title>Image Preview</title>');
        newTab.document.write('<style>body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0;background: black; }</style>');
        newTab.document.write('</head><body>');
        newTab.document.write('<img src="' + fileUrl + '" style="max-width: 100%; max-height: 100%;">');
        newTab.document.write('</body></html>');
        newTab.document.close();
      }
    }
  }
    previewPdfData() {
    const data = this.params.data;
   if(data.certificationId){
    this.downloadDocuments.guid=data.guId;
    this.downloadDocuments.documentTypeId=DocumentTypes.certificationDocument;
      this.companiesService.downloadCertificationDocument(this.downloadDocuments).subscribe((res) => {
        if (res.isSuccess) {
          this.openPdfPreview(res);
        }
      });
    }
    else{
      this.downloadDocuments.guid=data.guId;
      this.downloadDocuments.documentTypeId=DocumentTypes.companyDocuments;
      this.companiesService.downloadDocument(this.downloadDocuments).subscribe((res) => {
        if (res.isSuccess) {
          this.openPdfPreview(res);
        }
      });
    }
  }
  openPdfPreview(res: any) {
    const base64Data = res.response.base64String;
    const fileUrl = 'data:application/pdf;base64,' + base64Data;
    if (fileUrl) {
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.write('<html><head><title>PDF Preview</title></head><body><embed src="' + fileUrl + '" type="application/pdf" width="100%" height="100%"></embed></body></html>');
        newTab.document.close();
      }
    }
  }
  
  previewData(){
    const modalRef = this.modalService.open(AppPreviewComponent, {
      centered: true,
      backdrop: "static",
       size: "lg",
     // windowClass: "modal-fullscreen",
    });
    this.companiesService.preview.next({guId:this.params.data.guId,name:'documentsTab'});
  }
  preview() {
    const modalRef = this.modalService.open(PreviewComponent, {
      centered: true,
      backdrop: "static",
      size: "sm",

    });
  this.companiesService.preview.next( {guId:this.params.data.guId,name:'documentsTab'});

  switch (this.params.data.type) {
    // case '.jpg':
    // case '.png':
    // case '.jpeg':
    //   modalRef.componentInstance.jpg = true;
    //   modalRef.componentInstance.isLoadings = true;
    //   this.companiesService.downloadDocument(this.params.data.guId).subscribe((res) => {
    //     if (res.isSuccess) {
    //        this.base64String = res.response.base64String;
    //        modalRef.componentInstance.isLoadings = false;
    //        modalRef.componentInstance.url = `data:image/jpeg;base64,${this.base64String}`;
    //     }
    //   });   
    //   break;
    case '.doc':
    case '.docx':
     modalRef.componentInstance.docx = true;
        break;
    case '.txt':
      modalRef.componentInstance.excel = true;
        break;
   case '.csv':
      modalRef.componentInstance.csv = true;
        break;
    //  case '.pdf':
    //    modalRef.componentInstance.pdf = true;
    //     break;
     case '.xlsx':
     case '.xls':
      modalRef.componentInstance.xlsx = true;
        break;
     default:
      modalRef.componentInstance.other  = 'Unsupported file type.';
  }
  }
  
download() {
  const data = this.params.data;
 if(data.certificationId){
    this.downloadDocuments.guid=data.guId;
    this.downloadDocuments.documentTypeId=DocumentTypes.certificationDocument;
    this.companiesService.downloadCertificationDocument(this.downloadDocuments).subscribe((res) => {
      if (res.isSuccess) {
        this.handleDownloadSuccess(res);
      }
    });
  }
  else{
    this.downloadDocuments.guid=data.guId;
    this.downloadDocuments.documentTypeId=DocumentTypes.companyDocuments;
    this.companiesService.downloadDocument(this.downloadDocuments).subscribe((res) => {
      if (res.isSuccess) {
        this.handleDownloadSuccess(res);
      }
    });
  }
}
handleDownloadSuccess(res){
  const base64Data = res.response.base64String;
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  const fileBlob = new Blob([byteArray], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = res.response.fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

}
    handleError(err: any) {
    throw new Error('Method not implemented.');
  }
  delete(){
    const Id =this.params.data.id;
    this.companiesService.deleteCertification({id:Id}).subscribe((res)=>{
      if (res.isSuccess) {
        this.toastr.success(res.message);
        this.companiesService.isLoadData.next(true);
      }
      if (!res.isSuccess) {
        this.toastr.error(res.message);
      }
    })
  }

  edit(){
    const modalRef = this.modalService.open(AddCertificationsComponent, {
      centered: true,
      backdrop: "static",
      windowClass: "certificationClass",
    });
    modalRef.componentInstance.isAddValue = this.isAddValue;
    modalRef.componentInstance.certification.Id = this.params.data.certificationId;
    const dateString = this.params.data.dateIssued;
   const dateIssued = this.stringToDateService.convertStringToDate(dateString)
   modalRef.componentInstance.dateIssued = dateIssued;
    // const parsedDate = moment(dateString, 'MM/DD/YYYY');
    // const formattedDate = parsedDate.format('YYYY-MM-DD');
    // modalRef.componentInstance.selectedDate = formattedDate;
     const dateString1 = this.params.data.expirationDate;
     const dateExpired = this.stringToDateService.convertStringToDate(dateString1)
     modalRef.componentInstance.expirationDate = dateExpired;

    // const parsedDate1 = moment(dateString1, 'YYYY-MM-DDTHH:mm:ss');
    // const formattedDate1 = parsedDate1.format('YYYY-MM-DD');
    // modalRef.componentInstance.selectedDate1 = formattedDate1;
    modalRef.componentInstance.id = this.params.data.id;
    modalRef.componentInstance.fileName = this.params.data.fileName;
    modalRef.componentInstance.guId = this.params.data.guId;
    modalRef.componentInstance.type = this.params.data.type;
  }
  dateRenderer(params: any) {
    const originalDate = params;
    const dateObject = new Date(originalDate);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const formattedDate = `${month}/${day}/${year}`;
    this.dateRendererValue = formattedDate;
    return formattedDate;
  }
  // for code-crosswalk page
  deleteCodeCrosswalk(){
    const idInstance = new Id([this.params.data.id]);
    this.codeCrosswalkService.deleteCodeCrosswalk(idInstance).subscribe((res) => {
      if (res.isSuccess) {
        this.toastr.success(res.message);
        this.codeCrosswalkService.valuePass.next(true);
        this.ResMsgFromCompanyOrContact = res.message;
      } else {
        this.toastr.error(res.message);
      }
    });
  }
  editCodeCrosswalk(){
   const copyOfObject = Object.assign({}, this.taskCrosswalkDetail);
   const modalRef = this.modalService.open(AddEditCodeGroupComponent, {
      centered: true,
      backdrop: "static",
      size:'md',
      windowClass: "manage-manufacturer",
    });
     modalRef.componentInstance.insertUpdateCodeCrosswalk =  copyOfObject;
  }

  // for code-crosswalk page
importCompanies(){
  this.agGridService.importCompaniesValue.subscribe((res)=>{
    if(res !== undefined){
      const Id =this.params.data.system
   //   this.selectedDropdownValues = res;
    }
  });

}
openContactInfo(params){
  this.companiesService.id=params.data.companyId;
  let url = `crm/companies/company-info/${this.companiesService.id}`
  const item: Menu = { path: url, icon: '', title: params.data.companyName, type: 'link',id:params.data.companyId}
  this.tabsService.addTab(item);
  this.router.navigateByUrl(url);
  this.companiesService.activeTab.next(3);

}
openDelteModal() {
  const modalOption: NgbModalOptions = {
    centered: true,
    backdrop: "static",
    keyboard: false,
    windowClass: "markAsActiveInactive",
  };
  if (this.params.data.id) {
    const modalRef = this.modalService.open( CommonModelPopUpComponent,modalOption);
    modalRef.componentInstance.title = this.enumPopUpTitles.DELETE;
    modalRef.componentInstance.fullName = this.params.data.sic;
    modalRef.componentInstance.currentTab = "code-crosswalk";
    modalRef.componentInstance.pageType = this.tabName.CODECROSSWALKPOPUP;
    modalRef.componentInstance.delPopupforAdmin.subscribe((res) => {
      if (res){
          this.deleteCodeCrosswalk();
          setTimeout(() => {
            modalRef.componentInstance.ResMsgFromCompanyOrContact = this.ResMsgFromCompanyOrContact;
            }, 1000);
      }
    });
  }
}

  }

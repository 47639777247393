import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/components/authentication/login/login.service';
import { TabsService } from '../../../tabs/tabs.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SidebarNotificationService } from '../../../sidebar-notification/sidebar-notification.service';
import { Menu, NavService } from 'src/app/shared/services/nav.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  public menuItems: Menu[];

  constructor(  public loginService: LoginService,  public storageService: StorageService,   private router: Router,private toaster: ToastrService,  public tabsService: TabsService,
    public sidebarNotificationService:SidebarNotificationService,
    public navServices: NavService,) { }

  ngOnInit() {
  }
  logout(){
    this.loginService.SignOut()
    .subscribe(res => {
      if (res.isSuccess) {
        this.storageService.clear()
        this.router.navigate([`/auth/login`]);
      }
      this.toaster.success(res?.message);
      this.sidebarNotificationService.isSidebarNotificationShow.next(false);
      this.navServices.items.subscribe((menuItems) => {
        const activeItem = menuItems.find(item => item.active === true);
        if (activeItem) {
          activeItem.active = false;
        }
        this.menuItems = menuItems; 
      })
      
    });
  }
 
  openUserDetails(){
    this.tabsService.addTab({path: 'admin/user-details' ,icon:'' , title:'Profile',type:'link'})
    this.router.navigateByUrl('admin/user-details');  
  }
}

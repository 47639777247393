import { Component, Input, OnInit } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { ContactsService } from "../../pages/contacts/contacts.service";
import { AddContact } from "../../pages/contacts/contacts.model";
import { ToastrService } from "ngx-toastr";
import { CommonDropdownModel } from "../../../companies/pop-ups/add-company/add-company.model";
import { CommonTabService } from "src/app/shared/services/common-tab.service";
import { CommonModelPopUpComponent } from "src/app/shared/components/common-model-pop-up/common-model-pop-up.component";
import { Button, PopUpTitles } from "src/app/shared/model/enum";
import { TabsService } from "src/app/shared/components/tabs/tabs.service";
import { AgGridService } from "src/app/shared/components/ag-grid/ag-grid.service";

@Component({
  selector: "app-add-contact",
  templateUrl: "./add-contact.component.html",
  styleUrls: ["./add-contact.component.scss"],
})
export class AddContactComponent implements OnInit {
  @Input() title: string = "Add";
  @Input() isComapny:boolean = true;
  @Input()  insertConatactModel = new AddContact();
  emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  isEmailInvalid: boolean = false;
  enumButtonName = Button;
  constructor(
    public activeModal: NgbActiveModal,
    public contactsService: ContactsService,
    public toaster: ToastrService,
    public commonTabService: CommonTabService,
    private modalService: NgbModal,
    public tabService:TabsService,
    public agGridService: AgGridService,
  ) {}

  // insertConatactModel = new AddContact();
  insertConatactList: AddContact[] = [];
  timezoneList: CommonDropdownModel[] = [];
  genderList: CommonDropdownModel[] = [];
  companyNameList: CommonDropdownModel[] = [];
  enumPopUpTitles = PopUpTitles;
  departmentList: CommonDropdownModel[] = [];
  vendorNameList :CommonDropdownModel[] = []
  currentTab = ''
  submited: boolean = false;
  ngOnInit(): void {
    this.agGridService.unChecked.subscribe(res=>{
      if( res == false){
        this.insertConatactModel.markAsPrimaryContact = false;
      }
    })
    this.tabService.currentTab.subscribe(res => {
      this.currentTab = res
    })
    if(!this.currentTab){
      this.currentTab = this.tabService.thisTab
    }
    this.genderList = this.commonTabService.getAllDropdownList.getValue().genderList;
    this.timezoneList =this.commonTabService.getAllDropdownList.getValue().timezoneList;
    this.companyNameList =this.commonTabService.getAllDropdownList.getValue().companyNameList;
    this.departmentList = this.commonTabService.getAllDropdownList.getValue().departmentList;
    this.vendorNameList = this.commonTabService.getAllDropdownList.getValue().vendorNameList

  }

  addContact(addContact) {
    if (this.insertConatactModel.firstName && this.insertConatactModel.lastName && this.insertConatactModel.companyId) {
       if(this.isEmailInvalid){
        this.submited = true
        this.toaster.error('Please enter a valid email address with the pattern: user@example.com')
      }else{
  
      //this.insertConatactModel.isDeleted = false;
      if(addContact == 'Add'){
        this.contactsService.addContactService(this.insertConatactModel) .subscribe((res) => {
          if (res.isSuccess == true) {
            this.toaster.success(res.message);
            this.activeModal.close(res);
          }
          else {
            if (this.currentTab == 'vendor-contact') {
              this.toaster.error('Vendor Name Required');

            } else {
              this.submited = true;
              this.toaster.error('Company Name Required');
            }
          }
        });

      }else if(addContact == 'Save'){
        this.contactsService.updateContactService(this.insertConatactModel) .subscribe((res) => {
          if (res.isSuccess == true) {
            this.toaster.success(res.message);
            this.activeModal.close(res);
          }
          else {
            if (this.currentTab == 'vendor-contact') {
              this.toaster.error('Vendor Name Required');

            } else {
              this.submited = true;
              this.toaster.error('Company Name Required');
            }
          }
        });

      }
     }
    }  else {
      this.submited = true;
      this.toaster.error('Fill Required Fields');
    }

  }

  openReplacePrimaryPopup(contactInfo) {
    if(this.insertConatactModel.firstName && this.insertConatactModel.lastName  && this.insertConatactModel.companyId){
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "markAsActiveInactive",
    };
    const modalRef = this.modalService.open(
      CommonModelPopUpComponent,
      modalOption
    );
    modalRef.componentInstance.title =
      this.enumPopUpTitles.REPLACE_PRIMARY_CONTACT;
    modalRef.result.then((result) => {
      if (result.confirm) {
        this.insertConatactList.map((x) => (x.markAsPrimaryContact = false));
        contactInfo.isPrimary = result.confirm;
      } else {
        contactInfo.isPrimary = result.confirm;
      }
    });
  }else{
    setTimeout(() => {
      this.insertConatactModel.markAsPrimaryContact = false;      
    }, 100);
    this.toaster.error("Fill All Required Fields")
  }
  }

  onEmailChange(): void {
    this.isEmailInvalid = false;
    this.submited = false;
    const email = this.insertConatactModel.email;
    if (email !== '' && email !== undefined) {
      this.isEmailInvalid = !this.emailPattern.test(this.insertConatactModel.email);
    }
  }

}

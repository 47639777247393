import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserManagementService } from 'src/app/components/admin/user-management/pages/user-management/user-management.service';
import { ActivityLogPostModel } from 'src/app/components/crm/companies/pages/company-info-tabs/activity-tab/activity-tab.model';

@Component({
  selector: 'app-activity-commen-header',
  templateUrl: './activity-commen-header.component.html',
  styleUrls: ['./activity-commen-header.component.scss']
})
export class ActivityCommenHeaderComponent implements OnInit {
  @Output() selectValuesEmit:EventEmitter<any> = new EventEmitter();
  @Output() selectPriority:EventEmitter<any> = new EventEmitter();
  @Output() selectContact:EventEmitter<any> = new EventEmitter();
  @Output() selectAssignee:EventEmitter<any> = new EventEmitter();
  @Input() activityType;
  @Input() priority;
  @Input() contact;
  @Input() assignee;
  @Input() activityTypeData:any[];
  @Input() priorityData:any[];
  @Input() contactData:any[];
  @Input() assigneeData:any[];
  activityLogPostModel  = new ActivityLogPostModel();

  constructor( public userManagementService :UserManagementService,) { }

  ngOnInit(): void {
    this. onDateRangeChange();
  }
  onDateRangeChange() {
    this.userManagementService.selectedDateRange.subscribe((res)=>{
      if(res !== undefined){
        if(res !== ''){
          const dates = res.split(' - ');
          this.activityLogPostModel.fromDate =  dates[0];
          this.activityLogPostModel.endDate =dates[1];
        }else{
          this.activityLogPostModel.fromDate = '';
          this.activityLogPostModel.endDate =''
        }
      }
    });
  }

  selectValueChange(val)
  {
    this.selectValuesEmit.emit(val);
  }
  selectValueChange2(val)
  {
    this.selectPriority.emit(val);
  }
  selectValueChange3(val)
  {
    this.selectContact.emit(val);
  }
  
  selectValueChange4(val)
  {
    this.selectAssignee.emit(val);
  }
}

<!-- <div
 
  class="wrap_Multiselect-div {{
    showDropDown == true ? 'show-multiselect-height ' : ''
  }}"
  (mouseleave)="showDropDown = false"
>
  <div
    class="multiselect_Dropdown_Toggle position-relative  {{
      showDropDown == true ? 'multiselect-dropdown-toggle-border-bottom' : ''
    }}"
    (click)="showDropDown = !showDropDown"
  >
    <div class="selectLable nowrap ps-1">
      <span
        class="text-light-gray font-12"
        [ngClass]="{ activelistlable: checkedList?.length > 0 }"
      >
        {{ listLabel }}<span *ngIf="required" class="required-feild"> *</span>
      </span>
    </div>
    <div class="selectCheckedData nowrap text-primary-color">
      <span
        class="multiselect-check-list font-12 mb-0"
        *ngIf="checkedList?.length > 0"
      >
        <span
          class="d-inline-flex text-truncate w-100px"
          [ngClass]="checkedList?.length == 1 ? 'dClass' : ''"
        >
          {{
            checkedList.join(", ")
              | slice : 0 : checkedList[0]?.length + checkedList[1]?.length + 3
          }}
        </span>
      </span>
      <span class="show-other-btn font-12" *ngIf="checkedList?.length >= 2">
        ...+{{ checkedList?.length - 1 }} Others
      </span>
    </div>
  </div>
  <div class="show-multiselect-dropdown" *ngIf="showDropDown">
    <div class="multiDrop">&nbsp;</div>
    <div class="bg-light-color optionDropdown">
      <span *ngFor="let item of list; let i = index" class="m-0 p-0 form-check">
        <div>
        <input
          class="checkbox hand form-check-input"
          type="checkbox"
          [id]="i"
          [checked]="item.isActive"
          [(ngModel)]="item.isActive"
          (click)="toggle(item)"
        />
        <label
          [for]="i"
          class="form-check-label"
          [ngClass]="{
            'theme-color': item.isActive == true,
            'text-width text-wrap text-break': item.name.length > 10
          }"
          >{{ item.name }}</label
        >
        </div>
      </span>
    </div>
  </div>
  <span
    class="multiselect-drowdown hand"
    (click)="showDropDown = !showDropDown"
  >
    <img src="./assets/SVGIcons/lightArrow.svg" alt="" width="11" height="12" />
  </span>
  <span class="clearLogo hand" (mouseenter)="showDropDown = false">
    <app-svg-icon
      (click)="clearSelectedData()"
      [ngClass]="{ invisible: checkedList?.length <= 0 }"
      src="'../../../../../../assets/SVGIcons/undoSVG.svg"
    >
    </app-svg-icon>
  </span>
</div> -->


<div class="wrap_Multiselect-div multiselect-dropdown-box {{showDropDown == true ? 'show-multiselect-height ' : ''}}"
(mouseleave)="showDropDown = false">
  <div
    class="multiselect_Dropdown_Toggle position-relative multiselect-dropdown  {{showDropDown == true ? 'multiselect-dropdown-toggle-border-bottom' : ''}}"
    (click)="showDropDown = !showDropDown">
    <div class="selectLable nowrap ps-1">
      <span class="text-light-gray font-12"
        [ngClass]="{ activelistlable: checkedList?.length > 0 }">
        {{ listLabel }}<span *ngIf="required" class="required-feild"> *</span>
      </span>
    </div>
    <div class="selectCheckedData nowrap text-primary-color">
      <span
        class="multiselect-check-list font-12 mb-0"
        *ngIf="checkedList?.length > 0"
      >
        <!-- <span>{{ checkedList?.join(", ") | slice : 0 : 23 }}</span> -->
        <span
          class="d-inline-flex text-truncate w-100px"
          [ngClass]="checkedList?.length == 1 ? 'dClass' : ''"
        >
          {{
            checkedList.join(", ")
              | slice : 0 : checkedList[0]?.length + checkedList[1]?.length + 3
          }}
        </span>
      </span>
      <!-- <span class="show-other-btn font-12" *ngIf="checkedList?.length == 1 && checkedList[0].length > 14 ">...</span> -->
      <!-- <span class="show-other-btn font-12" *ngIf="checkedList?.length >= 2">
        ...+{{ checkedList?.length - 1 }} Others
      </span> -->
      <span class="show-other-btn font-12" *ngIf="checkedList?.length > 2">
        ...+{{ checkedList?.length - 2 }} Others
      </span>
    </div>
  </div>
  <div class="show-multiselect-dropdown show-dropdown" *ngIf="showDropDown">
    <!-- <div class="multiDrop">&nbsp;</div> -->
    <div class="bg-light-color optionDropdown px-2 py-2">
      <!-- [ngStyle]="{ width: drowDownColtwo ? '50%' : '100%' }" -->
      <span *ngFor="let item of list; let i = index" class="m-0 p-0 form-check">
        <div class="p-03">
        <input
          class="checkbox hand form-check-input"
          type="checkbox"
          [id]="i"
          [checked]="item.isActive"
          [(ngModel)]="item.isActive"
          (click)="toggle(item)"
        />
        <label
          [for]="i"
          class="form-check-label mb-0"
          [ngClass]="{
            'theme-color': item.isActive == true,
            'text-width text-wrap text-break': item.name.length > 10
          }"
          >{{ item.name }}</label
        >
        </div>
      </span>
    </div>
  </div>
  <span
    class="multiselect-drowdown hand"
    (click)="showDropDown = !showDropDown"
  >
    <img src="./assets/SVGIcons/lightArrow.svg" alt="" width="11" height="12" />
  </span>
  <span class="clearLogo hand" (mouseenter)="showDropDown = false">
    <app-svg-icon
      (click)="clearSelectedData()"
      [ngClass]="{ invisible: checkedList?.length <= 0 }"
      src="'../../../../../../assets/SVGIcons/undoSVG.svg"
    >
    </app-svg-icon>
  </span>
</div>


<div class="main-content h-100 d-flex flex-column w-100" [ngClass]="{'w-100':isSidebarNotificationShow && deviceWidth < 1200 ,'width_82per':isSidebarNotificationShow && deviceWidth > 1200}">
  <nav ngbNav #nav="ngbNav" class="d-flex" style="flex:0 0 auto">
    <ul class="nav nav-tabs" ngbNav  [activeId]="activeTabUrl" (navChange)="onTabChange($event)">

      <li  [ngbNavItem]="'crm'">
        <a  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" ngbNavLink class="border-right-grey" >
        <span style="color: #646E76;">Dashboard</span></a>
      </li>


      <li *ngFor="let tab of tabs, let index = index" [ngbNavItem]="tab.path">
        <!-- [routerLink]="rolesPermissionsService.isEdited===false?tab.path:activeTabUrl" use code in next line -->
        <a  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" ngbNavLink>
          <span class="m-r-10" (click)="closeTab(index, $event)"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="9"
            viewBox="0 0 10.081 10.1"
          >
            <path
              id="close"
              d="M4.363,14.094a.361.361,0,0,0,.255-.106L9.051,9.555l4.435,4.433a.361.361,0,1,0,.51-.51L9.562,9.045,13.995,4.61a.361.361,0,0,0-.51-.51L9.051,8.535,4.616,4.1a.361.361,0,0,0-.509.509L8.541,9.045,4.108,13.48C3.968,13.621,4.267,14.094,4.363,14.094Z"
              transform="translate(-4.021 -3.994)"
              fill="#8b9daa"
            />
          </svg>
        </span>
        <span>{{tab.title}}</span></a>
      </li>


      <div class="m-t-6">
        <span class="border-transparent px-0 ms-3"  (click)="closeAll()">
          <span class="m-r-10 " *ngIf="tabs.length!=0"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="9"
            viewBox="0 0 10.081 10.1"
          >
            <path
              id="close"
              d="M4.363,14.094a.361.361,0,0,0,.255-.106L9.051,9.555l4.435,4.433a.361.361,0,1,0,.51-.51L9.562,9.045,13.995,4.61a.361.361,0,0,0-.51-.51L9.051,8.535,4.616,4.1a.361.361,0,0,0-.509.509L8.541,9.045,4.108,13.48C3.968,13.621,4.267,14.094,4.363,14.094Z"
              transform="translate(-4.021 -3.994)"
              fill="#8b9daa"
            />
          </svg>
        </span>
        <!-- <span *ngIf="tabs.length!=0" class="hand close-tab">Close All</span> -->
        <button *ngIf="tabs.length!=0">Close All</button> 
      </span>
    </div>

    </ul>
  </nav>
  <div class="d-flex flex-column" style="flex:1 1 auto" (click)="toggleIsSidebarNotificationShow()">
    <router-outlet *ngIf="tabs.length > 0"></router-outlet>
  </div>
  <!-- <div [ngbNavOutlet]="nav"></div> -->
  <!-- <ngb-tabset [ngClass]="{'w-100':isSidebarNotificationShow && deviceWidth < 1200 ,'width_82per':isSidebarNotificationShow && deviceWidth > 1200}" class="h-100 d-flex flex-column" [activeId]="activeTabUrl" (tabChange)="onTabChange($event)">
    <ngb-tab *ngFor="let tab of tabs; let index = index" [id]="tab.path">
      <ng-template ngbTabTitle>
        <span class="m-r-10 cross-icon" (click)="closeTab(index, $event)"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="9"
            viewBox="0 0 10.081 10.1"
          >
            <path
              id="close"
              d="M4.363,14.094a.361.361,0,0,0,.255-.106L9.051,9.555l4.435,4.433a.361.361,0,1,0,.51-.51L9.562,9.045,13.995,4.61a.361.361,0,0,0-.51-.51L9.051,8.535,4.616,4.1a.361.361,0,0,0-.509.509L8.541,9.045,4.108,13.48C3.968,13.621,4.267,14.094,4.363,14.094Z"
              transform="translate(-4.021 -3.994)"
              fill="#8b9daa"
            />
          </svg>
        </span>
        <span>{{ tab.title }}</span>
      </ng-template>
      <ng-template class="h-100" ngbTabContent>
        <router-outlet></router-outlet>
      </ng-template>
    </ngb-tab>
  </ngb-tabset> -->
  <app-sidebar-notification *ngIf="isSidebarNotificationShow" class="position-absolute end-0 top-0 d-flex h-100 notificationCard"></app-sidebar-notification>
</div>

<div class="columnSettingBlock d-flex flex-column">
    <i class="icofont-close icon-close hand" (click)="Customizer('')"></i>
    <div class="d-flex align-items-center justify-content-end px-4 py-1 reset-filter">
        <app-svg-icon (click)="resetToDefault(true)"
          [src]="'./assets/SVGIcons/undoSVG.svg'"
          class="m-r-5"
        ></app-svg-icon>
        <span (click)="resetToDefault(true)" class="font-10 m-t-2" type="button">Reset to Default</span>
    </div>
    <div class="column-search d-flex align-items-center my-2">
       <app-svg-icon 
       style="color: #8B9DAA;"
         [src]="'./assets/SVGIcons/searchicon.svg'"    
         class="d-flex align-items-center justify-content-center"
         >
       </app-svg-icon>
       <input
         (input)="onFilterTextBoxChanged()"
         id="filter-text-box"
         class="searched"
         type="text"
         placeholder="Search"
       />
    </div>
        <ag-grid-angular
      class="ag-theme-alpine sideColumnSetting"
      style="height: 100%"
      [columnDefs]="sideColumnDefs"
      [rowDragManaged]="true"
      [icons]="icons"
      [animateRows]="true"
      [headerHeight]="0"
      [rowHeight]="35"
      [rowData]="sideRowData"
      (gridReady)="sideOnGridReady($event)"
      (rowDragEnd)="onRowDragEnd($event)"
      (rowDragLeave)="onRowDragLeave($event)"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    ></ag-grid-angular>
</div>
    <div>
      <div class="modal-header border-0 px-4 mx-2 pb-2 pt-4 mt-2 justify-content-start">
        <h6 class="modal-title text-center text-primary-color font-15">Assign Mailing List</h6>
      </div>
      <div class="modal-body px-4 mx-2">
        <div class="mb-4">
            <app-dropdown
                [selectLabel]="'Mailing List'"
                [data]="mailingListDropdown"
                [selectValue]="mailingLists.mailingListId"
                (selectValueEmit)="mailingLists.mailingListId = $event" 
                [optionDisable]="mailingLists.mailingListId"
                [required]="false"
                (click)="clearValue()">
            </app-dropdown>
        </div>
        <div class="mb-4 d-flex justify-content-center">
            <span class="text-light-gray font-12">OR</span>
        </div>
        <div class="mb-3">
            <app-checkbox
            [lableClass]="'text-gray '"
            (check)="mailingLists.isCreated = !mailingLists.isCreated;checkedVlue($event);clearDropdown()"
            [status]="mailingLists?.isCreated"
            [(ngModel)]="mailingLists.isCreated"
            [lableName]="'Create New Mailing List'"
            [dyanamicId]="'createNewMsilingList'"
          >
          </app-checkbox>

        </div>
        <div class="mb-2">
            <app-input 
            [inputLabel]="'Mailing List'" 
            [inputType]="'text'" 
            [inputValue]="mailingLists?.mailingListName"
            (inputValueEmit)="mailingLists.mailingListName=$event"
            [isInputDisabled]='!mailingLists?.isCreated'
            [ngClass]="{'border-red': !mailingLists.mailingListName && submit && mailingLists?.isCreated }" 
            (click)="clearDropdown()" >
        </app-input>

        </div>


      </div>
      <div class="modal-footer border-0 px-4 mx-2 pb-4 justify-content-center">
          <app-button 
            [buttonType]="'button'"  
            [button_2]="true" 
            [buttonClass]="'button button-info mx-2'" 
            (click)="activeModal.close()" 
            [buttonLable]="enumButtonName.CANCEL">
          </app-button>
          <app-button 
            [buttonType]="'submit'"  
            [button_2]="true"  
            [buttonClass]="'button mx-2 ' + (mailingLists.mailingListId || mailingLists.mailingListName || mailingLists.isCreated ? ' button-primary' : ' dark-info') " 

            [buttonLable]="enumButtonName.APPLY">
        </app-button>
        <!-- (click)="applyChanges()" -->
      </div>
    </div>
    
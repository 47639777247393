<div class="container-fluid container-box">
  <ag-grid-angular [style]="{ height: flexHeight }" class="ag-theme-alpine" [rowData]="rowData"
  [defaultColDef]="defaultColDef"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [components]="components" [rowSelection]="dataModel.rowSelection" [suppressRowClickSelection]="true"
    [suppressDragLeaveHidesColumns]="true" [columnDefs]="dataModel.colDefs" [pagination]="true" (gridReady)="onGridReady($event)" [suppressPaginationPanel]="true">
  </ag-grid-angular>
  <div class="wrapper mt-3">
    <div class="footer-box">
      <app-button *ngIf="currentTab !== tabName.CODECROSSWALK_TAB"
       class="px-2" [button_2]="true" [buttonClass]="'button button-info mx-0'"
        [buttonLable]="enumButtonName.CANCEL" (click)="closeTab()">
      </app-button>
      <app-button *ngIf="currentTab === tabName.CODECROSSWALK_TAB"
      class="px-2" [button_2]="true" [buttonClass]="'button button-info mx-0'"
       [buttonLable]="enumButtonName.CANCEL" (click)="close()">
     </app-button>

      <app-button progressStepPrev class="px-2" [button_2]="true" [buttonClass]="'button dark-info mx-0'"
        [buttonLable]="enumButtonName.PREVIOUS">
      </app-button>
      <app-button progressStepNext class="ps-2 pe-10" [button_2]="true" [buttonClass]="importDocumentsService.submit?'button button-primary mx-0':'button  dark-info mx-0'"
        [buttonLable]="enumButtonName.NEXT">
      </app-button>
    </div>
  </div>
</div> 
<div class="select-wrapper col-1 d-flex align-items-end position-relative">
  <select
    #mySelect
    class="w-100 p-l-15"
    (click)="active = !active"
    [ngClass]="active ? 'down' : ''"
  >
    <option *ngFor="let item of selectlist">
      {{ item }}
    </option>
  </select>
  <app-svg-icon
    *ngIf="active"
    class="active"
    [src]="'./assets/SVGIcons/arrow-down-icon.svg'"
  ></app-svg-icon>
</div>

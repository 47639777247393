<div class="reset-component-wrapper">
  <div
    class="container-fluid px-5 py-5 d-flex align-items-center justify-content-center"
  >
    <div class="card mb-0">
      <div
        class="card-body p-3 d-flex align-items-center justify-content-center"
      >
        <div
          class="reset-form-wrapper d-flex align-items-center justify-content-center"
        >
          <div>
            <img
              src="../../../../assets/images/login/aegislogo.png"
              alt=""
              width="200"
            />
          </div>
          <span *ngIf="!passSuccess" class="title-heading">Reset your password</span>
          <span *ngIf="passSuccess" class="title-heading me-4 pe-2">Password changed</span>
          <span *ngIf="!passSuccess" class="text-light-gray text-nowrap d-flex sub-heading">Enter a new password for&nbsp;({{ userEmail ? userEmail : 'Email Address'}})</span>
          <span *ngIf="passSuccess" class="text-light-gray me-2 d-flex sub-heading"><a class="text-decoration-underline" routerLink="/auth/login">Sign in</a>&nbsp;with your new password.</span>
          <div class="col-lg-8" [ngClass]="{'invisible':passSuccess}">
            <div class="form-group">
              <form action="" [formGroup]="passwordForm">
                <div class="mb-4">
                  <label class="mx-2 my-1" for="">New Password</label>
                  <div class="password-showhide-control">
                    <input
                      [type]="hidePassword1 ? 'text' : 'password'"
                      class="form-control"
                      placeholder="Enter Password"
                      formControlName="password" 
                      [(ngModel)]="restPasswordRequestmodel.newPassword"
                    />
                    <button
                      type="button"
                      (click)="hidePassword1 = !hidePassword1"
                      class="btn-showHide"
                    >
                      {{ hidePassword1 ? "Hide" : "Show" }}
                    </button>
                  </div>
                  <span
                    class="text-danger"
                    *ngIf="
                      passwordForm.controls.password.errors?.required &&
                      submitted
                    "
                    >Password is required</span
                  >
                </div>
                <div class="mb-4">
                  <label class="mx-2 my-1" for="">Confirm Password</label>
                  <div class="password-showhide-control">
                    <input
                      [type]="hidePassword2 ? 'text' : 'password'"
                      type="password"
                      class="form-control"
                      placeholder="Enter Password"
                      formControlName="confirmPassword" 
                      [(ngModel)]="restPasswordRequestmodel.confirmNewPassword"
                    />
                    <button
                      type="button"
                      (click)="hidePassword2 = !hidePassword2"
                      class="btn-showHide"
                    >
                      {{ hidePassword2 ? "Hide" : "Show" }}
                    </button>
                  </div>
                  <span
                    class="text-danger"
                    *ngIf="
                      passwordForm.controls.confirmPassword.errors?.required &&
                      submitted
                    "
                    >Confirm password is required</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="confirmPassword.errors?.match && submitted"
                  >
                    Password & Reset password does not matched</span
                  >
                </div>
                <div class="d-flex justify-content-between mt-5">
                  <app-button
                    [buttonLable]="enumButtonName.CONFIRM"
                    [buttonClass]="'border-blue px-2 font-15'"
                    [buttonType]="'submit'"
                    (click)="resetPassword()">
                  </app-button>
                  <app-button
                    [buttonLable]="enumButtonName.CANCEL" 
                    routerLink="/auth/login"
                    [buttonClass]="'border-grey px-2 font-15'">
                  </app-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="step-progress"
[ngStyle]="{ 'margin-top.px': currentTab === tabName.CODECROSSWALK_TAB ? -15 : 20 }">
  <div class="container-1">
    <ng-container *ngFor="let item of itemProgressList; index as i">
      <div
        class="check-box"
        [ngClass]="{
          complete: item.status == 'completed',
          active: item.status == 'in progress'
        }"
      >
        <svg
          *ngIf="item.names[i] == 'Summary' && failed"
          xmlns="http://www.w3.org/2000/svg"
          width="46"
          height="46"
          viewBox="0 0 52 52"
        >
          <g
            id="Group_42021"
            data-name="Group 42021"
            transform="translate(-2047 -238)"
          >
            <g
              id="Ellipse_53"
              data-name="Ellipse 53"
              transform="translate(2047 238)"
              fill="#ea5a5a"
              stroke="#ea5a5a"
              stroke-width="2"
            >
              <circle cx="26" cy="26" r="26" stroke="none" />
              <circle cx="26" cy="26" r="25" fill="none" />
            </g>
            <path
              id="Path_1564"
              data-name="Path 1564"
              d="M162.38,179.386a1.565,1.565,0,0,1-1.105-2.671l15.483-15.483a1.565,1.565,0,0,1,2.213,2.213l-15.483,15.483A1.566,1.566,0,0,1,162.38,179.386Zm0,0"
              transform="translate(1901.879 93.92)"
              fill="#fff"
            />
            <path
              id="Path_1565"
              data-name="Path 1565"
              d="M177.841,179.4a1.551,1.551,0,0,1-1.1-.459l-15.483-15.481a1.564,1.564,0,1,1,2.212-2.212l15.483,15.483a1.563,1.563,0,0,1-1.107,2.669Zm0,0"
              transform="translate(1901.898 93.902)"
              fill="#fff"
            />
          </g>
        </svg>

        <svg id="checkbox" viewBox="0 0 100 100" [style.display]="(item.names[i] == 'Summary'  && failed)  ? 'none' : 'block'">
          <circle class="circle" cx="50.5" cy="49" r="45" />
          <polyline class="check" points="28.5,51.9 41.9,65.3 72.5,32.8 " />
        </svg>
        <div
          class="progress-line"
          [ngClass]="{
            complete: item.status == 'completed',
            active: item.status == 'in progress'
          }"
          *ngIf="i !== itemlength - 1"
        >
          <div class="progress-percent"></div>
        </div>
        <div class="info text-box">
          <span
            class="step"
            [ngClass]="{
              errors: item.names[i] == 'Errors',
              map: item.names[i] == 'Map',
              template:
                item.names[i] == 'Preview' ||
                item.names[i] == 'Template' ||
                item.names[i] == 'Import'
            }"
            >{{ item.names[i] }}</span
          >
          <!-- <span class="progress-info" [ngClass]="{
                  'completed':item.status == 'completed',
                  'in-progress': item.status == 'in progress'
                }">{{item.status}}</span> -->
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="step-content">
  <ng-content select="app-progress-step"></ng-content>
</div>

import { AgGridService } from './../ag-grid.service';
import { Component, OnInit } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

export interface ICustomHeaderParams {
  menuIcon: string;
}

@Component({
  selector: 'app-header-icon',
  templateUrl: './header-icon.component.html',
  styleUrls: ['./header-icon.component.scss']
})
export class HeaderIconComponent implements OnInit, IHeaderAngularComp {

  public params!: IHeaderParams & ICustomHeaderParams;

  showArrow: boolean = true;
  arrowImg: string = "./assets/SVGIcons/downarrow.svg";
  constructor(public agGridService:AgGridService) { }

  ngOnInit(): void {
    this.agGridService.toggleHeaderExpandIcon.subscribe((res:boolean)=>{
      if(res){
        var checkAllExpandRows  = this.agGridService.getGridApi().getModel()?.gridOptionsWrapper.gridOptions.rowData?.every((row)=>row.isExpandable == true);
        if(checkAllExpandRows){
          this.showArrow = false;
          this.arrowImg = "./assets/SVGIcons/uparrow.svg";
        }
        else{
          this.showArrow = true;
          this.arrowImg = "./assets/SVGIcons/downarrow.svg";
        }
      }else{
        this.showArrow = true;
        this.arrowImg = "./assets/SVGIcons/downarrow.svg";
      }
    })
  }
  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
  }

  refresh() {
    return true;
  }
  toggleIcon() {
    if (this.showArrow) {
      this.params.api.getModel().forEachNode((row) => { row.data.isExpandable = true; });
      this.showArrow = false;
      this.arrowImg = "./assets/SVGIcons/uparrow.svg";
    }
    else {
      this.params.api.getModel().forEachNode((row) => { row.data.isExpandable = false; });
      this.showArrow = true;
      this.arrowImg = "./assets/SVGIcons/downarrow.svg";
    }
    this.params.api.resetRowHeights();
    this.params.api.redrawRows();
  }

}

import * as exp from "constants";

export enum GridName {
    LEADS = "leads",
    COMPANIES = "companies",
    CUSTOMERS = "customers",
    PROSPECTS = "prospects",
    CONTACTS = "contacts",
    VENDORLISTS = 'vendor-list',
    VENDORCONTACTS = 'vendor-contact',
    REQUIREMENTS = "requirements",
    COMPANYRECYCLE = "company-recycle-bin",
    CODECROSSWALK = "code-crosswalk",
    CONTACTRECYCLE = "contact-recycle-bin",
    ARCHIVED = "archivedCompanies",
    CERTIFICATION = "Certification",
    PARTLIST = "parts-list",
  }

  export enum AggridHeadername {
    COMPANIES = "Company",
    VENDOR_NAME = "Vendor Name",
    STAGE     = "Stage",
    CONTACT_NAME = "Contact Name",
    CONTACT_COMPANY_NAME = "company",
    PHONE = "Phone",
    SALESPERSON = "Salesperson",
    COMPANY_EMAIL = "Company Email",
    DANDB = "D&B Number",
    MAIN_CONTACT =     "Main Contact",
    EMAIL    = "Email",
    TYPE = "Type",
    DATE_CONTACTED    = "Date Contacted",
    RATING = "Rating",
    ACTIVE =  "Active",
    FAX = "Fax",
    WEBSITE = "Website",
    EMPLOYEES   = "Employees",
    TIMEZONE  = "Timezone",
    INDUSTRY  = "Industry",
    SIC  = "SIC",
    NAICS    = "NAICS",
    COUNTRY   = "Country",
    DO_NOT_CALL =  "Do Not Call",
    REVENUE  = "Revenue",
    ALTERNATE_SALESPERSON = "Alternate Salesperson",
    QUICK_NOTES =   "Quick Notes",
    ACCOUNT_NUMBER  = "Account number",
    CURRENCY   = "Currency",
    SOURCING_EMAIL =   "Sourcing Email",
    ACCOUNTING_CONTACT =   "Accounting Contact",
    SALES_12_MONTHS     = "Sales 12 months",
    STATE = "State",
    LEAD_SCORE = "Lead Score",
    INTENT_TO_BUY =  "Intent to Buy",
    DEPARTMENT =  "Department",
    TITLE     = "Title",
    LOCATION           = "Location",
    MAIN_OFFICE_NUMBER     =  "Main Office Number",
    EMAIL_ADDRESS  = "Email Address",
    SKYPE  = "Skype",
    XING     = "Xing",
    LINKEDIN  = "LinkedIn",
    VENDOR_TYPE = "Vendor Type",
    AVL   = "AVL",
    GENERAL_EMAIL    =  "General Email",
    PRIMARY_CONTACT_NAME = "Primary Contact Name",
    PRIMARY_CONTACT_EMAIL   = "Primary Contact Email",
    PRIMARY_CONTACT_PHONE =    "Primary Contact Phone",
    ACCOUNTING_CONTACT_NAME          = "Accounting Contact Name",
    ACCOUNTING_CONTACT_EMAIL_ADDRESS        = "Accounting Contact Email Address",
    PARENT_COMPANY            = "Parent Company ",
    ENTITY_TYPE =   "Entity Type",
    BUYERS_REP =  "Buyer's Rep",
    GENDER = "Gender",
    MOBILE_NUMBER   = "Mobile Number",
    DIRECT_NUMBER    = "Direct Number",
    MAIN_OFFICE_NUMBER_EXTENSION =      "Main Office Number + Extension",
    MARK_AS_PRIMARY =     "Mark As Primary",
    PRIMARY =  "Primary",
    MARK_AS_ACCOUNTING    = "Mark As Accounting",
    ACCOUNTING =   "Accounting",
    NEXT_TASK_DUE_DATE = "Next Task Due Date",
    LAST_CONTACT    = "Last Contact",
    SIC_CODE    = "SIC Code",
    SIC_DESCRIPTION      = "SIC Description",
    NAICS_CODE = "NAICS Code",
    NAICS_DESCRIPTION        = "NAICS Description",
    NAICS_YEAR =       "NAICS Year",
  }  


  export enum  HistoryHeader {
    MESSAGE  = "Message",
    ACTION =   "Action",
    BY       = "By",
    DATE     = " Date",
  }

  export enum DocumentTabHeader {
     FILE_NAME =    "File Name",
     FILE_TYPE =    "Type",
     SIZE  = "Size",
     CATEGORY    = "Category",
     SOURCE =  "Source",
     DATE_UPLOADED     = "Date Uploaded",
  }

  export enum CertificationTabHeader {
    CERTIFICATION_NAME  = "Certification Name",
    DATE_ISSUED   = "Date Issued",
    EXPIRATION_DATE =  "Expiration Date",
    FILE_NAME =   "File Name",
    EXPIRED   = "Expired",
    ACTIONS =   "Actions"
  }

  export enum PartandInventoryHeader {
   MPN =    "MPN",
   MFR =    "MFR",
   ON_HAND =     "On Hand",
   AVAILABLE =    "Available",
   ALLOCATED =   "Allocated",
   Consignment    = "Consignment",
   PENDING =   "Pending",
   QUARANTINE   = "Quarantine",
   OFFERS   = "Offers",
   OEM_EXCESS =  "OEM Excess",

  }

  //  REQUIRMENT ENUM 

  export enum RequirementHeader {
    REQUIREMENT_NUMBER    = "Requirement Number",
    REQ_DATE   = "Req Date",
    BUY_TARGET =  "Buy Target",
    STATUS =    "Status",
    PART_NUMBER   = "Part Number",
    DATE  = "Date Code",
    TARGET_PRICE =  "Target Price",
    SALESPERSON  =  "Salesperson",
    QTY   = "Qty"
  }


  //   PART AND Inventory  Enum 

  export enum InstockHeader {
   PART_NUMBER      = "Part Number",
   MFR =   "Mfr",
   ONHAND    = "On Hand",
   AVAILABLE = "Available",
   DATE_CODE =  "Date Code",
   LOT_CODE =    "Lot Code",
   SPQ    = "SPQ",
   ITEM_PKG  = "Item Pkg",
   ROHS =   "RoHS",
   VENDOR =   "Vendor",

  }

  export enum allocationHeader {
    ALLOCATED_QTY = "Allocated Qty",
    ALLOCATED_DATE = "Allocated Date",
    DATE_CODE = "Date Code",
    LOT_CODE = "Lot Code",
    SPQ = "SPQ",
    CONDITION = "Condition",
    SO_NO = "So No",
    ROHS =   "RoHS",
    CUSTOMER = "Customer",
    WAREHOUSE = "Warehouse",
    LOCATION = "Location"
  }

  export enum ConsignmentHeader {
     CONISIGNED_QTY = 'Consigned Qty',
     DATE_CODE = 'Date Code',
     LOT_CODE = 'Lot Code',
     AVAILABLE = 'Available',
     MPN =  'MPN',
     MFR = 'MFR',
     SPQ = 'SPQ',
     ITEM_PKG = 'Item Pkg',
     CONSIGNOR = 'Consignor',
     ROHS = 'RoHS'
  }

  export enum PendingHeader {
    STATUS  = "Status",
    MFR = 'MFR',
    WO_NO = 'WO No',
    TESTHOUSE = 'Testhouse',
    DATE_CODE = 'Date Code',
    LOT_CODE = 'Lot Code',
    QTY = 'Qty',
    CONDITION = 'Condition',
    MPN = 'MPN',
    VENDOR = 'Vendor',
  }

  export enum QuarantineHeader {
    STATUS = "Status",
    MFR = 'MFR',
    WO_NO = 'WO No',
    NCM =  'NCM',
    DATE_CODE = 'Date Code',
    LOT_CODE = 'Lot Code',
    QTY = 'Qty',
    CONDITION = 'Condition',
    MPN = 'MPN',
    REASON = 'Reason'
  }

  export enum importHeaders {
    SYSTEM_COLUMNS    =  "System Columns",
    MAP_TO = "Map to",
    STATUS = "Status",
    COMPANY_NAME = "Compnay Name",
    STAGE = "Stage",
    TYPE = "Type",
    SALESPERSON = "Salesperson",
    COUNTRY = "Country",
    ALTERNATE_SALESPERSON = "Alternate Salesperson",

    //  erros tabs 

    ROW_NO  = "Row No",
    ERROR_FIELD = "Error Field",
    ERROR_DATA = "Error Data",
    CORRECT_FIELD = "Correct Field",
    ERROR_MESSAGE = "Error Message",


  }

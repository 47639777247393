import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { ImportDocumentsService } from '../../import-documents.service';
import { AgGridI, AgGridPaginationConfig } from 'src/app/shared/components/ag-grid/ag-grid.model';
import { AgGridImportCodecrosswalkDuplicatesTab, AgGridImportCompaniesDuplicatesTab } from './duplicates-tab.model'
import { AgCustomHeaderComponent } from 'src/app/shared/components/ag-grid/ag-custom-header/ag-custom-header.component';
import { Button, CurrentTab, RoutePath } from 'src/app/shared/model/enum';
import { Subscription } from 'rxjs';
import { ImportCodeCrosswalkData, ImportCompanyCommand } from '../../import-documents.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { CodeCrosswalkService } from 'src/app/components/admin/code-crosswalk/code-crosswalk.service';
@Component({
  selector: 'app-duplicates-tab',
  templateUrl: './duplicates-tab.component.html',
  styleUrls: ['./duplicates-tab.component.scss']
})
export class DuplicatesTabComponent implements OnInit {
  @Output() navToError:EventEmitter<boolean>=new EventEmitter() ;
  flexHeight;
  messageflexHeight;
  private gridApi;
  private gridColumnApi;
  dataModel: AgGridI;
  data = [];
  enumButtonName = Button;
  importCodeCrosswalkData = new ImportCodeCrosswalkData();
  importCompanyCommand = new ImportCompanyCommand();
  sub:Subscription;
  potentialCompanies=[];
  potentialCodeCrosswalk=[];
  public components: {
    [p: string]: any;
  } = {
      agColumnHeader: AgCustomHeaderComponent,
    };
  agGridPaginationConfig = new AgGridPaginationConfig();
  @Input() potentialError: boolean = false;
  public defaultColDef: ColDef = {
    resizable: true,
    maxWidth:350
  };
  overlayNoRowsTemplate = '<div class="ag-overlay-no-rows-center">No Records Available</div>';
  currentTab:string;
  importError:boolean = false;
  routeUrl:string;
  routePath = RoutePath;
  tabName = CurrentTab;

  constructor(
    public commonService            : CommonService,
    public toaster                  : ToastrService,
    public importDocumentsService   : ImportDocumentsService,
    private router                  : Router,
    public tabsService: TabsService,
    public codeCrosswalkService     :CodeCrosswalkService,
  ) { 
    this.routeUrl = this.router.url;

    this.tabsService.currentTab.subscribe(res => {
      if (res !== undefined) {
        this.currentTab = res;
      } 
    })

    this.sub= this.importDocumentsService.dataPassAccordingToTrackingBar.subscribe(res=>{
      this.importError = res?.response?.importError?.length < 1 ? true:false
      if(this.importDocumentsService.indexActive==4){
        const res= this.importDocumentsService.dataPassAccordingToTrackingBar.getValue();
        if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
          this.potentialCodeCrosswalk = res?.response?.potentialCodeCrosswalk??[];
        }else{
          this.potentialCompanies = res?.response?.potentialCompanies??[];
        }
        this.fillRowData()
      }
    })
  }

  ngOnInit(): void {
    this.commonService.headerHeight.subscribe(res => {
      this.flexHeight = "calc(100vh - 340px - " + res + "px" + ")";
      this.messageflexHeight = "calc(100vh - 290px - " + res + "px" + ")";

    })
    
    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      this.dataModel = AgGridImportCodecrosswalkDuplicatesTab;
    //  this.data = [
    //   { type: 'new record', sicCode: 'new record', naicsCode: 'new record', industry: 'new record', action: 'new record' },
    // ]
    }else{
      this.dataModel = AgGridImportCompaniesDuplicatesTab;
    }
  
  }

  fillRowData() {
    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      this.data=this.potentialCodeCrosswalk;
        this.data.map((name)=>{
        name?.duplicateCodeCrosswalk.sort((a, b) => a.type.localeCompare(b.type))
        }) ;
    }else{
      this.data=this.potentialCompanies;
        this.data.map((name)=>{
        name.duplicateCompanies.sort((a, b) => a.type.localeCompare(b.type))
        }) ;
        // this.data=[]
}

  
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  moveToEnd() {
    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      this.insertCodeCrosswalkData();
    }else{
      this.insertData();
    }
  }

  closeTab(){
    if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
      this.importDocumentsService.closeVendorTab();
      this.router.navigate([this.routePath.VENDORLIST_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.VENDORLIST_TAB)
    }else{
      this.importDocumentsService.closeTab();
      this.router.navigate([this.routePath.COMPANY_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.COMPANY_TAB)
    }
  }
  close(){ 
    this.codeCrosswalkService.valuePopup.next(true)
   }
  

insertData(){
  const insertList=[];
  this.data.map((a)=>{
      let data=a.duplicateCompanies.find(b=>b.action!== undefined);
      if(data){
        insertList.push(data);
      }
  })
  console.log('insertList..',insertList);
  const data=this.importDocumentsService.dataPassAccordingToTrackingBar.getValue();
  this.importCompanyCommand.companyData=data?.response?.companyData;
  this.importCompanyCommand.importError=data?.response?.importError;
  this.importCompanyCommand.duplicateCompanies=insertList;
  this.importCompanyCommand.templateId=data?.response?.templateId;
  if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
    this.importCompanyCommand.isVendor = true;
    }
  this.importDocumentsService.insertImportOnlyCompany(this.importCompanyCommand).subscribe(res=>{
    if(res.isSuccess){
      this.toaster.success(res.message);
      this.importDocumentsService.applyChanges.next(res);
      this.importDocumentsService.toEnd.next(res);
    }else{
      this.toaster.error(res.message);
      this.importDocumentsService.applyChanges.next(res);
      this.importDocumentsService.toEnd.next(res);
    }
  })
}

insertCodeCrosswalkData(){
  const insertList=[];
  this.data.map((a) => {
    let data = a.duplicateCodeCrosswalk.find(b => b.action !== undefined);
    if (data) {
      insertList.push(data);
    }
  });
  
  const data=this.importDocumentsService.dataPassAccordingToTrackingBar.getValue();
  this.importCodeCrosswalkData.codeCrosswalkData=data?.response?.codeCrosswalkData;
  this.importCodeCrosswalkData.importError=data?.response?.importError;
  this.importCodeCrosswalkData.duplicateCodeCrosswalk=insertList;
  this.importCodeCrosswalkData.templateId=data?.response?.templateId;
  this.importDocumentsService.insertImportCodeCrosswalk(this.importCodeCrosswalkData).subscribe(res=>{
    if(res.isSuccess){
      this.toaster.success(res.message);
      this.importDocumentsService.applyChanges.next(res);
      this.importDocumentsService.toEnd.next(res);
    }else{
      this.toaster.error(res.message);
      this.importDocumentsService.applyChanges.next(res);
      this.importDocumentsService.toEnd.next(res);
    }
  })
}


navigateToErrorTab(){
  this.navToError.emit(true)
}

}

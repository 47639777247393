import { Injectable } from "@angular/core";
import { Observable, Subject, tap } from "rxjs";
import { Result } from "src/app/shared/model/response.model";
import { LoginModel } from "./login.model";
import { DataService } from "src/app/shared/services/data.service";
import { URLS } from 'src/app/components/admin/user-management/user-api-constant';
@Injectable({
  providedIn: "root",
})
export class LoginService {
  public activeTab = new Subject<string>();
  
  constructor(public service: DataService) {}
  SignIn(data: LoginModel): Observable<Result> {
    let url = URLS.login.signIn;
    const myjson=JSON.stringify(data)
    return this.service.post(url, data, false).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  SignOut(): Observable<Result> {
    let url = URLS.login.signOut;
    return this.service.post(url,'').pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }


  //
  setActiveTab(tabName:string)
  {
    this.activeTab.next(tabName);
  }



}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MarkComplete } from '../../activit-manager.model';
import { Button } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-mark-complete',
  templateUrl: './mark-complete.component.html',
  styleUrls: ['./mark-complete.component.scss']
})
export class MarkCompleteComponent implements OnInit {
  @Input() activityIdsList: [] = [];

   
  enumButtonName = Button;
  constructor(public  activeModal:NgbActiveModal) { }
  ngOnInit(): void {
  }



}

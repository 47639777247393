<div>
    <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen">
        <h6 class="modal-title text-center text-primary-color font-15">{{ insertUpdateCodeCrosswalk.id ? this.enumPopUpTitles.EDIT : this.enumPopUpTitles.ADD }} Code Group</h6>
        <div class="hand d-flex align-items-center">
            <div class="">
                <app-switch class="" 
                [status]="insertUpdateCodeCrosswalk.active"
                (click)="toggleActive()"
                ></app-switch>
            </div>
            <div class="ps-3 p-r-10">
                <app-svg-icon ></app-svg-icon>
            </div>
            <div (click)="clearDetails()" class="hand d-flex align-items-center ps-2">
                  <app-svg-icon 
                      class=""
                      [src]="'./assets/SVGIcons/Clearicon.svg'"  
                      class="h-33px">
                  </app-svg-icon>
                  <span class="px-2 pe-0 clear-color font-13">Clear Details</span>
              </div>
        </div>
    </div>
    <div class="modal-body px-4 mx-2 pb-0">
        <div class="container p-0">
            <div class="row gap-3 mx-0">
                <div class="col text-center  px-0">
                    <app-input 
                        onlyNumbers 
                        [inputLabel]="'SIC Code'" 
                        [inputType]="'text'" 
                        [inputValue]="insertUpdateCodeCrosswalk.sic"
                        (inputValueEmit)="insertUpdateCodeCrosswalk.sic=$event;onSicInputChange($event);clearValue(insertUpdateCodeCrosswalk.sic, 'sic')"
                         [ngClass]="{'border-red': !insertUpdateCodeCrosswalk.sic && submit }" 
                         (focusout)="onSicInputBlur()"
                         [inputMaxlength]="8"
                        [inputReqValidation]="true">
                    </app-input>
                    <div *ngIf="isSicError" style="font-size: 10px;color:red;position: absolute;">SIC Code must be at least 4 characters.</div>
                </div>
                <!-- <div class="col text-center px-0">
                    <div class="text-center mb-3">
                        <div class="form-floating">
                          <textarea 
                          class="form-control resize scrollable" 
                          placeholder="Leave a comment here" 
                          id="floatingTextarea" 
                          [(ngModel)]="insertUpdateCodeCrosswalk.sicDescription"
                          [ngClass]="{'bordered-red':!insertUpdateCodeCrosswalk.sicDescription && submit }" 
                          autocomplete="off" 
                          ></textarea>
                          <label for="floatingTextarea">SIC Description<i class="fst-normal ps-1 p-0 text-danger">*</i></label> 
                        </div>
                      </div>
                </div> -->

                <div class="col text-center px-0">
                    <div class="text-center mb-3">
                        <div class="d-flex flex-column position-relative p-b-10 addNote" 
                        [ngClass]="{'bordered-red':!insertUpdateCodeCrosswalk.sicDescription && submit }"
                        >
                            <label class="form-label position-absolute addLabel font-12" 
                            [class.label-transition]="insertUpdateCodeCrosswalk.sicDescription"
                            [class.label-transition-active]="insertUpdateCodeCrosswalk.sicDescription"
                            for="">
                                SIC Description <i class="fst-normal ps-1 p-0 text-danger">*</i>
                            </label>
                            <textarea
                              name="note"
                              rows="10"
                              class="form-control pt-1 mx-3 font-13 Textarea"
                              [(ngModel)]="insertUpdateCodeCrosswalk.sicDescription"   
                              >
                            </textarea>
                          </div> 
                    </div>
                </div>

            </div>
            <hr>
            <div class="row gap-3 mt-4 mx-0">
                <div class="col px-0 mx-0">
                    <div class="col text-center mb-4 px-0">
                        <app-input 
                            onlyNumbers 
                            [inputLabel]="'NAICS Code'" 
                            [inputType]="'text'" 
                            [inputValue]="insertUpdateCodeCrosswalk.naics"
                            [inputMaxlength]="4"
                            (inputValueEmit)="insertUpdateCodeCrosswalk.naics=$event;onNaicsInputChange($event);clearValue(insertUpdateCodeCrosswalk.naics, 'naics')"
                            [ngClass]="{'border-red':!insertUpdateCodeCrosswalk.naics && submit }" 
                            (focusout)="onNaicsInputBlur()"
                            [inputReqValidation]="true">
                        </app-input>
                        <div *ngIf="isNaicsError" style="font-size: 10px;color:red;position: absolute;">NAICS Code must be at least 4 characters.</div>
                    </div>
                    <div class="col text-center  px-0">
                        <app-input 
                            onlyNumbers 
                            [inputLabel]="'NAICS Year'"     
                            [inputType]="'text'" 
                            [inputReqValidation]="true"
                            [inputMaxlength]="4"
                            [inputValue]="insertUpdateCodeCrosswalk.naicsYear"
                            (inputValueEmit)="insertUpdateCodeCrosswalk.naicsYear=$event;onYearInputChange($event);clearValue(insertUpdateCodeCrosswalk.naicsYear, 'naicsYear')"
                            (focusout)="onYearInputBlur()"
                            [ngClass]="{'border-red': !insertUpdateCodeCrosswalk.naicsYear && submit }" 
                            >
                        </app-input>
                        <div *ngIf="isYearError" style="font-size: 10px;color:red;position: absolute;">NAICS Year must be at least 4 characters.</div>
                    </div>
                </div>
                <!-- <div class="col text-center  px-0 mx-0">
                    <div class="text-center mb-3">
                        <div class="form-floating">
                          <textarea 
                          class="form-control" 
                          placeholder="Leave a comment here" 
                          id="floatingTextarea" 
                          autocomplete="off" 
                          [(ngModel)]="insertUpdateCodeCrosswalk.naicsDescription"
                          [ngClass]="{'bordered-red': !insertUpdateCodeCrosswalk.naicsDescription && submit }" 
                          ></textarea>
                          <label for="floatingTextarea">NAICS Description<i class="fst-normal ps-1 p-0 text-danger">*</i></label> 
                        </div>
                      </div>
                </div> -->

                 <div class="col text-center px-0 mx-0">
                    <div class="text-center mb-3">
                        <div class="d-flex flex-column position-relative p-b-10 addNote" 
                        [ngClass]="{'bordered-red': !insertUpdateCodeCrosswalk.naicsDescription && submit }" 
                        >
                        <label class="form-label position-absolute addLabel font-12" for=""
                        [class.label-transition]="insertUpdateCodeCrosswalk.naicsDescription"
                        [class.label-transition-active]="insertUpdateCodeCrosswalk.naicsDescription"
>
                            NAICS Description <i class="fst-normal ps-1 p-0 text-danger">*</i>
                        </label>
                            <textarea
                              name="note"
                              rows="10"
                              class="form-control pt-1 mx-3 font-13 Textarea"
                              [(ngModel)]="insertUpdateCodeCrosswalk.naicsDescription"
                              >
                            </textarea>
                          </div>
                     </div>            
                    </div>    
            </div>
            <hr>
            <div class="row gap-3 mt-4 mb-2 mx-0">
                <div class="col px-0 mx-0">
                    <div class="col text-center mb-4 px-0">
                        <app-input 
                            onlyAlphabets 
                            [inputLabel]="'Industry'" 
                            [inputType]="'text'" 
                            [inputValue]="insertUpdateCodeCrosswalk.industry"
                            (inputValueEmit)="insertUpdateCodeCrosswalk.industry=$event"
                            [ngClass]="{'border-red': !insertUpdateCodeCrosswalk.industry && submit }" 
                            [inputReqValidation]="true">
                        </app-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer p-4 m-2 mt-0 pt-0 border-0 justify-content-end">
        <div class="m-0">
          <app-button class="" [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info'" (click)="activeModal.close()" [buttonLable]="enumButtonName.CANCEL"></app-button>
          <app-button class="ms-3" [buttonType]="'submit'"  [button_2]="true" [buttonClass]="'button dark-info mx-0'" (click)="insertUpdateCodeCrosswalk.id ?addCodeGroup(enumButtonName.UPDATE):addCodeGroup(enumButtonName.SAVE)" [buttonLable]="insertUpdateCodeCrosswalk.id ? enumButtonName.UPDATE : enumButtonName.SAVE"></app-button>
          <app-button class="ms-3" [buttonType]="'submit'"  [button_2]="true" [buttonClass]="'button dark-info mx-0'" (click)="insertUpdateCodeCrosswalk.id ?addCodeGroup(enumButtonName.UPDATE):addCodeGroup(enumButtonName.SAVE);addNew = !addNew" [buttonLable]="insertUpdateCodeCrosswalk.id ? enumButtonName.UPDATEANDADDNEW : enumButtonName.SAVEANDADDNEW"></app-button>
        </div>
      </div>
</div>

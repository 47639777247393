import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Assign, AssignDropdown } from '../../pages/companies/companies.model';
import { CompaniesService } from '../../pages/companies/companies.service';
import { ToastrService } from 'ngx-toastr';
import { CrmService } from '../../../crm.service';
import { VendorListService } from 'src/app/components/procurement/vendor-list/pages/vendor-list/vendor-list.service';
import { Subscription } from 'rxjs';
import { Button } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-assign',
  templateUrl: './assign.component.html',
  styleUrls: ['./assign.component.scss']
})
export class AssignComponent implements OnInit ,OnDestroy{
  enumButtonName = Button;
  dropdownHidden: boolean = false;
  vendorValueSubscription: Subscription;

  @Input() companyIds:[];

  altSalesPersonList      : AssignDropdown[] = [];
  salesPersonList         : AssignDropdown[] = [];
  buyeresRepList          : AssignDropdown[] = [];
  assignModel             = new Assign();

  constructor(public  activeModal:NgbActiveModal,
    public companiesService:CompaniesService,
    private toaster : ToastrService,
    public crmService:CrmService,
    public vendorListService :VendorListService

    ) { }

  ngOnInit(): void {
    let dropdownLit = this.crmService
    .getAllFilterDropDownList()
    .subscribe((res) => {
      this.altSalesPersonList = res.response.altSalesPersonList;
      this.salesPersonList    = res.response.salesPersonList;
      this.buyeresRepList     = res.response.buyerRepList;
    });
    this.vendorValueSubscription =  this.vendorListService.vendorValue.subscribe((res)=>{
      if(res == true){
        this.dropdownHidden = true
      }
    });
  }
  ngOnDestroy() {
    if (this.vendorValueSubscription) {
      this.vendorValueSubscription.unsubscribe();
    }
  }
  assignCompanyToRole(){
    if (this.assignModel) {
      this.assignModel.companyIds = this.companyIds;
      this.companiesService.assign(this.assignModel).subscribe((res) => {
        if (res.isSuccess == true) {
          this.toaster.success(res.message);
          this.activeModal.close(res.isSuccess);
          this.companyIds=[]
        } else {
          this.toaster.error(res.message)
        }
      });
    } 
  }

}

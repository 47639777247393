export enum Enum {}
export enum UserList {
  USER_LIST = "user list",
}



export enum PopUpTitles {
  MARK_AS_ACTIVE = "Mark as active",
  MARK_AS_INACTIVE = "Mark as inactive",
  RESET_PASSWORD = "Reset Password",
  RESET = "Reset",
  ADDRESS = "Address",
  CONTACT = 'Contact',
  ACTIVITY = 'Activity',
  PASSWORD_CHANGED = "Password Changed",
  DELETE = "Delete",
  SAVECHANGES = "Save changes",
  GRANTPERMISSION = "Grant all permissions",
  TAKEPERMISSION = "Take all permissions",
  UNSAVECHANGES = "Unsaved changes",
  FAVOURITE_DOCKS = "Remove Favorite",
  REPLACE_PRIMARY_CONTACT = "Replace current primary contact",
  POPUP_VALUE = "Error Message!",
  DELETE_TEMPLATEID = "Delete  ",
  EDIT_QUICKNOTES = "Edit Notes",
  ARCHIVED_COMPANIES = "Archive Company",
  UNABLE_ARCHIVED_COMPANIES  = "Unable to Archive",
  UNARCHIVED_COMPANIES = "Unarchive Company",
  TRANSFER = "Transfer",
  UPLOAD_DOCUMENT = 'Upload Document(s)' ,
  ADD =  "Add",
  EDIT = "Edit",
  RESTORE = "Restore",
  TERMS = 'Terms',
  COMMENT = 'Comment',
 

  // admin look-up-tables Multi - Level List PopupTitles 
  ADD_WAREHOUSE = 'Add Warehouse',
  EDIT_WAREHOUSE = "Edit Warehouse",
  ADD_SHIPPING_PROVIDER = "Add Shipping Provider",
  EDIT_SHIPPING_PROVIDERR = "Edit Shipping Provider",
  ADD_BILLING_TERMS = "Add Billing Term",
  EDIT_BILLING_TERMS = "Edit Billing Term",
  ADD_NCM_CODE = "Add NCM Code",
  EDIT_NCM_CODE = "Edit NCM Code",
  ADD_CURRENCY = "Add Currency",
  EDIT_CURRENCY = "Edit Currency",
  ADD_TAG = "Add Tag",
  EDIT_TAG = "Edit Tag",
  ADD_VALUE = "Add Value",
  EDIT_VALUE = "Edit Value",
  ADD_SALE_COMMISSION = "Add Sale Commission",
  EDIT_SALE_COMMISSION = "Edit Sale Commission",
  ADD_PURCHASE_COMMISSION = "Add Purchase Commission",
  EDIT_PURCHASE_COMMISSION = "Edit Purchase Commission",
  
}

export enum Button {
  CANCEL = "Cancel",
  DELETE = "Delete",
  CONFIRM = "Confirm",
  EDIT = 'Edit',
  OKAY = "Okay",
  NO = "No",
  YES = "Yes",
  SAVE = "Save",
  PREVIOUS ='Previous',
  NEXT = 'Next',
  UPDATE = 'Update',
  ADD_ADDRESS =  'Add Address',
  UPLOAD_DOCUMENT = 'Upload Document',
  UPLOAD = 'Upload',
  UPDATEANDADDNEW = 'Update and Add New',
  SAVEANDADDNEW = 'Save and Add New',
  APPLYCHANGES ='Apply Changes',
  IMPORT = 'Import',
  ASSIGN = 'Assign',
  ADDTASK ='Add Activity',
  SENDEMAIL ='Send Email',
  ARCHIVE = 'Archive',
  RESTORE ='Restore',
  APPROVEDELETION ='Approve Deletion',
  FILTERS = 'Filters',
  ADDCONTACT = 'Add Contact',
  APPLY = 'Apply',
  ASSIGNCOMMISSION = 'Assign Commission',
  ADDUSER = 'Add User',
  SAVECHANGES = 'Save Changes',
  ADDROLE =  'Add Role',
  UPDATEROLE = 'Update Role' ,
  COMPLETEIMPORT = 'Complete Import' ,
  ADDTOQUOTE =  'Add to Quote',
  SENDTOSOURCING =  'Send to Sourcing',
  ADDOFFER ='Add Offer',
  ADDMAILINGLIST ='Add to Mailing List',
  NEW ='New',
  CLONE ='Clone',
  TRANSFER = 'Transfer',
  EDIT_LINE = 'Edit Line',
  DISCARD = 'Discard',
  ADD_CONSIGNMENT  = 'Add Consignment',
  CREATE_PURCHASE_ORDER = 'Create Purchase Order',
  RETURN_TO_VENDOR = 'Return to Vendor ',
  WORK_ORDER_REQUEST = 'Work Order Request',
  BACK_TO_STOCK = 'Back to Stock',
  INCLUDE_ALTERNATIVE  = 'Include Alternatives (3)',
  SHOW_EXCLUDED = 'Show Excluded (3)',
  ADD = 'Add',
  ADD_REFERENCE = "Add Reference ",
  DATE_SORT = "Date Sort",
  SNOOZE    = "Snooze",
  MARK_COMPLETE = "Mark Complete",
  RESET_PASSWORD = 'Reset Password',
  KEEP_ACTIVITY ='Keep Activity',
  MANAGE_MFRS  =  "Manage Mfrs",
  ADD_MANUFACTURER =    "Add Manufacturer",
  UNARCHIVE = 'Unarchive',
  EDIT_COMPANY = "Edit Company",
  EDIT_VENDOR = 'Edit Vendor',
  ADD_REQUIRMENT = "Add Requirement",
  ADD_OFFER    = "Add Offer",
  ADD_QUOTE        = "Add Quote",
  ADD_SALES_ORDER =   "Add Sales Order",
  ADD_PURCHASE_ORDER = "Add Purchase Order",
  SEND_TO_QBO =   "Send to QBO",
  CLOSE_TAB = 'Close Tab',
  CLOSE = 'Close',
  NEW_IMPORT = 'New Import'


}

export enum FooterTitle {
  USER_MANAGEMENT = "User Management",
  USER_ROLE = "User Role",
  COMPANY = "Companies",
  LOOKUP = "Masters",
  LEADS = "Leads",
  PROSPECTS = 'Prospects',
  TASKS = "Tasks",
  ACTIVITY_MANAGER = "Activity Manager", 
  CUSTOMERS = 'Customers',
  CONTACTS = "Contacts",
  VENDORS = 'Vendor List',
  VENDOR_CONTACT = 'Vendor Contact',
  CONTACT_RECYCLEBIN = 'Contact Recycle Bin',
  COMPANY_RECYCLEBIN = 'Company Recycle Bin',
  CROSSWALK = 'Code Crosswalk',
  REQUIREMENTS = "Requirements",
  PARTLIST = "Parts List",
  PARTLISTINFO = "Parts List",



}

export enum Url {
  USER_MANAGEMENT_URL = "./assets/SVGIcons/user-management.svg",
  USER_ROLE_URL = "./assets/SVGIcons/user-role.svg",
  COMPANY_URL = "./assets/SVGIcons/Company.svg",
  LOOKUP_URL = "./assets/SVGIcons/lookup-tables.svg",
  PROSPECTS = "./assets/SVGIcons/crm/newBreafCase.svg",
  LEADS = "./assets/SVGIcons/crm/phoneicon.svg",
  CUSTOMERS = "./assets/SVGIcons/crm/customers.svg",
 CONTACTS = "./assets/SVGIcons/crm/contact.svg",
 TASKS = "./assets/SVGIcons/crm/task.svg",
 ACTIVITY_MANAGER = "./assets/SVGIcons/crm/task.svg",
 CROSSWALK = "./assets/SVGIcons/crm/contact.svg",
 REQUIREMENTS = "./assets/SVGIcons/sales/requirements-icon.svg",
 PARTLIST = "./assets/SVGIcons/Company.svg",


}

export enum Path {
  USER_MANAGEMENT_PATH = "admin/user-management",
  USER_ROLE_PATH = "admin/user-management/rolesAndPermissions",
  COMPANY_PATH = "crm/companies",
  LOOKUP_PATH = "admin/look-up-tables",
  PROSPECTS_PATH = "crm/prospects",
  LEADS_PATH = "crm/leads",
  CUSTOMERS_PATH = 'crm/customers',
  CONTACTS_PATH = "crm/contacts",
  TASKS_PATH = 'crm/taks',
  ACTIVITY_MANAGER_PATH='crm/activityManager',
  COMPANY_INFO_PATH = 'crm/companies/company-info',
  IMPORT_COMPANIES = 'crm/companies/import-companies',
  IMPORT_VENDORS = 'procurement/vendor-list/import-vendors',
  VENDOR_LIST = 'procurement/vendor-list',
  VENDOR_CONTACT = 'procurement/vendor-contact',
  COMPANY_RECYCLEBIN = 'admin/company-recycle-bin',
  CONTACT_RECYCLEBIN = 'admin/contact-recycle-bin',
  CROSSWALK = 'admin/code-crosswalk',
  IMPORT_CROSSWALK = 'admin/code-crosswalk/import',
  REQUIREMENTS = 'sales/requirements',
  WYSIWYG_EDITOR = 'admin/WYSIWYG-editor',
  PARTLIST = 'parts-and-inventory/parts-list',
  PARTLISTINFO = 'parts-and-inventory/parts-list/list-info'
}
export enum RoutePath {
  COMPANY_ROUTEPATH = "/crm/companies",
  LEAD_ROUTEPATH = '/crm/leads',
  PROSPECT_ROUTEPATH ='/crm/prospects',
  CUSTOMER_ROUTEPATH ='/crm/customers',
  IMPORTVENDOR_ROUTEPATH = '/procurement/vendor-list/import-vendors',
  VENDORLIST_ROUTEPATH = '/procurement/vendor-list',
  IMPORTCOMPANY_ROUTEPATH =  '/crm/companies/import-companies',
  CODECROSSWALK_ROUTEPATH = '/admin/code-crosswalk',
  REQUIREMENT_ROUTEPATH ='/sales/requirements',
  CONTACT_ROUTEPATH ='/crm/contacts',
  VENDORCONTACT_ROUTEPATH = '/procurement/vendor-contact',
  PARTLIST_ROUTEPATH = '/parts-and-inventory/parts-list',
  PARTLISTINFO_ROUTEPATH = '/parts-and-inventory/parts-list/list-info',
  PROCUREMENT_VENDOR_CONTACT_ROUTEPATH = '/procurement/vendor-contact',
  CONTACT_RECYLCLE_BIN_ROUTEPATH = '/admin/contact-recycle-bin',
  COMPANY_RECYCLE_BIN_ROUTEPATH ='/admin/company-recycle-bin',
  PROCUREMENT_VENDOR_LIST_ROUTEPATH = '/procurement/vendor-list',
  ARCHIVED_COMPANIES_ROUTEPATH = '/crm/archivedCompanies'
}
export enum CurrentTab {
  COMPANY_TAB = 'companies',
  LEAD_TAB = '/crm/leads',
  PROSPECT_TAB ='/crm/prospects',
  CUSTOMER_TAB ='/crm/customers',
  IMPORTVENDOR_TAB = '/procurement/vendor-list/import-vendors',
  VENDORLIST_TAB = 'vendor-list',
  CODECROSSWALK_TAB ='code-crosswalk',
  IMPORTCOMPANY_TAB =  '/crm/companies/import-companies',
  CONTACT_TAB = 'contacts',
  VENDORCONTACT_TAB ='vendor-contact',
  CONTACT_RECYCLE_BIN_TAB = 'contact-recycle-bin',
  COMPANY_RECYCLE_BIN_TAB = 'company-recycle-bin',
  REQUIREMENTS_TAB = 'requirements',
  ARCHIVED_COMPANIES_TAB = 'archivedCompanies',
  ADMIN_COMPANY_RECYCLEBIN_TAB = '/admin/company-recycle-bin',
  PROCUREMENT_VENDOR_CONTACT = '/procurement/vendor-contact',
  PROCUREMENT_VENDOR_LIST = '/procurement/vendor-list',
}


export enum EnfoRoutePath {
  CRM_COMPANIES_COMPANY_INFO = '/crm/companies/company-info/',
  PROCUREMENT_VENDOR_INFO   = '/procurement/vendor-info/',
}


export enum LabelsName{
  SALESPERSON               = "Salesperson",
  ALT_SALESPERSON           = "Alt Salesperson",
  BUYERS_REP                = "Buyer’s Rep",
  LAST_CONTACTED            = "Date Last Contacted",
  SCOPE                     = "Scope",
  COUNTRY                   = "Country",
  STATE                     = "State",
  TIME_ZONE                 = "Time Zone",
  INDUSTRY                  = "Industry",
  STAGE_STATUS              = "Stage Status",
  SIC_CODE                  = "SIC Code",
  NAICS_CODE                = "NAICS Code",
  LEAD_SCORE                = "Lead Score",
  PROSPECT_SCORE            = "Prospect Score",
  CUSTOMER_SCORE            = "Customer",
  CERTIFICATIONS            = "Certifications",
  MANUFACTURER              = "Manufacturer",
  SALE_COMMISSION_ROLE      = "Sales Commission Role",
  PURCHASE_COMMISSION_ROLE  = "Purchase Commission Role",
  SALE_STAGE                = "Sale Stage",

}
export enum LablesNames
{
  RATING                    = 'rating',
  ACTIVE                    = 'Active',
  INACTIVE                  = 'InActive',
  ARCHIVED                  = "archived",
  AVL                       = 'AVL',
  NAVL                      ='NAVL',
  EXCLUDEDFROM_VENDOR_LIST  ="excludedFromVendorlist",
  BUYER_REP                 ='BuyerRep',
  INDUSTRY                  = "Industry",
  MANUFACTURER              = "ManufacturerList",
  INTEND_TO_BUY             = "IntendToBuy",
  LEAD                      = 'Lead',
  NAICS_CODE                = "NAICSCode",
  SCOPE                     = "Scope",
  SALESPERSON               = "Salesperson",
  COUNTRY                   = "Country",
  ALT_SALESPERSON           = "Alt Salesperson",
  TIMEZONE_LIST             ="TimezoneList",
  SIC_CODE                  = "SICCode",
  STAGE_STATUS              ="StageStatus",
  STATE                     = 'State',
  TAGS                      = 'Tags',
//  CLASS                     = 'Class',
CLASS                       = 'Type',
VENDOR_TYPE               ='Vendor Type',
CONTACTEDDATE             ='Contacted Date',
// VENDOR_TYPE               ='VendorTypes',
CREATE = 'Create',
NEW = 'New'
  
}

export enum TabNames
{
  COMPANIES           = 'companies',
  LEADS               = 'leads',
  CUSTOMERS           = 'customers',
  PROSPECTS           = 'prospects',
  CONTACTS            = 'contacts',
  VENDOR_LIST         = 'vendor-list',
  VENDOR_CONTACT      = 'vendor-contact',
  COMPANY_RECYCLEBIN  ='company-recycle-bin',
  CONTACT_RECYCLEBIN  ='contact-recycle-bin',
  IMPORT_COMPANIES    ='import-companies',
  REQUIREMENTS        = 'requirements',
  ARCHIVEDCOMPANIES   = 'archivedCompanies',
  IMPORT_CODECROSSWALK='import',
  CODECROSSWALK       = 'code-crosswalk',
  CODECROSSWALKPOPUP  = 'Code Crosswalk',
  PARTLIST            = 'parts-list',
  INSTOCK = "In Stock",
  PENDING = "Pending"

}
export enum DisplayName
{
  COMPANY             = 'Company',
  VENDOR_NAME         = 'Vendor Name',
  FULL_NAME           = 'Full Name',
  VENDORCONTACT_NAME  = 'Contact Name',
  CERTIFICATION_NAME  = 'Certification Name',
  MESSAGE             = 'Message',
  FILE_NAME           = 'File Name',
  CONTACT_NAME        = 'Contact Name',
  VENDORNAME          = 'vendorName',
  CLASS               = 'Class',
  WEBSITE             = 'Website',
  FAX                 = 'Fax',
  RATING              = 'Rating',
  STAGE               = 'Stage',
  ACCOUNT_CLASS       ='Account Class',
  TYPE                = 'Type',
  PHONE               = 'Phone',
  DATECONTACTED       = 'Date Contacted',
  REVENUE             = 'Revenue',
  QUICKNOTE           = 'Quick Note',
  QUICKNOTES           = 'Quick Notes',
  Active              = 'Active',
  REQUIREMENT         = 'Requirement Number',
  VENDORTYPE          = 'Vendor Type',
  AVL                 = 'AVL',
  LEADSCORE           = 'Lead Score',
  INTENTTOBUY         = 'Intent to Buy',
  // CONTACTNAME         = 'Contact Name',
  COMPANYRATING       = 'Company Rating',
  DEPARTMENT          = 'Department',
  LOCATION            = 'Location',
  TIMEZONE            = 'Time Zone',
  TITLE               = 'Title',
  GENDER               = 'Gender',
  COMPANYNAME         = 'Companny',
  OFFICENUMBER        = 'Main Office Number',
  DIRECTNUMBER        = 'Direct Number',
  MOBILENUMBER        = 'Mobile Number',
  EMAILADDRESS        = 'Email Address',
  DONOTCALL           = 'Do Not Call',
  DONOTEMAIL          = 'Do Not Email',
  LASTCONTACT         = 'Last Contact',
  NEXTTASKDUEDATE     = 'Next Task Due Date',
  SALEPERSON          = 'Salesperson',
  ALTERNATESALEPERSON = 'Alternate Salesperson',
  REQDATE             = 'Req Date',
  BUYTARGET           = 'Buy Target',
  TARGETPRICE         = 'Target Price',
  MPN                 =  'MPN',
  REFERENCE_DOCUMENT  = 'Reference Documents'

}
export enum PDFDisplayName
{
  COMPANY             = 'Company',
  RATING              = 'Rating',
  STAGE               = 'Stage',
  TYPE                = 'Type',
  PHONE               = 'Phone',
  SALEPERSON          = 'Salesperson',
  Active              = 'Active',
  DATECONTACTED       = 'Date Contacted',
  REVENUE             = 'Revenue',
  ALTERNATESALEPERSON = 'Alternate Salesperson',
  QUICKNOTE           = 'Quick Note',
  LEADSCORE           = 'Lead Score',
  INTENTTOBUY         = 'Intent to Buy',


  // VENDOR_NAME         = 'Vendor Name',
  // FULL_NAME           = 'Full Name',
  // VENDORCONTACT_NAME  = 'Contact Name',
  // CERTIFICATION_NAME  = 'Certification Name',
  // MESSAGE             = 'Message',
  // FILE_NAME           = 'File Name',
  // CONTACT_NAME        = 'Contact Name',
  // VENDORNAME          = 'vendorName',
  // CLASS               = 'Class',
  // WEBSITE             = 'Website',
  // FAX                 = 'Fax',
  // ACCOUNT_CLASS       ='Account Class',
  // REQUIREMENT         = 'Requirement Number',
  // VENDORTYPE          = 'Vendor Type',
  // AVL                 = 'AVL',
  // // CONTACTNAME         = 'Contact Name',
  // COMPANYRATING       = 'Company Rating',
  // DEPARTMENT          = 'Department',
  // LOCATION            = 'Location',
  // TIMEZONE            = 'Time Zone',
  // TITLE               = 'Title',
  // GENDER               = 'Gender',
  // COMPANYNAME         = 'Companny',
  // OFFICENUMBER        = 'Main Office Number + Extension',
  // DIRECTNUMBER        = 'Direct Number',
  // MOBILENUMBER        = 'Mobile Number',
  // EMAILADDRESS        = 'Email Address',
  // DONOTCALL           = 'Do Not Call',
  // DONOTEMAIL          = 'Do Not Email',
  // LASTCONTACT         = 'Last Contact',
  // NEXTTASKDUEDATE     = 'Next Task Due Date',



}
export enum PDFContactDisplayName
{
  COMPANYNAME         = 'Companny',
  COMPANY             = 'Company',
  RATING              = 'Rating',
  CONTACT_NAME        = 'Contact Name',
  DEPARTMENT          = 'Department',
  LOCATION            = 'Location',
  TIMEZONE            = 'Time Zone',
  TITLE               = 'Title',
  OFFICENUMBER        = 'Main Office Number + Extension',
  DIRECTNUMBER        = 'Direct Number',
  MOBILENUMBER        = 'Mobile Number',
  EMAILADDRESS        = 'Email Address',
  DONOTCALL           = 'Do Not Call',
  DONOTEMAIL          = 'Do Not Email',
  LASTCONTACT         = 'Last Contact',
  NEXTTASKDUEDATE     = 'Next Task Due Date',
  VENDOR_NAME         = 'Vendor Name',


}
export enum PDFVendorDisplayName{
  VENDOR_NAME         = 'Vendor Name',
  RATING              = 'Rating',
  PHONE               = 'Phone',
  Active              = 'Active',
  QUICKNOTE           = 'Quick Note',
  VENDORTYPE          = 'Vendor Type',
  AVL                 = 'AVL'
}
export enum SiteWideColorName
{
  PRIMARY_BG_COLOR            ='primary_bg_color',
  PRIMARY_TEXT_COLOR          ="primary-text-color",
  PRIMARY_HOVER               ="primary-hover",
  SECONDARY_BG_COLOR          ='secondary_bg_color',
  SECONDARY_TEXT_COLOR        = 'secondary_text_color',
  SECONDARY_HOVER_COLOR       = 'secondary_hover_color',
  ACCENT_LINE_COLOR           = 'accent_line_color',
  DOCK_COLOR                  = 'dock_color',
  ICON_COLOR                  = 'icon_color',
  SITEWIDE_ACCENT_COLOR       = 'sitewide_accent_color',
  SITEWIDE_SECONDARY_COLOR    = 'sitewide_secondary_color',
  SITEWIDE_ACTIVE_HOVER_COLOR = 'sitewide_active_hover_color'

}

export enum OperationId{
  Status = '1',
  Rating = '2',
  LeadScore = '3',
  IntentToBuy = '4'
}

export enum CreditTerms{
    VendorTerm = 'Vendor',
    CustomerTerm = 'Customer'
}

export enum FieldTypeAndPlaceHolder{
  INPUT     = "Input",
  DROPDOWN  = "Dropdown",
  RATING    = "Rating",
  SWITCH    = "Switch",
  ENTER_COMPANY_NAME='Enter Company Name',
  ENTER_PHONE_NUMBER='Enter Phone Number'
}

export enum  DuplicateCompaniesType
{
  SYSTEMRECORD = "System Record",
  NEWRECORD = "New Record",
}

export enum  PermissonAccess {
  CAN_VIEW_BILLS= 'Accounting.Can.View.Bills',
  CAN_EDIT_BILLS= 'Accounting.Can.Edit.Bills',
  CALL_ADD_BILLS= 'Accounting.Call.Add.Bills',
  CAN_DELETE_BILLS= 'Accounting.Can.Delete.Bills',
  CAN_VIEW_CHECK= 'Accounting.Can.View.Check',
  CAN_EDIT_CHECK= 'Accounting.Can.Edit.Check',
  CAN_ADD_CHECK= 'Accounting.Can.Add.Check',
  CAN_DELETE_CHECK= 'Accounting.Can.Delete.Check',
  CAN_VIEW_CREDIT_MEMO= 'Accounting.Can.View.Credit.Memo',
  CAN_EDIT_CREDIT_MEMO= 'Accounting.Can.Edit.Credit.Memo',
  CAN_ADD_CREDIT_MEMO= 'Accounting.Can.Add.Credit.Memo',
  CAN_DELETE_CREDIT_MEMO= 'Accounting.Can.Delete.Credit.Memo',
  CAN_AUTHORIZE_CREDIT_MEMO= 'Accounting.Can.Authorize.Credit.Memo',
  CAN_POST_PAYMENT= 'Accounting.Can.Post.Payments',
  CAN_MANAGE_SETTINGS= 'Admin.Can.Manage.Settings',
  CAN_ADD_LOOKUP= 'Admin.Can.Add.Lookup',
  CAN_EDIT_LOOKUP= 'Admin.Can.Edit.Lookup',
  CAN_DELETE_LOOKUP= 'Admin.Can.Delete.Lookup',
  CAN_MANAGE_ROLES= 'Admin.Can.Manage.Roles',
  CAN_MANAGE_USERS= 'Admin.Can.Manage.Users',
  CAN_VIEW_COMPANY= 'Companies.Can.View.Company',
  CAN_VIEW_ALL_COMPANIES= 'Companies.Can.View.All.Companies',
  CAN_ADD_COMPANY= 'Companies.Can.Add.Company',
  CAN_EDIT_COMPANY= 'Companies.Can.Edit.Company',
  CAN_DELETE_COMPANY= 'Companies.Can.Delete.Company',
  CAN_AUTHORIZE_NEW_COMPANY= 'Companies.Can.Authorize.New.Company',
  CAN_PLACE_ON_CREDIT_HOLD= 'Companies.Can.Place.on.Credit.Hold',
  CAN_ADD_COMPANY_NOTE= 'Companies.Can.Add.Company.Note',
  CAN_EDIT_COMPANY_NOTE= 'Companies.Can.Edit.Company.Note',
  CAN_DELETE_COMPANY_NOTE= 'Companies.Can.Delete.Company.Note',
  CAN_PRINT_COMPANY_NOTE= 'Companies.Can.Print.Company.Note',
  CAN_VIEW_PUBLIC_ACCOUNTS= 'Companies.Can.View.Public.Accounts',
  CAN_VIEW_HOUSE_ACCOUNTS= 'Companies.Can.View.House.Accounts',
  CAN_CHANGE_HOUSE_ACCOUNT= 'Companies.Can.Change.House.Account',
  CAN_CHANGE_SALES_REP= 'Companies.Can.Change.Sales.Rep',
  CAN_VIEW_EMPLOYEES= 'Employee.Management.Can.View.Employees',
  CAN_ADD_EMPLOYEES= 'Employee.Management.Can.Add.Employees',
  CAN_EDIT_EMPLOYEES= 'Employee.Management.Can.Edit.Employees',
  CAN_DELETE_EMPLOYEES= 'Employee.Management.Can.Delete.Employees',
  CAN_VIEW_INVENTORY= 'Inventory.Management.Can.View.Inventory',
  CAN_ADD_INVENTORY= 'Inventory.Management.Can.Add.Inventory',
  CAN_EDIT_INVENTORY= 'Inventory.Management.Can.Edit.Inventory',
  CAN_DELETE_INVENTORY= 'Inventory.Management.Can.Delete.Inventory',
  CAN_UPLOAD_DATA= 'Inventory.Management.Can.Upload.Data',
  CAN_EXPORT_DATA_= 'Inventory.Management.Can.Export.Data.',
  CAN_UPLOAD_IMAGES= 'Inventory.Management.Can.Upload.Images',
  CAN_DELETE_IMAGES= 'Inventory.Management.Can.Delete.Images',
  INVENTORY_CAN_LIMIT_GL_ACCOUNTS_VIEW= 'Inventory.Management.Can.Limit.GL.Accounts.View',
  CAN_ACCESS_INVOICES= 'Invoice.Management..Can.Access.Invoices',
  CAN_ADD_INVOICES= 'Invoice.Management..Can.Add.Invoices',
  CAN_EDIT_INVOICES= 'Invoice.Management..Can.Edit.Invoices',
  CAN_VIEW_ALL_INVOICES= 'Invoice.Management..Can.View.All.Invoices',
  CAN_AUTHORIZE_INVOICES= 'Invoice.Management..Can.Authorize.Invoices',
  CAN_DELETE_INVOICES= 'Invoice.Management..Can.Delete.Invoices',
  CAN_PRINT_PICKTICKET= 'Invoice.Management..Can.Print.PickTicket',
  INVOICE_CAN_LIMIT_GL_ACCOUNTS_VIEW= 'Invoice.Management..Can.Limit.GL.Accounts.View',
  INVOICE_CAN_CREATE_CHARGE_DESCRIPTION= 'Invoice.Management..Can.Create.Charge.Description',
  ADMIN= 'Main.Page.Layout.Admin',
  ACCOUTING= 'Main.Page.Layout.Accouting',
  SALES= 'Main.Page.Layout.Sales',
  PURCHASING= 'Main.Page.Layout.Purchasing',
  CAN_ACCESS_OFFERS= 'Offers.Management.Can.Access.Offers',
  CAN_ADD_OFFERS= 'Offers.Management.Can.Add.Offers',
  CAN_EDIT_OFFERS= 'Offers.Management.Can.Edit.Offers',
  CAN_DELETE_OFFERS= 'Offers.Management.Can.Delete.Offers',
  CAN_VIEW_ALL_OFFERS= 'Offers.Management.Can.View.All.Offers',
  CAN_VIEW_PARTS= 'Parts.Management.Can.View.Parts',
  CAN_ADD_PARTS= 'Parts.Management.Can.Add.Parts',
  CAN_DELETE_PARTS= 'Parts.Management.Can.Delete.Parts',
  CAN_EDIT_PARTS= 'Parts.Management.Can.Edit.Parts',
  CAN_ACCESS_PROFORMA= 'Proforma.Management.Can.Access.Proforma',
  CAN_VIEW_ALL_PROFORMA= 'Proforma.Management.Can.View.All.Proforma',
  CAN_ADD_PROFORMA= 'Proforma.Management.Can.Add.Proforma',
  CAN_DELETE_PROFORMA= 'Proforma.Management.Can.Delete.Proforma',
  CAN_EDIT_PROFORMA= 'Proforma.Management.Can.Edit.Proforma',
  CAN_ACCESS_VIEW_POS= 'Purchasing.Management.Can.Access-View.POs',
  CAN_EDIT_POS= 'Purchasing.Management.Can.Edit.POs',
  CAN_ADD_POS= 'Purchasing.Management.Can.Add.POs',
  CAN_AUTHORIZE_POS= 'Purchasing.Management.Can.Authorize.POs',
  CAN_RECIEVE_POS= 'Purchasing.Management.Can.Recieve.POs',
  CAN_VIEW_ALL_POS= 'Purchasing.Management.Can.View.All.POs',
  CAN_DELETE_POS= 'Purchasing.Management.Can.Delete.POs',
  CAN_AUTHORIZE_PO_CHANGES= 'Purchasing.Management.Can.Authorize.PO.changes',
  PURCHASING_CAN_CREATE_CHARGE_DESCRIPTION= 'Purchasing.Management.Can.Create.Charge.Description',
  PURCHASING_CAN_LIMIT_GL_ACCOUNTS_VIEW= 'Purchasing.Management.Can.Limit.GL.Accounts.View',
  CAN_ADD_QUOTES= 'Quote.Management.Can.Add.Quotes',
  CAN_EDIT_QUOTES= 'Quote.Management.Can.Edit.Quotes',
  CAN_ACCESS_QUOTES= 'Quote.Management.Can.Access.Quotes',
  CAN_DELETE_QUOTES= 'Quote.Management.Can.Delete.Quotes',
  CAN_VIEW_ALL_QUOTE= 'Quote.Management.Can.View.All.Quote',
  CAN_VIEW_ALL_OFFER_LISTS= 'Quote.Management.Can.View.All.Offer.Lists',
  CAN_ACCESS_REPORTS= 'Reports.Can.Access.Reports',
  ACCOUNTING_REPORTS= 'Reports.Accounting.Reports',
  COMPANY_FINANCIAL= 'Reports.Company_Financial',
  CUSTOMERS_RECIEVABLE= 'Reports.Customers_Recievable',
  INVENTORY= 'Reports.Inventory',
  PURCHASE_ORDER_REPORTS= 'Reports.Purchase.Order.Reports',
  VENDORS_PAYABLES= 'Reports.Vendors_Payables',
  SALES_ORDER_REPORTS= 'Reports.Sales.Order.Reports',
  LISTINGS= 'Reports.Listings',
  CAN_ACCESS_ACTIVE_REQS= 'Requirements.Management.Can.Access.Active.Reqs',
  CAN_ADD_REQUIREMENTS= 'Requirements.Management.Can.Add.Requirements',
  CAN_EDIT_REQUIREMENTS= 'Requirements.Management.Can.Edit.Requirements',
  CAN_DELETE_REQUIREMENTS= 'Requirements.Management.Can.Delete.Requirements',
  CAN_VIEW_ALL_REQUIREMENTS= 'Requirements.Management.Can.View.All.Requirements',
  CAN_ACCESS_SOURCING_CENTER= 'Sourcing.Center.Management.Can.Access.Sourcing.Center',
  CAN_VIEW_SOURCING_CENTER= 'Sourcing.Center.Management.Can.View.Sourcing.Center',
  CAN_ADD_SOURCING_CENTER= 'Sourcing.Center.Management.Can.Add.Sourcing.Center',
  CAN_EDIT_SOURCING_CENTER= 'Sourcing.Center.Management.Can.Edit.Sourcing.Center',
  CAN_DELETE_SOURCING_CENTER= 'Sourcing.Center.Management.Can.Delete.Sourcing.Center',
  CAN_EDIT_RMAS= 'RMA.Management.Can.Edit.RMAs',
  CAN_ACCESS_RMAS= 'RMA.Management.Can.Access.RMAs',
  CAN_ADD_RMAS= 'RMA.Management.Can.Add.RMAs',
  CAN_VIEW_ALL_RMAS= 'RMA.Management.Can.View.All.RMAs',
  CAN_AUTHORIZE_RMAS= 'RMA.Management.Can.Authorize.RMAs',
  CAN_DELETE_RMAS= 'RMA.Management.Can.Delete.RMAs',
  CAN_RECIEVE_RMAS= 'RMA.Management.Can.Recieve.RMAs',
  CAN_UPLOAD_RMA_RECEIVE= 'RMA.Management.Can.Upload.RMA.Receive',
  CAN_ACCESS_SALES_ORDERS= 'Sales.Order.Management.Can.Access.Sales.Orders',
  CAN_VIEW_ALL_SALES_ORDERS= 'Sales.Order.Management.Can.View.All.Sales.Orders',
  CAN_ADD_SALES_ORDER= 'Sales.Order.Management.Can.Add.Sales.Order',
  CAN_EDIT_SALES_ORDER= 'Sales.Order.Management.Can.Edit.Sales.Order',
  CAN_AUTHORIZE_SALES_ORDERS= 'Sales.Order.Management.Can.Authorize.Sales.Orders',
  CAN_DELETE_SALES_ORDERS= 'Sales.Order.Management.Can.Delete.Sales.Orders',
  SALES_CAN_CREATE_CHARGE_DESCRIPTION= 'Sales.Order.Management.Can.Create.Charge.Description',
  CAN_LIMIT_GL_ACCOUNTS_VIEW= 'Sales.Order.Management.Can.Limit.GL.Accounts.View',
  CAN_ACCESS_WORK_ORDER= 'Work.Order.Management.Can.Access.Work.Order',
  CAN_ADD_WORK_ORDER= 'Work.Order.Management.Can.Add.Work.Order',
  CAN_AUTHORIZE_WORK_ORDERS= 'Work.Order.Management.Can.Authorize.Work.Orders',
  CAN_DELETE_WORK_ORDERS= 'Work.Order.Management.Can.Delete.Work.Orders',
  CAN_EDIT_WORK_ORDER= 'Work.Order.Management.Can.Edit.Work.Order',
  CAN_VIEW_ALL_WORK_ORDERS= 'Work.Order.Management.Can.View.All.Work.Orders',
  AUTHORIZED_NEW_COMPANY= 'Show.Messages.Authorized.New.Company',
  PART_POSTED_TO_FOUND_PART= 'Show.Messages.Part.Posted.to.Found.Part',
  AUTHORIZED_PO= 'Show.Messages.Authorized.PO',
  AUTHORIZED_SO= 'Show.Messages.Authorized.SO',
  ORDER_STATUS_NOTES_CHANGED= 'Show.Messages.Order.Status.Notes.Changed',
  RECIEVED_PO= 'Show.Messages.Recieved.PO',
  AUTHORIZED_INVOICE= 'Show.Messages.Authorized.Invoice',
  SHIPPING_CONFIRMATION_= 'Show.Messages.Shipping.Confirmation.',
  CALL_TRACKER= 'Show.Messages.Call.Tracker',
  WAREHOUSE_MESSAGE= 'Show.Messages.Warehouse.Message',
  CAN_ACCESS_SETTINGS= 'System.Access.Can.Access.Settings',
  CAN_ACCESS_VENDOR_RETURNS= 'Vender.Returns.Management.Can.Access.Vendor.Returns',
  CAN_VIEW_ALL_VENDOR_RETURNS= 'Vender.Returns.Management.Can.View.All.Vendor.Returns',
  CAN_ADD_VENDOR_RETURNS= 'Vender.Returns.Management.Can.Add.Vendor.Returns',
  CAN_CREATE_VENDOR_RETURNS= 'Vender.Returns.Management.Can.Create.Vendor.Returns',
  CAN_DELETE_VENDOR_RETURNS= 'Vender.Returns.Management.Can.Delete.Vendor.Returns',
  CAN_AUTHORIZE_VENDOR_RETURNS= 'Vender.Returns.Management.Can.Authorize.Vendor.Returns',
  CAN_EDIT_VENDOR_RETURNS= 'Vender.Returns.Management.Can.Edit.Vendor.Returns',
  CAN_ACCESS_VIEW_SHIPPING_CENTER= 'Shipping.Receiving.Management.Can.Access-View.Shipping.Center',
  CAN_MANAGE_EMAIL_TEMPLATE= 'Accounting.Can.Manage.Email.Template',
  CAN_MANAGE_TASKS= 'Task.Management.Can.Manage.Tasks',
};


export enum DocumentTypes
{
    companyDocuments = 1,
    tradeReferenceDocument = 2,
    resellerDocument = 3,
    certificationDocument = 4
}
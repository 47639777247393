export class GetCompanySettingData
{
    id               : string ="";
    userId           : string;
    companyName      : string;
    addressLine1     : string;
    addressLine2     : string;
    state            : string;
    email            : string;
    postalCode       : any;
    phoneNumber      : string;
    country          : string;
    city             : string;
}

export enum CompnayHeading {
COMPANY_SETTINGS  = 'Company Settings'
}
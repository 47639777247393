export class PartsAndInventory {
}
export class PartsListData{
    manufacturererId:string;
    showConsignment:boolean = false;

}
export class GetPartInfoDetails{
    partId             : string = '1';
    partName           : string = 'V23135W1001A309';
    mfrName            : string = 'ACE Industries';
    internalPartNumber : string = '116';
    inStock            : string = '7,500';
    qtyAvailable       : string = '3,500';
    category           : string = 'Semiconductors';
    mpn                : string = 'V23135W1001A309WT8V0';
    mfr                : string = 'Ace Industries';
    description        : string = 'Lorem Ipsum Lorem Ipsum Lorem Lorem Ipsum ';
    moq                : string = '2500';
    spq                : string = '2500';
    devicePkg          : string = 'Reel56789012345';
    itemPkg            : string = 'Reel56789012345';
    partStatus         : string = 'Active';
    mountType          : string = 'SMD456789012345';
    tempGrade          : string = 'Commercial 0-70';
    note               : string = 'Lorem Ipsum Lorem Ipsu ';
    dimensions         : string = '    33 x 31 x 41';
    storageTemp        :string = 'Commercial 0-70';
    backlighting       :string = '300 nits';
    brightness         :string = '';
    supplyVoltage      :string = '9V';
    rohs:string = 'RoHS3';
    reach:string = 'Compliant';
    nsn:string = '8415-01-530-2157';
    hts:string = '8532.00.0000';
    schedB:string = '8532.00.0001';
    eccn:string = 'EAR99';
    msl:string = '123456';
    cost :string = '$3.50';
    discountName :string = 'Sales Team Code';
    resale :string = '500';

    saleStageId       : string;
    typeId            : string;
    phoneNumber       : string;
    faxNumber         : string;
    emailAddress      : string;
    website           : string;
    salesPersonId     : string;
    altSalesPersonId  : string;
    buyerRepId        : string;
    contactReminder   : boolean;
    currencyId        : string;
    entityType        : string;
    timeZoneId        : string;
    billToAddress     : string;
    shipToAddress     : string;
    notes             : string;
    scopeId           : string;
    dAndBNumber       : number;
    sicCode           : string;
    naicsCode         : string;
    industryId        : string;
    tagsId            : tagsList[]=[];
    employee          : string;
    revenue           : string;
    parentCompany     : string;
    location          : string;
    isExpandable      : boolean;
    salesPersonName   : string
    altSalesPersonName: string
    overallRating     : number;
    approvedVendorList: any;
    vendorSpecialty   : string;
    accountNumber     : number;
    intentToBuyName   : string;
    leadScoreName     : string;
  }
  
  export class tagsList{
    id        : string='';
    name      : string='';
    labelName : string='';
    colorCode : string='';
    isActive  : boolean=false;
  }
  
import { map } from 'rxjs/operators';
import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { AllActivites, InsertSnooz, MarkComplete } from '../../activit-manager.model';
import * as moment from 'moment';
import { ActivityManagerService } from '../../activity-manager.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CompaniesService } from '../../../companies/pages/companies/companies.service';
import { Menu } from 'src/app/shared/services/nav.service';
import { NgbModal, NgbModalOptions, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { StingToDateService } from 'src/app/shared/services/stringToDate.service';
import { MarkCompleteComponent } from '../../pop-ups/mark-complete/mark-complete.component';
import { AddActivityComponent } from '../../pop-ups/add-activity/add-activity.component';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  @Output() orderId: EventEmitter<any> = new EventEmitter();
  @Input() istrue: boolean = true;
  @Input() iconHide: boolean = true;
  @Input() hideData = true;
  @Input() data: any;
  @Input() getclass = NgClass
  @Input() chageBgcolor: boolean = false;
  @Input() isCheckbox = false
  @Input() taskList: any
  selectedId: number;
  insertSnooz = new InsertSnooz();
  currentTab = ''
  selectedDate: string = ''; 
  selectedTime = '';
  formattedDate = ''
  timeIcon: boolean = false;
  hideIcon: boolean = false;
  datePickerShow = false
  selectedTimeValue;
  ngbTimePickerValue: NgbTimeStruct;
  isPopoverOpen = false;
  activityIdsList = new MarkComplete ()
  constructor(public tabService: TabsService, public activityService: ActivityManagerService, private toaster: ToastrService,
    private tabsService: TabsService, private router: Router, public companiesService: CompaniesService,
    public stringToDateService: StingToDateService,
    public modalService: NgbModal,
    public commonService      : CommonService,
  ) { }
  toogleIcon: boolean = false;
  ngOnInit(): void {
    this.selectedDate = '';

    this.data
    this.tabService.currentTab.subscribe(res => {
      this.currentTab = res;
    })
    if (!this.currentTab) {
      this.currentTab = this.tabService.thisTab
    }
  }

  passId(id) {
    this.orderId.emit(id)
  }

  clear(f: NgForm) {
    f.value.dateExpiration = '';
    this.selectedDate = '';
  }
  clearTime(f: NgForm) {
    f.value.timeExpiration = '';
    this.selectedTime = '';
    this.ngbTimePickerValue = null;
    this.isPopoverOpen = false;
    this.selectedTimeValue = null;

  }
  onClick(){
    this.isPopoverOpen = false;
  }

  datePicker(id) {
    this.taskList.map(value => {
      if (value.id == id) {
        this.data.isShowPopup = !value.isShowPopup
        value = !value.isShowPopup
      } else {
        value.isShowPopup = false
      }

    })

  }
  changeDateExpirations(selectedDate: string): void {
    this.changeDateExpiration(selectedDate)
  }
  changeDateExpiration(queryDate) {
    if(queryDate){
    this.selectedDate  = queryDate;
    this.hideIcon = false;
   // this.formattedDate = moment(queryDate).format('MM/DD/YYYY');
  // this.formattedDate = queryDate;
  this.formattedDate =this.stringToDateService.dateConvertedToString(queryDate);

    this.updateSnoozeTasks(this.formattedDate, this.selectedTime);
}else{
  this.selectedDate ='';
  this.formattedDate = ''
}
  }
  changeTimeExpiration(timeValue: any) {
    if(timeValue){
   // this.selectedTime = timeValue;
    this.hideIcon = false;
    const formattedTime = moment(timeValue).format('h:mmA');
    this.selectedTimeValue = formattedTime;
    const formattedTimeValue = moment().set({
      hour: timeValue.hour,
      minute: timeValue.minute,
      second: timeValue.second
    }).format('HH:mm');
    this.selectedTime = formattedTimeValue;

     this.updateSnoozeTasks(this.formattedDate, formattedTimeValue);
    }
  }






  addUpdateSnooz(interval: number) {
    const dueDateParts = this.data.dueDate.split('/'); // Split date parts
    const dueTimeParts = this.data.dueTime.split(':'); // Split time parts
    if (dueTimeParts[1].toLowerCase().includes('pm') || dueTimeParts[1].toLowerCase().includes('am')) {
      dueTimeParts[1] = dueTimeParts[1].replace(/(am|pm)/i, '');
    }
    
    this.data.dueTime = dueTimeParts.join(':');
    const currentDate = new Date(
      parseInt(dueDateParts[2]),    // Year
      parseInt(dueDateParts[0]) - 1, // Month (0-based)
      parseInt(dueDateParts[1]),    // Day
      parseInt(dueTimeParts[0]),    // Hours
      parseInt(dueTimeParts[1])     // Minutes
    );

    if (dueTimeParts[2] === 'PM' && currentDate.getHours() < 12) {
      currentDate.setHours(currentDate.getHours() + 12);
    }

    if (interval === 1) {
      currentDate.setHours(currentDate.getHours() + 24);
    } else if (interval === 2) {
      currentDate.setHours(currentDate.getHours() + 48);
    } else if (interval === 3) {
      currentDate.setDate(currentDate.getDate() + 7);
    }

    const formattedDate = moment(currentDate).format('MM/DD/YYYY');
    const formattedTime = dueTimeParts[0] + ':' + dueTimeParts[1].trim();
    let time = formattedTime;

    if (formattedTime.includes('PM') || formattedTime.includes('AM')) {
      time = formattedTime.replace('PM', '').replace('AM', '');
      time = time.trim();
    }

    // this.insertSnooz.snoozeTasks.push({
    //   taskId: this.data.id,
    //   date: formattedDate,
    //   time: time,
    // });

    this.updateSnoozeTasks(formattedDate, time);
  }
  updateSnoozeTasks(date: string, time: string) {
    if (date && time) {
      this.insertSnooz.snoozeTasks.push({
        taskId: this.data.id,
        date: date,
        time: time,
      });
      this.activityService.snoozeTasks(this.insertSnooz).subscribe(res => {
        if (res.isSuccess) {
          this.toaster.success(res.message);
          this.data.isShowPopup = false;
          this.activityService.getAll.next(true)
        } else {
          this.toaster.error(res.message);
          this.data.isShowPopup = false;
        }
      });
    }
  }
  openCompanyInfo(data){
      this.companiesService.id=data.companyId;
      let url = `crm/companies/company-info/${this.companiesService.id}`
      const item: Menu = { path: url, icon: '', title: data.companyName, type: 'link',id:data.companyId}
      this.tabsService.addTab(item);
      this.router.navigateByUrl(url);
      this.companiesService.activeTab.next(1);
  }
  openContactInfo(data){
    this.companiesService.id=data.companyId;
    let url = `crm/companies/company-info/${this.companiesService.id}`
    const item: Menu = { path: url, icon: '', title: data.companyName, type: 'link',id:data.companyId}
    this.tabsService.addTab(item);
    this.router.navigateByUrl(url);
    this.companiesService.activeTab.next(3);

  }
  markAsComplete(id:string){
   // this.activityService.markAsCompleted.next(id)
   const modalOption: NgbModalOptions = {
    centered: true,
    backdrop: "static",
    keyboard: false,
    size: "md",
  };
  const modalService = this.modalService.open(MarkCompleteComponent,modalOption);
  modalService.result.then(res => {
    if (res) {
      this.activityIdsList.ids = [id];
      this.activityService.markAsComplete(this.activityIdsList).subscribe(res => {
        if (res.isSuccess) {
          this.toaster.success(res.message);
          this.commonService.setSelectedDate(res);
        }
        if (!res.isSuccess) {
          this.toaster.error(res.message);
        }
      })
      if (res == 'ConfirmandClone') {
        this.openAddActivity(true,false);
      }
      if (res == 'ConfirmandCreateNew') {
        this.openAddActivity(false);
      }
    }
  })

  }
  openAddActivity(isTrue, isEdit?) {
    const copyOfObject = Object.assign({}, this.data);
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "lg",
    };
    const modalService = this.modalService.open(
      AddActivityComponent,
      modalOption
    );
    if (isTrue) {
      copyOfObject.id = '';
      if(copyOfObject.dueTime){
        var timeValue = copyOfObject.dueTime;
        const timeObject =  this.stringToDateService.convertTimeStringToObject(copyOfObject.dueTime);
        modalService.componentInstance. ngbTimePickerValue = timeObject
        if( (typeof timeValue === 'string' && (timeValue.includes('am') || timeValue.includes('pm') || timeValue.includes('AM') || timeValue.includes('PM')))){
          copyOfObject.dueTime =   this.stringToDateService.changeTimeExpiration( copyOfObject.dueTime);
        }
      }
    
      modalService.componentInstance.insertUpdateToDoActivity = copyOfObject
    }
    if (isEdit) {
      modalService.componentInstance.titleName = 'Edit'
    }
    modalService.result.then(res => {
      if (res) {
        this.commonService.setSelectedDate(res);
      }})
  }

togglePopover() {
  this.isPopoverOpen = !this.isPopoverOpen;
}

}

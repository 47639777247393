import { TabsService } from './../tabs/tabs.service';
import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { CommonService } from '../../services/common.service';
import { DockService } from './dock.service';
import { Router } from '@angular/router';
import { CrmService } from 'src/app/components/crm/crm.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends CommonService implements OnInit {

  public today: number = Date.now();

  constructor(private storageService: StorageService,
    private router: Router,
    public dockService: DockService,
    public crmService: CrmService,
    public tabsService: TabsService) {
    super()
  }

  ngOnInit(): void {
    this.dockService.pinnedlist = this.storageService.retrieve('pinnedValues');
    this.getPinnedValue()
  }


  routeToPage(item) {
    this.tabsService.addTab(item);
    this.router.navigateByUrl('procurement');
    this.router.navigateByUrl(item.path);
  }


  getPinnedValue() {
    this.crmService.getUserSetting()
      .subscribe(res => {
        if (res?.isSuccess) {
          // let json = JSON.parse(res?.response?.userSetting) ?? {}
          let json = JSON.parse(res?.response) ?? {}

          console.log('the pinned values#### ',json.pinnedValues)
          this.storageService.store('pinnedValues',json.pinnedValues)
          this.dockService.pinnedlist = json.pinnedValues
        }
      })
  }
}

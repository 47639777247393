<!-- <div class="container-fluid">
    <div class="row d-flex justify-content-end">
        <div class="col-xl-2">
          <div class="row position-relative activity-dorpdown">
            <div class="col-4">
              <p class="position-relative m-b-0 text-gray nowrap font-12">
                Activity Type
              </p>
            </div>
            <div class="col-8">
              <select
                class="form-select py-0"
                (ngModelChange)="selectValueChange($event)"
                [(ngModel)]="activityType">
                <option selected value=""></option>
                <option
                 *ngFor="let item of activityTypeData" 
                 [value]="item.id"
                 class="ps-3">
                  {{item.name}} 
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-xl-2">
          <div class="row position-relative priority-dropdown">
            <div class="col-4">
              <p class="m-b-0 text-gray position-relative font-12">Priority</p>
            </div>
            <div class="col-8">
              <select
                class="form-select py-0"
                (ngModelChange)="selectValueChange2($event)"
                [(ngModel)]="priority">
                <option selected value=""></option>
                <option
                  class="ps-3"
                  *ngFor="let priorityitem of priorityData" 
                  [value]="priorityitem.id">
                  {{priorityitem.name}} 
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-xl-2">
          <div class="row position-relative contact-dropdown">
            <div class="col-4">
              <p class="m-b-0 text-gray position-relative font-12">Contact</p>
            </div>
            <div class="col-8">
              <select
                class="form-select py-0"
                (ngModelChange)="selectValueChange3($event)"
                [(ngModel)]="contact">
                <option selected value=""></option>
                <option
                  class="ps-3"
                  *ngFor="let contactitem of contactData" 
                  [value]="contactitem.id">
                    {{contactitem.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="row position-relative assignee-dropdown">
            <div class="col-4">
              <p class="m-b-0 text-gray position-relative font-12">Assignee</p>
            </div>
            <div class="col-8">
              <select
                class="form-select py-0"
                (ngModelChange)="selectValueChange4($event)"
                [(ngModel)]="assignee">
                <option selected value=""></option>
                <option
                class="ps-3"
                *ngFor="let assigneeitem of assigneeData" 
                [value]="assigneeitem.id">
               {{assigneeitem.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-xl-2">
          <div class="row date-range-responsive">
            <div class="col-4">
              <p class="m-b-0 text-gray font-12">Date Range:</p>
            </div>
            <div class="col-8 app-daterange pe-0"
             [ngbTooltip]="activityLogPostModel.fromDate !== '' ? activityLogPostModel.fromDate + ' - ' + activityLogPostModel.endDate : null" container="body"
              >
            <app-flatpickr-calender [isActivity]="true"></app-flatpickr-calender>
             </div>
         </div>
        </div>
      </div>
</div> -->
<div class="container-fluid">
  <div class="selectBox d-flex">
    <div class="row">
      <div class="col">
        <div class="d-flex">
          <div class="">
            <p class="m-b-0 mt-2 text-gray font-12 text-nowrap">Activity Type</p>
          </div>
          <div class="col-7 ps-2"> 
            <select
              class="form-select text-truncate"
              (ngModelChange)="selectValueChange($event)"
              [(ngModel)]="activityType">
              <option selected value=""></option>
              <option *ngFor="let item of activityTypeData" [value]="item.id" class="ps-3">
                {{item.name}}
              </option>
             </select>
          </div>
        </div> 
      </div> 
      <div class="col">
        <div class="d-flex">
          <div class=""> <p class="m-b-0 mt-2 text-gray font-12 text-nowrap">Priority</p>  </div>
          <div class="col-7 ps-2"> 
            <select
             class="form-select py-0 text-truncate"
             (ngModelChange)="selectValueChange2($event)"
             [(ngModel)]="priority">
             <option selected value=""></option>
             <option
              class="ps-3"
              *ngFor="let priorityitem of priorityData" 
              [value]="priorityitem.id">
              {{priorityitem.name}} 
            </option>
           </select>
         </div>
        </div> 
      </div> 
      <div class="col">
        <div class="d-flex">
          <div class="">
            <p class="m-b-0 mt-2 text-gray font-12 text-nowrap">Contact</p>
          </div>
          <div class="col-7 ps-2"> 
            <select
              class="form-select py-0 text-truncate"
              (ngModelChange)="selectValueChange3($event)"
              [(ngModel)]="contact">
              <option selected value=""></option>
              <option
                class="ps-3"
                *ngFor="let contactitem of contactData" 
                [value]="contactitem.id">
                  {{contactitem.name}}
              </option>
            </select>
          </div>
        </div> 
      </div> 

      <div class="col">
        <div class="d-flex">
          <div class="">
            <p class="m-b-0 mt-2 text-gray font-12 text-nowrap">Assignee</p>
          </div>
          <div class="col-7 ps-2"> 
            <select
              class="form-select py-0 text-truncate"
              (ngModelChange)="selectValueChange4($event)"
              [(ngModel)]="assignee">
              <option selected value=""></option>
              <option
              class="ps-3"
              *ngFor="let assigneeitem of assigneeData" 
              [value]="assigneeitem.id">
             {{assigneeitem.name}}
              </option>
            </select>
          </div>
        </div> 
      </div> 
      <div class="col">
        <div class="d-flex">
          <div class="">
            <p class="m-b-0 mt-2 text-gray font-12 text-nowrap">Date Range:</p>
          </div>
          <div class="col-xl-7 col-md-9 mt-1 ps-2"
           [ngbTooltip]="activityLogPostModel.fromDate !== '' ? activityLogPostModel.fromDate + ' - ' + activityLogPostModel.endDate : null" container="body"
            >
          <app-flatpickr-calender [isActivity]="true"></app-flatpickr-calender>
           </div>
          </div>
        </div> 
      </div> 
    </div>
   </div>

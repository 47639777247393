import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteComment, InsertUpdateTaskCommentModel, MarkComplete,MentionList } from '../../activit-manager.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ActivityManagerService } from '../../activity-manager.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Button, PopUpTitles } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-add-edit-comment',
  templateUrl: './add-edit-comment.component.html',
  styleUrls: ['./add-edit-comment.component.scss']
})
export class AddEditCommentComponent implements OnInit{
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  items : MentionList[]=[];
  mentionList:any;
  isEditable = false;
  insertUpdateTaskCommentModel = new InsertUpdateTaskCommentModel();
  deleteComment = new DeleteComment()
  activityId : string='';
  commentId  : string='';
  comment = ''
  submited: boolean = false;
  highlightedText: string = '';
  @Input() isInputDisabled = false;
  @Input() inputReadonly : boolean  = false;
  @Input () inputValue : string = "";
  enumButtonName = Button;
  enumPopUpTitles        = PopUpTitles;
  
  @ViewChild('commentEditor') commentEditor: ElementRef;

  constructor(public activeModal: NgbActiveModal, 
              public toaster: ToastrService, 
              public storage: StorageService, 
              public activityService: ActivityManagerService,
              private sanitizer: DomSanitizer) { 
              }
  ngOnInit(): void {
    this.insertUpdateTaskCommentModel.userIds = this.storage.retrieve('userId');
    this.insertUpdateTaskCommentModel.taskId = this.activityId
    this.insertComment = this.getInnerHTML(this.comment)
    this.insertUpdateTaskCommentModel.id = this.commentId;
    this.deleteComment.ids.push(this.commentId);
    this.itemSelectedData( this.insertComment)
   // this.items = this.activityService?.getMentionList?.getValue();
     this.getAllMentionList();
  }

  itemSelectedData(event: any) {
    setTimeout(() => {
      this.htmlDoc = document.getElementById('my-content');
      this.htmlDoc.innerHTML = event;
      this.selectEnd();
    }, 10);
  
  }
  
  // getInnerHTML(val){
  //   return val.replace(/(<([^>]+)>)/ig,'');
  // }
  getInnerHTML(content: string): string {
    const tempElement = document.createElement('my-content');
    tempElement.innerHTML = content;
    return tempElement.innerHTML;
  }
  addComment() {
    this.getContent();
    this.insertUpdateTaskCommentModel.comment=this.comment
    this.activityService.insertComment(this.insertUpdateTaskCommentModel).subscribe(res => {
      if (res?.isSuccess == true) {
        this.toaster.success(res.message);
        this.activeModal.close('added')
      } else {
        this.toaster.error(res.message)
        this.submited = true;

      }
    })
  }


  editComment() {
    this.getContent();
    this.insertUpdateTaskCommentModel.comment=this.comment
    this.activityService.updateComment(this.insertUpdateTaskCommentModel).subscribe(res => {
      if (res?.isSuccess == true) {
        this.toaster.success(res.message);
        this.activeModal.close('added')
      } else {
        this.toaster.error(res.message)
        this.submited = true;

      }
    })
  }
  getAllMentionList(){
    this.activityService.getAllMentionData('').subscribe(res=>{
        this.items = res.response;
        
        const mentionlist:any = this.items.map(x=>`@${x.userName}`);
        this.mentionList = mentionlist;
    })
  }

  onDeleteComment(){
    this.activityService.deleteComment(this.deleteComment).subscribe(res => {
      if (res?.isSuccess == true) {
        this.toaster.success(res.message);
        this.activeModal.close('added')
      } else {
        this.toaster.error(res.message)
      }
    })
  }

  insertComment:any

  getFormattedComment() {
    const comment = this.insertComment;
    // Assuming 'items' contains mention data
    const mentionedNames = this.items?.map(item => item.userName);
    const formattedComment = comment.replace(
      new RegExp(`@(${mentionedNames?.join('|')})`, 'g'),
      `<span style="color: red;">$&</span>`
    );
    this.comment=formattedComment

  }




handleMentionStyling() {
  // Assuming you have a method to extract mentioned users from insertComment
  const mentionedUsers = this.extractMentionedUsers(this.insertComment);

  // Apply different styling for mentioned users
  mentionedUsers.forEach(user => {
    const mentionSpan = `<span class="mention">${user}</span>`;
    this.insertComment = this.insertComment.replace(`@${user}`, mentionSpan);
  });
}

extractMentionedUsers(comment: string): string[] {
  // Extract mentioned users from the comment
  // You need to implement this method based on your mentionConfig
  // For example, you might use a regular expression to find @mentions
  const mentionRegex = /@(\w+)/g;
  const matches = comment.match(mentionRegex);

  return matches ? matches.map(match => match.substr(1)) : [];
}

public htmlCode: string;
public htmlDoc: HTMLElement;



itemSelected(event: any) {
  setTimeout(() => {
    this.htmlDoc = document.getElementById('my-content');
    this.htmlDoc.innerHTML = this.htmlDoc.innerHTML.replace(
       '@'+event.userName,
      ' <span style=" color: var(--sitewide_primary_accent_color) ; padding: 0px">' + '@'+event.userName + '</span>&nbsp;'
    );
    // put the cursor to the end of field again...
    this.selectEnd();
  }, 10);

}

getContent(): string {
  const htmlDoc = document.getElementById('my-content');
  if (htmlDoc) {
    this.comment =  htmlDoc.innerHTML;
    return htmlDoc.innerHTML;
  }
  return '';
}
selectEnd() {
  let range, selection;
  range = document.createRange();
  range.selectNodeContents(this.htmlDoc);
  range.collapse(false);
  selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

clearInput(){
  if(!this.inputReadonly){
    // this.inputValue = ''
    this.insertComment = '';
    this.newComment = '';
  }
}
newComment:string;
value:boolean = false;
updateComment(event: any): void {
  this.value = true;
  this.newComment = event.target.innerHTML;
  const mentionedNames = this.items?.map(item => '@' + item.userName) || [];
  const regex = /(<span.*?>(.*?)<\/span>)/g;
  let match;
  while ((match = regex.exec(event.target.innerHTML)) !== null) {
    const spanElement = new DOMParser().parseFromString(match[1], 'text/html').body.firstChild;
    const mention = spanElement.textContent || '';
    if (!mentionedNames.includes(mention)) {
      this.htmlDoc = document.getElementById('my-content');

      const greySpan = `<span style="color: #8B9DAA;padding: 0px">${mention}</span>`;
    // this.htmlDoc.innerHTML = this.htmlDoc.innerHTML.replace(match[0], greySpan);
    // this.selectEnd();
    }
  }
}
newComments(){
  this.value = false;
  this.newComment = null;
  this.insertComment = null;
  this.htmlDoc.innerHTML = null;
}
}

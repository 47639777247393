import { PaginationModel } from "src/app/shared/model/pagination.model";

export class ListCalendarData {
  dates: string;
  isAvailable: boolean;
}

export class AllActivitysModel extends PaginationModel {
  id: string='';
  statusId: string='';
  assignee: string='';
  priorityId: string='';
  activityTypeId: string='';
  stageId: string='';
  countryId: string='';
  timeZoneId: string='';
  fromDate: string='';
  endDate: string='';
  pastDue: string='';
  taskTypeId: string='';
}

export class AllActivites {
  id: string='';
  title: string='';
  typeId: string='';
  typeName: string='';
  statusId: string='';
  statusName: string='';
  priorityId: string='';
  priorityName: string='';
  companyId: string='';
  contactId: string='';
  assignedToUserId: string='';
  dueDate: string='';
  dueTime: string='';
  dueDateInFormat: string='';
  createdAt: string='';
  description: string='';
  createdBy: string='';
  companyName: string='';
  contactName: string='';
  totalRecords: number=0;
  aging: string='';
  isChecked: boolean=false;
  pastDueRecords: number;

  //extra field add 
  isShowPopup: boolean=false;
  
}
export class InsertUpdateTaskCommentModel {
  id: string= '';
  comment: string = '';
  taskId: string='';
  userIds: string[]=[];
}


export class InsertUpdateToDoActivity {
  id: string='';
  typeId: string='';
  assignedToUserId: string='';
  title: string='';
  description: string='';
  companyId: string='';
  contactId: string='';
  dueDate: string='';
  dueTime: string='';
  priorityId: string='';
  statusId: string='';
  isMarkComplete: boolean=false;
}
export class InsertSnooz {
  snoozeTasks:SnoozeTasks[]=[];
}

export class SnoozeTasks {
  taskId: string='';
  date: string='';
  time: string='';
}

export class MarkComplete {
  ids:string[]  =[]
}
export class DeleteComment {
  ids:string[]  =[]
}
export class CancelActivity {
  ids:number[]  =[];
  reason: "string"
}

export class MentionList {
  userId: string;
  userName: string;
  email: string;
  profileUrl: string;
}
export class GetTaskCalendarListModel{
  startDate: string='';
  endDate: string='';
}

export class CalendarList{
  dates: string='';
  isAvailable: boolean=false;
  priorityCounts: number=0;
  lowPriorityCounts: number=0;
  highPriorityCounts: number=0;
  standardPriorityCounts: number=0;
  criticalPriorityCounts: number=0;
}

export class AddActivitiesMultipleCompanymodel
{
  id: string='';
  typeId: string='';
  assignedToUserId: string='';
  title: string='';
  description: string='';
  companyId: string[];
  contactId: string='';
  dueDate: string='';
  dueTime: string='';
  priorityId: string='';
  statusId: string='';
  isMarkComplete: boolean=false;
}

export class AddActivitiesMultipleContactmodel {
  id: string='';
  typeId: string='';
  assignedToUserId: string='';
  title: string='';
  description: string='';
  contactId: string[];
  companyId: string='';
  dueDate: string='';
  dueTime: string='';
  priorityId: string='';
  statusId: string='';
  isMarkComplete: boolean=false;
}
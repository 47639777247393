import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import * as model from '../user-management/user-management.model'
import { DataService } from '../../../../../shared/services/data.service';
import {RoleRequestModel, UserRequestModel} from './user-management.model';
import { Result } from '../../../../../shared/model/response.model';
import { stringify } from 'querystring';
import { URLS } from 'src/app/components/admin/user-management/user-api-constant';


@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(
    public dataService:DataService
  ) { }
  checkedValueUser= new Subject<boolean>();
  selectedDateRange = new BehaviorSubject<any>(undefined);
  lockSvgHide= new BehaviorSubject<any>(undefined);



 getAllActiveRole(roleRequestModel:model.RoleRequestModel){
  let url=URLS.user.getAllActiveRoles;
  return this.dataService.post(url,roleRequestModel).pipe<Result>(tap((response:any)=>{
  return response
  }))
 } 

getAllUser(userRequestModel:model.UserRequestModel){
  let url=URLS.user.getAllUsers;
  return this.dataService.post(url,userRequestModel).pipe<Result>(tap((response:any)=>{
  return response;
  }))
}

updateUserStatus(updateUserStatus: model.UpdateUserStatus): Observable<Result> {
  let url = URLS.user.updateUserStatus;
  return this.dataService.post(url, updateUserStatus).pipe(tap((response: any) => {
  return response;
  }));
}

deleteUser(userId) : Observable<Result>{
  let url = URLS.user.deleteUser;
  return this.dataService.post(url, userId).pipe(tap((response: any) => {
    return response;
  }))
}

// userExport(data: model.UserRequestModel): Observable<Result> {
//   let url = 'IdentityApi/account/exportGetAllUsers';
//   return this.dataService.post(url, data).pipe(tap((response: any) => {
//     return response;
//   }));
// }


insertAssignCommission(data: model.AssignCommissionRoleModel): Observable<Result> {
  let url = URLS.user.insertAssignCommission;
  return this.dataService.post(url, data).pipe(tap((response: any) => {
    return response;
  }));
}
getAllDropDownList(): Observable<Result> {
  let url = URLS.user.getAllDropDownList;
  return this.dataService.get(url, true).pipe(tap((response: any) => {
    return response;
  }));
}


  // #region start : addEdit user services 
  createUser(createNewUserModel:model.CreateNewUserModel): Observable<Result> 
  {
    let url = URLS.user.createUser;
    const myObj = JSON.stringify(createNewUserModel);
    return this.dataService.upload(url,createNewUserModel).pipe(tap((response: any) => {
    return response;
    }));
  };
  
  updateUser( updateUserModel :model.CreateNewUserModel): Observable<Result> {
     let url = URLS.user.updateUser;
     const myObj = JSON.stringify(updateUserModel);
     return this.dataService.upload(url, updateUserModel).pipe(tap((response: any) => {
     return response;
     }));
   };

   getData(): Observable<Result> {
    let url = URLS.user.getData;
    return this.dataService.get(url).pipe(tap((response: any) => {
    return response;
    }));
  };

  //#endregion : addEdit user services 
  adminRestPassword(restRequestmodel: model.RestPasswordRequestmodel): Observable<Result> {
    let url = URLS.user.adminResetPassword;
    const obj = JSON.stringify(restRequestmodel);
    return this.dataService.post(url, restRequestmodel).pipe(tap((response: any) => {
      return response;
    }));
  }
  //#endregion : resetpassword u services 
}

<div class="modal-header border-0 pb-0 mx-3">
  <h6 class="text-primary-color mb-0 mt-3 ml-3 font-15">{{ CompnayID != undefined ? editName :  headingsNames}}</h6>
</div>
<!-- end Modal header -->
<div class="modal-body px-4 mx-2">
  <!-- requirement information start -->
 <div class="row">
<div class="col-3">
  <div class="row">
    <div class="col">
      <span class="d-flex font-13 text-gray">Requirement Number:</span>
      <span class="font-13 text-primary-color">{{requirementInfo?.requirementNo ? requirementInfo?.requirementNo : '-'}}</span>
    </div>
    <div class="col-12 d-flex justify-content-end">
    <label
      for=""
      class="text-semi-primary hand mb-0 font-11 pe-1"
      (click)="openAddEditCompanyModal()"
      >+ Add Company
    </label>
  </div>
  <div class="col-12 mb-4 requirement">
    <app-input-search
    id="typeahead-format"
    [inputType]="'text'"
    [inputLabel]="'Company'"
    [ngbTypeahead]="companySearch"
    [inputValue]="companyName"
    (inputValueEmit)="requirementInfo.companyId = $event;selectValueChange($event)"
    (blur)="clearInputText()"
  ></app-input-search>
  <!-- [inputReqValidation]="true"
  [ngClass]="{'border-red':!requirementInfo.companyId && submit}" -->

  </div>
  <div class="col-12 mb-4">
    <app-input
      [inputLabel]="'Customer RFQ #'"
      [inputType]="'text'"
      [inputValue]="requirementInfo.customerRFQ"
      (inputValueEmit)="requirementInfo.customerRFQ = $event"
    >
    </app-input>
  </div>
  </div>
</div>
<div class="col-5">
  <div class="row">
    <div class="col">
      <span class="d-flex font-13 text-gray">Requirement Date:</span>
      <span class="font-13 text-primary-color">
        {{ requirementInfo?.requirementDate ? requirementInfo?.requirementDate : (todayDate | date:'MM/dd/yyyy') }}
      </span>
    </div>
    <div class="col-12 d-flex justify-content-center">
    <label
      for=""
      class="text-semi-primary hand mb-0 font-11 pe-4 me-2"
      (click)="openAddContactModal()"
      >+Add Contact
    </label>
  </div>
  <div class="col-7 mb-4 requirement">
    <!-- <app-input-search
      [inputLabel]="'Customer Contact'"
      [inputType]="'text'"
      [inputValue]="addRequirementInfo.contactName"
      (inputValueEmit)="addRequirementInfo.contactName = $event"
      [isInputDisabled]="disabledContact == true ? true : false"
      id="typeahead-format"
      [ngbTypeahead]="searchContact"
      [resultFormatter]="formatter"
      [inputReqValidation]="true"
    >
    </app-input-search> -->
    <app-dropdown
    [selectLabel]="'Customer Contact'"
    [data]="contactList"
    [selectValue]="requirementInfo.contactId"
    (selectValueEmit)=" requirementInfo.contactId = $event"
    [disabled]="disabledContact == true ? true : false"
    >
    </app-dropdown>
    <!-- [ngClass]="{'border-red':!requirementInfo.contactId && submit}" -->

  </div>
  <div class="col-5 mb-4">
    <app-dropdown
    [selectLabel]="'Req Source'"
    [data]="referralSourceList"
    [selectValue]="requirementInfo.requirementSource"
    (selectValueEmit)="requirementInfo.requirementSource = $event"
    >
    </app-dropdown>
  </div>
  <div class="col-contact mb-4">
    <!-- <app-input
      [inputLabel]="'Currency'"
      [inputType]="'text'"
      [inputValue]="requirementInfo.currency"
      (inputValueEmit)="requirementInfo.currency = $event"
    >
    </app-input> -->
    <app-dropdown  
    [selectLabel]="'Currency'"
    [data]="currencyList"
    [selectValue]="requirementInfo.currency"
    (selectValueEmit)="requirementInfo.currency = $event"
    >
    </app-dropdown>
  </div>
  <div class="col-contact mb-4">
    <app-input
      numberFormat
      [inputLabel]="'Exchange Rate'"
      [inputType]="'text'"
      [inputValue]="requirementInfo.exchangeRate"
      (inputValueEmit)="requirementInfo.exchangeRate = $event"
    >
    </app-input>
  </div>
  <div class="col-type pe-0">
    <div class="row type">
      <div class="col ps-1 pe-0 mb-1">
        <span class="d-flex font-11 text-nowrap text-light-gray mb-1">Opportunity Type:</span>
      </div>
      <div class="radio radio-p col-6 d-flex " *ngFor="let saleItem of saleStageList; let i = index">
        <input 
          type="radio"
          name="radiogroup1"
          [value]="saleItem.id"
          [checked]="requirementInfo.opportunityTypeId === saleItem.id"
          [id]="saleItem.id"
          (change)="onSelectionChange(saleItem)"
        />
        <label class="font-11" for="{{ saleItem.id }}">{{ saleItem.name }}</label>
      </div>
    </div>
  </div>
  </div>
</div>
<div class="col-4">
  <div class="col-12 noteWidth p-0 top">
    <div class="d-flex flex-column position-relative addNote">
      <label
        class="form-label position-absolute addLabel font-12"
        for=""
      >
      Customer Note
      </label>
      <textarea
        name="note"
        rows="3"
        class="form-control mx-3 mt-4 pt-1 font-13 Textarea"
        [(ngModel)]="requirementInfo.customerNotes"
      >
      </textarea>
    </div>
  </div>

</div>

</div>
  <!-- requirement information end-->


 <!-- requirement details start -->
 <div class="row">
  <div class="">
    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="active"
      class="nav-tabs  d-flex align-items-center" >
      <li [ngbNavItem]="1" class="pb-2" [ngClass]="active === 1 ? 'pe-3' : 'p-e-2'"
      [ngStyle]="{'border-bottom': active === 1 ? '1px solid var(--sitewide_primary_accent_color)' : '1px solid transparent'}"
      >
        <div
          class="hand font-13"
          [ngClass]="active === 1 ? 'text-primary-color' :  'text-light-gray'"
          (click)="activeTab(1)"
          ngbNavLink >
          Requirement Details
        </div>
        <ng-template ngbNavContent> 
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <label  for=""   class="text-semi-primary hand mb-0 font-11 me-5 pe-5 ms-2">+Add MFR</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3 mb-4 requirement">
            <!-- <app-input
              [inputLabel]="'MPN'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.mpn"
              (inputValueEmit)="requirementDetails.mpn = $event"
              [inputReqValidation]="true"
              [ngClass]="{'border-red':!requirementDetails.mpn && submit}"
            >
            </app-input> -->
            <app-input-search
            id="typeahead-format1"
            [inputType]="'text'"
            [inputLabel]="'MPN'"
            [ngbTypeahead]="mpnSearch"
            [inputValue]="requirementDetails.mpn"
            (inputValueEmit)="requirementDetails.mpn = $event;selectMpnValueChange($event)"
            (selectItem)="handleSelectItem($event)"
            (blur)="handleoutSideClick($event)"
          ></app-input-search>
          <!-- [inputReqValidation]="true"
          [ngClass]="{'border-red':!requirementDetails.mpn && submit}" -->

          </div>
          <div class="col-3 mb-4">
            <app-input
              [inputLabel]="'MFR'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.mfr"
              (inputValueEmit)="requirementDetails.mfr = $event"
              [isInputDisabled]="disabledMPNfields == true ? true : false"
            >
            </app-input>
            <!-- [inputReqValidation]="true"
            [ngClass]="{'border-red':!requirementDetails.mfr && submit}" -->

          </div>
          <div class="col-2 mb-4">
            <app-input 
              onlyNumbers 
              [inputLabel]="'Qty'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.qty"
              (inputValueEmit)="requirementDetails.qty = $event"
            >
            </app-input>
            <!-- [inputReqValidation]="true"
            [ngClass]="{'border-red':!requirementDetails.qty && submit}" -->

          </div>
          <div class="col-2 mb-4">
            <app-dropdown 
            [selectLabel]="'DC Restrictions'"
            [data]="dcRestrictionsList"
            [selectValue]="requirementDetails.dcRestrictions"
            (selectValueEmit)="requirementDetails.dcRestrictions = $event"
            >
            </app-dropdown>
          </div>
          <div class="col-2 mb-4">
            <app-dropdown
            [selectLabel]="'RoHS'"
            [data]="roHSList"
            [selectValue]="requirementDetails.roHS"
            (selectValueEmit)="requirementDetails.roHS = $event"
            [disabled]="disabledMPNfields == true ? true : false"
            >
            </app-dropdown>
            <!-- [required]="true"
            [ngClass]="{'border-red':!requirementDetails.roHS && submit}" -->

          </div>
             
        </div>
        <div class="row">
          <div class="col-3 mb-4">
            <app-input
              [inputLabel]="'Condition'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.conditions"
              (inputValueEmit)="requirementDetails.conditions = $event"
            >
            </app-input>
          </div>
          <div class="col-2 mb-4">
            <!-- <app-input
              [inputLabel]="'Need Date'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.needDate"
              (inputValueEmit)="requirementDetails.needDate = $event"
            >
            </app-input> -->
            <app-date-picker
              [time]="false"
              [inputLabel]="'Need Date'"
              [inputValue]="requirementDetails.needDate"
              (inputValueEmit)="requirementDetails.needDate = $event"
            ></app-date-picker>
          </div>
          <div class="col-3 mb-4">
            <app-input
              [inputLabel]="'CPN'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.cpn"
              (inputValueEmit)="requirementDetails.cpn = $event"
            >
            </app-input>
          </div>
          <div class="col-2 mb-4">
            <app-input
              [inputLabel]="'NSN'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.nsn"
              (inputValueEmit)="requirementDetails.nsn = $event"
              [isInputDisabled]="disabledMPNfields == true ? true : false"
            >
            </app-input>
          </div>
          <div class="col-2 mb-4">
            <app-input
              [inputLabel]="'Revision'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.revisions"
              (inputValueEmit)="requirementDetails.revisions = $event"
            >
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-8 mb-4">
            <app-input
              [inputLabel]="'Description'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.description"
              (inputValueEmit)="requirementDetails.description = $event"
              [isInputDisabled]="disabledMPNfields == true ? true : false"
            >
            </app-input>
            <!-- [inputReqValidation]="true"
            [ngClass]="{'border-red':!requirementDetails.description && submit}" -->

          </div>
          <div class="col-2 mb-4">
            <app-input
              [inputLabel]="'Item Packaging'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.itemPackaging"
              (inputValueEmit)="requirementDetails.itemPackaging = $event"
              [isInputDisabled]="disabledMPNfields == true ? true : false"
            >
            </app-input>
            <!-- [inputReqValidation]="true"
            [ngClass]="{'border-red':!requirementDetails.itemPackaging && submit}" -->

          </div>
          <div class="col-2 mb-4">
            <app-input
              [inputLabel]="'COO'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.coo"
              (inputValueEmit)="requirementDetails.coo = $event"
            >
            </app-input>
          </div>
        </div>

        <div class="row">
          <div class="col-3 mb-4">
            <app-input
              onlyNumbers 
              [inputLabel]="'Customer Target Price'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.customerTargetPrice"
              (inputValueEmit)="requirementDetails.customerTargetPrice = $event"
            >
            </app-input>
            <!-- [inputReqValidation]="true"
            [ngClass]="{'border-red':!requirementDetails.customerTargetPrice && submit}" -->

          </div>
          <div class="col-2 mb-4">
            <app-input
              [inputLabel]="'Testing'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.testing"
              (inputValueEmit)="requirementDetails.testing = $event"
            >
            </app-input>
          </div>
          <div class="col-2 mb-4">
            <app-dropdown
            [selectLabel]="'Urgency'"
            [data]="urgencyList"
            [selectValue]="requirementDetails.urgency"
            (selectValueEmit)="requirementDetails.urgency = $event"
            >
            </app-dropdown>
          </div>
          <div class="col-2 mb-4">
            <app-input
              onlyNumbers 
              [inputLabel]="'Target Buy Price'"
              [inputType]="'text'"
              [inputValue]="requirementDetails.targetBuyPrice"
              (inputValueEmit)="requirementDetails.targetBuyPrice = $event"
            >
            </app-input>
          </div>
        </div>

        </ng-template>
      </li>
     <!-- requirement details end -->


   <!-- requirement part details start -->


      <li [ngbNavItem]="2" class="pb-2 pe-3" [ngClass]="active === 2 ? 'ps-0' : 'ps-4'"
      [ngStyle]="{'border-bottom': active === 2 ? '1px solid var(--sitewide_primary_accent_color)' : '1px solid transparent'}">
        <div
          class="hand font-13"
          [ngClass]="active === 2 ? 'text-primary-color' :  'text-light-gray'"
          (click)="activeTab(2)"
          ngbNavLink>
          Part Details
        </div>
        <ng-template ngbNavContent>
       <div class="row pt-3">
        <div class="col-3 mb-4">
          <app-input
            [inputLabel]="'SPQ'"
            [inputType]="'text'"
            [inputValue]="requirementParts.spq"
            (inputValueEmit)="requirementParts.spq = $event"
            [isInputDisabled]="disabledMPNfields == true ? true : false"
          >
          </app-input>
        </div>
        <div class="col-3 mb-4">
          <app-input
            [inputLabel]="'MOQ'"
            [inputType]="'text'"
            [inputValue]="requirementParts.moq"
            (inputValueEmit)="requirementParts.moq = $event"
          >
          </app-input>
        </div>
        <div class="col-2 mb-4">
          <app-input
            [inputLabel]="'HTS'"
            [inputType]="'text'"
            [inputValue]="requirementParts.hts"
            (inputValueEmit)="requirementParts.hts = $event"
          >
          </app-input>
        </div>
        <div class="col-3 mb-4">
          <app-input
            [inputLabel]="'Schedule B'"
            [inputType]="'text'"
            [inputValue]="requirementParts.scheduleB"
            (inputValueEmit)="requirementParts.scheduleB = $event"
            [isInputDisabled]="disabledMPNfields == true ? true : false"
          >
          </app-input>
        </div>

       </div>
       <div class="row">
        <div class="col-3 mb-4">
          <app-input
            [inputLabel]="'ECCN'"
            [inputType]="'text'"
            [inputValue]="requirementParts.eccn"
            (inputValueEmit)="requirementParts.eccn = $event"
            [isInputDisabled]="disabledMPNfields == true ? true : false"
          >
          </app-input>
        </div>
        <div class="col-3 mb-4">
          <app-input
            [inputLabel]="'Categories'"
            [inputType]="'text'"
            [inputValue]="requirementParts.categories"
            (inputValueEmit)="requirementParts.categories = $event"
            [isInputDisabled]="disabledMPNfields == true ? true : false"
          >
          </app-input>
          <!-- [inputReqValidation]="true"
          [ngClass]="{'border-red':!requirementParts.categories && submit}" -->

        </div>
        <div class="col-2 mb-4">
          <app-input
            [inputLabel]="'Temp Range'"
            [inputType]="'text'"
            [inputValue]="requirementParts.tempRange"
            (inputValueEmit)="requirementParts.tempRange = $event"
            [isInputDisabled]="disabledMPNfields == true ? true : false"
          >
          </app-input>
        </div>
        <div class="col-3 mb-4">
          <app-input
            [inputLabel]="'Part Status'"
            [inputType]="'text'"
            [inputValue]="requirementParts.partStatus"
            (inputValueEmit)="requirementParts.partStatus = $event"
          >
          </app-input>
        </div>
       </div>
      </ng-template>
    </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="" style="height: 285px;"></div>
  </div>

 </div>
  <!-- requirement part details end -->

 <!-- requirement opportunity start -->
 <div class="row">
  <div class="col-12 mb-4">
    <span class="font-13 text-primary-color">Opportunity Details</span>
  </div>
  <div class="row" *ngIf="requirementInfo.opportunityTypeId == 2">
    <div class="pe-0" style="width: 125px;">
      <div class="row type">
        <div class="col mb-1">
          <span class="d-flex font-13 text-nowrap text-light-gray  font-11">Project Approved</span>
        </div>
        <div class="radio radio-p col-6 d-flex p-l-radio" *ngFor="let item of projectList; let i = index">
          <input
            type="radio"
            name="radiogroup"
            [value]="item.active"
            [checked]="opportunityDetails.isProjectApproved === item.active"
            [id]="item.active"
            (change)="onSelectionProjectChange(item)"
           
          />
          <label for="{{ item.active }}">{{ item.name }}</label>
        </div>
      </div>
    </div>
    <div class="col-2 mb-4">
      <!-- <app-input
        [inputLabel]="'Est. Start Date'"
        [inputType]="'text'"
        [inputValue]="opportunityDetails.estStartDate"
        (inputValueEmit)="opportunityDetails.estStartDate = $event"
      >
      </app-input> -->
      <app-date-picker
      [time]="false"
      [inputLabel]="'Est. Start Date'"
      [inputValue]="opportunityDetails.estStartDate"
      (inputValueEmit)="opportunityDetails.estStartDate = $event"
    ></app-date-picker>

    </div>
    <div class="col-2 mb-4">
      <app-date-picker
        [time]="false"
        [inputLabel]="'Bid Deadline'"
        [inputValue]="opportunityDetails.bidDeadline"
        (inputValueEmit)="opportunityDetails.bidDeadline = $event"
      >
      </app-date-picker>
    </div>
    <div class="col-3 mb-4">
      <app-input
        [inputLabel]="'End Customer/Industry'"
        [inputType]="'text'"
        [inputValue]="opportunityDetails.endCustomer"
        (inputValueEmit)="opportunityDetails.endCustomer = $event"
      >
      </app-input>
    </div>
    <div class="col-3 mb-4">
      <app-input
        [inputLabel]="'Project Name'"
        [inputType]="'text'"
        [inputValue]="opportunityDetails.projectName"
        (inputValueEmit)="opportunityDetails.projectName = $event"
      >
      </app-input>
    </div>
  
  
  </div>
  <div class="col-12">
  <div class="row">
    <div *ngIf="requirementInfo.opportunityTypeId == 1" class="col-5">
      <div class="col-12 mb-2">
        <span class="font-13 text-light-gray">Type</span>
      </div>
      <div class="col-12 p-0" style="height:99px;overflow:scroll;">
        <div class="d-flex flex-wrap labl-div align-div typeScroll">
        <app-button
          *ngFor="let items of oppurtunityTypeList; let i = index"
          [buttonType]="'button'"
          [button_2]="true"
          (click)="selecteAccountTypesId(items, i)"
          [buttonClass]="
            'button-lable-primary btn-hover font-12 mx-1 ps-2 m-b-5 text-center d-flex justify-content-center align-items-center'
          "
          [spanClass]="'text-truncate'"
          [buttonLable]="items.name"
          [ngClass]="items.isActive ? 'selected' : ''"
        >
        </app-button>
        </div>
      </div>
  </div>
    <div class=" mini-div-1" style="width: 28%;">
      <div class="row pe-1">
        <div class="col-12 d-flex justify-content-between">
          <div class="d-flex">
            <div class="text-light-gray font-11 text-nowrap me-2">
              Close Probability:
            </div>
            <div class="text-primary-color text-nowrap font-11">
              {{
                opportunityDetails?.closeProbability > 0
                  ? startRating +
                    " to " +
                    opportunityDetails?.closeProbability +
                    ""
                  : "No Range Selected"
              }}
            </div>
          </div>
          <div class="nowrap add-req p-0 text-semi-primary">
            <div class="hand d-flex" (click)="clearStausBar()">
              <app-svg-icon 
                [src]="'../../../../../../../assets/SVGIcons/smallClaer.svg'"
                class="d-flex align-items-center me-2 text-semi-primary"
              >
              </app-svg-icon>
              <span class="pe-0 font-11  text-semi-primary">
               Clear 
              </span>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <ngx-slider
          [(value)]="startRating"
          [(highValue)]="opportunityDetails.closeProbability"
          [options]="options"
          >
          </ngx-slider>
          <div class="row mx-1">
            <div class="col-6 text-start p-0 me-1 text-light-gray">
              <span>0</span>
            </div>
            <div class="col d-flex justify-content-end p-0 text-light-gray">
              <span>100</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-3 ps-2" *ngIf="requirementInfo.opportunityTypeId == 2" style="width: 32%;">
      <app-input
        [inputLabel]="'Application'"
        [inputType]="'text'"
        [inputValue]="opportunityDetails.application"
        (inputValueEmit)="opportunityDetails.application = $event"
      >
      </app-input>
    </div>
    <div class="" 
    [ngStyle]="{'width':requirementInfo.opportunityTypeId === 1 ? '30%' : '40%'}"
     >
      <app-input
        [inputLabel]="'Opportunity Notes'"
        [inputType]="'text'"
        [inputValue]="opportunityDetails.opportunityNotes"
        (inputValueEmit)="opportunityDetails.opportunityNotes = $event"
      >
      </app-input>
    </div>

  </div>
 </div>
 </div>
 <!-- requirement opportunity end -->

</div>
<div class="modal-footer border-0 mb-3 me-3">
  <div class="d-flex">
    <app-button
      class="me-3"
      [buttonType]="'button'"
      [button_2]="true"
      [buttonClass]="'button button-danger'"
      (click)="activeModal.close()"
      [buttonLable]="enumButtonName.CANCEL">
    </app-button>
    <app-button 
      [buttonType]="'submit'"
      [button_2]="true"
      [buttonClass]="'button button-primary'"
      [buttonLable]=" CompnayID != undefined ? editName :  headingsNames"
      (click)="requirementInfo.id? insertUpdateReuirements('update'): insertUpdateReuirements('add')"
    >
    </app-button>
  </div>
</div>

import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover, NgbTimepicker, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-ngb-timepicker',
  templateUrl: './ngb-timepicker.component.html',
  styleUrls: ['./ngb-timepicker.component.scss']
})
export class NgbTimepickerComponent implements OnInit {
  @Output() timeChange = new EventEmitter<any>();
 // @Output() timeChange = new EventEmitter<NgbTimeStruct | null>();
  @ViewChild('clockElement', { static: false }) clockElement: ElementRef;
 // @ViewChild('time') timepickerPopover :any;
 @ViewChild('timepickerPopover') timepickerPopover: NgbPopover;
 @ViewChild('timepicker') timepicker: NgbTimepicker;
  selectedTimeValue: string = '';
  @Input() selectedTime: string = '';
  @Input() ngbTimePickerValue: NgbTimeStruct | null = null;
  isPopoverOpen: boolean = false;
  hideIcon: boolean = false;
  isFocused: boolean = false;
  // ngbTimePickerValue: { hour: number, minute: number };
 // ngbTimePickerValue: { hour: number, minute: number } = null;
  constructor() {
    this.ngbTimePickerValue = { hour: 0, minute: 0, second: 0 };
   }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const isClickInsideDiv = this.clockElement && this.clockElement.nativeElement.contains(event.target);
    if (!isClickInsideDiv) {
      this.isPopoverOpen = false;
    }
  }
  
  ngOnInit(): void {
    if(this.selectedTime){
      this.selectedTimeValue = this.selectedTime
    }
  }
  togglePopover() {
    this.isPopoverOpen = !this.isPopoverOpen;
  }

  ngAfterViewInit(): void {
    const a = document.getElementById('timepicker_contain')
    
  }

  changeTimeExpiration1(time: NgbTimeStruct | null) {
    console.log('time changed', time)
    this.ngbTimePickerValue = time;
    this.timeChange.emit(time)
    if(time){
    this.ngbTimePickerValue = time;
    this.timeChange.emit(time);
    this.selectedTimeValue = `${this.ngbTimePickerValue?.hour}:${this.ngbTimePickerValue?.minute} ${this.ngbTimePickerValue?.second}`;
  } else {
    this.ngbTimePickerValue = null;
    this.selectedTimeValue = '';
  }
  this.timeChange.emit(time)
  
  }

  private pad(value: number | null): string {
    if (value === null || value === undefined) {
      return '00';
    }
    return value < 10 ? `0${value}` : `${value}`;
  }

  changeTimeExpiration(timeValue: any) {
    if(timeValue){
    this.hideIcon = false;
    const formattedTime = moment(timeValue).format('h:mmA');
   this.selectedTimeValue = (typeof timeValue === 'string' && (timeValue.includes('am') || timeValue.includes('pm') || timeValue.includes('AM') || timeValue.includes('PM'))) 
   ? timeValue 
   : formattedTime;
     const formattedTimeValue = moment().set({
      hour: timeValue.hour,
      minute: timeValue.minute,
      second: timeValue.second
    }).format('HH:mm');
    this.selectedTimeValue = formattedTime;
    this.timeChange.emit(formattedTimeValue);

    }else{
        this.selectedTimeValue = '';
    }
  }
  changeTimeExpiration11(event: NgbTimeStruct) {
    // You can add any logic here that you need to handle the change event
    console.log('Time changed: ', event);
  }
  
  clearTime() {
    this.selectedTimeValue = '';
    this.hideIcon = true;
    this.timeChange.emit(null);
    this.ngbTimePickerValue = null;
    //this.changeTimeExpiration1(null);
  }

  onEnterPress(){
  this.togglePopover()
  }

  onTabPress(){
    if(this.isPopoverOpen){
      this.isPopoverOpen = false;
    }
  }

  onTabs(event){
    if (event.key === 'Tab') {
      this.isPopoverOpen = false;
    }
    // this.timepickerPopover.close()
  }

  onFocus(){
    this.isFocused = true;
  }

  onBlur(){
    this.isFocused = false;
  }

}

import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[OnlyAlphabetsAndPunctuation]'
})
export class OnlyAlphabetsAndPunctuationDirective {
  @HostBinding('autocomplete') public autocomplete;

  constructor() {
    this.autocomplete = 'off';
  }

  @HostListener('keypress', ['$event']) public disableKeys(event: KeyboardEvent) {
    const keyCode = event.keyCode || event.which; // 'which' for compatibility with older browsers

    // Check if it's an alphabet letter, backspace, space, or allowed punctuation
    const isAllowedKey = keyCode === 8 || // Backspace
                         keyCode === 32 || // Space
                         (keyCode >= 65 && keyCode <= 90) || // A-Z
                         (keyCode >= 97 && keyCode <= 122) || // a-z
                         keyCode === 46 || // Period
                         keyCode === 44 || // Comma
                         keyCode === 63 || // Question Mark
                         keyCode === 33 || // Exclamation Mark
                         keyCode === 59 || // Semicolon
                         keyCode === 58;   // Colon

    if (!isAllowedKey) {
      event.preventDefault(); // Prevent the default action if the key is not allowed
    }
  }
}

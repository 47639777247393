import { ToastrService } from 'ngx-toastr';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { CrmService } from './../../../../components/crm/crm.service';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { UpdateCellData, UpdateCellDataIntentToBuy } from '../ag-grid.model';
import { OperationId } from 'src/app/shared/model/enum';
import { AgGridService } from '../ag-grid.service';

export class CurrentSelection{
  name:string=""
  colorCode:string=""
}
@Component({
  selector: 'app-intent-to-buy-editor',
  templateUrl: './intent-to-buy-editor.component.html',
  styleUrls: ['./intent-to-buy-editor.component.scss']
})
export class IntentToBuyEditorComponent implements OnInit,ICellEditorAngularComp {
  dropDownList: any[]=[];
  currentSelection = new CurrentSelection();
  // updateCellData= new UpdateCellData();
  updateCellData = new UpdateCellDataIntentToBuy();

  operationId=OperationId;
  active = false;
  params
  selectedValue: any;
  icon = './assets/SVGIcons/upIcon-arrow.svg'
  @ViewChild("mySelect") mySelect: ElementRef;

  constructor(private crmService:CrmService,private agGridService:AgGridService,private toastr:ToastrService) { }
  agInit(params: ICellEditorParams<any, any>): void {
    this.params = params;
    this.selectedValue = params.value;
    if(this.params?.column?.colId == 'intentToBuyId'){
      this.currentSelection.name = params?.data?.intentToBuyName;
    }
    else if(this.params?.column?.colId == 'leadScoreId'){
      this.currentSelection.name = params?.data?.leadScoreName;
    }
    this.currentSelection.colorCode = params?.data?.intendToBuyColorCode;
  }
  getValue() {
    
  }
  isPopup?(): boolean {
    return
  }
  refresh(params: any): boolean {
    return true;
  }
  getPopupPosition?(): string {
    return
  }
  isCancelBeforeStart?(): boolean {
    return
  }
  isCancelAfterEnd?(): boolean {
    return
  }
  focusIn?(): void {
    return
  }
  focusOut?(): void {
   return
  }
  afterGuiAttached?(): void {
    return
  }

  ngOnInit(): void {
    var allDropDownData = this.crmService.dropdownAllList.getValue();
    console.log('the all dropdownsd ', allDropDownData)
    console.log('the all dropdownsd ', this.params)
    if(this.params?.column?.colId == 'intentToBuyId'){
      this.dropDownList = allDropDownData?.intendToBuyList;
      // this.updateCellData.saleStageId = this.operationId.IntentToBuy;
      this.updateCellData.saleStageId = this.params?.data?.saleStageId;
    }
    else if(this.params?.column?.colId == 'leadScoreId'){
      this.dropDownList = allDropDownData?.leadsList;
      //this.updateCellData.saleStageId = this.operationId.LeadScore;
      this.updateCellData.saleStageId = this.params?.data?.saleStageId;
    }
    
  }

      newOption: any;
  emptyDataSelection = {
    name: 'Select'
  };

  setCurrentSelection(option) {
    let expanded = this.params.data.isExpandable
    this.currentSelection = option;
    // this.updateCellData.companyIds = this.params?.data?.id ;
    this.updateCellData.companyIds.push(this.params?.data?.id);
    if(option.labelName =='IntendToBuy'){
      this.updateCellData.intentToBuyId = option.id;
    }else{
      this.updateCellData.leadScoreId = option.id;
    }
        this.crmService.updateRatingStatusLeadScoreIntendToBuy(this.updateCellData).subscribe(res=>{
      console.log(res);
      res.response.isExpandable = expanded
      const rowNode = this.agGridService.getGridApi().getRowNode(String(this.params.rowIndex));
      rowNode.setData(res.response);
      // this.agGridService.gridApi.value.resetRowHeights()
      this.toastr.success(res.message);
      this.params.api.refreshCells();
    })
    this.params.data.isExpandable = expanded;
    this.params.api.stopEditing();
  }

}

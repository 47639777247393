import { BehaviorSubject, Observable, tap } from "rxjs";
import { Result } from "src/app/shared/model/response.model";
import { DataService } from "src/app/shared/services/data.service";
import { Id, InsertUpdateCodeCrosswalk, UpdateStatusCodeCrosswalk } from "./pages/code-crosswalk.model";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { URLS } from 'src/app/components/crm/crm-api-constants';

@Injectable({
  providedIn: "root",
})
export class CodeCrosswalkService {


  public valuePass = new BehaviorSubject(undefined);
  public valueSearchtext = new BehaviorSubject(undefined);
  public openAddModal = new BehaviorSubject(undefined);
  public valuePopup = new BehaviorSubject(undefined);


  constructor(private service: DataService,
    public router: Router,
  ) { }


  //insert update code-crosswalk data
  insertCodeCrosswalk(data: InsertUpdateCodeCrosswalk): Observable<Result> {
    data.currentUrl = this.router.url;
    let url = URLS.masterCodeCrosswalk.insertCodeCrosswalk;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  updateCodeCrosswalk(data: InsertUpdateCodeCrosswalk): Observable<Result> {
    let url = URLS.masterCodeCrosswalk.updateCodeCrosswalk;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  updateSatusCodeCrosswalk(data: UpdateStatusCodeCrosswalk): Observable<Result> {
    let url = URLS.masterCodeCrosswalk.updateCodeCrosswalkStatus;
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //delete code-crosswalk data
  deleteCodeCrosswalk(id: Id): Observable<Result> {
    let url = URLS.masterCodeCrosswalk.deleteCodeCrosswalk;
    return this.service.post(url, id).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }


}

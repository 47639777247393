import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { SidebarNotificationService } from '../sidebar-notification.service';
import { UserIdModel } from '../sidebar-notification.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { AddActivityComponent } from 'src/app/components/crm/activity-manager/pop-ups/add-activity/add-activity.component';
import { AddEditCommentComponent } from 'src/app/components/crm/activity-manager/pop-ups/add-edit-comment/add-edit-comment.component';
import { ActivityManagerService } from 'src/app/components/crm/activity-manager/activity-manager.service';
import { ActivityTabService } from 'src/app/components/crm/companies/pages/company-info-tabs/activity-tab/activity-tab.service';
import { AllActivites, AllActivitysModel, InsertSnooz, InsertUpdateToDoActivity, MarkComplete } from 'src/app/components/crm/activity-manager/activit-manager.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CompaniesService } from 'src/app/components/crm/companies/pages/companies/companies.service';
import { TabsService } from '../../tabs/tabs.service';
import { Menu } from 'src/app/shared/services/nav.service';
import { StingToDateService } from 'src/app/shared/services/stringToDate.service';
import { MarkCompleteComponent } from 'src/app/components/crm/activity-manager/pop-ups/mark-complete/mark-complete.component';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
  showPopup:boolean = false;
  datePickerShow:boolean = false;
  formattedTime: string = '';
  hideIcon:boolean = false;
  selectedDate ='';
  selectedTime ='';
  userIdModel = new UserIdModel();
  selecteddata;
  @Output() markComplete: EventEmitter<any> = new EventEmitter();
  @Input() tooltipPosition: boolean = false;
  @Input()data :any;
  @Input()tasksList :any;
  @Input() iconHide: boolean;
  @Input() getclass: any;
  @Output() tooltipToggled: EventEmitter<number> = new EventEmitter<number>();
  insertSnooz = new InsertSnooz();
  allActivitysModel = new AllActivitysModel()
  allActivites: AllActivites[] = []
  activityIdsList = new MarkComplete()
  isTimeEmpty: boolean = true;
  insertUpdateToDoActivity = new InsertUpdateToDoActivity()
  formattedDate:string;
  @ViewChild('contentWrapper') contentWrapper!: ElementRef;
  ngbTimePickerValue: NgbTimeStruct;
  selectedTimeValue;
  isPopoverOpen = false;

  constructor(public sidebarNotificationService:SidebarNotificationService,
    public storageservice: StorageService,
    public modalService: NgbModal,
    public activityService: ActivityManagerService,
    public activityTabService: ActivityTabService,
    private toaster: ToastrService,private tabsService: TabsService,
    private router: Router, public companiesService: CompaniesService,
    public stringToDateService: StingToDateService,
    public commonService      : CommonService,
    ) { }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
      const tooltipContainer = document.querySelector('.custom-tooltip');
      if (tooltipContainer && !tooltipContainer.contains(event.target as Node)) {
       // this.data.datePickerShow = false;
      }
    } 

    functionInChild(selectedId){
      this.tasksList.map((a)=>{
        if(selectedId==a.id){
          a.datePickerShow=false
        }else{
          a.datePickerShow=false
        }
      })
    }
    
  ngOnInit(): void {
    this.data
  //  this.updateTime();
  }



  datePicker(selectedId){
  this.tasksList.map((a)=>{
    if(selectedId==a.id){
      a.datePickerShow=!a.datePickerShow;
      const element = this.contentWrapper.nativeElement;
      this.sidebarNotificationService.tooltip.next( element.offsetTop);
      event.stopPropagation();
    }else{
      a.datePickerShow=false
    }
  })
  }
  updateTime() {
    const date = new Date();
    const formattedDate = moment(date).format('DD/MM/YY');
    const formattedTime = moment(date).format('h:mmA');
    this.formattedTime = `${formattedDate} at ${formattedTime}`;
  }

  changeDateExpirations(selectedDate: string): void {
    console.log(selectedDate); 
    this.changeDateExpiration(selectedDate)
  }

  changeDateExpiration(queryDate) {
    if(queryDate){
    this.selectedDate  = queryDate;
    this.hideIcon = false;
   // this.formattedDate = moment(queryDate).format('MM/DD/YYYY');
   //this.formattedDate = queryDate;
   this.formattedDate = this.stringToDateService.dateConvertedToString(queryDate);
   this.updateSnoozeTasks(this.formattedDate, this.selectedTime);
}else{
  this.selectedDate ='';
  this.formattedDate = ''
}
  }
  onClick(){
    this.isPopoverOpen = false;
  }
  changeTimeExpiration(timeValue: any) {
    if(timeValue){
 //   this.selectedTime = timeValue;
    this.hideIcon = false;
    const formattedTime = moment(timeValue).format('h:mmA');
    this.selectedTimeValue = formattedTime;
    const formattedTimeValue = moment().set({
      hour: timeValue.hour,
      minute: timeValue.minute,
      second: timeValue.second
    }).format('HH:mm');
    this.selectedTime = formattedTimeValue;
    this.updateSnoozeTasks(this.formattedDate, formattedTimeValue);
    }
  }

 
  
  clear(f:NgForm){
    f.value.dateExpiration = '';
    this.selectedDate = '';
    this.formattedDate =  '';
   }
   clearTime(f:NgForm){
    f.value.timeExpiration = '';
    this.selectedTime = '';
    this.isPopoverOpen = false;
    this.ngbTimePickerValue = null;
    this.selectedTimeValue = null;

   }

   
   add_EditActivityComponent(data,title){
    const copyOfObject = Object.assign({}, data);
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "lg",
    };
    const modalRef = this.modalService.open( AddActivityComponent,modalOption);
    modalRef.componentInstance.titleName = title;
    if(title=='Edit'){
      if(copyOfObject.dueTime){
        const timeObject =  this.stringToDateService.convertTimeStringToObject(copyOfObject.dueTime);
        copyOfObject.dueTime   = this.stringToDateService.convertToFormattedTimeString(copyOfObject.dueTime);
        modalRef.componentInstance. ngbTimePickerValue = timeObject
        // modalRef.componentInstance.dueTime  =copyOfObject?.dueTime??'';

        }
  
      modalRef.componentInstance.insertUpdateToDoActivity =copyOfObject;
      modalRef.componentInstance.typeId = copyOfObject?.typeId??'';
      modalRef.componentInstance.assignedToUserId = copyOfObject?.assignedToUserId??'';
      modalRef.componentInstance.title = copyOfObject?.title??'';
      modalRef.componentInstance.companyId = copyOfObject?.companyId??'';
      modalRef.componentInstance.contactId = copyOfObject?.contactId??'';
      modalRef.componentInstance.priorityId = copyOfObject?.priorityId??'';
      modalRef.componentInstance.description  =copyOfObject?.description??'';
      modalRef.componentInstance.dueDate  =copyOfObject?.dueDate??'';
      // modalRef.componentInstance.dueTime  =copyOfObject?.dueTime??'';
    }
    modalRef.result.then(res=>{
      if(res){
        this.sidebarNotificationService.loadData.next(true);
        // this.getTasks();
      }
    })
  }

  addComment(comment,isEditable){
    const copyOfObject = Object.assign({}, comment)
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "md",
    };
    const modalRef = this.modalService.open( AddEditCommentComponent,modalOption);
    modalRef.componentInstance.isEditable = isEditable
    modalRef.componentInstance.activityId =this.data.id;
    modalRef.componentInstance.id   = copyOfObject?.commentId??'';
    modalRef.componentInstance.comment   = copyOfObject?.comment??''
    modalRef.result.then(res => {
      if (res) {
        this.sidebarNotificationService.loadData.next(true);
      }
    })
  }

  updateSnoozeTasks(date: string, time: string) {
    if (date && time) {
      this.insertSnooz.snoozeTasks.push({
        taskId: this.data.id, 
        date: date,
        time: time,
      });
  
      this.activityService.snoozeTasks(this.insertSnooz).subscribe(res => {
        if (res.isSuccess) {
          this.toaster.success(res.message);
          this.datePickerShow = false;
          this.sidebarNotificationService.loadData.next(true);
        } else {
          this.toaster.error(res.message);
          this.datePickerShow = false;
        }
      });
    }
  }
  

  addUpdateSnooz(interval: number) {
    const dueDateParts = this.data.dueDate.split('/'); // Split date parts
    const dueTimeParts = this.data.dueTime.split(':'); // Split time parts
    if (dueTimeParts[1].toLowerCase().includes('pm') || dueTimeParts[1].toLowerCase().includes('am')) {
      dueTimeParts[1] = dueTimeParts[1].replace(/(am|pm)/i, '');
    }

    const currentDate = new Date(
      parseInt(dueDateParts[2]),    // Year
      parseInt(dueDateParts[0]) - 1, // Month (0-based)
      parseInt(dueDateParts[1]),    // Day
      parseInt(dueTimeParts[0]),    // Hours
      parseInt(dueTimeParts[1])     // Minutes
    );
  
    if (dueTimeParts[2] === 'PM' && currentDate.getHours() < 12) {
      currentDate.setHours(currentDate.getHours() + 12);
    }
  
    if (interval === 1) {
      currentDate.setHours(currentDate.getHours() + 24);
    } else if (interval === 2) {
      currentDate.setHours(currentDate.getHours() + 48);
    } else if (interval === 3) {
      currentDate.setDate(currentDate.getDate() + 7);
    }
  
    const formattedDate = moment(currentDate).format('MM/DD/YYYY');
    const formattedTime = dueTimeParts[0] + ':' + dueTimeParts[1].trim();
    let time = formattedTime;
  
    if (formattedTime.includes('PM') || formattedTime.includes('AM')) {
      time = formattedTime.replace('PM', '').replace('AM', '');
      time = time.trim();
    }
  
    this.insertSnooz.snoozeTasks.push({
      taskId: this.data.id,
      date: formattedDate,
      time: time,
    });
  
    this.updateSnoozeTasks(formattedDate, time);
  }
       
  openCompanyInfo(data){
    this.companiesService.id=data.companyId;
    let url = `crm/companies/company-info/${this.companiesService.id}`
    const item: Menu = { path: url, icon: '', title: data.companyName, type: 'link',id:data.companyId}
    this.tabsService.addTab(item);
    this.router.navigateByUrl(url);
    this.companiesService.activeTab.next(1);
}
openContactInfo(data){
  this.companiesService.id=data.companyId;
  let url = `crm/companies/company-info/${this.companiesService.id}`
  const item: Menu = { path: url, icon: '', title: data.companyName, type: 'link',id:data.companyId}
  this.tabsService.addTab(item);
  this.router.navigateByUrl(url);
  this.companiesService.activeTab.next(3);

}

togglePopover() {
  this.isPopoverOpen = !this.isPopoverOpen;
}
markAsComplete(id:string){
  const modalOption: NgbModalOptions = {
   centered: true,
   backdrop: "static",
   keyboard: false,
   size: "md",
 };
 const modalService = this.modalService.open(MarkCompleteComponent,modalOption);
 modalService.result.then(res => {
   if (res) {
     this.activityIdsList.ids = [id];
     this.activityService.markAsComplete(this.activityIdsList).subscribe(res => {
       if (res.isSuccess) {
         this.toaster.success(res.message);
         this.commonService.setSelectedDate(res);
       }
       if (!res.isSuccess) {
         this.toaster.error(res.message);
       }
     })
     if (res == 'ConfirmandClone') {
       this.openAddActivity('ConfirmandClone',false);
     }
     if (res == 'ConfirmandCreateNew') {
       this.openAddActivity(false);
     }
   }
 })

 }
 openAddActivity(isTrue, isEdit?) {
   const copyOfObject = Object.assign({}, this.data);
   const modalOption: NgbModalOptions = {
     centered: true,
     backdrop: "static",
     keyboard: false,
     size: "lg",
   };
   const modalService = this.modalService.open(
     AddActivityComponent,
     modalOption
   );
   if (isTrue) {
    if(copyOfObject.dueTime){
      const timeObject =  this.stringToDateService.convertTimeStringToObject(copyOfObject.dueTime);
      copyOfObject.dueTime   = this.stringToDateService.convertToFormattedTimeString(copyOfObject.dueTime);
      modalService.componentInstance. ngbTimePickerValue = timeObject
      }
     modalService.componentInstance.insertUpdateToDoActivity = copyOfObject
   }
   if (isTrue == 'ConfirmandClone') {
    copyOfObject.id = '';
    if(copyOfObject.dueTime){
      var timeValue = copyOfObject.dueTime;
      const timeObject =  this.stringToDateService.convertTimeStringToObject(copyOfObject.dueTime);
      modalService.componentInstance. ngbTimePickerValue = timeObject
      if( (typeof timeValue === 'string' && (timeValue.includes('am') || timeValue.includes('pm') || timeValue.includes('AM') || timeValue.includes('PM')))){
        copyOfObject.dueTime =   this.stringToDateService.changeTimeExpiration( copyOfObject.dueTime);
      }
    }
  
    modalService.componentInstance.insertUpdateToDoActivity = copyOfObject
  }

   if (isEdit) {
     modalService.componentInstance.titleName = 'Edit'
   }
   modalService.result.then(res => {
    if (res) {
      this.commonService.setSelectedDate(res);
    }})

 }

}

import { DocumentCellRendererComponent } from "src/app/components/crm/companies/pages/company-info-tabs/document-cell-renderer/document-cell-renderer.component";
import { AgColDefsI, AgGridI,} from "../../../../shared/components/ag-grid/ag-grid.model";
import { ActionsComponent } from "./actions/actions.component";

export class DuplicatesTab {
}


const TypeColumn: AgColDefsI = {
    headerName: "Type",
    field: "type",
    minWidth: 50,
    width:50,
    lockPinned: true,
    suppressMovable: true,
    cellStyle:{'padding-left':"22px",'color':'#646E76'},
    flex:1,
 
    
};

const CompanyNameColumn: AgColDefsI = {
    headerName: "Company Name",
    field: "companyName",
    minWidth: 400,
    width:400,
    lockPinned: true,
    sortable: true,
    suppressMovable: true,
    cellStyle:{ 'color':'var(--sitewide_primary_accent_color)','padding-left':"22px"},
    //cellClass: "padding-left-38px",
    
};

const PhoneColumn: AgColDefsI = {
headerName: "Phone Number",
field: "phoneNumber",
minWidth: 50,
width:50,
lockPinned: true,
sortable: true,
suppressMovable: true,
flex:1,
cellStyle:{'padding-left':"22px",'color':'#646E76'},
};
  
const AddressColumn: AgColDefsI = {
headerName: "Address",
field: "address",
minWidth: 550,
width:550,
lockPinned: true,
sortable: true,
suppressMovable: true,
cellStyle:{'padding-left':"22px",'color':'#646E76'},
};
  
const ActionColumn: AgColDefsI = {
headerName: "Action",
field: "action",
sortable: true,
cellClass: "padding-left-38px",
minWidth: 150,
flex:1,
lockPinned: true,
cellRenderer:ActionsComponent,
cellStyle:{'padding-left':"22px",'color':'#646E76'},
// checkboxSelection: function (params) {
//   return params?.data?.type == 'New Record';
// },
};

const SICCodeColumn: AgColDefsI = {
  headerName: "SIC Code",
  field: "sic",
  minWidth: 550,
  width:550,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  };
  const NAICSCodeColumn: AgColDefsI = {
    headerName: "NAICS Code",
    field: "naics",
    minWidth: 550,
    width:550,
    lockPinned: true,
    sortable: true,
    suppressMovable: true,
    cellStyle:{'padding-left':"22px",'color':'#646E76'},
    };
    const IndustryColumn: AgColDefsI = {
      headerName: "Industry",
      field: "industry",
      minWidth: 550,
      width:550,
      lockPinned: true,
      sortable: true,
      suppressMovable: true,
      cellStyle:{'padding-left':"22px",'color':'#646E76'},
      };
            
export const ColDefsCodeDuplicates: AgColDefsI[] = [
  TypeColumn,
  SICCodeColumn,
  NAICSCodeColumn,
  IndustryColumn,
  ActionColumn,
];

  export const ColDefsDuplicates: AgColDefsI[] = [
    TypeColumn,
    CompanyNameColumn,
    PhoneColumn,
    AddressColumn,
    ActionColumn,
  ];

  export const AgGridImportCompaniesDuplicatesTab: AgGridI = {
    colDefs: ColDefsDuplicates,
    rowSelection: "multiple",
  };
  export const AgGridImportCodecrosswalkDuplicatesTab: AgGridI = {
    colDefs: ColDefsCodeDuplicates,
    rowSelection: "multiple",
  };

  
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Button, PopUpTitles } from "../../model/enum";
import { AgGridService } from "../ag-grid/ag-grid.service";
import { UpdateQuickNotes } from "../ag-grid/ag-grid.model";
import { CrmService } from "src/app/components/crm/crm.service";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-common-model-pop-up",
  templateUrl: "./common-model-pop-up.component.html",
  styleUrls: ["./common-model-pop-up.component.scss"],
})
export class CommonModelPopUpComponent implements OnInit {
  @Input() fullName: string;
  @Input() title: string;
  @Input() pageType:string;
  @Input() ResMsgFromCompanyOrContact:string;
  @Input() currentTab:string;
  @Input() message: string;
  @Input() templateName: string;
  @Input() notes: string;
  @Input() companyId: string;
  @Input() deleteForAdmin:boolean=false;


  @Output() delPopupforAdmin: EventEmitter<any> = new EventEmitter();
  enumPopUpTitles = PopUpTitles;
  // enumButton = Button;
  enumButtonName = Button;
//  deleteForAdmin:boolean=false;
  quickNotes = new  UpdateQuickNotes();

  constructor(public activeModal: NgbActiveModal,public crmService: CrmService,public toaster: ToastrService,
    public agGridService: AgGridService,) {}

  ngOnInit(): void {
    this.quickNotes.notes =  this.notes 
    
  }

  deletePopup(){
    this.delPopupforAdmin.emit(true);
    setTimeout(() => {
    this.deleteForAdmin=true;
    }, 1000);
    
  }
  closedPopup(){
    this.agGridService.unChecked.next(false);
  }
  notesUpdate(){
    this.quickNotes.companyId = this.companyId;
    this.crmService.updatequicknote(this.quickNotes).subscribe(res=>{
      if(res.isSuccess){
        this.toaster.success(res.message);
        this.activeModal.close({ message: res.response, confirm: true });
      }else{
        this.toaster.error(res.message);
        this.activeModal.close('Close click')

      }
    })
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() ratingValue
  @Input() isDisabled = false
  @Input() dynamicId ?:string='';
  ratingStarList =[
    {id:'star5', value:5 , },
    {id:'star4.5', value:4.5 , className:'halff'},
    {id:'star4', value:4 , },
    {id:'star3.5', value:3.5, className:'halff'},
    {id:'star3', value:3 , },
    {id:'star2.5', value:2.5, className:'halff'},
    {id:'star2', value:2 , },
    {id:'star1.5', value:1.5 , className:'halff'},
    {id:'star1', value:1 , },
    {id:'star0.5', value:0.5 , className:'halff'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

  onRatingChange(value){
    this.newItemEvent.emit(value);
    console.log(value)
  }
  

}

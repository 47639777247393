
import { TradeInformationVm } from "../company-info-tabs/settings-tab/settings-tab.model";
import { PaginationBaseModel } from "./../../../../../shared/model/common.model";
export class Companies {}

export class CompanyPaginationModel extends PaginationBaseModel {
  companyId: number = 0;
  isActive: boolean = true;
  stage: string = "All";
  filters: string = "";
  exportAsPdf: boolean;
  selectedId: number[];
  filtersFromPopup: string = "";
}

export class AssignDropdown {
  id: string;
  name: string;
}

//assign model
export class Assign {
  companyIds: string[];
  salesPersonId: string = "";
  altSalesPersonId: string = "";
  buyersId: string = "";
}

// delete company
export class DeleteCompany {
  id: any[];
  userId:string=''
}

export class ImportCompanyVM {
  heading1: string = "Import Company";
  heading2: string = "Assign";
  lable1: string = "Select a CSV file or XLSX";
  lable2: string = "Or drag and drop it here ";
  lable3: string = "Uploading file";
  lable4: string = "Import was completed successfully";
  lable5: string = "Download Template File";
  errormsg: string = "Error! Please try uploading your files again.";
}

// Import Model
export class ImportModel {
  file: string;
  saleRepId: string;
  buyerRepId: string;
}

export class GetAllDropDownModel {
  id: number = 0;
  altSalesPersonList: CommonFilterModel[] = [];
  buyerRepList: CommonFilterModel[] = [];
  classList: CommonFilterModel[] = [];
  countryList: CommonFilterModel[] = [];
  salesPersonList: CommonFilterModel[] = [];
  tagsList: CommonFilterModel[] = [];
  timeZoneList: CommonFilterModel[] = [];
  typeList: CommonFilterModel[] = [];
  stateList: CommonFilterModel[] = [];
  scopeList: CommonFilterModel[] = [];
  sicCodeList: CommonFilterModel[] = [];
  intendToBuyList: CommonFilterModel[] = [];
  industryList: CommonFilterModel[] = [];
  leadsList: CommonFilterModel[] = [];
  naicsCodeList: CommonFilterModel[] = [];
  leadScoreList: CommonFilterModel[] = []
  prospectScoreList: CommonFilterModel[] = []
  stageStatusList: CommonFilterModel[] = [];
  manufacturerList: CommonFilterModel[] = [];
  vendorTypeList: CommonFilterModel[] = [];
  startRating: number = 0;
  endRating: number = 0;
  fromDate: string = "";
  filterTypeId: number = 1
  toDate: string = "";
  companyStatusId: number = 2;
  userId: string = "";
  archived: boolean;
  excludedFromVendorlist:boolean
  avlId:number = 2;
  avl:boolean;
  navl:boolean;
 // lastContactedDate:string;
}
export class CompanyFilterList {
  altSalesPersonList: string;
  archived: boolean;
  active: boolean;
  inActive: boolean;
  buyersRepList: string;
  classList: string;
  vendorTypeList:string;
  companyStatusId: number;
  countryList: string;
  endRating: number;
  fromDate: string;
  id: number;
  industryList: string;
  manufacturerList : string;
  leadScoreList:string;
  naicsCodeList: string;
  salesPersonList: string;
  scopeList: string;
  sicCodeList: string;
  stageStatusList: string;
  prospectScoreList:string;
  startRating: number;
  stateList: string;
  tagsList: string;
  timeZoneList: string;
  filterType:number;
  toDate: string;
  excludedFromVendorlist:boolean
  avlId:number
  avl:boolean;
  navl:boolean;
//  lastContactedDate: string;
}
export class CommonFilterModel {
  id: string;
  name: string;
  colorCode: string;
  labelName: string;
  isActive: boolean = false;
}

export class RemoveCompanyFilterModel {
  id: string;
  name: string;
  labelName: string;
  colorCode: string;
  isActive: boolean;
  userId: string;
}

export class FilterCheckList {
  salesPerson_CheckList: string[];
  alt_SalesPerson_CheckList: string[];
  buyers_Rep_CheckList: string[];
  last_Contacted_CheckList: string[];
 // last_Contacted_CheckList: string;
  scope_CheckList: string[];
  country_CheckList: string[];
  state_CheckList: string[];
  naicsCodeList_CheckList: string[];
  time_Zone_CheckList: string[];
  leadsList_CheckList: string[];
  industryList_CheckList: string[];
  manufacturerList_CheckList: string[];
  intendToBuyList_CheckList: string[];
  sicCodeList_CheckList: string[];
  stageStatusList_CheckList: string[];
  leadScoreList_CheckList: string[]
  prospectScoreList_CheckList: string[]
  endRating: number;
  startRating:number;
  filterType:number;
}


export class GetCompanyDetails{
  companyId         : string;
  companyName       : string;
  saleStageId       : string;
  typeId            : string;
  phoneNumber       : string;
  faxNumber         : string;
  emailAddress      : string;
  website           : string;
  salesPersonId     : string;
  altSalesPersonId  : string;
  buyerRepId        : string;
  contactReminder   : boolean;
  currencyId        : string;
  entityType        : string;
  timeZoneId        : string;
  billToAddress     : string;
  shipToAddress     : string;
  notes             : string;
  scopeId           : string;
  dAndBNumber       : number;
  sicCode           : string;
  naicsCode         : string;
  industryId        : string;
  tagsId            : tagsList[]=[];
  employee          : string;
  revenue           : string;
  parentCompany     : string;
  location          : string;
  isExpandable      : boolean;
  salesPersonName   : string
  altSalesPersonName: string
  // extra field added
  overallRating     : number;
  approvedVendorList: any;
  vendorSpecialty   : string;
  accountNumber     : number;
  intentToBuyName   : string;
  leadScoreName     : string;
  // endregion
}

export class tagsList{
  id        : string='';
  name      : string='';
  labelName : string='';
  colorCode : string='';
  isActive  : boolean=false;
}

export class GetAccountAddressByCompanyIdRequestModel{
  companyId   : number;
  searchText  : string='';
}

export class GetAddressVm{
      id            : number;
      companyId     : number;
      addressName   : string;
      addressType   : string;
      isDefault     : boolean;
      address1      : string;
      address2      : string;
      city          : string;
      state         : string;
      zipCode       : string;
      country       : string;
      phoneNumber   : string;
      addressVAT    : string;
      isActive      : boolean;
      fullAddress   : string;
}


export class GetPeopleByCompanyIdRequestModel{
  companyId     : string='';
  //department    : string='';
  departmentId  : string='';
  searchText    : string='';
  active        : number=1;
}


export class GetHistoryByCompanyIdRequestModel {
  companyId: string='';
  contactId: string='';
}

export class ContactDetailVm {
  contactId         : number;
  companyId         : number;
  firstName         : string;
  lastName          : string;
  fullName          : string;
  title             : string;
  location          : string;
  skype             : string;
  xing              : string;
  doNotEmail        : boolean;
  doNotCall         : boolean;
  genderName        : string;
  timeZoneName      : string;
  departmentName    : string;
  isActive            : boolean;
  markAsPrimaryContact: boolean;
  markAsAccountingContact: boolean;
  markAsPrimaryPurchasingContact: boolean;
  mainOfficeNumber   : string;
  directNumber       : string;
  phoneNumber        : string;
  mobileNumber       : string;
  genderId           : string;
  timeZoneId         : string;
  email              : string;
  linkedIn           : string;
  departmentId       : string;
  notes              : string;
 // accountId         : number;
 // gender            : string;
 // department        : string;
 // timeZone          : string;
//  email1            : string;
 // linkin            : string;
  // isPrimary         : boolean;
  // accounting        : boolean;
 // phone2Ext         : string;
 // phone1            : string;
 // phone2            : string;
  //active            : boolean;

}

export class UploadDocuments{
  documentTypeId: number=0;
  companyOrVendorId: string='';
  fileName: string='';
  file: string='';
}
export class UploadCertificationDocuments{
  documentTypeId: number=0;
  companyOrVendorId: string='';
  fileName: string='';
  file: string='';
}

export class UploadCertificationsDocuments{
  vendorId    : number=0;
 // fileName    : string;
}

export class DownloadDocuments{
  guid: string='';
  documentTypeId: number=0;
}


export class InsertCertification {
  id: string;
  vendorId: string='';
  certificationId: string;
  dateIssued: string;
  expirationDate: string;
  fileName: string;
  guid: string;
  type: string;
}

export class InsertUpdateManageManufacturer {
  id: number
  manufacturerDetails: ManufacturerDetail[]
  vendorId: string='';
  userId: string
}

export class ManufacturerDetail {
  parentId: string;
  parentName: string;
  childId:string;
  childName: string;
}



export class ActivitysRequestModel extends PaginationBaseModel{
  contactId: string='';
  statusId: string='';
  typeId: string='';
  priorityId: string='';
}

export class DeleteContactOrCompanyModel{
  ids: string[];
}
export class mailingList{
  companyId        : string='';
  companyName      : string='';
  contactId        : string='';
  contactName      : string='';
  emailAddress     : string='';
  createdBy        : string='';
  contactFirstName : string='';
  contactLastName  : string='';
}

export class ExportMailingList{
  mailingRecords: mailingList[] = [];
  mailingListId        : string;
  mailingListName      : string;
  isCreated        : boolean = false;

}


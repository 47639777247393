import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { log } from 'console';

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('/');

      let dateObj: NgbDateStruct = {  month: <any>null,day: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ?
        `${padNumber(date.month)}/${padNumber(date.day)}/${date.year || ''}` :
        '';
  }
}
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Input () inputLabel :string = 'Date';
  @Input () inputValue :any;
  @Input () inputReqValidation:boolean = false;
  @Input() time = true
  fromDate: NgbDate | null;
	toDate: NgbDate | null;
	hoveredDate: NgbDate | null = null;
  @Input() minDate: NgbDate;
  @ViewChild('dateInput') dateInput!: ElementRef;
  @ViewChild('d') dp: any;
  @ViewChild('d', { static: true }) datepicker: NgbDatepicker;
  @ViewChild('dp') datePicker: ElementRef;
  constructor(private renderer: Renderer2) { 
  }

  ngOnInit(): void {
  }

  clearInput(){
      this.inputValue = ''
  }

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}
	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}
	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}
	isStartDate(date: NgbDate) {
		return date.equals(this.fromDate);
	}
	isEndDate(date: NgbDate) {
		return date.equals(this.toDate);
	}

  selectedTime: string | null = null;

  onTimeFocus() {
    // Clear the input value when it receives focus
    this.selectedTime = '';
  }

  onTimeBlur() {
    // Reset the value to null if it's empty when the input loses focus
    if (!this.selectedTime) {
      this.selectedTime = null;
    }
  }

  ngAfterViewInit() {
    // this.renderer.setAttribute(this.dp.nativeElement, 'tabindex', '-1');
    const a =  document.getElementById('datepicker_contain')
    console.log(a)
  }

  open(){
    if(this.dateInput && this.dateInput.nativeElement){
      this.dateInput.nativeElement.focus();
    }
  }

  handleEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      // datePicker.toggle();
      this.dp.open()
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if(event.key === 'Tab' && this.dp.isOpen()) {
          this.dp.close()
    }
  }

  onTab(event: KeyboardEvent,  datepicker: NgbInputDatepicker){
    if(event.key === 'Tab') {
      datepicker.close();
    }
  }

  onTabs(event){
    console.log(event)
    // event?.preventDefault()
    this.dp.close()
  }



}

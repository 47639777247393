<div class="container-fluid mt-xl-0 mt-lg-0 mt-md-4 mt-3 container-h">
  <div class="row p-0">
    <div
      class="col-xl-12 p-0"
      *ngFor="let contactInfo of contactInfoAddList; let i = index"
    >
      <div class="row mx-0 mb-2">
        <div class="col-xl-6 col-md-6 p-0 mb-1">
          <div class="d-flex">
            <label class="text-primary-color">Contact {{ i + 1 }} </label>
            <div (click)="deleteAddress(contactInfo,i)" class="d-flex m-l-25 hand" *ngIf="i != 0">
              <app-svg-icon [src]="'./assets/SVGIcons/smDelete.svg'">
              </app-svg-icon>
              <span class="m-l-10 text-danger-color hand ">Delete</span>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 d-flex justify-content-end p-0 pr-2">
          <label
          *ngIf="i == 0"
            for=""
            class="clear-color hand mb-0 mt-lg-0 mt-md-4"
            (click)="addAnotherContact()"
            >+ Add another contact</label
          >
        </div>
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-xl-6 p-0">
                <div class="firstCol pe-2 me-5">
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'First Name'"
                      [inputType]="'text'"
                      onlyAlphabets
                      [isReset]="true"
                      [inputReqValidation]="true"
                      [inputValue]="contactInfo.firstName"
                      (inputValueEmit)="contactInfo.firstName = $event;"
                      [ngClass]="{ 'border-red': !contactInfo.firstName && submitcontcat }"
                    >
                    </app-input>
                  </div>
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Last Name'"
                      [inputType]="'text'"
                      onlyAlphabets
                      [isReset]="true"
                      [inputReqValidation]="true"
                      [inputValue]="contactInfo.lastName"
                      (inputValueEmit)="contactInfo.lastName = $event"
                      [ngClass]="{ 'border-red': !contactInfo.lastName && submitcontcat }"
                    >
                    </app-input>
                  </div>
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Title'"
                      [inputType]="'text'"
                      onlyAlphabets
                      [isReset]="true"
                      [inputValue]="contactInfo.title"                          
                      (inputValueEmit)="contactInfo.title = $event"
                    >
                    </app-input>
                  </div>
                  <div class="col mb-lg-0 p-0">
                    <!-- <app-input
                        [inputLabel]="'Gender'"
                        [inputType]="'text'"
                        [isReset]="true" 
                        onlyAlphabets 
                        [maxLen]="6"
                        [inputValue]="contactInfo.gender"
                        (inputValueEmit)="contactInfo.gender = ($event)">
                      </app-input> -->
                    <app-dropdown
                      [selectLabel]="'Gender'"
                      [data]="genderList"
                      [selectValue]="contactInfo.genderId"
                      (selectValueEmit)="contactInfo.genderId = $event"
                    >
                    </app-dropdown>
                  </div>
      
                  <div class="col p-0 mt-3">
                    <!-- <app-input
                        [inputLabel]="'Department'"
                        [inputType]="'text'"
                        onlyAlphabets 
                        [maxLen]="10"
                        [isReset]="true"
                        [inputValue]="contactInfo.name"
                        (inputValueEmit)="contactInfo.name = ($event)">
                      </app-input> -->
                    <app-dropdown
                      [selectLabel]="'Department'"
                      [data]="departmentList"
                      [selectValue]="contactInfo.departmentId"
                      (selectValueEmit)="contactInfo.departmentId = $event">
                    </app-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 p-0">
                <div class="firstCol pe-2 me-5">
                  <!-- <div class="col p-0 mb-3">
                      <app-input
                        [inputLabel]="'Department'"
                        [inputType]="'text'"
                        onlyAlphabets 
                        [maxLen]="10"
                        [isReset]="true"
                        [inputValue]="contactInfo.name"
                        (inputValueEmit)="contactInfo.name = ($event)">
                      </app-input>
                    </div> -->
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Location'"
                      [inputType]="'text'"
                      [isReset]="true"
                      [inputValue]="contactInfo.location"
                      (inputValueEmit)="contactInfo.location = $event"
                    >
                    </app-input>
                  </div>
                  <div class="col p-0 mb-3">
                    <!-- <app-input
                        [inputLabel]="'Timezone'"
                        [inputType]="'text'"
                        [maxLen]="15"
                        [isReset]="true" 
                        [inputValue]="contactInfo.timezone"
                        (inputValueEmit)="contactInfo.timezone = ($event)">
                      </app-input> -->
                    <app-dropdown
                      [selectLabel]="'Timezone'"
                      [data]="timezoneList"
                      [selectValue]="contactInfo.timeZoneId"
                      (selectValueEmit)="contactInfo.timeZoneId = $event"
                    >
                    </app-dropdown>
                  </div>
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Email'"
                      [inputType]="'text'"
                      [isReset]="true"
                      [inputMaxlength]="25"
                      [inputValue]="contactInfo.email"
                      (inputValueEmit)="contactInfo.email = $event;onEmailChange(i)"
                      [ngClass]="{ 'border-red':  isEmailInvalid && submitcontcat}"
                    >
                    </app-input>
                  </div>
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Main Office Number + Extension'"
                      [inputType]="'text'"
                      [isReset]="true"
                      onlyNumbers
                      [inputMaxlength]="14"
                      [inputValue]="contactInfo.mainOfficeNumber"
                      (inputValueEmit)="contactInfo.mainOfficeNumber = $event"
                    >
                    </app-input>
                  </div>
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Direct Number'"
                      [inputType]="'text'"
                      onlyNumbers
                      [inputMaxlength]="14"
                      [inputValue]="contactInfo.directNumber"
                      (inputValueEmit)="contactInfo.directNumber = $event"
                      [isReset]="true"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-xl-6 p-0">
                <div class="thirdCol pe-2 me-5">
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Mobile Number'"
                      [inputType]="'text'"
                      onlyNumbers
                      [inputMaxlength]="14"
                      [inputValue]="contactInfo.mobileNumber"
                      (inputValueEmit)="contactInfo.mobileNumber = $event"
                      [isReset]="true"
                    >
                    </app-input>
                  </div>
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Fax Number'"
                      [inputType]="'text'"
                      [isReset]="true"
                      onlyNumbers
                      [inputMaxlength]="10"
                      [inputValue]="contactInfo.faxNumber"
                      (inputValueEmit)="contactInfo.faxNumber = $event"
                    >
                    </app-input>
                  </div>
      
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Skype'"
                      [inputType]="'text'"
                      [inputValue]="contactInfo.skype"
                      [inputMaxlength]="25"
                      (inputValueEmit)="contactInfo.skype = $event">
                    </app-input>
                  </div>
                  <div class="col p-0 mb-3">
                    <app-input
                      [inputLabel]="'Xing'"
                      [inputType]="'text'"
                      [inputValue]="contactInfo.xing"
                      [inputMaxlength]="25"
                      (inputValueEmit)="contactInfo.xing = $event">
                    </app-input>
                  </div>
                  <div class="col p-0">
                    <app-input
                      [inputLabel]="'LinkedIn'"
                      [inputType]="'text'"
                      [inputValue]="contactInfo.linkedIn"
                      [inputMaxlength]="25"
                      (inputValueEmit)="contactInfo.linkedIn = $event">
                    </app-input>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 p-0  mt-xl-0 mt-md-3">
                <div class="fourthCol">
                  <div class="col-xl-12 mb-1 p-0">
                    <div class="d-flex checkbox-solid-info show-inactive">
                      <app-checkbox
                        class="hand"
                        [status]="contactInfo.markAsPrimaryContact"
                        [lableClass]="'text-light-gray font-12 m-0'"
                        (check)="
                          contactInfo.markAsPrimaryContact = $event;
                          contactInfo.markAsPrimaryContact  
                            ? openReplacePrimaryPopup(contactInfo,i)
                            : ''
                        "
                        [lableName]="'Mark as Primary Contact'"
                        [dyanamicId]="'Mark as Primary Contact' + i"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="col-xl-12 mb-1 p-0">
                    <div class="d-flex checkbox-solid-info show-inactive">
                      <app-checkbox
                        [lableClass]="'text-light-gray font-12 m-0'"
                        [status]="contactInfo.markAsAccountingContact"
                        (check)="contactInfo.markAsAccountingContact = $event"
                        [lableName]="'Mark as Accounting Contact'"
                        [dyanamicId]="'Mark as Accounting Contact' + i"
                      ></app-checkbox>
                    </div>
                  </div>
                  <div class="col-xl-12 mb-1 p-0">
                    <div class="d-flex checkbox-solid-info show-inactive">
                      <app-checkbox
                        [lableClass]="'text-light-gray font-12'"
                        [status]="contactInfo.doNotCall"
                        (check)="contactInfo.doNotCall = $event"
                        [lableName]="'Do Not Call '"
                        [dyanamicId]="'Do Not Call ' + i"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="col-xl-12 mb-1 p-0">
                    <div class="d-flex checkbox-solid-info show-inactive">
                      <app-checkbox
                        [lableClass]="'text-light-gray font-12'"
                        [status]="contactInfo.markAsPrimaryPurchasingContact"
                        (check)="contactInfo.markAsPrimaryPurchasingContact = $event"
                        [lableName]="'Mark as Primary Purchasing Contact'"
                        [dyanamicId]="'Mark as Primary Purchasing Contact' + i"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <!-- <div class="col-xl-12 mb-1 p-0">
                    <div class="d-flex checkbox-solid-info show-inactive">
                      <app-checkbox
                        [lableClass]="'text-light-gray font-12'"
                        [status]="contactInfo.doNotCall"
                        (check)="contactInfo.doNotCall = $event"
                        [lableName]="'Do Not Call '"
                        [dyanamicId]="'Do Not Call ' + i"
                      >
                      </app-checkbox>
                    </div>
                  </div> -->
                  <div class="col-xl-12 mb-3 p-0">
                    <div class="d-flex checkbox-solid-info show-inactive" *ngIf="contactHeadName == 'Add Company' || contactHeadName==''">
                      <app-checkbox
                        [lableClass]="'text-light-gray font-12'"
                        [status]="contactInfo.doNotEmail"
                        (check)="contactInfo.doNotEmail = $event"
                        [lableName]="'Do Not Email '"
                        [dyanamicId]="'Do Not Email ' + i"
                      >
                      </app-checkbox>
                    </div>
                  </div> 
      
                  <div class="col-xl-12 noteWidth col-md-8 p-0">
                    <div class="d-flex flex-column position-relative me-4 addNote">
                      <label
                        class="form-label position-absolute addLabel font-12"
                        for=""
                      >
                        Notes
                      </label>
                      <textarea
                        name="note"
                        rows="3"
                        class="form-control mx-3 mt-4 pt-1 font-13 Textarea"
                        [(ngModel)]="contactInfo.notes"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

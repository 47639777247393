import { Component, OnInit } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'app-custome-tooltip',
  templateUrl: './custome-tooltip.component.html',
  styleUrls: ['./custome-tooltip.component.scss']
})
export class CustomeTooltipComponent implements OnInit, ITooltipAngularComp
 {
dataToShow:string = ''
  constructor() { }
  agInit(params: ITooltipParams<any, any>): void {
    this.dataToShow = params.value
  }

  ngOnInit() {
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() checkboxResize: boolean = false;
  @Input() default: boolean = false;
  @Input() status: boolean;
  @Input() readonly: boolean=false;
  @Input() isSelectedAdmin: boolean;
  @Output() check:EventEmitter<boolean>=new EventEmitter() ;
  @Input() lableName:string;
  @Input() lableClass:string;
  @Input() dyanamicId:string;
  @Input() dynamicName:string;

  isTrue = false;
  constructor() { }

  ngOnInit(): void {
  }
  cliked(event){
    this.isTrue = event
  }

}

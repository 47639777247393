import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Button, PopUpTitles } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {
  @Input() fullName: string;
  @Input() title: string;
  enumButtonName = Button;
  enumPopUpTitles = PopUpTitles;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}

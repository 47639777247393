import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custome-rating',
  templateUrl: './custome-rating.component.html',
  styleUrls: ['./custome-rating.component.scss']
})
export class CustomeRatingComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<string>();
  @Input() value
  @Input() isDisabled = false
  ratingStarList =[
  {id:'heart5', value:5 , },
  {id:'heart4.5', value:4.5 , className:'halff'},
  {id:'heart4', value:4 , },
  {id:'heart3.5', value:3.5, className:'halff'},
  {id:'heart3', value:3 , },
  {id:'heart2.5', value:2.5, className:'halff'},
  {id:'heart2', value:2 , },
  {id:'heart1.5', value:1.5 , className:'halff'},
  {id:'heart1', value:1 , },
  {id:'heart0.5', value:0.5 , className:'halff'},
]

  constructor() { }

  ngOnInit(): void {
  }

  onRatingChange(value){
    this.newItemEvent.emit(value);
    console.log(value)
  }
  

}

<app-step-progressbar>
  <app-progress-step>
    <app-import-tab></app-import-tab>
  </app-progress-step>
  <app-progress-step>
    <app-map-tab></app-map-tab>
  </app-progress-step>
  <app-progress-step>
    <app-details-tab></app-details-tab>
  </app-progress-step>
  <app-progress-step>
    <app-preview-tab></app-preview-tab>
  </app-progress-step>
  <app-progress-step>
    <app-errors-tab></app-errors-tab>
  </app-progress-step>
  <app-progress-step>
    <app-summary-tab></app-summary-tab>
  </app-progress-step>
</app-step-progressbar>
import { PaginationBaseModel } from "../../../../../../shared/model/common.model";

export class ActivityTab {}

export class ActivityRequestModel extends PaginationBaseModel {
  id: string='';
  assignee: string='';
  //activityTypeId: string='';
  priorityId: string='';
  contactId: string='';
  fromDate: string='';
  endDate: string='';
  companyId: string='';
  taskTypeId: string='';

}

export class ActivityLogPostModel extends PaginationBaseModel {
  id: number;
  sortedBy: string;
  typeId: string = "";
  priorityId: string = "";
  contactId: string = "";
  assignee: string = "";
  fromDate: string = "";
  endDate: string = "";
  companyId: string;
}
export class ActivityLogResponseModel {
  todaysActivity: WeekList[]
  thisWeekActivity: WeekList[]
  thisMonthActivity: WeekList[]
  activityDateRangeList: WeekList[]
}

export class TaskDetial {
  address: string;
  aging: string;
  assignedToUserId: string;
  assignedUserName: string;
  companyId: string;
  companyName: string;
  companyType: string;
  contactId: string;
  contactName: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  description: string;
  directNumber: string;
  dueDate: string;
  dueDateInFormat: string;
  dueTime: string;
  email: string;
  id: number;
  location: string;
  mainOfficeNumber: string;
  mobileNumber: string;
  priority: string;
  priorityId: string;
  status: string;
  statusId: string;
  // taskComments: TaskComment[];
  activityComments: TaskComment[];
  timeZone: string;
  title: string;
  type: string;
  typeId: string;
}

export class TaskComment {
  comment: string;
  commentId: number;
  createdAt: string;
  //taskId: number;
  activityId: string;
  updatedAt: string;
  userId: string;
  userName: string;
  isTooltip:boolean
}
export class WeekList {
  id: number;
  title: string;
  description: string;
  priority: string;
  status: string;
  type: string;
  assignedToUserId: string;
  assignedUserName: string;
  completedBy: string;
  contactName: string;
  contactId: string;
  completedByName: string;
  dueDate: string;
  dueDateInFormat: string;
}

export enum ActivityType {
  CALL = "Call",
  EMAIL = "Email",
  FILE = "File",
  NOTE = "Note",
  MEETING = "Meeting",
  TASK = "Task",
  REMINDER = "Reminder",
  QUOTES = "Quote",
}

export class GetConatactIdRequestModel{
  companyId: string='';
  departmentId: string='';
  searchText: string='';
  active: number=0;
}
<div class="modal-content">
  <div class="modal-header px-4 pb-0 pt-4 mt-2 mx-2 border-0">
    <div class="site-wide-primary">
      <h6 class="modal-title font-15">{{companySettingHeading.COMPANY_SETTINGS}}</h6>
      <p class="font-12 text-light-info m-t-5 mb-0">
        Enter your company’s information.
      </p>
    </div>
  </div>
  <!-- Modal Header End -->
  <div class="modal-body px-4 mx-2 pb-0 mt-2">
    <div class="row">
      <div class="col-4">
        <div class="mb-4">
          <app-input
          [inputLabel]="'Company Name'"
          [inputType]="'text'"
          [inputValue]="companySettingData.companyName"
          (inputValueEmit)="companySettingData.companyName = $event"
          [inputReqValidation]="true"
          [ngClass]="{'border-red': !companySettingData.companyName && submit}"

        >
        </app-input>
  
        </div>
        <div class="mb-4">
          <app-input
          [inputLabel]="'Email Address'"
          [inputType]="'email'"
          [inputMaxlength]="25"
          [inputValue]="companySettingData.email"
          (inputValueEmit)="companySettingData.email = $event;onEmailChange()"
          [ngClass]="{'border-red': isEmailInvalid && submit}"
        >
        </app-input>
        </div>
        <div class="mb-4">
          <app-input
          [inputLabel]="'Phone Number'"
          [inputType]="'text'"
          [inputValue]="companySettingData.phoneNumber"
          (inputValueEmit)="companySettingData.phoneNumber = $event"
          [inputMaxlength]="14"
          onlyNumbers
        >
        </app-input>
        </div>
      </div>
      <div class="col-4">
        <div class="mb-4">
          <app-input
          [inputLabel]="'Address Line 1'"
          [inputType]="'text'"
          [inputValue]="companySettingData.addressLine1"
          (inputValueEmit)="companySettingData.addressLine1 = $event"
        >
        </app-input>
        </div>
        <div class="mb-4">
          <app-input
          [inputLabel]="'Address Line 2'"
          [inputType]="'text'"
          [inputValue]="companySettingData.addressLine2"
          (inputValueEmit)="companySettingData.addressLine2 = $event"
        >
        </app-input>
        </div>
        <div class="mb-4">
          <app-dropdown
          [selectLabel]="'Country'"
          [data]="countryList"
          [selectValue]="companySettingData.country"
          (selectValueEmit)="companySettingData.country = $event"
          [required]="false"
        >
        </app-dropdown>
        </div>
      </div>
      <div class="col-4">
        <div class="mb-4">
          <app-dropdown
          [selectLabel]="'State/Province'"
          [data]="stateList"
          [selectValue]="companySettingData.state"
          (selectValueEmit)="companySettingData.state = $event"
          [required]="false"
        >
        </app-dropdown>
        </div>
         <div class="mb-4">
          <app-input
          [inputLabel]="'Postal Code'"
          [inputType]="'text'"
          [inputValue]="companySettingData.postalCode"
          (inputValueEmit)="companySettingData.postalCode = $event"
        >
        </app-input>
        </div>
        <div class="mb-4">
          <app-input
          [inputLabel]="'City'"
          [inputType]="'text'"
          [inputValue]="companySettingData.city"
          (inputValueEmit)="companySettingData.city = $event"
        >
        </app-input>
        </div>


      </div>
    </div>
  </div>
  <!-- Modal body End -->
  <div class="modal-footer px-4 mx-2 border-0 mb-3">
    <app-button
      style="margin-right: 13px"
      [button_2]="true"
      [buttonClass]="'button button-info font-11'"
      [buttonLable]="enumButtonName.CANCEL"
      (click)="activeModal.close()"
    >
    </app-button>
    <app-button
      style="margin-right: 0;"
      [button_2]="true"
      [buttonClass]="'button button-primary font-11'"
      [buttonLable]="enumButtonName.SAVE"
      (click)="insertUpdateCompanySettingData()"
    >
    </app-button>
  </div>
  <!-- Modal Footer End -->
</div>

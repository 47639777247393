<div class="col d-flex align-items-center gap-1">
  <div class="col-sm-12">
    <div class="wrapper wrapper-2">
      <input #flatpickrInput type="text" class="flatpick" placeholder=" ">
      <span class="calendar-icon" (click)="openDatepicker()">
        <img src="../../../../assets/SVGIcons/calendar.svg" alt="">
      </span>
      <label
      for="date"
      class="label1"
      [ngClass]="{ label2: selectedDate !== '' }"
    >
      Date
      <i class="fst-normal ps-1 p-0 text-danger"></i>
    </label>
  </div>
  <span
    [ngClass]="{ 'd-none': selectedDate == '' }"
    (click)="clear()"
    class="refresh-icon"
    ><app-svg-icon
      class="svgIcon"
      [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
    ></app-svg-icon
  ></span>
    </div>
  </div>

<div>
    <div class="modal-header border-0 px-4 mx-2 pb-2 pt-4 mt-2 justify-content-start">
    </div>
    <div class="modal-body px-4 mx-2">
      <div class="text-center">
        <app-svg-icon 
        class="mt-3 mb-3 justify-content-center text-center"
        [src]="'../../../../assets/SVGIcons/uploadesuccess.svg'"
        ></app-svg-icon>
        <div  class="font-15 text-semi-primary mb-2">Records Added</div>
        <div   class="font-15 text-gray mb-4">Records are added to your  <br>
            mailing list.</div>    
        <div class="font-13 text-light-gray mb-3">Added Records: {{record}} Companies</div>
        <div>
      </div>
    </div>
    <div class="modal-footer border-0 px-4 mx-2 pb-4 justify-content-center">
        <app-button 
          [buttonType]="'submit'"  
          [button_2]="true"  
          [buttonClass]="'button button-primary'" 
          (click)="applyChanges();activeModal.close()" 
          [buttonLable]="enumButtonName.OKAY">
      </app-button>
    </div>
  </div>
  
export class Pagination {
}

export class PaginationModel{
    sortColumn      : string='';
    sortDirection   : string='';
    pageNumber      : number=1;
    pageSize        : number=10;
    searchText      : string='';
    getAll          : boolean=false;
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-common-part-info-expanded-tab',
  templateUrl: './common-part-info-expanded-tab.component.html',
  styleUrls: ['./common-part-info-expanded-tab.component.scss']
})
export class CommonPartInfoExpandedTabComponent implements OnInit {
  @Input() infoDetails: any;
  @Output() expanded:EventEmitter<any> = new EventEmitter();
  @Input() isContactReminder: boolean;
  @Input() label1: string ;
  @Input() label2: string ;
  @Input() label3: string ;
  @Input() label4: string ;
  // @Input() label5: string ;
  // @Input() label6: string ;
//  @Input() salespersons: string[];
 // @Input() isVendor: boolean;

  constructor() { }

  ngOnInit(): void {
  }
  toggleIcon(value:boolean) {
    this.isContactReminder = !this.isContactReminder;
    this.expanded.emit(value)
  }
}

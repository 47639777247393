<div class="container-fluid px-0 container-h">
  <div class="col-12 p-0">&nbsp;</div>
  <form class="theme-form needs-validation novalidate">
    <div class="row mx-0 d-flex">
    <div class="col-xl-6 col-md-6 p-0">
      <div class="row d-flex mx-0">
          <!-- first col section-->
        <div class="p-0 col-xl-6">
          <div class="pe-2 me-5">
            <div class="col-xl-12 p-0 mb-3">
              <app-input
                [inputLabel]="headingNames == enumPopupHeadingNames.ADDCOMPANY ||
                  headingNames == ''? 'Company Name': 'Vendor Name'"
                [inputType]="'text'"
                [inputValue]="headingNames == enumPopupHeadingNames.ADDCOMPANY ||
                  headingNames == ''? insertUpdateGeneralInfoCompany.generalInfo.companyName
                    : insertUpdateGeneralInfoCompany.generalInfo.vendorName"
                (inputValueEmit)="headingNames == enumPopupHeadingNames.ADDCOMPANY ||
                  headingNames == ''? (insertUpdateGeneralInfoCompany.generalInfo.companyName =$event)
                    : (insertUpdateGeneralInfoCompany.generalInfo.vendorName =$event);tabNext()"
                [ngClass]="{'border-red':headingNames == enumPopupHeadingNames.ADDCOMPANY 
                      ? !insertUpdateGeneralInfoCompany.generalInfo.companyName && submit
                      : !insertUpdateGeneralInfoCompany.generalInfo.vendorName && submit2
                }"
                [inputReqValidation]="true"
                (parentFun)="onKeydown($event, '#email')"
              >
              </app-input>
              <!-- <app-input
              *ngIf="headingNames== enumPopupHeadingNames.ADDVENDOR"
              [inputLabel]="'Vendor Name'"
              [inputType]="'text'"
              [ngClass]="{'border-red': headingNames== enumPopupHeadingNames.ADDCOMPANY ? (!insertUpdateGeneralInfoCompany.generalInfo.vendorName && submit): (!insertUpdateGeneralInfoCompany.generalInfo.vendorName && submit2)}"
              [inputReqValidation]="true"
              onlyAlphabets
              [inputValue]="insertUpdateGeneralInfoCompany.generalInfo.vendorName"
              (inputValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.vendorName = $event"
              (parentFun)="onKeydown($event, '#email')">
            </app-input> -->
            </div>
            <div class="col-xl-12 p-0 mb-3">
              <app-input
                [inputLabel]="'Email Address'"
                [inputType]="'email'"
                [inputMaxlength]="25"
                [inputValue]="insertUpdateGeneralInfoCompany.generalInfo.emailAddress"
                (inputValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.emailAddress =$event;onEmailChange()"
                (parentFun)="onKeydown($event, '#phoneNumber')"
                [ngClass]="{'border-red': (headingNames === enumPopupHeadingNames.ADDCOMPANY || headingNames === enumPopupHeadingNames.ADDVENDOR) &&
                    (isEmailInvalid && submit) || (isEmailInvalid && submit2)}"
              >
              </app-input>
            </div>
            <div class="col-xl-12 p-0 mb-3">
              <app-input
                [inputLabel]="'Phone Number'"
                [inputType]="'text'"
                onlyNumbers
                [inputMaxlength]="14"
                [inputValue]="insertUpdateGeneralInfoCompany.generalInfo.phoneNumber"
                (inputValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.phoneNumber = $event;tabNext()"
                (parentFun)="onKeydown($event, '#faxNumber')"
              >
              </app-input>
            </div>
            <div class="col-xl-12 p-0 mb-3">
              <app-input
                [inputLabel]="'Fax Number'"
                [inputType]="'text'"
                onlyNumbers
                [inputMaxlength]="10"
                [inputValue]="insertUpdateGeneralInfoCompany.generalInfo.faxNumber"
                (inputValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.faxNumber = $event "
              >
              </app-input>
            </div>
            <div class="col-xl-12 p-0 mb-3">
              <app-dropdown
                [selectLabel]="generalInfoLables?.STATE"
                [data]="stateList"
                [selectValue]="insertUpdateGeneralInfoCompany.generalInfo.stateId"
                (selectValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.stateId = $event "
                [required]="false"
              >
              </app-dropdown>
            </div>
            <div class="col-xl-12 p-0 mb-3">
              <app-dropdown
                [selectLabel]="generalInfoLables?.COUNTRY"
                [data]="countryList"
                [selectValue]="insertUpdateGeneralInfoCompany.generalInfo.countryId"
                [ngClass]="{'border-red':headingNames == enumPopupHeadingNames.ADDVENDOR ||  headingNames == enumPopupHeadingNames.EDITVENDOR
                      ? !insertUpdateGeneralInfoCompany.generalInfo.countryId && submit2 : ''}"
                (selectValueEmit)=" insertUpdateGeneralInfoCompany.generalInfo.countryId =$event;tabNext()"
                [required]=" headingNames == enumPopupHeadingNames.ADDVENDOR ||  headingNames == enumPopupHeadingNames.EDITVENDOR"
              >
              </app-dropdown>
            </div>
            <div class="col-xl-12 p-0 mb-3">
              <app-input
                [inputLabel]="'Website'"
                [inputType]="'text'"
                [inputValue]="insertUpdateGeneralInfoCompany.generalInfo.website"
                (inputValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.website = $event;onWebsiteChange()"
                [ngClass]="{ 'border-red': (headingNames === enumPopupHeadingNames.ADDCOMPANY || headingNames === enumPopupHeadingNames.ADDVENDOR) &&
                        (isWebsiteInvalid && submit) || (isWebsiteInvalid && submit2)}"
              >
              </app-input>
            </div>
          </div>
        </div>
          <!-- second col section-->
        <div class="p-0 col-xl-6">
          <div class="pe-2 me-5">
            <div class="col-xl-12 p-0 mb-3">
              <app-dropdown
                [selectLabel]="generalInfoLables?.TIME_ZONE"
                [data]="timezoneList"
                [selectValue]="insertUpdateGeneralInfoCompany.generalInfo.timeZoneId"
                (selectValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.timeZoneId = $event "
                [required]="false"
              >
              </app-dropdown>
            </div>
            <div class="col-xl-12 p-0 mb-3">
              <!-- [optionDisable]="insertUpdateGeneralInfoCompany.generalInfo.salesPerson2UserId" -->
              <app-dropdown
                [selectLabel]="generalInfoLables?.SALESPERSON"
                [disabled]="(headingNames === enumPopupHeadingNames.ADDVENDOR || getEditName === enumPopupHeadingNames.EDITVENDOR) &&
               !(insertUpdateGeneralInfoCompany.generalInfo.markAsCompany && typeName)"
                [data]="salesPersonList"
                [optionDisable]="insertUpdateGeneralInfoCompany.generalInfo.altSalesPersonId"
                [selectValue]=" headingNames == enumPopupHeadingNames.ADDCOMPANY ||
                  getEditName == this.enumPopupHeadingNames.EDITCOPANY ? insertUpdateGeneralInfoCompany.generalInfo.salesPersonId: '' "
                [ngClass]="{'border-red':headingNames == enumPopupHeadingNames.ADDCOMPANY ? !insertUpdateGeneralInfoCompany.generalInfo
                          .salesPersonId && submit : ''}"
                (selectValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.salesPersonId =$event;;tabNext()"
                [required]="headingNames == enumPopupHeadingNames.ADDCOMPANY ||getEditName == this.enumPopupHeadingNames.EDITCOPANY "
              >
              </app-dropdown>
            </div>
            <div class="col-xl-12 p-0 mb-3">
              <app-dropdown
                [disabled]="(headingNames === enumPopupHeadingNames.ADDVENDOR || getEditName === enumPopupHeadingNames.EDITVENDOR) &&
               !(insertUpdateGeneralInfoCompany.generalInfo.markAsCompany && typeName) "
                [selectLabel]="generalInfoLables?.ALT_SALESPERSON"
                [data]="altSalesPersonList"
                [optionDisable]=" insertUpdateGeneralInfoCompany.generalInfo.salesPersonId"
                [selectValue]=" headingNames == enumPopupHeadingNames.ADDCOMPANY ||getEditName == this.enumPopupHeadingNames.EDITCOPANY 
                    ? insertUpdateGeneralInfoCompany.generalInfo .altSalesPersonId : ''"
                (selectValueEmit)=" insertUpdateGeneralInfoCompany.generalInfo.altSalesPersonId =$event  "
                [required]="false"
              >
              </app-dropdown>
            </div>
            <div class="col-xl-12 p-0 mb-3">
              <app-dropdown
                [selectLabel]="generalInfoLables?.BUYERS_REP"
                [data]="buyerRepList"
                [selectValue]="insertUpdateGeneralInfoCompany.generalInfo.buyerRepId"
                (selectValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.buyerRepId =$event "
                [required]="false"
              >
              </app-dropdown>
            </div>
           <div class="col-xl-12 p-0 mb-3" 
              *ngIf=" insertUpdateGeneralInfoCompany.generalInfo.markAsCompany ==true && typeName"
            >
              <app-dropdown
                [selectLabel]="'Link Manufacturer'"
                [data]="manufacturerList"
                [selectValue]="insertUpdateGeneralInfoCompany.generalInfo.manufacturerId "
                (selectValueEmit)="insertUpdateGeneralInfoCompany.generalInfo.manufacturerId = $event"
                [ngClass]="{'border-red':!insertUpdateGeneralInfoCompany.generalInfo.manufacturerId && submit3}"
                [required]="true"
              >
              </app-dropdown>
            </div> 
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-6 p-xl-0 p-md-2">
      <div class="row d-flex mx-0">
      <div class="col-xl-6 p-0">
        <div class="row mx-0 p-0 mt-lg-0 mt-md-3">
          <div class="col-12 p-0 mb-2">
            <label for="" class="label-color"> Sales Stage:
              <span class="required-feild mb-1"
                *ngIf="headingNames == enumPopupHeadingNames.ADDCOMPANY" >*</span>
            </label>
            <div class="col ms-2 p-0"> 
              <div class=""
                [ngClass]="{ disableradiobtn: headingNames == enumPopupHeadingNames.ADDVENDOR }"
              >
                <div  class="radio radio-p"
                  *ngFor="let saleItem of saleStageList; let i = index" >
                  <input
                    *ngIf="saleItem.name != 'Vendor'"
                    type="radio"
                    name="radiogroup"
                    [value]="saleItem.id"
                    required
                    [disabled]=" headingNames == enumPopupHeadingNames.ADDVENDOR ||
                      getEditName == this.enumPopupHeadingNames.EDITVENDOR"
                    [checked]="saleItem.id == stageID"
                    [id]="'stage' + saleItem.id"
                    (change)="onSelectionChange(saleItem);tabNext()"
                  />
                  <label
                    *ngIf="saleItem.name != 'Vendor'"
                    for="{{ 'stage' + saleItem.id }}"
                    class="form-check-label"
                    [disabled]=" headingNames == enumPopupHeadingNames.ADDVENDOR ||
                      getEditName == this.enumPopupHeadingNames.EDITVENDOR "
                    [ngClass]="{ notallow:headingNames == enumPopupHeadingNames.ADDVENDOR }"
                  > {{ saleItem.name }}</label>
                </div>
              </div>
            </div>
          </div>
          <!-- Lead Stage -->
          <div  *ngIf="stageID == 2" class="col-12 p-0 mb-2" >
            <label for="" class="label-color">Lead Score<span class="required-feild mb-1">*</span></label>
            <div class="col ms-2 p-0">
              <div class="">
                <div   class="radio radio-p"
                  *ngFor="let leadItem of leadsList; let i = index"
                >
                  <input
                    type="radio"
                    name="radiogroups"
                    [value]="leadItem.id"
                    required
                    [(ngModel)]=" insertUpdateGeneralInfoCompany.generalInfo.leadScoreId"
                    [id]="leadItem.name"
                    (change)="tabNext()"
                  />
                  <label for="{{ leadItem.name }}" class="form-check-label">
                    {{ leadItem.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div   *ngIf="stageID == 3"  class="col-12 p-0 mb-2" >
            <label for="" class="label-color"> Intent to Buy <span class="required-feild mb-1">*</span>
            </label>
            <div class="col ms-2 p-0">
              <div class="">
                <div  class="radio radio-p"
                  *ngFor="let leadItems of intendToBuyList; let i = index"
                >
                  <input
                    type="radio"
                    name="radiogroups"
                    [value]="leadItems.id"
                    required
                    [(ngModel)]="  insertUpdateGeneralInfoCompany.generalInfo.intentToBuyId"
                    [id]="leadItems.name"
                    (change)="tabNext()"
                  />
                  <label   for="{{ leadItems.name }}" class="form-check-label" >
                    {{ leadItems.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0">
            <label class="addUserLabel label-color mb-2" for="">
              Turn on Contact Reminders for this company?
            </label>
            <div class="row mx-0">
              <div   class="col-xl-6 col-lg-6 col-md-8 d-flex col-gap p-0"
                [ngClass]="{disableradiobtn: headingNames == enumPopupHeadingNames.ADDVENDOR }"
              >
                <div  class="radio radio-p m-2"
                  *ngFor="let isContactitem of isContactReminderList"
                >
                  <input
                    [id]="isContactitem.id"
                    type="radio"
                    name="radio4"
                    [value]="isContactitem.isContactReminder"
                    [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.contactReminder"
                    [disabled]=" headingNames == enumPopupHeadingNames.ADDVENDOR || getEditName == this.enumPopupHeadingNames.EDITVENDOR"
                  />
                  <label
                    for="{{ isContactitem.id }}"
                    class="form-check-label"
                    [disabled]=" headingNames == enumPopupHeadingNames.ADDVENDOR ||
                      getEditName == this.enumPopupHeadingNames.EDITVENDOR"
                    >{{ isContactitem.name }}
                  </label>
                </div>
              </div>
              <div class="col-xl-12 p-0 mb-2 mt-2">
                <!-- <div class="d-flex show-inactive mb-2"> -->
                <app-checkbox
                  [lableClass]="'text-light-gray '"
                  (change)="insertUpdateGeneralInfoCompany.generalInfo.doNotCall =!insertUpdateGeneralInfoCompany.generalInfo.doNotCall "
                  [status]="insertUpdateGeneralInfoCompany.generalInfo.doNotCall "
                  [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.doNotCall"
                  [lableName]="'Do Not Call'"
                  [dyanamicId]="'donotcall'"
                  [readonly]=" headingNames == enumPopupHeadingNames.ADDVENDOR ||getEditName == this.enumPopupHeadingNames.EDITVENDOR"
                  [isSelectedAdmin]="headingNames == enumPopupHeadingNames.ADDVENDOR ||
                    getEditName == this.enumPopupHeadingNames.EDITVENDOR "
                >
                </app-checkbox>
              </div>
              <!-- <div class="col-xl-12 p-0 mb-2"
                *ngIf="insertUpdateGeneralInfoCompany.generalInfo.markAsCompany == true && marrkascompany "
              >
                <app-checkbox
                  [lableClass]="'text-light-gray hand'"
                  [lableName]="'Do Not Email'"
                  [dyanamicId]="'donotemail'"
                  [status]="insertUpdateGeneralInfoCompany.generalInfo.doNotEmail "
                  (change)=" insertUpdateGeneralInfoCompany.generalInfo.doNotEmail = !insertUpdateGeneralInfoCompany.generalInfo.doNotEmail"
                  [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.doNotEmail "
                >
                </app-checkbox>
              </div> -->
              <div  class="col-xl-12 p-0 mb-2"
                *ngIf=" headingNames !== enumPopupHeadingNames.ADDVENDOR ||showvendor "
              >
                <!-- <app-checkbox
                  [lableClass]="'text-light-gray hand'"
                  [lableName]="'Mark as Vendor'"
                  [status]="insertUpdateGeneralInfoCompany.generalInfo.markAsVendor"
                  (change)="insertUpdateGeneralInfoCompany.generalInfo.markAsVendor =
                      !insertUpdateGeneralInfoCompany.generalInfo.markAsVendor;
                    getvenvalue(insertUpdateGeneralInfoCompany.generalInfo.markAsVendor)"
                  [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.markAsVendor"
                  value=""
                >
                </app-checkbox>  -->
                <app-checkbox
                  [lableClass]="'text-light-gray hand'"
                  [lableName]="'Mark as Vendor'"
                  [status]="insertUpdateGeneralInfoCompany.generalInfo.markAsVendor"
                  (change)="insertUpdateGeneralInfoCompany.generalInfo.markAsVendor =
                      !insertUpdateGeneralInfoCompany.generalInfo.markAsVendor; 
                      newGetvenvalue(insertUpdateGeneralInfoCompany.generalInfo.markAsVendor)"
                  [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.markAsVendor"
                  value=""
                >
                </app-checkbox>
              </div>
             <div 
                class="col-xl-12 p-0 mb-2"
                *ngIf=" insertUpdateGeneralInfoCompany.generalInfo.markAsVendor ==true"
              >
                <app-checkbox
                  value=""
                  [lableClass]="'text-light-gray hand'"
                  [lableName]="'Exclude from vendor list'"
                  [status]="insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Vendor_List "
                  (change)="insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Vendor_List =!insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Vendor_List "
                  [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Vendor_List "
                >
                </app-checkbox>
              </div> 
              <div class="col-xl-12 p-0 mb-2"
                *ngIf=" headingNames == enumPopupHeadingNames.ADDVENDOR && marrkascompany "
              >
                <!-- <app-checkbox
                  value=""
                  [lableClass]="'text-light-gray hand'"
                  [lableName]="'Mark as Company'"
                  [status]="insertUpdateGeneralInfoCompany.generalInfo.markAsCompany "
                  (change)="insertUpdateGeneralInfoCompany.generalInfo.markAsCompany =!insertUpdateGeneralInfoCompany.generalInfo.markAsCompany;
                    getMakascompanyValue(insertUpdateGeneralInfoCompany.generalInfo.markAsCompany)"
                  [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.markAsCompany "
                >
                </app-checkbox> -->
                <app-checkbox
                value=""
                [lableClass]="'text-light-gray hand'"
                [lableName]="'Mark as Company'"
                [status]="insertUpdateGeneralInfoCompany.generalInfo.markAsCompany "
                (change)="insertUpdateGeneralInfoCompany.generalInfo.markAsCompany =!insertUpdateGeneralInfoCompany.generalInfo.markAsCompany;
                newGetMakascompanyValue(insertUpdateGeneralInfoCompany.generalInfo.markAsCompany)"
                [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.markAsCompany "
              >
              </app-checkbox>

              </div>
              <div
                class="col-xl-12 p-0 mb-2"
                *ngIf="insertUpdateGeneralInfoCompany.generalInfo.markAsCompany == true"
              >
                <app-checkbox
                  [lableClass]="'text-light-gray hand'"
                  [lableName]="'Exclude from Company List'"
                  [status]="insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Company_List "
                  (change)="insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Company_List =!insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Company_List;"
                  [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Company_List "
  
                >
                </app-checkbox>
              </div> 
              <div
                class="col-xl-12 p-0"
                *ngIf=" headingNames == enumPopupHeadingNames.ADDVENDOR && marrkascompany ||
                 headingNames == enumPopupHeadingNames.ADDCOMPANY 
                 "
              >
                <app-checkbox
                  value=""
                  [status]="insertUpdateGeneralInfoCompany.generalInfo.approvedVendorList "
                  (change)="insertUpdateGeneralInfoCompany.generalInfo.approvedVendorList =!insertUpdateGeneralInfoCompany.generalInfo.approvedVendorList"
                  [(ngModel)]="insertUpdateGeneralInfoCompany.generalInfo.approvedVendorList"
                  [lableClass]="'text-light-gray hand'"
                  [lableName]="'Approved Vendor List (AVL)'"
                >
                </app-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 all-filter-font p-0">
        <div class="row mx-0 mt-xl-0 mt-lg-3">
          <div class=" mt-md-2 mt-lg-0 p-0 pe-xl-0">
            <div class="row mx-0">
              <div class="col-12 d-flex justify-content-between p-0">
                <div class="d-flex">
                  <div class="text-light-gray nowrap me-4">
                    <!-- {{  addRating.rating }} -->
                    {{currentTab === "companies"?"Company Rating ":addRating.rating}}
                    <!-- {{currentTab === "vendor-list"?"Vendor Rating":filterPopupVM.companyRating }}: -->
                    <!-- <div> {{currentTab === "vendor-list"?"Vendor Rating":filterPopupVM.companyRating }}:</div> -->
                  </div>
                  <div class="text-primary-color nowrap font-11">
                    {{
                      insertUpdateGeneralInfoCompany.generalInfo.overallRating > 0
                        ? insertUpdateGeneralInfoCompany.generalInfo.overallRating + " Stars ": "No stars"
                    }}
                  </div>
                </div>
                <div class="nowrap p-0">
                  <div  class="hand d-flex clear-color"
                    (click)="insertUpdateGeneralInfoCompany.generalInfo.overallRating = 0"
                  >
                    <app-svg-icon
                      [src]="'../../../../../../../assets/SVGIcons/smallClaer.svg'"
                      class="d-flex align-items-center me-2"
                    >
                    </app-svg-icon>
                    <span class="pe-0 font-11">{{ addCompnyAlltext.clear }}</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 mt-1 p-0 d-flex">
                <star-rating
                  [ratingValue]="insertUpdateGeneralInfoCompany.generalInfo.overallRating"
                  (newItemEvent)="getRatingValue($event)"
                >
                </star-rating>
              </div>
            </div>
          </div>
          <!-- end range slider -->
          <div class="p-0 mb-lg-0 mb-md-3">
            <div class="col-12 pe-xl-0 pe-lg-0 mt-md-2 mt-lg-0 mt-xl-3 mt-3">
              <div class="row mx-0">
                <div class="col-12 d-flex justify-content-between text-start p-0 pe-lg-0 pe-md-0">
                  <div>
                    <span class="text-light-gray">Type:
                      <span class="required-feild mb-1">*</span>
                    </span>
                  </div>
                  <div class="text-end p-0">
                    <div class="hand d-flex justify-content-end clear-color"
                      (click)="typeId = undefined;this.companiesService.nextTab.next(false)"
                    >
                      <app-svg-icon
                        [src]="'../../../../../../../assets/SVGIcons/smallClaer.svg'"
                        class="d-flex align-items-center me-2"
                      >
                      </app-svg-icon>
                      <span class="font-11">{{ addCompnyAlltext.clear }}</span>
                    </div>
                  </div>
                </div>
                <div  class="col-12 col-xl-12 mt-2 mb-2 p-0 d-flex flex-wrap lable-div" >
                  <app-button
                    *ngFor="let items of classList; let i = index"
                    [ngClass]="{ selected: items.id == typeId }"
                    [buttonType]="'button'"
                    [button_2]="true"
                    [buttonClass]="'button-lable-primary mx-0 ps-2  text-center font-12 btn-hover'"
                    [buttonLable]="items.name"
                    (click)="selecteAccountClassId(items, i);tabNext()"
                  >
                  </app-button>
                </div>
              </div>
            </div>
            <!-- end class -->
            <div class="col-xl-12 col-lg-12 col-md-12 pe-xl-0 pe-lg-0 mt-2 mt-xl-2 mt-lg-3 px-0"
            >
              <div class="row mx-0">
                <div  class="col-12 d-flex justify-content-between p-0 text-start pe-xl-0 pe-lg-0 pe-md-0">
                  <div>
                    <span class="text-light-gray">{{ addCompnyAlltext.tags }}:</span>
                  </div>
                  <div class="text-end p-0">
                    <div class="hand d-flex justify-content-end clear-color"
                      (click)="clearFilter(tagsListData)"
                    >
                      <app-svg-icon
                        [src]="'../../../../../../../assets/SVGIcons/smallClaer.svg'"
                        class="d-flex align-items-center me-2"
                      >
                      </app-svg-icon>
                      <span class="font-11">{{ addCompnyAlltext.clear }}</span>
                    </div>
                  </div>
                </div>
                <div  class="col-12 p-0 mt-2 d-flex flex-wrap lable-div">
                  <app-button
                    *ngFor="let tags of tagsListData; let i = index"
                    [ngClass]="tags.selectedTags ? 'selected' : ''"
                    [buttonType]="'button'"
                    [button_2]="true"
                    [spanClass]="''"
                    [buttonClass]="'button-lable-primary mx-0 ps-2 text-center font-12 btn-hover'"
                    [buttonLable]="tags.name"
                    (click)="selecteAccountTagsId(tags, i)"
                  >
                  </app-button>
                </div>
              </div>
            </div>
          </div>
          <!-- end tags -->
        </div>
      </div>
      </div>
    </div>
    </div>
 </form>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CompaniesService } from '../../../companies/companies.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsTabService } from '../../settings-tab/settings-tab.service';
import { DocumentTypes } from 'src/app/shared/model/enum';
import { DownloadDocuments } from '../../../companies/companies.model';

@Component({
  selector: 'app-app-preview',
  templateUrl: './app-preview.component.html',
  styleUrls: ['./app-preview.component.scss']
})
export class AppPreviewComponent implements OnInit {
  data;
  fileUrl              = "";
  isLoadings: boolean  = false;
  downloadDocuments= new DownloadDocuments();
  constructor(  private sanitise: DomSanitizer, 
    public settingsTabService:SettingsTabService,
    public companiesService:CompaniesService,
    public activeModal: NgbActiveModal
    ) {
   }

  ngOnInit(): void {
    this. preview();
  }
  preview(){
    this.companiesService.preview.subscribe((res)=>{
       if(res.name==='tradeReference'){
        this.data = res;
        this.downloadTradeReference();
       }
       if(res.name==='documentsTab'){
        this.data = res;
        this.downloadDocumentsTab();
       }
      
    });

  }


  downloadDocumentsTab() {
    this.isLoadings = true;
    const data = this.data;
    this.downloadDocuments.guid=data.guId;
    this.downloadDocuments.documentTypeId=DocumentTypes.companyDocuments;
    this.companiesService.downloadDocument(this.downloadDocuments).subscribe((res) => {
     
      if (res.isSuccess) {
        const base64Data = res.response.base64String;
        this.fileUrl = 'data:application/pdf;base64,' + base64Data;
        if (this.fileUrl) {
          this.isLoadings = false;
          this.cleanUrl(this.fileUrl);
        }
      }
    });
  }

  downloadTradeReference(){
    this.isLoadings = true;
    const data = this.data;
    this.downloadDocuments.guid=data.guId;
    this.downloadDocuments.documentTypeId=DocumentTypes.tradeReferenceDocument;
    this.settingsTabService.downloadAndPreviewTradeReference(this.downloadDocuments).subscribe((res) => {
 
      if (res.isSuccess) {
        const base64Data = res.response.base64String;
        this.fileUrl = 'data:application/pdf;base64,' + base64Data;
        if (this.fileUrl) {
          this.isLoadings = false;
          this.cleanUrl(this.fileUrl);
        }
      }
    });
  }

  cleanUrl(url){
    return this.sanitise.bypassSecurityTrustResourceUrl(url);
  }

}

import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[commaSeparated]'
})
export class CommaSeparatedDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: any): void {
    let input = event.target.value;
    input = input.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    event.target.value = this.formatNumber(input);
  }

  private formatNumber(value: string): string {
    if (value === '') {
      return value;
    }

    const numericValue = parseFloat(value);
    if (numericValue < 100) {
      return numericValue.toString(); // Don't modify values less than 100
    } else {
      return (numericValue / 100).toFixed(2); // Format with two decimal places
    }
  }
}

<div class="modal-content">
  <div class="modal-header px-4  pt-4 mt-1 border-0 pb-0">
    <h6 class="modal-titel  ms-2 ps-1  text-primary-color font-15">
      {{ titleName }} {{this.enumPopUpTitles.ACTIVITY}}
    </h6>
  </div> 
  <div class="modal-body px-4 mx-2">
    <div class="row">
      <div class="col-6 file-div-wrap">
        <app-dropdown
          [selectLabel]="'Type'"
          [data]="activityPopupTypesList"
          [selectValue]="insertUpdateToDoActivity?.typeId"
          (selectValueEmit)="insertUpdateToDoActivity.typeId = $event"
          [ngClass]="{'border-red':!insertUpdateToDoActivity.typeId && submit}"
          [required]="true"
        >
        </app-dropdown>
      </div>
      <div class="col-6 file-div-wrap">
        <app-dropdown
          [selectLabel]="'Assigned To'"
          [data]="activityAssigneeList"
          [selectValue]="insertUpdateToDoActivity.assignedToUserId"
          (selectValueEmit)="insertUpdateToDoActivity.assignedToUserId = $event"
          [ngClass]="{'border-red':!insertUpdateToDoActivity.assignedToUserId && submit}"
          [required]="true"
        >
        </app-dropdown>
      </div>
    </div>
    <div class="row m-t-15">
      <div class="col-12">
        <app-input
          [inputLabel]="'Title'"
          [inputType]="'text'"
          [inputValue]="insertUpdateToDoActivity.title"
          (inputValueEmit)="insertUpdateToDoActivity.title = $event"
          [inputReqValidation]="true"
          [ngClass]="{'border-red':!insertUpdateToDoActivity.title && submit}"
        >
        </app-input>
      </div>
    </div>

    <div class="row m-t-15">
      <div class="col-12">
        <div 
          [ngClass]="{'textarea-div':!insertUpdateToDoActivity.description && submit}"
          class="d-flex flex-column position-relative addNote">
          <label
            class="form-label position-absolute addLabel font-11"
            for="description"
          >
            Description
            <i class="fst-normal ps-1 p-0 text-danger">*</i>
          </label>
          <textarea
          [(ngModel)]="insertUpdateToDoActivity.description"
          name="description"
          rows="10"
          class="form-control mx-3 mt-4 pt-1 font-13 Textarea"
          >
          </textarea>
          <span
            *ngIf="insertUpdateToDoActivity.description"
            (click)="insertUpdateToDoActivity.description = ''"
            class="position-absolute clear-icon hand"
            ><app-svg-icon
              class="svgIcon"
              [src]="'../../../../../../../assets/SVGIcons/undoSVG.svg'"
            ></app-svg-icon
          ></span>
        </div>
      </div>
    </div>

    <div class="row m-t-15">
      <div class="col">
        <div class="row">
          <div class="col-12">
            <app-dropdown
              [selectLabel]="'Company'"
              [data]="companyNameList"
              [disabled]="disabledCompnay ==true  ? true : false"
              [selectValue]="insertUpdateToDoActivity.companyId"
              (selectValueEmit)="insertUpdateToDoActivity.companyId = $event;selectValueChange(insertUpdateToDoActivity.companyId)"
              [required]="false">
            </app-dropdown>
          </div>
        </div>
        <div class="row m-t-15">
          <div class="col-12">
            <app-dropdown
              [selectLabel]="'Contact'"
              [data]="contactList"
              [disabled]="disabledContact == true ? true : false"
              [selectValue]="insertUpdateToDoActivity.contactId"
              (selectValueEmit)="insertUpdateToDoActivity.contactId = $event;selectContactValueChange(insertUpdateToDoActivity.contactId)"
            >
            </app-dropdown>
          </div>
        </div>
        <div class="row m-t-15">
          <div class="col">
            <app-date-picker
              [time]="false"
              [inputReqValidation]="true"
              [inputLabel]="'Due Date'"
              [inputValue]="dueDate"
              (inputValueEmit)="dueDate = $event"
              [ngClass]="{'border-red':!dueDate && submit}"
            ></app-date-picker>
          </div>
        </div>
        <div class="row m-t-15">
          <div class="col">
            <app-ngb-timepicker
            [selectedTime]="insertUpdateToDoActivity.dueTime"
            [ngbTimePickerValue]="ngbTimePickerValue"
            (timeChange)="insertUpdateToDoActivity.dueTime = $event;onTimeChange($event)"
            ></app-ngb-timepicker>
          </div>
        </div>
        <!-- <div class="row m-t-15">
          <div class="col"> -->
            <!-- <app-timepicker
              [inputValue]="insertUpdateToDoActivity.dueTime"
              (inputValueEmit)="insertUpdateToDoActivity.dueTime = $event"
            ></app-timepicker> -->
            <!-- [ngClass]="{'invalide-border':!insertUpdateToDoActivity.dueTime && submit}"
            [inputReqValidation]="true"
             -->
             <!-- <div #clockElement class="">
              <div class="date-container form-floating  d-flex">
                <input
                  [(ngModel)]="selectedTimeValue"
                  readonly
                  class="px-1 hand form-control time-input"
                />
                <label
                for="time"
                class="label1"
                [ngClass]="{'label2' : selectedTime != '' && hideIcon == false}">
                 Time 
                <i class="fst-normal ps-1 p-0 text-danger"></i>
              </label>
                <img width="14px" src="../../../assets/SVGIcons/clockIcon.svg"  (click)="togglePopover()" alt="" class="hand clock">
              </div>
              <span
              [ngClass]="{'d-none': hideIcon == true ||  selectedTime == ''}"
              (click)="clearTime(); "
              class="refresh-icon"><app-svg-icon
                class="svgIcon"
                [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
              ></app-svg-icon
            ></span>
              <ng-container *ngIf="isPopoverOpen" id="isPopoverOpen">
                <ngb-popover placement="bottom" [autoClose]="false">
                  <ngb-timepicker 
                   [id]="'timePicker1'" 
                   [name]="'timePicker1'" 
                   [(ngModel)]="ngbTimePickerValue"
                   (ngModelChange)="changeTimeExpiration($event)"
                   [meridian]="true" class="time-picker-custom timepicker"></ngb-timepicker>
                </ngb-popover>
              </ng-container>
            </div>

          </div>
        </div> -->
      </div>
      <div class="col p-l-15">
        <div class="m-l-15">
          <label for="" class="font-12 text-light-info">
            Priority:
            <span class="required-feild mb-1">*</span>
          </label>
          <div
            class="radio radio-p padding-7"
            *ngFor="let saleItem of priorityList; let i = index"
          >
            <input
              type="radio"
              name="radiogroup"
              [value]="saleItem.id"
              [(ngModel)]="insertUpdateToDoActivity.priorityId"
              required
              [id]="'priority' + saleItem.id"
            />
            <label
              for="{{ 'priority' + saleItem.id }}"
              class="form-check-label"
            >
              {{ saleItem.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer px-4 mx-2 pb-4 border-0">
    <div class="justify-content-end me-0">
      <app-button
        [button_2]="true"
        [buttonClass]="'button button-danger'"
        [buttonLable]="enumButtonName.CANCEL"
        (click)="activeModal.close()"
      >
      </app-button>
      <app-button
        class="ms-3"
        [button_2]="true"
        [buttonClass]="'button button-info'"
        [buttonLable]="titleName=='Edit'?'Update & Mark as Complete':titleName + ' & Mark as Complete'"
        (click)="insertUpdateToDoActivity.isMarkComplete = true;addStatusId(); addTask()"
      >
      </app-button>
      <app-button
        class="ms-3"
        [button_2]="true"
        [buttonClass]="'button button-primary'"
        [buttonLable]="titleName=='Edit'?enumButtonName.UPDATE: enumButtonName.ADD"
        (click)="insertUpdateToDoActivity.isMarkComplete = false; addTask()"
      >
      </app-button>
    </div>
  </div>
</div>

<div class="modal-content">
    <div class="modal-header px-4 border-0 pb-0 pt-4">
      <h6 class="modal-titel ms-2 mb-0 text-primary-color font-15">{{enumPopUpTitles.UPLOAD_DOCUMENT}}</h6>
    </div>
    <div class="modal-body px-5">
      <div class="row">
        <div class="col-12 file-div-wrap">
          <button class="file-upload-button">
            <ng-container *ngIf="firstContainer">
              <svg class="file-icon" xmlns="http://www.w3.org/2000/svg" width="38.086" height="50" viewBox="0 0 38.086 50">
                <g id="document" transform="translate(-5.957)">
                  <path id="Path_970" data-name="Path 970"
                    d="M42.363,10.378,32.947,1.469A5.35,5.35,0,0,0,29.256,0H11.328A5.377,5.377,0,0,0,5.957,5.371V44.629A5.377,5.377,0,0,0,11.328,50H38.672a5.377,5.377,0,0,0,5.371-5.371V14.279a5.393,5.393,0,0,0-1.68-3.9Zm-2.845,1.341H32.227a.489.489,0,0,1-.488-.488V4.359ZM38.672,47.07H11.328a2.444,2.444,0,0,1-2.441-2.441V5.371A2.444,2.444,0,0,1,11.328,2.93h17.48v8.3a3.422,3.422,0,0,0,3.418,3.418h8.887v29.98A2.444,2.444,0,0,1,38.672,47.07Z"
                    fill="#8b9daa" />
                  <path id="Path_971" data-name="Path 971"
                    d="M35.449,19.531H13.965a1.465,1.465,0,0,0,0,2.93H35.449a1.465,1.465,0,0,0,0-2.93Z" fill="#8b9daa" />
                  <path id="Path_972" data-name="Path 972"
                    d="M35.449,27.344H13.965a1.465,1.465,0,0,0,0,2.93H35.449a1.465,1.465,0,0,0,0-2.93Z" fill="#8b9daa" />
                  <path id="Path_973" data-name="Path 973"
                    d="M21.066,35.156h-7.1a1.465,1.465,0,0,0,0,2.93h7.1a1.465,1.465,0,0,0,0-2.93Z" fill="#8b9daa" />
                </g>
              </svg>
              <input type="file" name="files" (change)="upload($event)" />
              <!-- <input type="file" class="mx-auto" ID="fileSelect" (change)="onSelect($event)"  accept=".xlsx, .xls, .csv" /> -->
              <label for="" class="file-lable1 mt-3 font-15">
                Select a file(s) to upload.
              </label>
              <label class="font-10" style="color: #8B9DAA;">Or drag and drop it here</label>
            </ng-container>

            <!-- <ng-container *ngIf="hideProgressbar">
              <div >
                <div class="col-12 clear-color font-16 fw-bold">
                  {{ progressPercentage() }}%
                </div>
                  <div class=" {{ progress == 1 ? 'progressbar-1' : 'progressbar' }} ">
                    <span class="status" [style.animation-delay.s]="-progress"></span>
                  </div>
                <div class="col-12">
                  <p class="my-4 text-gray">Uploading file</p>
                  <p class="text-danger text-decoration-underline"  (click)="hideUploadingdiv()">Cancel upload</p>
                </div>
              </div>
            </ng-container> -->
            <div *ngIf="uploadSuccess" class="d-flex justify-content-end w-100 "><app-svg-icon (click)="newImport()" [src]="'../../../../../assets/SVGIcons/circle-close.svg'"></app-svg-icon> </div>
            <ng-container *ngIf="uploadSuccess" class="success">
              <div class="row">
                <div class="col-12">
                  <img src="./assets/SVGIcons/uploadesuccess.svg" alt="" width="40" height="40" />
                </div>
              </div>
              <label class="sucess mt-3 text-gray">{{
                importCompanyVM.lable4
                }}</label>
              <div class="col-12" *ngFor="let item of file">
                <span class="text-light-gray font-12">{{ item.name }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="hideErrordiv">
              <div class="row">
                  <div class="col-12">
                    <img src="./assets/SVGIcons/Erroricon.svg" alt="" width="40" height="40" />
                  </div>
                  <div class="col-12 mt-3">
                    <p class="text-gray">Error! Please try uploading your files again.</p>
                    <p class="text-decoration-underline mt-3" [ngClass]="hideErrordiv === true ? 'text-primary-color' : 'text-gray'" (click)="showuploadefile()">Upload file</p>
                  </div>
                <div>
                </div>
              </div>
            </ng-container>
          </button>
        </div>

        <div class="col-12 " *ngIf="pageType===enumUploadDocuments.DOCUMENTSTABS">
          <div class="row">
            <div class="col-12 ps-0 my-4">
              <h6 class="text-gray f-13 mb-0">Select a category</h6>
            </div>
            <div class="col-6 ps-0">
              <app-dropdown
              [selectLabel]="'Category'"
              [data]="categoryList"
              [selectValue]="uploadModel.categoryId"
              (selectValueEmit)="uploadModel.categoryId= $event;"
              ></app-dropdown>
            </div>
            <div class="col-6 pe-0">

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer px-4 border-0 pb-4">
      <div class="justify-content-end px-2">
        <app-button
          [button_2]="true"
          [buttonClass]="'button button-info'"
          [buttonLable]="enumButtonName.CANCEL"
          (click)="activeModal.close(false)">
        </app-button>
        <app-button
          class="ms-4"
          [button_2]="true"
          [buttonClass]="'button button-primary'"
          (click)="buttonDisable? uploadFile():''"
          [buttonLable]="enumButtonName.UPLOAD"
          >
        </app-button>
      </div>
    </div>
  </div>
  <!-- (click)="hideProgressbar?'':buttonDisable? uploadFile():''" -->

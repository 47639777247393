<div class="modal-header border-0 pb-0 mx-3">
  <h6 class="text-primary-color mb-0 mt-3 ml-3 font-15">{{ CompnayID != undefined ? editName :  headingsNames}}</h6>
</div>
<!-- end Modal header -->
<div class="modal-body px-4 mx-2">
  <tabset #tabset>
    <tab
      (selectTab)="onTabChange($event)"
      heading="General Information"
     >
     <!-- [disabled]="tab1Disabled"  -->
      <app-general-information
        #generalInfo
        [items]="stagesName"
        [comID]="CompnayID"
        [headingNames]="headingsNames"
        [getEditName]="editName"
        >
      </app-general-information>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      heading="Address Information"
     >
     <!-- [disabled]="tab2Disabled" -->
      <app-address-information #addressInfo [addressHeading]="headingsNames"></app-address-information>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      heading="Details"
     >
     <!-- [disabled]="tab3Disabled" -->
      <app-details #detailInfo [headName]="headingsNames"  [comID]="CompnayID" ></app-details>
    </tab>
    <tab
      (selectTab)="onTabChange($event)"
      heading="Contact Information"
    >
    <!-- [disabled]="tab4Disabled" -->
      <app-contact-information #contactInfo [contactHeadName]="headingsNames"></app-contact-information>
    </tab>
  </tabset>
</div>
<div class="modal-footer border-0 mb-3 mt-2 me-3">
  <div class="d-flex">
    <app-button
      class="me-3"
      [buttonType]="'button'"
      [button_2]="true"
      [buttonClass]="'button button-danger'"
      (click)="resetId(); ngbActiveModal.dismiss()"
      [buttonLable]="enumButtonName.CANCEL">
    </app-button>
    <app-button
      class="me-3"
      [buttonType]="'button'"
      [button_2]="true"
      (click)="nextTabs()"
      [buttonLable]="enumButtonName.NEXT"
      [buttonClass]="nextTab == true ? 'button button-primary' : 'button button-info'"
      *ngIf="tab4Disabled">
    </app-button>
    <app-button
      [buttonType]="'submit'"
      [button_2]="true"
      (click)="insetUpdateCompnayData(CompnayID != undefined ? UpdateValue : headingsNames)"
      [buttonClass]="'button button-primary'"
      [buttonLable]=" CompnayID != undefined  ? UpdateValue : headingsNames"
    >
    </app-button>
  </div>
</div>
<!-- [buttonClass]="tabset.tabs[0].active == true || tabset.tabs[2].active == true ? 'button button-info' : 'button button-primary'" -->
<!-- [ngClass]="{'pe-none': tabset.tabs[0].active == true ||  showButton == false}" -->

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Button } from 'src/app/shared/model/enum';
import { ExportMailingList, mailingList } from '../../pages/companies/companies.model';
import { RecordAddedComponent } from '../record-added/record-added.component';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { CommonDropdownModel } from '../add-company/add-company.model';
import { CompaniesService } from '../../pages/companies/companies.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-mailing-list',
  templateUrl: './add-mailing-list.component.html',
  styleUrls: ['./add-mailing-list.component.scss']
})
export class AddMailingListComponent implements OnInit {
  enumButtonName    = Button;
  mailingLists      = new ExportMailingList();
  recordList        = new mailingList()
  mailingListDropdown: CommonDropdownModel[] = [];
  submit:Boolean = false;
  @Input() dataList:[];

  
  constructor(public  activeModal:NgbActiveModal, 
              public modalService: NgbModal,
              public commonTabService: CommonTabService,
              public companiesService:CompaniesService,
              private toaster : ToastrService,

    ) { }


  ngOnInit(): void {
    this.mailingListDropdown = this.commonTabService.getAllDropdownList.getValue().mailingListDropdown;

  }
  applyChanges(){
    var dataArray = this.dataList as any[];
    const mailingRecords: mailingList[] = [];
    for (var i = 0; i < dataArray.length; i++) {
        var value = dataArray[i];
        if (typeof value === 'object' && value !== null) {
            const mailingListItem: mailingList = {
                companyId: value.id || '',
                companyName: value.companyName || '',
                contactId: value.contactId || '',
                contactName: value.contactName || '',
                createdBy: value.createdBy || '',
                emailAddress: value.emailAddress || '',
                contactFirstName: value.contactFirstName || '',
                contactLastName: value.contactLastName || ''
            };
            mailingRecords.push(mailingListItem);
        }
    }
    this.mailingLists.mailingRecords = mailingRecords;
    if(this.mailingLists.mailingListId){
      this.mailingLists.isCreated = false;
      this.mailingLists.mailingListName = ''
      this.exportMailing()
    }else if(this.mailingLists.isCreated){
      if(this.mailingLists.mailingListName){
        this.exportMailing()
      }else{this.submit = true;}
    }
  }
  checkedVlue(event){
    if(event == false){
      this.mailingLists.mailingListName = ''
    }
  }
  clearValue(){
    if( this.mailingLists.isCreated){
      this.mailingLists.isCreated = false;
      this.mailingLists.mailingListName = ''
    }
}
clearDropdown(){
  if( this.mailingLists.mailingListId){this.mailingLists.mailingListId =''}
}

  openModal(value){
    const modalRef = this.modalService.open(RecordAddedComponent, {
      centered: true,
      windowClass: "resetPassword",
      keyboard: false,
      backdrop: 'static',
      size: "sm",
    });
    modalRef.componentInstance.record = value?.response
    modalRef.result.then((result) => {
      if (result) {
      }
    });
  }
  exportMailing(){
    this.companiesService.exportMailing(this.mailingLists).subscribe((res) => {
      if (res.isSuccess == true) {
        this.activeModal.close(res.isSuccess);
        this. openModal(res)
      } else {
        this.toaster.error(res.message)
      }
    });



  }
}

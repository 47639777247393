<!--<p *ngIf="!isVisible" class="companyp mb-0">{{companyName}} </p> -->
<div *ngIf="params?.data?.isExpandable" class="container-fluid mt-5 w-100">
  <div class="row my-auto mx-auto w-100">
    <div class="first-col">
      <div class="row mt-2 mb-2">
        <div class="col-12 mr-30">
          <div class="row" *ngIf="currentTab !== 'vendor-list'">
            <p for="" class="text-light-gray font-10 mb-0">Parent Company:
            </p>
          </div>
          <div class="row" *ngIf="currentTab !== 'vendor-list'">
            <div class="col-11">
            <p class="text-primary-color mb-2 font-11 text-truncate">
              {{params?.data?.parentCompany?params?.data?.parentCompany:"-"}}
            </p>
            </div>
          </div>
          <div class="row">
            <p for="" class="text-light-gray font-10 mb-0">Location:</p>
          </div>

          <div class="row text-truncate">
            <div class="col-11">
              <p title="{{ params?.data?.location }}" class="mb-2 font-11 text-truncate my-auto text-primary-color">
                {{ params?.data?.location?params?.data?.location:"-" }}
                <!-- Florida, USA -->
              </p>
            </div>
          </div>
          <div class="row">
            <p for="" class="text-light-gray font-10 mb-0">Timezone: </p>
          </div>
          <div class="row text-truncate">
            <div class="col-11">
              <p class="text-primary-color text-break text wrap mb-2 font-11 text-truncate">{{ params?.data?.timeZoneName?params?.data?.timeZoneName:"-" }}</p>
            </div>
          </div>
          <div class="row" *ngIf="currentTab == 'vendor-list'">
            <p for="" class="text-light-gray font-10 mb-0">Parent Company:
            </p>
          </div>
          <div class="row" *ngIf="currentTab == 'vendor-list'">
            <div class="col-11">
            <p class="text-primary-color mb-2 font-11 text-truncate">
              {{params?.data?.parentCompany?params?.data?.parentCompany:"-"}}
            </p>
          </div>
          </div>

        </div>
      </div>

    </div>
   <!-- <div class="sec-col">
      <div class="row m-0">
        <div class="col-xl-6 col-lg-6 col-md-6 mt-2 px-0">
          <div class="row">
            <p for="" class="text-light-gray font-10 mb-0">Account Number:
            </p>
          </div>
          <div class="row ">
            <p class="text-gray mb-2 font-11 text-truncate">
              {{ params?.data?.bankAccountNumber?params?.data?.bankAccountNumber:"890675486646"}}
            </p>
          </div>
          <div class="row">
            <p for="" class="text-light-gray font-10 mb-0">Accounting Contact:
            </p>
          </div>
          <div class="row">
            <p title="{{ params?.data?.accountContactEmailAddress}}" class="text-gray mb-2 font-11 text-truncate">
              {{ params?.data?.accountContactEmailAddress?params?.data?.accountContactEmailAddress:"Adrien_Rice71@gmail.com"}}
            </p>
          </div>
          <div class="row ">
            <p class="text-gray mb-2 font-11 text-truncate">
              {{ params?.data?.accountContactPhoneNumber?params?.data?.accountContactPhoneNumber:"(294) 789-8567" }}
            </p>
          </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 mt-2">
          <p for="" class="text-light-gray font-10 mb-0">Main Contact:
          </p>
          <p class="text-gray mb-1 font-11 text-truncate">
            {{ params?.data?.primaryContactName?params?.data?.primaryContactName:"Adrien Rice"}}
          </p>
          <p class="text-gray mb-1 font-11 text-truncate">
            {{ params?.data?.primaryContactEmail?params?.data?.primaryContactEmail:"Adrien_Rice71@gmail.com"}}
          </p>
          <p class="text-gray mb-2 font-11 text-truncate">
            {{ params?.data?.primaryContactPhone?params?.data?.primaryContactPhone:"(294) 789-8567"}}
          </p>
        </div>
      </div>
    </div>
     <div class="thrid-col NAICS-pos">
      <div class="row mt-2 mb-1">
        <div class="col-xl-4 col-lg-4 col-md-4 ">
          <p for="" class="text-light-gray font-10 mb-0">Entity Type:
          </p>
          <p class="text-gray mb-2 font-11 text-truncate">
            {{  params?.data?.entityType?params?.data?.entityType:"Parent" }}
          </p>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5 ">
          <p for="" class="text-light-gray font-10 mb-0">Type:</p>
          <p class="text-gray mb-2 font-11 text-truncate">{{ params?.data?.class?params?.data?.class:"EDU" }}</p>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-4 ">
          <p for="" class="text-light-gray font-10 mb-0">D & B Number:
          </p>
          <p title="{{ params?.data?.dandBNumber }}" class="text-gray mb-2 font-11 text-truncate">
            {{ params?.data?.dandBNumber?params?.data?.dandBNumber:"116786987" }}
          </p>
        </div>

        <div class="col-4 ">
          <p for="" class="text-light-gray font-10 mb-0">SIC Code:
            <app-svg-icon
              (click)="clickSicsIcon()" 
              [src]="'./assets/SVGIcons/I_button.svg'"    
              class="I-icon hand">
            </app-svg-icon>
          </p>
          <p class="text-gray mb-2 font-11 text-truncate">{{ params?.data?.sicCode?params?.data?.sicCode:"11656780" }}</p>
        </div>
        <div class="col-3  NAICS-pos">
          <p for="" class="text-light-gray font-10 mb-0">NAICS code:
            <app-svg-icon 
              (click)="clickIcon()"
              [src]="'./assets/SVGIcons/I_button.svg'"    
              class="I-icon hand">
            </app-svg-icon>
          </p>
          <p class="text-gray mb-2 font-11 text-truncate">{{ params?.data?.naicsCode?params?.data?.naicsCode:"116785" }}</p>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-xl-12 ">
          <p for="" class="text-light-gray font-10 mb-0">Industry:</p>
          <p class="text-primary-color mb-2 font-11 text-truncate nowrap">
            {{ params?.data?.industryName?params?.data?.industryName:"Lorem ipsum dolor sit amet" }}
          </p>
        </div>
      </div>
    </div>
    <div class="sm-cards" *ngIf="show;">
      <p class="font-10 m-0">
        Audio and Video <br />
        Equipment <br />
        Manufacturing
      </p>
    </div>
    <div class="sm-cards-Sics" *ngIf="showSics;">
      <p class="font-10 m-0">
        Audio and Video <br />
        Equipment <br />
        Manufacturing
      </p>
    </div>
    <div class="fourth-col NAICS-pos">
      <div class="row mr-1 mt-2">
        <div class="col-xl-5">
          <div class="row">
            <div class="col-xl-12 ">
              <p for="" class="text-light-gray font-10 mb-0">Revenue:
              </p>
              <p class="text-gray text-truncate  mb-2 font-11 ">${{params?.data?.revenue?params?.data?.revenue:"4,805,000"}}</p>
            </div>
            <div class="col-xl-12 ">
              <p for="" class="text-light-gray font-10 mb-0">Currency:
              </p>
              <p class="text-gray mb-2 font-11">${{ params?.data?.currency?params?.data?.currency:"$" }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="col-xl-12 ">
            <p for="" class="text-light-gray font-10 mb-0">Sales 12 months:
            </p>
            <p title="{{ params?.data?.sales12Month }}" class="text-gray mb-2 font-11">${{
              params?.data?.sales12Month?params?.data?.sales12Month:"360,000" }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="fifth-col col-xl-1 col-lg-1 col-md-1">
      <p for="" class="text-light-gray font-10 mt-2 mb-0">Quick Note:</p>
      <p class="text-primary-color mb-2 font-11 prewrap">
        {{params?.data?.recentNotes
          ?params?.data?.recentNotes

          :"Lorem ipsum dolor sit amet, consetetur sadipscing" }}
      </p>
    </div> -->
  </div>
</div>
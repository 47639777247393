<div
  class="bg-primary-color d-flex justify-content-between align-items-center text-light"
  style="border-radius: 5px; padding: 0.7em 1em"
>
  <div class="d-flex gap-5 wrapper" style="width: 80%">
    <div class="d-flex gap-3 align-items-center">
      <span class="font-12">{{label1}}</span>
      <span class="m-l-10 font-13 fw-bold text-truncate">{{infoDetails?.internalPartNumber == undefined ? '-' : infoDetails?.internalPartNumber}}</span>
    </div>
    <div class="d-flex gap-3 align-items-center">
      <span class="font-12">{{label2}}</span>
      <span class="m-l-10 font-13 fw-bold text-truncate">{{infoDetails?.inStock == undefined ? '-' : infoDetails?.inStock}}</span>
    </div>
    <div class="d-flex gap-3 align-items-center">
      <span class="font-12">{{label3}}</span>
      <span class="m-l-10 font-13 fw-bold text-truncate">{{infoDetails?.qtyAvailable == undefined ? '-' : infoDetails?.qtyAvailable}}</span>
    </div>
    <div class="d-flex gap-3 align-items-center w-20">
      <span class="font-12">{{label4}}</span>
      <span class="m-l-10 font-13 fw-bold text-truncate">{{infoDetails?.category == undefined ? '-' : infoDetails?.category}}</span>
    </div>
  </div>
  <div class="d-xl-flex d-md-none d-flex align-items-center hand">
    <img
      (click)="toggleIcon(isContactReminder)"
      src="./assets/SVGIcons/{{ isContactReminder ? 'uparrow' : 'downarrow' }}.svg"
      alt=""
    />
  </div>
</div>

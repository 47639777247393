import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject, tap } from "rxjs";
import { DataService } from "../../../shared/services/data.service";
import { Result } from "../../../shared/model/response.model";
import { GetCompanySettingData } from "./company-setting.model";

@Injectable({
  providedIn: "root",
})
export class CompanySettingsService {
  public imageDataName = new Subject<string>();
  constructor(
    public router: Router,
    private service: DataService,
  ) {}
  insertUpdateCompanySettingData(insertUpdateData: GetCompanySettingData): Observable<Result> {
    let url ="identity/companySetting/insertUpdateCompanySetting";
    return  this.service.post(url,insertUpdateData,true).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }

  public getCompanySettingByUserId(userId:string): Observable<Result>{
    let url ='identity/companySetting/getCompanySettingByUserId?userId='+userId;
    return this.service.get(url,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
  }
  

}

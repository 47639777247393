import { SvgPreloadService } from './svgPreload.service';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {
  @ViewChild('svgContainer', { static: true }) svgContainer: ElementRef;

  @Input() public src:any="";
  @Input() public classSvg:any;
  
  constructor(private renderer: Renderer2,private el: ElementRef,
    private http: HttpClient,private svgPreloader: SvgPreloadService) { }

  ngOnInit(): void {
    if(this.src != undefined){
      this.http.get(this.src,{responseType: 'text'}).subscribe(svg => {
        if(this.src != ''){
          this.el.nativeElement.innerHTML = svg;
        }
    });
    }
    // this.svgPreloader.preloadSvg(this.src)
    // .then(svg => this.el.nativeElement.innerHTML = svg)
    // .catch(error => console.error('Error preloading SVG:', error));
  }

  }

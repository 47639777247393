import { AddCompanyService } from './../add-company.service';
import {
  AfterViewInit, Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Options } from "@angular-slider/ngx-slider";
import {
  AllStaticText,
  CommonDropdownModel,
  InserUpdateCompny,
  PopupHeadingNames,
  SaleStage,
  SaleStagestring,
  TagsListData,
  TypeList
} from '../add-company.model';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { LabelsName } from 'src/app/shared/model/enum';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { BehaviorSubject, Observable } from 'rxjs';
export class list {
  name: string
}
@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit, AfterViewInit {
  @Input() items: string;
  @Input() comID
  @ViewChild('tabset') tabset: TabsetComponent;
  @Output() dataEvent = new EventEmitter<string>();
  @Input() name: boolean;
  @Input() state: boolean;
  @Output() hide = new EventEmitter();
  @Output() genInfoSucccess = new EventEmitter();
  @Input() headingNames: string = '';
  @Input() getEditName: string = ''
  generalInfoLables = LabelsName;
  submit: boolean = false
  submit2: boolean = false;
  highValue: number = 0.0;
  insertUpdateGeneralInfoCompany = new InserUpdateCompny();
  typeId: any
  screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
  tagsId:any[]=[];
  width: number;
  addCompnyAlltext = new AllStaticText;
  showLeadScorList: boolean;
  subListItemName: string = '';
  intendToBuyId: string;
  addRating  = new AllStaticText;
  currentTab = '';
  filterPopupVM = new AllStaticText;
  isContactReminderList = [{ id: 'yes', name: 'Yes', isContactReminder: true },
  { id: 'no', name: 'No', isContactReminder: false }]
  // ngx slider region start
  options: Options =
    {
      floor: 0.0,
      ceil: 5.0,
      step: 0.5,
      showSelectionBar: true,
    };

  timezoneList: CommonDropdownModel[] = [];
  classList: CommonDropdownModel[] = [];
  countryList: CommonDropdownModel[] = [];
  stateList: CommonDropdownModel[] = [];
  salesPersonList: CommonDropdownModel[] = [];
  altSalesPersonList: CommonDropdownModel[] = [];
  buyerRepList: CommonDropdownModel[] = [];
  tagList: CommonDropdownModel[] = [];
  intendToBuyList: CommonDropdownModel[] = [];
  leadsList: CommonDropdownModel[] = [];
  manufacturerList: CommonDropdownModel[] = [];
  saleStageList: TypeList[] = [];
  enumPopupHeadingNames = PopupHeadingNames;
  enumSaleStagestring = SaleStagestring;
  saleStagedata: any
  stageID: number;
  lesadIntentID
  showvendor: boolean = false;
  marrkascompany: boolean = false;
  selectedOption: string;
  submit3: boolean = false;
  tagsListData: CommonDropdownModel[] = [];
  enumSaleStage = SaleStage
  emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  websitepattern= /^(https?|ftp):\/\/[^\s/$.]+[^\s]*$/;
  isEmailInvalid: boolean = false;
  isWebsiteInvalid: boolean = false;
  nameCompany:string='';
  typeName:boolean = false;
  @HostListener("window:resize", ["$event"])
  onResize(event: { target: { innerWidth: number } }) {
    this.width = event.target.innerWidth;
  }
  //region 
  constructor(
    public companiesService: AddCompanyService,
    private toastr: ToastrService,
    public companys: AddCompanyService,
    public commonTabService: CommonTabService,
    public renderer: Renderer2,
    public tabsService: TabsService) {
    this.getScreenWidth().subscribe((res) => {
      this.width = res;
    });
  }
  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }
  
  
  ngOnInit(): void {
    this.getEditName
    this.headingNames;
    this.comID
    this.timezoneList = this.commonTabService.getAllDropdownList.getValue().timezoneList;
    if (this.headingNames === this.enumPopupHeadingNames.ADDCOMPANY) {
      this.classList = this.commonTabService.getAllDropdownList.getValue().typeList;
    }
    else if (this.headingNames === this.enumPopupHeadingNames.ADDVENDOR) {
      this.classList = this.commonTabService.getAllDropdownList.getValue().vendorTypeList;
      this.typeName = !!this.classList.find(x => x.name === 'Manufacturer' &&  x.id == this.typeId );

      // if (this.comID == undefined) {
      //   this.getvendorTypeList()
      // } else {
      // }
      this.marrkascompany = true;
      this.showvendor = false;
    }
    this.tagList = this.commonTabService.getAllDropdownList.getValue().tagsList;
    this.tagsListData = this.commonTabService.getAllDropdownList.getValue().tagsList;
    this.countryList = this.commonTabService.getAllDropdownList.getValue().countryList;
    this.salesPersonList = this.commonTabService.getAllDropdownList.getValue().salesPersonList;
    this.altSalesPersonList = this.commonTabService.getAllDropdownList.getValue().altSalesPersonList;
    this.buyerRepList = this.commonTabService.getAllDropdownList.getValue().buyerRepList;
    this.stateList = this.commonTabService.getAllDropdownList.getValue().stateList;
    this.manufacturerList = this.commonTabService.getAllDropdownList.getValue().manufacturerList;
    this.intendToBuyList = this.commonTabService.getAllDropdownList.getValue().intendToBuyList.reverse();
    this.leadsList = this.commonTabService.getAllDropdownList.getValue().leadsList.reverse();
    this.saleStageList = this.commonTabService.getAllDropdownList.getValue().stageList.reverse();
    const order = ['Lead', 'Prospect', 'Customer','Vendor'];
    this.saleStageList.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));

    this.saleStagedata = this.items
    // this.clearFilter(this.tagList)
    this.insertUpdateGeneralInfoCompany.generalInfo.intentToBuyId = '';
    this.insertUpdateGeneralInfoCompany.generalInfo.leadScoreId = '';
    this.typeId = this.insertUpdateGeneralInfoCompany.generalInfo.typeId;

    if (this.saleStagedata == this.enumSaleStage.Lead && this.headingNames == this.enumPopupHeadingNames.ADDCOMPANY) {
      this.getTagsByStageId('2');
      this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId =this.enumSaleStage.Lead;
    }
    if (this.saleStagedata == this.enumSaleStage.Prospect && this.headingNames == this.enumPopupHeadingNames.ADDCOMPANY) {
      this.getTagsByStageId('3');
      this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId =this.enumSaleStage.Prospect;

    }
    if (this.comID == undefined && this.headingNames == this.enumPopupHeadingNames.ADDCOMPANY) {
      if (this.saleStagedata == 'All' || this.saleStagedata == this.enumSaleStage.Customer) {
        this.getTagsByStageId('1')
        this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId = this.enumSaleStage.Customer
      }
    }
   // this.getvendorTypeList()
    if (this.headingNames == this.enumPopupHeadingNames.ADDVENDOR && this.insertUpdateGeneralInfoCompany.generalInfo.markAsCompany == false) {
      this.getTagsByStageId('4');
      this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId = this.enumSaleStage.Vendor;
      // this.insertUpdateGeneralInfoCompany.generalInfo.salesPerson1UserId = '';
    }
  }
tabNext(){
  if(this.headingNames =='Add Vendor' || this.headingNames =='Edit Vendor'){
    this.nameCompany = this.insertUpdateGeneralInfoCompany.generalInfo.vendorName
  }else{ this.nameCompany = this.insertUpdateGeneralInfoCompany.generalInfo.companyName}
  const companyName = this.nameCompany ;
  const phoneNumber = this.insertUpdateGeneralInfoCompany.generalInfo.phoneNumber;
  const nameAndPhone = { companyName, phoneNumber };
  this.companiesService.companyName.next(nameAndPhone);
    if( this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == '1'){
    if(this.insertUpdateGeneralInfoCompany.generalInfo.companyName && this.insertUpdateGeneralInfoCompany.generalInfo.salesPersonId && this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId &&  this.typeId ){
      this.companiesService.nextTab.next(true)
    }else{this.companiesService.nextTab.next(false)}
  } else  if( this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == '2'){
    if(this.insertUpdateGeneralInfoCompany.generalInfo.companyName && this.insertUpdateGeneralInfoCompany.generalInfo.salesPersonId && this.insertUpdateGeneralInfoCompany.generalInfo.leadScoreId && this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId &&  this.typeId ){
      this.companiesService.nextTab.next(true)
    }else{this.companiesService.nextTab.next(false)}
  }else  if( this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == '3'){
    if(this.insertUpdateGeneralInfoCompany.generalInfo.companyName && this.insertUpdateGeneralInfoCompany.generalInfo.salesPersonId && this.insertUpdateGeneralInfoCompany.generalInfo.intentToBuyId && this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId &&  this.typeId ){
      this.companiesService.nextTab.next(true)
    }else{this.companiesService.nextTab.next(false)}
  }else  if( this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == '4'){
    if(this.insertUpdateGeneralInfoCompany.generalInfo.vendorName && this.insertUpdateGeneralInfoCompany.generalInfo.countryId && this.typeId ){
      this.companiesService.nextTab.next(true)
    }else{this.companiesService.nextTab.next(false)}
  }
}
  ngAfterViewInit() {
    if ((this.saleStagedata == 'All' || this.saleStagedata == this.enumSaleStage.Customer || this.stageID == 1) && this.headingNames == this.enumPopupHeadingNames.ADDCOMPANY) {
      document.getElementById('stage1').click();
    }
    else if ((this.saleStagedata == this.enumSaleStage.Lead || this.stageID == 4) && this.headingNames == this.enumPopupHeadingNames.ADDCOMPANY) {
      document.getElementById('stage4').click();
    }
    else if ((this.saleStagedata == this.enumSaleStage.Prospect || this.stageID == 3) && this.headingNames == this.enumPopupHeadingNames.ADDCOMPANY) {
      document.getElementById('stage3').click();
    }
  }
  getvendorTypeList() {
        this.classList = this.commonTabService.getAllDropdownList.getValue().vendorTypeList;
  }
  onHover() {
    this.state = false;
    this.hide.emit(this.state);
  }

  clearFilter(listname: any) {
    if (listname == this.classList) {
      for (let i = 0; i < this.classList.length; i++) {
        this.classList[i].isActive = false;
        this.typeId = '';
      }
    } else if (listname == this.tagsListData) {
      for (let i = 0; i < this.tagsListData.length; i++) {
       // this.tagsListData[i].isActive = false;
        this.tagsListData[i].selectedTags= false;
        this.tagsId = [];
      }
    }
  }
  selecteAccountClassId(item, index) {
    this.typeId = item.id;
    this.typeName =  item.name === 'Manufacturer' && item.id == this.typeId ;

  }
  selecteAccountTagsId(item, index) {
    const data = this.tagsId.find(a=>a == item.id);
    if(data != null && data != undefined){
      const indexToRemove = this.tagsId.indexOf(data);
      if (indexToRemove !== -1) {
        this.tagsId.splice(indexToRemove, 1);
        }
      }
      else{
        this.tagsId.push(item.id);
      }
   
    this.tagsListData?.map((x) => {
      const data = this.tagsId.find(a=>a == x.id);
      if(data != null && data != undefined){
        x.selectedTags = true;
      }
      else{
        x.selectedTags = false;
      }
    })
  }

  onSelectionChange(stage) {
    this.getTagsByStageId(stage.id);
    this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId = stage.id
    this.insertUpdateGeneralInfoCompany.generalInfo.intentToBuyId = '';
    this.insertUpdateGeneralInfoCompany.generalInfo.leadScoreId = '';
    if (stage.name === this.enumSaleStagestring.LEAD) {
      this.showLeadScorList = true;
    }
    else {
      this.showLeadScorList = false;
    }
  };
  onSelectionChanges(stage) {
    if (stage.labelName == "IntendToBuy") {
      this.insertUpdateGeneralInfoCompany.generalInfo.intentToBuyId = stage.id;
    }
    else if (stage.labelName == this.enumSaleStagestring.LEAD) {
      this.insertUpdateGeneralInfoCompany.generalInfo.leadScoreId = stage.id;
    }
  }

  // for input keydown ------------------
  onKeydown(event: any, value: any) {
    const element = this.renderer.selectRootElement(value, true);
    if (event.key === "Enter") {
      element.focus();
    }
  }
  onEmailChange(): void {
    this.isEmailInvalid = false;
    this.submit = false;
    this.submit2 = false;
    const email = this.insertUpdateGeneralInfoCompany.generalInfo.emailAddress;
    if (email !== '' && email !== undefined) {
      this.isEmailInvalid = !this.emailPattern.test(this.insertUpdateGeneralInfoCompany.generalInfo.emailAddress);
    }
  }
  
  onWebsiteChange(){
    this.isWebsiteInvalid = false;
    this.submit = false;
    this.submit2 = false;
    const website = this.insertUpdateGeneralInfoCompany.generalInfo.website;
    if (website !== '' && website !== undefined) {
      this.isWebsiteInvalid = !this.websitepattern.test(this.insertUpdateGeneralInfoCompany.generalInfo.website);
    }

  }
  generalInfocheck(): string {   
    if (this.headingNames == this.enumPopupHeadingNames.ADDCOMPANY) {
      if (this.insertUpdateGeneralInfoCompany.generalInfo.companyName && this.insertUpdateGeneralInfoCompany.generalInfo.salesPersonId && this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId  && this.typeId) {
        if ((this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Lead && this.insertUpdateGeneralInfoCompany.generalInfo.leadScoreId) || (this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Prospect && this.insertUpdateGeneralInfoCompany.generalInfo.intentToBuyId) || (this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Customer)) {
          
          if (this.isEmailInvalid) {
            this.submit = true
            return "email";
           }
           if (this.isWebsiteInvalid) {
            this.submit = true
            return "website";
           }


          return "success";
        }
      }
      else {
        this.submit = true;
        
      }
    }

    if (this.headingNames == this.enumPopupHeadingNames.ADDVENDOR) {
      if (this.insertUpdateGeneralInfoCompany.generalInfo.vendorName && this.insertUpdateGeneralInfoCompany.generalInfo.countryId  &&  this.typeId != undefined) {
        if (this.isEmailInvalid) {
          this.submit2 = true;
          return "email";
        }
        if (this.isWebsiteInvalid) {
          this.submit2 = true;
          return "website";
        }

        return "success";
      }
      else {
        this.submit2 = true;
      }
    }
    // if (this.insertUpdateGeneralInfoCompany.generalInfo.markAsCompany == true && this.headingNames == this.enumPopupHeadingNames.ADDVENDOR) {
    //   if (this.insertUpdateGeneralInfoCompany.generalInfo.vendorName && this.insertUpdateGeneralInfoCompany.generalInfo.manufacturerId && this.insertUpdateGeneralInfoCompany.generalInfo.salesPersonId && this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId != this.enumSaleStage.Vendor && this.typeId != undefined) {
    //     if ((this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Lead && this.insertUpdateGeneralInfoCompany.generalInfo.leadScoreId) || (this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Prospect && this.insertUpdateGeneralInfoCompany.generalInfo.intentToBuyId) || (this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Customer)) {
    //       return "success";
    //     }
    //   }
    //   else {
    //     this.submit3 = true;
    //     this.submit = true;
    //   }
    // }
    
    if (this.insertUpdateGeneralInfoCompany.generalInfo.markAsCompany == true && this.typeName && this.headingNames == this.enumPopupHeadingNames.ADDVENDOR) {
      if (this.insertUpdateGeneralInfoCompany.generalInfo.vendorName && this.insertUpdateGeneralInfoCompany.generalInfo.manufacturerId && this.insertUpdateGeneralInfoCompany.generalInfo.salesPersonId && this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId != this.enumSaleStage.Vendor && this.typeId != undefined) {
        if ((this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Lead && this.insertUpdateGeneralInfoCompany.generalInfo.leadScoreId) || (this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Prospect && this.insertUpdateGeneralInfoCompany.generalInfo.intentToBuyId) || (this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Customer)) {
          return "success";
        }
      }
      else {
        this.submit3 = true;
        this.submit = true;
      }
    }

  }

  getRatingValue(val) {
     this.insertUpdateGeneralInfoCompany.generalInfo.overallRating = val;
  }

  getTagsByStageId(stageId: string) {
    this.stageID = Number(stageId);
    this.companiesService.GetTagsByStageId(this.stageID).subscribe((res) => {
      if (res.isSuccess == true) {
        this.tagsListData = res.response.tagsList
      }
    })

  }
  // getMakascompanyValue(value: boolean) {
  //   if (value == false) {

  //     this.stageID = 0;
  //     this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId = this.enumSaleStage.Vendor;
  //     // this.companiesService.GetVendorTypeandtags().subscribe((res) => {
  //     //   if (res.isSuccess) {
  //     //     this.typeId = 0;
  //     //     this.tagsListData = res.response.tagsList;
  //     //     this.classList = res.response.vendorTypesList;
  //     //     this.companiesService.sendNameData.next(undefined);
  //     //     this.submit3 = false;
  //     //     this.submit = false;
  //     //   }
  //     // })

  //   }
  //   console.log(value)
  //   this.classList = this.commonTabService.getAllDropdownList.getValue().typeList;
  //   this.companiesService.sendNameData.next(this.enumPopupHeadingNames.ADDCOMPANY);
  //   this.tagsListData.forEach((a) => a.isActive = false);
  //   this.insertUpdateGeneralInfoCompany.generalInfo.markAsVendor = false

  // }
  // getvenvalue(value: boolean) {
  //   if (value == true) {
  //     this.getTagsByStageId(this.enumSaleStage.Vendor);
  //     this.insertUpdateGeneralInfoCompany.generalInfo.markAsCompany = false;
  //     this.showvendor = true;
  //     this.headingNames = this.enumPopupHeadingNames.ADDVENDOR;
  //     if (this.getEditName == this.enumPopupHeadingNames.EDITCOPANY) {
  //       this.getEditName = this.enumPopupHeadingNames.EDITVENDOR;
  //     }
  //     this.stageID = 0;
  //     this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId = this.enumSaleStage.Vendor;
  //    // this.tagsListData = this.commonTabService.getAllDropdownList.getValue().tagsList;
  //     this.classList = this.commonTabService.getAllDropdownList.getValue().vendorTypeList;
  //     console.log( this.classList);
      
     
  //   } else {
  //     this.headingNames = this.enumPopupHeadingNames.ADDCOMPANY;
  //     console.log(value)
  //     this.classList = this.commonTabService.getAllDropdownList.getValue().typeList;
  //     this.companiesService.sendNameData.next(this.enumPopupHeadingNames.ADDCOMPANY);
  //     this.tagsListData.forEach((a) => a.isActive = false);
  //     this.stageID = 1;
  //     this.insertUpdateGeneralInfoCompany.generalInfo.saleStageId = this.enumSaleStage.Customer

  //   }
  // }
  newGetvenvalue(value: boolean){
    if (value == true) {
      this.insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Vendor_List = false
    }
  }
  newGetMakascompanyValue(value: boolean){
    if (value == false) {
      this.insertUpdateGeneralInfoCompany.generalInfo.exclude_From_Company_List = false
    }
  }
}
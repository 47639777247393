<div class="container-fluid d-flex flex-column">
    <div class="row mb-1 d-flex">
        <div class="d-flex justify-content-between align-items-center">
            <span class="col-2 w-330px">
                <app-search-input [inputSearchPlaceholder]="'Search by Value...'" 
                [inputSerachValue]="paginationBaseModel.searchText"
                (inputSerachValueEmit)="searchRoleByText($event)"
                > </app-search-input>
            </span>
            <div class="d-flex">
                <app-button
                 class="px-1" 
                 [buttonLable]="enumButtonName.DELETE"
                 (click)="openDelteModal()"
                 [buttonClass]="idList?.length != 0 ? 'border-red' : 'border-grey cursor-notAllowed '"
                ></app-button>
                <app-button 
                  class="px-1"
                  imgUrl=""
                  [buttonLable]="enumButtonName.IMPORT"
                  [buttonClass]="'border-theme hand'"
                  (click)="openImport()"
                  ></app-button>
                <app-button
                 class="px-1"
                 imgUrl=""
                 (click)="addEditCodeGroupPopup()"
                 [buttonLable]="enumButtonName.ADD"
                 [buttonClass]="'border-theme'"
                 ></app-button>
                 
                 <app-svg-icon
                 [src]="'./assets/SVGIcons/pin.svg'"
                 class="p-l-5 d-flex align-items-center"
                 (click)="pinned =!pinned; openFavouriteDockPopup(pinned)"
                 [ngClass]="pinned == true ? 'pinnedColor' : ''"
               ></app-svg-icon>
            </div>
        </div>
    </div>
    <!-- <app-crm-common-header  [buttonName] ="'Add New'" [isVisible]="false" [footerPath]="enumPath.VENDOR_CONTACT" [footerIcon]="enumUrl.CONTACTS" [footerTitle]="footerTitle.VENDOR_CONTACT" ></app-crm-common-header> -->
    <app-ag-grid
    class="h-100 d-flex flex-column position-relative code-crosswalk"
    [dataModel]="agGridDataModelContacts"
  ></app-ag-grid>
</div>
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  Params,
  RouteReuseStrategy,
  UrlSegment,
} from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { TabsService } from "../components/tabs/tabs.service";
interface RouteStorageObject {
  snapshot: ActivatedRouteSnapshot;
  handle: DetachedRouteHandle;
}

@Component({
  selector: "reuse-routing",
  template: "",
})
// export class CustomRouteReuseStrategy implements RouteReuseStrategy {
//   constructor(public tabService: TabsService) {}
//   private routeStore = new Map<string, DetachedRouteHandle>();
//   // first it calls when leaving a route
//   shouldDetach(route: ActivatedRouteSnapshot): boolean {
//     const path = this.getRoutePath(route);
//     return path && this.tabService.allOpenedTabs.includes(path);
//   }
//   // if detached store it

//   store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
//     const path = this.getRoutePath(route);
//     if (!route.routeConfig) return;
//     if (this.routeStore.has(path)) return;
//     this.routeStore.set(path, handle);
//   }

//   // if you want to attach it back
//   shouldAttach(route: ActivatedRouteSnapshot): boolean {
//     const path = this.getRoutePath(route);
//     if (path == "auth/login/") {
//       this.tabService.tabs = [];
//       this.tabService.allOpenedTabs = [];
//       this.routeStore.clear();
//       this.tabService.addedTabs.next([]);
//     }
//     // return !!route.routeConfig && !!this.routeStore.get(path) && !!route.component;
//     if (this.tabService.allOpenedTabs.length < this.routeStore.size) {
//       this.tabService.allOpenedTabs.map((value) => {
//         this.routeStore = new Map(
//           Array.from(this.routeStore).filter(([key, v]) => key == value)
//         );
//       });
//     }
//     return (
//       path &&
//       this.tabService.allOpenedTabs.includes(path) &&
//       !!this.routeStore.get(path) &&
//       !!route.component
//     );
//   }

//   // if you got a copy of it it will load it
//   retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
//     const path = this?.getRoutePath(route);
//     // if (!route.routeConfig || !this.routeStore.get(path)) return null;
//     // return this.routeStore.get(path)!;
//     if (!route.component) return null;
//     return this.routeStore.get(path);
//   }

//   shouldReuseRoute(
//     future: ActivatedRouteSnapshot,
//     curr: ActivatedRouteSnapshot
//   ): boolean {
//     let reuse = future.routeConfig === curr.routeConfig;
//     return (
//       future.routeConfig === curr.routeConfig || future.data.reuseComponent
//     );
//   }

//   private getRoutePath(route: ActivatedRouteSnapshot): string {
//     let path = "";
//     let curr = route;
//     while (curr) {
//       const urlSegments = curr?.url?.map((segment) => segment.path).join("/");
//       path = urlSegments?.length ? `${urlSegments}/${path}` : path;
//       curr = curr?.parent;
//     }
//     return path;
//   }
// }

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  storedRoutes: { [key: string]: RouteStorageObject } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuseRoute || false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const id = this.createIdentifier(route);
    if (route.data.reuseRoute && id.length > 0) {
      this.storedRoutes[id] = { handle, snapshot: route };
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const id = this.createIdentifier(route);
    const storedObject = this.storedRoutes[id];
    const canAttach = !!route.routeConfig && !!storedObject;
    if (!canAttach) return false;

    const paramsMatch = this.compareObjects(route.params, storedObject.snapshot.params);
    const queryParamsMatch = this.compareObjects(route.queryParams, storedObject.snapshot.queryParams);

    return paramsMatch && queryParamsMatch;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const id = this.createIdentifier(route);
    if (!route.routeConfig || !this.storedRoutes[id]) return null;
    return this.storedRoutes[id].handle;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private createIdentifier(route: ActivatedRouteSnapshot) {
    const segments: UrlSegment[][] = route.pathFromRoot.map(r => r.url);
    const subpaths = ([] as UrlSegment[]).concat(...segments).map(segment => segment.path);
    return segments.length + '-' + subpaths.join('/');
  }

  private compareObjects(base: any, compare: any): boolean {

    for (const baseProperty in { ...base, ...compare }) {

      if (compare.hasOwnProperty(baseProperty)) {
        switch (typeof base[baseProperty]) {
          case 'object':
            if (typeof compare[baseProperty] !== 'object' || !this.compareObjects(base[baseProperty], compare[baseProperty])) {
              return false;
            }
            break;
          case 'function':
            if (typeof compare[baseProperty] !== 'function' || base[baseProperty].toString() !== compare[baseProperty].toString()) {
              return false;
            }
            break;
          default:
            if (base[baseProperty] != compare[baseProperty]) {
              return false;
            }
        }
      } else {
        return false;
      }
    }
    return true;
  }}

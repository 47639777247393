<div class="container-fluid p-0 mt-xl-0 mt-lg-0 mt-md-4 mt-3 container-h">
  <form class="needs-validation" novalidate>
    <div class="row mx-0">
      <div
        class="col-xl-12 p-0"
        *ngFor="let item of InsertUpdateCompanyAdd; let i = index">
        <div class="row mx-0 mb-1">
          <div
            class="col-xl-12 d-flex justify-content-between p-0 mb-1 mt-lg-0 mt-md-3">
            <div class="d-flex">
              <label class="text-primary-color">Address {{ i + 1 }} </label>
              <div (click)="deleteAddress(item,i)" class="d-flex m-l-25 hand" *ngIf="i != 0">
                <app-svg-icon [src]="'./assets/SVGIcons/smDelete.svg'">
                </app-svg-icon>
                <span class="m-l-10 text-danger-color hand ">Delete</span>
              </div>
            </div>
            <label
              *ngIf="i == 0"
              for=""
              class="clear-color hand mb-0"
              (click)="addAnotherAddress()"
              >+ Add another address
            </label>
          </div>
          <div class="d-flex container-fluid p-0">
            <div class="col-xl-3 p-0 col-lg-3">
              <div class="pe-2 me-xl-5 me-lg-4 me-md-2">
                <div class="col p-0 mb-4">
                  <app-input
                    [inputLabel]="'Address Name'"
                    [inputType]="'text'"
                    [inputValueRequired]="true"
                    [inputValue]="item.addressName"
                    [inputReqValidation]="true"
                    [ngClass]="{ 'border-red': !item.addressName && (submit || submit2 )}"
                    (inputValueEmit)="item.addressName = $event;tabNext()">
                  </app-input>
                </div>
                <div class="col p-0 mb-4">
                  <app-dropdown
                    [selectLabel]="'Type'"
                    [data]="addressTypeList"
                    [required]="gedata == enumPopupHeadingNames.ADDCOMPANY || enumPopupHeadingNames.ADDVENDOR || addressHeading == enumPopupHeadingNames.ADDCOMPANY || addressHeading == enumPopupHeadingNames.ADDVENDOR ? true : false"
                    [selectValue]="item.typeId"
                    [ngClass]="{ 'border-red': !item.typeId && (submit || submit2 ) }"
                    (selectValueEmit)="
                      item.typeId = $event; getvalue(item.typeId);tabNext()
                    "
                  ></app-dropdown>
                </div>
                <div class="col p-0">
                  <div class="d-flex checkbox-solid-info show-inactive">
                    <app-checkbox
                      [status]="item.isDefault"
                      (check)="item.isDefault = $event"
                      [lableName]="'Mark as Default for Type'"
                      [lableClass]="'text-light-gray'"
                      [dyanamicId]="'text-light-gray' + i"
                    >
                    </app-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 p-0">
              <div class="pe-2 me-xl-5 me-lg-4 me-md-2">
                <div class="col p-0 mb-4">
                  <app-input
                    [inputLabel]="'Address Line 1'"
                    [inputType]="'text'"
                    [inputValue]="item.address1"
                    (inputValueEmit)="item.address1 = $event"
                  >
                  </app-input>
                </div>
                <div class="col p-0 mb-4">
                  <app-input
                    [inputLabel]="'Address Line 2'"
                    [inputType]="'text'"
                    [inputValue]="item.address2"
                    (inputValueEmit)="item.address2 = $event"
                  >
                  </app-input>
                </div>
                <!-- <div class="col p-0 mb-4">
                  <app-input
                    [inputLabel]="'Address Line 3'"
                    [inputType]="'text'"
                    [inputValue]="item.address3"
                    (inputValueEmit)="item.address3 = $event"
                  ></app-input>
                </div> -->
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 p-0">
              <div class="pe-2 me-xl-5 me-lg-4 me-md-2">
                <div class="col p-0 mb-4">
                  <app-dropdown
                    [selectLabel]="'Country'"
                    [data]="countryList"
                    [selectValue]="item.countryId"
                    [required]="gedata == enumPopupHeadingNames.ADDCOMPANY || addressHeading == enumPopupHeadingNames.ADDCOMPANY ||addressHeading == enumPopupHeadingNames.ADDVENDOR ? true : false"
                    [ngClass]="{ 'border-red': !item.countryId && submit }"
                    (selectValueEmit)="item.countryId = $event;tabNext()"
                  >
                  </app-dropdown>
                </div>
                <div class="col p-0 mb-4">
                  <app-dropdown
                    [selectLabel]="'State/Province'"
                    [data]="stateList"
                    [selectValue]="item.stateId"
                    (selectValueEmit)="item.stateId = $event"
                  >
                  </app-dropdown>
                </div>
                <div class="col p-0 mb-4">
                  <app-input
                    [inputLabel]="'Postal Code'"
                    [inputType]="'text'"
                    [inputMaxlength]="11"
                    onlyNumbers
                    [inputValue]="item.postalCode"
                    (inputValueEmit)="item.postalCode = $event"
                  >
                  </app-input>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 p-0">
              <div class="pe-2 me-xl-5 me-lg-4 me-md-2">
                <div class="col p-0 mb-4">
                  <app-input
                    [inputLabel]="'City'"
                    [inputType]="'text'"
                    [inputMaxlength]="30"
                    onlyAlphabets
                    [inputValue]="item.city"
                    (inputValueEmit)="item.city = $event"
                  >
                  </app-input>
                </div>
                <div class="col p-0 mb-4">
                  <app-input
                    [inputLabel]="'Phone Number'"
                    [inputType]="'text'"
                    onlyNumbers
                    [inputMaxlength]="14"
                    (inputValueEmit)="item.phoneNumber = $event"
                    [inputValue]="item.phoneNumber"
                  >
                  </app-input>
                </div>
                <div class="col p-0 mb-4">
                  <app-input
                    [inputLabel]="'VAT/RFC/CNPJ'"
                    [inputMaxlength]="15"
                    [inputType]="'text'"
                    [inputValue]="item. vaT_RFC_CNPJ"
                    (inputValueEmit)="item. vaT_RFC_CNPJ = $event"
                  >
                  </app-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12 p-0 d-flex justify-content-end">
            <div class="">
              <label
                for=""
                class="text-primary-color hand"
                (click)="cloneAddressInfo(item, i); chekValueRequire()"
                >+ Clone this address
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

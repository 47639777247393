<div *ngIf="!deleteSuccessfulMessage">
  <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 d-flex justify-content-center">
    <h6 class="modal-title text-center text-danger-color font-15">{{enumPopUpTitles.DELETE}}</h6>
  </div>
  <div class="modal-body p-4 mx-2">
    <div class="text-center">
      <p class="font-11  text-gray">You are about to permanently delete a {{title}} record <br> 
        This cannot be undone<br>
        Are you sure you want to delete <span class="text-primary-color font-12">{{fullName}}</span> <br>
        from the {{title=='company'?'company':title}} list?
      </p>

    </div>
  </div>
  <div class="modal-footer pt-0 m-2 mt-0 border-0 justify-content-center">
    <div class="mb-2 me-1 pe-2">
      <app-button [buttonType]="'button'" [button_2]="true"  [buttonClass]="'button button-info me-4'"  (click)="activeModal.close()" [buttonLable]="enumButtonName.CANCEL"></app-button>
      <app-button [buttonType]="'submit'" [button_2]="true"  [buttonClass]="'button button-danger mx-0'"(click)="deleteValue()"  [buttonLable]="enumButtonName.DELETE"></app-button>
    </div>
  </div>
</div>
<div *ngIf="deleteSuccessfulMessage">
  <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 d-flex justify-content-center">
    <h6 class="modal-title text-center text-danger-color font-15">{{enumPopUpTitles.DELETE}}</h6>
  </div>
  <div class="modal-body p-4 mx-2">
    <div class="text-center">
      <span class="font-11 text-gray">Your {{title=='company'?'company':title}} deletion is complete.</span>
    </div>
  </div>
  <div class="modal-footer pt-0 m-2 mt-0 border-0 justify-content-center">
    <div class="mb-2 me-1 pe-2">
      <app-button [buttonType]="'submit'" [button_2]="true"  [buttonClass]="'button button-info mx-0'"  (click)="activeModal.close()"  [buttonLable]="enumButtonName.OKAY"></app-button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { DataService } from "../../../../../shared/services/data.service";
import { StorageService } from "../../../../../shared/services/storage.service";
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Result } from "../../../../../shared/model/response.model";
import {AddRequirementModel} from "../../../requirements/popup/add-requirement/add-requirement.model"
import {URLS} from "src/app/components/sales/sales-api-constants"
@Injectable({
  providedIn: 'root'
})
export class RequirementsService {
  public recordPass = new BehaviorSubject(undefined);
  public id:string='';
  private selectedDataSubject = new BehaviorSubject<any>(undefined);


  setSelectedData(data: any): void {
    this.selectedDataSubject.next(data);
  }


  getSelectedData(): Observable<any> {
    return this.selectedDataSubject.asObservable();
  }

  constructor(private service: DataService,
    public storageService:StorageService) { }
  insertRequirementData(insertRequirementData: AddRequirementModel): Observable<Result> {
    let url = URLS.requirement.insert;
    const myjson = JSON.stringify(insertRequirementData);
    return this.service.post(url, insertRequirementData).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateRequirementData(insertRequirementData: AddRequirementModel): Observable<Result> {
    let url = URLS.requirement.update;
    return this.service.post(url, insertRequirementData).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllRequirementDropdown(): Observable<Result> {
    let url = URLS.dropdown.getAllDropdown;
    return this.service.get(url,true).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }

  getAllMPNDropdownList(): Observable<Result> {
    let url = URLS.dropdown.getAllMPNList;
    return this.service.get(url,true).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }
  getRequirementByMPN(id:string): Observable<Result> {
    let url = URLS.requirement.getByMPN + id;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }
  getRequirementNo(): Observable<Result> {
    let url = URLS.requirement.getNo;
    return this.service.get(url,true).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }
  getRequirementById(id:string): Observable<Result> {
    let url = URLS.requirement.getById + id;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }

  
}

export class ImportDocuments {
}

export class InsertUpdateImportDocumnets {
    importCompanyModel = new ImportCompanyModel();
    importCodeCrosswalkModel = new ImportCodeCrosswalkModel();
    templateData = new TemplateData();
    mappingTemplateList=[];
    sourcingTemplateList=[];
    tempTemplateMappingColumns='';
    isVendor:boolean = false;
}

export class ImportCompanyModel {
    file: string='';
    salesPersonId: string='';
    altSalesPersonId: string='';
    buyersId: string='';
    mappingTemplateId: string='';
}

export class ImportCodeCrosswalkModel {
    file: string='';
    mappingTemplateId: string='';
}

export class TemplateData {
    id: string='';
    fileName:string='';
    sourceId: number=1;
    templateName: string='';
    templateDescription: string='';
    isTemplateCreate: boolean=false;
    templateMappingColumns: TemplateMappingColumns[] = [];
}

export class TemplateMappingColumns {
    id: string='';
    templateId: string='';
    map:[]=[]
    mappedColumn: string='';
    systemColumn: string='';
    status: boolean;
    isRequired: boolean=false;
}



export class ImportCodeCrosswalkData{
    codeCrosswalkData: CodeCrosswalkData[]
    importError: ImportError[]
    duplicateCodeCrosswalk: DuplicateCodeCrosswalk[]
    templateId: string=''
}

export class CodeCrosswalkData {
    id: string=''
    createdBy: string=''
    createdAt: string=''
    updatedBy: string=''
    updatedAt: string=''
    isDeleted: boolean
    deletedBy: string=''
    deletedAt: string=''
    externalId: string=''
    rowNumber: number
    isValid: boolean
    templateId: string=''
    sic: string=''
    sicDescription: string=''
    naics: string=''
    naicsDescription: string=''
    naicsYear: string='';
    industry: string='';
  }

  export class DuplicateCodeCrosswalk {
    type: string='';
    sic: string='';
    naics: string='';
    industry: string='';
    action: boolean;
    rowNo: number;
  }


export class ImportCompanyCommand {
    companyData: CompanyData[]=[] ;
    importError: ImportError[]=[] ;
    duplicateCompanies:DuplicateCompanies[]=[];
    templateId: string='';
    isVendor:boolean = false;
}

export class CompanyData {
    id: string='';
    createdBy: string='';
    createdAt: string='';
    updatedBy: string='';
    updatedAt: string='';
    isDeleted: boolean
    deletedBy: string='';
    deletedAt: string='';
    externalId: string='';
    rowNumber: number=0;
    company: string='';
    stage: string='';
    phone: string='';
    salesperson: string='';
    email: string='';
    type: string='';
    dateContacted: string='';
    companyRating: string='';
    active: string='';
    fax: string='';
    website: string='';
    employees: string='';
    timezone: string='';
    industry: string='';
    sic: string='';
    naics: string='';
    country: string='';
    doNotCall: string='';
    revenue: string='';
    alternateSalesperson: string='';
    recentNotes: string='';
    accountNumber: string='';
    currency: string='';
    sourcingEmail: string='';
    accountingContact: string='';
    state: string='';
    isValid: boolean=false;
    templateId: string='';
  }

  
export class ImportError {
    companyName: string='';
    errorField: string='';
    errorData: string='';
    correctField: string='';
    errorMessage: string='';
    status: boolean=false;
    rowNumber: number;
  }  

export class DuplicateCompanies {
    type: string
    companyName: string
    phoneNumber: string
    address: string
    action: boolean
    rowNo: number
  }

  // constants.ts
export const CROSSWALK_REQUIRED_VALUES = ["SIC Code", "SIC Description", "NAICS Code", "NAICS Description", "NAICS Year", "Industry"];
export const COMPANY_REQUIRED_VALUES = ["Company", "Stage", "Type", "Country"];
export const VENDOR_REQUIRED_VALUES = ["Vendor Name", "Type", "Country"];

  
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { GeneralInformationComponent } from "./general-information/general-information.component";
import { AddressInformationComponent } from "./address-information/address-information.component";
import { ContactInformationComponent } from "./contact-information/contact-information.component";
import { ToastrService } from "ngx-toastr";
import { AddCompanyService } from "./add-company.service";
import { DetailsComponent } from "./details/details.component";
import { AgGridPaginationConfig, TablePaginationModel } from "src/app/shared/components/ag-grid/ag-grid.model";
import { AgGridService } from "src/app/shared/components/ag-grid/ag-grid.service";
import { GetAllCompanyPostModel, InserUpdateCompny, PopupHeadingNames, SaleStage } from "./add-company.model";
import { Button } from "src/app/shared/model/enum";
import { CommonTabService } from "src/app/shared/services/common-tab.service";

@Component({
  selector: "app-add-company",
  templateUrl: "./add-company.component.html",
  styleUrls: ["./add-company.component.scss"],
})
export class AddCompanyComponent implements OnInit {
  enumButtonName = Button;
  @ViewChild("tabset") tabset: TabsetComponent;
  @ViewChild("generalInfo") generalInformationComponent: GeneralInformationComponent;
  @ViewChild("addressInfo") addressInformationComponent: AddressInformationComponent;
  @ViewChild("contactInfo") contactInformationComponent: ContactInformationComponent;
  @ViewChild("detailInfo") detailInfoComponent: DetailsComponent;
  @Input() CompnayID: string;
  @Output("parent") parentFun: EventEmitter<any> = new EventEmitter();
  tab1Disabled: boolean = false;
  tab2Disabled: boolean = true;
  tab3Disabled: boolean = true;
  tab4Disabled: boolean = true;
  paginationBaseModel = new TablePaginationModel();
  getAllCompanyPostModel = new GetAllCompanyPostModel();
  agGridPaginationConfig = new AgGridPaginationConfig();
  insertUpdateGeneralInfoCompanys = new InserUpdateCompny();
  enumPopupHeadingNames = PopupHeadingNames;
  enumSaleStage = SaleStage
  constructor(
    public modalService: NgbModal,
    public ngbActiveModal: NgbActiveModal,
    public activeModal: NgbActiveModal,
    public companys: AddCompanyService,
    public companiesService: AddCompanyService,
    private toastr: ToastrService,
    public agGridService: AgGridService,
    public commonTabService: CommonTabService,
  ) { }
  nextTabActive: boolean = false;
  submit: boolean = false;
  @Input() stagesName: string;
  @Input() headingsNames: string = '';
  @Input() editName: string = '';
  @Input() UpdateValue: string = '';
  nextTab :boolean = false;
  showButton:boolean = false;
  ngOnInit(): void {
    this.editName;
    this.UpdateValue;
    this.stagesName;
    this.headingsNames;
    this.CompnayID;
    if (this.CompnayID) {
      this.getCompanyData(this.CompnayID)
    }
    this.companiesService.nextTab.subscribe(res=>{
      if(res !== undefined){
        this.nextTab = res
      }

    })
  }
  // 18/04/2023----------
  nextTabs() {
    const currentIndex = this.tabset.tabs.findIndex((tab) => tab.active);
    const nextIndex = currentIndex + 1;
    if (nextIndex < this.tabset.tabs.length) {
      const successMessage = this.generalInformationComponent.generalInfocheck();
      if ((successMessage == "success" && currentIndex == 0) || (currentIndex == 0 ? "" : this.addressInformationComponent.checkRequiredValue() == true && currentIndex == 1) || currentIndex == 2) {
        if ((successMessage == "success" && currentIndex == 0)) {
         // this.tab2Disabled = false;
        // this.showButton = true
        } else if ((currentIndex == 0 ? "" : this.addressInformationComponent.checkRequiredValue() == true && currentIndex == 1)) {
        //  this.showButton = true
         // this.tab3Disabled = false;
        } else if (currentIndex == 2 && this.detailInfoComponent.detailsInfocheck() == true) {
        //  this.tab4Disabled = false;
        }
        setTimeout(() => {
          this.tabset.tabs[nextIndex].active = true;
        }, 100)

      }
      else if (successMessage == "email"){        
        this.toastr.error('Please enter a valid email address with the pattern: user@example.com')
      }   
      else if (successMessage == "website"){        
        this.toastr.error('Please enter a valid website address starting with \'http://\' or \'https://\'.');
      } 
      else {
        this.toastr.error("Fill required details");
       // this.showButton = false

      }
    }

  }

  initDatatable() {
    this.paginationBaseModel = {
      sortColumn: "",
      sortDirection: "",
      pageNumber: this.agGridPaginationConfig.currentPageNumber,
      pageSize: this.agGridPaginationConfig.pageSize,
      searchText: this.agGridPaginationConfig.searchText,
      getAll: this.agGridPaginationConfig.pageSize == 1 ? true : false,
      id: this.paginationBaseModel.id,
      isActive: this.paginationBaseModel.isActive,
      stage: this.agGridService.getStage(),
      companyOrVendorId: '',
      documentTypeId:0,
      filters: "",
      companyId: '',
      categoryId: '',
      selectedId: [],
      exportAsPdf: true,
      filtersFromPopup: "",
      searchCompanyText:this.paginationBaseModel.searchCompanyText,
    };
    this.agGridService.tablePaginationData.next(this.paginationBaseModel);
  }
  // For Insert Company
  insetUpdateCompnayData(insetUpdateCompnayData) {
    // let addressTypepId = this.addressInformationComponent.addressTypepId;
    // let accountTypeId = this.contactInformationComponent.accountTypeId;
    this.generalInformationComponent.insertUpdateGeneralInfoCompany.addressDetails = this.addressInformationComponent.InsertUpdateCompanyAdd;
    // if (accountTypeId == undefined || accountTypeId == "") 
    // {
    //   this.generalInformationComponent.insertUpdateGeneralInfoCompany.contactInfo = [];
    // }
    // else 
    if (this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.markAsCompany == false && this.headingsNames == this.enumPopupHeadingNames.ADDVENDOR) {
      this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.salesPersonId = '';
      this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.altSalesPersonId = '';
    }
    if (this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.saleStageId == this.enumSaleStage.Vendor) {
      this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.salesPersonId = '';
      this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.altSalesPersonId = '';
    }
    this.generalInformationComponent.insertUpdateGeneralInfoCompany.contactDetails = this.contactInformationComponent.contactInfoAddList[0]?.firstName == undefined ? [] : this.contactInformationComponent.contactInfoAddList;
    // this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.accountClassId = this.generalInformationComponent.classList.filter((a) => a?.id === this.generalInformationComponent.typeId) ?? [];
    this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.typeId = this.generalInformationComponent.typeId
    this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.tagsId = this.generalInformationComponent.tagsListData.filter((a) => a?.selectedTags === true) ?? [];
    // this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.tagsId=tags.map(a=>a.id);
    // this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.isActive = true;
    this.generalInformationComponent.insertUpdateGeneralInfoCompany.detailsInfo = this.detailInfoComponent.insertUpdateGeneralInfoCompany.detailsInfo;
    const allCompanydData = this.generalInformationComponent.insertUpdateGeneralInfoCompany;
    const tabindex = this.tabset.tabs.findIndex((tab) => tab.active);
    if(this.generalInformationComponent.generalInfocheck() == "success" && this.addressInformationComponent.checkRequiredValue() == true){
    if ((this.generalInformationComponent.generalInfocheck() == "success" && tabindex == 0) || (tabindex == 0 ? "" : this.addressInformationComponent.checkRequiredValue() == true && tabindex == 1) ||
      tabindex == 2 ||
      (tabindex == 3 && this.contactInformationComponent.checkRequiredContactValue() == "Contact Success")) {
        if( tabindex == 2){this.generalInformationComponent.insertUpdateGeneralInfoCompany.contactDetails = []}
      if (insetUpdateCompnayData == 'Add Vendor') {
        this.addVendor(allCompanydData);
      } else if (insetUpdateCompnayData == 'Update Vendor') {
        this.updateVendor(allCompanydData);
      } else if (insetUpdateCompnayData == 'Add Company') {
        this.addCompany(allCompanydData)
      } else if (insetUpdateCompnayData == 'Update Company') {
        this.updateCompany(allCompanydData)
      }
    } else if (tabindex == 3 && this.contactInformationComponent.checkRequiredContactValue() == "Contact Email"){        
      this.toastr.error('Please enter a valid email address with the pattern: user@example.com')
    } else {
      this.submit = true;
      this.toastr.error("Fill All Required Fields");
    }
  }else{
    this.submit = true;
    this.toastr.error("Fill All Required Fields");
  }
  }

  addCompany(allCompanydData) {
    this.companiesService.insertGeneralinpformation(allCompanydData).subscribe((data) => {
      if (data.isSuccess == true) {
        this.toastr.success(data.message);
        this.initDatatable()
        this.activeModal.close({ isSuccess: true });
      }
      else {
        this.toastr.error(data.message);
      }
    });
  }

  updateCompany(allCompanydData) {
    this.companiesService.updateGeneralinpformation(allCompanydData).subscribe((data) => {
      if (data.isSuccess == true) {
        this.toastr.success(data.message);
        this.initDatatable()
        this.activeModal.close({ isSuccess: true });
      }
      else {
        this.toastr.error(data.message);
      }
    });
  }

  addVendor(allCompanydData) {
    this.companiesService.insertVendorIformation(allCompanydData).subscribe((data) => {
      if (data.isSuccess == true) {
        this.toastr.success(data.message);
        this.initDatatable()
        this.activeModal.close({ isSuccess: true });
      }
      else {
        this.toastr.error(data.message);
      }
    });

  }
  updateVendor(allCompanydData) {
    this.companiesService.updateVendorIformation(allCompanydData).subscribe((data) => {
      if (data.isSuccess == true) {
        this.toastr.success(data.message);
        this.initDatatable()
        this.activeModal.close({ isSuccess: true });
      }
      else {
        this.toastr.error(data.message);
      }
    });

  }
  // end resion
  resetId() {
    this.companys.nextTabActiveRequired.next(undefined);
    this.companys.comapanyId.next(undefined);
    this.generalInformationComponent.tagList.map(x => x.isActive = false)
  };



  onTabChange(event) {
    if (event.heading == 'General Information') {
      // this.tab1Disabled = false;
      // this.tab2Disabled = true;
      // this.tab3Disabled = true;
       this.tab4Disabled = true;
      this.generalInformationComponent.tabNext();
    } else if (event.heading == 'Address Information') {
      // this.tab1Disabled = false;
      // this.tab2Disabled = false;
      // this.tab3Disabled = true;
       this.tab4Disabled = true;
      this.addressInformationComponent.tabNext()
    } else if (event.heading == 'Details') {
      // this.tab1Disabled = false;
      // this.tab2Disabled = false;
      // this.tab3Disabled = false;
       this.tab4Disabled = true;
    } else if (event.heading == 'Contact Information') {
      this.tab1Disabled = false;
      this.tab2Disabled = false;
      this.tab3Disabled = false;
      this.tab4Disabled = false;
    }

  }

  getCompanyData(companyId: string) {
    // this.getAllCompanyPostModel.companyId = comapanyId 
    // this.getAllCompanyPostModel.pageNumber =0;
    // this.getAllCompanyPostModel.pageSize =0;
    // this.getAllCompanyPostModel.selectedId =[0];
    // this.getAllCompanyPostModel.stage =  this.editName === this.enumPopupHeadingNames.EDITVENDOR ? 'Vendor' : 'ALL';
    // this.getAllCompanyPostModel.getAll =true;
    this.companiesService.GetDataByCompanyId(companyId).subscribe((data) => {
      if (data.isSuccess === true) {
        const datas = [];
        datas.push(data.response)
        datas.forEach((data) => {
          this.insertUpdateGeneralInfoCompanys = data
        });
        this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo = this.insertUpdateGeneralInfoCompanys.generalInfo;
        if(this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo){
          let classList = this.commonTabService.getAllDropdownList.getValue().vendorTypeList;
          this.generalInformationComponent.typeId = this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.typeId;
          this.generalInformationComponent.typeName = !!classList.find(x => x.name === 'Manufacturer' &&  x.id == this.generalInformationComponent.typeId );

          this.generalInformationComponent.tabNext();
        }
        this.generalInformationComponent.tagsListData?.map((x) => {
          const data = this.insertUpdateGeneralInfoCompanys.generalInfo.tagsId.find(a=>a.id == x.id);
          if(data != null && data != undefined){
            x.selectedTags = true;
          }
          else{
            x.selectedTags = false;
          }
        })

       // this.generalInformationComponent.tagsListData = this.insertUpdateGeneralInfoCompanys.generalInfo.tagsId;
        //this.generalInformationComponent.classList =  this.insertUpdateGeneralInfoCompanys.generalInfo.accountClassId;
        this.insertUpdateGeneralInfoCompanys.addressDetails.forEach((data) => {
          this.addressInformationComponent.getAddressData.push(data)
        })
        this.addressInformationComponent.InsertUpdateCompanyAdd = this.addressInformationComponent.getAddressData;
        if (this.addressInformationComponent.InsertUpdateCompanyAdd.length == 0) {
          this.addressInformationComponent.addAnotherAddress();
        }

        this.addressInformationComponent.addressTypepId = this.addressInformationComponent.InsertUpdateCompanyAdd.map((a) => a.typeId)
        this.detailInfoComponent.insertUpdateGeneralInfoCompany.detailsInfo = this.insertUpdateGeneralInfoCompanys.detailsInfo;
        this.insertUpdateGeneralInfoCompanys.contactDetails.forEach((res) => {
          this.contactInformationComponent.getContactInfoData.push(res)
        })
        this.contactInformationComponent.contactInfoAddList = this.contactInformationComponent.getContactInfoData;
        if (this.contactInformationComponent.contactInfoAddList.length == 0) {
          this.contactInformationComponent.addAnotherContact();
        }
        // this.contactInformationComponent.getContactInfoData.push(data.response[0].contactInfo[0]);

        this.generalInformationComponent.stageID = Number(this.insertUpdateGeneralInfoCompanys.generalInfo.saleStageId);
        this.generalInformationComponent.typeId = this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.typeId;
        this.generalInformationComponent.tagsId = this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.tagsId.map(a=>a.id);
        this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.saleStageId = this.insertUpdateGeneralInfoCompanys.generalInfo.saleStageId;


        //  this.generalInformationComponent.insertUpdateGeneralInfoCompany.generalInfo.saleStageId = this.insertUpdateGeneralInfoCompanys.generalInfo.saleStageId == '2' ? 'Lead' : this.insertUpdateGeneralInfoCompanys.generalInfo.saleStageId == '3' ? 'Prospect' : this.insertUpdateGeneralInfoCompanys.generalInfo.saleStageId == '1' ? 'Customer' : this.insertUpdateGeneralInfoCompanys.generalInfo.saleStageId == '4' ? 'Vendor' : '';
        // this.generalInformationComponent.classList?.forEach((element) => {
        //   // if (element.isActive === true) {
        //   //   this.generalInformationComponent.typeId = element.id;
        //   // }
        // });
      }
    })
  }

}

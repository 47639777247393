import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject, Observable } from 'rxjs';
import { baseUrl , identitySocketUrl ,smxSocketUrl } from 'src/environments/environment';
import { CommonTabService } from './common-tab.service';

@Injectable({
  providedIn: 'root'
})

export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private smxHubConnection: signalR.HubConnection;
  public valuePassContact = new BehaviorSubject(undefined);


  constructor( private commonTabService: CommonTabService,) {
            this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(identitySocketUrl, {  // Update URL here
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();
  
    this.startConnection();
    this.hubConnection.onclose(() => this.startConnection());
    // ********************************************************************
    this.smxHubConnection = new signalR.HubConnectionBuilder()
    .withUrl(smxSocketUrl, { 
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    })
    .configureLogging(signalR.LogLevel.Information)
    .build();

  this.smxStartConnection();
  this.smxHubConnection.onclose(() => this.smxStartConnection());


  }

  startConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      this.hubConnection
        .start()
        .then(() => {
          console.log('Connection established with Identity SignalR hub');
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          console.error('Error connecting to SignalR hub:', error);
          observer.error(error);
        });
    });
  }

  receiveMessage(): Observable<string> {
    return new Observable<string>((observer) => {
      this.hubConnection.on('received', (message: string) => {
        observer.next(message);
      });
    });
  }

  sendMessage(message: string): void {
    this.hubConnection.invoke('SendChange', message);
  }
  public stopConnection(): void {
    if (this.hubConnection) {
      this.hubConnection.stop()
        .then(() => {
          console.log('Identity SignalR connection stopped.');
        })
        .catch(err => console.error('Error while stopping SignalR connection: ', err));
    }
  }
  

   // ********************************************************************
   smxStartConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      this.smxHubConnection
        .start()
        .then(() => {
          console.log('SMX Connection established with SignalR hub');
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          console.error('SMX Error connecting to SignalR hub:', error);
          observer.error(error);
        });
    });
  }
  smxReceiveMessage(): Observable<string> {
    return new Observable<string>((observer) => {
      this.smxHubConnection.on('received', (message: string) => {
        observer.next(message);
      });
    });
  }
  smxDetailsInfoMessage(): Observable<string> {
    return new Observable<string>((observer) => {
      this.smxHubConnection.on('CompanyDetails', (message: string) => {
        observer.next(message);
      });
    });
  }


  smxSendMessage(message: string): void {
    this.smxHubConnection.invoke('SendChange', message);
  }
  smxReceiveListMessage(): Observable<string> {
    return new Observable<string>((observer) => {
      this.smxHubConnection.on('dropdownList', (message: string) => {
        observer.next(message);
        let data = message['data']
        this.commonTabService.getAllDropdownList.next(data);
      });
    });
  }



  public smxStopConnection(): void {
    if (this.smxHubConnection) {
      this.smxHubConnection.stop()
        .then(() => {
          console.log('SMX SignalR connection stopped.');
        })
        .catch(err => console.error('Error while stopping SignalR connection: ', err));
    }
  }
  

}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { DeleteContactAddress, GetAllCompanyPostModel, InserUpdateCompny, InsertAccountAddress, InsertContactInfo} from './add-company.model';
import { Result } from 'src/app/shared/model/response.model';
import { DataService } from "src/app/shared/services/data.service";
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage.service';
import { URLS } from 'src/app/components/crm/crm-api-constants';

@Injectable({
  providedIn: 'root'
})
export class AddCompanyService {
  public hideNexst              = new BehaviorSubject<boolean>(false);
  public nextTabActiveRequired  = new BehaviorSubject<any>(undefined);
  public comapanyId             = new BehaviorSubject<number>(undefined);
  public checkPostDone          = new BehaviorSubject<any>(undefined);
  public callGetCompany         = new BehaviorSubject<any>(undefined);
  public sendNameData           = new Subject<string>();
  public addressNexst           = new Subject<boolean>();
  public nextTab              = new BehaviorSubject<boolean>(false);
  public companyName         = new BehaviorSubject<any>(undefined);


  constructor(
    public router: Router, 
    private service: DataService,
    private storageService: StorageService
    ) { }

    hideNextBtn(hide:boolean){
      this.hideNexst.next(hide)
  
    }
    addNextBtn(hide: boolean) {
      this.addressNexst.next(hide);
    }

  insertGeneralinpformation(insertUpdateGeneralInformation: InserUpdateCompny): Observable<Result> {
    insertUpdateGeneralInformation.currentUrl = this.router.url;
    let dat = JSON.stringify(insertUpdateGeneralInformation)
    let url = URLS.company.insertCompany;
    const myJson = JSON.stringify(insertUpdateGeneralInformation);
    return this.service.post(url, insertUpdateGeneralInformation).pipe<Result>(tap ((response:any) =>{
      return response;
    }))
  };


  updateGeneralinpformation(insertUpdateGeneralInformation: InserUpdateCompny): Observable<Result> {
    insertUpdateGeneralInformation.currentUrl = this.router.url;
    let dat = JSON.stringify(insertUpdateGeneralInformation)
    let url = URLS.company.updateCompany;
    const myJson = JSON.stringify(insertUpdateGeneralInformation);
    return this.service.post(url, insertUpdateGeneralInformation).pipe<Result>(tap ((response:any) =>{
      return response;
    }))
  };

  insertVendorIformation(insertUpdateGeneralInformation: InserUpdateCompny): Observable<Result> {
    insertUpdateGeneralInformation.currentUrl = this.router.url;
    let dat = JSON.stringify(insertUpdateGeneralInformation)
    let url = URLS.vendor.insertVendor;
    const myJson = JSON.stringify(insertUpdateGeneralInformation);
    return this.service.post(url, insertUpdateGeneralInformation).pipe<Result>(tap ((response:any) =>{
      return response;
    }))
  };

 updateVendorIformation(insertUpdateGeneralInformation: InserUpdateCompny): Observable<Result> {
  insertUpdateGeneralInformation.currentUrl = this.router.url;
    let dat = JSON.stringify(insertUpdateGeneralInformation)
    let url = URLS.vendor.updateVendor;
    const myJson = JSON.stringify(insertUpdateGeneralInformation);
    return this.service.post(url, insertUpdateGeneralInformation).pipe<Result>(tap ((response:any) =>{
      return response;
    }))
  };


  
  // address informaion :
  InserAddressInformation(insertAccountAddress:InserUpdateCompny): Observable<Result>{
    let url = URLS.companyAddress.insertCompanyAddress;
    const myJson = JSON.stringify(insertAccountAddress);
    return this.service.post(url, insertAccountAddress).pipe<Result>(tap ((response:any) =>{
    return response;
    }))
  };
  
  // contact informaion ||personal Info :
  insertContactInformation(contactInfo:InsertContactInfo): Observable<Result>
  {
    let url = URLS.companyContact.insertCompanyContact;
    const myJson = JSON.stringify(contactInfo);
    return this.service.post(url, contactInfo).pipe<Result>(tap ((response:any) =>{
      return response;
    }))
  };

 
  GetTagsByStageId(StageId:number): Observable<Result>
  {
    let url =`${URLS.dropDown.getTagsByStageId}?stageId=${StageId}`;
    let body = {stageId:StageId};
    return this.service.post(url, body).pipe<Result>(tap ((response:any) =>{
    return response;
    }));
  }

  GetDataByCompanyId(companyId:string): Observable<Result>
  {
    let url = URLS.company.getAllCompanyAddressContactInfo;
    return this.service.post(url, {companyId:companyId}).pipe<Result>(tap ((response:any) =>{
    return response;
    }));
  }

  // vendor type and tag list

 
  DeleteAddress(deleteContactAddress: DeleteContactAddress): Observable<Result>{
    let data = JSON.stringify(deleteContactAddress)
    let url = URLS.companyAddress.deleteCompanyAddress;
    return this.service.post(url,deleteContactAddress).pipe<Result>(tap ((response:any) =>{
    return response;
    }));
  }

  DeleteContact(deleteContactAddress: DeleteContactAddress): Observable<Result>{
    let url =URLS.companyContact.deleteCompanyContact;
    return this.service.post(url,deleteContactAddress).pipe<Result>(tap ((response:any) =>{
    return response;
    }));
  }

  GetCodeCrosswalkList(): Observable<Result>{
    let url = URLS.dropDown.getCodeCrosswalkList;
    return this.service.get(url,true).pipe<Result>(tap ((response:any) =>
    {
      return response;
    }));
  }

  }



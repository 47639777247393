import { Component, Input, OnInit } from '@angular/core';
import { InserUpdateCompny } from '../add-company.model';
import { CommonDropList } from 'src/app/shared/model/common.model';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { AddCompanyService } from '../add-company.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @Input() headName
  insertUpdateGeneralInfoCompany  = new InserUpdateCompny();
  industryList    : CommonDropList[]=[];
  scopeList       : CommonDropList[]=[];
  currencyList    : CommonDropList[]=[];
  codeCrosswalkList:any []= [];
  sicValues:any []= [];
  naicsValues:any []= [];
  industryValues:any []= [];
  industryId                : string;
  sicCode                   : string;
  naicsCode                 : string;
  industryDisable:boolean = false;
  @Input() comID
  errorMessageNAICS:boolean = false;
  errorMessageSIC:boolean = false;
  minDate: NgbDate;
  constructor( public commonTabService : CommonTabService ,
    public companiesService: AddCompanyService,
    private toaster: ToastrService,

    ) { 
      const today = new Date();
      this.minDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  
  }

  ngOnInit(): void 
          {
            this.headName
            this.industryList = this.commonTabService.getAllDropdownList.getValue().industryList;
            this.scopeList    = this.commonTabService.getAllDropdownList.getValue().scopeList;
            this.currencyList = this.commonTabService.getAllDropdownList.getValue().currencyList;
            this.GetCodeCrosswalkList();
            if (this.comID) {
              this.getCompanyData(this.comID)
            }
    }
    getCompanyData(companyId: string) {
      this.companiesService.GetDataByCompanyId(companyId).subscribe((data) => {
        if (data.isSuccess === true) {
          const datas = [];
          datas.push(data.response)
          datas.forEach((data) => {
         this.sicCode = data.detailsInfo.sicCode;
         this.naicsCode =  data.detailsInfo.naicsCode;
         this.industryId = data.detailsInfo.naicssicId;
         if(data.detailsInfo.naicssicId ){
          this.industryDisable = true;
        }
        if(data.detailsInfo.resellerIdExpirationDate){
          this.resellerDate = this.convertStringToDate(data.detailsInfo.resellerIdExpirationDate) 
         }
          });
        }})
      
      }
          GetCodeCrosswalkList() {
            this.companiesService.GetCodeCrosswalkList().subscribe((res) => {
              if (res.isSuccess) {
                this.codeCrosswalkList =res?.response?.codeCrosswalkList;
                this.sicValues = this.codeCrosswalkList?.map(item => item.sic);
                this.naicsValues = this.codeCrosswalkList?.map(item => item.naics);
          this.industryValues = this.codeCrosswalkList?.map(item => ({
              id: item.id, 
              name: item.industry
            }));
              }
            })
          }
          industrySelected(industryId: string) {
            if(industryId == ''){
              this.sicCode = null;
              this.naicsCode = null;
              this.industryDisable = false;
              this.errorMessageSIC = false;
              this.errorMessageNAICS = false;
            }else{
              const selectedItem = this.codeCrosswalkList.find(item => item.id === industryId);
              if (selectedItem) {
                this.sicCode = selectedItem.sic;
                this.naicsCode = selectedItem.naics;
                this.insertUpdateGeneralInfoCompany.detailsInfo.naicssicId = selectedItem.id;
                this.industryId = selectedItem.id;
                this.errorMessageSIC = false;
                this.errorMessageNAICS = false;
                if( this.insertUpdateGeneralInfoCompany.detailsInfo.naicssicId){
                  this.industryDisable = true;
                }
              }
            }
          }
          naicsSelected(naics: string) {
            if(naics == ''){
              this.naicsCode = null;
              this.sicCode = null;
              this.insertUpdateGeneralInfoCompany.detailsInfo.naicssicId  = null;
              this.industryId = null;
              this.industryDisable = false;
              this.errorMessageNAICS = false;
              this.errorMessageSIC = false;
            }else{
              this.industryDisable = false;
              this.errorMessageNAICS = false;
              const selectedItem = this.codeCrosswalkList.find(item => item.naics === naics);
              if (selectedItem) {
                this.industryId = selectedItem.id;
                this.sicCode = selectedItem.sic;
                this.naicsCode = selectedItem.naics;
                this.insertUpdateGeneralInfoCompany.detailsInfo.naicssicId = selectedItem.id;
                if( this.insertUpdateGeneralInfoCompany.detailsInfo.naicssicId){
                  this.industryDisable = true;
                  this.errorMessageSIC = false;
                  this.errorMessageNAICS = false;
                }
              }else if (this.naicsValues.includes(naics)) {
                this.errorMessageNAICS = false;
              } else {
                this.errorMessageNAICS = true;
              }
  
            }
          }
         sicSelected(sic: string) {
          if(sic == ''){
            this.sicCode = null;
            this.naicsCode = null;
            this.insertUpdateGeneralInfoCompany.detailsInfo.naicssicId  = null;
            this.industryId = null;
            this.industryDisable = false;
            this.errorMessageSIC = false;
            this.errorMessageNAICS = false;
          }else{
            this.industryDisable = false;
            this.errorMessageSIC = false;
            const selectedItem = this.codeCrosswalkList.find(item => item.sic === sic);
            if (selectedItem) {
              this.industryId = selectedItem.id;
              this.naicsCode = selectedItem.naics;
              this.sicCode = selectedItem.sic;
              this.insertUpdateGeneralInfoCompany.detailsInfo.naicssicId = selectedItem.id;
              if( this.insertUpdateGeneralInfoCompany.detailsInfo.naicssicId){
                this.industryDisable = true;
                this.errorMessageSIC = false;
                this.errorMessageNAICS = false;
              }
            }
            else if (this.sicValues.includes(sic)) {
              this.errorMessageSIC = false;
            } else {
              this.errorMessageSIC = true;
            }

          } 
        
          }

          formatter = (result: string) => result.toUpperCase();

          searchSicCode: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
            text$.pipe(
              debounceTime(200),
              distinctUntilChanged(),
              map((term) =>
                term === '' ? [] : this.sicValues.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
              ),
            );

            
            searchNAICSCode: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
            text$.pipe(
              debounceTime(200),
              distinctUntilChanged(),
              map((term) =>
                term === '' ? [] : this.naicsValues.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
              ),
            );

            detailsInfocheck(): true {
              if (!this.errorMessageSIC && !this.errorMessageNAICS) {   
                return true
              }else {
                this.toaster.error("Fill required details");
              }   
            }
resellerDate:any
            reselletId(){
              this.insertUpdateGeneralInfoCompany.detailsInfo.resellerIdExpirationDate = this.dateConvertedToString(this.resellerDate);

            }
            dateConvertedToString(date) {
              return  (date.month < 10 ? '0' : '') + date.month + '/' +(date.day < 10 ? '0' : '') + date.day + '/'  + date.year;
            }
            convertStringToDate(inputDate: string): NgbDate | null {
              const dateParts = inputDate.split('/');
              
              if (dateParts.length === 3) {
                const year = parseInt(dateParts[2], 10);
                const month = parseInt(dateParts[0], 10);
                const day = parseInt(dateParts[1], 10);
              
                if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
                  return { year, month, day } as NgbDate;
                }
              }
              return null;
            }  
          
          
}
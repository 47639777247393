import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-intent-to-buy',
  templateUrl: './intent-to-buy.component.html',
  styleUrls: ['./intent-to-buy.component.scss']
})
export class IntentToBuyComponent implements OnInit,ICellRendererAngularComp {
  params;

  constructor() { }

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    this.params = params;
    return true;
  }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Button, LabelsName } from 'src/app/shared/model/enum';
import { UploadDocumentsComponent } from '../../../pop-ups/upload-documents/upload-documents.component';
import { UploadDocumentsEnum } from '../../../settings-tab/settings-tab.model';
import { CompaniesService } from '../../../../companies/companies.service';
import { InsertCertification } from '../../../../companies/companies.model';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { StingToDateService } from 'src/app/shared/services/stringToDate.service';

@Component({
  selector: 'app-add-certifications',
  templateUrl: './add-certifications.component.html',
  styleUrls: ['./add-certifications.component.scss']
})
export class AddCertificationsComponent implements OnInit {
  insertCertification = new InsertCertification() ;
  selectedDate ='';
  selectedDate1 ='';
  submit: boolean = false;
  certificationList = [ ];
  enumButtonName = Button;
  @Input() isAddValue: boolean;
  id:string='';
  fileName:string = '';
  guId:string = '';
  type:string = '';
  @Input() dateIssued  :any;
  @Input() expirationDate  :any;



  hideIcon:boolean = false;
  enumFilterLabel = LabelsName;
  @Input() getAllDropDownModel = ['option1','option2','option3'];
  Certifications = 'Certifications*';
  enumUploadDocuments=UploadDocumentsEnum;
  generalInfoLables = LabelsName;
  insertUpdateGeneralInfoCompany = '';
  // @Input() addUpdateAddress: any;
  certification = {
    Id: null, 
  };





  constructor( public activeModal   : NgbActiveModal,
               public modalService  : NgbModal,
               public companiesService   : CompaniesService,
               private toastr: ToastrService,
               public commonTabService: CommonTabService,
               public stringToDateService: StingToDateService,



    ) { }

  ngOnInit(): void {
    this.certificationList = this.commonTabService.getAllDropdownList.getValue().certificationList;
    if (this.dateIssued && this.expirationDate) {
      this.dateIssued = this.stringToDateService.convertStringToDate(this.dateIssued);
      this.expirationDate = this.stringToDateService.convertStringToDate(this.expirationDate)
    } else {
      if(this.insertCertification.dateIssued){
        this.dateIssued = this.stringToDateService.convertStringToDate(this.insertCertification.dateIssued);
      }
     else if(this.insertCertification.expirationDate){
        this.expirationDate = this.stringToDateService.convertStringToDate(this.insertCertification.expirationDate);
      }

    }

  }


  checkRequiredValue(): boolean {
    if (this.certification.Id) {
      return true;
    } else {
      this.submit = true;
    }
  }
  addCertification(){
    const res = this.companiesService.getResponseData();
    if (this.checkRequiredValue()) {
      this.insertCertification.id =  this.id ;
      this.insertCertification.dateIssued = this.stringToDateService.dateConvertedToString(this.dateIssued);
      this.insertCertification.expirationDate = this.stringToDateService.dateConvertedToString(this.expirationDate);
     // this.insertCertification.dateIssued = this.selectedDate;
     // this.insertCertification.expirationDate = this.selectedDate1;
      this.insertCertification.guid =  res?.response?.guId??this.guId;
      this.insertCertification.certificationId =  this.certification.Id;
      this.insertCertification.vendorId =this.companiesService.id;
      this.insertCertification.fileName = res?.response?.fileName ??this.fileName;
      this.insertCertification.type = res?.response?.type ??this.type;
      this.companiesService.insertCertification(this.insertCertification).subscribe(res=>{
        if (res.isSuccess) {
          this.toastr.success(res.message);
          this.companiesService.isLoadData.next(true);
          this.activeModal.close({ isSuccess: true, addUpdateAddress: this.insertCertification });
        }
        if (!res.isSuccess) {
          this.toastr.error(res.message);
        }
       })
    }
  }

  editCertification(){
    const res = this.companiesService.getResponseData();
    if (this.checkRequiredValue()) {
      this.insertCertification.id =  this.id ;
    //  this.insertCertification.dateIssued = this.selectedDate;
     // this.insertCertification.expirationDate = this.selectedDate1;
      this.insertCertification.dateIssued =  this.stringToDateService.dateConvertedToString(this.dateIssued);
      this.insertCertification.expirationDate =  this.stringToDateService.dateConvertedToString(this.expirationDate);

      this.insertCertification.guid =  res?.response?.guId??this.guId;
      this.insertCertification.certificationId =  this.certification.Id;
      this.insertCertification.vendorId =this.companiesService.id;
      this.insertCertification.fileName = res?.response?.fileName ??this.fileName;
      this.insertCertification.type = res?.response?.type ??this.type;
      this.companiesService.updateCertification(this.insertCertification).subscribe(res=>{
        if (res.isSuccess) {
          this.toastr.success(res.message);
          this.companiesService.isLoadData.next(true);
          this.activeModal.close({ isSuccess: true, addUpdateAddress: this.insertCertification });
        }
        if (!res.isSuccess) {
          this.toastr.error(res.message);
        }
       })
    }
  }


  changeDateIssued(queryDate) {
    this.selectedDate = queryDate;
    this.insertCertification.dateIssued = this.selectedDate;
    this.hideIcon = false;
  }
  changeDateExpiration(queryDate) {
    this.selectedDate1  = queryDate;;
    this.insertCertification.expirationDate = this.selectedDate1;
    this.hideIcon = false;
  }

  openUploadFilePopups() {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "markAsAddEditAddress",
    };
    const modalRef = this.modalService.open(UploadDocumentsComponent, modalOption);
    modalRef.componentInstance.pageType= this.enumUploadDocuments.CERTIFICATION;
    modalRef.result.then(res=>{
      this.CertificationsDocuments();
    })
}
CertificationsDocuments(){
  // this.companiesService.insertUpdateCertification(this.companiesService.id).subscribe(res=>{
  // })
}


clearAllDetails(f:NgForm){
  f.value.dateIssued      = '';
  f.value.dateExpiration  = '';
  this.selectedDate       = '';
  this.selectedDate1       = '';
  this.certification.Id = '';
}

onSubmit(f: NgForm) {
  if (f.invalid) {
      return;
  }
}
clear(f:NgForm){
 f.value.dateIssued = ''  ;
 this.selectedDate = '';
 this.selectedDate1 = '';
}
clear1(f:NgForm){
  f.value.dateExpiration = ''  ;
  this.selectedDate1 = '';
 }
 


}

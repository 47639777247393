<!-- <div class="select-wrapper">
  <select
    #mySelect
    class="w-100 p-l-15"
     [(ngModel)]="selectedValue"
      (ngModelChange)="onSelectChange($event)"
  >
  <option value="">-Select Field-</option>
    <option *ngFor="let item of selectlist">
      {{ item }}
    </option>
  </select>
</div> -->
<!-- <div>
  <ng-container *ngIf="showDropdown; else inputTemplate">
    <input class="form-control mt-1 f-13" 
    [ngClass]="{
      'map-highlighted-row': params?.data?.companyName === errorselectlist && params?.data?.correctField === '' && !submitted
    }"
    [(ngModel)]="inputValue" 
    [placeholder]="placevalue"
    (ngModelChange)="onValueChange()" />
  </ng-container>


  <ng-template #inputTemplate>
    <div class="select-wrapper"  *ngIf="!ratingVisible; else elseBlock2">
      <select [ngClass]="{
        'map-highlighted-row': params?.data?.companyName === errorselectlist && params?.data?.correctField === '' && !submitted
      }" [ngClass]="{
        'highlighted-row': (
          (params?.data?.systemColumn === 'Company' && params?.data?.mappedColumn.trim() === '') ||
          (params?.data?.systemColumn === 'Stage' && params?.data?.mappedColumn.trim() === '') ||
          (params?.data?.systemColumn === 'Type' && params?.data?.mappedColumn.trim() === '') ||
          (params?.data?.systemColumn === 'Fax' && params?.data?.mappedColumn.trim() === '')||
          (params?.data?.systemColumn === 'Website' && params?.data?.mappedColumn.trim() === '')||
          (params?.data?.systemColumn === 'Country' && params?.data?.mappedColumn.trim() === '')
        ) && !submit
      }" #mySelect class="w-100 p-l-15 text-truncate p-r-25" [(ngModel)]="selectedValue"
        (ngModelChange)="onSelectChange($event)">
        <option value="">-Select Field-</option>
        <option *ngFor="let item of selectlist" [value]="item "> {{ item }} </option>
      </select>
    </div>
    <ng-template #elseBlock2>
      <div class="h-100 w-100">
        <star-rating [ratingValue]="ratingValue" (newItemEvent)="getRatingValue($event)">
        </star-rating>
      </div>
      
    </ng-template>
  </ng-template>
</div> -->
<!-- 
(params?.data?.systemColumn === 'Fax' && params?.data?.mappedColumn.trim() === '')||
(params?.data?.systemColumn === 'Website' && params?.data?.mappedColumn.trim() === '')|| -->



<div [ngSwitch]="getType()">
  <div *ngSwitchCase="enumFieldTypeAndPlaceHolder.INPUT">
    <div *ngIf="!shouldApplyOnlyNumbers">
    <input class="form-control mt-1 f-13" [ngClass]="{ 
      'map-highlighted-row': params?.data?.companyName === errorselectlist && params?.data?.correctField === '' && !submitted
    }" [(ngModel)]="value" [placeholder]="'Enter '+placevalue" (ngModelChange)="valueChange($event)" />
  </div>
  <div *ngIf="shouldApplyOnlyNumbers">
    <input class="form-control mt-1 f-13" onlyNumbers
    [attr.maxlength]="isSicCode ? 8 : 4" 
          [ngClass]="{ 
      'map-highlighted-row': params?.data?.companyName === errorselectlist && params?.data?.correctField === '' && !submitted
    }" [(ngModel)]="value" [placeholder]="'Enter '+placevalue" (ngModelChange)="valueChange($event)"
    (focus)="checkIfSicCode()" />
  </div>

</div>
  <!-- <div *ngSwitchCase="enumFieldTypeAndPlaceHolder.RATING">
    <div class="h-100 w-100">
      <fieldset class="rate" [id]="params.rowIndex">
        <ng-container *ngFor="let items of ratingStarList">
          <input
            type="radio"
            [id]="params.rowIndex + items.id"
            [name]="rating + params.rowIndex"
            [(ngModel)]="params.data.correctField"
            [value]="items.value"
            (click)="valueChange(items.value)"
          />
          <label class='elabel'  for="{{params.rowIndex + items.id }}" [class]="items.className"> </label>
        </ng-container>
      </fieldset>
    </div>
  </div>
  <div *ngSwitchCase="enumFieldTypeAndPlaceHolder.SWITCH">

  </div> -->
  <div *ngSwitchDefault>
    <div class="select-wrapper">
      <select [ngClass]="{
      'map-highlighted-row': params?.data?.companyName === errorselectlist && params?.data?.correctField === '' && !submitted
    }" [ngClass]="{
      'highlighted-row': (
        (params?.data?.systemColumn === 'Company' && params?.data?.mappedColumn.trim() === '') ||
        (params?.data?.systemColumn === 'Stage' && params?.data?.mappedColumn.trim() === '') ||
        (params?.data?.systemColumn === 'Type' && params?.data?.mappedColumn.trim() === '') ||
        (params?.data?.systemColumn === 'Country' && params?.data?.mappedColumn.trim() === '') ||
        (params?.data?.systemColumn === 'SIC Code' && params?.data?.mappedColumn.trim() === '') ||
        (params?.data?.systemColumn === 'SIC Description' && params?.data?.mappedColumn.trim() === '') ||
        (params?.data?.systemColumn === 'NAICS Code' && params?.data?.mappedColumn.trim() === '') ||
        (params?.data?.systemColumn === 'NAICS Year' && params?.data?.mappedColumn.trim() === '') ||
        (params?.data?.systemColumn === 'NAICS Description' && params?.data?.mappedColumn.trim() === '')||
        (currentTab == tabName?.CODECROSSWALK_TAB ? (params?.data?.systemColumn === 'Industry' && params?.data?.mappedColumn.trim() === ''):'') ||
        (routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH?(params?.data?.systemColumn === 'Vendor Name' && params?.data?.mappedColumn.trim() === ''):'')
      ) && !submit
    }" #mySelect class="w-100 p-l-15 text-truncate p-r-25" [(ngModel)]="value"
        (ngModelChange)="valueChange($event)">
        <option value="">-Select {{placevalue}}</option>
        <option *ngFor="let item of selectlist" [value]="item "> {{ item }} </option>
      </select>
    </div>
  </div>
</div>



import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CompaniesService } from 'src/app/components/crm/companies/pages/companies/companies.service';

@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective implements OnInit{
    @Input() public spiedTags = [];
    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection: string;
    isExapnded: any;

    constructor(private _el: ElementRef,public companiesService: CompaniesService,) {}

    ngOnInit(): void {
        this.companiesService.isExpanded.subscribe(res => {
          console.log('the res ', res)
          this.isExapnded = res
        })
      }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        let currentSection: string;
        const container = this._el.nativeElement;
        const containerTop = container.offsetTop;
        const containerBottom = containerTop + container.offsetHeight;
        const scrollTop = event.target.scrollTop;
        
        const visibleArea = container.offsetHeight * -3.2; // Adjust this threshold as needed
        const visibleAreaExpand = container.offsetHeight * -0.2; // Adjust this threshold as needed

        for (let i = 0; i < container.children.length; i++) {
            const element = container.children[i];
            if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                const elementTop = element.offsetTop - containerTop;
                const elementBottom = elementTop + element.offsetHeight;

                if (this.isExapnded == true) {
                    if (elementTop <= scrollTop + visibleArea && elementBottom >= scrollTop + visibleArea) {
                        currentSection = element.id;
                        break;
                    }
                } else {
                    if (elementTop <= scrollTop + visibleAreaExpand && elementBottom >= scrollTop + visibleAreaExpand) {
                        currentSection = element.id;
                        break;
                    }
                } 
            }
        }

        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }
}

import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommonModelPopUpComponent } from '../common-model-pop-up/common-model-pop-up.component';
import { PopUpTitles } from '../../model/enum';
import { ImportDocumentsService } from 'src/app/components/import-documents/import-documents.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {
  @Output() select: EventEmitter<any> = new EventEmitter();
  enumPopUpTitles = PopUpTitles;
  selctedValue:any;
  @Input() importCodecrosswalk    : boolean = false;
  constructor(
    private modalService: NgbModal,
    public importDocumentService: ImportDocumentsService,
    public toaster: ToastrService,

  ) { }

  public testForm: NgForm;
  public isDropDownOpen: boolean = false;
  public dropdown: string = '';
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    // Check if the clicked element is not the div and not the trigger button
    const isClickInsideDiv = event.target instanceof Element && event.target.closest('.ul-container');
    const insideList = event.target instanceof Element && event.target.closest('.input-container');
    console.log('ths dfasd ', insideList)
    console.log('thesadsd i', isClickInsideDiv)
    if (isClickInsideDiv) {
      this.isDropDownOpen = false
    }
    if (!insideList && this.isDropDownOpen) {
      this.isDropDownOpen = false
    }

  }
  @Input('options') options: any[];
  @Output() inputValueEmit: EventEmitter<string> = new EventEmitter();

  ngOnInit() {
    this.options.forEach((opt) => {
      opt.isActive = false;
    });
    this.options.splice(0, 0, { name: '', isActive: true });
  }

  toggleDropdown() {
    console.log('inside the toggle')
    this.isDropDownOpen = !this.isDropDownOpen;
  }

  selectOption(evt: any, optionIndex: number, id) {
    
    console.log('the id is ', id)
    this.options.forEach((opt: any, index: number) => {
      opt.isActive = optionIndex === index;
    });
    this.dropdown = evt.target.innerText;
  }
  deleteOption(event, index, name) {
    console.log('the evnt is ', this.options)

    console.log("coolll", index);
    const values = "";
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "markAsActiveInactive",
    };
    const modalRef = this.modalService.open(CommonModelPopUpComponent, modalOption);
    modalRef.componentInstance.title = this.enumPopUpTitles.DELETE_TEMPLATEID;
    let template = name
    modalRef.componentInstance.templateName = template;
    modalRef.result.then((result) => {
      console.log('the result sss ', result)
      if (result.confirm) {
        this.importDocumentService.deleteTemplateByTemplateId(index).subscribe(res => {
          console.log('the delet is ', res)
          if (res.isSuccess) {
            this.toaster.success(res.message);
            this.inputValueEmit.emit(res.message)
            this.selectedData('');
          } else {
            this.toaster.error(res.message);
          }
        })
      }
    });
  }
  clearInput() {
    this.dropdown = ''
  }




  selectedData(item:any){
    this.selctedValue = item;
    this.select.emit(item?.id??'')

  }
}

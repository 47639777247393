export class AddCompany 
{
}

//company dropdown Model //
export class CommonDropdownModel 
  {
    id          : string;
    name        : string;
    isActive    : boolean;
    labelName   : string;
    colorCode   : string;
    selectedTags: boolean;
  }
   //Filter popup
   export class AllStaticText 
   {
    heading       : string = "Filters";
    clearfilter   : string = "Clear Filters";
    companyRating : string = 'Company Rating';
    class         : string = "Class";
    type          : string = "Type";
    clear         : string = "Clear";
    active        : string =  "Active";
    apply         : string = "Apply";
    cancel        : string = "Cancel";
    tags          : string = "Tags";
    vendorType: string = 'Vendor Type';
    rating: string = "Rating";
  }

  //typelist
  export class TypeList {
    id: number;
    name: string;
    isActive:boolean;
  }

  // Insert address information
  export class InsertAccountAddress {
    insertAccounts: InsertAccount[] = []
  }

  export class InsertAccount {
    id             : number;
    companyId      : number;
    addressTypeId  : number;
    countryId      : number;
    accountId      : number;
    name           : string;
    contactId      : number;
    contactName    : string;
    phoneNumber    : string;
    faxNumber      : string;
    city           : string;
    state          : string;
    zipCode        : string;
    addressVAT     : string;
    fullAddress    : string;
    isDefault      : boolean=false;
    isActive       : boolean;
    isDeleted      : boolean;
    value          
  }
  //end
  
  // Insert contact Information
     export class InsertContactInfo {
      insertPeoples: InsertPeople[] = [];
    }
    export class InsertPeople {
      id: string='';
      companyId: string='';
      firstName: string='';
      lastName: string='';
      title: string='';
      genderId: string='';
      departmentId: string='';
      location: string='';
      timeZoneId: string='';
      email: string='';
      mainOfficeNumber: string='';
      directNumber: string='';
      phoneNumber: string='';
      mobileNumber: string='';
      faxNumber: string='';
      skype: string='';
      xing: string='';
      linkedIn: string='';
      markAsPrimaryContact: boolean=false;
      markAsAccountingContact: boolean=false;
      doNotCall: boolean=false;
      doNotEmail: boolean=false;
      markAsPrimaryPurchasingContact: boolean=false;
      notes: string='';
      isActive: boolean=false;
    }
  // inser people / contact info end


  
  // contact info
  export class ContactInformation{
    firstName         : string;
    lastName          : string;
    title             : string;
    gender            : string;
    department        : string;
    location          : string;
    timezone          : string;
    email             : string;
    mainOfficeNumber  : string;
    directNumber      : string;
    mobileNumber      : string;
    faxNumber         : string;
    primaryContact    : boolean;
    accountingContact : boolean;
    doNotCall         : boolean;
    purchasingContact : boolean;
    note              : string;
  };
// for new changes api modal
export class InserUpdateCompny
{
  generalInfo                  = new  GeneralInfoCompny();
  addressDetails               : AddressesInfoList[] =[];
  contactDetails               : ContactInfoList[] =[];
  detailsInfo                  = new DetailInfo(); 
  currentUrl                   : string;
}
export class GeneralInfoCompny
{
  id                        : string;
  companyName               : string;
  emailAddress              : string;
  phoneNumber               : string;
  faxNumber                 : string;
  stateId                   : string;
  countryId                 : string;
  website                   : string;
  timeZoneId                : string;
  salesPersonId             : string;
  altSalesPersonId          : string;
  buyerRepId                : string;
  contactReminder           : boolean;
  doNotCall                 : boolean;
  markAsVendor              : boolean;
  exclude_From_Vendor_List  : boolean;
  typeId                    : string;
  tagsId                    : any[];
  saleStageId               : string;
  intentToBuyId             : string;
  leadScoreId               : string;
  overallRating             : number;
  // extra field added 
  manufacturerId:string;
  markAsCompany:boolean=false;
  exclude_From_Company_List  : boolean;

  // 
  vendorName                : string;
  approvedVendorList        :boolean;
}

export class AddressesInfoList
{
      id            : string;
      companyId     : string;
      addressName   : string;
      typeId        : string;
      address1      : string;
      address2      : string;
      countryId     : string;
      stateId       : string;
      postalCode    : string;
      city          : string;
      phoneNumber   : string;
      vaT_RFC_CNPJ  : string;
      isDefault     : boolean;
      vendorId      : string;
}
export class ContactInfoList
{
    id                               : string = '';
    companyId                        : string;
    firstName                        : string;
    lastName                         : string;
    title                            : string;
    genderId                         : string;
    departmentId                     : string;
    location                         : string;
    timeZoneId                       : string;
    email                            : string;
    mainOfficeNumber                 : string;
    directNumber                     : string;
    phoneNumber                      : string;
    mobileNumber                     : string;
    faxNumber                        : string;
    skype                            : string;
    xing                             : string;
    linkedIn                         : string;
    markAsPrimaryContact             : boolean;
    markAsAccountingContact          : boolean;
    doNotCall                        : boolean;
    doNotEmail                       : boolean;
    markAsPrimaryPurchasingContact   : boolean;
    notes                            :string;
   vendorId                          : string;
}

export class DetailInfo {
  dAndBNumber               : number;
  naicssicId                : string;
  // industryId                : string;
  // sicCode                   : string;
  // naicsCode                 : string;
  scopeId                   : string;
  entityType                : string;
  employee                  : string;
  revenue                   : string;
  parentCompany             : string;
  currencyId                : string;
  taxId                     : string;
  vaT_RFC_CNPJ              : string;
  resellerId                : string;
  resellerIdExpirationDate  : any;
  notes                     : string;
}

 export class AccountClassIdList
 {
    id                      : string;
    name                    : string;
    labelName               : string;
    colorCode               : string;
    isActive                : boolean
  }

  export class TagsList
    {
      id        : string;
      name      : string;
      labelName : string;
      colorCode : string;
      isActive  : boolean;
    }
  export class TagsListData{
    tagId:number;
    tagName: string;
    isActive:boolean;
  }

  export class DeleteCompanyAddress
  {
    ids: string[]
  }

  export class InserUpdateAddress
  {
    addressDetail  : InsertUpdateAccountAddress[] =[];
  }
  
  export class InsertUpdateAccountAddress{
    id: string='';
    companyId: string='';
    addressName: string='';
    typeId: string='';
    address1: string='';
    address2: string='';
    countryId: string='';
    stateId: string='';
    postalCode: string='';
    city: string='';
    phoneNumber: string='';
    vaT_RFC_CNPJ: string='';
    isDefault: boolean=false;
  }
  export class GetAllCompanyPostModel{
      sortColumn        : string ;
      sortDirection     : string ;
      pageNumber        : number ;
      pageSize          : number;
      searchText        : string;
      getAll            : boolean;
      companyId         : number;
      stage             : string;
      selectedId        : number[];
      filters           : string;
      filtersFromPopup  : string;
      isActive          : boolean;
      exportAsPdf       : boolean;
  }

  export class DeleteContactAddress
  {
    ids: string[];
  }

  export enum PopupHeadingNames
  {
    ADDCOMPANY    = 'Add Company',
    ADDVENDOR     = 'Add Vendor',
    EDITCOPANY    = 'Edit Company',
    UPDATECOMPANY = 'Update Company',
    EDITVENDOR    = 'Edit Vendor',
    UPDATEVENDOR  = 'Update Vendor',
    ADD_USER = 'Add User',
    EDIT_USER = 'Edit User',
    ADD_ROLE = 'Add Role',
    EDIT_ROLE  = "Edit Role",
    ASSIGN_COMMISSION_ROLE = 'Assign Commission Role'
  }

  export enum SaleStagestring{
    LEAD = 'Lead',
    CUSTOMER = 'Customer',
    VENDOR = 'Vendor',
    PROSPECT = 'Prospect'

  }

  export enum SaleStage{
    Customer  = "1" ,
    Lead      = "2" ,
    Prospect  = "3" ,
    Vendor    = "4" ,
}
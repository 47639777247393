import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { UpdateCellData, UpdateCellDataIntentToBuy, UpdateStatus, UpdateVendorStatus } from "../ag-grid.model";
import { CrmService } from "src/app/components/crm/crm.service";
import { ToastrService } from "ngx-toastr";
import { AgGridService } from "../ag-grid.service";
import { TabsService } from "../../tabs/tabs.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { CommonModelPopUpComponent } from "../../common-model-pop-up/common-model-pop-up.component";
import { PopUpTitles, RoutePath, TabNames } from "src/app/shared/model/enum";
import { CodeCrosswalkService } from "src/app/components/admin/code-crosswalk/code-crosswalk.service";
import { InsertUpdateCodeCrosswalk, UpdateStatusCodeCrosswalk } from "src/app/components/admin/code-crosswalk/pages/code-crosswalk.model";

@Component({
  selector: "app-ag-switch",
  templateUrl: "./ag-switch.component.html",
  styleUrls: ["./ag-switch.component.scss"],
})
export class AgSwitchComponent implements OnInit, ICellRendererAngularComp {
  enumTabNames = TabNames;
  public cellValue!: string;
  checked = true;
  params: any;
  // updateCellData= new UpdateCellData();
  updateCellData = new UpdateStatus();
  updateVendorCellData = new UpdateVendorStatus();


  currentTab: any;
  enumPopUpTitles   = PopUpTitles;
  insertUpdateCodeCrosswalk = new InsertUpdateCodeCrosswalk();
  updateStatusCodeCrosswalk = new UpdateStatusCodeCrosswalk();
  routePath = RoutePath;


  
  constructor(
    private crmService               : CrmService,
    private toastr                   : ToastrService,
     public agGridService            : AgGridService,
     public tabsService              : TabsService,
     private modalService            : NgbModal,
     public codeCrosswalkService     : CodeCrosswalkService,

     ) {}

  ngOnInit(): void {
     this.tabsService.currentTab.subscribe(res => {

      if (res) {
        this.currentTab = res
      }
    })
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellValue = this.getValueToDisplay(params);
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return false;
  }

  buttonClicked() {
    alert(`${this.cellValue} medals won!`);
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
  checkedHandler(event) {
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, event);
   // this.updateCellData.saleStageId = '1';
    // this.updateCellData.companyIds = this.params?.data?.id;
   // this.updateCellData.companyIds.push(this.params?.data?.id);
   // this.updateCellData.leadScoreId = String(event);
   if(this.params?.data?.saleStageName == "Vendor"){
     this.updateVendorCellData.active = event;
      this.updateVendorCellData.vendorId = this.params?.data?.id;
   }else if(this.params?.data?.vendorId){
    this.updateVendorCellData.active = event;
   this.updateVendorCellData.contactId = this.params?.data?.id;}
   else{
    this.updateCellData.active = event;
    if (this.params?.data?.saleStageId) {
      this.updateCellData.companyId = this.params?.data?.id;
    } else {
      this.updateCellData.contactId = this.params?.data?.id;
    }
   }
    if (window.location.pathname === this.routePath.CODECROSSWALK_ROUTEPATH) {
     // this.crosswalkFunction(event.target.checked);
     this.crosswalkValue(event);
    } else {
      if(this.params?.data?.saleStageName == "Vendor" || this.params?.data?.vendorId){
        this.crmService.updateVendorStatus(this.updateVendorCellData).subscribe(res=>{
          if(res.isSuccess){
            this.toastr.success(res.message);
            this.crmService.swichChanged.next(true)
          }
        })
      }else{
        this.crmService.updateRatingStatus(this.updateCellData).subscribe(res=>{
          if(res.isSuccess){
            this.toastr.success(res.message);
            this.crmService.swichChanged.next(true)
          // this.agGridService.toggleHeaderExpandIcon.next(false)
          }
        })
      }
    }
  }

  crosswalkValue(value){
    this.updateStatusCodeCrosswalk.active =  value;
    this.updateStatusCodeCrosswalk.id = this.params.data.id;
    this.codeCrosswalkService.updateSatusCodeCrosswalk(this.updateStatusCodeCrosswalk).subscribe((res)=>{
      if(res.isSuccess){
        this.toastr.success(res.message);
         this.codeCrosswalkService.valuePass.next(true);
      }else {
        this.toastr.error(res.message)
      }
    })

  }
  


  crosswalkFunction(value){
    const values = this.params.data;
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "markAsActiveInactive",
    };
    const modalRef = this.modalService.open(CommonModelPopUpComponent, modalOption);
    modalRef.componentInstance.title = !values.active == false ? this.enumPopUpTitles.MARK_AS_ACTIVE : this.enumPopUpTitles.MARK_AS_INACTIVE;
    modalRef.componentInstance.fullName = values.sic;
    modalRef.componentInstance.pageType = 'multilevel';
    modalRef.result.then((result) =>  {
      if (result.confirm)  {
        this.params.data.active =  value;
        this.insertUpdateCodeCrosswalk = this.params.data;
        this.codeCrosswalkService.updateCodeCrosswalk(this.insertUpdateCodeCrosswalk).subscribe((res)=>{
          if(res.isSuccess){
            this.toastr.success(res.message);
            this.codeCrosswalkService.valuePass.next(true);
          }else {
            this.toastr.error(res.message)
          }
        })
      } 
    });
  }

  
}

<div class="modal-content">
  <div class="modal-header px-4 border-0  pt-4">
    <h6 class="modal-titel ms-3 mb-0 text-primary-color font-15">Preview</h6>
  </div>
  <div class="modal-body px-5">
    <app-custom-loader
    class="position-absolute top-50 start-50 translate-middle"
    style="z-index: 10 ; margin-top: -25px;"
    [isLoaderLoading]="isLoadings"
   ></app-custom-loader>

    <div class="row">
     <div class="preview-wrapper p-2 d-flex align-items-center justify-content-center">
      <ng-container>
      {{other}}
    </ng-container>
      <!-- <ng-container  *ngIf="jpg && isLoadings==false ">
        <img [src]="url" height="200" class="preview-image">
      </ng-container> -->
      <!-- <ng-container *ngIf="pdf">
       <img src="../../../../../../../../assets/images/image/pdf.png" width="100px">
      </ng-container>   -->
      <ng-container *ngIf="docx">
        <img src="../../../../../../../../assets/images/image/doc.png" width="100px">
      </ng-container>     
      <ng-container *ngIf="excel" >
        <img src="../../../../../../../../assets/images/image/txt-file.png" width="100px">
      </ng-container>   
      <ng-container *ngIf="xlsx" >
        <img src="../../../../../../../../assets/images/image/xlsx.png" width="100px">
          </ng-container> 
      <ng-container *ngIf="csv" >
        <img src="../../../../../../../../assets/images/image/csv.png" width="100px">
     </ng-container>   
     </div>
    </div>
  </div>
  <div class="modal-footer px-4 border-0 pb-4">
    <div class="justify-content-end px-2">
      <app-button
        [button_2]="true"
        [buttonClass]="'button button-info'"
        [buttonLable]="enumButtonName.CANCEL"
        (click)="activeModal.close(false)">
      </app-button>
    </div> 
  </div>
</div>

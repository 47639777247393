import { VendorList } from './../../procurement/vendor-list/pages/vendor-list/vendor-list.model';
import { Button, DisplayName, LabelsName, LablesNames, PDFContactDisplayName, PDFDisplayName, PDFVendorDisplayName, PermissonAccess, RoutePath, TabNames } from './../../../shared/model/enum';
import { DeleteCompany, DeleteContactOrCompanyModel, GetAllDropDownModel } from './../companies/pages/companies/companies.model';
import { CrmService } from "./../crm.service";
import { AgGridService } from "./../../../shared/components/ag-grid/ag-grid.service";
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  AssignDropdown,
  RemoveCompanyFilterModel,
} from "../companies/pages/companies/companies.model";
import {
  AgGridPaginationConfig,
  SaveUserSetting,
  TablePaginationModel,
} from "src/app/shared/components/ag-grid/ag-grid.model";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { CommonModelPopUpComponent } from "src/app/shared/components/common-model-pop-up/common-model-pop-up.component";
import { FooterTitle, Path, PopUpTitles, Url } from "src/app/shared/model/enum";
import { ToastrService } from "ngx-toastr";
import { AddCompanyComponent } from "../companies/pop-ups/add-company/add-company.component";
import { AssignComponent } from "../companies/pop-ups/assign/assign.component";
import { ImportCompanyComponent } from "../companies/pop-ups/import-company/import-company.component";
import { DockService } from "src/app/shared/components/footer/dock.service";
import { FilterComponent } from "../companies/pop-ups/filter/filter.component";
import { Filters } from "src/app/shared/model/filter.model";
import { StorageService } from "src/app/shared/services/storage.service";
import { SidebarNotificationService } from "src/app/shared/components/sidebar-notification/sidebar-notification.service";
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { BehaviorSubject, Observable, Subscription, timeInterval } from 'rxjs';
import { AddContactComponent } from '../contacts/pop-ups/add-contact/add-contact.component';
import { UserSetting } from '../crm.model';
import { DeleteCompanyPopupComponent } from '../../admin/company-recycle-bin/popups/delete-company-popup/delete-company-popup.component';
import { RestoreComponent } from '../../admin/company-recycle-bin/popups/restore/restore.component';
import { AddActivityComponent } from '../activity-manager/pop-ups/add-activity/add-activity.component';
import { AddActivitTitle } from 'src/app/shared/model/common.model';
import { Menu } from 'src/app/shared/services/nav.service';
import { Router } from '@angular/router';
import { AddRequirementComponent } from '../../sales/requirements/popup/add-requirement/add-requirement.component';
import {ArchivedService} from '../archived-companies/archived-companies.service'
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable'
import * as XLSX from 'xlsx';
import { CommonService } from "src/app/shared/services/common.service";
import { AddCompanyService } from '../companies/pop-ups/add-company/add-company.service';
import { AddMailingListComponent } from '../companies/pop-ups/add-mailing-list/add-mailing-list.component';
import {StingToDateService} from '../../../shared/services/stringToDate.service'
import {PartsListData} from '../../parts-and-inventory/parts-and-inventory.model'
import { AddiconList } from '../../authentication/login/login.model';

@Component({
  selector: "app-crm-common-header",
  templateUrl: "./crm-common-header.component.html",
  styleUrls: ["./crm-common-header.component.scss"],
})
export class CrmCommonHeaderComponent implements OnInit, OnDestroy {
  enumButtonName          = Button;
  enumItemLablesNames     = LablesNames;
  enumTabNames            = TabNames;
  paginationBaseModel     = new TablePaginationModel();
  agGridPaginationConfig  = new AgGridPaginationConfig();
  partsListData           = new  PartsListData ();
  // paginationBaseModel= new TablePaginationModel();
  //#region All Input
  @Input() isVisible: boolean;
  @Input() footerIcon: string;
  @Input() footerTitle: string;
  @Input() footerPath: string;
  @Input() buttonName: string
  @Input() buttonImport: string = 'Import';
  showlist:boolean = false;
  showNewlist:boolean = false;

  //#endregion
  @ViewChildren('buttonRefs') buttonRefs: any;

  //#region All Output
  filtersFromPopup: string = "";
  //#endregion

  //#region List Declaration
  dropdownData: AssignDropdown[] = [];
  buttonWidths = [];
  dropdownDataList =
  [
    {
      id: "2",
      name: "Leads",
      labelName: "Stage",
      colorCode: null,
      isActive: false,
    },
    {
      id: "3",
      name: "Prospects",
      labelName: "Stage",
      colorCode: null,
      isActive: false,
    },
    {
      id: "1",
      name: "Customers",
      labelName: "Stage",
      colorCode: null,
      isActive: false,
    },
  ]
  //#endregion

  //#region model Declaration
  //#endregion
  getSiebarValues: any;
  active = false;
  pinned: boolean = false;
  enumPopUpTitles = PopUpTitles;
  companyName: string[] = [];
  contactName: string[] = [];
  vendorContactName: string[] = [];
  contactReycleBin: string[] = [];
  companyID: any[] = [];
  contactID: any[] = [];
  sendCompanyID : string [] =[];
  sendContactID :string [] = [];
  base64: any;
  file: Blob;
  fileName: string;
  enumUrl = Url;
  enumPath = Path;
  enumFooterTitle = FooterTitle;
  stagesName: string;
  selectFilterOptionlist: any[] = [];
  expanded1: boolean = false;
  isDropDown = false;
  id: number;
  isLoading: boolean = false;
  buttonSum = 0
  addToIndex = 0
  removeCompanyFilterModel = new RemoveCompanyFilterModel();
  // @ViewChild("mySelect", { static: true }) mySelect: ElementRef;
  @ViewChild("mySelect") mySelect: ElementRef;
  selectedData: any[] = [];
  selectedRequirementData: any[] = [];
  isButtonDisabled: boolean = false;
  companyGridApi:any;
  companyColumnApi:any;
  //filter show variables//
  @ViewChild('fileterButton') fileterButton: ElementRef;
  headerData:any
  show: any;
  toggle: boolean = false;
  showValfirst: any;
  arr: any[] = [];
  downArrow: string = "./assets/SVGIcons/downarrow.svg";
  upArrow: string = "./assets/SVGIcons/uparrow.svg";
  currentTab = ''
  width: number
  isSideBarOpend = false
  screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
  count = 0;
  valueGet = 0;
  finalShow: number;
  bigShow: number
  getAllDropDownModel = new GetAllDropDownModel();
  deleteContactModel = new DeleteContactOrCompanyModel();
  deleteCompanyModel = new DeleteCompany();
  enumAddActivitTitle = AddActivitTitle;
  userSetting = new UserSetting();
  allUserSettings: any
  saveUserSetting = new SaveUserSetting();
  enumPermissonAccess = PermissonAccess
  switchSubscription: Subscription;
  starSubscription: Subscription;
  stringArrayCompanyID = [];
  stringArrayContactID =[];
  ////////////////////
  testingArr = [
    'Companies.Can.View.Company',
    'Companies.Can.View.All.Companies',
    'Companies.Can.Add.Company',
    'Companies.Can.Edit.Company',
    'Companies.Can.Delete.Company'
  ];
  assign_Button: boolean;
  delete_Button: boolean;
  add_Contact_Button: boolean;
  add_Company_Button: boolean;
  add_Task_Button: boolean;
  send_Email_Button: boolean;
  archive_Button: boolean;
  import_Companies_Button: boolean;
  userID: any;
  ResMsgFromCompanyOrContact: string;

  // defaultColumns = DisplayName;
  defaultColumns = PDFContactDisplayName;
  defaultColumns1 = PDFDisplayName;
  defaultColumns2 = PDFVendorDisplayName;
route;
routerPath = RoutePath

  /////////////////////
  constructor(
    public agGridService: AgGridService,
    public modalService: NgbModal,
    public crmService: CrmService,
    private toastr: ToastrService,
    private dockService: DockService,
    private elRef: ElementRef,
    private sidebarNotificationService: SidebarNotificationService,
    public storage: StorageService,
    public tabsService: TabsService,
    private router: Router,
    public  archivedService: ArchivedService,
    public  commonService: CommonService,
    public companiesService: AddCompanyService,
    public stingToDateService: StingToDateService,

    
  ) {

    this.sidebarNotificationService.isSidebarNotificationShow.subscribe(res => {
      if (res != undefined) {
        // this.show = this.valueGet;
        this.getSiebarValues = res.bool;
        if (res.bool === true) {
        }
        this.isSideBarOpend = res.bool
        this.show = this.selectFilterOptionlist.length;

        this.ngAfterView()
      }

    })

    this.getScreenWidth().subscribe((res) => {
      this.width = res;
    });

  }

  @HostListener("window:resize", ["$event"])
  onResize(event: { target: { innerWidth: number } }) {
    this.show = this.selectFilterOptionlist.length;
    this.addToIndex = 1;
    this.width = event.target.innerWidth;
    this.ngAfterView()
  }
  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }

  @HostListener("document:click", ["$event.target"])
  onClickOutside(target: HTMLElement) {
    if (!this.mySelect?.nativeElement?.contains(target)) {
      this.active = false;
    }
  }

  formatTooltipLabel(labelName: string): string {
    switch (labelName) {
      case "excludedFromVendorlist":
         // return "Excluded From Vendor List";
         return this.route == this.routerPath.COMPANY_ROUTEPATH?"Include Excluded" : "Excluded From Vendor List";
      case "AVL":
          return "AVL";
      case "NAVL":
          return "Non AVL";
      case "startRating":
          return "Start Rating";
      case "endRating":
          return "End Rating";
      case "fromDate":
          return "From Date";
      case "toDate":
          return "To Date";
      case "Contacted Date":
          return "Date Contacted";
      case "SICCode":
          return "SIC Code";
      case "NAICS Code":
          return "NAICS Code";
      default:
          // Split the label name by uppercase letters
          const words = labelName.split(/(?=[A-Z])/);

          // Add space between words and convert to lowercase
          const formattedName = words.map(word => word.toLowerCase()).join(' ');

          // Convert the first letter of each word to uppercase
          return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
  }
}


downloadPDF(){
const header = this.getHeaderAndData().header;
const data = this.getHeaderAndData().data;
this.commonService.generatePdfAndPrint(data,header,'company PDF');
}

getHeaderAndData():{header:any[],data:any[]}{
const nodes  =  this.companyGridApi.getRenderedNodes()
const data = nodes.map((x)=>x.data);

const getColumns = this.companyColumnApi.getColumns();
const columns =  getColumns.map((x)=>({
 title: x.colDef.headerName,
name:x.colDef.field,
}))
const defaultColumsList = []
if(this.currentTab == 'contacts' || this.currentTab == 'vendor-contact'){
  for(let item in this.defaultColumns){
    defaultColumsList.push(this.defaultColumns[item])
  }
}else if(this.currentTab == 'vendor-list'){
  for(let item in this.defaultColumns2){
    defaultColumsList.push(this.defaultColumns2[item])
  }
}else{
for(let item in this.defaultColumns1){
  defaultColumsList.push(this.defaultColumns1[item])
}
}
const defalutColumnsList = columns.filter((x)=> defaultColumsList.some((y)=> x.title == y ));

return {header:defalutColumnsList , data:data }
}

downLoadExcel(){
const columns = this.getHeaderAndData().header;
const data = this.getHeaderAndData().data;
const headData = [columns?.map(column => column?.title)];
const bodyData = data?.map(row => columns?.map(column => row[column?.name]));
this.commonService.export(headData,bodyData,'company.xlsx');
}

  ngOnInit(): void {
    this.route = this.router.url
  this.agGridService.gridApi.subscribe((res)=>{
    if(res){
      this.companyGridApi = res;
    }
  })

  this.agGridService.gridColumnApi.subscribe((res)=>{
    if(res){
      this.companyColumnApi = res;
    }
  })

   this.userID =  this.storage.retrieve('userId')

    let isActiveTrue = false
    let isStar = false
    this.crmService.isRating.subscribe(res => {
      isStar = res

    }
    )
    this.starSubscription = this.agGridService.ratingChanged.subscribe(res => {
      if (isStar) {
        this.changeTableData()
      }
    })
    this.crmService.isActive.subscribe(res => {
      isActiveTrue = res
    })
    this.switchSubscription = this.crmService.swichChanged.subscribe(res => {
      this.crmService.swichChanged.observed
      if (isActiveTrue) {
        this.changeTableData()
      }
    })
    this.crmService.getAllFilterDropDownList().subscribe((res) => {
      this.getAllDropDownModel = res?.response;
    })

    this.crmService.getAllFilterDropDownList().subscribe((res) => {
      this.crmService.dropdownAllList.next(res.response);
    });
    this.pinned =
      this.dockService?.pinnedlist?.find((a) => a?.path == this.footerPath)
        ?.pinned ?? false;
    if (!this.pinned) {
      this.getPinnedValue()
    }
    this.accountTypeDropdown();
    this.initDatatable();
    this.ngAfterView()


    this.agGridService.selectionChanged.subscribe((res) => {
      if (res) 
      {
        // this added for temporary plz remove when bind data
        if(this.route == '/parts-and-inventory/parts-list'){
          const ress:any = res;
          this.selectedData = ress?.api?.getSelectedRows();
        }else{
          this.selectedData = this.agGridService.getGridApi().getSelectedRows();
        }
        this.companyName = this.selectedData.map((items) => {return items.vendorName || items.companyName; });
        this.contactName = this.selectedData.map((items) => { return items.contactFullName});
        this.vendorContactName = this.selectedData.map((items) => { return items.fullName});
        this.contactReycleBin = this.selectedData.map((items) => { return items.contactName});
        this.companyID = this.selectedData.map((items) => {return items.id;});
        this.contactID = this.selectedData.map((items) => { return items.id;});
        if(this.currentTab == 'vendor-contact' || this.currentTab == 'contacts'){
          this.stringArrayContactID = Array.from(this.contactID, num => num?.toString());
        }else{
          this.stringArrayCompanyID = Array.from(this.companyID, num => num?.toString());
        }
      }
    });
    this.tabsService.currentTab.subscribe(res => {

      if (res) {
        this.currentTab = res
      } else {
        this.currentTab = this.tabsService.thisTab
      }
    })
    this.getCompanyFilter();
    this.changePermissionByTab();

  }


  changePermissionByTab() {
    if (this.currentTab == this.enumTabNames.COMPANIES) {
      this.add_Company_Button = this.testingArr.includes(this.enumPermissonAccess.CAN_ADD_COMPANY)
    }
    if (this.currentTab == this.enumTabNames.LEADS) {

    }
    if (this.currentTab == this.enumTabNames.CUSTOMERS) {

    }
    if (this.currentTab == this.enumTabNames.PROSPECTS) {

    }
    if (this.currentTab == this.enumTabNames.CONTACTS) {
      this.add_Company_Button = this.testingArr.includes(this.enumPermissonAccess.CAN_ADD_COMPANY)
    }
  }
  //#region getStageDropDownData

  initDatatable() {
    this.paginationBaseModel = {
      sortColumn: "",
      sortDirection: "",
      pageNumber: this.agGridPaginationConfig.currentPageNumber,
      pageSize: this.agGridPaginationConfig.pageSize,
      searchText: this.agGridPaginationConfig.searchText,
      getAll: this.agGridPaginationConfig.pageSize == 1 ? true : false,
      id: this.paginationBaseModel.id,
      isActive: this.paginationBaseModel.isActive,
      stage: this.agGridService.getStage(),
      companyId: '',
      categoryId: '0',
      companyOrVendorId: '',
      documentTypeId:0,
      filters: "",
      selectedId: [],
      exportAsPdf: true,
      filtersFromPopup: this.agGridService.getFilter(),
     searchCompanyText:this.paginationBaseModel.searchCompanyText,
    };
    this.agGridService.tablePaginationData.next(this.paginationBaseModel);
    this.stagesName = this.paginationBaseModel.stage;
  }
  getPinnedValue() {
    this.crmService.getUserSetting()
      .subscribe(res => {
        if (res?.isSuccess) {
          // let json = JSON.parse(res?.response?.userSetting) ?? {}
          let json = JSON.parse(res?.response) ?? {}

          this.pinned =
            json.pinnedValues?.find((a) => a?.path == this.footerPath)
              ?.pinned ?? false;
             
          if (json.pinnedValues && !this.dockService.pinnedlist) {
            this.storage.store('pinnedValues', json.pinnedValues)
            this.dockService.pinnedlist = json.pinnedValues
          }
        }
      })
  }
  accountTypeDropdown() {
    this.agGridService.accountTypeSelectDropdown().subscribe((res) => {
      if (res.isSuccess == true) {
        this.dropdownData = res?.response?.stageList;
      
      }
    });
  }

  //#endregion
  currentSelection: string = 'All'; 
  setCurrentSelection(option: any,id:any): void {
    this.currentSelection = option;
    this.paginationBaseModel.stage = id;
    this.agGridService.setStage(id);
    this.agGridService.tablePaginationData.next(this.paginationBaseModel);

  }

  getCompanyDataByStageValue() {
    this.agGridService.tablePaginationData.next(this.paginationBaseModel);
  }

  searchTextFilter(searchText) {
    this.agGridService.tablePaginationData.next(this.paginationBaseModel);
  }

  OpenFilterModal() {
    if (!this.isButtonDisabled) {
    this.isButtonDisabled = true;
    this.crmService.getAllFilterDropDownList().subscribe((res) => {
      this.getAllDropDownModel = res?.response;
    })
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      // size: "lg",
      windowClass: "filterCompanyPopUP",
    };
    setTimeout(() => {
      const modalRef = this.modalService.open(FilterComponent, modalOption);

      modalRef.componentInstance.stagesName = this.paginationBaseModel.stage;
      modalRef.componentInstance.getAllDropDownModel = this.getAllDropDownModel;
      modalRef.result.then((result) => {
        this.isButtonDisabled = false;
        if (result) {
          this.getCompanyFilter();
        }
      });
    }, 10)
  }
  }

  getDataFromStorage() {
    let grids = this.storage.retrieve("userSetting")
    // stored grids
    this.userSetting.companies = grids.companies;
    this.userSetting.contacts = grids.contacts;
    this.userSetting.customers = grids.customers;
    this.userSetting.leads = grids.leads;
    this.userSetting.prospects = grids.prospects;
    this.userSetting.vendorLists = grids.VendorList;
    this.userSetting.vendorContacts = grids.vendorContact;
    this.userSetting.requirementLists = grids.requirements;

    // stored filter
    this.userSetting.companiesFilter = this.storage.retrieve(this.enumTabNames.COMPANIES)
    this.userSetting.contactFilter = this.storage.retrieve(this.enumTabNames.CONTACTS)
    this.userSetting.customersFilters = this.storage.retrieve(this.enumTabNames.CUSTOMERS)
    this.userSetting.leadsFilter = this.storage.retrieve(this.enumTabNames.LEADS)
    this.userSetting.prospectsFilter = this.storage.retrieve(this.enumTabNames.PROSPECTS)
    this.userSetting.vendorListFilters = this.storage.retrieve("vendorList")
    this.userSetting.vendorContactsFilter = this.storage.retrieve('vendorContact');
    this.userSetting.requirementsFilter = this.storage.retrieve(this.enumTabNames.REQUIREMENTS);

    this.saveUserSetting.userSetting = JSON.stringify(this.userSetting);
    this.agGridService.saveUserSetting(this.saveUserSetting).subscribe(res => {
      const json = JSON.parse(res?.response);
      this.storage.store("userSetting", json)
    })
  }

  getUserSettings() {
    let value: any
    this.crmService.getUserSetting()
      .subscribe(res => {
        if (res?.isSuccess) {
          // let json = JSON.parse(res?.response?.userSetting) ?? {}
          let json = JSON.parse(res?.response) ?? {}

         // let json =  new UserSetting();

          this.storage.store("userSetting", json);
          let companies = this.storage.retrieve(this.enumTabNames.COMPANIES);
          if (companies == undefined && json.companiesFilter) {
            this.storage.store(this.enumTabNames.COMPANIES, json.companiesFilter)
            value = json.companiesFilter
            this.selectFilterOptionlist = json.companiesFilter?.companyFilterResponse ?? [];
            this.applyFilter(json.companiesFilter?.companyFilterList);
            this.ngAfterView();
            this.changeTableData()
          }

          let leads = this.storage.retrieve(this.enumTabNames.LEADS);
          if (leads == undefined && json.leadsFilter) {
            this.storage.store(this.enumTabNames.LEADS, json.leadsFilter)
            this.selectFilterOptionlist = json.leadsFilter?.companyFilterResponse ?? [];
            this.applyFilter(json.leadsFilter?.companyFilterList);
            this.ngAfterView();
            this.changeTableData()
          }

          let customer = this.storage.retrieve(this.enumTabNames.CUSTOMERS);
          if (customer == undefined && json.customersFilters) {
            this.storage.store(this.enumTabNames.CUSTOMERS, json.customersFilters)
            this.selectFilterOptionlist = json.customersFilters?.companyFilterResponse ?? [];
            this.applyFilter(json.customersFilters?.companyFilterList);
            this.ngAfterView();
            this.changeTableData()
          }

          let prospeect = this.storage.retrieve(this.enumTabNames.PROSPECTS);
          if (prospeect == undefined && json.prospectsFilter) {
            this.storage.store(this.enumTabNames.PROSPECTS, json.prospectsFilter)
            this.selectFilterOptionlist = json.prospectsFilter?.companyFilterResponse ?? [];
            this.applyFilter(json.prospectsFilter?.companyFilterList);
            this.ngAfterView();
            this.changeTableData()
          }

          let contact = this.storage.retrieve(this.enumTabNames.CONTACTS);
          if (contact == undefined && json?.contactFilter) {
            this.storage.store(this.enumTabNames.CONTACTS, json.contactFilter)
            this.selectFilterOptionlist = json.contactFilter?.companyFilterResponse ?? [];
            this.applyFilter(json.contactFilter?.companyFilterList);
            this.ngAfterView();
            this.changeTableData()
          }
          let vendorList = this.storage.retrieve("vendorList");
          if (vendorList == undefined && json?.vendorListFilters) {
            this.storage.store('vendorList', json.vendorListFilters)
            this.selectFilterOptionlist = json.vendorListFilters?.companyFilterResponse ?? [];
            this.applyFilter(json.vendorListFilters?.companyFilterList);
            this.ngAfterView();
            this.changeTableData()
          }
          let vendorContact = this.storage.retrieve("vendorContact");
          if (vendorContact == undefined && json?.vendorContactsFilter) {
            this.storage.store('vendorContact', json.vendorContactsFilter)
            this.selectFilterOptionlist = json.vendorContactsFilter?.companyFilterResponse ?? [];
            this.applyFilter(json.vendorContactsFilter?.companyFilterList);
            this.ngAfterView();
            this.changeTableData()
          }
          let procurement = this.storage.retrieve(this.enumTabNames.REQUIREMENTS);
          if (procurement == undefined && json?.requirementsFilter) {
            this.storage.store(this.enumTabNames.REQUIREMENTS, json.requirementsFilter)
            this.selectFilterOptionlist = json.requirementsFilter?.companyFilterResponse ?? [];
            this.applyFilter(json.requirementsFilter?.companyFilterList);
            this.ngAfterView();
            this.changeTableData()
          }
        }
        
        else {
          this.storage.store("userSetting", {})
        }

      });

  }

  getCompanyFilter() {
    let value = this.storage.retrieve("filters");
    if (this.currentTab == this.enumTabNames.COMPANIES) {
      value = this.storage.retrieve(this.enumTabNames.COMPANIES);
      if (value == undefined) {
        this.getUserSettings()
      }
    }
    if (this.currentTab == this.enumTabNames.LEADS) {
      value = this.storage.retrieve(this.enumTabNames.LEADS);
      if (value == undefined) {
        this.getUserSettings()
      }
    }
    if (this.currentTab == this.enumTabNames.CUSTOMERS) {
      value = this.storage.retrieve(this.enumTabNames.CUSTOMERS);
      if (value == undefined) {
        this.getUserSettings()
      }
    }
    if (this.currentTab == this.enumTabNames.PROSPECTS) {
      value = this.storage.retrieve(this.enumTabNames.PROSPECTS);
      if (value == undefined) {
        this.getUserSettings()
      }
    }
    if (this.currentTab == this.enumTabNames.CONTACTS) {
      value = this.storage.retrieve(this.enumTabNames.CONTACTS);
      if (value == undefined) {
        this.getUserSettings()
      }
    }
    if (this.currentTab == this.enumTabNames.VENDOR_LIST) {
      value = this.storage.retrieve("vendorList");
      if (value == undefined) {
        this.getUserSettings()
      }
    }
    if (this.currentTab == this.enumTabNames.VENDOR_CONTACT) {
      value = this.storage.retrieve("vendorContact");
      if (value == undefined) {
        this.getUserSettings()
      }
    }
    if (this.currentTab == this.enumTabNames.CONTACT_RECYCLEBIN) {
      value = this.storage.retrieve(this.enumTabNames.CONTACT_RECYCLEBIN);
      if (value == undefined) {
        this.getUserSettings()
      }
    } if (this.currentTab == this.enumTabNames.COMPANY_RECYCLEBIN) {
      value = this.storage.retrieve(this.enumTabNames.COMPANY_RECYCLEBIN);
      if (value == undefined) {
        this.getUserSettings()
      }
    }
    if (this.currentTab == this.enumTabNames.REQUIREMENTS) {
      value = this.storage.retrieve(this.enumTabNames.REQUIREMENTS);
      if (value == undefined) {
        this.getUserSettings()
      }
    }
    // this.getDataFromStorage()
    this.filtersFromPopup = "";
    
    this.selectFilterOptionlist = value?.companyFilterResponse ?? [];
    this.applyFilter(value?.companyFilterList);
    this.ngAfterView();
    this.changeTableData()
  }

  updateCleareFilter() {
    let value = this.storage.retrieve("filters");
    if (this.currentTab == this.enumTabNames.COMPANIES) {
      value = this.storage.retrieve(this.enumTabNames.COMPANIES);
    }
    if (this.currentTab == this.enumTabNames.LEADS) {
      value = this.storage.retrieve(this.enumTabNames.LEADS);
    }
    if (this.currentTab == this.enumTabNames.CUSTOMERS) {
      value = this.storage.retrieve(this.enumTabNames.CUSTOMERS);
    }
    if (this.currentTab == this.enumTabNames.PROSPECTS) {
      value = this.storage.retrieve(this.enumTabNames.PROSPECTS);
    }
    if (this.currentTab == this.enumTabNames.CONTACTS) {
      value = this.storage.retrieve(this.enumTabNames.CONTACTS);
    }
    if(this.currentTab == this.enumTabNames.VENDOR_LIST){
      value = this.storage.retrieve('vendorList')
    }
    if(this.currentTab == this.enumTabNames.VENDOR_CONTACT){
      value = this.storage.retrieve('vendorContact')
    }
    if(this.currentTab == this.enumTabNames.REQUIREMENTS){
      value = this.storage.retrieve(this.enumTabNames.REQUIREMENTS)
    }

    // this.getDataFromStorage()
    this.filtersFromPopup = "";

    this.selectFilterOptionlist = value?.companyFilterResponse ?? [];
    this.applyFilter(value?.companyFilterList);
    this.ngAfterView();
    this.changeTableData()
  }

  changeTableData() {
    this.agGridService.tablePaginationData.next(this.paginationBaseModel);
  }
  applyFilter(data) {
    data
    let filterCriteria = data;
    let filters = new Filters();
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.fromDate,
      "fromDate",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.salesPersonList,
      "salesPersonList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.altSalesPersonList,
      "altSalesPersonList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.buyersRepList,
      "buyersRepList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.scopeList,
      "scopeList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.timeZoneList,
      "timeZoneList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.leadScoreList,
      "leadScoreList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.prospectScoreList,
      "prospectScoreList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.naicsCodeList,
      "naicsCodeList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.countryList,
      "countryList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.stateList,
      "stateList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.classList,
      "classList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.vendorTypeList,
      "vendorTypeList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.tagsList,
      "tagsList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.startRating,
      "startRating",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.stageStatusList,
      "stageStatusList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.endRating,
      "endRating",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.archived,
      "archived",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.avlId,
      "avlId",
      "eq"
    );

    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.sicCodeList,
      "sicCodeList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.excludedFromVendorlist,
      "excludedFromVendorlist",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.industryList,
      "industryList",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.companyStatusId,
      "companyStatusId",
      "eq"
    );
    filters = this.crmService.modifyFilter(
      filters,
      filterCriteria?.manufacturerList,
      "manufacturerList",
      "eq"
    );


    this.filtersFromPopup =
      JSON.stringify(filters) == '{"rules":[],"groups":[]}'
        ? ""
        : JSON.stringify(filters);
    this.paginationBaseModel.filtersFromPopup = this.filtersFromPopup;
    this.agGridService.setFilter(this.filtersFromPopup)
    // this.getAllCompanyData();
  }

  removeFilterTab(item): void {
    if (item.labelName == this.enumItemLablesNames.RATING) {
      this.crmService.isRating.next(false)
    }
    if (item.lableName == this.enumItemLablesNames.ACTIVE || item.lableName == this.enumItemLablesNames.INACTIVE) {
      this.crmService.isActive.next(false)
    }
    this.removeCompanyFilterModel = item;
    let storeData
    if (this.currentTab == this.enumTabNames.COMPANIES) {
      storeData = this.storage.retrieve(this.enumTabNames.COMPANIES);
    }
    if (this.currentTab == this.enumTabNames.LEADS) {
      storeData = this.storage.retrieve(this.enumTabNames.LEADS);
    }
    if (this.currentTab == this.enumTabNames.CUSTOMERS) {
      storeData = this.storage.retrieve(this.enumTabNames.CUSTOMERS);
    }
    if (this.currentTab == this.enumTabNames.PROSPECTS) {
      storeData = this.storage.retrieve(this.enumTabNames.PROSPECTS);
    }
    if (this.currentTab == this.enumTabNames.CONTACTS) {
      storeData = this.storage.retrieve(this.enumTabNames.CONTACTS);
    }
    if(this.currentTab == this.enumTabNames.VENDOR_LIST){
      storeData = this.storage.retrieve('vendorList');
    }
    if(this.currentTab == this.enumTabNames.VENDOR_CONTACT){
      storeData = this.storage.retrieve('vendorContact');
    }
    if(this.currentTab == this.enumTabNames.REQUIREMENTS){
      storeData = this.storage.retrieve(this.enumTabNames.REQUIREMENTS);
    }

    let dropDowns = storeData?.allDropDowns
    storeData.companyFilterResponse = storeData.companyFilterResponse.filter(value => !(value.labelName === item.labelName && value.id === item.id));
    this.selectFilterOptionlist.filter(value => !(value.labelName === item.labelName && value.id === item.id))

    // removing data from filterList that we use in grid api
 
    if (item.labelName == this.enumItemLablesNames.CONTACTEDDATE) {
      storeData.companyFilterList.fromDate = '';
      storeData.allDropDowns.fromDate  = '';
    }
    if (item.labelName == this.enumItemLablesNames.ARCHIVED) {
      storeData.companyFilterList.archived = false
      storeData.allDropDowns.archived = false
    }
    if (item.labelName == this.enumItemLablesNames.AVL) {
 
      if(!storeData.allDropDowns.navl){
        storeData.allDropDowns.avlId = 3
      }

      if(storeData.allDropDowns.navl){
        storeData.allDropDowns.avlId = 0
      }
      storeData.companyFilterList.avl = false
      storeData.allDropDowns.avl = false
    }
    if (item.labelName == this.enumItemLablesNames.NAVL) {
    
      if(!storeData.allDropDowns.avl){
        storeData.allDropDowns.avlId = 3
      }

      if(storeData.allDropDowns.avl){
        storeData.allDropDowns.avlId = 1
      }
      storeData.companyFilterList.navl = false
      storeData.allDropDowns.navl = false
    }
    // if (item.labelName == "AVL" || item.labelName == 'NAVL') {
    //   storeData.companyFilterList.AVLId = 2
    //   storeData.allDropDowns.AVLId = 2
    // }
    if (item.labelName == this.enumItemLablesNames.EXCLUDEDFROM_VENDOR_LIST) {
      storeData.companyFilterList.excludedFromVendorlist = false
      storeData.allDropDowns.excludedFromVendorlist = false
    }

    if (item.labelName == this.enumItemLablesNames.ACTIVE) {
      storeData.activeList[0].isActive = false;
      if (storeData.activeList[1].isActive) {
        storeData.companyFilterList.companyStatusId = 0
      } else {
        storeData.companyFilterList.companyStatusId = 2
      }
    }
    if (item.labelName == this.enumItemLablesNames.INACTIVE) {
      storeData.activeList[1].isActive = false;
      if (storeData.activeList[0].isActive) {
        storeData.companyFilterList.companyStatusId = 1
      } else {
        storeData.companyFilterList.companyStatusId = 2
      }
    }
    if (item.labelName == this.enumItemLablesNames.RATING) {
      storeData.allDropDowns.endRating = 0;
      storeData.allDropDowns.startRating = 0;
      storeData.companyFilterList.endRating = 0;
      storeData.companyFilterList.startRating = 0;
    }
    if (item.labelName == this.enumItemLablesNames.ALT_SALESPERSON) {
      storeData.allDropDowns.altSalesPersonList =
        dropDowns.altSalesPersonList.filter(value => value.name != item.name)
      storeData.companyFilterList.altSalesPersonList =
        storeData.companyFilterList.altSalesPersonList.replace(item.id, '')
      storeData.companyFilterList.altSalesPersonList =
        storeData.companyFilterList.altSalesPersonList.replace(/,+/g, ',')
      if (storeData.companyFilterList.altSalesPersonList == ',') {
        storeData.companyFilterList.altSalesPersonList =
          storeData.companyFilterList.altSalesPersonList.replace(',', '')
      }
    }

    if (item.labelName == this.enumItemLablesNames.BUYER_REP) {
      storeData.allDropDowns.buyerRepList =
        dropDowns.buyerRepList.filter(value => value.name != item.name)

      storeData.companyFilterList.buyersRepList =
        storeData.companyFilterList.buyersRepList.replace(item.id, '')
      storeData.companyFilterList.buyersRepList =
        storeData.companyFilterList.buyersRepList.replace(/,+/g, ',')
      if (storeData.companyFilterList.buyersRepList == ',') {
        storeData.companyFilterList.buyersRepList =
          storeData.companyFilterList.buyersRepList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.COUNTRY) {
      storeData.allDropDowns.countryList =
        dropDowns.countryList.filter(value => value.name != item.name)
      storeData.companyFilterList.countryList =
        storeData.companyFilterList.countryList.replace(item.id, '')
      storeData.companyFilterList.countryList =
        storeData.companyFilterList.countryList.replace(/,+/g, ',')
      if (storeData.companyFilterList.countryList == ',') {
        storeData.companyFilterList.countryList =
          storeData.companyFilterList.countryList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.INDUSTRY) {
      storeData.allDropDowns.industryList =
        dropDowns.industryList.filter(value => value.name != item.name)
      storeData.companyFilterList.industryList =
        storeData.companyFilterList.industryList.replace(item.id, '')
      storeData.companyFilterList.industryList =
        storeData.companyFilterList.industryList.replace(/,+/g, ',')
      if (storeData.companyFilterList.industryList == ',') {
        storeData.companyFilterList.industryList =
          storeData.companyFilterList.industryList.replace(',', '')
      }
    }

    if (item.labelName == this.enumItemLablesNames.MANUFACTURER) {
      storeData.allDropDowns.manufacturerList =
        dropDowns.manufacturerList.filter(value => value.name != item.name)
      storeData.companyFilterList.manufacturerList =
        storeData.companyFilterList.manufacturerList.replace(item.id, '')
      storeData.companyFilterList.manufacturerList =
        storeData.companyFilterList.manufacturerList.replace(/,+/g, ',')
      if (storeData.companyFilterList.manufacturerList == ',') {
        storeData.companyFilterList.manufacturerList =
          storeData.companyFilterList.manufacturerList.replace(',', '')
      }
    }

    if (item.labelName == this.enumItemLablesNames.INTEND_TO_BUY) {
      storeData.allDropDowns.prospectScoreList =
        dropDowns.prospectScoreList.filter(value => value.name != item.name)
      storeData.companyFilterList.prospectScoreList =
        storeData.companyFilterList.prospectScoreList.replace(item.id, '')
      storeData.companyFilterList.prospectScoreList =
        storeData.companyFilterList.prospectScoreList.replace(/,+/g, ',')
      if (storeData.companyFilterList.prospectScoreList == ',') {
        storeData.companyFilterList.prospectScoreList =
          storeData.companyFilterList.prospectScoreList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.LEAD) {
      storeData.allDropDowns.leadScoreList =
        dropDowns.leadScoreList.filter(value => value.name != item.name)
      storeData.companyFilterList.leadScoreList =
        storeData.companyFilterList.leadScoreList.replace(item.id, '')
      storeData.companyFilterList.leadScoreList =
        storeData.companyFilterList.leadScoreList.replace(/,+/g, ',')
      if (storeData.companyFilterList.leadScoreList == ',') {
        storeData.companyFilterList.leadScoreList =
          storeData.companyFilterList.leadScoreList.replace(',', '')
      }
    }

    if (item.labelName == this.enumItemLablesNames.NAICS_CODE) {
      storeData.allDropDowns.naicsCodeList =
        dropDowns.naicsCodeList.filter(value => value.name != item.name)
      storeData.companyFilterList.naicsCodeList =
        storeData.companyFilterList.naicsCodeList.replace(item.name, '')
      storeData.companyFilterList.naicsCodeList =
        storeData.companyFilterList.naicsCodeList.replace(/,+/g, ',')
      if (storeData.companyFilterList.naicsCodeList == ',') {
        storeData.companyFilterList.naicsCodeList =
          storeData.companyFilterList.naicsCodeList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.SALESPERSON) {
      storeData.allDropDowns.salesPersonList =
        dropDowns.salesPersonList.filter(value => value.name != item.name)
      storeData.companyFilterList.salesPersonList =
        storeData.companyFilterList.salesPersonList.replace(item.id, '')
      storeData.companyFilterList.salesPersonList =
        storeData.companyFilterList.salesPersonList.replace(/,+/g, ',')
      if (storeData.companyFilterList.salesPersonList == ',') {
        storeData.companyFilterList.salesPersonList =
          storeData.companyFilterList.salesPersonList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.SCOPE) {
      storeData.allDropDowns.scopeList =
        dropDowns.scopeList.filter(value => value.name != item.name)
      storeData.companyFilterList.scopeList =
        storeData.companyFilterList.scopeList.replace(item.id, '')
      storeData.companyFilterList.scopeList =
        storeData.companyFilterList.scopeList.replace(/,+/g, ',')
      if (storeData.companyFilterList.scopeList == ',') {
        storeData.companyFilterList.scopeList =
          storeData.companyFilterList.scopeList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.TIMEZONE_LIST) {
      storeData.allDropDowns.timeZoneList =
        dropDowns.timeZoneList.filter(value => value.name != item.name)

      storeData.companyFilterList.timeZoneList =
        storeData.companyFilterList.timeZoneList.replace(item.id, '')
      storeData.companyFilterList.timeZoneList =
        storeData.companyFilterList.timeZoneList.replace(/,+/g, ',')
      if (storeData.companyFilterList.timeZoneList == ',') {
        storeData.companyFilterList.timeZoneList =
          storeData.companyFilterList.timeZoneList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.SIC_CODE) {
      storeData.allDropDowns.sicCodeList =
        dropDowns.sicCodeList.filter(value => value.name != item.name)
      storeData.companyFilterList.sicCodeList =
        storeData.companyFilterList.sicCodeList.replace(Number(item.name), '')
      storeData.companyFilterList.sicCodeList =
        storeData.companyFilterList.sicCodeList.replace(/,+/g, ',');
      if (storeData.companyFilterList.sicCodeList == ',') {
        storeData.companyFilterList.sicCodeList =
          storeData.companyFilterList.sicCodeList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.STAGE_STATUS) {
      storeData.allDropDowns.stageStatusList =
        dropDowns.stageStatusList.filter(value => value.name != item.name)

      storeData.companyFilterList.stageStatusList =
        storeData.companyFilterList.stageStatusList.replace(item.id, '')
      storeData.companyFilterList.stageStatusList =
        storeData.companyFilterList.stageStatusList.replace(/,+/g, ',')
      if (storeData.companyFilterList.stageStatusList == ',') {
        storeData.companyFilterList.stageStatusList =
          storeData.companyFilterList.stageStatusList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.STATE) {
      storeData.allDropDowns.stateList =
        dropDowns.stateList.filter(value => value.name != item.name)
      storeData.companyFilterList.stateList =
        storeData.companyFilterList.stateList.replace(item.id, '')
      storeData.companyFilterList.stateList =
        storeData.companyFilterList.stateList.replace(/,+/g, ',')
      if (storeData.companyFilterList.stateList == ',') {
        storeData.companyFilterList.stateList =
          storeData.companyFilterList.stateList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.TAGS) {
      storeData.allDropDowns.tagsList =
        dropDowns.tagsList.filter(value => value.name != item.name)
      storeData.companyFilterList.tagsList =
        storeData.companyFilterList.tagsList.replace(item.id, '')
      storeData.companyFilterList.tagsList =
        storeData.companyFilterList.tagsList.replace(/,+/g, ',')
      if (storeData.companyFilterList.tagsList == ',') {
        storeData.companyFilterList.tagsList =
          storeData.companyFilterList.tagsList.replace(',', '')
      }
    }

    if (item.labelName == this.enumItemLablesNames.CLASS) {
      storeData.allDropDowns.classList =
        dropDowns.classList.filter(value => value.name != item.name)
      storeData.companyFilterList.classList =
        storeData.companyFilterList.classList.replace(item.id, '')
      storeData.companyFilterList.classList =
        storeData.companyFilterList.classList.replace(/,+/g, ',')
      if (storeData.companyFilterList.classList == ',') {
        storeData.companyFilterList.classList =
          storeData.companyFilterList.classList.replace(',', '')
      }
    }
    if (item.labelName == this.enumItemLablesNames.VENDOR_TYPE) {
      storeData.allDropDowns.vendorTypeList =
        dropDowns.vendorTypeList.filter(value => value.name != item.name)
      storeData.companyFilterList.vendorTypeList =
        storeData.companyFilterList.vendorTypeList.replace(item.id, '')
      storeData.companyFilterList.vendorTypeList =
        storeData.companyFilterList.vendorTypeList.replace(/,+/g, ',')
      if (storeData.companyFilterList.vendorTypeList == ',') {
        storeData.companyFilterList.vendorTypeList =
          storeData.companyFilterList.vendorTypeList.replace(',', '')
      }
    }
    if (this.currentTab == this.enumTabNames.COMPANIES) {
      this.storage.store(this.enumTabNames.COMPANIES, storeData)
    }
    if (this.currentTab == this.enumTabNames.LEADS) {
      this.storage.store(this.enumTabNames.LEADS, storeData)
    }
    if (this.currentTab == this.enumTabNames.CUSTOMERS) {
      this.storage.store(this.enumTabNames.CUSTOMERS, storeData)
    }
    if (this.currentTab == this.enumTabNames.PROSPECTS) {
      this.storage.store(this.enumTabNames.PROSPECTS, storeData)
    }
    if (this.currentTab == this.enumTabNames.CONTACTS) {
      this.storage.store(this.enumTabNames.CONTACTS, storeData)
    }
    if(this.currentTab == this.enumTabNames.VENDOR_LIST){
      this.storage.store('vendorList', storeData)
    }
    if(this.currentTab == this.enumTabNames.VENDOR_CONTACT){
      this.storage.store('vendorContact', storeData)
    }
    if(this.currentTab == this.enumTabNames.REQUIREMENTS){
      this.storage.store(this.enumTabNames.REQUIREMENTS, storeData)
    }

    this.getDataFromStorage()
    // this.getCompanyFilter();
    this.updateCleareFilter()
  }

  clearAllFilter() {
    this.crmService.isRating.next(false)
    this.crmService.isActive.next(false)
    this.agGridService.toggleHeaderExpandIcon.next(false)
    if (this.currentTab == this.enumTabNames.COMPANIES) {
      this.storage.removeItem(this.enumTabNames.COMPANIES)
    }
    if (this.currentTab == this.enumTabNames.LEADS) {
      this.storage.removeItem(this.enumTabNames.LEADS)
    }
    if (this.currentTab == this.enumTabNames.CUSTOMERS) {
      this.storage.removeItem(this.enumTabNames.CUSTOMERS)
    }
    if (this.currentTab == this.enumTabNames.PROSPECTS) {
      this.storage.removeItem(this.enumTabNames.PROSPECTS)
    }
    if (this.currentTab == this.enumTabNames.CONTACTS) {
      this.storage.removeItem(this.enumTabNames.CONTACTS)
    }
    if(this.currentTab == this.enumTabNames.VENDOR_LIST){
      this.storage.removeItem('vendorList');
    }
    if(this.currentTab == this.enumTabNames.VENDOR_CONTACT){
      this.storage.removeItem('vendorContact');
    }
    this.getDataFromStorage()

    if (this.selectFilterOptionlist.length != 0 || this.paginationBaseModel.searchText != '') {
      this.selectFilterOptionlist.length
      this.paginationBaseModel.searchText = ''
      // this.getCompanyFilter();
      this.updateCleareFilter()
    }
    if (this.currentTab == this.enumTabNames.COMPANIES && this.paginationBaseModel.stage != 'All') {
      this.paginationBaseModel.stage = 'All'
      this.updateCleareFilter()
      // this.getCompanyFilter();
    }
  }
  openAddRequiremnt() {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
       size:'xl',
     // windowClass: "addCompanyPopUP",
    };
    const modalRef = this.modalService.open(AddRequirementComponent, modalOption);
    modalRef.componentInstance.stagesName = this.paginationBaseModel.stage;
    modalRef.componentInstance.headingsNames = this.buttonName;
  }
  openAddEditCompanyModal() {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      // size:'xl',
      windowClass: "addCompanyPopUP",
    };
    const modalRef = this.modalService.open(AddCompanyComponent, modalOption);
    modalRef.componentInstance.stagesName = this.paginationBaseModel.stage;
    modalRef.componentInstance.headingsNames = this.buttonName;
    if(this.buttonName == 'Add Company'){
      this.companiesService.nextTab.next(false)
    }
  }
  openMailingListModal(){
    if (this.selectedData.length != 0) {
      const modalRef = this.modalService.open(AddMailingListComponent, {
        centered: true,
        windowClass: "resetPassword",
        keyboard: false,
        backdrop: 'static',
        size: "sm",
      });
      modalRef.componentInstance.dataList = this.selectedData;
      modalRef.result.then((result) => {
        if (result) {
          this.agGridService.tablePaginationData.next(this.paginationBaseModel);
        }
      });
    }

  }
  openAssignModal() {
    if (this.selectedData.length != 0) {
      const modalRef = this.modalService.open(AssignComponent, {
        centered: true,
        windowClass: "resetPassword",
        keyboard: false,
        backdrop: 'static',
        size: "md",
      });
      modalRef.componentInstance.companyIds = this.companyID;
      modalRef.result.then((result) => {
        if (result) {
          this.agGridService.tablePaginationData.next(this.paginationBaseModel);
        }
      });
    }
  }

  //#region delete
  openDelteModal() 
  {
    // opem confirm model  delete company
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "markAsActiveInactive",
    };
    if (this.selectedData.length != 0) {
      const modalRef = this.modalService.open(
        CommonModelPopUpComponent,
        modalOption
      );
      modalRef.componentInstance.title = this.enumPopUpTitles.DELETE;
      let companyName = this.companyName[1] != undefined ? ", " + this.companyName[1] : "";
      modalRef.componentInstance.fullName = `${this.companyName[0]}${companyName} ${this.companyName.length > 2? "&" + " " + (this.companyName.length - 2 + " ") + "others" : ""} `;
     
      if( this.currentTab == this.enumTabNames.CONTACTS || this.currentTab== this.enumTabNames.VENDOR_CONTACT)
      {
        let contactName = this.vendorContactName[1] != undefined ? ", " + this.vendorContactName[1] : "";
        modalRef.componentInstance.fullName = `${this.vendorContactName[0]}${contactName} ${this.vendorContactName.length > 2? "&" + " " + (this.vendorContactName.length - 2 + " ") + "others"  : ""} `;

      }
      modalRef.componentInstance.currentTab = this.currentTab;
      modalRef.componentInstance.pageType = this.currentTab == this.enumTabNames.VENDOR_LIST ?  'vendor': this.currentTab == this.enumTabNames.CONTACTS ?  'contact' : this.currentTab == this.enumTabNames.VENDOR_CONTACT ? 'vendor contact' : "company";
      modalRef.componentInstance.delPopupforAdmin.subscribe((res) => {
        if (res){
          
          if(this.currentTab == this.enumTabNames.CONTACTS || this.currentTab== this.enumTabNames.VENDOR_CONTACT){
            this.deletContact();
          }else
          {
            this.delectcompanyOnConfirm();
          }
          setTimeout(() => {
          modalRef.componentInstance.ResMsgFromCompanyOrContact = this.ResMsgFromCompanyOrContact;
          }, 1000);
        }
      });
    }
  }

  // delte company
  delectcompanyOnConfirm() {
    this.deleteCompanyModel.id = this.companyID;
    // this.deleteCompanyModel.userId = this.userID;
    this.crmService.deleteCompany(this.deleteCompanyModel).subscribe((res) => {
      if (res.isSuccess == true) {
        this.agGridService
          .getGridApi()
          .updateRowData({ remove: this.selectedData });
        this.ResMsgFromCompanyOrContact = res.message;
      }
    });
  }
  // delte contact
  deletContact() {
    this.deleteContactModel.ids = this.contactID;
    // this.deleteContactModel.userId = this.userID;
    this.crmService.deleteContact(this.deleteContactModel).subscribe((res) => {
      if (res.isSuccess == true) {
        this.agGridService
          .getGridApi()
          .updateRowData({ remove: this.selectedData });
        this.ResMsgFromCompanyOrContact = res.message;
      }
    });
  }
  //#endregion

// delete company-recycle bin & contact-recycle bin start

restore(currentTab){
  const modalOption: NgbModalOptions = {
    centered: true,
    backdrop: "static",
    keyboard: false,
    windowClass: 'resetPassword'
  }
  if (this.selectedData.length != 0) {
    const modalRef = this.modalService.open(RestoreComponent, modalOption);

    if (currentTab == this.enumTabNames.COMPANY_RECYCLEBIN) {
      
      let companyName = this.companyName[1] != undefined ? ", " + this.companyName[1] : "";
      modalRef.componentInstance.fullName = `${this.companyName[0]} ${companyName} ${this.companyName.length > 2 ? "&" + " " + (this.companyName.length - 2 + " ") + "others" : ""} `;
      modalRef.componentInstance.title = 'company record';
      modalRef.result.then((result) => {
        if (result) {
          this.RestoreCompany();
        }
      });
    } else {
      let contactName = this.vendorContactName[1] != undefined ? ", " + this.vendorContactName[1] : "";
      modalRef.componentInstance.fullName = `${this.vendorContactName[0]} ${contactName} ${this.vendorContactName.length > 2 ? "&" + " " + (this.vendorContactName.length - 2 + " ") + "others" : ""} `;
      modalRef.componentInstance.title = 'contact';
      modalRef.result.then((result) => {
        if (result) {
          this.RestoreContact();
        }
      });
    }
  }
}


  approveDeletion(currentTab) {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: 'resetPassword'
    }
    if (this.selectedData.length != 0) {
      const modalRef = this.modalService.open(DeleteCompanyPopupComponent, modalOption);

      if (currentTab == this.enumTabNames.COMPANY_RECYCLEBIN) {
        let companyName = this.companyName[1] != undefined ? ", " + this.companyName[1] : "";
        modalRef.componentInstance.fullName = `${this.companyName[0]} ${companyName} ${this.companyName.length > 2 ? "&" + " " + (this.companyName.length - 2 + " ") + "others" : ""} `;
        modalRef.componentInstance.title = 'company';
        modalRef.componentInstance.delPopup.subscribe((res) => {
          if (res) {
            this.DeleteCompanyPermanent();
          }
        });
      } else {
        let contactName = this.vendorContactName[1] != undefined ? ", " + this.vendorContactName[1] : "";
        modalRef.componentInstance.fullName = `${this.vendorContactName[0]} ${contactName} ${this.vendorContactName.length > 2 ? "&" + " " + (this.vendorContactName.length - 2 + " ") + "others" : ""} `;
        modalRef.componentInstance.title = 'contact';
        modalRef.componentInstance.delPopup.subscribe((res) => {
          if (res) {
            this.DeleteContactPermanently();
          }
        });
      }
    }
  }

  DeleteCompanyPermanent(){
    // this.crmService.deleteCompanyPermanently({ ids: this.companyID }).subscribe((res) => {
    //   if (res.isSuccess == true) {
    //     this.agGridService
    //       .getGridApi()
    //       .updateRowData({ remove: this.selectedData });
    //     this.toastr.success(res.message);
    //   }
    // });
  }

 DeleteContactPermanently(){
  // this.crmService.deleteContactPermanently({ ids: this.contactID }).subscribe((res) => {
  //   if (res.isSuccess == true) {
  //     this.agGridService
  //       .getGridApi()
  //       .updateRowData({ remove: this.selectedData });
  //     this.toastr.success(res.message);
  //   }
  // });
 }

 RestoreCompany(){
  this.crmService.restoreCompany({ ids: this.companyID }).subscribe((res) => {
    if (res.isSuccess == true) {
      this.agGridService
        .getGridApi()
        .updateRowData({ remove: this.selectedData });
      this.toastr.success(res.message);
    }
  });
 }

 RestoreContact(){
  this.crmService.restoreContact({ ids: this.contactID }).subscribe((res) => {
    if (res.isSuccess == true) {
      this.agGridService
        .getGridApi()
        .updateRowData({ remove: this.selectedData });
      this.toastr.success(res.message);
    }
  });
 }

// delete company-recycle bin & contact-recycle bin start
addOfferForm(formName){

}
showexportList1(fileType){

}

  showexportList(fileType) {
    this.paginationBaseModel.selectedId = this.companyID;
    if (fileType == "excel") {
      this.paginationBaseModel.exportAsPdf = false;
    }
    if (fileType == "pdf") {
      this.paginationBaseModel.exportAsPdf = true;
    }

    // this.crmService.exportCompany(this.paginationBaseModel).subscribe((res) => {
    //   this.base64 = res.response[0].headerData;

    //   const byteArray = new Uint8Array(
    //     atob(this.base64)
    //       .split("")
    //       .map((char) => char.charCodeAt(0))
    //   );
    //   if (fileType == "pdf") {
    //     this.file = new Blob([byteArray], { type: "application/pdf" });
    //     this.fileName = "Company.pdf";
    //   }
    //   if (fileType == "excel") {
    //     this.file = new Blob([byteArray], {
    //       type: "data:application/octet-stream;base64",
    //     });
    //     this.fileName = "Company.xlsx";
    //   }
    //   const fileURL = URL.createObjectURL(this.file);
    //   let link = document.createElement("a");
    //   link.download = this.fileName;
    //   link.target = "_blank";

    //   // Construct the URI
    //   link.href = fileURL;
    //   document.body.appendChild(link);
    //   link.click();

    //   // Cleanup the DOM
    //   document.body.removeChild(link);
    // });
  }

  // openImportCompany() {
  //   const modalOption: NgbModalOptions = {
  //     centered: true,
  //     backdrop: "static",
  //     keyboard: false,
  //     size: "lg",
  //   };
  //   const modalRef = this.modalService.open(
  //     ImportCompanyComponent,
  //     modalOption
  //   );
  // }

navigateToImportCompnaies(buttonImport){
  if(buttonImport === 'Import'){
    const item : Menu = {path:  this.enumPath?.IMPORT_COMPANIES, icon: '', title: 'Import', type: 'link'}
    this.tabsService.addTab(item);
    this.router.navigateByUrl(this.enumPath?.IMPORT_COMPANIES);
    this.crmService?.sendImportId.next(1)
  }
  if(buttonImport === 'Import Vendors'){
    const item : Menu = {path:  this.enumPath.IMPORT_VENDORS, icon: '', title: 'Import Vendors', type: 'link'}
    this.tabsService.addTab(item);
    this.router.navigateByUrl(this.enumPath.IMPORT_VENDORS);
    this.crmService.sendImportId.next(2)
  }

}

  //#region pinned functionality
  pinActive(pinned) {

    if (pinned) {
      this.dockService.addFavouriteDocks(
        this.footerIcon,
        pinned,
        this.footerTitle
      );
    }
    if (!pinned) {
      this.dockService.removeFavouriteDocks();
    }
  }




  gettingArray() {
    let buttonSum = 0
    this.buttonWidths = []
    const containerWidth = this.elRef.nativeElement.querySelector('.filterBar').offsetWidth;
    this.show = this.selectFilterOptionlist.length
    setTimeout(() => {
      try {
        this.buttonRefs.some((buttonRef, index) => {
          this.buttonWidths.push({ width: buttonRef.nativeElement.offsetWidth, name: buttonRef.nativeElement.innerText });
          buttonSum = buttonSum + buttonRef.nativeElement.offsetWidth + 10
          if (buttonSum > containerWidth) {
            if (this.width > 1024) {
              this.bigShow = index
            }
            this.showValfirst = index;
            if (containerWidth > 850) {
              this.show = index + this.addToIndex;
              this.finalShow = index
            } else {
              this.finalShow = index
              this.show = index
            }
            if (this.count === 0) {
              this.count = 1;
              this.valueGet = this.show;
            }
            return true;
          }
        });
      } finally {
        if (this.finalShow) {
          if (this.width > 1024) {
            this.show = this.bigShow
          }
          else {
            this.show = this.finalShow
          }
        }
        if (this.toggle) {
          this.show = this.selectFilterOptionlist.length;
        }
        if (buttonSum < containerWidth && this.toggle) {
          this.toggle = false
        }

      }
    }, 10);

  }
  ngAfterView() {
    setTimeout(() => {
      this.gettingArray()
    }, 20);
  }
  showOther() {
    this.show = this.selectFilterOptionlist.length;
    this.toggle = !this.toggle
  }
  seeAllorLess() {
    this.toggle = !this.toggle
    if (this.toggle) {
      this.show = this.selectFilterOptionlist.length;
    } else {
      if (this.width > 1024) {
        this.show = this.bigShow;
      }
      else {
        this.show = this.showValfirst;
      }
    }
  }

  openFavouriteDockPopup(pinned) {
    if (!pinned) {
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "markAsActiveInactive",
      };
      const modalRef = this.modalService.open(
        CommonModelPopUpComponent,
        modalOption
      );
      modalRef.componentInstance.title = this.enumPopUpTitles.FAVOURITE_DOCKS;
      modalRef.result.then((result) => {
        if (result.confirm) {
          this.pinActive(pinned);
        }
        if (!result.confirm) {
          this.pinned = !this.pinned;
        }
      });
    } else if (pinned) {
      this.pinActive(pinned);
    }
  }

  openAddContactModal() {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      // size:'xl',
      windowClass: "addContactPopup",
    };
    const modalRef = this.modalService.open(AddContactComponent, modalOption);
    modalRef.result.then((res) => {
      if (res?.isSuccess == true) {
        this.agGridService.tablePaginationData.next(this.paginationBaseModel);
      }
    })

    // modalRef.componentInstance.stagesName = this.paginationBaseModel.stage;
  }

  open_AddTaskComponent()
  {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "lg",
    };
    const modalRef = this.modalService.open( AddActivityComponent,modalOption);
    modalRef.componentInstance.addActivityTitle = this.footerPath;
    // modalRef.componentInstance.disabledCompnay =true;
    // modalRef.componentInstance.disabledContact =true;
    if(this.currentTab == 'vendor-contact' || this.currentTab == 'contacts'){
      modalRef.componentInstance.disabledCompnay = true;
      modalRef.componentInstance.disabledContact = this.stringArrayContactID.length == 1 ? false : true;
    }else{
      modalRef.componentInstance.disabledCompnay = this.stringArrayCompanyID.length == 1 ? false : true;
      modalRef.componentInstance.disabledContact = this.stringArrayCompanyID.length == 1 ? false : true;
    }
    modalRef.componentInstance.addActivitiesMultipleCompanymodel.companyId = this.stringArrayCompanyID;
    modalRef.componentInstance.addActivitiesMultipleContactmodel.contactId = this.stringArrayContactID
    modalRef.result.then(res=>{
      if(res)
      {
        this.agGridService.tablePaginationData.next(this.paginationBaseModel);
      }
    })
  }
  Unarchive(){
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "markAsActiveInactive",
    };
    if (this.selectedData.length != 0) {
      const modalRef = this.modalService.open(
        CommonModelPopUpComponent,
        modalOption
      );
       modalRef.componentInstance.title = this.enumPopUpTitles.UNARCHIVED_COMPANIES;
       let companyName = this.companyName[1] != undefined ? ", " + this.companyName[1] : "";
       modalRef.componentInstance.fullName = `${this.companyName[0]}${companyName} ${this.companyName.length > 2? "&" + " " + (this.companyName.length - 2 + " ") + "others" : ""} `.trim();
      modalRef.result.then((res) => {
        if (res.confirm == true) {
           this.unArchivedCompanies()
       }
       });

  }
}
  selectedDataArchive(){
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      windowClass: "markAsActiveInactive",
    };
    if (this.selectedData.length != 0) {
      const modalRef = this.modalService.open(
        CommonModelPopUpComponent,
        modalOption
      );
       modalRef.componentInstance.title = this.enumPopUpTitles.ARCHIVED_COMPANIES;
       let companyName = this.companyName[1] != undefined ? ", " + this.companyName[1] : "";
       modalRef.componentInstance.fullName = `${this.companyName[0]}${companyName} ${this.companyName.length > 2? "&" + " " + (this.companyName.length - 2 + " ") + "others" : ""} `.trim();
      modalRef.result.then((res) => {
        if (res.confirm == true) {
           this.archivedCompanies()
       }
       });
    }

  }
  archivedCompanies(){
   this.deleteCompanyModel.id = this.companyID;
   this.archivedService.archivedCompanies(this.deleteCompanyModel).subscribe((res) => {
     if (res.isSuccess == true) {
       this.agGridService .getGridApi().updateRowData({ remove: this.selectedData });
     }
   });

  }
  unArchivedCompanies(){
    this.deleteCompanyModel.id = this.companyID;
    this.archivedService.unArchivedCompanies(this.deleteCompanyModel).subscribe((res) => {
      if (res.isSuccess == true) {
        this.agGridService .getGridApi().updateRowData({ remove: this.selectedData });
      }
    });
 
   }
   closeList(){
    this.showlist = false
   } 
   closeNewList(){
    this.showNewlist = false
   } 

   addiconList: AddiconList[] = [
    { id: 1, name: "Inventory", selected: false },
    { id: 2, name: "Parts", selected: false },
    { id: 3, name: "OEM Excess", selected: false },
    { id: 4, name: "Vendor Parts", selected: false },
  ];
  addNewList: AddiconList[] = [
    { id: 1, name: "Inventory", selected: false },
    { id: 2, name: "Requirement", selected: false },
    { id: 3, name: "Quote", selected: false },
    { id: 4, name: "Sales Order", selected: false },
    { id: 5, name: "Purchase Order", selected: false },
    { id: 6, name: "Consignment", selected: false },
  ];

   index:number;
  seletedItem(index: number) {
    this.index=index;
    for (let i = 0; i < this.addiconList.length; i++) {
      if (i === index) {
        this.addiconList[i].selected = true;
      }
      else {
        this.addiconList[i].selected = false;
      }
    }

  }
  seletedNewItem(index: number) {
    this.index=index;
    for (let i = 0; i < this.addNewList.length; i++) {
      if (i === index) {
        this.addNewList[i].selected = true;
      }
      else {
        this.addNewList[i].selected = false;
      }
    }

  }

  ngOnDestroy() {
    if (this.switchSubscription != undefined) {
      this.switchSubscription.unsubscribe();
    }
    if (this.starSubscription != undefined) {
      this.starSubscription.unsubscribe();
    }
  }
}


import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { content } from "./shared/routes/routes";
import { ResetPasswordComponent } from "./components/authentication/login/reset-password/reset-password.component";
import { AuthGuardGuard} from './shared/guard/auth-guard.guard'
const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full",
  },
  {
    path: "",
    component: ContentComponent,
    canActivate:[AuthGuardGuard],
    children: content,
  },
  {
    path: "auth/login",
    loadChildren: () =>
      import("./components/authentication/login/login.module").then(
        (m) => m.LoginModule
      ),
  },
  // {
  //   path: '**',
  //   redirectTo: 'auth/login'
  // },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
        relativeLinkResolution: "legacy",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

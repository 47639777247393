<form ngNativeValidate>
    <div>
      <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-start">
        <h6 class="modal-title text-center text-primary-color font-15">Assign</h6>
      </div>
      <div class="modal-body p-4 mx-2">
        <div class="mb-3">
            <app-dropdown
                [selectLabel]="'Salesperson'"
                [data]="salesPersonList"
                [selectValue]="assignModel.salesPersonId"
                (selectValueEmit)="assignModel.salesPersonId = $event" 
                [hidden]="dropdownHidden"
                [optionDisable]="assignModel.altSalesPersonId"
                [required]="false">
            </app-dropdown>
        </div>
        <div class="mb-3">
            <!-- select-container -->
            <app-dropdown
                [selectLabel]="'Alt Salesperson'"
                [data]="altSalesPersonList" 
                [optionDisable]="assignModel.salesPersonId"
                [selectValue]="assignModel.altSalesPersonId"
                (selectValueEmit)="assignModel.altSalesPersonId = $event" 
                [hidden]="dropdownHidden"
                [required]="false">
            </app-dropdown>
        </div>
        <div class="mb-3">
            <!-- select-container -->
            <app-dropdown
                [selectLabel]="'Buyer’s Rep'"
                [data]="buyeresRepList"
                [selectValue]="assignModel.buyersId"
                (selectValueEmit)="assignModel.buyersId = $event" 
                [required]="false">
            </app-dropdown>
        </div>
      </div>
      <div class="modal-footer p-4 pt-0 m-2 mt-0 border-0 justify-content-center">
        <!-- <div class="mb-2"> -->
          <!-- <button type="submit">Done</button> -->
          <app-button 
            [buttonType]="'button'"  
            [button_2]="true" 
            [buttonClass]="'button button-info mx-3'" 
            (click)="activeModal.close()" 
            [buttonLable]="enumButtonName.CANCEL">
          </app-button>
          <app-button 
            [buttonType]="'submit'"  
            [button_2]="true"  
            [buttonClass]="'button button-primary mx-3'" 
            (click)="(assignModel.salesPersonId || assignModel.altSalesPersonId || assignModel.buyersId)? assignCompanyToRole():''" 
            [buttonLable]="enumButtonName.APPLY">
        </app-button>
        <!-- </div> -->
      </div>
    </div>
    </form>
    
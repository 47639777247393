import { AgGridService } from './../ag-grid.service';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-cell-icon',
  templateUrl: './cell-icon.component.html',
  styleUrls: ['./cell-icon.component.scss']
})
export class CellIconComponent implements OnInit, ICellRendererAngularComp {

  constructor(public agGridService: AgGridService) {
  }
  params: any;
  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    this.params = params;
    return true;
  }

  ngOnInit(): void {
  }

  toggleExpandableRow() {
    var checkAllExpandRows = this.agGridService.getGridApi().getModel().gridOptionsWrapper.gridOptions.rowData?.every((row) => row.isExpandable == true);
    let expanded = 0
    let data = this.params.api.getModel().rowsToDisplay
    data.map(value => {
      if (value.data.isExpandable) {
        expanded++
      }
    })
    this.params.data.isExpandable = !this.params.data.isExpandable;
    this.params.api.resetRowHeights();
    this.params.api.redrawRows();
    if (expanded + 1 == data.length) {
      this.agGridService.toggleHeaderExpandIcon.next(true)
    }
    if (expanded == 1) {
      this.agGridService.toggleHeaderExpandIcon.next(true)
    }
  }

}

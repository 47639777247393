import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { LoginService } from 'src/app/components/authentication/login/login.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardGuard implements CanActivate {

  constructor(
    public loginService: LoginService,
    public router: Router,
    public storage : StorageService
  ) { 

  }

  public GetToken(): any {
    return this.storage.retrieve('authorizationDataIdToken');
}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.GetToken()
    if(token){
      return true;
    } else {
       return this.router.createUrlTree(['']);
    }

    
  }

  
}

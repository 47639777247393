import { Assign, CompanyPaginationModel, DeleteCompany, GetAccountAddressByCompanyIdRequestModel, GetAllDropDownModel, GetPeopleByCompanyIdRequestModel, ImportModel,  InsertUpdateManageManufacturer,  ActivitysRequestModel,  UploadDocuments, InsertCertification, DownloadDocuments, ExportMailingList} from './companies.model';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { Operations } from 'src/app/shared/model/filter.model';
import { Result } from 'src/app/shared/model/response.model';
import { DataService } from 'src/app/shared/services/data.service';
import { DeleteCompanyAddress, InserUpdateAddress, InsertUpdateAccountAddress } from '../../pop-ups/add-company/add-company.model';
import { GetAllManageManufacturers } from '../company-info-tabs/manufacturers/manufacturers.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import {URLS }   from 'src/app/components/crm/crm-api-constants';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private service: DataService,  public storageService:StorageService) { }
  public isEdit: EventEmitter<any>    = new EventEmitter();
  public isSelected = new Subject<boolean>();
  public toggelheight = new Subject<boolean>();
  public isExpanded = new BehaviorSubject(false);
  expand = false
  public filterList = new Subject<any[]>();
  public paramsss = new Subject<boolean>();
  public id:string='';
  public preview = new BehaviorSubject(undefined);
  public isLoadData = new BehaviorSubject(false);
  private responseData: any;
  public activeTab = new BehaviorSubject(undefined);
  public activeVendorTab = new BehaviorSubject(undefined);

  setResponseData(data: any) {
    this.responseData = data;
  }

  getResponseData() {
    const data = this.responseData;
    this.responseData = undefined; 
    return data;
  }
  
  getValueData(toggelheight:boolean){
    this.toggelheight.next(toggelheight);
  }
  // get table data
  GetAllCompanies(getAllCompanies: CompanyPaginationModel): Observable<Result> {
    let url = URLS.company.getAllCompanies;
    return this.service.post(url, getAllCompanies).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }

  // GetAllAccountTypes dropdown list
  accountTypeSelectDropdown(): Observable<Result> {
    let url =URLS.dropDown.getAllSalesStageList;
    return this.service.post(url,{}).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }
    //Add assign
    assign(assign: Assign): Observable<Result> {
      let url = URLS.company.addAssignee;
      const myJson = JSON.stringify(assign);
      return this.service.post(url, assign).pipe<Result>(tap((response: any) => {
      return response;
        })
      );
    }
    exportMailing(exportMailing: ExportMailingList): Observable<Result> {
      let url = URLS.company.exportMailingList;
      const myJson = JSON.stringify(exportMailing);
      return this.service.post(url, exportMailing).pipe<Result>(tap((response: any) => {
      return response;
        })
      );
    }

    deleteCompany(deleteCompany:DeleteCompany): Observable<Result> {
      deleteCompany.userId   = this.storageService.retrieve('userId');
      let url = URLS.company.deleteCompanies;
      return this.service.post(url, deleteCompany).pipe<Result>(tap((response: any) => {
      return response;
        })
      );
    }

   


    getCompanyDetailsById(id:string): Observable<Result>{
      let url = URLS.company.getCompanyDetailsById;
      return this.service.post(url,{companyId:id}).pipe<Result>(tap((response: any) => {
      return response;
        })
      );
    }


    getAccountAddressByCompanyId(data:GetAccountAddressByCompanyIdRequestModel): Observable<Result>{
      let url = URLS.companyAddress.getCompanyAddressByCompanyId;
      return this.service.post(url,data).pipe<Result>(
        tap((response: any) => {
          return response;
        })
      );
    }





    insertCompanyAddress(data:InserUpdateAddress): Observable<Result>{
      let url=URLS.companyAddress.insertCompanyAddress;
      const json=JSON.stringify(data)
      return this.service.post(url,data).pipe<Result>(
        tap((response: any) => {
          return response;
        })
      );
    }
    UpdateCompanyAddress(data:InserUpdateAddress): Observable<Result>{
      let url=URLS.companyAddress.updateCompanyAddress;
      const json=JSON.stringify(data)
      return this.service.post(url,data).pipe<Result>(
        tap((response: any) => {
          return response;
        })
      );
    }
   DeleteCompanyAddress(data:DeleteCompanyAddress): Observable<Result>{
      let url=URLS.companyAddress.deleteCompanyAddress;
      const json=JSON.stringify(data)
      return this.service.post(url,data).pipe<Result>(
        tap((response: any) => {
          return response;
        })
      );
    }



    // api service of conatct tab 
    
    getPeopleByCompanyId(data:GetPeopleByCompanyIdRequestModel): Observable<Result>{
      let url = URLS.companyContact.getCompanyContactsByCompanyId;
      return this.service.post(url,data).pipe<Result>(
        tap((response: any) => {
          return response;
        })
      );
    }
 
    getPeopleByContactId(data:any): Observable<Result>{
      let url = URLS.companyContact.getCompanyContactByContactId;
      return this.service.post(url,data).pipe<Result>(
        tap((response: any) => {
          return response;
        })
      );
    }

    
    deleteByContactId(data:any): Observable<Result>{
      let url = URLS.companyContact.deleteCompanyContact;
      return this.service.post(url,data).pipe<Result>(tap((response: any) => {
      return response;
        })
      );
    }



    getHistoryByCompanyId(data:any): Observable<Result> {
      let url = URLS.companyHistory.getCompanyHistoryByContactId;
      const myjson = JSON.stringify(data);
      return this.service.post(url, data, true).pipe<Result>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // getAllDropdowns(): Observable<Result>{
    //   let url = "SourceMatrixApi/Companies/GetDropDownList";
    //   return this.service.get(url).pipe<Result>(
    //     tap((response: any) => {
    //       return response;
    //     })
    //   );
    // }

    // exportCompany(data:CompanyPaginationModel): Observable<Result>{
    //   let url = "SourceMatrixApi/Companies/exportAll";
    //   return this.service.post(url, data).pipe<Result>(
    //     tap((response: any) => {
    //       return response;
    //     })
    //   );
    // }
    deleteCertification(data:any):Observable<Result>{
      let url =URLS.vendorCertification.deleteVendorCertification;
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }


    insertCertification(insertCertification:InsertCertification):Observable<Result>{
      let url =URLS.vendorCertification.insertVendorCertification;
      const myJson=JSON.stringify(insertCertification)
      return this.service.post(url,insertCertification,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }


    updateCertification(insertCertification:InsertCertification):Observable<Result>{
      let url =URLS.vendorCertification.updateVendorCertification;
      const myJson=JSON.stringify(insertCertification)
      return this.service.post(url,insertCertification,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }


    getCompanyHistories(data:any):Observable<Result>{
      let url =URLS.companyHistory.getAllCompanyHistories;
      const myJson=JSON.stringify(data)
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }

    getCertificationsByCompany(data:any):Observable<Result>{
      let url =URLS.vendorCertification.getAllVendorCertification;
      const myJson=JSON.stringify(data)
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }
    getDocumentsByCompany(data:any):Observable<Result>{
      let url =URLS.companyDocuments.getDocumentByCompanyOrTypeId;
      const myJson=JSON.stringify(data)
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }


    delectDocuments(data:any):Observable<Result>{
      let url =URLS.companyDocuments.deleteDocumentById;
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }
    downloadCertificationDocument(downloadDocuments: DownloadDocuments): Observable<Result> {
      let url = URLS.companyDocuments.downloadDocuments;
      return this.service.post(url,downloadDocuments).pipe<Result>(tap((response: any) => {
      return response as Blob;
      }));
    }



    downloadDocument(downloadDocuments: DownloadDocuments): Observable<Result> {
        let url = URLS.companyDocuments.downloadDocuments;
        return this.service.post(url,downloadDocuments).pipe<Result>(tap((response: any) => {
        return response as Blob;
      }));
    }

    uploadResellerDocument(data:UploadDocuments):Observable<Result>{
      let url=URLS.companyDocuments.uploadCompanyDetailsSettingSectionDocument
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }


    uploadTradeReferenceDocument(data:UploadDocuments):Observable<Result>{
      let url=URLS.companyDocuments.uploadCompanyDetailsSettingSectionDocument
      const myJson=JSON.stringify(data);
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }

    uploadCerticationDocument(data:UploadDocuments):Observable<Result>{
      let url=URLS.companyDocuments.uploadCompanyDetailsSettingSectionDocument
      const myJson=JSON.stringify(data);
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }

    insertUpdateManageManufacturer(data:InsertUpdateManageManufacturer):Observable<Result>{
      let url =URLS.manageManufacturer.insertUpdateManageManufacturer
      const myJson=JSON.stringify(data)
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }

    getAllManageManufacturer(data:GetAllManageManufacturers):Observable<Result>{
      let url =URLS.manageManufacturer.getAllManageManufacturer;
      const myJson=JSON.stringify(data)
      return this.service.post(url,data,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }


    getTasksByConatctId(activitysRequestModel:ActivitysRequestModel):Observable<Result>{
      let url =URLS.activityManager.getAllActivityByContactId;
      const myJson=JSON.stringify(activitysRequestModel)
      return this.service.post(url,activitysRequestModel,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }

    }

    
    

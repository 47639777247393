<div class="d-xl-block d-md-none">
    <ul
      style="border-radius: 5px"
      ngbNav
      #nav="ngbNav"
      [(activeId)]="active"
      class="nav-tabs bg-semi-light d-flex px-5 align-items-center" [ngClass]="ngClass">
      <li *ngFor="let tab of tabData" [ngbNavItem]="tab.id" class="" [ngClass]="listClass">
        <div
          class="{{tab.id === 22 || tab.id === 33 ?'cursor-not-allowed':'hand'}}"
          [ngClass]="active === tab.id ? 'active-button' : 'not-active'"
          (click)="onTabClick(tab.id)"
          ngbNavLink>
          <!-- (click)="activeTab(tab.id)" -->
          {{ tab.label }}
        </div>
        <ng-template ngbNavContent>
          <ng-container *ngComponentOutlet="tab.component"></ng-container>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-3" [ngClass]="tabClass"></div>
</div>

<!-- ********************************FOR RESPONSIVE IPAD PRO***************************************************** -->
<div class="m-t-15 h-100 d-xl-none d-md-block" [ngClass]="tabClass">
  <div class="custom-dropdown mb-2 position-relative">
    <div class="selected-option text-center" (click)="toggleDropdown()">
      <span class="pe-3">{{ getSelectedTabLabel() }}</span>
      <img *ngIf="!dropdownOpen" style="transform: rotate(90deg);" src="../../../../../../assets/SVGIcons/Arrow---Right-Circle.svg" alt="">
      <img *ngIf="dropdownOpen" style="transform: rotate(-90deg);" src="../../../../../../assets/SVGIcons/Arrow---Right-Circle.svg" alt="">
    </div>
    <div class="col-12 dropdown-options position-absolute" style="z-index: 1;" *ngIf="dropdownOpen">
      <div *ngFor="let tab of tabData">
        <div>
          <div
            [ngClass]="{
              'active-button': active === tab.id,
              'not-active': active !== tab.id,
              'cursor-not-allowed' : active !== tab.id
            }"
            (click)="selectTab(tab.id)"
            (touchstart)="$event.preventDefault()"
          >
            {{ tab.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngComponentOutlet="getActiveComponent()"></ng-container>
</div>
  
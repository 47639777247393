import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[appInputBogieFormat]'
})

export class InputBogieFormatDirective {

  @Input() appInputBogieFormat: boolean;

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (this.appInputBogieFormat) {
      this.ngControl.valueAccessor?.writeValue(
        this.format(value)
      );
    }
  }

  format(value: string): string {
    if (!value) {
      return '';
    }
    const cleanedValue = value.replace(/\D/g, '');
    const areaCode = cleanedValue.substring(0, 3);
    const firstPart = cleanedValue.substring(3, 6);
    const secondPart = cleanedValue.substring(6, 10);
    let formattedValue = `(${areaCode})${firstPart}`;
    if (secondPart) {
      formattedValue += `-${secondPart}`;
    }
    return formattedValue;
  }
  

}


export class Response {
}

export class Result {
    ok: boolean;
    body: any;
    isSuccess: boolean;
    message: string;
    response: any
    totalRecords: number
  }

  
  export class PaginationVM {
    constructor() {
      this.PageSize = 50;
      this.PageNumber = 1;
      this.StartIndex = 1
      this.GetAll = false;
    }
    Id: number;
    PageSize: number;
    PageNumber: number;
    StartIndex: number;
    LastIndex: number;
    TotalRecords: number;
    FromDate: Date | string;
    ToDate: Date | string;
    GetAll: boolean;
    SortedBy: string;
    marketplaceId :number;
  }
  
  export enum PageSizeDropdown {
    Fifty = 0,
    Hundred = 1,
    TwoHundred = 2,
    ThreeHundred = 3,
    All = 4,
  }
  
  export const PageSizeDropdownDes = {
    [PageSizeDropdown.Fifty]: "50",
    [PageSizeDropdown.Hundred]: "100",
    [PageSizeDropdown.TwoHundred]: "200",
    [PageSizeDropdown.ThreeHundred]: "300",
    [PageSizeDropdown.All]: "All"
  }


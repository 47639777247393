import { SidebarNotificationService } from './../sidebar-notification/sidebar-notification.service';
import { TabsService } from './../tabs/tabs.service';
import { CommonService } from "./../../services/common.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
  Input,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Menu, NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SystemSettingsComponent } from 'src/app/components/admin/system-settings/pages/system-settings/system-settings.component';
import { SystemSettingsService } from 'src/app/components/admin/system-settings/pages/system-settings/system-settings.service';
import { log } from 'console';
import { AddiconList } from 'src/app/components/authentication/login/login.model';
import { LoginService } from 'src/app/components/authentication/login/login.service';
import { StorageService } from '../../services/storage.service';
import { AddCompanyComponent } from 'src/app/components/crm/companies/pop-ups/add-company/add-company.component';
import { TablePaginationModel } from '../ag-grid/ag-grid.model';
import { AddContactComponent } from 'src/app/components/crm/contacts/pop-ups/add-contact/add-contact.component';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { UserRequestModel } from 'src/app/components/admin/user-management/pages/user-management/user-management.model';
import { UserManagementService } from 'src/app/components/admin/user-management/pages/user-management/user-management.service';
import { UserList } from '../../model/enum';
import { AddRequirementComponent } from 'src/app/components/sales/requirements/popup/add-requirement/add-requirement.component';
import { CompanySettingsComponent } from 'src/app/components/admin/company-settings/company-settings.component';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild("header") header: ElementRef;
  public elem: any;
  public dark: boolean = this.layout.config.settings.layout_version == "dark-only" ? true : false;
  imageData: string;
  currentNav: string;
  showlist: boolean = false;
  activeTab: string = "";
  activeIcon: string;
  clickValue:boolean= true;
widthVal:number;
  vvvaaalll:boolean=false;
  paginationBaseModel = new TablePaginationModel();
  @Input() buttonName: string = 'Add Company'
  userRequestModel= new UserRequestModel();


  constructor(
    private systemSettingsService: SystemSettingsService,
    public layout: LayoutService,
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    public commonService: CommonService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    public tabsService: TabsService,
    private modalService: NgbModal,
    public sidebarNotificationService: SidebarNotificationService,
    public loginService: LoginService,
    public storageService: StorageService,
    public agGridService: AgGridService,
    public userManagementService :UserManagementService,
  ) {
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // let cleanName = event.url.replace('/crm/', '')
          let cleanName = event.url.substring(event.url.lastIndexOf('/') + 1);
          cleanName = cleanName.charAt(0).toUpperCase() + cleanName.slice(1);
          this.tabsService.thisTab = cleanName
          if(cleanName == 'Customer'){
            this.tabsService.thisTab = 'Customers'
          }
          menuItems.filter((items) => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) {
              return false;
            }
            items.children.filter((subItems) => {
              if (subItems.path === event.url.substring(1)) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) {
                return false;
              }
              subItems.children.filter((subSubItems) => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.widthVal=event.target.innerWidth ;
    if (event.target.innerWidth < 1025) {
      this.leftArrowNone = false;
      this.rightArrowNone = false;
    }
    else {
      this.leftArrowNone = true;
      this.rightArrowNone = true;
    }
  }

  ngOnInit() {
    this.getAllUser();
    this.systemSettingsService.imageDataName.subscribe(data => {
      if (data == undefined) {
        this.imageData = "../assets/SVGIcons/logo.svg"
      } else {
        this.imageData = data;

      }
    })
    this.elem = document.documentElement;
    if (this.width < 1025) {
      this.leftArrowNone = false;
      this.rightArrowNone = false;
    }
    else {
      this.leftArrowNone = true;
      this.rightArrowNone = true;
    }


    this.sidebarNotificationService.headerIconActive.subscribe((res: string) => {
      this.activeIcon = res;

    })
  }

  ngAfterViewChecked() {
    this.commonService.headerHeight.next(
      this.header.nativeElement.offsetHeight
    );
  }

  public menuItems: Menu[];
  // For Horizontal Menu
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = true;

  // For Horizontal Menu

  @ViewChild("widgetsContent", { read: ElementRef })
  public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: "smooth",
    });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: "smooth",
    });
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }
showSecondary:boolean = false;
  // Click Toggle menu
  toggletNavActive(item) {
    this.showSecondary = true;
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) {
          return false;
        }
        a.children.forEach((b) => {
          if (a.children.includes(item)) {

            b.active = false;
          }
        });
      });
      // this.router.navigateByUrl(item.path);
    }
    if (!item.active) item.active = !item.active;
    this.cdref.detectChanges();
    this.commonService.headerHeight.next(
      this.header.nativeElement.offsetHeight
    );
  }

  closeNavActive(item) {
    this.showSecondary = false;
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) {
          return false;
        }
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
    this.cdref.detectChanges();
    this.commonService.headerHeight.next(
      this.header.nativeElement.offsetHeight
    );
  }


  addTabs(item) {
    if (item.title == 'System Settings') {
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        size:'xl',
      };
      const modalRef = this.modalService.open(SystemSettingsComponent,modalOption);
    }else if(item.title == 'Company Settings'){
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        size:'xl',
      };
      const modalRef = this.modalService.open(CompanySettingsComponent,modalOption);
    }
    else {
      this.tabsService.addTab(item);
      // this.router.navigateByUrl(item.path);
    }
  }

  // toggelNotificationSideBar(){
  //   this.sidebarNotificationService.isSidebarNotificationShow.next(true);
  // }


  toggelNotificationSideBar(val,clickValue) {
    if(!clickValue){
      this.sidebarNotificationService.isSidebarNotificationShow.next(false);
    }else{
    val == 'notifications' ? this.sidebarNotificationService.isSidebarNotificationShow.next({ tab: 'notifications', bool: true }) : this.sidebarNotificationService.isSidebarNotificationShow.next({ tab: 'tasks', bool: true });
    if (val == 'notifications' || val == 'tasks') {
      this.showlist = false;
    }
  }
  }

  addListShow(val) {
    this.showlist = !this.showlist;
    if (val == 'add') {
      this.sidebarNotificationService.isSidebarNotificationShow.next(false);
    }
    this.addiconList[this.index].selected=false;
    this.addiconList[0].selected=true;
  }

  index:number;
  seletedItem(index: number) {
    this.index=index;
    for (let i = 0; i < this.addiconList.length; i++) {
      if (i === index) {
        this.addiconList[i].selected = true;
      }
      else {
        this.addiconList[i].selected = false;
      }
    }

  }


  selectedHero?: AddiconList;
  addiconList: AddiconList[] = [
    { id: 1, name: "Add New", selected: true },
    { id: 2, name: "Company", selected: false },
    { id: 3, name: "Contact", selected: false },
    { id: 4, name: "Requirement", selected: false },
    { id: 5, name: "Offer", selected: false },
    { id: 6, name: "Quote", selected: false },
    { id: 7, name: "Sales Order", selected: false },
    { id: 8, name: "Purchase Order", selected: false },
    { id: 9, name: "Work Order", selected: false },
    { id: 10, name: "RMA", selected: false },
    { id: 11, name: "RTV", selected: false },
  ];

  openAddEditCompanyModal(companyId: string) {
    if (companyId === 'Company') {
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "addCompanyPopUP",
      };
      const modalRef = this.modalService.open(AddCompanyComponent, modalOption);
      modalRef.componentInstance.stagesName = this.paginationBaseModel.stage;
      modalRef.componentInstance.headingsNames = this.buttonName;
    }
  }
  openAddEditAddContactModal(companyId: string) {
    if (companyId === 'Contact') {
      const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "addContactPopup",
      };
      const modalRef = this.modalService.open(AddContactComponent, modalOption);
      modalRef.result.then((res) => {
        if (res.isSuccess == true) {
          this.agGridService.tablePaginationData.next(this.paginationBaseModel);
        }
      })
    }
  }
  openAddRequiremnt(companyId: string) {
    if (companyId === 'Requirement') {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
       size:'xl',
     // windowClass: "addCompanyPopUP",
    };
    const modalRef = this.modalService.open(AddRequirementComponent, modalOption);
    modalRef.componentInstance.headingsNames = "Add Requirement";
  }
  }

  getAllUser(){
    this.userRequestModel.getAll = true;
    this.userManagementService.getAllUser(this.userRequestModel).subscribe(res=>{
      if(res.isSuccess){
        var DropDownModel = res.response;
        this.agGridService.userList.next(DropDownModel);
       // this.storageService.store(UserList.USER_LIST,DropDownModel);
     }
    })
  }

}


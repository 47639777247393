import { EventEmitter, Injectable } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import * as model from './roles-and-permissions.model'
import { Observable, Subject, tap } from 'rxjs';
import { Result } from 'src/app/shared/model/response.model';
import { URLS } from 'src/app/components/admin/user-management/user-api-constant';

@Injectable({
  providedIn: 'root'
})
export class RolesPermissionsService {
 
  public  rolesPageChanged = new Subject<string>();
  public isEdited:boolean=false;
  constructor(private service: DataService,) { }

  getAllRoles(getAllRolesRequestModel : model.GetAllRolesRequestModel): Observable<Result> {
    let url = URLS.user.getAllRoles;
    return this.service.post(url, getAllRolesRequestModel, true).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }

  getPermissionByRoleIds(getAllPermissionRequestModel:model.GetAllPermissionRequestModel):Observable<Result>{
    let url=URLS.user.getPermissionByRoleIds
    return this.service.post(url,getAllPermissionRequestModel).pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }


  updatePermissions(updatePermissionModel:model.UpdatePermissionModel):Observable<Result>{
    let url=URLS.user.updatePermissions;
    return this.service.post(url,updatePermissionModel).pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }

  //add and update role delete services
  roleCreate(createRoleVM: model.AddUpdateRoleModel): Observable<Result> {
    let url = URLS.user.roleCreate;
    return this.service.post(url, createRoleVM).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }

  roleUpdate(updateRoleDataVM: model.AddUpdateRoleModel): Observable<Result> {
    let url = URLS.user.roleUpdate;
    const myJSON = JSON.stringify(updateRoleDataVM);
    return this.service.post(url, updateRoleDataVM).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }

  deleteRole({roleId: userRoleId}): Observable<Result> {
    let url = URLS.user.deleteRole;
    return this.service.post(url, {roleId: userRoleId}).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  //add and update role ,delete end
}


import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UserManagementService } from 'src/app/components/admin/user-management/pages/user-management/user-management.service';
import { Id } from 'src/app/components/admin/code-crosswalk/pages/code-crosswalk.model';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit ,AfterViewInit{
  @Input() isValid: boolean =false;
  @Input() selectValue;
  @Input() required : boolean = false;
  //@Input() data:any[];
  @Input() selectLabel:string;
  @Input() disabled:boolean =false;
  @Input () optionDisable:number=null;
  @Output() selectValueEmit:EventEmitter<any> = new EventEmitter();
  @Output() selectionChange = new EventEmitter<number | null>();
  @Input() isSvgVisible : boolean = false;
  @ViewChild('hiddenElement') hiddenElement: ElementRef;
  @ViewChild('dropdownLabel') dropdownLabel: ElementRef;
  @ViewChild('dropdown') dropdown: NgbDropdown;
  isFocused = false;
  SetValue 
  filteredData: Array<{ id: number, name: string }> = [];
  private _data: Array<{ id: number, name: string }> = []; 
  searchText: string = '';
  @Input()
  set data(value: Array<{ id: number, name: string }>) {
    this._data = value;
    this.filteredData = [...this._data];
  }

  get data(): Array<{ id: number, name: string }> {
    return this._data;
  }

  //@Output() selectValueChange: EventEmitter<number> = new EventEmitter<number>();
 // @Output() setCurrentSelection: EventEmitter<{ name: string, id: number }> = new EventEmitter<{ name: string, id: number }>();

 setUp(event: KeyboardEvent) {
  const key = event.key;
  if (key.length === 1) { // Check if it's a printable character
    this.searchText += key;
  } else if (key === 'Backspace') { // Handle backspace
    this.searchText = this.searchText.slice(0, -1);
  }
  this.filterData(key);
 } 

  filterData(key: string) {
    if (key && key.length === 1 && /[a-zA-Z]/.test(key)) {
      this.filteredData = this._data.filter(item =>
        item.name.toLowerCase().startsWith(key.toLowerCase())
      );
    } else {
      this.filteredData = [...this._data];
    }
  }

  // setUp(e){
  // console.log(e)
  // }
  constructor(
    public userManagementService: UserManagementService,
    private el: ElementRef, private renderer: Renderer2
    ) { 
      this.renderer.listen('window', 'click', (e: Event) => {
        if (!this.el.nativeElement.contains(e.target)) {
          this.resetFilter();
        }
      });
    }

    resetFilter() {
      this.searchText = '';
      this.filteredData = [...this._data];
    }

  ngOnInit(): void {
    this.getCurrentSelectionName()

  }
  getCurrentSelectionName(): string {
    const selectedItem = this.data.find(item => item.id === this.selectValue);
    return selectedItem ? selectedItem.name : null;
  }

  ngAfterViewInit(): void{
    document.getElementById('hidden').focus();
  }
  selectValueChange(val){
       this.selectValueEmit.emit(val);
     }
  setCurrentSelection(option: any,val:any): void {
    if(option !=='No Records Found' && val !== null){
    this.selectValueEmit.emit(val);
  }
  }
  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }
}

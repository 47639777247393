import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[tabNext]'
})
export class TabNextDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      const inputs = Array.from(document.querySelectorAll('input, textarea, select, button'));
      const currentIndex = inputs.indexOf(event.target as HTMLElement);
      const nextIndex = (currentIndex + 1) % inputs.length;
      const nextInput = inputs[nextIndex] as HTMLElement;
      if (nextIndex === 0 && document.activeElement === inputs[currentIndex]) {
        // If currentIndex is the first element and nextIndex becomes 0, focus on the last element
        const lastInput = inputs[inputs.length - 1] as HTMLElement;
        lastInput.focus();
      } else {
        nextInput.focus();
      }
      event.preventDefault();
    }
  }
}

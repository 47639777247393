export class Login {
}
export class LoginModel 
{
    email    : string; 
    password    : string; 
    rememberMe  : boolean; 
    timeZone    : string; 
    deviceToken : string
   
}

export class AddiconList 
{
    id       : number;
    name     : string;
    selected : boolean;
}

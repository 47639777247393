<div class="date-input range-datepicker">
	<div class="input-group">
		<input 
            name="datepicker" 
            class="input-calendar text-truncate"  
            ngbDatepicker
			[value]="inputDate" 
            #datepicker="ngbDatepicker" 
            [autoClose]="'outside'"
			(dateSelect)="onDateSelection($event)" 
            [displayMonths]="2" 
            [dayTemplate]="dayTemplate"
			[footerTemplate]="footerTemplate" 
            outsideDays="hidden" 
            [startDate]="fromDate!" 
            tabindex="-1"
			readonly="true" 
			/>
            <ng-template #dayTemplate let-date let-focused="focused">
                <span 
                    class="custom-day" 
                    [class.focused]="focused" 
                    [class.range]="isRange(date)"
                    [class.start-date]="isStartDate(date)" 
                    [class.end-date]="isEndDate(date)"
                    [class.faded]="isHovered(date) || isInside(date)" 
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                </span>
            </ng-template>
			<!-- Templates -->
<ng-template #footerTemplate>
	<hr class="my-0">
	<div class="action-buttons">
		<button class="button button-info font-11" (click)="onClear()">Clear</button>
		<button class="button button-primary font-11" (click)="onApply()" [disabled]="fromDate && !toDate">Apply</button>
	</div>
</ng-template>
        <div (click)="datepicker.toggle()">
            <svg id="calendar_3_" data-name="calendar (3)" xmlns="http://www.w3.org/2000/svg" width="14.106" height="13.224" viewBox="0 0 14.106 13.224">
                <path id="Path_1083" data-name="Path 1083" d="M12.784,1.882H11.02V1.441a.441.441,0,1,0-.882,0v.441H3.967V1.441a.441.441,0,0,0-.882,0v.441H1.322A1.324,1.324,0,0,0,0,3.2v9.7a1.324,1.324,0,0,0,1.322,1.322H12.784A1.324,1.324,0,0,0,14.106,12.9V3.2A1.324,1.324,0,0,0,12.784,1.882ZM1.322,2.763H3.086V3.2a.441.441,0,1,0,.882,0V2.763h6.171V3.2a.441.441,0,1,0,.882,0V2.763h1.763a.441.441,0,0,1,.441.441V4.526H.882V3.2A.441.441,0,0,1,1.322,2.763ZM13.224,12.9a.441.441,0,0,1-.441.441H1.322A.441.441,0,0,1,.882,12.9V5.408H13.224Z" transform="translate(0 -1)" fill="#8b9daa"/>
                <path id="Path_1084" data-name="Path 1084" d="M7.441,15.882h.441a.441.441,0,1,0,0-.882H7.441a.441.441,0,1,0,0,.882Z" transform="translate(-3.914 -8.829)" fill="#8b9daa"/>
                <path id="Path_1085" data-name="Path 1085" d="M12.441,15.882h.441a.441.441,0,1,0,0-.882h-.441a.441.441,0,0,0,0,.882Z" transform="translate(-6.71 -8.829)" fill="#8b9daa"/>
                <path id="Path_1086" data-name="Path 1086" d="M17.441,15.882h.441a.441.441,0,1,0,0-.882h-.441a.441.441,0,0,0,0,.882Z" transform="translate(-9.506 -8.829)" fill="#8b9daa"/>
                <path id="Path_1087" data-name="Path 1087" d="M22.441,15.882h.441a.441.441,0,0,0,0-.882h-.441a.441.441,0,0,0,0,.882Z" transform="translate(-12.302 -8.829)" fill="#8b9daa"/>
                <path id="Path_1088" data-name="Path 1088" d="M7.441,19.882h.441a.441.441,0,1,0,0-.882H7.441a.441.441,0,0,0,0,.882Z" transform="translate(-3.914 -11.065)" fill="#8b9daa"/>
                <path id="Path_1089" data-name="Path 1089" d="M12.441,19.882h.441a.441.441,0,1,0,0-.882h-.441a.441.441,0,0,0,0,.882Z" transform="translate(-6.71 -11.065)" fill="#8b9daa"/>
                <path id="Path_1090" data-name="Path 1090" d="M17.441,19.882h.441a.441.441,0,1,0,0-.882h-.441a.441.441,0,1,0,0,.882Z" transform="translate(-9.506 -11.065)" fill="#8b9daa"/>
                <path id="Path_1091" data-name="Path 1091" d="M22.441,19.882h.441a.441.441,0,1,0,0-.882h-.441a.441.441,0,1,0,0,.882Z" transform="translate(-12.302 -11.065)" fill="#8b9daa"/>
                <path id="Path_1092" data-name="Path 1092" d="M7.441,23.882h.441a.441.441,0,1,0,0-.882H7.441a.441.441,0,0,0,0,.882Z" transform="translate(-3.914 -13.302)" fill="#8b9daa"/>
                <path id="Path_1093" data-name="Path 1093" d="M12.441,23.882h.441a.441.441,0,1,0,0-.882h-.441a.441.441,0,0,0,0,.882Z" transform="translate(-6.71 -13.302)" fill="#8b9daa"/>
                <path id="Path_1094" data-name="Path 1094" d="M17.441,23.882h.441a.441.441,0,1,0,0-.882h-.441a.441.441,0,1,0,0,.882Z" transform="translate(-9.506 -13.302)" fill="#8b9daa"/>
              </svg>
              
            </div>
	</div>
</div>
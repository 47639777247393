// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "Your Api Key",
    authDomain: "Your Auth Domain",
    databaseURL: "Your Database Url",
    projectId: "Your Project Id",
    storageBucket: "Your StorageBucket url",
    messagingSenderId: "Your Sender Id"
  }
};


// export const  baseUrl = 'http://103.118.16.132:5081/api/v1/'
// export const  baseUrl = 'http://103.118.16.132:5080/api/v1/'
// export const  baseUrl = 'http://3.18.144.73:6081/api/v1/'
 export const  baseUrl = 'https://apistage.sourcematrixerp.com/api/';
 //export const  baseUrl = 'https://localhost:55001/api/'

//export const  baseUrl = 'https://jy04d9kii7.execute-api.us-east-2.amazonaws.com/api/'
export const  identitySocketUrl = 'https://identityapistage.sourcematrixerp.com/identityHub'
 export const  smxSocketUrl = 'https://appapistage.sourcematrixerp.com/smxHub'

/*

 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

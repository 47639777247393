import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserManagementService } from 'src/app/components/admin/user-management/pages/user-management/user-management.service';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit , OnChanges{
  @Output() switchValueEmit:EventEmitter<any> = new EventEmitter();

  @Input() status: boolean ;
  @Input() isEditable?: boolean ;
  @Input() id: boolean ;
  constructor(public userManagementService:UserManagementService) { }

  ngOnChanges(changes: SimpleChanges): void {
      console.log(changes)
  }

  ngOnInit(): void {
    this.userManagementService.checkedValueUser.subscribe((res)=>{
      if(res===this.id){
        this.status = !this.status
      }
    });
  }


}

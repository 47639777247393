
import { DocumentCellRendererComponent } from "src/app/components/crm/companies/pages/company-info-tabs/document-cell-renderer/document-cell-renderer.component";
import { AgColDefsI, AgGridI,} from "../../../../shared/components/ag-grid/ag-grid.model";
import { AgDropdownCorrectFieldComponent } from "src/app/shared/components/ag-dropdown-correct-field/ag-dropdown-correct-field.component";



const SystemsColumn: AgColDefsI = {
  headerName: "System Columns",
  field: "systemColumn",
  minWidth: 100,
  width:400,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellStyle:{ 
    'color':'var(--sitewide_primary_accent_color)',
    'padding-left':' 20px'
  },
  cellRenderer: params => {
    const cellValue = params.value;
   // const rowIndex = params.rowIndex;
    if (["SIC Code","SIC Description","NAICS Code","NAICS Year","NAICS Description","Industry"].includes(cellValue)) {
      const htmlContent = `<i class="fst-normal ps-1 p-0 text-danger">*</i>`;
      return `${cellValue}${htmlContent}`;
    } else {
      return cellValue;
    }
  },
  //flex:1
};

const SystemColumn: AgColDefsI = {
  headerName: "System Columns",
  field: "systemColumn",
  minWidth: 100,
  width:400,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellStyle:{ 
    'color':'var(--sitewide_primary_accent_color)',
    'padding-left':' 20px'
  },
  cellRenderer: params => {
    const cellValue = params.value;
   // const rowIndex = params.rowIndex;
    if (["Company","Stage", "Type","Country"].includes(cellValue)) {
      const htmlContent = `<i class="fst-normal ps-1 p-0 text-danger">*</i>`;
      return `${cellValue}${htmlContent}`;
    } else {
      return cellValue;
    }
  },
  //flex:1
};
const SystemVendorColumn: AgColDefsI = {
  headerName: "System Columns",
  field: "systemColumn",
  minWidth: 100,
  width:400,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellStyle:{ 
    'color':'var(--sitewide_primary_accent_color)',
    'padding-left':' 20px'
  },
  cellRenderer: params => {
    const cellValue = params.value;
   // const rowIndex = params.rowIndex;
    if (["Vendor Name","Type","Country"].includes(cellValue)) {
      const htmlContent = `<i class="fst-normal ps-1 p-0 text-danger">*</i>`;
      return `${cellValue}${htmlContent}`;
    } else {
      return cellValue;
    }
  },
  //flex:1
};

const MapColumn: AgColDefsI = {
  headerName: "Map to",
  field: "mappedColumn",
  minWidth: 200,
  width:470,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellRenderer: AgDropdownCorrectFieldComponent,
 // flex:1,
};

const StatusColumn: AgColDefsI = {
  headerName: "Status",
  field: "status",
  minWidth: 100,
  width:250,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  // cellStyle:{
  //   'display':'flex',
  //   'justify-content':'center',
  // },
  cellRenderer: DocumentCellRendererComponent,
  flex:1
};



export const ColDefsDocuments: AgColDefsI[] = [
   SystemColumn,
   MapColumn,
   StatusColumn,
 ];
 export const ColDefsDocumentsAnother: AgColDefsI[] = [
  SystemsColumn,
  MapColumn,
  StatusColumn,
];
export const ColDefsVendorDocuments: AgColDefsI[] = [
  SystemVendorColumn,
  MapColumn,
  StatusColumn,
];

export const AgGridImportVendorMap: AgGridI = {
  colDefs: ColDefsVendorDocuments,
  rowSelection: "multiple",
};

export const AgGridImportCompaniesMap: AgGridI = {
  colDefs: ColDefsDocuments,
  rowSelection: "multiple",
};
export const AgGridImportCodecrosswalkMap: AgGridI = {
  colDefs: ColDefsDocumentsAnother,
  rowSelection: "multiple",
};


const ipadMediaQuery = window.matchMedia("(min-width: 767px) and (max-width: 1025px)");
if (ipadMediaQuery.matches) {
  console.log("iPad screen detected. Setting width to 300.");
  SystemColumn.width = 300; // Set width for iPad screens
}

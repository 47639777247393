<div>
  <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen">
    <h6 class="modal-title text-center text-primary-color font-15">
      {{ title }} {{enumPopUpTitles.CONTACT}}
    </h6>
  </div>
  <div class="modal-body p-4 mx-2">
    <div class="text-center mb-4">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-md-6 p-0 col-lg-6">
            <div class="firstCol pe-4">
              <div class="col p-0 mb-3">
                <app-input
                  [inputReqValidation]="true"
                  [inputLabel]="'First Name'"
                  [inputType]="'text'"
                  onlyAlphabets
                  [inputValue]="insertConatactModel.firstName"
                  (inputValueEmit)="insertConatactModel.firstName = $event"
                  [ngClass]="{
                    'border-red': !insertConatactModel.firstName && submited
                  }"
                >
                </app-input>
              </div>
              <div class="col p-0 mb-3">
                <app-input
                  [inputReqValidation]="true"
                  [inputLabel]="'Last Name'"
                  [inputType]="'text'"
                  onlyAlphabets
                  [inputValue]="insertConatactModel.lastName"
                  (inputValueEmit)="insertConatactModel.lastName = $event"
                  [ngClass]="{
                    'border-red': !insertConatactModel.lastName && submited
                  }"
                >
                </app-input>
              </div>
              <div class="col p-0 mb-3">
                <app-input
                  [inputLabel]="'Title'"
                  [inputType]="'text'"
                  onlyAlphabets
                  [inputValue]="insertConatactModel.title"
                  (inputValueEmit)="insertConatactModel.title = $event"
                >
                </app-input>
              </div>
              <div class="col mb-lg-0 p-0">
                <app-dropdown
                  [selectLabel]="'Gender'"
                  [data]="genderList"
                  [selectValue]="insertConatactModel.genderId"
                  (selectValueEmit)="insertConatactModel.genderId = $event"
                >
                </app-dropdown>
              </div>

              <div class="col p-0 mt-3">
                <app-dropdown
                  [selectLabel]="'Department'"
                  [data]="departmentList"
                  [selectValue]="insertConatactModel.departmentId"
                  (selectValueEmit)="insertConatactModel.departmentId = $event"
                >
                  >
                </app-dropdown>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 p-0 col-lg-6">
            <div class="firstCol pe-4">
              <div class="col p-0 mb-3">
                <app-input
                  [inputLabel]="'Location'"
                  [inputType]="'text'"
                  [inputValue]="insertConatactModel.location"
                  (inputValueEmit)="insertConatactModel.location = $event"
                >
                </app-input>
              </div>
              <div class="col p-0 mb-3">
                <app-dropdown
                  [data]="timezoneList"
                  [selectLabel]="'Timezone'"
                  [selectValue]="insertConatactModel.timeZoneId"
                  (selectValueEmit)="insertConatactModel.timeZoneId = $event"
                >
                </app-dropdown>
              </div>
              <div class="col p-0 mb-3">
                <app-input
                  [inputLabel]="'Email'"
                  [inputType]="'text'"
                  [inputValue]="insertConatactModel.email"
                  (inputValueEmit)="insertConatactModel.email = $event ;onEmailChange()" 
                  [inputMaxlength]="40"
                 [ngClass]="{ 'border-red': isEmailInvalid && submited}"
                >
                </app-input>
              </div>
              <div class="col p-0 mb-3">
                <app-input
                  [inputLabel]="'Main Office Number + Extension'"
                  [inputType]="'text'"
                  [inputValue]="insertConatactModel.mainOfficeNumber"
                  (inputValueEmit)="insertConatactModel.mainOfficeNumber = $event"
                  onlyNumbers
                  [inputMaxlength]="14"
                >
                </app-input>
              </div>
              <div class="col p-0 mb-3">
                <app-input
                  [inputLabel]="'Direct Number'"
                  [inputType]="'text'"
                  onlyNumbers
                  [inputValue]="insertConatactModel.directNumber"
                  (inputValueEmit)="insertConatactModel.directNumber = $event"
                  [inputMaxlength]="14"
                >
                </app-input>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 p-0 col-lg-6">
            <div class="thirdCol pe-4">
              <div class="col p-0 mb-3">
                <app-input
                  [inputLabel]="'Mobile Number'"
                  [inputType]="'text'"
                  onlyNumbers
                  [inputValue]="insertConatactModel.mobileNumber"
                  (inputValueEmit)="insertConatactModel.mobileNumber = $event"
                  [inputMaxlength]="14"
                >
                </app-input>
              </div>
              <div class="col p-0 mb-3">
                <app-input
                  [inputLabel]="'Fax Number'"
                  [inputType]="'text'"
                  [inputValue]="insertConatactModel.faxNumber"
                  (inputValueEmit)="insertConatactModel.faxNumber = $event"
                  onlyNumbers
                  [inputMaxlength]="10"
                >
                </app-input>
              </div>
              <div class="col p-0 mb-3">
                <app-input
                  [inputLabel]="'Skype'"
                  [inputType]="'text'"
                  [inputValue]="insertConatactModel.skype"
                  (inputValueEmit)="insertConatactModel.skype = $event"
                  [inputMaxlength]="25"
                >
                </app-input>
              </div>
              <div class="col p-0 mb-3">
                <app-input
                  [inputLabel]="'Xing'"
                  [inputType]="'text'"
                  [inputValue]="insertConatactModel.xing"
                  (inputValueEmit)="insertConatactModel.xing = $event"
                  [inputMaxlength]="25"
                >
                </app-input>
              </div>
              <div class="col p-0">
                <app-input
                  [inputLabel]="'LinkedIn'"
                  [inputType]="'text'"
                  [inputValue]="insertConatactModel.linkedIn"
                  (inputValueEmit)="insertConatactModel.linkedIn = $event"
                  [inputMaxlength]="25"
                >
                </app-input>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 p-0 col-lg-6">
            <div class="fourthCol">
              <div
                *ngIf="isComapny && currentTab != 'vendor-contact'"
                class="col mb-xl-3 p-0 mb-3"
              >
                <app-dropdown
                  [data]="companyNameList"
                  [selectLabel]="'Company Name'"
                  [selectValue]="insertConatactModel.companyId"
                  (selectValueEmit)="insertConatactModel.companyId = $event"
                  [required]="true"
                  [ngClass]="{
                    'border-red': !insertConatactModel.companyId && submited
                  }"
                >
                </app-dropdown>
              </div>
              <div
                *ngIf="currentTab == 'vendor-contact'"
                class="col mb-xl-3 p-0 mb-3"
              >
                <app-dropdown
                  [data]="vendorNameList"
                  [selectLabel]="'Vendor Name'"
                  [selectValue]="insertConatactModel.companyId"
                  (selectValueEmit)="insertConatactModel.companyId = $event"
                  [required]="true"
                  [ngClass]="{
                    'border-red': !insertConatactModel.companyId && submited
                  }"
                >
                </app-dropdown>
              </div>
              <div class="col-xl-12 mb-1 p-0">
                <div class="d-flex checkbox-solid-info show-inactive">
                  <app-checkbox
                    class="hand"
                    [status]="insertConatactModel.markAsPrimaryContact"
                    (check)="
                      insertConatactModel.markAsPrimaryContact = $event;
                      insertConatactModel.markAsPrimaryContact
                        ? openReplacePrimaryPopup(insertConatactModel)
                        : ''
                    "
                    [lableClass]="'text-light-gray hand font-12 m-0'"
                    [lableName]="'Mark as Primary Contact'"
                    [dyanamicId]="'manContactID'"
                  >
                  </app-checkbox>
                </div>
              </div>
              <div class="col-xl-12 mb-1 p-0">
                <div class="d-flex checkbox-solid-info show-inactive">
                  <app-checkbox
                    [lableClass]="'text-light-gray hand font-12 m-0'"
                    [status]="insertConatactModel.markAsAccountingContact"
                    (check)="insertConatactModel.markAsAccountingContact = $event"
                    [dyanamicId]="'contactID'"
                    [lableName]="'Mark as Accounting Contact'"
                  >
                  </app-checkbox>
                </div>
              </div>
              <div class="col-xl-12 mb-1 p-0">
                <div class="d-flex checkbox-solid-info show-inactive">
                  <app-checkbox
                    [lableClass]="'text-light-gray hand font-12'"
                    [status]="insertConatactModel.doNotCall"
                    (check)="insertConatactModel.doNotCall = $event"
                    [lableName]="'Do Not Call '"
                  >
                  </app-checkbox>
                </div>
              </div>
              <div class="col-xl-12 mb-1 p-0">
                <div class="d-flex checkbox-solid-info show-inactive">
                  <app-checkbox
                    [lableClass]="'text-light-gray hand font-12'"
                    [status]="insertConatactModel.doNotEmail"
                    (check)="insertConatactModel.doNotEmail = $event"
                    [lableName]="'Do Not Email'"
                    [dyanamicId]="'contactEmailID'"
                  >
                  </app-checkbox>
                </div>
              </div>
              <div class="col-xl-12 mb-3 p-0">
                <div class="d-flex checkbox-solid-info show-inactive">
                  <app-checkbox
                    [lableClass]="'text-light-gray hand font-12'"
                    [status]="insertConatactModel.markAsPrimaryPurchasingContact"
                    (check)="insertConatactModel.markAsPrimaryPurchasingContact = $event"
                    [lableName]="'Mark as Primary Purchasing Contact'"
                    [dyanamicId]="'contactPurchasing'"
                  >
                  </app-checkbox>
                </div>
              </div>
              <div class="col-xl-12 col-md-8 p-0">
                <div class="d-flex flex-column position-relative addNote">
                  <label
                    class="form-label position-absolute addLabel font-12"
                    for=""
                  >
                  Notes
                  </label>
                  <textarea
                    name="note"
                    rows="3"
                    class="form-control mx-3 mt-4 pt-1 font-13 Textarea"
                    [(ngModel)]="insertConatactModel.notes"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer pt-0 m-2 mt-0 pe-2 border-0 justify-content-end">
    <div class="mb-2 me-3">
      <app-button
        [buttonType]="'button'"
        [button_2]="true"
        [buttonClass]="'button button-danger me-4'"
        (click)="activeModal.close()"
        [buttonLable]="enumButtonName.CANCEL"
      ></app-button>
      <app-button
        [buttonType]="'submit'"
        [button_2]="true"
        [buttonClass]="'button button-primary mx-0'"
        (click)="title === 'Edit' ? addContact('Save') :  addContact('Add') "
        [buttonLable]="title === 'Edit' ? enumButtonName.SAVE: enumButtonName.ADD"
      ></app-button>
    </div>
  </div>
</div>

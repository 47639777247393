import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[onlyNumbers]'
})
export class OnlyNumbersDirective {
  @HostBinding('autocomplete') public autocomplete;

  constructor() {
    this.autocomplete = 'off';
  }

  @HostListener('keypress', ['$event']) public disableKeys(e) {
    document.all ? e.keyCode : e.keyCode;
    return (e.keyCode == 8 || (e.keyCode >= 48 && e.keyCode <= 57));
  }
  @HostListener('paste', ['$event']) public onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || (e.originalEvent && e.originalEvent.clipboardData);
    const pastedText = clipboardData.getData('text/plain').replace(/[^0-9]/g, '');
    document.execCommand('insertText', false, pastedText);
  }

  @HostListener('input', ['$event']) public onInput(e) {
    const input = e.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RoutePath } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  routePath;
  routeUrl =  RoutePath;
  constructor(private route : Router) { }
  agInit(params: ICellRendererParams) {

    this.params = params;
    console.log('the params are #####', this.params)
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.routePath = this.route.url
  }

  onSelectChange(event): void {
    const selectedOption = event;
    console.log('Selected value:', selectedOption);
    this.params.data.action = event;
 
  }


}

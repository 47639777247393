import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-common-tab',
  templateUrl: './common-tab.component.html',
  styleUrls: ['./common-tab.component.scss']
})
export class CommonTabComponent implements OnInit {
  @Input() active:number = 1;
  @Input() tabData: { id: number, label: string, component: any }[] = [];
  @Input() tabClass:string = '';
  @Input() ngClass:string = '';
  @Input() listClass:string = '';
  dropdownOpen: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  activeTab(id) {
    this.active = id;
  }
  onTabClick(tabId: number) {
    if (tabId !== 22 && tabId !== 33) {
      this.activeTab(tabId);
    } else {
      ///console.log(`Tab click ignored for tab ID: ${tabId}`);
    }
  }
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  getSelectedTabLabel(): string {
    const activeTab = this.tabData.find(tab => tab.id === this.active);
    return activeTab ? activeTab.label : '';
  }

  selectTab(tabId: number) {
    this.active = tabId;
    this.dropdownOpen = false;
  }

  getActiveComponent() {
    const activeTab = this.tabData.find(tab => tab.id === this.active);
    return activeTab ? activeTab.component : null;
  }
}

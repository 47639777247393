<div class="card bg-semi-light m-0">
    <div class="card-body p-3">
        <div class="mb-2 f-10 text-light-info">Description</div>
        <div class="mb-3 ">
            <!-- <p class="f-11 text-info-color">formatComment () {{data?.description==''?'-':data?.description}}</p> -->
            <ng-container *ngIf="data?.description !== ''; else noDescription">
                <p class="f-11 text-info-color" [innerHTML]="formatComment(data?.description)"></p>
              </ng-container>
              <ng-template #noDescription>
                <p class="f-11 text-info-color">-</p>
              </ng-template>
        </div>
        <div class="d-flex gap-5 fst-italic text-light-info">
            <p class="f-10">Added {{data?.createdAt ==''?'-':data?.createdAt}}</p>
            <p class="f-10">Created by {{data?.createdByName ==''?'-':data?.createdByName}}</p>
        </div>
    </div>
</div>
import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CommonTabService } from './shared/services/common-tab.service';
import { catchError } from 'rxjs';
import { Result } from './shared/model/response.model';
import { DataService } from './shared/services/data.service';
import { SignalRService } from './shared/services/signal-r.service';
import { NavigationStart, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    public dataService:  DataService,
    private loader: LoadingBarService, translate: TranslateService, private commonTabService: CommonTabService,private signalrservice: SignalRService,
    public router: Router,
    private modalService: NgbModal) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    }
    // this.getAllDropdownListData();
    this.dataService.isLoading
  // this.signalrservice.getData('SendChange');
  }
  ngOnInit(): void {
    this.closeOpenModal()
    }
    closeOpenModal(){
      this.router.events.subscribe((event: any): void => {
        if (event instanceof NavigationStart) {
          if (event.navigationTrigger === 'popstate') {
           this.modalService.dismissAll()
          }
        }
      });
    }
//get all common dropdown list :( start)

//get all common dropdown list :( end)
  
}



import { Component, HostListener, OnInit } from '@angular/core';
import { AgCustomHeaderComponent } from 'src/app/shared/components/ag-grid/ag-custom-header/ag-custom-header.component';
import { AgGridI, AgGridPaginationConfig } from 'src/app/shared/components/ag-grid/ag-grid.model';
import { AgGridImportCodecrosswalkPreview, AgGridImportCompaniesPreview, AgGridImportVendorPreview, ColumnName } from '../preview-tab/preview-tab.model'
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { ImportDocumentsService } from '../../import-documents.service';
import { Button, CurrentTab, RoutePath } from 'src/app/shared/model/enum';
import { ColDef } from 'ag-grid-community';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { CodeCrosswalkService } from 'src/app/components/admin/code-crosswalk/code-crosswalk.service';


@Component({
  selector: 'app-preview-tab',
  templateUrl: './preview-tab.component.html',
  styleUrls: ['./preview-tab.component.scss']
})
export class PreviewTabComponent implements OnInit {
  enumButtonName = Button;
  enumColumnName = ColumnName;
  agGridPaginationConfig = new AgGridPaginationConfig();
  dataModel: AgGridI;
  private gridApi;
  private gridColumnApi;
  public components: {
    [p: string]: any;
  } = {
      agColumnHeader: AgCustomHeaderComponent,
    };
  rowData = [];
  flexHeight;
  public defaultColDef: ColDef = {
    resizable: true,
   // maxWidth:350
  };
  overlayNoRowsTemplate = '<div class="ag-overlay-no-rows-center">No Records Available</div>';
  currentTab:string;
  routeUrl:string;
  routePath = RoutePath;
  tabName = CurrentTab;

  constructor(
    private router: Router,
    public commonService: CommonService,
    public importDocumentsService: ImportDocumentsService,
    public tabsService: TabsService,
    public codeCrosswalkService     :CodeCrosswalkService,

  ) { 
    this.routeUrl = this.router.url;

  }
  @HostListener('window:resize', ['$event'])

  ngOnInit(): void {
    this.commonService.headerHeight.subscribe(res => {
      this.flexHeight = "calc(100vh - 290px - " + res + "px" + ")"

    })
    this.tabsService.currentTab.subscribe(res => {
      if (res !== undefined) {
        this.currentTab = res;
      } 
    })
    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      this.dataModel = AgGridImportCodecrosswalkPreview;
    }else{
      if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
        this.dataModel =  AgGridImportVendorPreview
      }else{
      this.dataModel = AgGridImportCompaniesPreview;
    }
    }
    
    this.importDocumentsService.dataPassAccordingToTrackingBar.subscribe((res) => {
      if (res?.response !== undefined) {
        console.log('therd asfasfasd ', res)
        this.rowData = this.currentTab == this.tabName.CODECROSSWALK_TAB?(res?.response?.codeCrosswalkData):(res?.response?.companyData);
        this.rowData.forEach(a => {
          Object.entries(a).forEach((obj:any)=>{
            if (obj[1] === null || obj[1] == '') {
              this.gridColumnApi.setColumnsVisible([obj[0]], false);
            } else {
              this.gridColumnApi.setColumnsVisible([obj[0]], true);
            };
          })
        })
      }
    });
  }

  closeTab(){
    if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
      this.importDocumentsService.closeVendorTab();
      this.router.navigate([this.routePath.VENDORLIST_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.VENDORLIST_TAB)
    }else{
      this.importDocumentsService.closeTab();
      this.router.navigate([this.routePath.COMPANY_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.COMPANY_TAB)
    }
  }
  close(){ 
    this.codeCrosswalkService.valuePopup.next(true)
   }
  
  backToImport() {
    this.importDocumentsService.previuosTab.next(true);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}

import { AgSwitchComponent } from "src/app/shared/components/ag-grid/ag-switch/ag-switch.component";
import { AgColDefsI, AgGridI,} from "../../../../shared/components/ag-grid/ag-grid.model";
import { RatingComponent } from "src/app/shared/components/ag-grid/rating/rating.component";
import { DocumentCellRendererComponent } from "src/app/components/crm/companies/pages/company-info-tabs/document-cell-renderer/document-cell-renderer.component";


const svgColumn: AgColDefsI = {
  headerName: "",
  field: "svgSuccess",
  minWidth: 100,
  width: 100,
  lockPinned: true,
  suppressMovable: true,
  cellRenderer: DocumentCellRendererComponent,
};


const CompanyNameColumn: AgColDefsI = {
  headerName: "Company Name",
  field: "company",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellStyle:{ 'color':'var(--sitewide_primary_accent_color)','padding-left':"22px"},
  //cellClass: "padding-left-38px",
  flex:1

};
const VendorNameColumn: AgColDefsI = {
  headerName: "Vendor Name",
  field: "company",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellStyle:{ 'color':'var(--sitewide_primary_accent_color)','padding-left':"22px"},
  //cellClass: "padding-left-38px",
  flex:1

};

const StageColumn: AgColDefsI = {
  headerName: "Stage",
  field: "stage",
  minWidth: 100,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  flex:1

};

const EmailAddressColumn: AgColDefsI = {
  headerName: "Email Address",
  field: "email",
  minWidth: 300,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  flex:1

};

const TypeColumn: AgColDefsI = {
  headerName: "Type",
  field: "type",
  minWidth: 100,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  flex:1
  
};

const RatingColumn: AgColDefsI = {
  headerName: "Rating",
  field: "companyRating",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px"},
  cellRenderer: RatingComponent,
};


const ActiveColumn: AgColDefsI = {
  headerName: "Active",
  field: "active",
  minWidth: 100,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'padding-top':"6px"},
  cellRenderer: AgSwitchComponent,
};

const CountryColumn: AgColDefsI = {
  headerName: "Country",
  field: "country",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const PhoneColumn: AgColDefsI = {
  headerName: "Phone",
  field: "phone",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const FaxColumn: AgColDefsI = {
  headerName: "Fax",
  field: "fax",
  minWidth: 100,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};

const CurrencyColumn: AgColDefsI = {
  headerName: "Currency",
  field: "currency",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const DateContactedColumn: AgColDefsI = {
  headerName: "Date Contacted",
  field: "dateContacted",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const DoNotCallColumn: AgColDefsI = {
  headerName: "Do Not Call",
  field: "doNotCall",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};

const DAndBNumber: AgColDefsI = {
  headerName: "D&B Number",
  field: "dAndBNumber",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
};

const Tax: AgColDefsI = {
  headerName: "Tax",
  field: "taxId",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
};

const YTDSales: AgColDefsI = {
  headerName: "YTD Sales",
  field: "ytdSales",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
};

const Tags: AgColDefsI = {
  headerName: "Tags",
  field: "tags",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
};


const EmployeesColumn: AgColDefsI = {
  headerName: "Employees",
  field: "employees",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};

const AccountNumberColumn: AgColDefsI = {
  headerName: "Account Number",
  field: "accountNumber",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const NaicsColumn: AgColDefsI = {
  headerName: "NAICS",
  field: "naics",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};


const RecentNotesColumn: AgColDefsI = {
  headerName: "Recent Notes",
  field: "recentNotes",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const RevenueColumn: AgColDefsI = {
  headerName: "Revenue",
  field: "revenue",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};

const SalespersonColumn: AgColDefsI = {
  headerName: "Salesperson",
  field: "salesperson",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};

const SICColumn: AgColDefsI = {
  headerName: "SIC",
  field: "sic",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const IndustryColumn: AgColDefsI = {
  headerName: "Industry",
  field: "industry",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};

const StateColumn: AgColDefsI = {
  headerName: "State",
  field: "state",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const WebsiteColumn: AgColDefsI = {
  headerName: "Website",
  field: "website",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};

const SourcingEmailColumn: AgColDefsI = {
  headerName: "Sourcing Email",
  field: "sourcingEmail",
  minWidth: 250,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const TimezoneColumn: AgColDefsI = {
  headerName: "Timezone",
  field: "timezone",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const AlternateSalespersonColumn: AgColDefsI = {
  headerName: "Alternate Salesperson",
  field: "alternateSalesperson",
  minWidth: 150,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const AccountingContactColumn: AgColDefsI = {
  headerName: "Accounting Contact",
  field: "accountingContact",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const sicCodeColumn: AgColDefsI = {
  headerName: "SIC Code",
  field: "sic",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const sicDescriptionColumn: AgColDefsI = {
  headerName: "SIC Description",
  field: "sicDescription",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const naicsCodeColumn: AgColDefsI = {
  headerName: "NAICS Code",
  field: "naics",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const naicsYearColumn: AgColDefsI = {
  headerName: "NAICS Year",
  field: "naicsYear",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const naicsDescriptionColumn: AgColDefsI = {
  headerName: "NAICS Description",
  field: "naicsDescription",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
const industryColumn: AgColDefsI = {
  headerName: "Industry",
  field: "industry",
  minWidth: 200,
  lockPinned: true,
  sortable: true,
  suppressMovable: true,
  flex:1,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  
};
// const AVLColumn: AgColDefsI = {
//   headerName: "AVL",
//   field: "approvedVendorList",
//   sortable: true,
//   lockPinned: true,
//   minWidth: 100,
//   cellStyle:{'padding-left':"22px",'color':'#646E76'},
//   flex:1,
//   suppressMovable: true,

// };
// const PrimaryContactNameColumn: AgColDefsI = {
//   headerName: "Primary Contact Name",
//   field: "primaryContactName",
//   sortable: true,
//   lockPinned: true,
//   minWidth: 218,
//   cellStyle:{'padding-left':"22px",'color':'#646E76'},
//   flex:1,
//   suppressMovable: true,
// };
// const PrimaryContactEmailColumn: AgColDefsI = {
//   headerName: "Primary Contact Email",
//   field: "primaryContactEmail",
//   sortable: true,
//   lockPinned: true,
//   cellStyle:{'padding-left':"22px",'color':'#646E76'},
//   flex:1,
//   suppressMovable: true,
// };
// const PrimaryContactPhoneColumn: AgColDefsI = {
//   headerName: "Primary Contact Phone",
//   field: "primaryContactPhone",
//   sortable: true,
//   lockPinned: true,
//   minWidth: 218,
//   cellStyle:{'padding-left':"22px",'color':'#646E76'},
//   flex:1,
//   suppressMovable: true,
// };
// const AccountingContactNameColumn: AgColDefsI = {
//   headerName: "Accounting Contact Name",
//   field: "accountingContact",
//   sortable: true,
//   lockPinned: true,
//   minWidth: 240,
//   cellStyle:{'padding-left':"22px",'color':'#646E76'},
//   flex:1,
//   suppressMovable: true,
// };
// const AccountingContactEmailColumn: AgColDefsI = {
//   headerName: "Accounting Contact Email Address",
//   field: "accountContactEmail",
//   sortable: true,
//   lockPinned: true,
//   minWidth: 290,
//   cellStyle:{'padding-left':"22px",'color':'#646E76'},
//   flex:1,
//   suppressMovable: true,
// };

const ParentCompanyColumn: AgColDefsI = {
  headerName: "Parent Company ",
  field: "parentCompany",
  sortable: true,
  lockPinned: true,
  minWidth: 182,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  flex:1,
  suppressMovable: true,
};
const EntityTypeColumn: AgColDefsI = {
  headerName: "Entity Type",
  field: "entityType",
  sortable: true,
  lockPinned: true,
  minWidth: 145,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  flex:1,
  suppressMovable: true,
};
const BuyersRepColumn: AgColDefsI = {
  headerName: "Buyer's Rep",
  field: "buyerRep",
  sortable: true,
  lockPinned: true,
  minWidth: 148,
  cellStyle:{'padding-left':"22px",'color':'#646E76'},
  flex:1,
  suppressMovable: true,
};



export enum ColumnName {
COMPANY = 'company',
STAGE = 'stage',
SIC = 'sic',
EMAIL ='email',
PHONE ='phone',
FAX = 'fax',
INDUSTRY = 'industry',
EMPLOYEE ='employees',
NAICS = 'naics',
DONOTCALL='doNotCall',
DATECONTACTED ='dateContacted',
CURRENCY = 'currency',
ALT_SALESPERSON ='alternateSalesperson',
ACCOUNTING_CONTACT= 'accountingContact',
ACTIVE = 'active',
ACCOUNT_NUMBER ='accountNumber',
COUNTRY ='country',
STATE ='state',
COMPANY_RATING='companyRating',
RECENT_NOTES='recentNotes',
REVENUE = 'revenue',
SALESPERSON ='salesperson',
SOURCING_EMAIL ='sourcingEmail',
TIME_ZONE='timezone',
TYPE ='type',
WEBSITE ='website',
}

export const ColDefsDocumentsCode: AgColDefsI[] = [
  svgColumn,
  sicCodeColumn,
  sicDescriptionColumn,
  naicsCodeColumn,
  naicsYearColumn,
  naicsDescriptionColumn,
  industryColumn,

]
export const ColDefsDocuments: AgColDefsI[] = [
  svgColumn,
  CompanyNameColumn,
  StageColumn,
  EmailAddressColumn,
  TypeColumn,
  RatingColumn,
  ActiveColumn,
  PhoneColumn,
  FaxColumn,
  WebsiteColumn,
  SalespersonColumn,
  NaicsColumn,
  IndustryColumn,
  SICColumn,
  DateContactedColumn,
  CountryColumn,
  CurrencyColumn,
  DoNotCallColumn,
  EmployeesColumn,
  TimezoneColumn,
  AlternateSalespersonColumn,
  AccountNumberColumn,
  SourcingEmailColumn,
  StateColumn,
  RevenueColumn,
  RecentNotesColumn,
  AccountingContactColumn,
  DAndBNumber,
  Tax,
  YTDSales,
  Tags
];
export const ColDefsVendorDocuments: AgColDefsI[] = [
  svgColumn,
  VendorNameColumn,
  RatingColumn,
  PhoneColumn,
  DateContactedColumn,
  TypeColumn,
  RecentNotesColumn,
  FaxColumn,
  WebsiteColumn,
  EmployeesColumn,
  TimezoneColumn,
  SICColumn,
  NaicsColumn,
  CountryColumn,
  AccountNumberColumn,
  CurrencyColumn,
  DAndBNumber,
 // AccountingContactNameColumn,
  StateColumn,
 // AVLColumn,
  EmailAddressColumn,
  // PrimaryContactNameColumn,
  // PrimaryContactEmailColumn,
  // PrimaryContactPhoneColumn,
  // AccountingContactEmailColumn,
  ParentCompanyColumn,
  EntityTypeColumn,
  BuyersRepColumn,
  Tags,
  ActiveColumn,

];

export const AgGridImportVendorPreview: AgGridI = {
  colDefs: ColDefsVendorDocuments,
  rowSelection: "multiple",
};


export const AgGridImportCompaniesPreview: AgGridI = {
  colDefs: ColDefsDocuments,
  rowSelection: "multiple",
};
export const AgGridImportCodecrosswalkPreview: AgGridI = {
  colDefs: ColDefsDocumentsCode,
  rowSelection: "multiple",
};



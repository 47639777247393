<div *ngIf="currentTab !== 'contact-recycle-bin'">
    <!-- currentTab !== 'contacts' && -->
    <div 
    class="d-flex align-items-center OtherCompany" 
    *ngIf="params.value !== enumParams.REQUIREMENT && params.value != enumParams.COMPANY && params.value != enumParams.CONTACT_NAME && params.data.uniqueName != enumParams.VENDORNAME && params.value !=  enumParams.FULL_NAME && params.value != enumParams.VENDORCONTACT_NAME">
        <input 
        [checked]="params?.data?.checked" 
        (click)="hideShowColumns($event, params.value)" 
        class="form-check-input hand"
            [id]="params.value" type="checkbox" />
        <label class="columnName text-wrap text-break mb-0 line">
            <!-- {{ params.value }} -->
            {{ params.value == 'company' ? enumParams.COMPANY :  params.value }}
            <span 
            class="m-l-5 font-style" 
            *ngIf="shouldDisplayDefault()">
                (Default)
            </span>
    
        </label>
    </div>
    <div 
        class="d-flex align-items-center company" 
        *ngIf="params.value == enumParams.REQUIREMENT || params.value == enumParams.COMPANY ||params.value == enumParams.VENDORCONTACT_NAME || params.value == enumParams.CONTACT_NAME || params.data.uniqueName == enumParams.VENDORNAME || params.value ==  enumParams.FULL_NAME || params.value == enumParams.VENDORCONTACT_NAME">
        <label class="columnName text-wrap text-break mb-0 line">
            {{ params.value }}  <span  class="m-l-5 font-style font-10">(Default)</span>
        </label>
    </div>
</div>
<div *ngIf=" currentTab == 'contact-recycle-bin'">
    <!-- currentTab == 'contacts' || -->
    <div class="d-flex align-items-center OtherCompany">
        <input 
        *ngIf="params.value !=  enumParams.VENDORCONTACT_NAME" 
        [checked]="params?.data?.checked" 
        (click)="hideShowColumns($event, params.value)" 
        class="form-check-input hand"
        [id]="params.value" 
        type="checkbox" />
        <label 
            class="columnName text-wrap text-break mb-0 line" >
            {{ params.value == 'company' ? enumParams.COMPANY :  params.value }}
            <span 
            class="m-l-5 font-style" 
            *ngIf="shouldDisplayDefault()">
                (Default)
            </span>
                          
        </label>
    </div>

</div>

import { Component, EventEmitter, Injectable, OnInit, Output, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
export class SelectedDates {
	startDate: NgbDate;
	endDate: NgbDate;
}

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('-');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }
 
  format(date: NgbDateStruct | null): string {
    return date ?
        `${padNumber(date.day)}/${padNumber(date.month)}/${padNumber(date.year)}` :
        '';
  }
}

@Component({
  selector: 'app-date-range-piker',
  templateUrl: './date-range-piker.component.html',
  styleUrls: ['./date-range-piker.component.scss'],
    providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})
export class DateRangePikerComponent implements OnInit {
  @Output() selectedDates: EventEmitter<SelectedDates> = new EventEmitter<SelectedDates>();
  @ViewChild("datepicker") dp: NgbInputDatepicker;
	hoveredDate: NgbDate | null = null;
	inputDate: string = "";
	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	isSpecificComponent;
	constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
		this.onClear();
	}

  ngOnInit(): void {
  }

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
		this.inputDate = this.formatter.format(this.fromDate) + ' - ' + this.formatter.format(this.toDate);

	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}
	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}
	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}
	isStartDate(date: NgbDate) {
		return date.equals(this.fromDate);
	}
	isEndDate(date: NgbDate) {
		return date.equals(this.toDate);
	}
	onClear() {
		this.inputDate = null;
		this.fromDate = null;
		this.toDate = null;
	}
	onApply() {
		this.selectedDates.emit({
			startDate: this.fromDate,
			endDate: this.toDate
		});
		this.dp.close();
	}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { Result } from '../../../shared/model/response.model';
import { DataService } from '../../../shared/services/data.service';
import { DeleteCompany } from '../companies/pages/companies/companies.model';
import { StorageService } from '../../../shared/services/storage.service';
import {URLS }   from 'src/app/components/crm/crm-api-constants';
@Injectable({
  providedIn: 'root'
})
export class ArchivedService {

  dropdownAllList = new BehaviorSubject(undefined);

  constructor(private service:DataService,
    public storageService:StorageService) { }
  

  archivedCompanies(data:DeleteCompany): Observable<Result> {
    data.userId   = this.storageService.retrieve('userId');
   let url = URLS.archivedCompanies.archiveCompanies;
   return this.service.post(url, data).pipe<Result>(tap((response: any) => {
   return response;
     })
   );
 }
 unArchivedCompanies(data:DeleteCompany): Observable<Result> {
  data.userId   = this.storageService.retrieve('userId');
 let url =URLS.archivedCompanies.unarchiveCompanies;
 return this.service.post(url, data).pipe<Result>(tap((response: any) => {
 return response;
   })
 );
}
}

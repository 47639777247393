import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { Result } from 'src/app/shared/model/response.model';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { COMPANY_REQUIRED_VALUES, CROSSWALK_REQUIRED_VALUES, ImportCodeCrosswalkData, ImportCompanyCommand ,VENDOR_REQUIRED_VALUES} from './import-documents.model';
import { URLS } from 'src/app/components/crm/crm-api-constants';
@Injectable({
  providedIn: 'root'
})
export class ImportDocumentsService {
  count: number=0;
  tabs = [];
  submit:boolean=false
  indexActive=0;
  submitted:boolean=false;

public dataPassAccordingToTrackingBar = new BehaviorSubject(undefined);
public progressbarValue = new BehaviorSubject(undefined);
public previuosTab = new BehaviorSubject(undefined);
public mapValidation = new BehaviorSubject(undefined);
public applyChanges = new BehaviorSubject(undefined);
public toEnd = new BehaviorSubject(undefined);
public errorValidation = new BehaviorSubject(undefined);
public fileName = new BehaviorSubject(undefined);



  constructor(public dataService:DataService,
    private tabsService: TabsService,
    private router: Router,
    public storageService: StorageService) { }



  public getImportCompanyDropDownList ():Observable<Result>{
    let url=URLS.dropDown.importCompanyDropDownList;
    return this.dataService.get(url, '').pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }
  public deleteTemplateByTemplateId (id):Observable<Result>{
    let url=`${URLS.importCompany.deleteTemplateByTemplateId}?TemplateId=${id}`;
    return this.dataService.get(url, '').pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }


  public getImportCompanyTemplateById (id:any,sourceId : number):Observable<Result>{
    let url = `${URLS.importCompany.getTemplateByTemplateId}?TemplateId=${id}&sourceId=${sourceId}`;
    return this.dataService.get(url, '').pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }

  public validateFile(data:any):Observable<Result>{
    let url= URLS.importCompany.validateFileWithTemplate;
    return this.dataService.post(url,data).pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }


  public validateFileHasData(data:any):Observable<Result>{
    let url= URLS.importCompany.validateFileHasData;
    return this.dataService.post(url,data).pipe<Result>(tap((response:any)=>{
    return response;
    }))
  }




  public mapToList (data:any):Observable<Result>{
    let url=URLS.importCompany.getHeaderNameListFromFile;
    const myjson=JSON.stringify(data)
    return this.dataService.post(url, data).pipe<Result>(tap((response:any)=>{
      return response;
    }))
  }

  public InsertImportCompanyData (data:any):Observable<Result>{
    const myjson=JSON.stringify(data)
    let url=URLS.importCompany.importCompanyTemplate;
    return this.dataService.post(url, data).pipe<Result>(tap((response:any)=>{
      return response;
    }))
  }

  public InsertImportCodeCrosswalkTemplate (data:any):Observable<Result>{
    const myjson=JSON.stringify(data)
    let url= URLS.masterCodeCrosswalk.importCodeCrosswalkTemplate;
    return this.dataService.post(url, data).pipe<Result>(tap((response:any)=>{
      return response;
    }))
  } 


  public insertImportOnlyCompany (data:ImportCompanyCommand):Observable<Result>{
    const myjson=JSON.stringify(data)
    let url= URLS.importCompany.importCompany;
    return this.dataService.post(url, data).pipe<Result>(tap((response:any)=>{
      return response;
    }))
  }

  public insertImportCodeCrosswalk (data:ImportCodeCrosswalkData):Observable<Result>{
    const myjson=JSON.stringify(data)
    let url= URLS.masterCodeCrosswalk.importCodeCrosswalk;
    return this.dataService.post(url, data).pipe<Result>(tap((response:any)=>{
      return response;
    }))
  }


 public closeTab(){
    this.indexActive=0
    this.tabs = [];
    const index=this.tabsService?.tabs?.findIndex(a=>a.title==='Import Companies')
    this.tabsService.tabs.splice(index,1);
    this.tabs =this.tabsService?.tabs
    this.tabsService.allOpenedTabs.splice(index,1);
    this.storageService.store("allTabs",this.tabsService.tabs);
   // this.router.navigateByUrl(this.tabsService.tabs[index-1].path);
    this.tabsService.deleteTab(index);
    this.dataPassAccordingToTrackingBar = new BehaviorSubject(undefined);
  }

  public closeVendorTab(){
    this.indexActive=0
    this.tabs = [];
    const index=this.tabsService?.tabs?.findIndex(a=>a.title==='Import Vendors')
    this.tabsService.tabs.splice(index,1);
    this.tabs =this.tabsService?.tabs
    this.tabsService.allOpenedTabs.splice(index,1);
    this.storageService.store("allTabs",this.tabsService.tabs);
   // this.router.navigateByUrl(this.tabsService.tabs[index-1].path);
    this.tabsService.deleteTab(index);
    this.dataPassAccordingToTrackingBar = new BehaviorSubject(undefined);
  }


  checkRequiredValueCodecrosswalk(list): boolean {
    let count:number=0;
    const intialList = list.filter(a => a.status === true);
    for (let i = 0; i < intialList.length; i++) {
      let systemColumn = intialList[i].systemColumn
      if (CROSSWALK_REQUIRED_VALUES.includes(systemColumn)) {
        count ++;
        if (count === 6) {
          return true
        }

      }
    }
    return false
  }

  checkRequiredValue(list): boolean {
    let count:number=0;
    const intialList = list.filter(a => a.status === true);
    for (let i = 0; i < intialList.length; i++) {
      let systemColumn = intialList[i].systemColumn
      if (COMPANY_REQUIRED_VALUES.includes(systemColumn)) {
        count ++;
        if (count === 4) {
          return true
        }

      }
    }
    return false
  }

  checkVendorRequiredValue(list): boolean {
    let count:number=0;
    const intialList = list.filter(a => a.status === true);
    for (let i = 0; i < intialList.length; i++) {
      let systemColumn = intialList[i].systemColumn
      if (VENDOR_REQUIRED_VALUES.includes(systemColumn)) {
        count ++;
        if (count === 3) {
          return true
        }

      }
    }
    return false
  }


  checkRequiredValueError(list): boolean {
    let count:number=0;
    const listData = list?.map(x=>x. companyName);
    const intialList = list.filter(a => a.status === true);
    for (let i = 0; i < intialList.length; i++) {
      let systemColumn = intialList[i].systemColumn
      if (listData.includes(systemColumn)) {
        count ++;
        if (count === list.length) {
          return true
        }

      }
    }
    return false
  }

}

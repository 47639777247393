export class InsertUpdateCodeCrosswalk {
    id              : string;
    sic             : string;
    sicDescription  : string;
    naics           : string;
    naicsDescription: string;
    naicsYear       : string;
    industry        : string;
    active          : boolean = true;
    isDeleted       : boolean;
    externalId      : string;
    currentUrl      : string;
  }
  export class UpdateStatusCodeCrosswalk {
    id              : string;
    active          : boolean;
  }

  export class Id {
    id: number[];
  
    constructor(id: number[]) {
      this.id = id;
    }
  }
    
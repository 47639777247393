<div>
  <div class="modal-header p-4 m-2 mb-0 pb-0 border-0 justify-content-betweeen">
    <h6 class="modal-title text-center text-primary-color font-15">
      {{isEditable == true ? this.enumPopUpTitles.ADD : this.enumPopUpTitles.EDIT}} {{enumPopUpTitles.COMMENT}}
    </h6>
    <div class="hand d-flex align-items-center">
      <div *ngIf="!isEditable" class="ps-3 p-r-10">
        <app-svg-icon
          (click)="onDeleteComment()"
          [src]="'./assets/SVGIcons/deleteicon.svg'"
          class="h-33px"
        >
        </app-svg-icon>
      </div>
    </div>
  </div>
  <div class="modal-body p-4 mx-2">
    <div class="text-center box mb-3">
      <div class="form-floating">
         <!-- <textarea [innerHTML]="getFormattedComment()"
          #commentEditor
          contenteditable="true"
          class="form-control resize mention-item"
          placeholder="Leave a comment here"
          id="floatingTextarea"
          autocomplete="off"
          [(ngModel)]="insertComment"
          [ngClass]="{
            'border-red': !insertComment && submited
          }"
          [mentionConfig]="{ items: items, labelKey: 'userName'}"
          [mentionListTemplate]="mentionListTemplate"
          
        ></textarea>  -->

        <div class="CommentBox rounded colorText"
        [mentionConfig]="{ items: items, labelKey: 'userName'}"
        [mentionListTemplate]="mentionListTemplate"
        (itemSelected)="itemSelected($event)"
        contenteditable="true"
        id="my-content"
        [innerHTML]="insertComment"
        (input)="updateComment($event)"
      >
      
    </div>
        
        <label for="floatingTextarea" 
          >Comment<i class="fst-normal ps-1 p-0 text-danger">*</i></label
        >
        <span
          *ngIf="insertComment"
          (click)="newComments()"
          class="position-absolute top-0 start-0 hand"
          ><app-svg-icon
            class="svgIcon"
            [src]="'../../../../../../../assets/SVGIcons/undoSVG.svg'"
          ></app-svg-icon
        ></span>
        <span
        *ngIf="newComment"
        (click)="newComments()"
        class="position-absolute top-0 start-0 hand"
        ><app-svg-icon
          class="svgIcon"
          [src]="'../../../../../../../assets/SVGIcons/undoSVG.svg'"
        ></app-svg-icon
      ></span>

        <!-- @mention section  -->
        <ng-template #mentionListTemplate let-item="item" class="mentionList">
          <div class="d-flex rounded mx-0 px-0 d-flex align-items-center"> 
            <div class="mx-0 px-0 ">
              <img class="mr-3 my-0 rounded-circle" [src]="item.profileUrl" alt="" width="30"
                height="30"
                onerror="this.src='../../../../../../assets/SVGIcons/default.png'"
                >
            </div>
            <div class="mx-0 px-0 text-truncate">
              <span class="f-12 my-0 py-0 mentioned-name">{{item.userName}}</span><br>
              <span class="font-10 my-0 py-0">{{item.email}}</span>
            </div>
          </div>
        </ng-template>
        <!-- @mention section  -->
      </div>
    </div>
    <!-- <div class="text-center mb-3">
      <div class="form-floating">
        <textarea
          class="form-control resize"
          placeholder="Leave a comment here"
          [(ngModel)]="insertUpdateTaskCommentModel.comment"
          id="floatingTextarea"
          style="height: '150px'"
          autocomplete="off"
        ></textarea>
        <label for="floatingTextarea"
          >Comment<i class="fst-normal ps-1 p-0 text-danger">*</i></label
        >
      </div>
    </div> -->
  </div>
  <div class="modal-footer pt-0 m-2 mt-0 border-0 justify-content-end">
    <div class="mb-2 me-2 pe-1">
      <app-button
        [buttonType]="'button'"
        [button_2]="true"
        [buttonClass]="'button button-info me-4'"
        (click)="activeModal.close()"
        [buttonLable]="enumButtonName.CANCEL"
      ></app-button>
      <app-button class="commentBtn"
        [buttonType]="'submit'"
        [button_2]="true"
        [buttonClass]="insertComment? 'button button-primary mx-0': 'button dark-info mx-0'"
        (click)="isEditable == true ? addComment():editComment()"
        [buttonLable]="isEditable == true ? enumButtonName.ADD : enumButtonName.SAVE"
      ></app-button>
      <!-- [buttonClass]=" isEditable == true? 'button dark-info mx-0': 'button button-primary mx-0'" -->

    </div>
  </div>
</div>

 <div ngbDropdown class="select-wrapper d-flex" (click)="active = !active">
  <div
    class="btn btn-intent d-flex align-items-center"
    #mySelect
    [ngClass]="{ 'select-focused': !active }"
    ngbDropdownToggle
    id="dropdownBasic6"
  >
  <!-- || params?.column?.colId == 'leadScoreId' -->
    <i
      *ngIf="params?.column?.colId == 'intentToBuyId'"
      class="fa fa-circle pe-2"
      [style.color]="currentSelection?.colorCode"
      style="font-size: x-small"
    ></i
    >{{ currentSelection?.name }}
  </div>
  <div class="intent" ngbDropdownMenu aria-labelledby="dropdownBasic6">
    <div
      class="d-flex align-items-center hand"
      style="height: 26px; max-width: 105px"
      *ngFor="let option of dropDownList"
      (click)="setCurrentSelection(option)"
    >
      <i
        *ngIf="params?.column?.colId == 'intentToBuyId'"
        class="fa fa-circle pe-2"
        [style.color]="option?.colorCode"
        style="font-size: x-small"
      ></i
      ><span class="f-12">{{ option?.name }}</span>
    </div>
  </div>
  <app-svg-icon
    *ngIf="active"
    id="dropdownBasic6"
    ngbDropdownToggle
    class="active"
    [src]="'./assets/SVGIcons/upIcon-arrow.svg'"
  ></app-svg-icon>
  <app-svg-icon
    *ngIf="!active"
    id="dropdownBasic6"
    ngbDropdownToggle
    class="active"
    [src]="'./assets/SVGIcons/downIcon-arrow.svg'"
  ></app-svg-icon>
</div>

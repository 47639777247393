import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ColorVariables,FavoritesDock,GetSystemSettingData, InertUpdateSystemSetting, PrimaryMenu, SecondaryMenu, SiteWide, SystemHeading} from './system-settings.model'
import {SystemSettingsService} from './system-settings.service'
import { Button, SiteWideColorName } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss'],

  // directives: [ImageUploderComponent]
})
export class SystemSettingsComponent implements OnInit 
{
  enumSiteWideColorName       = SiteWideColorName;
  colorVariables              = new ColorVariables();
  getSystemSettingData        = new GetSystemSettingData();
  systemSettinPrimaryMenu     = new PrimaryMenu();
  systemSettingSecondaryMenu  = new SecondaryMenu();
  systemSettingFavoritesDock  = new FavoritesDock();
  systemSettingSiteWide       = new SiteWide();
  inertUpdateSystemSetting    = new InertUpdateSystemSetting();
  imageUrl
  
  SystemHeadings = SystemHeading;

  enumButtonName = Button;
  // Start Constructor---------
  constructor(
    private systemSettingsService:SystemSettingsService,
    public storageservice: StorageService,
    public activeModal: NgbActiveModal)
  {
    
  }

  ngOnInit(): void 
            {
              this.getAllSystemSettingData();

            }

  // ngOnInit End --------

  getAllSystemSettingData()
            { 
              const userId   = this.storageservice.retrieve('userId');
              this.systemSettingsService.GetAllSystemSettingData({userId:userId}).subscribe(res =>{
                let primaryData                                  = JSON.parse(res?.response?.primaryMenus);
                let secondaryData                                = JSON.parse(res?.response?.secondaryMenus);
                let favoriteDockData                             = JSON.parse(res?.response?.favoriteDocks);
                let siteWideData                                 = JSON.parse(res?.response?.siteWides);
                this.colorVariables.base64textString             =   primaryData?.siteLogo;
                this.colorVariables.favoriteDock_color           = favoriteDockData?.backgroundColor;
                this.colorVariables.favoriteDockicon_color       = favoriteDockData?.iconColor;
                this.colorVariables.secondary_bg_color            = secondaryData?.backgroundColor;
                this.colorVariables.secondary_text_icon_color     = secondaryData?.textAndIconColor;
                this.colorVariables.secondary_hover_active_color  = secondaryData?.hoverAndActiveTextAndIconColor;
                this.colorVariables.accent_line_color            = secondaryData?.accentLineColor;
                this.colorVariables.primary_bg_color             = primaryData?.backgroundColor;
                this.colorVariables.primary_text_icon_color      = primaryData?.textAndIconColor;
                this.colorVariables.primary_hover_active_color   = primaryData?.hoverAndActiveTextAndIconColor;
                this.colorVariables.sitewide_accent_color        = siteWideData?.primaryAccentColor;
                this.colorVariables.sitewide_secondary_color      = siteWideData?.secondaryAccentColor;
                this.colorVariables.sitewide_active_hover_color  = siteWideData?.hoverAndActiveColor;
           
                
              })
            }
            // Get All System Settin Data End --------
  public onEventLog(event: string, data: any, section): void 
          {
            switch(section){
              case this.enumSiteWideColorName.PRIMARY_BG_COLOR :  this.colorVariables.primary_bg_color = data.color || data;
              break;
              case this.enumSiteWideColorName.PRIMARY_TEXT_COLOR : this.colorVariables.primary_text_icon_color = data.color || data;
              break;
              case this.enumSiteWideColorName.PRIMARY_HOVER : this.colorVariables.primary_hover_active_color = data.color || data;
              break;
              case this.enumSiteWideColorName.SECONDARY_BG_COLOR: this.colorVariables.secondary_bg_color = data.color || data;
              break;
              case this.enumSiteWideColorName.SECONDARY_TEXT_COLOR : this.colorVariables.secondary_text_icon_color = data.color || data;
              break;
              case this.enumSiteWideColorName.SECONDARY_HOVER_COLOR : this.colorVariables.secondary_hover_active_color = data.color || data;
              break;
              case this.enumSiteWideColorName.ACCENT_LINE_COLOR : this.colorVariables.accent_line_color = data.color || data;
              break;
              case this.enumSiteWideColorName.DOCK_COLOR : this.colorVariables.favoriteDock_color = data.color || data;
              break;
              case this.enumSiteWideColorName.ICON_COLOR : this.colorVariables.favoriteDockicon_color = data.color || data;
              break;
              case this.enumSiteWideColorName.SITEWIDE_ACCENT_COLOR : this.colorVariables.sitewide_accent_color = data.color || data;
              break;
              case this.enumSiteWideColorName.SITEWIDE_SECONDARY_COLOR : this.colorVariables.sitewide_secondary_color = data.color || data;
              break;
              case this.enumSiteWideColorName.SITEWIDE_ACTIVE_HOVER_COLOR : this.colorVariables.sitewide_active_hover_color = data.color || data;
              break;
            }
          }
          
handleFile(event) 
          {
            var binaryString = event.target.result;
            this.colorVariables.base64textString = btoa(binaryString);
   
          }
selectFile(event) 
          {
            var files = event.target.files;
            var file = files[0];
            if (files && file) {
              var reader = new FileReader();
              reader.onload = this.handleFile.bind(this);
              reader.readAsBinaryString(file);
            }
          }
          onDragOver(event) {
            event.preventDefault();
          }
        
          onDrop(event) {
            event.preventDefault();
            const file = event.dataTransfer?.files[0];
            if (file) {
              var reader = new FileReader();
              reader.onload = this.handleFile.bind(this);
              reader.readAsBinaryString(file);
            }
          }
        
insertUpdateSystemSetingData()
          {
            document.documentElement.style.setProperty('--primary-hover-active-color', this.colorVariables.primary_hover_active_color);
            document.documentElement.style.setProperty('--primary-bg-color',           this.colorVariables.primary_bg_color);
            document.documentElement.style.setProperty('--primary-text-color',         this.colorVariables.primary_text_icon_color);
            document.documentElement.style.setProperty('--primary-icon-color',         this.colorVariables.primary_text_icon_color);
            document.documentElement.style.setProperty('--secondary-text-icon-color',  this.colorVariables.secondary_text_icon_color);
            document.documentElement.style.setProperty('--secondary-bg-color',         this.colorVariables.secondary_bg_color);
            document.documentElement.style.setProperty('--secondary-accent-line-color',this.colorVariables.accent_line_color);
            document.documentElement.style.setProperty('--secondary-hover-active',     this.colorVariables.secondary_hover_active_color);
            document.documentElement.style.setProperty('--dock-bg-color',              this.colorVariables.favoriteDock_color);
            document.documentElement.style.setProperty('--dock-icon-color',            this.colorVariables.favoriteDockicon_color);
            document.documentElement.style.setProperty('--sitewide-active-hover-color',this.colorVariables.sitewide_active_hover_color);
            document.documentElement.style.setProperty('--sitewide_primary_accent_color', this.colorVariables.sitewide_accent_color);
            document.documentElement.style.setProperty('--sitewide_secondary_color',    this.colorVariables.sitewide_secondary_color);
            
            this.systemSettinPrimaryMenu.backgroundColor                = this.colorVariables.primary_bg_color;
            this.systemSettinPrimaryMenu.hoverAndActiveTextAndIconColor = this.colorVariables.primary_hover_active_color;
            this.systemSettinPrimaryMenu.siteLogo                       = this.colorVariables.base64textString;
            this.systemSettinPrimaryMenu.textAndIconColor               = this.colorVariables.primary_text_icon_color;
            let systemSettinPrimaryMenu                                 = JSON.stringify( this.systemSettinPrimaryMenu);
            this.systemSettingsService.sendImageData("data:image/png;base64," +  this.systemSettinPrimaryMenu.siteLogo );
            this.systemSettingSecondaryMenu.accentLineColor                = this.colorVariables.accent_line_color;
            this.systemSettingSecondaryMenu.backgroundColor                = this.colorVariables.secondary_bg_color;
            this.systemSettingSecondaryMenu.hoverAndActiveTextAndIconColor = this.colorVariables.secondary_hover_active_color;
            this.systemSettingSecondaryMenu.textAndIconColor               = this.colorVariables.secondary_text_icon_color;
            let systemSettingSecondaryMenu                                 = JSON.stringify(this.systemSettingSecondaryMenu);
            this.systemSettingFavoritesDock.backgroundColor                = this.colorVariables.favoriteDock_color;
            this.systemSettingFavoritesDock.iconColor                      = this.colorVariables.favoriteDockicon_color;
            let favoriteDock                                               = JSON.stringify(this.systemSettingFavoritesDock);
            this.systemSettingSiteWide.hoverAndActiveColor                 = this.colorVariables.sitewide_active_hover_color;
            this.systemSettingSiteWide.primaryAccentColor                  = this.colorVariables.sitewide_accent_color;
            this.systemSettingSiteWide.secondaryAccentColor                = this.colorVariables.sitewide_secondary_color;
            let systemSettingSiteWide                                      = JSON.stringify( this.systemSettingSiteWide);
            // this.inertUpdateSystemSetting.id                              = 0;
            this.inertUpdateSystemSetting.userId                          = this.storageservice.retrieve('userId');
            this.inertUpdateSystemSetting.primaryMenus                    = systemSettinPrimaryMenu;
            this.inertUpdateSystemSetting.secondaryMenus                  = systemSettingSecondaryMenu;
            this.inertUpdateSystemSetting.favoriteDocks                   = favoriteDock;
            this.inertUpdateSystemSetting.siteWides                       = systemSettingSiteWide;
            this.systemSettingsService.UpdateSystemSettingData(this.inertUpdateSystemSetting).subscribe(res => {
              if(res.isSuccess){
              }
            })
          }



}

import { AgGridColumnsSettingService } from './../ag-grid-columns-setting/ag-grid-columns-setting.service';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ColDef, ColumnApi, ColumnResizedEvent, ComponentStateChangedEvent, DragStartedEvent, DragStoppedEvent, GridApi, GridSizeChangedEvent, RowClassRules, RowHeightParams, SelectionChangedEvent } from 'ag-grid-community';
import { AgGridI, AgGridPaginationConfig, PaginationBaseModel, SaveUserSetting, TablePaginationModel, TablePaginationModelContact, TablePaginationModelVendorList } from './ag-grid.model';
import { AgGridService } from './ag-grid.service';
import { AgCustomHeaderComponent } from './ag-custom-header/ag-custom-header.component';
import { BehaviorSubject, Observable, Subscription, catchError } from 'rxjs';
import { Result } from '../../model/response.model';
import { AgGridDataModelCompany, UserSetting } from 'src/app/components/crm/crm.model';
import { StorageService } from '../../services/storage.service';
import { TabsService } from '../tabs/tabs.service';
import { Router } from '@angular/router';
import { CustomeTooltipComponent } from './custome-tooltip/custome-tooltip.component';
import { CodeCrosswalkService } from 'src/app/components/admin/code-crosswalk/code-crosswalk.service';
import { RequirementsService } from 'src/app/components/sales/requirements/pages/requirements/requirements.service';
import { SignalRService } from '../../services/signal-r.service';
import { CurrentTab, EnfoRoutePath, RoutePath, TabNames } from '../../model/enum';
import {GridName} from '../../../shared/model/ag-grid-common-constant'
declare var window: any;

@Component({
  selector: 'app-ag-grid',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.scss']
})
export class AgGridComponent implements OnInit {
  @Input() getRowStyle :any;
  @Input() dataModel: AgGridI;
  isLoading: boolean = false;
  subscription: Subscription;
  // dataModel:AgGridI;
  userSetting = new UserSetting();
  saveUserSetting = new SaveUserSetting();
  paginationBaseModel = new PaginationBaseModel();
  tablePaginationModel = new TablePaginationModel();
  agGridPaginationConfig = new AgGridPaginationConfig();
  tablePaginationModelContact = new TablePaginationModelContact();
  tablePaginationModelVendorList = new  TablePaginationModelVendorList();
  @Output("pageSizeChanged") pageSizeChanged: EventEmitter<any> = new EventEmitter();
  @Output("pageChanged") pageChanged: EventEmitter<any> = new EventEmitter();
  @Output() requirementList: EventEmitter<any> = new EventEmitter();
  @Output() onRowClick: EventEmitter<any> = new EventEmitter();
  enumTabNames            = TabNames;

  
  deviceWidth: any;
  customizer = '';
  gridName = GridName
  public components: {
    [p: string]: any;
  } = {
      // agColumnHeader: AgCustomHeaderComponent,
    };

  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 0,
    resizable:true,
    //maxWidth:250,
    // tooltipComponent:CustomeTooltipComponent
  }
  previousTabData: any[] = [];
  currentTabData: any[] = [];
  currentTab = '';
  currentUrl: string;
  searchTextCodeCrossWalk: string;
  overlayNoRowsTemplate = '<div class="ag-overlay-no-rows-center">No Records Available</div>';
  gridApi:GridApi;
 routePath = RoutePath;
 tabName = CurrentTab;
 companyD = EnfoRoutePath;
 screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
 idsToDelete: any[] = [];
 returnMessage:any;
  constructor(public agGridService: AgGridService,
    public storageService: StorageService,
    public agGridColumnsSettingService: AgGridColumnsSettingService, public tabService: TabsService,
    public codeCrosswalkService: CodeCrosswalkService,
    public  requirementsService: RequirementsService,
    public route: Router,
    public signalRService: SignalRService,
    private cdr: ChangeDetectorRef
    ) {
      this.getScreenWidth().subscribe((res) => {
        this.deviceWidth  = res;
      });
  
     }
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.deviceWidth = event.target.innerWidth;
  }
  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }


  onGridSizeChanged(params: GridSizeChangedEvent) {
    //const percentageWidth = 15.4;
    const percentageWidth = this.deviceWidth > 1800 ? 15.6 : 15.4;
    const containerWidth = this.deviceWidth;
    const newMinWidthPixels = this.deviceWidth <= 1024
    ? 300
    : Math.round((percentageWidth / 100) * containerWidth);
    const columnApi: ColumnApi = params.columnApi;
    const column = columnApi.getColumn('companyName') || columnApi.getColumn('vendorName');
    if (column) {
      columnApi?.setColumnWidth(column.getColId(), newMinWidthPixels);
      console.log(`Setting width for column ${column.getColId()} to ${newMinWidthPixels}px`);
      console.log(`Actual width of column ${column.getColId()}: ${column.getActualWidth()}px`);
    }
    }
  ngOnInit(): void {
    this.signalRData()
    this.agGridService.dataInput.next(this.dataModel.storageName)
    this.currentUrl = this.route.url;
    this.tabService.currentTab.subscribe(res => {
      if (res) {
        this.currentTab = res
        console.log('the current tab in ag-rid##### ', res)
        console.log('the current url is ',this.currentUrl )
      }
    })
    // this.dataModel = AgGridDataModelCompany;
    // this.initDatatable();
    // this.getAllTableData();
    this.codeCrosswalkService.valueSearchtext.subscribe((res) => {
      if (res !== undefined) {
        this.searchTextCodeCrossWalk = res;
        this.paginationBaseModel.pageNumber = 1;
        if (this.currentUrl == this.routePath.CODECROSSWALK_ROUTEPATH || this.currentTab == this.tabName.CODECROSSWALK_TAB) {
          this.getCodeCrossWalkTableData();
        }

      }
    });

    this.codeCrosswalkService.valuePass.subscribe((res) => {
      if (res == true) {
        if (this.currentUrl == this.routePath.CODECROSSWALK_ROUTEPATH || this.currentTab == this.tabName.CODECROSSWALK_TAB) {
          this.getCodeCrossWalkTableData();
        }
      }
    });
    this.requirementsService.recordPass.subscribe((res) => {
      if (res == true) {
        if (this.currentUrl == this.routePath.REQUIREMENT_ROUTEPATH || this.currentTab == this.tabName.REQUIREMENTS_TAB) {
          this.getSalesRequirementsTableData();
        }
      }
    });


    this.deviceWidth = window.innerWidth;
    this.agGridService.agGridPaginationConfigRes.subscribe((result) => {
      if (result) {
        this.agGridPaginationConfig = result;
      }
    });

    // customizer layout in sidebar settin
    this.agGridColumnsSettingService.isCustomizerLayoutOpen.subscribe(
      (res) => {
        this.customizer = res;
      }
    );
    // customizer layout in sidebar settin

    this.subscription = this.agGridService.tablePaginationData.subscribe(res => {
      this.tablePaginationModel = res;
      if (this.currentTab == this.tabName.CONTACT_TAB || this.currentUrl == this.routePath.CONTACT_ROUTEPATH) {
        this.getContactTableData();
      }else if(this.currentUrl == this.routePath.PROCUREMENT_VENDOR_CONTACT_ROUTEPATH || this.currentTab == this.tabName.VENDORCONTACT_TAB){
        this.allContactVendorData();
      }
      else if (this.currentUrl == this.routePath.CONTACT_RECYLCLE_BIN_ROUTEPATH || this.currentTab == this.tabName.CONTACT_RECYCLE_BIN_TAB) {
        this.getContactRecycleBinData();
      }
      else if (this.currentUrl == this.routePath.COMPANY_RECYCLE_BIN_ROUTEPATH || this.currentTab == this.tabName.COMPANY_RECYCLE_BIN_TAB) {
        this.getCompanyRecycleBinData();
      }
      else if (this.currentUrl == this.routePath.CODECROSSWALK_ROUTEPATH || this.currentTab == this.tabName.CODECROSSWALK_TAB) {
        this.getCodeCrossWalkTableData();
      }
      else if (this.currentUrl == this.routePath.PROCUREMENT_VENDOR_LIST_ROUTEPATH || this.currentTab == this.tabName.VENDORLIST_TAB) {
        this.getVendorListTableData();
      }
      else if (this.currentUrl == this.routePath.REQUIREMENT_ROUTEPATH || this.currentTab == this.tabName.REQUIREMENTS_TAB) {
        this.getSalesRequirementsTableData();
      }
      else if (this.currentUrl == this.routePath.ARCHIVED_COMPANIES_ROUTEPATH || this.currentTab == this.tabName.ARCHIVED_COMPANIES_TAB) {
        this.getAllArchivedCompanies();
      }else if (this.currentUrl == this.routePath.PARTLIST_ROUTEPATH || this.currentTab == this.enumTabNames.PARTLIST) {
        this.getPartsListTableData();
      }
      else {
        this.getAllTableData();
      }
    })
  }
  getAllArchivedCompanies(){
    this.agGridPaginationConfig.rows = [];
    this.isLoading = true;

    this.agGridService.GetAllArchivedCompanies(this.tablePaginationModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: Result) => {
        if (data.isSuccess === true) {
          console.log('the data is of grid is#######', data)
          this.agGridService.agGridPaginationConfigRes.next(
            this.agGridService.updateTableData(data)
          );
          this.isLoading = false;
          this.agGridService.getGridApi().showNoRowsOverlay();
          this.agGridService.selectionChanged.next(this.agGridService.getGridApi().getSelectedRows())
        }
      });

  }
  getPartsListTableData(){
    this.agGridPaginationConfig.rows = [
      {id:'1',isExpandable:false ,mpn:'V23135W1001A309',mfr:'ACE Industries',onHand:'15000',available:'5000',allocated:'5000',consignment:'5000',pending:'0',quarantine:'0',offers:'Yes',oemExcess:'0'},
      {id:'2',isExpandable:false ,mpn:'V23135W-XY',mfr:'ACE Industries',onHand:'15000',available:'10000',allocated:'5000',consignment:'5000',pending:'0',quarantine:'0',offers:'Yes',oemExcess:'0'},
      {id:'3',isExpandable:false ,mpn:'V23135W1001A309',mfr:'ACE Industries',onHand:'15000',available:'5000',allocated:'5000',consignment:'5000',pending:'0',quarantine:'0',offers:'Yes',oemExcess:'0'},
      {id:'4',isExpandable:false ,mpn:'V23135W-XY',mfr:'ACE Industries',onHand:'15000',available:'10000',allocated:'5000',consignment:'5000',pending:'0',quarantine:'0',offers:'Yes',oemExcess:'0'},
      {id:'5',isExpandable:false ,mpn:'V23135W1001A309',mfr:'ACE Industries',onHand:'15000',available:'5000',allocated:'5000',consignment:'5000',pending:'0',quarantine:'0',offers:'Yes',oemExcess:'0'},
      {id:'6',isExpandable:false ,mpn:'V23135W-XY',mfr:'ACE Industries',onHand:'15000',available:'10000',allocated:'5000',consignment:'5000',pending:'0',quarantine:'0',offers:'Yes',oemExcess:'0'},
      {id:'7',isExpandable:false ,mpn:'V23135W1001A309',mfr:'ACE Industries',onHand:'15000',available:'5000',allocated:'5000',consignment:'5000',pending:'0',quarantine:'0',offers:'Yes',oemExcess:'0'},
    ];
    this.requirementList.emit( this.agGridPaginationConfig.rows);
    this.agGridPaginationConfig.totalRecords = 6
  }
  getSalesRequirementsTableData(){
  this.agGridPaginationConfig.rows = [];
  this.isLoading = true;

  this.agGridService.GetAllRequirementData(this.tablePaginationModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe((data: Result) => {
      if (data.isSuccess === true) {
        console.log('the data is of grid is#######', data);
        this.requirementList.emit(data?.response);
        this.agGridService.agGridPaginationConfigRes.next(
          this.agGridService.updateTableData(data)
        );
        this.isLoading = false;
        this.agGridService.getGridApi().showNoRowsOverlay();
        this.agGridService.selectionChanged.next(this.agGridService.getGridApi().getSelectedRows())
      }
    });

  }
  //#region  common Table get Data
  crosswalkDatatable() {
    this.paginationBaseModel = {
      getAll: this.agGridPaginationConfig.pageSize == 1 ? true : false,
      pageNumber: this.agGridPaginationConfig.currentPageNumber,
      pageSize: this.agGridPaginationConfig.pageSize,
      searchText: this.agGridPaginationConfig.searchText,
      sortColumn: "",
      sortDirection: "",
    }
  }
  initDatatable() {
    if (this.currentUrl == this.routePath.CODECROSSWALK_ROUTEPATH) {
      this.crosswalkDatatable();
    } else {
      this.tablePaginationModel = {
        sortColumn: "",
        sortDirection: "",
        pageNumber: this.agGridPaginationConfig.currentPageNumber,
        pageSize: this.agGridPaginationConfig.pageSize,
        searchText: this.agGridPaginationConfig.searchText,
        getAll: this.agGridPaginationConfig.pageSize == 1 ? true : false,
        id: this.tablePaginationModel.id,
        isActive: this.tablePaginationModel.isActive,
        companyOrVendorId: '',
        documentTypeId:0,
        stage: this.agGridService.getStage(),
        filters: "",
        selectedId: [],
        exportAsPdf: true,
        companyId: '',
        categoryId: '0',
        filtersFromPopup: this.agGridService.getFilter(),
        searchCompanyText:this.tablePaginationModel.searchCompanyText,

      };
      // this.agGridService.tablePaginationData.next(this.tablePaginationModel);
      this.tablePaginationModelContact = {
        sortColumn: "",
        sortDirection: "",
        pageNumber: this.agGridPaginationConfig.currentPageNumber,
        pageSize: this.agGridPaginationConfig.pageSize,
        searchText: this.agGridPaginationConfig.searchText,
        getAll: this.agGridPaginationConfig.pageSize == 1 ? true : false,
        id: this.tablePaginationModel.id,
        filters: "",
        filtersFromPopup: this.agGridService.getFilter(),
      }
    }
  }
  getRecyclebinTableData() {
    this.agGridPaginationConfig.rows = [];
  }
  getCodeCrossWalkTableData() {
    this.agGridPaginationConfig.rows = [];
    this.isLoading = true;
    this.paginationBaseModel.searchText = this.searchTextCodeCrossWalk;
    this.agGridService.getAllCodeCrosswalk(this.paginationBaseModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: Result) => {
        if (data.isSuccess === true) {
          this.agGridService.agGridPaginationConfigRes.next(this.agGridService.updateTableData(data));
          this.isLoading = false;
          this.agGridService.getGridApi().showNoRowsOverlay();
          this.agGridService.selectionChanged.next(this.agGridService.getGridApi().getSelectedRows())
        }
      });
  }
  getVendorListTableData(){
    this.agGridPaginationConfig.rows = [];
    this.isLoading = true;
    this.agGridService.getVendorList(this.tablePaginationModel).pipe(catchError((err) => this.handleError(err))).subscribe((data: Result) => {
        if (data.isSuccess === true) {
          this.agGridService.agGridPaginationConfigRes.next(this.agGridService.updateTableData(data));
          this.isLoading = false;
          this.agGridService.getGridApi().showNoRowsOverlay();
          this.agGridService.selectionChanged.next(this.agGridService.getGridApi().getSelectedRows())
        }
      });

  }
  getAllTableData() {
    this.agGridPaginationConfig.rows = [];
    this.isLoading = true;

    this.agGridService.GetAllCRM(this.tablePaginationModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: Result) => {
        if (data.isSuccess === true) {
          console.log('the data is of grid is#######', data)
          this.agGridService.agGridPaginationConfigRes.next(
            this.agGridService.updateTableData(data)
          );
          this.isLoading = false;
          this.agGridService.getGridApi().showNoRowsOverlay();
          this.agGridService.selectionChanged.next(this.agGridService.getGridApi().getSelectedRows())
        }
      });
  }
  allContactVendorData(){
    this.agGridPaginationConfig.rows = [];
    this.isLoading = true;
    this.agGridService.GetAllVendorContacts(this.tablePaginationModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: Result) => {
        if (data.isSuccess === true) {
          this.agGridService.agGridPaginationConfigRes.next(
            this.agGridService.updateTableData(data)
          );
          this.isLoading = false;
          this.agGridService.getGridApi().showNoRowsOverlay();
          this.agGridService.selectionChanged.next(this.agGridService.getGridApi().getSelectedRows())
        }
      });

  }
getContactRecycleBinData(){
  this.agGridPaginationConfig.rows = [];
  this.isLoading = true;
  this.agGridService.GetAllContactsRecybinData(this.tablePaginationModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe((data: Result) => {
      if (data.isSuccess === true) {
        this.agGridService.agGridPaginationConfigRes.next(
          this.agGridService.updateTableData(data)
        );
        this.isLoading = false;
        this.agGridService.getGridApi().showNoRowsOverlay();
        this.agGridService.selectionChanged.next(this.agGridService.getGridApi().getSelectedRows())
      }
    });

}
getCompanyRecycleBinData(){
  this.agGridPaginationConfig.rows = [];
  this.isLoading = true;
  this.agGridService.  GetAllCompanyRecybinData (this.tablePaginationModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe((data: Result) => {
      if (data.isSuccess === true) {
        this.agGridService.agGridPaginationConfigRes.next(
          this.agGridService.updateTableData(data)
        );
        this.isLoading = false;
        this.agGridService.getGridApi().showNoRowsOverlay();
        this.agGridService.selectionChanged.next(this.agGridService.getGridApi().getSelectedRows())
      }
    });

}

  getContactTableData() {
    this.agGridPaginationConfig.rows = [];
    this.isLoading = true;
    this.agGridService.GetAllContacts(this.tablePaginationModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((data: Result) => {
        if (data.isSuccess === true) {
          this.agGridService.agGridPaginationConfigRes.next(
            this.agGridService.updateTableData(data)
          );
          this.isLoading = false;
          this.agGridService.getGridApi().showNoRowsOverlay();
          this.agGridService.selectionChanged.next(this.agGridService.getGridApi().getSelectedRows())
        }
      });
  }

  searchTextFilters(value) {
    this.tablePaginationModel.searchText = value;
    if (this.currentTab == this.tabName.CONTACT_TAB || this.currentUrl == this.routePath.CONTACT_ROUTEPATH) {
      this.getContactTableData();
    }else if(this.currentUrl == this.routePath.VENDORCONTACT_ROUTEPATH || this.currentTab == this.tabName.VENDORCONTACT_TAB){
      this.allContactVendorData();
    }else if (this.currentUrl == this.routePath.VENDORLIST_ROUTEPATH || this.currentTab == this.tabName.VENDORLIST_TAB) {
      this.getVendorListTableData();
    }else {
      this.getAllTableData();
    }
  }

  handleError(err: any): any {
    console.log(err);
    if(this.isLoading){
      this.isLoading = false
    }
  }

  //#endregion


  //#region onGridReady

  // onGridReady(params) {
  //   this.agGridService.setGridApi(params.api);
  //   this.agGridService.setGridColumnApi(params.columnApi);
  //   // var stage = this.agGridService.getStage()
  //   // if(stage != "Lead"){
  //   //   var a = this.agGridService.getGridColumnApi();
  //   //   a.setColumnsVisible(['leadScore'], false)
  //   // }
  //   // if(stage != "Prospect"){
  //   //   var a = this.agGridService.getGridColumnApi();
  //   //   a.setColumnsVisible(['IntenttoBuy'], false)
  //   // }
  //   // if(stage == "Lead" || stage == "Prospect" || stage == "Customer"){
  //   //   var a = this.agGridService.getGridColumnApi();
  //   //   a.setColumnsVisible(['stage'], false)
  //   // }
  // }

  onGridReady(params) {
    this.gridApi = params.api
    this.agGridService.setGridApi(params.api);
    this.agGridService.setGridColumnApi(params.columnApi);
    this.gridApi.sizeColumnsToFit(params.columnApi);
    // For Column Save State
    var colState = this.storageService.retrieve(this.dataModel.storageName);
    // if (colState != undefined) {
    //   colState.forEach(col => {
    //     col.colId = this.UDFList?.filter(el => el.nStarUDFName.toLowerCase() == col.colId.toLowerCase() && el.nStarTable == 'Transactions')[0]?.udfFieldName ?? col.colId;
    //   })
    // }
    if (!colState) {
      return;
    }
    this.agGridService.getGridColumnApi().applyColumnState({
      state: colState,
      applyOrder: true,
    });

    // var value = (document.getElementById('page-size') as HTMLInputElement)
    // .value;
    // For Column Save State
  }
  //#endregion

  //#region  For getting Row Height
  getRowHeight(params: RowHeightParams): number | undefined | null {
    return params.data.isExpandable ? 225 : 32;
  }
  //#endregion

  //#region Pagination
  onPageSizeChange(pageSize: number) {
    this.agGridService.onPageSizeChanged(pageSize);
    this.initDatatable();
    if (this.currentTab == this.tabName.CONTACT_TAB ||this.currentUrl == this.routePath.CONTACT_ROUTEPATH) {
      this.getContactTableData();
    }else if( this.currentTab == this.routePath.CONTACT_RECYLCLE_BIN_ROUTEPATH || this.currentUrl == this.routePath.CONTACT_RECYLCLE_BIN_ROUTEPATH){
      this.getContactRecycleBinData();
    }else if (this.currentUrl == this.routePath.COMPANY_RECYCLE_BIN_ROUTEPATH || this.currentTab == this.tabName.COMPANY_RECYCLE_BIN_TAB) {
      this.getCompanyRecycleBinData();
    }else if (this.currentUrl == this.routePath.CODECROSSWALK_ROUTEPATH) {
      this.getCodeCrossWalkTableData();
    }else if(this.currentUrl == this.routePath.VENDORCONTACT_ROUTEPATH || this.currentTab == this.tabName.VENDORCONTACT_TAB){
      this.allContactVendorData();
    }else if (this.currentUrl == this.routePath.VENDORLIST_ROUTEPATH || this.currentTab == this.tabName.VENDORLIST_TAB) {
      this.getVendorListTableData();
    }else if (this.currentUrl == this.routePath.REQUIREMENT_ROUTEPATH || this.currentTab == this.tabName.REQUIREMENTS_TAB) {
      this.getSalesRequirementsTableData();
    }else if (this.currentUrl == this.routePath.PARTLIST_ROUTEPATH || this.currentTab == this.enumTabNames.PARTLIST) {
      this.getPartsListTableData();
    }
    else {
      this.getAllTableData();
    }
    this.agGridService.toggleHeaderExpandIcon.next(false)
  }

  onPageChanged(pageNum: number) {
    if(this.tablePaginationModel.pageNumber == pageNum){
      return
    }
    this.agGridService.onPageChanged(pageNum);
    this.initDatatable();
    if (this.currentTab == this.tabName.CONTACT_TAB || this.currentUrl == this.routePath.CONTACT_ROUTEPATH) {
      this.getContactTableData();
    }else if (this.currentUrl == this.routePath.CONTACT_RECYLCLE_BIN_ROUTEPATH || this.currentTab == this.tabName.CONTACT_RECYCLE_BIN_TAB) {
      this.getContactRecycleBinData();
    }else if (this.currentUrl == this.routePath.COMPANY_RECYCLE_BIN_ROUTEPATH || this.currentTab == this.tabName.COMPANY_RECYCLE_BIN_TAB) {
      this.getCompanyRecycleBinData();
    }else if (this.currentUrl == this.routePath.CODECROSSWALK_ROUTEPATH) {
      this.getCodeCrossWalkTableData();
    }else if(this.currentUrl == this.routePath.VENDORCONTACT_ROUTEPATH || this.currentTab == this.tabName.VENDORCONTACT_TAB){
      this.allContactVendorData();
    }else if (this.currentUrl == this.routePath.VENDORLIST_ROUTEPATH || this.currentTab == this.tabName.VENDORLIST_TAB) {
      this.getVendorListTableData();
    }else if (this.currentUrl == this.routePath.REQUIREMENT_ROUTEPATH || this.currentTab == this.tabName.REQUIREMENTS_TAB) {
      this.getSalesRequirementsTableData();
    }else if (this.currentUrl == this.routePath.PARTLIST_ROUTEPATH || this.currentTab == this.enumTabNames.PARTLIST) {
      this.getPartsListTableData();
    }
    else {
      this.getAllTableData();
    }
    this.agGridService.toggleHeaderExpandIcon.next(false)
  }
  //#endregion

  //#region side column settings
  Customizer(val) {
    this.customizer = val;
    this.agGridColumnsSettingService.isCustomizerLayoutOpen.next(val);
  }
  //#endregion

  //#region onSelectionChanged
  onSelectionChanged(event: SelectionChangedEvent) {

    this.agGridService.selectionChanged.next(event);
  }
  //#endregion

  //#region onColumnDragStop
  onDragStopped(event: DragStoppedEvent) {
    this.agGridService.onColumnDragStopped.next(event);
    this.updateUserSetting();
  }
  //#endregion
  columnMoved(event) {
    window.colState = event.columnApi.getColumnState(); // For getting Column State
    this.storageService.store(this.dataModel.storageName, window.colState);
  }
  updateUserSetting() {
    const data = this.agGridService.getGridApi().getColumnDefs();
    let currentTab = ''
    this.tabService.currentTab.subscribe(res => {
      currentTab = res
    })
    if (!currentTab) {
      currentTab = this.tabService.thisTab
    }
    this.userSetting = this.storageService.retrieve("userSetting")
    if (currentTab == this.gridName.LEADS) {
      this.userSetting.leads = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.COMPANIES) {
      this.userSetting.companies = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.CUSTOMERS) {
      this.userSetting.customers = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.PROSPECTS) {
      this.userSetting.prospects = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.CONTACTS) {
      this.userSetting.contacts = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.VENDORLISTS) {
      console.log('in vendor contact ##########')
      this.userSetting.vendorLists = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.VENDORCONTACTS) {
      console.log('in veondero contact %%%%%%%%%%%%%')
      this.userSetting.vendorContacts = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.REQUIREMENTS) {
      console.log('in veondero contact %%%%%%%%%%%%%')
      this.userSetting.requirementLists = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    this.saveUserSetting.userSetting = JSON.stringify(this.userSetting);
    this.agGridService.saveUserSetting(this.saveUserSetting).subscribe(res => {
      const json = JSON.parse(res?.response);
      this.storageService.store("userSetting", json)
    })
  }

  //#region getRowClass
  public rowClassRules: RowClassRules = {
    "border-0": (params) => {
      return params.data.isExpandable == false;
    },

    "row-border": (params) => {
      return params.data.isExpandable == true;
    },
    "expandable-grid": (params) => {
      return params.data.isExpandable == true;
    },
    "custom-row-style": (params) => {
      return params.data.isExpandable == true;
    },


  };
  //#endregion
  onFirstDataRendered(params: { api: GridApi; columnApi: ColumnApi }) {
    this.agGridService.saveColumnState(params.columnApi);
  }
  onRowClicked(params){
   console.log(params);
   this.onRowClick.emit(params)

  }
  onColumnResized(event: ColumnResizedEvent) {
    if(event.source == 'uiColumnDragged'){
    window.colState = event.columnApi.getColumnState(); // For getting Column State
    this.storageService.store(this.dataModel.storageName, window.colState);
  }
  }

     ngOnDestroy() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
    this.signalRService.smxStopConnection();
        console.log("stop connection");

    }
signalRData(){
  this.signalRService.smxStartConnection().subscribe(() => {
    this.signalRService.smxReceiveMessage().subscribe((message) => {
     console.log(message);
     this.returnMessage = message;
      const messageData = message['data']
      console.log(messageData);
      if(message['type'] == 'Delete'){
        if(this.route.url == this.routePath.CONTACT_ROUTEPATH || this.route.url == this.routePath.VENDORCONTACT_ROUTEPATH){
          this.idsToDelete = messageData['ids'];
        }else{
          this.idsToDelete = messageData['id'];
        }
      //  this.deleteRowData()
      this.deleteListRecord();
      }else{
        this.addRowData(messageData)
      //  this.listRecords(messageData);
  }
    })
    this.dropdownListData()
  })
  
}
deleteRowData(){
  switch ( this.returnMessage['currentURL']) {
    case this.route?.url:
      this.deleteListRecord();
      break;
    default:
      break;
  }
}
addRowData(messageData){
  switch (this.returnMessage['currentURL']) {
    case this.routePath.CONTACT_ROUTEPATH:
    case this.routePath.VENDORCONTACT_ROUTEPATH:
    case this.routePath.COMPANY_ROUTEPATH:
    case this.routePath.VENDORLIST_ROUTEPATH:
    case this.routePath.CODECROSSWALK_ROUTEPATH:
     // this.listRecords(messageData);
     this.callFunction(messageData)
      break;
      case this.companyD.CRM_COMPANIES_COMPANY_INFO + messageData.companyId:
      case this.companyD.PROCUREMENT_VENDOR_INFO + messageData.vendorId:
       this.callFunction(messageData)
      break;
      case this.companyD.CRM_COMPANIES_COMPANY_INFO + this.returnMessage['moduleId']:
        case this.companyD.PROCUREMENT_VENDOR_INFO + this.returnMessage['moduleId']:
          this.callFunction(messageData)
          break;

    
    default:
     // this.callFunction(messageData)
     this.listRecords(messageData);
      break;
  }
}
callFunction(messageData){
  if(  (this.route?.url == this.routePath.LEAD_ROUTEPATH || this.route?.url == this.routePath.COMPANY_ROUTEPATH) &&
  messageData?.saleStageId !== undefined && messageData?.saleStageId == '2'
) {
      this.listRecords(messageData);
  }else  if((this.route?.url == this.routePath.CUSTOMER_ROUTEPATH || this.route?.url == this.routePath.COMPANY_ROUTEPATH )
    && messageData?.saleStageId !== undefined && messageData?.saleStageId == '1' ){
      this.listRecords(messageData);
  }
  else  if((this.route?.url == this.routePath.PROSPECT_ROUTEPATH || this.route?.url == this.routePath.COMPANY_ROUTEPATH )
    && messageData?.saleStageId !== undefined && messageData?.saleStageId == '3' ){
      this.listRecords(messageData);
  }else if((this.route?.url == this.routePath.VENDORLIST_ROUTEPATH)
    && messageData?.saleStageId !== undefined && messageData?.saleStageId == '4'){
      if((this.returnMessage['moduleName'] !== 'Vendor Contact') ){
       this.listRecords(messageData);
     }
  }else 
   if(this.route?.url == this.routePath.COMPANY_ROUTEPATH){
    if(messageData?.saleStageId !== '4' && messageData?.saleStageId !== undefined){
      if((this.returnMessage['moduleName'] !== 'Company Contact') ){
       this.listRecords(messageData);
     }
    }
  }
  else  if(this.route?.url == this.routePath.CONTACT_ROUTEPATH){
    if((this.returnMessage['moduleName'] == 'Company Contact') ){
      this.listRecords(messageData);
    }
  }
  else  if(this.route?.url == this.routePath.VENDORCONTACT_ROUTEPATH){
    if((this.returnMessage['moduleName'] == 'Vendor Contact') ){
      this.listRecords(messageData);
    }
  }
  else if(this.route?.url == this.routePath.CODECROSSWALK_ROUTEPATH){
    if((this.returnMessage['moduleName'] == 'Code Crosswalk') ){
      this.listRecords(messageData);
    }
  }

}
deleteListRecord(){
  const removeIndices = this.agGridPaginationConfig?.rows
  .map((item, index) =>  this.idsToDelete?.includes(item?.id) ? index : -1)
  .filter(index => index !== -1);
removeIndices.reverse().forEach(index => {
  this.agGridPaginationConfig.rows.splice(index, 1);
});
this.gridApi?.setRowData(this.agGridPaginationConfig.rows);  
this.cdr.detectChanges();

}
listRecords(messageData){
  const index = this.agGridPaginationConfig?.rows.findIndex(item => item?.id === messageData?.id);
  if (index !== -1) {
  this.agGridPaginationConfig.rows[index] = messageData;
 } else {
 this.agGridPaginationConfig.rows.push(messageData);
 }  
 this.gridApi?.setRowData(this.agGridPaginationConfig.rows);  
 this.cdr.detectChanges();

}
dropdownListData(){
  this.signalRService.smxReceiveListMessage().subscribe((message) => {
    const messageData = message['data']
    console.log(messageData);
  })
}

smxSendMessage(message: string): void {
  this.signalRService.smxSendMessage(message);
}  
}

const commonGridOptions = {
  defaultColDef: {
    width: 100, // Set the common width for all columns
  },
  // Other common grid options can be added here
};

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import {
  AddressesInfoList,
  CommonDropdownModel,
  DeleteContactAddress,
  InserUpdateCompny,
  PopupHeadingNames,
  TypeList
} from "../add-company.model";
import { ToastrService } from 'ngx-toastr';
import { AddCompanyService } from '../add-company.service';
import { CommonTabService } from "src/app/shared/services/common-tab.service";

@Component({
  selector: 'app-address-information',
  templateUrl: './address-information.component.html',
  styleUrls: ['./address-information.component.scss']
})
export class AddressInformationComponent implements OnInit {
  @Output() dataEvent = new EventEmitter<string>();
  @Output() inputFillReq = new EventEmitter();
  @Input() addressHeading:string
  submit: boolean = false;
  addressTypepId: any;
  InsertUpdateCompanyAdd: AddressesInfoList[] = [];
  getAddressData = []
  addressInfoVm = new AddressesInfoList()
  insertUpdateGeneralInfoCompany = new InserUpdateCompny();
  saleStageList: TypeList[] = [];
  countryList: CommonDropdownModel[] = [];
  stateList: CommonDropdownModel[] = [];
  addressTypeList: CommonDropdownModel[] = [];
  id:any[]=[];
  enumPopupHeadingNames =  PopupHeadingNames;
  gedata:string
  deleteContactAddress =  new DeleteContactAddress()
  submit2:boolean=false;
  constructor(
    public companiesService: AddCompanyService,
    private toaster: ToastrService,
    public commonTabService: CommonTabService,) { }

valuePass(){
  this.companiesService.companyName.subscribe((data)=>{
      this.InsertUpdateCompanyAdd.forEach(address => {
      address.addressName = data.companyName;
      address.phoneNumber = data.phoneNumber;
   });
  })
}
  ngOnInit(): void {
    this.valuePass()
    this.addressHeading
    this.saleStageList = this.commonTabService.getAllDropdownList.getValue().typeList;
    this.countryList = this.commonTabService.getAllDropdownList.getValue().countryList;
    this.stateList = this.commonTabService.getAllDropdownList.getValue().stateList;
    this.addressTypeList = this.commonTabService.getAllDropdownList.getValue().addressTypeList;
    this.onClick();
    this.addAnotherAddress();
    this.companiesService.sendNameData.subscribe((res) =>{
      this.gedata = res
    })
  }
  tabNext(){
    this.InsertUpdateCompanyAdd;
    const allHaveNames = this.InsertUpdateCompanyAdd.every(address => address.addressName && address.typeId && address.countryId);

    if(allHaveNames){
      this.companiesService.nextTab.next(true);
    }else{
      this.companiesService.nextTab.next(false)
    }
  }
  
  onClick() {
    this.dataEvent.emit('Data from child component');
  }

  insertAddressdata() {
    this.insertUpdateGeneralInfoCompany.addressDetails = this.InsertUpdateCompanyAdd;
    this.companiesService.insertGeneralinpformation(this.insertUpdateGeneralInfoCompany).subscribe((res) => {
      if (res.isSuccess == true) {
        this.toaster.success(res.message);
      }
    })
  }

  addAnotherAddress() {
  let addresses =  this.InsertUpdateCompanyAdd;
    this.addressInfoVm = new AddressesInfoList();
    this.InsertUpdateCompanyAdd = [...this.InsertUpdateCompanyAdd, this.addressInfoVm];
    this.InsertUpdateCompanyAdd?.forEach(address => {
      address.addressName = addresses[0]?.addressName;
      address.phoneNumber = addresses[0]?.phoneNumber;

    });
  }

  cloneAddressInfo(addressClone: AddressesInfoList, index) {
    const cloneField = Object.assign({}, addressClone);
    cloneField.id = '';
    this.InsertUpdateCompanyAdd.splice(index + 1, 0, cloneField)
  }

  getvalue(event) {
    this.addressTypepId = event;
  }
  checkRequiredValue(): boolean 
  {
    if(this.addressHeading == this.enumPopupHeadingNames.ADDCOMPANY || this.addressHeading== '' ||    this.gedata == this.enumPopupHeadingNames.ADDCOMPANY )
    {
    if (this.InsertUpdateCompanyAdd.every(check => check.addressName && check.typeId && check.countryId)) 
    {
      return true;
    } else 
    {
      this.submit = true;
    }
  }
  else if(this.addressHeading == this.enumPopupHeadingNames.ADDVENDOR)
  {
    if(this.InsertUpdateCompanyAdd.every(check => check.addressName && check.typeId && check.countryId)) 
    {
      return true;
    } else 
    {
      this.submit2 = true;
    }
  }
  }

  deleteAddress(value,index)
  {if(value.id){
    this.id.push(value.id)
    this.deleteContactAddress.ids = this.id;
    this.companiesService.DeleteAddress(this.deleteContactAddress).subscribe((res) =>{
      if(res.isSuccess)
      {
        this.InsertUpdateCompanyAdd.splice(index, 1);
        this.toaster.success(res.message)
      }
    })}
    else{
      this.InsertUpdateCompanyAdd.splice(index, 1);
    }

  }

}
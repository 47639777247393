<!-- Page Header Start-->
<div
  #header
  class="page-header"
  [class.close_icon]="navServices.collapseSidebar"
>
  <div class="header-wrapper row m-0">
    <div
      class="d-flex align-items-center main-header px-0 flex-lg-wrap flex-md-wrap"
    >
      <!-- <img
        class="image-height-width order-xl-1 order-lg-1 order-md-1"
        [src]="imageData"
        onerror="this.src=''"
        id="onlyImage"
        alt="SMW"
      /> -->
      <img
        class="image-height-width order-xl-1 order-lg-1 order-md-1"
        [src]="imageData"
        onerror="this.src='../../../../assets/SVGIcons/logo.svg'"
        id="onlyImage"
        alt=""
      />
      <nav
        class="sidebar-main main-header-nav order-xl-2 {{
          widthVal >= 991 && widthVal <= 1057 ? 'order-lg-3' : 'order-lg-2'
        }} order-md-3 d-flex align-items-end justify-content-lg-between justify-content-md-between"
      >
        <div
          class="left-arrow"
          id="left-arrow"
          [class.d-none]="leftArrowNone"
          (click)="scrollLeft()"
        >
          <app-svg-icon [src]="'./assets/SVGIcons/Polygon1.svg'"></app-svg-icon>
        </div>
        <div #widgetsContent id="sidebar-menu">
          <ul class="sidebar-links custom-scrollbar">
            <li
              [class]="
                menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'
              "
              *ngFor="let menuItem of menuItems"
              [ngClass]="{ active: menuItem.active }"
            >
              <a
                href="javascript:void(0)"
                class="sidebar-link sidebar-title"
                [class.link-nav]="!menuItem.children"
                [ngClass]="{ active: menuItem.active }"
                *ngIf="menuItem.type === 'sub'"
                (click)="toggletNavActive(menuItem)"
              >
                <span>{{ menuItem.title | translate }}</span>
              </a>
              <!-- Link -->
              <a
                [routerLink]="!menuItem.type ? null : [menuItem.path]"
                routerLinkActive="active"
                class="sidebar-link sidebar-title"
                [class.link-nav]="!menuItem.children"
                [ngClass]="{ active: menuItem.active }"
                *ngIf="menuItem.type === 'link'"
              >
                <span>{{ menuItem.title | translate }}</span>
              </a>
              <!-- External Link -->
              <a
                href="{{ !menuItem.type ? null : menuItem.path }}"
                class="sidebar-link sidebar-title"
                [class.link-nav]="!menuItem.children"
                [ngClass]="{ active: menuItem.active }"
                *ngIf="menuItem.type === 'extLink'"
              >
                <span>{{ menuItem.title | translate }}</span>
              </a>
              <!-- External Tab Link -->
              <a
                href="{{ !menuItem.type ? null : menuItem.path }}"
                target="_blank"
                class="sidebar-link sidebar-title"
                [class.link-nav]="!menuItem.children"
                [ngClass]="{ active: menuItem.active }"
                *ngIf="menuItem.type === 'extTabLink'"
              >
                <span>{{ menuItem.title }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div
          class="right-arrow"
          id="right-arrow"
          [class.d-none]="rightArrowNone"
          (click)="scrollRight()"
        >
          <app-svg-icon [src]="'./assets/SVGIcons/Polygon2.svg'"></app-svg-icon>
        </div>
      </nav>
      <div
        class="p-0 order-xl-3  {{
          widthVal >= 991 && widthVal <= 1057 ? 'order-lg-2' : 'order-lg-3'
        }} order-md-2 right-navContent me-3"
      >
        <ul class="nav-menus">
          <li>
            <app-svg-icon [src]="'./assets/SVGIcons/searchBigIcon.svg'">
            </app-svg-icon>
          </li>

          <li
            class="addIcon position-relative {{
              activeIcon == 'add' ? 'active' : ''
            }}"
            (click)="
              addListShow('add');
              activeIcon = 'add';
              showlist == false ? (activeIcon = '') : (activeIcon = 'add')
            "
          >
            <div
              class="position-absolute"
              (mouseleave)="showlist = false; currentNav = ''; activeIcon = ''"
            >
              <div class="list-wrap" *ngIf="showlist">
                <ol
                  class="add-list-item"
                  *ngFor="let listitem of addiconList; let i = index;let f=first"
                >
                  <li>
                    <span
                      (click)="seletedItem(i);openAddEditCompanyModal(listitem.name);openAddEditAddContactModal(listitem.name);
                      ;openAddRequiremnt(listitem.name)"
                      [ngClass]="{ active: listitem.selected,'fw-semibold':f}"
                      >{{ listitem.name }}</span
                    >
                  </li>
                </ol>
              </div>
            </div>

            <app-svg-icon [src]="'./assets/SVGIcons/Add.svg'"> </app-svg-icon>
          </li>
          <li
            class="{{ activeIcon == 'tasks' ? 'active' : '' }}"
            (click)="toggelNotificationSideBar('tasks',clickValue);clickValue = !clickValue; activeIcon = 'tasks'"
          >
            <app-svg-icon class="task" [src]="'./assets/SVGIcons/addTask.svg'">
            </app-svg-icon>
          </li>
          <li
            class="{{ activeIcon == 'notifications' ? 'active' : '' }}"
            (click)="
              toggelNotificationSideBar('notifications',clickValue);clickValue = !clickValue;
              activeIcon = 'notifications'
            "
          >
            <app-svg-icon [src]="'./assets/SVGIcons/notification.svg'">
            </app-svg-icon>
          </li>
          <li class="profile-nav onhover-dropdown p-0">
            <app-my-account></app-my-account>
          </li>
        </ul>
      </div>
    </div>
    <nav class="sidebar-main sub-header">
      <div id="sidebar-menu">
        <ul class="sidebar-links custom-scrollbar">
          <li
            [class]="
              menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'
            "
            *ngFor="let menuItem of menuItems"
            [ngClass]="{ active: menuItem.active }"
          >
            <!-- 2nd Level Menu -->
            <ul 
              class="sidebar-submenu sub-menu-item ms-5"
              [ngClass]="{ active: menuItem.active }"
              [ngClass]="{
                'menu-open': menuItem.active,
                'menu-close': !menuItem.active
              }"
              *ngIf="menuItem.children && showSecondary"
              [style.display]="menuItem.active ? 'flex' : 'none'"
            >
              <li
                *ngFor="let childrenItem of menuItem.children"
                [ngClass]="{ active: childrenItem.active }"
              >
                <!-- Sub -->
                <a
                  class="submenu-title"
                  href="javascript:void(0)"
                  *ngIf="childrenItem.type === 'sub'"
                  (click)="toggletNavActive(childrenItem)"
                >
                  <span class="text-nowrap"> {{ childrenItem.title | translate }}</span>
                </a>
                <!-- Link -->
                <a
                  class="submenu-title align-items-center"
                  (click)="addTabs(childrenItem)"
                  [routerLink]="
                    !childrenItem.type
                      ? null
                      : 'admin/system-settings' == [childrenItem.path] || 'admin/company-settings' == [childrenItem.path]
                      ? null
                      : [childrenItem.path]
                  "
                  *ngIf="childrenItem.type === 'link'"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <app-svg-icon [src]="childrenItem.icon"> </app-svg-icon>
                  <span  class="text-nowrap">{{ childrenItem.title | translate }}</span>
                </a>
                <!-- External Link -->
                <a
                  class="submenu-title"
                  href="{{ !childrenItem.type ? null : childrenItem.path }}"
                  *ngIf="childrenItem.type === 'extLink'"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span  class="text-nowrap">{{ childrenItem.title | translate }}</span>
                </a>
                <!-- External Tab Link -->
                <a
                  class="submenu-title"
                  href="{{ !childrenItem.type ? null : childrenItem.path }}"
                  target="_blank"
                  *ngIf="childrenItem.type === 'extTabLink'"
                >
                  <span  class="text-nowrap">{{ childrenItem.title | translate }}</span>
                </a>
              </li>
              <li>
                <app-svg-icon
                  *ngIf="menuItem.children.length > 0"
                  (click)="closeNavActive(menuItem)"
                  [src]="'./assets/SVGIcons/downArrow1.svg'"
                ></app-svg-icon>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
<!-- Page Header Ends -->

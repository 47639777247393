import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from 'src/app/shared/services/data.service';
import { Observable, tap } from 'rxjs';
import { Result } from 'src/app/shared/model/response.model';
import {UserIdModel} from './sidebar-notification.model'
@Injectable({
  providedIn: 'root'
})
export class SidebarNotificationService {
BehaviourSb
  isSidebarNotificationShow = new BehaviorSubject(undefined);
  headerIconActive  = new Subject();
  changedVal = new BehaviorSubject(undefined)
  loadData = new BehaviorSubject(undefined);
  tooltip = new BehaviorSubject(undefined);

  
  
  // (undefined)        
  constructor(public dataService:DataService) { }
  
  GetAllTaskList(UserIdModel:UserIdModel): Observable<Result>{
    let url='app/ActivityManager/getSideBarActivities';
    return this.dataService.post(url,UserIdModel,true).pipe<Result>(tap((response:any)=>{
      return response;
      }))
    }
    
}

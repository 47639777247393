<app-button
*ngIf="currentTab == this.enumTabNames.INSTOCK"
class="mx-1"
imgUrl=""
[buttonLable]="buttonName" >
</app-button>
<div 
appClickOutside (clickOutside)="closeList()"
class="d-flex position-relative">
<app-button
class="mx-1"
imgUrl=""
[buttonLable]="name"
[class]="buttonClass"
(click)="showlist = !showlist">
</app-button>
<div class="list-wrap"
[ngClass]="classActive"
 style="width: 100px;" *ngIf="showlist">
<ol
  class="add-list-item"
  *ngFor="let listitem of optionList; let i = index;let f=first" >
  <li>
    <span
    (click)="seletedItem(i);" 
      [ngClass]="{ activeName: listitem.selected}"
      >{{ listitem.name }}</span
    >
  </li>
</ol>
</div>

</div>
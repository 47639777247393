<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xl-12 px-0 d-flex">
      <div class="w-100 col-md-12 col-xl-12 background-image">
        <div class="w-100 d-flex flex-wrap align-items-center justify-content-center justify-content-xl-evenly flex-md-column flex-xl-row">
          <img
            src="../../../../assets/images/login/aegislogo.png"
            alt="login-Page"
          />
          <div class="card my-md-5 my-xl-0">
            <div class="card-body">
              <form
                class="theme-form"
                ngNativeValidate
                autocomplete="nope"
              >
                <span class="text-primary-color f-16 f-w-600">Log In</span>
                <p class="text-light-info f-12 mb-3">Enter your credentials to access your account.</p>
                <div class="form-group login-mt">
                  <input 
                    type="email" 
                    name="username" 
                    #username="ngModel" 
                    [(ngModel)]="loginModel.email" 
                    required
                    placeholder="Email" 
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
                    class="form-control login-mt"
                    autocomplete="off">
                  <div class="form-control-feedback" *ngIf="username.errors && (username.dirty || username.touched)">
                    <p *ngIf="username.errors.required" class="text-danger font-10">Email is required</p>
                    <p *ngIf="username.errors?.pattern" class="text-danger font-10">This is not a valid Email</p>
                  </div>
                </div>
                <div class="form-group login-mt">
                  <input 
                    class="form-control " 
                    [type]="show ? 'text' : 'password'" 
                    name="password" 
                    #password="ngModel"
                    minlength="6" 
                    maxlength="40" 
                    [(ngModel)]="loginModel.password" 
                    required 
                    autocomplete="off"
                    placeholder="Password">
                  <!-- <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div> -->
                  <div class="form-control-feedback" *ngIf="password.errors && (password.dirty || password.touched)">
                    <p *ngIf="password.errors.required" class="text-danger font-10">Password is required</p>
                    <p *ngIf="password.errors.minlength" class="text-danger font-10">This is not a valid password</p>
                  </div>
                </div>
                <div class="form-group p-t-20 mb-0">
                  <button class="btn" id="signIn" type="submit" (click)="authenticate()">
                    <span>Log In</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { ImportDocumentsService } from '../../import-documents.service';
import { Button, CurrentTab, Path, RoutePath } from 'src/app/shared/model/enum';
import { Router } from '@angular/router';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { BehaviorSubject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-summary-tab',
  templateUrl: './summary-tab.component.html',
  styleUrls: ['./summary-tab.component.scss'],
})
export class SummaryTabComponent implements OnInit {
  flexHeight;
  failed = true;
  totalRecords;
  currentTab:string;
  fileName:string;
  routeUrl:string;
  routePath = RoutePath;
  tabName = CurrentTab;
  enumButtonName = Button;

  @ViewChild('toEnd', { static: false }) toEnd: ElementRef;
  constructor(public commonService: CommonService,
    public importDocumentsService:ImportDocumentsService,
    private router: Router,
    public tabsService: TabsService,
    public  activeModal: NgbActiveModal,
    )
   { 
    this.routeUrl = this.router.url;
      }

  ngOnInit(): void {
    this.tabsService.currentTab.subscribe(res => {
      if (res !== undefined) {
        this.currentTab = res;
      } 
    })
  
    this.importDocumentsService.toEnd.subscribe(res => {
      if(res?.isSuccess == true){
        this.failed = true;
        this.totalRecords = res.totalRecords;
        this.toEnd?.nativeElement.click();
        console.log('thasc clickeddd')
        document.getElementById('toEnd').click()
      }else{
        this.failed = false;
      }
    })
    this.commonService.headerHeight.subscribe(res =>{
      this.flexHeight = "calc(100vh - 290px - " + res +"px" + ")" 
  })
  this.importDocumentsService.fileName.subscribe((res)=>{
    if(res){
      this.fileName =res;
    }
  })

  }
 
  backToImport(){
    if(this.currentTab== this.tabName.CODECROSSWALK_TAB){
      this.activeModal.close(true);
    }else{
      this.importDocumentsService.dataPassAccordingToTrackingBar = new BehaviorSubject(undefined);
      if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
        this.router.navigate([this.routePath.VENDORLIST_ROUTEPATH]);
        setTimeout(() => {
          this.router.navigate([this.routePath.IMPORTVENDOR_ROUTEPATH]);
        }, 1);
        }else{
        this.router.navigate([this.routePath.COMPANY_ROUTEPATH]);
        setTimeout(() => {
          this.router.navigate([this.routePath.IMPORTCOMPANY_ROUTEPATH]);
        }, 1);
        }
    }
   }

}

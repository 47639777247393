// app.constants.ts

export const URLS = {
    requirement: {
      insert: 'app/Requirement/insertRequirement',
      update: 'app/Requirement/updateRequirement',
     
      getByMPN: 'app/Requirement/getRequirementByMPN?MPN=',
      getNo: 'app/Requirement/getRequirementNo',
      getById: 'app/Requirement/getRequirementById?Id='
    },
    importCompanyOther: {
      deleteTemplateByTemplateId: 'app/importCompany/deleteTemplateByTemplateId?TemplateId='
    },
    dropdown:{
        getAllDropdown: 'app/DropDown/GetAllRequirementsDropDownList',
        getAllMPNList: 'app/Requirement/getAllMPNList',
    }
  };
  
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {InsertUpdateCodeCrosswalk} from '../../pages/code-crosswalk.model'
import {CodeCrosswalkService  } from "../../code-crosswalk.service"
import { ToastrService } from 'ngx-toastr';
import { Button, PopUpTitles } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-add-edit-code-group',
  templateUrl: './add-edit-code-group.component.html',
  styleUrls: ['./add-edit-code-group.component.scss']
})
export class AddEditCodeGroupComponent implements OnInit {
  submit:boolean=false;
  @Input() disabled: boolean = false;
  insertUpdateCodeCrosswalk = new InsertUpdateCodeCrosswalk();
  addNew:boolean=false;
  enumButtonName = Button
  subscription: any;
  enumPopUpTitles = PopUpTitles;
  constructor(public  activeModal: NgbActiveModal,
    public codeCrosswalkService:CodeCrosswalkService,
    public toaster: ToastrService
    ) { 
    }
  
    
    

  ngOnInit(): void {
  }

  
 
  isSicError: boolean = false;
  onSicInputBlur(): void {
    this.isSicError = this.insertUpdateCodeCrosswalk?.sic?.length < 4 ? true : false;
  }

  onSicInputChange(value: string): void {
  if(value.length >= 4){
    this.isSicError = false;
  }
  }
  isNaicsError: boolean = false;
  onNaicsInputBlur(): void {
    this.isNaicsError = this.insertUpdateCodeCrosswalk?.naics?.length < 4 ? true : false;
  }

  onNaicsInputChange(value: string): void {
  if(value.length >= 4){
    this.isNaicsError = false;
  }
  }
  onYearInputChange(value: string): void {
    if(value.length >= 4){
      this.isYearError = false;
    }
    }
  
  isYearError: boolean = false;
  onYearInputBlur(): void {
    this.isYearError = this.insertUpdateCodeCrosswalk?.naicsYear?.length < 4 ? true : false;
  }

  clearValue(value: string, field: string) {
    if (value === '') {
      switch (field) {
        case 'sic':
          if (this.insertUpdateCodeCrosswalk?.sic === '') {
            this.isSicError = false;
          }
          break;
        case 'naics':
          if (this.insertUpdateCodeCrosswalk?.naics === '') {
            this.isNaicsError = false;
          }
          break;
        case 'naicsYear':
          if (this.insertUpdateCodeCrosswalk?.naicsYear === '') {
            this.isYearError = false;
          }
          break;
        // Add more cases if needed for additional fields
      }
    }
  }
  
 clearDetails() {
  this.insertUpdateCodeCrosswalk = this.insertUpdateCodeCrosswalk.id
    ? { ...this.insertUpdateCodeCrosswalk, industry: '', active: true, naics: '', naicsYear: '', sic: '', sicDescription: '', naicsDescription: '' }
    : new InsertUpdateCodeCrosswalk();
}

  toggleActive() {
    this.insertUpdateCodeCrosswalk.active = !this.insertUpdateCodeCrosswalk.active;
  }
  // addCodeNew(){
  //   this.addCodeGroup();
  //   if (this.checkRequiredValue()) {
  //     setTimeout(() => {
  //       this.codeCrosswalkService.openAddModal.next(true);
  //     }, 1000); 
  //   }
  // }
addCodeGroup(addCodeGroup){
  if (!this.isSicError && !this.isNaicsError && !this.isYearError) {
  if (this.checkRequiredValue()) {
    if(addCodeGroup == this.enumButtonName.SAVE){
      this.insertUpdateCodeCrosswalk.id = '';
      this.codeCrosswalkService.insertCodeCrosswalk(this.insertUpdateCodeCrosswalk).subscribe((res)=>{
        if(res.isSuccess){
          this.toaster.success(res.message);
          this.codeCrosswalkService.valuePass.next(true);
          if(this.addNew == true){
            this.insertUpdateCodeCrosswalk = new InsertUpdateCodeCrosswalk();
            this.addNew = false;
          }else{
            this.activeModal.close(res);
          }
        }else {
          this.toaster.error(res.message)
        }
      })
    
    } else if(addCodeGroup == this.enumButtonName.UPDATE){
      this.codeCrosswalkService.updateCodeCrosswalk(this.insertUpdateCodeCrosswalk).subscribe((res)=>{
      
        if(res.isSuccess){
          this.toaster.success(res.message);
          this.codeCrosswalkService.valuePass.next(true);
          if(this.addNew == true){
            this.insertUpdateCodeCrosswalk = new InsertUpdateCodeCrosswalk();
            this.addNew = false;
          }else{
            this.activeModal.close(res);
          }
        }else {
          this.toaster.error(res.message)
        }
      })
    }
}
  }else {
    this.toaster.error('Cannot save due to validation errors.')
  }
}
checkRequiredValue(): boolean {
  if (this.insertUpdateCodeCrosswalk.naicsDescription &&
    this.insertUpdateCodeCrosswalk.industry &&
    // this.insertUpdateCodeCrosswalk.active &&
    this.insertUpdateCodeCrosswalk.naics &&
    this.insertUpdateCodeCrosswalk.naicsYear &&
    this.insertUpdateCodeCrosswalk.sic &&
    this.insertUpdateCodeCrosswalk.sicDescription 
    ) {
    return true;
  } else {
    this.submit = true;
  }
}

isFormValid(): boolean {
  if (!this.insertUpdateCodeCrosswalk) {
      return false; 
  }
  const sicValid = this.insertUpdateCodeCrosswalk.sic?.length >= 4 && this.insertUpdateCodeCrosswalk.sic?.length <= 8;
  const naicsValid = this.insertUpdateCodeCrosswalk.naics?.length === 4;
  const naicsYearValid = this.insertUpdateCodeCrosswalk.naicsYear?.length === 4;
  return sicValid && naicsValid && naicsYearValid;
}
}

<fieldset  [ngClass]="{'pe-none':(currentTab== 'contact-recycle-bin' || currentTab== 'company-recycle-bin' || currentTab== 'import-companies' || currentTab== 'import-vendors')}" class="rate" id="{{params.data.id}}_{{params.rowIndex}}" name="{{params.data.id}}_{{params.rowIndex}}">
    <input type="radio" id="rating10_{{params.rowIndex}}" (click)="onRatingChange(params.data, 10)" [checked]="rating==10?true:false" /><label for="rating10_{{params.rowIndex}}" title="5 stars"></label>
    <input type="radio" id="rating9_{{params.rowIndex}}"  (click)="onRatingChange(params.data, 9)"  [checked]="rating==9?true:false"  /><label class="halff" for="rating9_{{params.rowIndex}}" title="4.5 stars"></label>
    <input type="radio" id="rating8_{{params.rowIndex}}"  (click)="onRatingChange(params.data, 8)"  [checked]="rating==8?true:false"  /><label for="rating8_{{params.rowIndex}}" title="4 stars"></label>
    <input type="radio" id="rating7_{{params.rowIndex}}"  (click)="onRatingChange(params.data, 7)"  [checked]="rating==7?true:false"  /><label class="halff" for="rating7_{{params.rowIndex}}" title="3.5 stars"></label>
    <input type="radio" id="rating6_{{params.rowIndex}}"  (click)="onRatingChange(params.data, 6)"  [checked]="rating==6?true:false"  /><label for="rating6_{{params.rowIndex}}" title="3 stars"></label>
    <input type="radio" id="rating5_{{params.rowIndex}}"  (click)="onRatingChange(params.data, 5)"  [checked]="rating==5?true:false"  /><label class="halff" for="rating5_{{params.rowIndex}}" title="2.5 stars"></label>
    <input type="radio" id="rating4_{{params.rowIndex}}"  (click)="onRatingChange(params.data, 4)"  [checked]="rating==4?true:false"  /><label for="rating4_{{params.rowIndex}}" title="2 stars"></label>
    <input type="radio" id="rating3_{{params.rowIndex}}"  (click)="onRatingChange(params.data, 3)"  [checked]="rating==3?true:false"  /><label class="halff" for="rating3_{{params.rowIndex}}" title="1.5 stars"></label>
    <input type="radio" id="rating2_{{params.rowIndex}}"  (click)="onRatingChange(params.data, 2)"  [checked]="rating==2?true:false"  /><label for="rating2_{{params.rowIndex}}" title="1 star"></label>
    <input type="radio" id="rating1_{{params.rowIndex}}"  (click)="onRatingChange(params.data, 1)"  [checked]="rating==1?true:false"  /><label class="halff" for="rating1_{{params.rowIndex}}" title="0.5 star"></label>
</fieldset>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { CompaniesService } from 'src/app/components/crm/companies/pages/companies/companies.service';
import { CrmService } from 'src/app/components/crm/crm.service';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent implements OnInit {

  showDropDown: boolean;
  @Input() list:  any[];
  @Input() listLabel: string;
  @Input() required: boolean = false;
  @Input() drowDownColtwo:boolean= false;
  @Input() checkedList: any[];
  currentSelected: {};

  constructor(
    public crmService:CrmService ) {
    this.checkedList = [];
  }
  
  ngOnInit(): void {

    // this.checkedList = this.list?.filter(a=>a.isActive===true).map(b=>b.name);

    this.crmService.isSelected.subscribe((res) => {
      // this.list?.forEach((item) => {
      //   item.isActive= false;
      // });
      // this.checkedList = this.list?.filter(a=>a.isActive===true).map(b=>b.name)??[];
    });
  }

  toggle(item){
    item.isActive=!item.isActive
    this.checkedList = this.list?.filter(a=>a.isActive===true).map(b=>b.name);
    console.log('the toggle ', this.checkedList)
  }

  clearSelectedData() {
    this.list?.forEach((item) => {
      item.isActive = false;
    });
    this.checkedList = this.list?.filter(a=>a.isActive===true).map(b=>b.name);
  }



}

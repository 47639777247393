import {GridName} from './../../../shared/model/ag-grid-common-constant'


export class AgGridI {
    colDefs: AgColDefsI[];
    rowSelection: "single" | "multiple";
    storageName?: GridName;
  static Grid: any;
 }

 export class  commonGridOptions {
  defaultColDef: {
    width: 100, // Set the common width for all columns
  }
  // Other common grid options can be added here
};

 export class AgColDefsI {
  resizable?:boolean;
    headerName?: string;
    headerClass?:string;
    field: string;
    suppressMovable?:boolean;
    lockPosition?: boolean | 'left' | 'right' | null;
    lockPinned?: boolean;
    width?: number ;
    minWidth?: number;
    maxWidth?: number;
    editable?: any;
    sortable?: boolean | false;
    isfilter?: boolean | false; // to display filter icon in header
    pinned?: boolean | 'left' | 'right' | null;
    headerCheckboxSelection?: boolean | false | any;
    checkboxSelection?: boolean | false | any;
    cellClass?: string;
    hide?: boolean;
    cellClassRules?:any;
    cellStyle?:any
    cellRenderer?:any;
    cellRendererParams?:any;
    cellEditor?:any;
    headerComponentFramework?:any;
    suppressMenu?:boolean;
    cellRendererFramework?:any;
    headerComponentParams?:any;
    tooltipField?:any;
    flex?:number;
    uniqueName?:string = '';
   
 }

export class PaginationBaseModel {
    sortColumn   : string='';
    sortDirection: string = '';
    pageNumber   : number = 1;
    pageSize     : number = 25;
    searchText   : string = '';
    getAll       : boolean = false;
}
export class TableHistoryModel extends PaginationBaseModel{
  companyId: string='';
}

export class TableCertificationModel extends PaginationBaseModel{
  vendorId: string='';
}

export class TablePaginationModel extends PaginationBaseModel{
  id        : string='';
  isActive  : boolean=true;
  stage     : string='All';
  filters   : string="";
  exportAsPdf: boolean;
  selectedId: number[];
  companyId : string='';
  categoryId  :string='0'
  filtersFromPopup: string = "";
  companyOrVendorId: string
  documentTypeId: number;
  searchCompanyText : string = ''
}
export class TablePaginationModelContact extends PaginationBaseModel{
  filtersFromPopup  : string = "";
  filters           : string="";
  id                : string='';
}
export class TablePaginationModelVendorList extends PaginationBaseModel{
  stage    : string ="";
  id       : string ="";
}
export class AgGridPaginationConfig {
  rows              : any[] = [];
  totalRecords      : number=0;
  pageSize          : number = 25;
  getAll            : boolean= false;
  searchText        : string = '';
  currentPageNumber : number = 1;
  tableOffset       : number = 0;

  limitOptions = [
    {
      key: '10',
      value: 10
    },
    {
      key: '25',
      value: 25
    },
    {
      key: '50',
      value: 50
    },
    {
      key: '75',
      value: 75
    },
    {
      key: '100',
      value: 100
    },
    {
      key: 'All',
      value: 1
    },
  ];
}

export class UpdateCellData{
  companyId: number;
  operationId: number;
  data: string ;
  // saleStageId: string ;
  // leadScoreId: string ;
  // intentToBuyId: string ;
  // companyIds:string []
}
export class UpdateCellDataIntentToBuy{
  // companyId: number;
   //operationId: number;
   //data: string ;
   saleStageId: string ;
   leadScoreId: string ;
   intentToBuyId: string ;
   companyIds:string [] =[]
 }
 export class UpdateStatus{
   companyId: string = '';
   contactId: string = '';
   active:boolean;
 }
 export class UpdateOverallRatingStatus{
  companyId: string = '';
  overallRating:number;
  currentUrl:string
}
export class UpdateQuickNotes{
  companyId: string = '';
  notes: string = '';
}

 export class UpdateVendorStatus{
  contactId: string = '';
  vendorId: string = '';
  active:boolean;
}

 
export class SaveUserSetting{
    userId      : string;
    userSetting : string;

}
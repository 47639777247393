<div class="container-fluid container-h1">
  <div class="col-12 p-0">&nbsp;</div>
  <div class="row">
    <div class="row container-fluid p-0">
      <div class="col-xl-6 col-md-6">
        <div class="row">
        <div class="col-xl-6">
          <div class="me-4">
            <div class="col p-0 mb-4">
              <app-input
                [inputLabel]="'D & B Number'"
                [inputType]="'text'"
                inputMaxlength="9"
                [inputValue]="  insertUpdateGeneralInfoCompany.detailsInfo.dAndBNumber "
                (inputValueEmit)=" insertUpdateGeneralInfoCompany.detailsInfo.dAndBNumber = $event "
                onlyNumbers>
              </app-input>
            </div>
            <div class="col p-0 mb-4">
              <app-dropdown
                [selectLabel]="'Industry'"
                [data]="industryValues"
                [selectValue]="industryId"
                [disabled] = "industryDisable"
                (selectValueEmit)="industrySelected($event)">
              </app-dropdown>
            </div>
            <div class="col p-0 mb-4">
              <div class="row mx-0">
                <div class="col-xl-6 p-0 col-md-6 pe-2 col-lg-6 requirement">
                  <!-- <app-input
                    [inputLabel]="'SIC Code'"
                    [inputType]="'text'"
                    onlyNumbers
                    isSmallInput="true"
                    [inputValue]="insertUpdateGeneralInfoCompany.detailsInfo.sicCode"
                    (inputValueEmit)="
                      insertUpdateGeneralInfoCompany.detailsInfo.sicCode = $event
                    "
                  >
                  </app-input> -->
                  <app-input-search
                  class="code"
                  [inputLabel]="'SIC Code'"
                  [inputType]="'text'"
                  onlyNumbers
                  isSmallInput="true"
                  [inputValue]="sicCode"
                  (inputValueEmit)="sicSelected($event)"
                  id="typeahead-format"
                  [ngbTypeahead]="searchSicCode"
                  [resultFormatter]="formatter"
                  [inputMaxlength] = "8"
                >
                </app-input-search>
                <div *ngIf="errorMessageSIC" class="font-10 position-absolute text-danger">
                  SIC code is not available.
                </div>
            
                </div>
                <div class="col-xl-6 ps-2 p-0 col-md-6 col-lg-6 requirement">
                  <!-- <app-input
                    [inputLabel]="'NAICS Code'"
                    [inputType]="'text'"
                    onlyNumbers
                    isSmallInput="true"
                    [inputValue]="insertUpdateGeneralInfoCompany.detailsInfo.naicsCode
                    "
                    (inputValueEmit)="
                      insertUpdateGeneralInfoCompany.detailsInfo.naicsCode =
                        $event
                    "
                  >
                  </app-input> -->
                  <app-input-search
                   class="code"
                  [inputLabel]="'NAICS Code'"
                    [inputType]="'text'"
                    onlyNumbers
                    isSmallInput="true"
                    [inputValue]="naicsCode"
                    (inputValueEmit)="naicsSelected($event)"
                  id="typeahead-format"
                  [ngbTypeahead]="searchNAICSCode"
                  [resultFormatter]="formatter"
                  [inputMaxlength] = "6"
                  >
                </app-input-search>
                <div *ngIf="errorMessageNAICS" class="font-10 position-absolute text-danger">
                  NAICS code is not available.
                </div>

                </div>
              </div>
            </div>
            <div class="col p-0 mb-4">
              <app-dropdown
                [selectLabel]="'Scope'"
                [data]="scopeList"
                [selectValue]="insertUpdateGeneralInfoCompany.detailsInfo.scopeId"
                (selectValueEmit)="insertUpdateGeneralInfoCompany.detailsInfo.scopeId = $event
                "
              >
              </app-dropdown>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="me-4">
            <div class="col p-0 mb-4">
              <div class="col p-0 mb-4">
                <!-- <app-dropdown
                  [selectLabel]="'Entity Type'"
                  [data]=""
                  (selectValueEmit)="
                    insertUpdateGeneralInfoCompany.detailsInfo.entityType = $event
                  "
                >
                </app-dropdown> -->
                <app-input
                [inputLabel]="'Entity Type'"
                [inputType]="'text'"
                isSmallInput="true"
                [inputValue]="
                insertUpdateGeneralInfoCompany.detailsInfo.entityType 
                "
                (inputValueEmit)="
                insertUpdateGeneralInfoCompany.detailsInfo.entityType =
                  $event
              "
              >
              </app-input>
  
              </div>
            </div>
            <div class="col p-0 mb-4">
              <div class="row mx-0">
                <div class="col-xl-6 p-0 col-md-6 pe-2 col-lg-6">
                  <app-input
                    [inputLabel]="'Employees'"
                    [inputType]="'text'"
                    onlyNumbers
                    [inputMaxlength]="100"
                    isSmallInput="true"
                    [inputValue]="
                      insertUpdateGeneralInfoCompany.detailsInfo.employee
                    "
                    (inputValueEmit)="
                      insertUpdateGeneralInfoCompany.detailsInfo.employee =
                        $event
                    "
                  >
                  </app-input>
                </div>
                <div class="col-xl-6 ps-2 p-0 col-md-6 col-lg-6">
                  <app-input
                    numberFormat
                    [inputLabel]="'Revenue'"
                    [inputType]="'text'"
                    isSmallInput="true"
                    [inputValue]="insertUpdateGeneralInfoCompany.detailsInfo.revenue
                    "
                    (inputValueEmit)="
                      insertUpdateGeneralInfoCompany.detailsInfo.revenue = $event
                    "
                  >
                  </app-input>
                </div>
              </div>
            </div>
            <div class="col p-0 mb-4">
              <div class="col p-0 mb-4">
                <!-- <app-dropdown
                  [selectLabel]="'Parent Company'"
                  [data]=""
                  (selectValueEmit)="
                    insertUpdateGeneralInfoCompany.detailsInfo.parentCompanyId =
                      $event
                  "
                >
                </app-dropdown> -->
  
                <app-input
                [inputLabel]="'Parent Company'"
                [inputType]="'text'"
                isSmallInput="true"
                [inputValue]="
                insertUpdateGeneralInfoCompany.detailsInfo.parentCompany 
                "(inputValueEmit)="insertUpdateGeneralInfoCompany.detailsInfo.parentCompany =$event"
              >
              </app-input>
              </div>
            </div>
            <div class="col p-0 mb-4">
              <app-dropdown
                [selectLabel]="'Currency'"
                [data]="currencyList"
                [selectValue]="insertUpdateGeneralInfoCompany.detailsInfo.currencyId"
                (selectValueEmit)="
                  insertUpdateGeneralInfoCompany.detailsInfo.currencyId = $event
                "
              >
              </app-dropdown>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="col-xl-6 col-md-6">
        <div class="row">
        <div class="col-xl-6">
          <div class="me-4">
            <div class="col p-0 mb-4">
              <app-input
                [inputLabel]="'Tax ID'"
                [inputType]="'text'"
                [inputMaxlength]="15"
                [inputValue]="insertUpdateGeneralInfoCompany.detailsInfo.taxId"
                (inputValueEmit)="
                  insertUpdateGeneralInfoCompany.detailsInfo.taxId = $event
                "
              >
              </app-input>
            </div>
            <div class="col p-0 mb-4">
              <app-input
                [inputLabel]="'VAT/RFC/CNPJ'"
                [inputType]="'text'"
                [inputMaxlength]="15"
                [inputValue]="
                  insertUpdateGeneralInfoCompany.detailsInfo.vaT_RFC_CNPJ
                "
                (inputValueEmit)="
                  insertUpdateGeneralInfoCompany.detailsInfo.vaT_RFC_CNPJ = $event
                "
              >
              </app-input>
            </div>
            <div class="col p-0 mb-4">
              <app-input
                [inputLabel]="'Reseller ID'"
                [inputMaxlength]="15"
                [inputType]="'text'"
                [inputValue]="
                  insertUpdateGeneralInfoCompany.detailsInfo.resellerId
                "
                (inputValueEmit)="
                  insertUpdateGeneralInfoCompany.detailsInfo.resellerId = $event
                "
              >
              </app-input>
            </div>
            <div class="col p-0 mb-4">
              <!-- <app-input
                [inputLabel]="'Reseller ID Expiration Date'"
                [inputType]="'text'"
                [inputMaxlength]="10"
                [inputValue]="insertUpdateGeneralInfoCompany.detailsInfo.resellerIdExpirationDate
                "(inputValueEmit)="insertUpdateGeneralInfoCompany.detailsInfo.resellerIdExpirationDate =$event">
              </app-input> -->
              <app-date-picker
              style="width: 21%;"
              [time]="false"
              [inputLabel]="'Reseller ID Expiration Date'"
              [inputValue]="resellerDate"
              [minDate]="minDate"
              (inputValueEmit)="resellerDate=$event;reselletId()"
            ></app-date-picker>
            </div>
          </div>
        </div>
        <div class="col-xl-6  mb-4">
          <div class="row" *ngIf="headName == 'Add Vendor'">
            <div class="col-xl-12 mb-4">
              <app-input
                [inputLabel]="'Vendor Specialty'"
                [inputType]="'text'"
                [inputValue]="insertUpdateGeneralInfoCompany.detailsInfo.vendorSpecialty"
                (inputValueEmit)="insertUpdateGeneralInfoCompany.detailsInfo.vendorSpecialty =$event" >
              </app-input>
            </div>
          </div>
          <div class="d-flex flex-column position-relative p-b-10 addNote"
          [style.height]="(headName === 'Add Vendor' || headName === 'Update Vendor') ? '180px' : '100%'" >
            <label class="form-label position-absolute addLabel font-12" for="">
              Notes
            </label>
            <textarea
              name="note"
              rows="10"
              class="form-control pt-1 mx-3 font-13 Textarea"
              [(ngModel)]="insertUpdateGeneralInfoCompany.detailsInfo.notes">
            </textarea>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>

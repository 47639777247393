import { Router } from '@angular/router';
import { ImportDocumentsService } from '../../import-documents.service';
import { ProgressHelperService } from './progress-helper.service';
import { ProgressStepComponent } from './progress-step/progress-step.component';
import { Status, UiHelper } from './uiHelper';
import { AfterContentInit, Component, ContentChildren, ElementRef, Input, OnInit, QueryList, Renderer2 } from '@angular/core';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { CurrentTab } from 'src/app/shared/model/enum';

@Component({
  selector: 'app-step-progressbar',
  templateUrl: './step-progressbar.component.html',
  styleUrls: ['./step-progressbar.component.scss']
})
export class StepProgressbarComponent extends UiHelper implements OnInit, AfterContentInit {
  isPreviewTabCalled : boolean = false;
  shouldDisplayGrid: boolean = false;
  failed = false
    names =[
    'Import',
    'Map',
    'Template',
    'Preview',
    'Errors',
    'Summary'
  ]

  itemlength: number
  @Input() public set selectedIndex(value) {
    this.activeIndex = value || 0
    this.importDocumentsService.indexActive=  this.activeIndex ;
  }
  currentTab:string;
  tabName = CurrentTab;

  @ContentChildren(ProgressStepComponent) public steps: QueryList<ProgressStepComponent>
  constructor(protected helperService: ProgressHelperService,
    private renderer: Renderer2, private elementRef: ElementRef,
    public importDocumentsService: ImportDocumentsService,
    private router: Router,
    public tabsService: TabsService,

    ) {
    super(helperService);
  }

gridSection(index){
  const screenWidth = window.innerWidth;
  if (screenWidth > 1024 && (index == 3 || index == 4 || index == 5)) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'initial');
  } else {
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'grid');
  }
}
// previewTab(){
//   this.importDocumentsService.progressbarValue.subscribe((res)=>{
//     if(res == true){
//       this.isPreviewTabCalled = true;
//       for (let i = 0; i <= 3; i++) {
//         this.itemProgressList[i].status = 'completed';
//       }
//       this.activeIndex = 3;
//       this.importDocumentsService.indexActive= this.activeIndex ;
//       this.switchStatusNext(this.activeIndex)
//       this.setActiveStep(this.activeIndex);
//       this.gridSection(this.activeIndex);
//     }
//   });

// }
// importTab(){
//   this.importDocumentsService.previuosTab.subscribe((res)=>{
//     if(res == true){
//       if(this.isPreviewTabCalled){
//         for (let i = 3; i >= 0; i--) {
//           this.itemProgressList[i].status = ' ';
//         }
//          this.activeIndex = 0;
//          this.importDocumentsService.indexActive= this.activeIndex ;
//          this.switchStatusPrev(this.activeIndex)
//          this.setActiveStep(this.activeIndex);
//          this.gridSection(this.activeIndex);
//       }
//       else{
//         this.prev()
//        }
//    }
//   });
// }
summaryTab(){
  this.importDocumentsService.applyChanges.subscribe((res)=>{
    if(res == true || res?.isSuccess == false){
     this.next();
     this.failed= true;
    }else if(res?.isSuccess == true && res?.response == null){
      this.next();
      this.itemProgressList[5].status = 'completed';
    }
  });
}
  ngOnInit(): void {
    this.tabsService.currentTab.subscribe(res => {
      if (res !== undefined) {
        this.currentTab = res;
      } 
    })

    this.summaryTab();
    // this.previewTab();
    // this.importTab();
    this.failed= false;


    this.gridSection(this.activeIndex);
    this.progresshelper.eventHelper.subscribe({
      next: ({ prev, next }) => {
        if (next) {
          this.next();
          this.isPreviewTabCalled = false;
        }

        if (prev) {
          this.prev()
        }
      }
    })
  }
  public prev() {
    this.decreaseStep()
  }
  decreaseStep() {
    if (this.activeIndex === this.itemlength - 1 &&
      this.itemProgressList[this.activeIndex].status === Status.COMPLETED) {
      this.undoLastComplete()
    }else{
      if(this.activeIndex > 0){
        this.activeIndex--
        this.importDocumentsService.indexActive= this.activeIndex;
        this.switchStatusPrev(this.activeIndex)
        this.setActiveStep(this.activeIndex);
        this.gridSection(this.activeIndex);

      }
    }
  }
  public next() {
    this.increaseStep()
  }
  increaseStep() {
    if (this.activeIndex === this.itemlength - 1 &&
      this.itemProgressList[this.activeIndex].status !== Status.COMPLETED) {
      this.completeLastStep()
    }
    if (this.activeIndex < this.itemlength - 1) {
      this.activeIndex++
      this.importDocumentsService.indexActive= this.activeIndex;
      this.switchStatusNext(this.activeIndex)
      this.setActiveStep(this.activeIndex);
      this.gridSection(this.activeIndex);

    }
  }

  ngAfterContentInit(): void {
    this.initPorgress(this.progressStep.length)
    this.setActiveStep(this.activeIndex)
    this.initStepIndex()
  }
  private setActiveStep(activeIndex: number) {
    if (this.stepExists) {
      this.removeActiveStep()
      this.updatedActiveStep(activeIndex)
    }
  }
  removeActiveStep() {
    this.progressStep.map(step => {
      if (step.isActive) {
        step.isActive = false
      }
    })
  }
  updatedActiveStep(index: any) {
    this.progressStep[index].activeState = this.progressStep[index]
  }

  private initStepIndex() {
    this.progressStep.forEach((step, i) => (step.stepIndex = i))
  }
  public get activeStep(): ProgressStepComponent {
    return this.progressStep[this.activeIndex]
  }

  private get progressStep(): ProgressStepComponent[] {
    return this.steps.toArray()
  }
  private get stepExists(): boolean {
    return this.progressStep && Array.isArray(this.progressStep)
  }
  private initPorgress(value) {
    this.itemlength = value || 0
    this.itemProgressList = this.generatedProgressArray(this.itemlength);
  }
  generatedProgressArray(length: number): { stepIndex: number; status: string; names:string[] }[] {
    return [...Array(length).keys()].map(key => {
      return {
        stepIndex: key,
        status: key === this.activeIndex ? Status.IN_PROGRESS : Status.PENDING,
        names:this.names
      }
    })
  }
}

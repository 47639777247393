import { Component, OnInit, ViewChild } from '@angular/core';
import { AgCustomHeaderComponent } from 'src/app/shared/components/ag-grid/ag-custom-header/ag-custom-header.component';
import { AgGridI, AgGridPaginationConfig } from 'src/app/shared/components/ag-grid/ag-grid.model';
import { AgGridImportCodecrosswalkMap, AgGridImportCompaniesMap, AgGridImportVendorMap } from "../map-tab/map-tab.model";
import { ImportDocumentsService } from '../../import-documents.service'
import { InsertUpdateImportDocumnets } from '../../import-documents.model';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Button, CurrentTab, RoutePath } from 'src/app/shared/model/enum';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { CodeCrosswalkService } from 'src/app/components/admin/code-crosswalk/code-crosswalk.service';

@Component({
  selector: 'app-map-tab',
  templateUrl: './map-tab.component.html',
  styleUrls: ['./map-tab.component.scss']
})
export class MapTabComponent implements OnInit {
  enumButtonName = Button
  insertUpdateImportDocumnets = new InsertUpdateImportDocumnets();
  agGridPaginationConfig = new AgGridPaginationConfig();
  dataModel: AgGridI;
  private gridApi;
  private gridColumnApi;
  public components: {
    [p: string]: any;
  } = {
      agColumnHeader: AgCustomHeaderComponent,
    }
  sub: Subscription
  required = ["Company", "Stage", "Type", "Country" ];
  requiredVendor = ["Vendor Name","Type", "Country" ]

  rowData = [];
  flexHeight;
  isRecall: boolean = false;
  columnList: [];
  mapList = [];
  codecrosswalkSystemColumns = ["SIC Code","SIC Description","NAICS Code","NAICS Year","NAICS Description","Industry" ]
  staticSystemColumns = [ 
  "Company",
  "Rating",
  "Stage",
  "Type",
  "Phone",
  "Fax",
  // "Salesperson",
  "Website",
  // "Email",
  "Employees",
  "Active",
  // "Date Contacted",
  "Timezone",
  "Industry",
  "SIC",
  "NAICS",
  "Country",
  "Do Not Call",
  "Revenue",
  // "Alternate Salesperson",
  "Recent Notes",
  // "Account Number",
  "Currency",
  "Company Email",
  "D&B Number",
  // "Primary Contact Name",
  // "Primary Contact Phone",
  // "Accounting Contact Name",
  // "Accounting Contact Phone Number",
  // "Accounting Contact Email Address",
  "YTD Sales",
  "State",
  "Parent Company",
  "Entity Type",
  "Tax ID",
  "Tags"	
  
];
staticVendorSystemColumns = ['Vendor Name','Type','Email Address','Phone Number','Fax Number','State','Country','Time Zone','Tags','Rating','Address 1','Address 2'];
  overlayNoRowsTemplate = '<div class="ag-overlay-no-rows-center">No Records Available</div>';
  currentTab:string;
  sourceId: number=1;
  routeUrl:string;
  routePath = RoutePath;
  tabName = CurrentTab;

  constructor(
    public importDocumentsService: ImportDocumentsService,
    private router: Router,
    public toaster: ToastrService,
    public commonService: CommonService,
    public tabsService: TabsService,
    public codeCrosswalkService     :CodeCrosswalkService,

  ) {
    this.routeUrl = this.router.url;
    this.sub = this.importDocumentsService.dataPassAccordingToTrackingBar.subscribe(res => {
      if (this.importDocumentsService.indexActive == 1) {
        this.insertUpdateImportDocumnets = this.importDocumentsService.dataPassAccordingToTrackingBar.getValue();
        this.getMapToList()
      }
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  ngOnInit(): void {
    this.tabsService.currentTab.subscribe(res => {
      if (res !== undefined) {
        this.currentTab = res;
      } 
    })

    this.importDocumentsService.mapValidation.next(true);
    this.commonService.headerHeight.subscribe(res => {
      this.flexHeight = "calc(100vh - 280px - " + res + "px" + ")"
    })
if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
  this.dataModel = AgGridImportCodecrosswalkMap;
  this.fillList()
}else if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
  this.dataModel = AgGridImportVendorMap;
}else{
  this.dataModel = AgGridImportCompaniesMap;
}
    // this.getMapToList()
  }

  getMapToList() {
    if (this.currentTab == this.tabName.CODECROSSWALK_TAB) {
      if (this.insertUpdateImportDocumnets?.importCodeCrosswalkModel?.file) {
        const data = { file: this.insertUpdateImportDocumnets?.importCodeCrosswalkModel?.file ?? '' }
        this.importDocumentsService.mapToList(data).subscribe(res => {
          this.mapList = res?.response ?? [];
          if (this.insertUpdateImportDocumnets?.importCodeCrosswalkModel?.mappingTemplateId) {
            this.fillListById()
          } else {
            this.fillList();
          }
        })
      }
    } else if (this.insertUpdateImportDocumnets?.importCompanyModel?.file) {
      const data = { file: this.insertUpdateImportDocumnets?.importCompanyModel?.file ?? '' }
      this.importDocumentsService.mapToList(data).subscribe(res => {
        this.mapList = res?.response ?? [];
        if (this.insertUpdateImportDocumnets?.importCompanyModel?.mappingTemplateId) {
          this.fillListById()
        } else {
          this.fillList();
        }
      })
    }
  }

  fillList() {
 
    this.importDocumentsService.getImportCompanyDropDownList().subscribe(res => {
      this.isRecall = res.isSuccess;
      this.insertUpdateImportDocumnets.tempTemplateMappingColumns='';
      if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
        this.rowData = this.codecrosswalkSystemColumns.map(systemColumn => ({ systemColumn }));
        this.rowData.map((a) => {
          a.map = this.mapList;
          a.templateId = a?.templateId ?? '';
          a.id = a?.id ?? '';
          a.status = false;
          a.mappedColumn = '';
        })
        this.importDocumentsService.submit = this.importDocumentsService.checkRequiredValueCodecrosswalk(this.rowData)
      }else{
        if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
          this.rowData = this.staticVendorSystemColumns.map(systemColumn => ({ systemColumn }));
        }else{
          this.rowData = this.staticSystemColumns.map(systemColumn => ({ systemColumn }));
        }
        this.rowData.map((a) => {
          a.map = this.mapList;
          a.templateId = a?.templateId ?? '';
          a.id = a?.id ?? '';
          a.status = false;
          a.mappedColumn = '';
        })
        if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
          this.importDocumentsService.submit = this.importDocumentsService.checkVendorRequiredValue(this.rowData)
        }else{
          this.importDocumentsService.submit = this.importDocumentsService.checkRequiredValue(this.rowData)
        }
      }
    })
  }

  fillListById(){
    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){this.sourceId=3}
    else if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){this.sourceId=2}
    this.importDocumentsService.getImportCompanyTemplateById(this.currentTab == this.tabName.CODECROSSWALK_TAB?(this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId):(this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId),this.sourceId)
    .subscribe(res=>{
      this.insertUpdateImportDocumnets.tempTemplateMappingColumns='';
      this.rowData= res?.response?.templateMappingColumns;
      this.rowData.map((a)=>{
        a.map=this.mapList
      });
      if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
        this.rowData.map((a) => {
          if (this.codecrosswalkSystemColumns.includes(a.systemColumn)) {
            a.isRequired = true;
          } else {
            a.isRequired = false;
          }
        })
      }else if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
        this.rowData.map((a) => {
          if (this.requiredVendor.includes(a.systemColumn)) {
            a.isRequired = true;
          } else {
            a.isRequired = false;
          }
        })
      }
      else{
        this.rowData.map((a) => {
          if (this.required.includes(a.systemColumn)) {
            a.isRequired = true;
          } else {
            a.isRequired = false;
          }
        })
      }
      this.insertUpdateImportDocumnets.tempTemplateMappingColumns= JSON.stringify(this.rowData);
      this.insertUpdateImportDocumnets.templateData= res?.response;
      if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
        this.importDocumentsService.submit=  this.importDocumentsService.checkRequiredValueCodecrosswalk(this.rowData);
      }else{
        if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
          this.importDocumentsService.submit=  this.importDocumentsService.checkVendorRequiredValue(this.rowData);
        }else{
          this.importDocumentsService.submit=  this.importDocumentsService.checkRequiredValue(this.rowData);
        }
      }
    })
  }

  close(){ 
    this.codeCrosswalkService.valuePopup.next(true)
   }
  
  passDataToDetails() {
//  {
    if (this.importDocumentsService.submit) {
      this.isRecall = false;
      const list = [];
      this.gridApi.forEachNode((a) => {
        list.push(a.data)
      });
      list.map((b) => {
        if (b.mappedColumn === b.systemColumn) {
          b.status = true
        }
      })
      if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
        list.map((a) => {
          if (this.codecrosswalkSystemColumns.includes(a.systemColumn)) {
            a.isRequired = true;
          } else {
            a.isRequired = false;
          }
        })
      }
      else if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
        list.map((a) => {
          if (this.requiredVendor.includes(a.systemColumn)) {
            a.isRequired = true;
          } else {
            a.isRequired = false;
          }
        })
      }else{
        list.map((a) => {
          if (this.required.includes(a.systemColumn)) {
            a.isRequired = true;
          } else {
            a.isRequired = false;
          }
        })
  
      }
      this.insertUpdateImportDocumnets.templateData.templateMappingColumns=list;
      this.importDocumentsService.dataPassAccordingToTrackingBar.next(this.insertUpdateImportDocumnets)
    } else {
      this.importDocumentsService.mapValidation.next(false);
      this.toaster.error("Please insert required field");
    // }
  }
  }

  onSelectionChanged($event) { }


  closeTab(){
    if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
      this.importDocumentsService.closeVendorTab();
      this.router.navigate([this.routePath.VENDORLIST_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.VENDORLIST_TAB)
    }else{
      this.importDocumentsService.closeTab();
      this.router.navigate([this.routePath.COMPANY_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.COMPANY_TAB)
    }
  }

}

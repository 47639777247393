<button id="hidden" class="position-absolute top-0 end-0 opacity-0"></button>

<div *ngIf="!shouldPhoneFormat" class="form-floating">
  <input tabNext
    [type]="inputType"
    class="form-control"
    id="floatingInput"
    autocomplete="off"
    [(ngModel)]="inputValue"
    [appInputBogieFormat]="shouldBogieFormat"
    (ngModelChange)="inputValueEmit.emit(inputValue)"
    placeholder="name@example.com"
    [required]="inputReqValidation"
    [maxlength]="inputMaxlength"
    [readonly]="inputReadonly"
    [pattern]="passwordPattern()"
    [disabled]="isInputDisabled"
    (keydown)="onKeydownSearchLedger($event)"
    [ngbTypeahead]="ngbTypeahead"
    [resultFormatter]="resultFormatter"
    (blur)="clearInputText($event)"
    (selectItem)="handleSelectItem($event)"

  />
  <div [ngClass]="{'showImage': isValid, 'hideImage': !isValid}"><img *ngIf="isSvgVisible"  src="../../../../assets/SVGIcons/lock.svg" alt=""></div>

  <!-- <div *ngIf="passwordFormat" class="invalid-feedback">
            Password must contains at least 8 characters including uppercase letters, lowercase letters, numbers, and symbols 
           </div> -->
  <div
    *ngIf="(passwordFormat && inputValue) || inputDefaultShow"
    class="invalid-feedback"
  >
    {{
      showHide == true
        ? "Password must contains at least 8 characters including uppercase, lowercase letters, numbers, and symbols"
        : "
            Password must contains at least 8 characters including uppercase, lowercase letters, numbers, and symbols "
    }}
  </div>

  <label for="floatingInput" class="label-transition d-flex align-items-center "
    >{{ inputLabel
    }}<i *ngIf="inputReqValidation" class="fst-normal ps-1 p-0 text-danger"
      >*</i
    ></label
  >
  <span
    [ngClass]="{'invisible':!inputValue}"
    (click)="clearInput(); inputValueEmit.emit(inputValue)"
    class="position-absolute icon-con top-0 start-0 hand  {{
      inputValue == 'Admin' ? 'pe-None' : ''
    }}"
    ><app-svg-icon
      class="svgIcon"
      [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
    ></app-svg-icon
  ></span>
  <i
    *ngIf="(inputLabel.includes('Password') && inputValue) || inputDefaultShow"
    (click)="
      showHide = !showHide;
      showHide == true ? (inputType = 'password') : (inputType = 'text')
    "
    class="position-absolute fst-normal hand font-9 top-0 end-0 text-light-gray"
    >{{ showHide == true ? "show" : "hide" }}</i
  >
</div>
<div *ngIf="shouldPhoneFormat" class="form-floating">
  <input
    [type]="inputType"
    class="form-control"
    id="floatingInput"
    autocomplete="off"
    [(ngModel)]="inputValue"
    mask="0000-0000-0000" 
    [appInputBogieFormat]="shouldBogieFormat"
    (ngModelChange)="inputValueEmit.emit(inputValue)"
    placeholder="name@example.com"
    [required]="inputReqValidation"
    [maxlength]="inputMaxlength"
    [readonly]="inputReadonly"
    [pattern]="passwordPattern()"
  />

  <!-- <div *ngIf="passwordFormat" class="invalid-feedback">
            Password must contains at least 8 characters including uppercase letters, lowercase letters, numbers, and symbols 
           </div> -->
  <div
    *ngIf="(passwordFormat && inputValue) || inputDefaultShow"
    class="invalid-feedback"
  >
    {{
      showHide == true
        ? "Password must contains at least 8 characters including uppercase, lowercase letters, numbers, and symbols"
        : "
            Password must contains at least 8 characters including uppercase, lowercase letters, numbers, and symbols "
    }}
  </div>

  <label for="floatingInput" class="label-transition d-flex align-items-center "
    >{{ inputLabel
    }}<i *ngIf="inputReqValidation" class="fst-normal ps-1 p-0 text-danger"
      >*</i
    ></label
  >
  <span
  style="  padding: 1.4em 0.8em;"
    [ngClass]="{'invisible':!inputValue}"
    (click)="clearInput(); inputValueEmit.emit(inputValue)"
    class="icon-con position-absolute top-0 start-0 hand {{
      inputValue == 'Admin' ? 'pe-None' : ''
    }}"
    ><app-svg-icon
      class="svgIcon"
      [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
    ></app-svg-icon
  ></span>
  <i
    *ngIf="(inputLabel.includes('Password') && inputValue) || inputDefaultShow"
    (click)="
      showHide = !showHide;
      showHide == true ? (inputType = 'password') : (inputType = 'text')
    "
    class="position-absolute fst-normal hand font-9 top-0 end-0 text-light-gray"
    >{{ showHide == true ? "show" : "hide" }}</i
  >
</div>

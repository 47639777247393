import { Enum } from "./enum";

export class Common {
}

export class PaginationBaseModel {
    sortColumn   : string='';
    sortDirection: string = '';
    pageNumber   : number = 1;
    pageSize     : number = 10;
    searchText   : string = '';
    getAll       : boolean = false;
}

export class CommonDropdown {
    id                  : number
    countryList         : CommonDropList[];
    timezoneList        : CommonDropList[];
    stateList           : CommonDropList[];
    scopeList           : CommonDropList[];
    salesPersonList     : CommonDropList[];
    altSalesPersonList  : CommonDropList[];
    buyerRepList        : CommonDropList[];
    classList           : CommonDropList[];
    tagsList            : CommonDropList[];
    typeList            : CommonDropList[];
    industryList        : any[];
    stageStatusList     : CommonDropList[];
    sicCodeList         : CommonDropList[];
    naicsCodeList       : CommonDropList[];
    leadsList           : CommonDropList[];
    intendToBuyList     : CommonDropList[];
    genderList          : CommonDropList[];
    startRating         : number
    endRating           : number
    fromDate            : string
    toDate              : string
    companyStatusId     : number
    archived            : boolean
  }

  export class CommonDropList {
    id                  : string;
    name                : string;
    labelName           : string;
    colorCode           : string;
    isActive            : boolean;
  }

  export enum AddActivitTitle 
  {
    ADDMULTIPLECOMPNYTASK = 'Add Multiple Company Task',
    ADDSINGLETASK         = 'Add Single Task ',
    ADDMULTIPLECONTACTTASK = 'Add Multiple Contact Task'
  }
  


<div class="container-fluid  container-box">
  <app-duplicates-tab *ngIf="isDuplicate" [potentialError]="potentialError" (navToError)="reloadData($event)"></app-duplicates-tab>
  <app-preview-tab *ngIf="isPreviewTabOpen"></app-preview-tab>
</div>
<div *ngIf="defaultView" class="container-fluid  container-box">
  <div  *ngIf="importError" [style]="{'height': flexHeight}">
    <div class="row">
      <div class="wrapper">
        <div class="footer-box justify-content-start">
          <span class="text-semi-primary no-error">No errors detected.</span>
      </div>
    </div>
   </div>
  </div>
  <ag-grid-angular 
  *ngIf="!importError"
  [style]="{'height': flexHeight}"
  class=" ag-theme-alpine"
  [rowData]="rowData"
  [components]="components"
  [rowSelection]="dataModel.rowSelection"
  [suppressRowClickSelection]="true"
  [suppressDragLeaveHidesColumns]="true"
  [columnDefs]="dataModel.colDefs"
  [pagination]="true"
  (selectionChanged)="onSelectionChanged($event)"
  [suppressPaginationPanel]="true"
  [defaultColDef]="defaultColDef"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
>
</ag-grid-angular>


<div class="row position-relative mt-3">
  <div *ngIf="!importError" class="col-4 position-absolute">
    <div class="font-11 text-red fw-bold">You have {{rowData?.length}} errors</div>
    <div class="font-11 text-light-gray">Error rows do not get imported.</div>
  </div>
  <div class="wrapper">
    <div class="footer-box">
      <app-button *ngIf="currentTab !== tabName.CODECROSSWALK_TAB"
      class="px-2"
      [button_2]="true"
      [buttonClass]="'button button-info mx-0'"
      [buttonLable]="enumButtonName.CANCEL"
      (click)="closeTab()"
      >
     </app-button>
     <app-button *ngIf="currentTab === tabName.CODECROSSWALK_TAB"
     class="px-2"
     [button_2]="true"
     [buttonClass]="'button button-info mx-0'"
     [buttonLable]="enumButtonName.CANCEL"
     (click)="close()"
     >
    </app-button>
     <!-- <app-button
     *ngIf="isDuplicate"
       progressStepNext
       class="ps-2 pe-10"
       [button_2]="true"
       [buttonClass]="'button  dark-info mx-0'"
       [buttonLable]="enumButtonName.APPLYCHANGES"
       (click)="applyChanges()"
       >
     </app-button> -->
     <button #prevCallButton id="prevCall" progressStepPrev (click)="previousCall();" class="button dark-info">Previous</button>

     <!-- <app-button
     #prevCall
     progressStepPrev
    class="px-2"
    [button_2]="true"
    [buttonClass]="'button  dark-info mx-0'"
    [buttonLable]="enumButtonName.PREVIOUS"
    (click)="previousCall(); "
   >
   </app-button> -->
     <app-button
       class="ps-2 pe-10"
       [button_2]="true"
       [buttonClass]="importDocumentsService.submit?'button button-primary mx-0':'button  dark-info mx-0'"
       [buttonLable]="enumButtonName.NEXT"
       [inputReadonly]="buttonDisabled"
       (click)="duplicateCall(); "
       >
     </app-button>
    </div>
  </div>
 </div>
</div>

import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { Component, OnInit } from '@angular/core';
import { AgGridColumnsSettingService } from './ag-grid-columns-setting.service';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { ColDef, ColumnApi, GridApi, RowDragLeaveEvent } from 'ag-grid-community';
import { SidebarCellComponent } from './sidebar-cell/sidebar-cell.component';
// import { TabsService } from '../tabs/tabs.service';
import { StorageService } from '../../services/storage.service';
import {  UserSetting } from 'src/app/components/crm/crm.model';
import {GridName} from '../../../shared/model/ag-grid-common-constant'

import { AgGridI, SaveUserSetting } from '../ag-grid/ag-grid.model';
import { DisplayName } from '../../model/enum';
declare var window: any;

@Component({
  selector: 'app-ag-grid-columns-setting',
  templateUrl: './ag-grid-columns-setting.component.html',
  styleUrls: ['./ag-grid-columns-setting.component.scss']
})
export class AgGridColumnsSettingComponent implements OnInit {
  enumParams = DisplayName;
  dataModel: any;
  customizer: string = "";
  //#region initializations
  sidebarGridApi!: GridApi;
  sidebarGridColumnApi: ColumnApi;
  initialSideRowData: any[] = [];
  sideRowData: any[] = [];
  userSetting = new UserSetting();
  saveUserSetting = new SaveUserSetting();
  gridName = GridName
  currentTab = ''
  //#endregion
  contacts = false
  indexToSKip = 2;
  searchData: string = ''
  //#region column Defination initialization
  public sideColumnDefs: ColDef[] = [
    {

      field: "columns",
      cellRenderer: SidebarCellComponent,
      // rowDrag: true,
      rowDrag: function (params) {
        return params.data.columns != "Requirement Number" && params.data.columns != "Company" && params.data.columns != 'Full Name' && params.data.columns != 'Contact Name'&& params.data.columns != 'Vendor Contact Name'  && params.data.uniqueName != 'vendorName';
      },
    }
  ];
  public sideDefaultColDef: ColDef = {
    width: 170,
    autoHeight: true,
    sortable: true,
    filter: true,
  };

  //#endregion

  public icons: {
    [key: string]: Function | string;
  } = {
      rowDrag: ` <img 
    src="./assets/SVGIcons/Gridicon.svg" 
    alt="" 
    width="13" 
    height="12">`,
    };
  overlayNoRowsTemplate = '<div class="ag-overlay-no-rows-center">No Records Available</div>';

  constructor(
    public agGridService: AgGridService,
    public tabService: TabsService,
    public storageService: StorageService,
    public agGridColumnsSettingService: AgGridColumnsSettingService,
  ) { }

  ngOnInit(): void {
    this.agGridService.dataInput.subscribe((res =>{
      if(res){
        this.dataModel = res;
      }
    }))
    this.agGridColumnsSettingService.sidebarRow.subscribe((res) => {
      this.hideShowColumns(res[0].checked, res[0].name);
    });

    this.agGridColumnsSettingService.isCustomizerLayoutOpen.subscribe(
      (res) => {
        this.customizer = res;
      }
    );
    this.tabService.currentTab.subscribe(res => {
      this.currentTab = res
    })
    if (!this.currentTab) {
      this.currentTab = this.tabService.thisTab

    }
    if (this.currentTab === 'contact-recycle-bin' || this.currentTab == 'contacts') {
      this.indexToSKip = 1;
    }
    if (this.currentTab == 'vendor-list') {
      this.indexToSKip = 3;
    }
    if (this.currentTab === 'requirements') {
      this.indexToSKip = 0;
    }

  }

  //#region onGridReady
  sideOnGridReady(params) {


    this.sidebarGridApi = params.api;
    this.sidebarGridColumnApi = params.columnApi;
    this.agGridService.onColumnDragStopped.subscribe((event) => {
      this.getUpdatedColumnDefination();
      // if (event) {
      //   this.getUpdatedColumnDefination();
      // } 
      // else {
      //   this.setColDefinationToRows(null);
      // }
    });
  }
  //#endregion

  //#region getupdatedColumnDefination
  getUpdatedColumnDefination() {
    var columnsData = this.agGridService.getGridColumnApi().getAllGridColumns();
    this.setColDefinationToRows(columnsData);
  }
  //#endregion

  //#region setColDefinationToRows
  setColDefinationToRows(value) {
    if (value != null) var columns = value;
    else columns = this.agGridService.getGridColumnApi().getColumns();
    var sideRowData1: any[] = [];

    columns
      .map((item) => {
        return {
          columns: item.colDef.headerName,
          checked: item.visible,
          uniqueName: item.colDef.uniqueName
        };
      })
      .forEach((item) => sideRowData1.push(item));
    this.sideRowData = sideRowData1.filter(
      (item) =>
        item.columns != undefined &&
        item.columns != "" &&
        item.columns != "Icons"
    );
    this.initialSideRowData = sideRowData1.filter(
      (item) =>
        item.columns != undefined &&
        item.columns != "" &&
        item.columns != "Icons"
    );

  }
  //#endregion
  onFilterTextBoxChanged() {
    this.searchData = (document.getElementById("filter-text-box") as HTMLInputElement)
      .value;
    if (!this.searchData) {
      this.resetToDefault(false);
    } else {
      this.getUpdatedColumnDefination();
      this.sideRowData = this.initialSideRowData.filter((value) => {
        return value.columns?.toLowerCase().startsWith(this.searchData?.toLowerCase());
      });
    }
  }

  onRowDragLeave(event: RowDragLeaveEvent) {
    this.resetToDefault(false);
    if (this.searchData) {
      this.sideRowData = this.initialSideRowData.filter((value) => {
        return value.columns?.toLowerCase().startsWith(this.searchData?.toLowerCase());
      });
    }
  }

  onRowDragEnd(event: RowDragLeaveEvent) {
    let isSearched = false;
    let name = event.overNode.data.columns;
    let eventValue = event.overIndex + 1;
    let eventValue1 = event.overIndex - 2;
    console.log(eventValue);


    if ((this.searchData.includes('c') || this.searchData.includes('C')) && this.currentTab != 'contacts') {
      isSearched = true
    }
    if ((this.searchData.includes('f') || this.searchData.includes('F')) && this.currentTab == 'contacts') {
      isSearched = true
    }

    if (this.searchData) {
      if (name != "Company" && name != "Requirement Number" && event.overIndex == 0 && this.currentTab != 'contacts' && isSearched || name != 'Full Name' && event.overIndex == 0 && this.currentTab == 'contacts' && isSearched) {
        this.resetToDefault(false);
      } else {
        if (event.overIndex == 0) {
          this.indexToSKip = 3
        } else {
          this.indexToSKip = 2
        }
        this.agGridService
          .getGridColumnApi()
          .moveColumn(this.getSidebarFeilds(name), event.overIndex + this.indexToSKip);
      }
    } else {
      if (name != "Company" && name != "Requirement Number" && event.overIndex == 0 && this.currentTab != 'contacts' || name != 'Full Name' && event.overIndex == 0 && this.currentTab == 'contacts') {
        this.resetToDefault(false);
      } else if (this.currentTab === 'company-recycle-bin' || this.currentTab === 'companies' || this.currentTab === 'leads' || this.currentTab === 'prospects'|| this.currentTab === 'customers') {
        this.agGridService.getGridColumnApi().moveColumn(this.getSidebarFeilds(name), eventValue + this.indexToSKip);
      }else if (this.currentTab === 'vendor-contact' ) {
        this.agGridService.getGridColumnApi().moveColumn(this.getSidebarFeilds(name), eventValue1 + this.indexToSKip);
      }
      else {
        this.agGridService
          .getGridColumnApi()
          .moveColumn(this.getSidebarFeilds(name), event.overIndex + this.indexToSKip);
      }
    }
    this.updateUserSetting();
    this.getAllRows()
  }

  getAllRows() {
    let rowData = [];
    this.sidebarGridApi.forEachNode(node =>
      rowData.push(node.data)
    );
    if(this.sideRowData.length < rowData.length){
      this.resetToDefault(false)
    }
  }
  columnSave(){
    const data = this.agGridService.getGridApi().getColumnDefs();

    window.colState = data; // For getting Column State
    this.storageService.store(this.dataModel, window.colState);

  }
  updateUserSetting() {
    // this.sideRowData =  Array.from(new Set(this.sideRowData));
    const data = this.agGridService.getGridApi().getColumnDefs();
    let currentTab = ''
    this.tabService.currentTab.subscribe(res => {
      currentTab = res
    })
    if (!currentTab) {
      currentTab = this.tabService.thisTab
    }
    this.userSetting = this.storageService.retrieve("userSetting")
    if (currentTab == this.gridName.LEADS) {
      this.userSetting.leads = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.COMPANIES) {
      this.userSetting.companies = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.CUSTOMERS) {
      this.userSetting.customers = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.PROSPECTS) {
      this.userSetting.prospects = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.CONTACTS) {
      this.userSetting.contacts = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.VENDORLISTS) {
      this.userSetting.vendorLists = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.CONTACTRECYCLE) {
      this.userSetting.contactRecycle = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.COMPANYRECYCLE) {
      this.userSetting.companiesRecycle = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }
    if (currentTab == this.gridName.REQUIREMENTS) {
      this.userSetting.requirementLists = data.map((a: any, index: number) => {
        return { name: a.field == undefined ? '' : a.field, index: index + 1 }
      })
    }

    this.saveUserSetting.userSetting = JSON.stringify(this.userSetting);
    this.agGridService.saveUserSetting(this.saveUserSetting).subscribe(res => {
      const json = JSON.parse(res?.response);
      this.storageService.store("userSetting", json)
    })
  }

  getSidebarFeilds(name: string) {
    var headerNameList = this.agGridService
      .getGridApi()
      .getColumnDefs()
      .map((item) => {
        return {
          headerName: item.headerName,
          field: item.field,
        };
      });
    return headerNameList.find((a) => a.headerName == name).field;
  }

  resetToDefault(isResetButtonClicked) {
    this.searchData = '';
    if (isResetButtonClicked) {
      (document.getElementById("filter-text-box") as HTMLInputElement).value = "";
      this.agGridService.getGridColumnApi().resetColumnState();
      this.setColDefinationToRows(null);
      // this.initialSideRowData.map(data => data.checked = true);
      if (this.currentTab === 'vendor-list') {
        return this.isVendorListConditionMet();
      } else if (this.currentTab === 'company-recycle-bin' || this.currentTab === 'companies') {
        return this.isCompanyConditionMet();
      }else if (this.currentTab === 'leads') {
        return this.isLeadConditionMet();
      }else if (this.currentTab === 'prospects') {
        return this.isProspectConditionMet();
      }
      else if (this.currentTab === 'contact-recycle-bin' || this.currentTab === 'contacts' || this.currentTab === 'vendor-contact') {
        return this.isContactConditionMet();
      }else if (this.currentTab === 'requirements') {
        return this.isRequirementConditionMet();
      } else {
        return this.defaultList();
      }
  
    }
    else {
      this.getUpdatedColumnDefination();
    }
    if (this.currentTab == 'contacts' && isResetButtonClicked || this.currentTab == 'contact-recycle-bin' && isResetButtonClicked) {
      this.sideRowData = [this.sideRowData[1], this.sideRowData[0], ...this.sideRowData.slice(2)];
    }
    this.sidebarGridApi.setRowData([...this.initialSideRowData]);
    this.updateUserSetting();
  }

  hideShowDefaultColumns(initialSideRowData) {
    initialSideRowData.forEach((element: any) => {
      this.agGridService
    .getGridColumnApi()
    .setColumnVisible(this.getSidebarFeilds(element.columns), element.checked);
  // this.sideRowData.map(value => {  
  //   if (value.columns == element.columns) {
  //     value.checked = element.checked
  //   }
  // })
    });
    const columnState = this.agGridService.getGridColumnApi().getColumnState();
    this.storageService.store(this.dataModel, columnState);

  }

  hideShowColumns(event, columnName) {
    if (!event) {
      this.agGridService
        .getGridColumnApi()
        .setColumnVisible(this.getSidebarFeilds(columnName), false);
      this.sideRowData.map(value => {
        if (value.columns == columnName) {
          value.checked = false
        }
      })
      const columnState = this.agGridService.getGridColumnApi().getColumnState();
      this.storageService.store(this.dataModel, columnState);
  
    } else {
      this.agGridService
        .getGridColumnApi()
        .setColumnVisible(this.getSidebarFeilds(columnName), true);
      this.sideRowData.map(value => {
        if (value.columns == columnName) {
          value.checked = true
        }
      })
      const columnState = this.agGridService.getGridColumnApi().getColumnState();
      this.storageService.store(this.dataModel, columnState);
  
      this.sidebarGridApi.setRowData([...this.sideRowData]);
      this.updateUserSetting();
   
    }
  }

  Customizer(val) {
    this.customizer = val;
    this.agGridColumnsSettingService.isCustomizerLayoutOpen.next(val);
    // this.commonService.isSidebarOpened.subscribe((isOpened) => {
    //   this.isSidebarOpened = isOpened;
    // });
  }

  ngOnDestroy() {
    this.agGridColumnsSettingService.isCustomizerLayoutOpen.next('');
  }
  isRequirementConditionMet(){
    this.initialSideRowData.map(data => ( data.columns == this.enumParams.REQUIREMENT ||
      data.columns == this.enumParams.REQDATE ||
      data.columns == this.enumParams.BUYTARGET ||
      data.columns == this.enumParams.TARGETPRICE 
    )? data.checked = true : data.checked = false);
    this.hideShowDefaultColumns(this.initialSideRowData)    
  }

  isVendorListConditionMet() {
       this.initialSideRowData.map(data => ( data.columns == this.enumParams.VENDOR_NAME ||
        data.columns === this.enumParams.Active ||
        data.columns === this.enumParams.RATING ||
        data.columns === this.enumParams.PHONE ||
        data.columns === this.enumParams.VENDORTYPE ||
        data.columns === this.enumParams.AVL ||
        data.columns === this.enumParams.QUICKNOTE
      )? data.checked = true : data.checked = false);
      this.hideShowDefaultColumns(this.initialSideRowData)    

  }
  isProspectConditionMet() {
    this.initialSideRowData.map(data => (data.columns == this.enumParams.COMPANY ||
      data.columns === this.enumParams.STAGE ||
      data.columns === this.enumParams.Active ||
      data.columns === this.enumParams.RATING ||
      data.columns === this.enumParams.TYPE ||
      data.columns === this.enumParams.PHONE ||
      data.columns === this.enumParams.INTENTTOBUY ||
      data.columns === this.enumParams.DATECONTACTED ||
      data.columns === this.enumParams.REVENUE ||
      data.columns === this.enumParams.QUICKNOTE
   )? data.checked = true : data.checked = false);
   this.hideShowDefaultColumns(this.initialSideRowData)    

}
isLeadConditionMet() {
  this.initialSideRowData.map(data => (data.columns == this.enumParams.COMPANY ||
    data.columns === this.enumParams.STAGE ||
    data.columns === this.enumParams.Active ||
    data.columns === this.enumParams.RATING ||
    data.columns === this.enumParams.TYPE ||
    data.columns === this.enumParams.PHONE ||
    data.columns === this.enumParams.LEADSCORE ||
    data.columns === this.enumParams.DATECONTACTED ||
    data.columns === this.enumParams.REVENUE ||
    data.columns === this.enumParams.QUICKNOTE
 )? data.checked = true : data.checked = false);
 this.hideShowDefaultColumns(this.initialSideRowData)    

}
isCompanyConditionMet() {
  this.initialSideRowData.map(data => (data.columns == this.enumParams.COMPANY
 || data.columns == this.enumParams.RATING
 || data.columns == this.enumParams.Active
 || data.columns == this.enumParams.STAGE
 || data.columns == this.enumParams.TYPE
 || data.columns == this.enumParams.PHONE
 || data.columns == this.enumParams.DATECONTACTED
 || data.columns == this.enumParams.REVENUE
 || data.columns == this.enumParams.SALEPERSON
 || data.columns === this.enumParams.ALTERNATESALEPERSON 
 || data.columns === this.enumParams.QUICKNOTES
 )? data.checked = true : data.checked = false);
 this.hideShowDefaultColumns(this.initialSideRowData)    

}
defaultList() {
  this.initialSideRowData.map(data => (data.columns == this.enumParams.COMPANY
 || data.columns == this.enumParams.VENDOR_NAME
 || data.columns == this.enumParams.RATING
 || data.columns == this.enumParams.Active
 || data.columns == this.enumParams.STAGE
 || data.columns == this.enumParams.TYPE
 || data.columns == this.enumParams.PHONE
 || data.columns == this.enumParams.DATECONTACTED
 || data.columns == this.enumParams.REVENUE
 || data.columns == this.enumParams.QUICKNOTE
 || data.columns === this.enumParams.ALTERNATESALEPERSON 
 || data.columns === this.enumParams.QUICKNOTE
 )? data.checked = true : data.checked = false);
 this.hideShowDefaultColumns(this.initialSideRowData)    

}
isContactConditionMet() {
  this.initialSideRowData.map(data => (data.columns === this.enumParams.VENDORCONTACT_NAME ||
    data.columns === this.enumParams.RATING ||
    data.columns === this.enumParams.DEPARTMENT ||
    data.columns === this.enumParams.LOCATION ||
    data.columns === this.enumParams.TIMEZONE ||
    data.columns === this.enumParams.TITLE ||
    data.columns === this.enumParams.OFFICENUMBER ||
    data.columns === this.enumParams.DIRECTNUMBER ||
    data.columns === this.enumParams.MOBILENUMBER ||
    data.columns === this.enumParams.EMAILADDRESS ||
    data.columns === this.enumParams.DONOTCALL ||
    data.columns === this.enumParams.DONOTEMAIL ||
    data.columns === this.enumParams.LASTCONTACT ||
    data.columns === this.enumParams.COMPANYNAME ||
    data.columns === this.enumParams.Active ||
    data.columns === this.enumParams.NEXTTASKDUEDATE ||
    data.columns === this.enumParams.VENDOR_NAME

 )? data.checked = true : data.checked = false);
  this.hideShowDefaultColumns(this.initialSideRowData)    

}

}

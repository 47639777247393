import { Component, Input, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { UpdateCellData,UpdateCellDataIntentToBuy, UpdateOverallRatingStatus } from "../ag-grid.model";
import { CrmService } from "src/app/components/crm/crm.service";
import { ToastrService } from "ngx-toastr";
import { AgGridService } from "../ag-grid.service";
import { TabsService } from "../../tabs/tabs.service";

@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"],
})
export class RatingComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  rating: number;
  // updateCellData = new UpdateCellData();
  updateCellData = new UpdateOverallRatingStatus();

  constructor(private crmService: CrmService,public tabsService: TabsService, private toastr: ToastrService, public agGridService: AgGridService) {
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }
  agInit(params: ICellRendererParams) {
    this.params = params;
    if(params.data.overallRating === undefined){
      this.rating = params.data.companyRating * 2;
    }else{
      this.rating = params.data.overallRating * 2;
    }
    if(params.data.companyRating != undefined){
      this.rating = params.data.companyRating * 2;
    }
   
    this.agGridService?.starInfo?.map(value => {
      if (value.id == params.data.id) {
        this.rating = value.stars
      }
    })
  }



  onRatingChange(rowData: any, value: number) {
    this.rating = value;
    if(rowData.companyId ||rowData.vendorId){
      this.updateCellData.companyId = rowData.companyId ||rowData.vendorId;

    }else{
      this.updateCellData.companyId = rowData.id;
    }
   this.updateCellData.overallRating = (value / 2);
    this.crmService.updateOverallRatingStatus(this.updateCellData).subscribe(res => {
      if (res.isSuccess) {
        let isAdded = false
        this.toastr.success(res.message);
        this.agGridService.starInfo.map(value => {
          if (value.id == rowData.id) {
            value.stars = this.rating
            isAdded = true
            return
          }
        })
        if(!isAdded){
          this.agGridService.starInfo.push({id:rowData.id, stars:this.rating})
        }
        this.agGridService.ratingChanged.next(true)
      }
    })
  }

  currentTab
  ngOnInit(): void { 
    this.tabsService.currentTab.subscribe(res => {

      if (res) {
        this.currentTab = res
      }
    })
  }
}

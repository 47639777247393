import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImportDocumentsService } from '../../import-documents.service';
import { InsertUpdateImportDocumnets } from '../../import-documents.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subscription } from 'rxjs';
import { Button, CurrentTab, RoutePath } from 'src/app/shared/model/enum';
import { ProgressHelperService } from '../step-progressbar/progress-helper.service';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import { CodeCrosswalkService } from 'src/app/components/admin/code-crosswalk/code-crosswalk.service';



@Component({
  selector: 'app-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrls: ['./details-tab.component.scss']
})
export class DetailsTabComponent implements OnInit {
  enumButtonName = Button;
  Details = {
    templateName: '',
    templateDescription: ''

  };
  selectedOption = '1'
  insertUpdateImportDocumnets = new InsertUpdateImportDocumnets();
  submit: boolean = false;
  myForm: FormGroup;
  flexHeight;
  sub: Subscription;
  isRecall: boolean = false;
  mappingTemplateId: any;
  currentTab:string;
  fileName:string;
  routeUrl:string;
  routePath = RoutePath;
  tabName = CurrentTab;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    public toaster: ToastrService,
    public commonService: CommonService,
    private progressHelper: ProgressHelperService,
    public importDocumentsService: ImportDocumentsService,
    public tabsService: TabsService,
    public codeCrosswalkService     :CodeCrosswalkService,

    ) {
      this.routeUrl = this.router.url;
    this.sub = this.importDocumentsService.dataPassAccordingToTrackingBar.subscribe(res => {
      if (this.importDocumentsService.indexActive == 2) {
        this.insertUpdateImportDocumnets = this.importDocumentsService.dataPassAccordingToTrackingBar.getValue();
        this.insertUpdateImportDocumnets.templateData.isTemplateCreate = false;
        if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
          this.mappingTemplateId = this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId == '' ? '0' : this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId;
        }else{
          this.mappingTemplateId = this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId == '' ? '0' : this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId;
        }

        this.myForm.setValue({
          templateName: this.insertUpdateImportDocumnets.templateData.templateName,
          description: this.insertUpdateImportDocumnets.templateData.templateDescription
        });
      }
    })
    this.myForm = this.formBuilder.group({
      templateName: ['', Validators.required],
      description: ['']
    });
  }

  // get noSpacesValidator() {
  //   return (control) => {
  //     const hasSpaces = /\s/.test(control.value);
  //     return hasSpaces ? { 'hasSpaces': true } : null;
  //   };
  // }

  ngOnInit(): void {
    this.tabsService.currentTab.subscribe(res => {
      if (res !== undefined) {
        this.currentTab = res;
      } 
    })
    this.commonService.headerHeight.subscribe(res => {
      this.flexHeight = "calc(100vh - 250px - " + res + "px" + ")"
    })
    this.isRecall = true;

    this.importDocumentsService.fileName.subscribe((res)=>{
      if(res){
        this.fileName =res;
      }
    })
  
  }

  onSubmit() {
    this.isRecall = false;
    if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
    this.insertUpdateImportDocumnets.isVendor = true;
    }
    this.insertUpdateImportDocumnets.templateData.templateDescription = this.myForm.value.description
    this.insertUpdateImportDocumnets.templateData.templateName = this.myForm.value.templateName
    this.insertUpdateImportDocumnets.templateData.sourceId = this.router.url.includes('import-companies') ? 1 : 2;
    if (!this.insertUpdateImportDocumnets.templateData.isTemplateCreate || this.myForm.controls.templateName.valid) {
      delete this.insertUpdateImportDocumnets.importCodeCrosswalkModel;
      this.importDocumentsService.InsertImportCompanyData(this.insertUpdateImportDocumnets).subscribe(res => {
        if (res.isSuccess) {
          this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId = res?.response?.templateId??'0';
          this.progressHelper.eventHelper.next({ prev: false, next: true })
          this.importDocumentsService.dataPassAccordingToTrackingBar.next(res);
        } else {
          this.toaster.error(res.message);
        }
      })
    }
    this.submit = true;
    if (this.myForm.valid) {
    }
  }

  onSubmitCodeCrossWalk(){
    this.isRecall = false;
    this.insertUpdateImportDocumnets.templateData.templateDescription = this.myForm.value.description
    this.insertUpdateImportDocumnets.templateData.templateName = this.myForm.value.templateName
    this.insertUpdateImportDocumnets.templateData.sourceId = 3;
    if (!this.insertUpdateImportDocumnets.templateData.isTemplateCreate || this.myForm.controls.templateName.valid) {
        delete this.insertUpdateImportDocumnets.isVendor;
      delete this.insertUpdateImportDocumnets.importCompanyModel;
      this.importDocumentsService.InsertImportCodeCrosswalkTemplate(this.insertUpdateImportDocumnets).subscribe(res => {
        if (res.isSuccess) {
          this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId = res?.response?.templateId??'0';
          this.progressHelper.eventHelper.next({ prev: false, next: true })
          this.importDocumentsService.dataPassAccordingToTrackingBar.next(res);
        } else {
          this.toaster.error(res.message);
        }
      })
    }
    this.submit = true;
    if (this.myForm.valid) {
    }
  }
  closeTab(){
    if(this.routeUrl == this.routePath.IMPORTVENDOR_ROUTEPATH){
      this.importDocumentsService.closeVendorTab();
      this.router.navigate([this.routePath.VENDORLIST_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.VENDORLIST_TAB)
    }else{
      this.importDocumentsService.closeTab();
      this.router.navigate([this.routePath.COMPANY_ROUTEPATH]);
      this.tabsService.currentTab.next(this.tabName.COMPANY_TAB)
    }
  }
  close(){ 
    this.codeCrosswalkService.valuePopup.next(true)
   }
  
  insertOrShowingError() {
    const trimmedTemplateName = this.myForm.value.templateName.trim();

  if (trimmedTemplateName !== '' || this.selectedOption == '1' && trimmedTemplateName == '') {
    const val = this.insertUpdateImportDocumnets?.mappingTemplateList?.map(a => a.name).includes(this.myForm.value.templateName);
    if(this.currentTab == this.tabName.CODECROSSWALK_TAB){
      if (this.selectedOption == '1') {
        this.insertUpdateImportDocumnets.templateData.isTemplateCreate = false;
        this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId = this.mappingTemplateId;
      } else if (this.selectedOption == '3') {
        this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId = '0'
        this.insertUpdateImportDocumnets.templateData.isTemplateCreate = true;
      } else if (this.selectedOption == '2') {
        this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId = this.mappingTemplateId;
        this.insertUpdateImportDocumnets.templateData.isTemplateCreate = true;
      } if (this.insertUpdateImportDocumnets.importCodeCrosswalkModel.mappingTemplateId == '0' && val && this.insertUpdateImportDocumnets.templateData.isTemplateCreate) {
        this.toaster.error('Template already exists!!!')
      } else {
          this.onSubmitCodeCrossWalk()
      }
    }else{
      if (this.selectedOption == '1') {
        this.insertUpdateImportDocumnets.templateData.isTemplateCreate = false;
        this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId = this.mappingTemplateId;
      } else if (this.selectedOption == '3') {
        this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId = '0'
        this.insertUpdateImportDocumnets.templateData.isTemplateCreate = true;
      } else if (this.selectedOption == '2') {
        this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId = this.mappingTemplateId;
        this.insertUpdateImportDocumnets.templateData.isTemplateCreate = true;
      } if (this.insertUpdateImportDocumnets.importCompanyModel.mappingTemplateId == '0' && val && this.insertUpdateImportDocumnets.templateData.isTemplateCreate) {
        this.toaster.error('Template already exists!!!')
      } else {
          this.onSubmit();
      }
    }
  }

}
}

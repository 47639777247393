import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';

@Component({
  selector: 'app-company-expandable',
  templateUrl: './company-expandable.component.html',
  styleUrls: ['./company-expandable.component.scss']
})
export class CompanyExpandableComponent implements OnInit, ICellRendererAngularComp {

  constructor(public tabService: TabsService) { }

  registerRowDragger: (rowDraggerElement: HTMLElement, dragStartPixels?: number, value?: string, suppressVisibilityChange?: boolean) => void;
  show: boolean = false;
  showSics: boolean = false;
  message: any;
  params: any;
  currentTab = ''

  agInit(params: any): void {
    this.params = params;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  ngOnInit(): void {
    this.tabService.currentTab.subscribe(res => {
      this.currentTab = res
    })
    if(!this.currentTab){
      this.currentTab = this.tabService.thisTab
    }
  }
  getResponse($event) {
    this.message = $event;
  }

  clickIcon() {
    this.show = !this.show;
  }
  clickSicsIcon() {
    this.showSics = !this.showSics;
  }

  toggleHover(isHovered: boolean): void {
    this.show = isHovered;
  }
  toggleHoverSic(isHovered: boolean): void {
    this.showSics = isHovered;
  }

  
}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject, tap } from "rxjs";
import {
  GetSystemSettingData,
  InertUpdateSystemSetting,
} from "./system-settings.model";
import { DataService } from "src/app/shared/services/data.service";
import { Result } from "src/app/shared/model/response.model";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class SystemSettingsService {
  public imageDataName = new Subject<string>();
  private readonly GET_ALL_THEME_CUSTOMIZATION_URL = 'identity/themeCustomization/getAllThemeCustomization';
  private readonly INSERT_UPDATE_THEME_CUSTOMIZATION_URL = 'identity/themeCustomization/insertUpdateThemeCustomization';

  constructor(
    public router: Router,
    private service: DataService,
    private http: HttpClient
  ) {}
  GetAllSystemSettingData(getSystemSettingData: any): Observable<Result> {
    let url = this.GET_ALL_THEME_CUSTOMIZATION_URL;
    return this.service.post(url, getSystemSettingData, true).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }
  UpdateSystemSettingData(inertUpdateSystemSetting: InertUpdateSystemSetting): Observable<Result> {
    let url =this.INSERT_UPDATE_THEME_CUSTOMIZATION_URL;
    return  this.service.post(url,inertUpdateSystemSetting,true).pipe<Result>(tap((response: any) => {
    return response;
      })
    );
  }

  sendImageData(imageName: any) {
    this.imageDataName.next(imageName);
  }
}

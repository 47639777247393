import { IntentToBuyEditorComponent } from './../../shared/components/ag-grid/intent-to-buy-editor/intent-to-buy-editor.component';
import {
  AgColDefsI,
  AgGridI,
} from "./../../shared/components/ag-grid/ag-grid.model";
import { AgSwitchComponent } from "./../../shared/components/ag-grid/ag-switch/ag-switch.component";
import { RatingComponent } from "./../../shared/components/ag-grid/rating/rating.component";
import { CellIconComponent } from "./../../shared/components/ag-grid/cell-icon/cell-icon.component";
import { HeaderIconComponent } from "./../../shared/components/ag-grid/header-icon/header-icon.component";
import { DatePipe } from "@angular/common";
import { CompanyExpandableComponent } from "./companies/company-expandable/company-expandable.component";
import { AgDropdownComponent } from "./../../shared/components/ag-grid/ag-dropdown/ag-dropdown.component";
import { IntentToBuyComponent } from "src/app/shared/components/ag-grid/intent-to-buy/intent-to-buy.component";
import { CompanyRendererComponent } from 'src/app/shared/components/ag-grid/company-renderer/company-renderer.component';
import { VendorExpandableComponent } from '../procurement/vendor-expandable/vendor-expandable.component';
import { VendorRendererComponent } from 'src/app/shared/components/ag-grid/vendor-renderer/vendor-renderer.component';
import { CompanyColumnExpandableComponent } from './companies/company-column-expandable/company-column-expandable.component';
import { DocumentCellRendererComponent } from './companies/pages/company-info-tabs/document-cell-renderer/document-cell-renderer.component';
import {AggridHeadername, GridName} from './../../shared/model/ag-grid-common-constant'



const commonCellStyle = {
 'color': '#646E76'
};
export class Crm {}

const ExpandableColumn: AgColDefsI = {
  headerName: "",
  field: "isExpandable",
  lockPosition: "left",
  headerClass: "width-0",
  lockPinned: true,
  suppressMovable: false,
  resizable:false,
  cellStyle: {
    position: "absolute",
    top: "0px",
    minWidth: "100%",
    left: "0 !important",
    borderTop: "var(--sitewide_accent_color) solid 1px",
    borderRight: "var(--sitewide_accent_color) solid 1px",
    borderBottom: "var(--sitewide_accent_color) solid 1px",
    borderLeft: "var(--sitewide_scondary_color) solid 3px",
  },
  cellClassRules: {
    "border-0": (params) => !(params?.data?.isExpandable || false),
  },
  
  cellRenderer: CompanyExpandableComponent,
  cellRendererParams: { context: this },
  width: 0,
  flex:0
};
const CompanyExpandableColumn: AgColDefsI = {
  headerName: "",
  field: "isExpandable",
  lockPosition: "left",
  headerClass: "width-0",
  lockPinned: true,
  suppressMovable: false,
  pinned:'left',
  cellStyle: {
    position: "absolute",
    top: "0px",
    minWidth: "100%",
    left: "0 !important",
    // borderTop: "var(--sitewide_accent_color) solid 1px",
    // borderRight: "var(--sitewide_accent_color) solid 1px",
    // borderBottom: "var(--sitewide_accent_color) solid 1px",
    // borderLeft: "var(--sitewide_scondary_color) solid 3px",
  },
  // cellClassRules: {
  //   "border-0": (params) => !params?.data?.isExpandable ?? false,
  // },
  cellRenderer: CompanyColumnExpandableComponent,
  cellRendererParams: { context: this },
  width: 0,
};

const CompanyColumn: AgColDefsI = {
  headerName: AggridHeadername.COMPANIES,
  field: "companyName",
  // headerClass: "position-initial",
  // cellClass: "position-initial",
  sortable: true,
  headerCheckboxSelection: true,
  lockPosition: "left",
  suppressMovable: false,
  lockPinned: true,
  resizable:false,
//  pinned:'left',
  // cellStyle: {
  //   paddingLeft:'1.4em',

  // },
  checkboxSelection: function (params) {
    return !params?.data?.isExpandedRows;
  },
  tooltipField: 'companyName',
  // minWidth: 230,
  maxWidth:500,
  cellRenderer: CompanyRendererComponent
};
const CommonCompanyColumn: AgColDefsI = {
  headerName: AggridHeadername.COMPANIES,
  field: "companyName",
  headerClass: "position-initial",
  // cellClass: "position-initial",
  sortable: true,
  headerCheckboxSelection: true,
  lockPosition: "left",
  suppressMovable: false,
  lockPinned: true,
  checkboxSelection: function (params) {
    return !params?.data?.isExpandedRows;
  },
  tooltipField: 'name',
   minWidth: 100,
  // cellRenderer: CompanyRendererComponent
};
const VendorNameColumn: AgColDefsI = {
  headerName: AggridHeadername.VENDOR_NAME,
  field: "vendorName",
  headerClass: "position-initial",
  cellClass: "left-px",
  sortable: true,
  headerCheckboxSelection: true,
  lockPosition: "left",
  suppressMovable: false,
  uniqueName:'vendorName',
  lockPinned: true,
 // pinned:'left',
  resizable:false,
  checkboxSelection: function (params) {
    return !params?.data?.isExpandedRows;
  },
  tooltipField: 'vendorName',
  // minWidth: 200,
  maxWidth: 500,
  cellRenderer: VendorRendererComponent
};

const ContactFullNameColumn: AgColDefsI = {
  headerName: AggridHeadername.CONTACT_NAME,
  field: "fullName",
  headerClass: "position-initial",
  cellClass: "position-initial",
  sortable: true,
  pinned:'left',
  headerCheckboxSelection: true,
  lockPosition: "left",
  suppressMovable: false,
  lockPinned: true,
  resizable:false,
  checkboxSelection: function (params) {
    return !params?.data?.isExpandedRows;
  },
  minWidth: 250,
  tooltipField: "fullName",
  cellStyle: function (params) {
    // Add a left margin of 2 pixels to all cells
    return {
      marginLeft: '2px',
      // You can add more styles as needed
    };
  },
  cellRenderer:DocumentCellRendererComponent
};


const ContactCompanyColumn: AgColDefsI = {
  headerName: AggridHeadername.CONTACT_COMPANY_NAME,
  field: "companyName",
  sortable: true,
  lockPinned: true,
  minWidth: 140,
  cellClass: "padding-left-38px",

  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,

};

// const CompanyIconColumn: AgColDefsI = {
//   field: "icon",
//   headerComponentFramework: HeaderIconComponent,
//   suppressMenu: true,
//   width: 50,
//   minWidth: 50,
//   lockPosition: "left",
//   lockPinned: true,
//   pinned:'left',
//   cellRendererFramework: CellIconComponent,
//   cellRendererParams: { context: this },
//   headerComponentParams: { context: this },
// };
const IconColumn: AgColDefsI = {
  field: "icon",
  headerComponentFramework: HeaderIconComponent,
  suppressMenu: true,
  width: 50,
  minWidth: 50,
  lockPosition: "left",
  lockPinned: true,
 // pinned:'left',
  cellRendererFramework: CellIconComponent,
  resizable:false,
  cellRendererParams: { context: this },
  headerComponentParams: { context: this },
  cellStyle: {
    'padding-left':'19px',
  },

};

const StageColumn: AgColDefsI = {
  headerName: AggridHeadername.STAGE,
  field: "saleStageName",
  sortable: true,
  lockPinned: true,
  minWidth: 114,
  cellClass: "padding-left-38px",
  tooltipField: 'stage',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};

const PhoneColumn: AgColDefsI = {
  headerName: AggridHeadername.PHONE,
  field: "phoneNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 116,
  headerClass:'text-truncate',
  cellClass: "padding-left-38px",
  tooltipField: 'phoneNumber',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};

const SalespersonColumn: AgColDefsI = {
  headerName: AggridHeadername.SALESPERSON,
  field: "salesPersonName",
  sortable: true,
  lockPinned: true,
  minWidth: 155,
  cellClass: "padding-left-38px",
  tooltipField: 'salesPersonName',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};

const EmailColumn: AgColDefsI = {
  headerName: AggridHeadername.COMPANY_EMAIL,
  field: "emailAddress",
  sortable: true,
  lockPinned: true,
  minWidth: 178,
  cellClass: "padding-left-38px",
  tooltipField: 'emailAddress',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const DandBColumn: AgColDefsI = {
  headerName: AggridHeadername.DANDB,
  field: "dandBNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 155,
  cellClass: "padding-left-38px",
  tooltipField: 'dandBNumber',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value,
};
const PhonePrimarytColumn: AgColDefsI = {
  headerName: AggridHeadername.PHONE,
  field: "primaryContactPhone",
  sortable: true,
  lockPinned: true,
  minWidth: 250,
  cellClass: "padding-left-38px",
  tooltipField: 'primaryContactPhone',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const MainContactColumn: AgColDefsI = {
  headerName: AggridHeadername.MAIN_CONTACT,
  field: "primaryContactName",
  sortable: true,
  lockPinned: true,
  minWidth: 160,
  cellClass: "padding-left-38px",
  tooltipField: 'primaryContactName',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const EmailprimaryColumn: AgColDefsI = {
  headerName: AggridHeadername.EMAIL,
  field: "primaryContactEmail",
  sortable: true,
  lockPinned: true,
  minWidth: 250,
  cellClass: "padding-left-38px",
  tooltipField: 'primaryContactEmail',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};



const ClassColumn: AgColDefsI = {
  headerName: AggridHeadername.TYPE,
  field: "typeName",
  sortable: true,
  lockPinned: true,
  minWidth: 110,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};

const TypeColumn: AgColDefsI = {
  headerName: AggridHeadername.TYPE,
  field: "typeName",
  sortable: true,
  lockPinned: true,
  minWidth: 110,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};

const DateContactedColumn: AgColDefsI = {
  headerName: AggridHeadername.DATE_CONTACTED,
  field: "dateContacted",
  sortable: true,
  lockPinned: true,
  minWidth: 175,
  cellStyle: {
    'color':'#646E76',
  },
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,

  // cellRenderer: (res) => {
  //   const datepipe: DatePipe = new DatePipe("en-US");
  //   var x = datepipe.transform(res.data.createdAt?.split("T"), "dd/MM/yyyy");
  //   // params => params.value === "" ?"<p>&#8212;</p>": params.value 
  //   return x;

  // },
  cellClass: "padding-left-38px",
};

const RatingColumn: AgColDefsI = {
  headerName: AggridHeadername.RATING,
  field: "overallRating",
  sortable: true,
  lockPinned: true,
  minWidth: 120,
  cellRenderer: RatingComponent,
  cellClass: "padding-left-38px",
};
const VendorRatingColumn: AgColDefsI = {
  headerName:  AggridHeadername.RATING,
  field: "overallRating",
  sortable: true,
  lockPinned: true,
  minWidth: 125,
  cellRenderer: RatingComponent,
  cellClass: "padding-left-38px",
};

const ActiveColumn: AgColDefsI = {
  headerName: AggridHeadername.ACTIVE,
  field: "active",
  sortable: true,
  lockPinned: true,
  minWidth: 118,
  cellRenderer: AgSwitchComponent,
  cellClass: "padding-left-38px",
};
const vendorStatusActiveColumn: AgColDefsI = {
  headerName: AggridHeadername.ACTIVE,
  field: "vendorStatus",
  sortable: true,
  lockPinned: true,
  minWidth: 135,
  cellRenderer: AgSwitchComponent,
  cellClass: "padding-left-38px",
};

const FaxColumn: AgColDefsI = {
  headerName: AggridHeadername.FAX,
  field: "faxNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 100,
  cellClass: "padding-left-38px",
  tooltipField: 'faxNumber',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value,
};
const WebsiteColumn: AgColDefsI = {
  headerName: AggridHeadername.WEBSITE,
  field: "website",
  sortable: true,
  lockPinned: true,
  minWidth: 130,
  cellClass: "padding-left-38px",
  tooltipField: 'website',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value,
};
const EmployeesColumn: AgColDefsI = {
  headerName: AggridHeadername.EMPLOYEES,
  field: "employee",
  sortable: true,
  lockPinned: true,
  minWidth: 145,
  cellClass: "padding-left-38px",
  tooltipField: 'employee',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const TimezoneColumn: AgColDefsI = {
  headerName: AggridHeadername.TIMEZONE,
  field: "timeZoneName",
  sortable: true,
  lockPinned: true,
  minWidth: 136,
  cellClass: "padding-left-38px",
  tooltipField: 'timeZoneName',
  cellRenderer: params => params.value === "" || params.value === null ? "<p>&#8212;</p>" : params.value,
};
const IndustryColumn: AgColDefsI = {
  headerName: AggridHeadername.INDUSTRY,
  field: "industryName",
  sortable: true,
  lockPinned: true,
  minWidth: 128,
  cellClass: "padding-left-38px",
  tooltipField: 'industryName',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const SICColumn: AgColDefsI = {
  headerName: AggridHeadername.SIC,
  field: "sicCode",
  sortable: true,
  lockPinned: true,
  minWidth: 100,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" || params.value === null ? "<p>&#8212;</p>" : params.value,
  // cellRenderer: params => params.value === null || params.value === "" ? "<p>&#8212;</p>" : params.value 
};
const NAICSColumn: AgColDefsI = {
  headerName: AggridHeadername.NAICS,
  field: "naicsCode",
  sortable: true,
  lockPinned: true,
  minWidth: 115,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" || params.value === null ? "<p>&#8212;</p>" : params.value,
};
const CountryColumn: AgColDefsI = {
  headerName: AggridHeadername.COUNTRY,
  field: "countryName",
  sortable: true,
  lockPinned: true,
  minWidth: 127,
  cellClass: "padding-left-38px",
  tooltipField: 'countryName',
  cellRenderer: params => params.value === "" || params.value === null ? "<p>&#8212;</p>" : params.value,
};
const DoNotCallColumn: AgColDefsI = {
  headerName: AggridHeadername.DO_NOT_CALL,
  field: "doNotCall",
  sortable: true,
  lockPinned: true,
  minWidth: 145,
  cellClass: "padding-left-38px",
  // cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
  cellRenderer: params => {
    if (params.value === "") {
      return "<p>&#8212;</p>"; 
    } else if (params.value === true) {
      return "Yes";
    }  else if (params.value === false) {
      return "No"; 
    }
  }

};
const RevenueColumn: AgColDefsI = {
  headerName: AggridHeadername.REVENUE,
  field: "revenue",
  sortable: true,
  lockPinned: true,
  minWidth: 131,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" || params.value === null ? "<p>&#8212;</p>" : params.value,
};
const AlternateSalespersonColumn: AgColDefsI = {
  headerName: AggridHeadername.ALTERNATE_SALESPERSON,
  field: "altSalesPersonName",
  sortable: true,
  lockPinned: true,
  minWidth: 211,
  cellClass: "padding-left-38px",
  tooltipField: 'altSalesPersonName',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const RecentNotesColumn: AgColDefsI = {
  headerName: AggridHeadername.QUICK_NOTES,
  field: "notes",
  sortable: true,
  lockPinned: true,
  minWidth: 150,
  cellClass: "padding-left-38px",
  tooltipField: 'notes',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value,
};
const AccountNumberColumn: AgColDefsI = {
  headerName: AggridHeadername.ACCOUNT_NUMBER,
  field: "accountNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 181,
  cellClass: "padding-left-38px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const CurrencyColumn: AgColDefsI = {
  headerName: AggridHeadername.CURRENCY,
  field: "currencyName",
  sortable: true,
  lockPinned: true,
  minWidth: 135,
  cellClass: "padding-left-38px",
  tooltipField: 'currencyName',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const SourcingEmailColumn: AgColDefsI = {
  headerName: AggridHeadername.SOURCING_EMAIL,
  field: "sourcingEmail",
  sortable: true,
  lockPinned: true,
  minWidth: 168,
  cellClass: "padding-left-38px",
  tooltipField: 'sourcingEmail',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const AccountingContactColumn: AgColDefsI = {
  headerName: AggridHeadername.ACCOUNTING_CONTACT,
  field: "accountingContact",
  sortable: true,
  lockPinned: true,
  minWidth: 200,
  cellClass: "padding-left-38px",
  tooltipField: 'accountingContact',
  cellRenderer: params => params.value === "" || params.value === null ?  "<p>&#8212;</p>" : params.value,
};

const SaleYearColumn: AgColDefsI = {
  headerName: AggridHeadername.SALES_12_MONTHS,
  field: "sales12MonthId",
  sortable: true,
  lockPinned: true,
  headerClass:'text-truncate',
  minWidth: 175,
  cellClass: "padding-left-38px",
  tooltipField: 'sales12MonthId',
  cellRenderer: params => params.value === "" || params.value === null ? "<p>&#8212;</p>" : params.value,
};

const StateColumn: AgColDefsI = {
  headerName: AggridHeadername.STATE,
  field: "stateName",
  sortable: true,
  lockPinned: true,
  minWidth: 114,
  cellClass: "padding-left-38px",
  tooltipField: 'stateName',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const LeadScoreColumn: AgColDefsI = {
  headerName: AggridHeadername.LEAD_SCORE,
  field: "leadScoreId",
  editable: true,
  sortable: true,
  lockPinned: true,
  minWidth: 145,
  cellRenderer: IntentToBuyComponent,
  cellEditor: IntentToBuyEditorComponent,
  tooltipField: 'leadScoreName',
  cellClass: 'hand'
};
const IntenttoBuyColumn: AgColDefsI = {
  headerName: AggridHeadername.INTENT_TO_BUY,
  field: "intentToBuyId",
  editable: true,
  sortable: true,
  lockPinned: true,
  minWidth: 154,
  cellRenderer: IntentToBuyComponent,
  cellEditor: IntentToBuyEditorComponent,
  tooltipField: 'intentToBuyName',
  cellClass: 'hand'
};


const DepartmentColumn: AgColDefsI = {
  headerName: AggridHeadername.DEPARTMENT,
  field: "departmentName",
  sortable: true,
  lockPinned: true,
  minWidth: 155,
  cellClass: "padding-left-38px",
  tooltipField: "departmentName",
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const TitleColumn: AgColDefsI = {
  headerName: AggridHeadername.TITLE,
  field: "title",
  sortable: true,
  lockPinned: true,
  minWidth: 105,
  cellClass: "padding-left-38px",
  tooltipField: "contactTitle",
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const LocationColumn: AgColDefsI = {
  headerName: AggridHeadername.LOCATION,
  field: "location",
  sortable: true,
  lockPinned: true,
  minWidth: 130,
  cellClass: "padding-left-38px",
  tooltipField: 'location',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const MainOfficePhoneColumn: AgColDefsI = {
  headerName: AggridHeadername.MAIN_OFFICE_NUMBER,
  field: "mainOfficeNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 200,
  cellClass: "padding-left-38px",
  tooltipField: 'mainOfficeNumber',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const ContactTimezoneColumn: AgColDefsI = {
  headerName: AggridHeadername.TIMEZONE,
  field: "timeZoneName",
  sortable: true,
  lockPinned: true,
  minWidth: 142,
  cellClass: "padding-left-38px",
  tooltipField: "timeZoneName",
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value,
};
const ContactEmailColumn: AgColDefsI = {
  headerName: AggridHeadername.EMAIL_ADDRESS,
  field: "email",
  sortable: true,
  lockPinned: true,
  minWidth: 165,
  cellClass: "padding-left-38px",
  tooltipField: 'email',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const ContactFaxColumn: AgColDefsI = {
  headerName: AggridHeadername.FAX,
  field: "faxNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 100,
  cellClass: "padding-left-38px",
  tooltipField: 'faxNumber',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
};
const ContactSkypeColumn: AgColDefsI = {
  headerName: AggridHeadername.SKYPE,
  field: "skype",
  sortable: true,
  lockPinned: true,
  minWidth: 115,
  cellClass: "padding-left-38px",
  tooltipField: 'skype',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const ContactXingColumn: AgColDefsI = {
  headerName: AggridHeadername.XING,
  field: "xing",
  sortable: true,
  lockPinned: true,
  minWidth: 107,
  cellClass: "padding-left-38px",
  tooltipField: 'xing',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const ContactLinkedinColumn: AgColDefsI = {
  headerName: AggridHeadername.LINKEDIN,
  field: "linkedIn",
  sortable: true,
  lockPinned: true,
  minWidth: 128,
  cellClass: "padding-left-38px",
  tooltipField: 'linkedIn',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const ContactNotes: AgColDefsI = {
  headerName: AggridHeadername.QUICK_NOTES,
  field: "notes",
  sortable: true,
  lockPinned: true,
  minWidth: 143,
  cellClass: "padding-left-38px",
  tooltipField: 'notes',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};

const VendorExpandableColumn: AgColDefsI = {
  headerName: "",
  field: "isExpandable",
  lockPosition: "left",
  headerClass: "width-0",
  lockPinned: true,
  suppressMovable: false,
  cellStyle: {
    position: "absolute",
    top: "0px",
    minWidth: "100%",
    left: "0 !important",
    borderTop: "var(--sitewide_accent_color) solid 1px",
    borderRight: "var(--sitewide_accent_color) solid 1px",
    borderBottom: "var(--sitewide_accent_color) solid 1px",
    borderLeft: "var(--sitewide_scondary_color) solid 3px",
  },
  cellClassRules: {
    "border-0": (params) => !(params?.data?.isExpandable || false),
  },
  
  cellRenderer: VendorExpandableComponent,
  cellRendererParams: { context: this },
  width: 0,
};
const VendorTypeColumn: AgColDefsI = {
  headerName: AggridHeadername.VENDOR_NAME,
  field: "typeName",
  sortable: true,
  lockPinned: true,
  minWidth: 155,
  cellClass: "padding-left-38px",
  tooltipField: 'vendorType',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const QuickNoteColumn: AgColDefsI = {
  headerName: AggridHeadername.QUICK_NOTES,
  field: "notes",
  sortable: true,
  lockPinned: true,
  minWidth: 145,
  cellClass: "padding-left-38px",
  tooltipField: 'quickNote',
  cellRenderer: params => params.value === "" || params.value === null



  ? "<p>&#8212;</p>" : params.value
};
const AVLColumn: AgColDefsI = {
  headerName: AggridHeadername.AVL,
  field: "approvedVendorList",
  sortable: true,
  lockPinned: true,
  minWidth: 100,
  cellClass: "padding-left-38px",
  tooltipField: 'approvedVendorList',
  cellRenderer: params => params.value === true ? "YES" : "<p>&#8212;</p>"  
};
const GeneralEmailColumn: AgColDefsI = {
  headerName: AggridHeadername.GENERAL_EMAIL,
  field: "primaryContactEmail",
  sortable: true,
  lockPinned: true,
  minWidth: 162,
  cellClass: "padding-left-38px",
  tooltipField: 'primaryContactEmail',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const DAndBNumberColumn: AgColDefsI = {
  headerName: AggridHeadername.DANDB,
  field: "dandBNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 155,
  cellClass: "padding-left-38px",
  tooltipField: 'dandBNumber',
  cellRenderer: params => params.value === "" || params.value === null ? "<p>&#8212;</p>" : params.value,


}; 

const PrimaryContactNameColumn: AgColDefsI = {
  headerName: AggridHeadername.PRIMARY_CONTACT_NAME,
  field: "primaryContactName",
  sortable: true,
  lockPinned: true,
  minWidth: 218,
  cellClass: "padding-left-38px",
  tooltipField: 'primaryContactName',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const PrimaryContactEmailColumn: AgColDefsI = {
  headerName: AggridHeadername.PRIMARY_CONTACT_EMAIL,
  field: "primaryContactEmail",
  sortable: true,
  lockPinned: true,
  minWidth: 215,
  cellClass: "padding-left-38px",
  tooltipField: 'primaryContactEmail',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const PrimaryContactPhoneColumn: AgColDefsI = {
  headerName: AggridHeadername.PRIMARY_CONTACT_PHONE,
  field: "primaryContactPhone",
  sortable: true,
  lockPinned: true,
  minWidth: 218,
  cellClass: "padding-left-38px",
  tooltipField: 'primaryContactPhone',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const AccountingContactNameColumn: AgColDefsI = {
  headerName: AggridHeadername.ACCOUNTING_CONTACT_NAME,
  field: "accountingContactName",
  sortable: true,
  lockPinned: true,
  minWidth: 240,
  cellClass: "padding-left-38px",
  tooltipField: 'accountingContactName',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value,
};
const AccountingContactEmailColumn: AgColDefsI = {
  headerName: AggridHeadername.ACCOUNTING_CONTACT_EMAIL_ADDRESS,
  field: "accountContactEmail",
  sortable: true,
  lockPinned: true,
  minWidth: 290,
  cellClass: "padding-left-38px",
  tooltipField: 'accountContactEmail',
   cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};

const ParentCompanyColumn: AgColDefsI = {
  headerName: AggridHeadername.PARENT_COMPANY,
  field: "parentCompany",
  sortable: true,
  lockPinned: true,
  minWidth: 182,
  cellClass: "padding-left-38px",
  tooltipField: 'parentCompany',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const EntityTypeColumn: AgColDefsI = {
  headerName: AggridHeadername.ENTITY_TYPE,
  field: "entityType",
  sortable: true,
  lockPinned: true,
  minWidth: 145,
  cellClass: "padding-left-38px",
  tooltipField: 'entityType',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
};
const BuyersRepColumn: AgColDefsI = {
  headerName: AggridHeadername.BUYERS_REP,
  field: "buyersName",
  sortable: true,
  lockPinned: true,
  minWidth: 148,
  cellClass: "padding-left-38px",
  tooltipField: 'buyersName',
  cellRenderer: params => params.value === ""? "<p>&#8212;</p>" : params.value,
};


const VendorColumn: AgColDefsI = {
  headerName: AggridHeadername.VENDOR_NAME,
  field: "vendorName",
  sortable: true,
  lockPinned: true,
  minWidth: 165,
  cellClass: "padding-left-38px",
  tooltipField: 'vendor',
  uniqueName:'vendorContactName',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
};
const ContactNameColumn: AgColDefsI = {
  // headerName: "Contact Name",
  // field: "contactFullName",
  // sortable: true,
  // lockPinned: true,
  // minWidth: 170,
  // pinned:'left',
  // cellClass: "padding-left-38px",
  // tooltipField: 'contactName',
  // cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
  headerName: AggridHeadername.CONTACT_NAME,
  field: "fullName",
  headerClass: "position-initial",
  cellClass: "position-initial",
  sortable: true,
  pinned:'left',
  headerCheckboxSelection: true,
  lockPosition: "left",
  suppressMovable: false,
  lockPinned: true,
  resizable:false,
  checkboxSelection: function (params) {
    return !params?.data?.isExpandedRows;
  },
  minWidth: 170,
  tooltipField: "contactFullName",
 // cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
 cellRenderer: VendorRendererComponent,
  cellStyle: function (params) {
    return {
      marginLeft: '2px',
    };
  },


};
const GenderColumn: AgColDefsI = {
  headerName: AggridHeadername.GENDER,
  field: "genderName",
  sortable: true,
  lockPinned: true,
  minWidth: 128,
  cellClass: "padding-left-38px",
  tooltipField: 'genderName',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const MobileNumberColumn: AgColDefsI = {
  headerName: AggridHeadername.MOBILE_NUMBER,
  field: "mobileNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 170,
  cellClass: "padding-left-38px",
  tooltipField: 'mobileNumber',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
// const EmailAddressColumn: AgColDefsI = {
//   headerName: "Email Address",
//   field: "emailAddress",
//   sortable: true,
//   lockPinned: true,
//   minWidth: 170,
//   cellClass: "padding-left-38px",
//   tooltipField: 'emailAddress',
//   cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
// };

const VendorEmailColumn: AgColDefsI = {
  headerName: AggridHeadername.EMAIL_ADDRESS,
  field: "email",
  sortable: true,
  lockPinned: true,
  minWidth: 170,
  cellClass: "padding-left-38px",
  tooltipField: 'email',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};

const DirectNumberColumn: AgColDefsI = {
  headerName: AggridHeadername.DIRECT_NUMBER,
  field: "directNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 168,
  cellClass: "padding-left-38px",
  tooltipField: 'directNumber',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const MainOfficeNumberColumn: AgColDefsI = {
  headerName: AggridHeadername.MAIN_OFFICE_NUMBER_EXTENSION,
  field: "mainOfficeNumber",
  sortable: true,
  lockPinned: true,
  minWidth: 270,
  cellClass: "padding-left-38px",
  tooltipField: 'mainOfficeNumber',
  cellRenderer: params => params.value === "" || params.value === null? "<p>&#8212;</p>" : params.value
};
const MarkAsPrimaryColumn: AgColDefsI = {
  headerName: AggridHeadername.MARK_AS_PRIMARY,
  field: "markAsPrimary",
  sortable: true,
  lockPinned: true,
  minWidth: 190,
  cellClass: "padding-left-38px",
  tooltipField: 'markAsPrimary',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
};
const MarkAsPrimaryContactColumn: AgColDefsI = {
  headerName: AggridHeadername.PRIMARY,
  field: "markAsPrimaryContact",
  sortable: true,
  lockPinned: true,
  minWidth: 125,
  cellClass: "padding-left-38px",
  tooltipField: 'markAsPrimaryContact',
  // cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
  cellRenderer: params => {
    if (params.value === "") {
      return "<p>&#8212;</p>"; 
    } else if (params.value === true) {
      return "Yes";
    }  else if (params.value === false) {
      return "No"; 
    }
  }

};

const MarkeAsAccountingColumn: AgColDefsI = {
  headerName: AggridHeadername.MARK_AS_ACCOUNTING,
  field: "markAsAccounting",
  sortable: true,
  lockPinned: true,
  minWidth: 190,
  cellClass: "padding-left-38px",
  tooltipField: 'markAsAccounting',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
};
const MarkeAsAccountingContactColumn: AgColDefsI = {
  headerName: AggridHeadername.ACCOUNTING,
  field: "markAsAccountingContact",
  sortable: true,
  lockPinned: true,
  minWidth: 150,
  cellClass: "padding-left-38px",
  tooltipField: 'markAsAccountingContact',
  // cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
  cellRenderer: params => {
    if (params.value === "") {
      return "<p>&#8212;</p>"; 
    } else if (params.value === true) {
      return "Yes";
    }  else if (params.value === false) {
      return "No"; 
    }
  }

};

const DoNotEmail: AgColDefsI = {
  headerName: AggridHeadername.DO_NOT_CALL,
  field: "doNotEmail",
  sortable: true,
  lockPinned: true,
  minWidth: 155,
  cellClass: "padding-left-38px",
  tooltipField: 'doNotEmail',
  // cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
  cellRenderer: params => {
    if (params.value === "") {
      return "<p>&#8212;</p>"; 
    } else if (params.value === true) {
      return "Yes";
    }  else if (params.value === false) {
      return "No"; 
    }
  }

};
const NextTaskDueDate: AgColDefsI = {
  headerName: AggridHeadername.NEXT_TASK_DUE_DATE,
  field: "nextTaskDueDate",
  sortable: true,
  lockPinned: true,
  minWidth: 230,
  cellClass: "padding-left-38px",
  tooltipField: 'nextTaskDueDate',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
};
const DueDate: AgColDefsI = {
  headerName: AggridHeadername.NEXT_TASK_DUE_DATE,
  field: "dueDate",
  sortable: true,
  lockPinned: true,
  minWidth: 195,
  cellClass: "padding-left-38px",
  tooltipField: 'dueDate',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
};

const LastContact: AgColDefsI = {
  headerName: AggridHeadername.LAST_CONTACT,
  field: "lastContacted",
  sortable: true,
  lockPinned: true,
  minWidth: 155,
  cellClass: "padding-left-38px",
  tooltipField: 'lastContact',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
};
const LastContacted: AgColDefsI = {
  headerName: AggridHeadername.LAST_CONTACT,
  field: "lastContacted",
  sortable: true,
  lockPinned: true,
  minWidth: 155,
  cellClass: "padding-left-38px",
  tooltipField: 'lastContacted',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value
};


const SicCodeColumn: AgColDefsI = {
   headerName: AggridHeadername.SIC_CODE,
   field: "sic",
  // headerClass: "position-initial",
   sortable: true,
   headerCheckboxSelection: true,
   lockPosition: "left",
   suppressMovable: true,
   uniqueName:'siccodename',
   lockPinned: true,
  // pinned:'left',
  cellStyle:{...commonCellStyle,"padding-left":"19px"},
  checkboxSelection: function (params) {
    return !params?.data?.isExpandedRows;
  },
  minWidth: 160,
 // flex:1,
};
const SicDescription: AgColDefsI = {
  headerName: AggridHeadername.SIC_DESCRIPTION,
  field: "sicDescription",
  sortable: true,
  lockPinned: true,
  suppressMovable: true,
  minWidth: 145,
  //cellClass: "padding-left-0px",
  tooltipField: 'sicDescription',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
 // flex:1

};
const NaiscCode: AgColDefsI = {
  headerName: AggridHeadername.NAICS_CODE,
  field: "naics",
  sortable: true,
  lockPinned: true,
  suppressMovable: true,
  minWidth: 125,
  cellClass: "padding-left-20px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
  //flex:1

};
const NaiscDescription: AgColDefsI = {
  headerName: AggridHeadername.NAICS_DESCRIPTION,
  field: "naicsDescription",
  sortable: true,
  lockPinned: true,
  suppressMovable: true,
  minWidth: 160,
  cellClass: "padding-left-20px",
  tooltipField: 'naicsDescription',
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
 // flex:1

};

const NaiscYear: AgColDefsI = {
  headerName: AggridHeadername.NAICS_YEAR,
  field: "naicsYear",
  sortable: true,
  lockPinned: true,
  suppressMovable: true,
  minWidth: 120,
  cellClass: "padding-left-20px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
 // flex:1

};
const CodeCrosswalkActiveColumn: AgColDefsI = {
  headerName: "",
  field: "active",
  lockPinned: true,
  suppressMovable: true,
  minWidth: 108,
  cellRenderer: AgSwitchComponent,
  cellClass: "",
  cellStyle: {'margin-top': '0.13rem', 'justify-content': 'center' },
  resizable:false,
//  flex:1
  
};
const CodeCrosswalkEdit: AgColDefsI = {
    headerName: "",
    field: "editCodeCrosswalk",
    minWidth: 50,
    lockPinned: true,
    suppressMovable: true,
    cellStyle: { 
      'color': '#646E76',
      'display': 'flex',
      'justify-content': 'flex-end'
    },
    resizable:false,
    cellRenderer:DocumentCellRendererComponent,
   // flex:1
    

};
const CodeCrosswalkDelete: AgColDefsI = {
  headerName: "",
  field: "deleteCodeCrosswalk",
  minWidth: 50,
  lockPinned: true,
  suppressMovable: true,
  cellStyle: {color: "#CC4D4D"} ,
  resizable:false,
  cellRenderer:DocumentCellRendererComponent,
 // flex:1

};
const CodeCrosswalkIndustry: AgColDefsI = {
  headerName: AggridHeadername.INDUSTRY,
  field: "industry",
  sortable: true,
  lockPinned: true,
  suppressMovable: true,
  minWidth: 102,
  cellClass: "padding-left-20px",
  cellRenderer: params => params.value === "" ? "<p>&#8212;</p>" : params.value,
 // flex:1
};
export const ColDefsCompany: AgColDefsI[] = [
  ExpandableColumn,
 // CompanyExpandableColumn,
 ...(window.innerWidth >= 1025 ? [CompanyExpandableColumn] : []),
  CompanyColumn,
  RatingColumn,
  StageColumn,
  ClassColumn,
  PhoneColumn,
  FaxColumn,
  SalespersonColumn,
  WebsiteColumn,
  EmployeesColumn,
  ActiveColumn,
  DateContactedColumn,
  TimezoneColumn,
  IndustryColumn,
  SICColumn,
  NAICSColumn,
  CountryColumn,
  DoNotCallColumn,
  RevenueColumn,
  AlternateSalespersonColumn,
  RecentNotesColumn,
  AccountNumberColumn,
  CurrencyColumn,
  EmailColumn,
  DandBColumn,
  // PhonePrimarytColumn,
  MainContactColumn,
  // EmailprimaryColumn,
  AccountingContactColumn,
  IconColumn,
 // SourcingEmailColumn,
 SaleYearColumn,
  StateColumn
];
export const ColDefsLead: AgColDefsI[] = [
  ExpandableColumn,
  CompanyExpandableColumn,
  CompanyColumn,
  RatingColumn,
  StageColumn,
  LeadScoreColumn,
  ClassColumn,
  PhoneColumn,
  FaxColumn,
  SalespersonColumn,
  WebsiteColumn,
  EmployeesColumn,
  ActiveColumn,
  DateContactedColumn,
  TimezoneColumn,
  IndustryColumn,
  SICColumn,
  NAICSColumn,
  CountryColumn,
  DoNotCallColumn,
  RevenueColumn,
  AlternateSalespersonColumn,
  RecentNotesColumn,
  AccountNumberColumn,
  CurrencyColumn,
  EmailColumn,
  DandBColumn,
  // PhonePrimarytColumn,
  MainContactColumn,
  // EmailprimaryColumn,
  AccountingContactColumn,
  IconColumn,
 // SourcingEmailColumn,
 SaleYearColumn,
  StateColumn
];
export const ColDefsProspect: AgColDefsI[] = [
  ExpandableColumn,
  CompanyExpandableColumn,
  CompanyColumn,
  RatingColumn,
  StageColumn,
  IntenttoBuyColumn,
  ClassColumn,
  PhoneColumn,
  FaxColumn,
  SalespersonColumn,
  WebsiteColumn,
  EmployeesColumn,
  ActiveColumn,
  DateContactedColumn,
  TimezoneColumn,
  IndustryColumn,
  SICColumn,
  NAICSColumn,
  CountryColumn,
  DoNotCallColumn,
  RevenueColumn,
  AlternateSalespersonColumn,
  RecentNotesColumn,
  AccountNumberColumn,
  CurrencyColumn,
  EmailColumn,
  DandBColumn,
  // PhonePrimarytColumn,
  MainContactColumn,
  // EmailprimaryColumn,
  AccountingContactColumn,
  IconColumn,
 // SourcingEmailColumn,
 SaleYearColumn,
  StateColumn
];
export const ColDefsCustomer: AgColDefsI[] = [
  ExpandableColumn,
  CompanyExpandableColumn,
  CompanyColumn,
  RatingColumn,
  StageColumn,
  ClassColumn,
  PhoneColumn,
  FaxColumn,
  SalespersonColumn,
  WebsiteColumn,
  EmployeesColumn,
  ActiveColumn,
  DateContactedColumn,
  TimezoneColumn,
  IndustryColumn,
  SICColumn,
  NAICSColumn,
  CountryColumn,
  DoNotCallColumn,
  RevenueColumn,
  AlternateSalespersonColumn,
  RecentNotesColumn,
  AccountNumberColumn,
  CurrencyColumn,
  EmailColumn,
  DandBColumn,
  // PhonePrimarytColumn,
  MainContactColumn,
  // EmailprimaryColumn,
  AccountingContactColumn,
  IconColumn,
 // SourcingEmailColumn,
 SaleYearColumn,
  StateColumn
];
export const ColDefsContacts: AgColDefsI[] = [
  // IconColumn,
  ContactNameColumn,

  ExpandableColumn,
  ContactCompanyColumn,
  RatingColumn,
  TitleColumn,
  DepartmentColumn,
  GenderColumn,
  LocationColumn,
  ContactTimezoneColumn,
  MainOfficePhoneColumn,
  DirectNumberColumn,
  MobileNumberColumn,
  ContactEmailColumn,
  MarkAsPrimaryContactColumn,
  MarkeAsAccountingContactColumn,
  DoNotCallColumn,
  DoNotEmail,
  ContactNotes,
  LastContacted,
  DueDate,
  ContactSkypeColumn,
  ContactXingColumn,
  ContactLinkedinColumn,
  ActiveColumn,
  // ContactFaxColumn,
  // PhoneColumn,
  // SalespersonColumn,
  // TypeColumn,
  // DateContactedColumn,
  // FaxColumn,
  // WebsiteColumn,
  // EmployeesColumn,
  // IndustryColumn,
  // SICColumn,
  // NAICSColumn,
  // CountryColumn,
  // RevenueColumn,
  // AlternateSalespersonColumn,
  // RecentNotesColumn,
  // AccountNumberColumn,
  // CurrencyColumn,
  // SourcingEmailColumn,
  // AccountingContactColumn,
  // StateColumn
]

export const ColDefsVendorList: AgColDefsI[] = [
  // VendorExpandableColumn,
  // CompanyExpandableColumn,
  VendorExpandableColumn,
  ...(window.innerWidth >= 1025 ? [CompanyExpandableColumn] : []),
  VendorNameColumn,
  IconColumn,
  RatingColumn,
  PhoneColumn,
  ActiveColumn,
  DateContactedColumn,
  QuickNoteColumn,
  VendorTypeColumn,
  AVLColumn,
  FaxColumn,
  WebsiteColumn,
  EmployeesColumn,
  TimezoneColumn,
  SICColumn,
  NAICSColumn,
  CountryColumn,
  AccountNumberColumn,
  CurrencyColumn,
  GeneralEmailColumn,
  DAndBNumberColumn,
  PrimaryContactNameColumn,
  PrimaryContactEmailColumn,
  PrimaryContactPhoneColumn,
  AccountingContactNameColumn,
  AccountingContactEmailColumn,
  StateColumn,
  ParentCompanyColumn,
  EntityTypeColumn,
  BuyersRepColumn,
];
export const ColDefsVendorContacts: AgColDefsI[] = [
  ContactNameColumn,
  VendorColumn,
  VendorRatingColumn,
  TitleColumn,
  DepartmentColumn,
  GenderColumn,
  LocationColumn,
  MainOfficePhoneColumn,
  TimezoneColumn,
  MainOfficeNumberColumn,
  DirectNumberColumn,
  MobileNumberColumn,
  // EmailAddressColumn,
  VendorEmailColumn,
  // MarkAsPrimaryColumn,
  // MarkeAsAccountingColumn,
  MarkAsPrimaryContactColumn,
  MarkeAsAccountingContactColumn,
  DoNotCallColumn,
  DoNotEmail,
  QuickNoteColumn,
  LastContact,
  //NextTaskDueDate,
  DueDate,
  ContactSkypeColumn,
  ContactXingColumn,
  ContactLinkedinColumn,
   ActiveColumn,
  //vendorStatusActiveColumn
];
export const ColDefsCompanyRecycleBin: AgColDefsI[] = [
  // ExpandableColumn,
  // CompanyExpandableColumn,
  // CompanyColumn,
  // StageColumn,
  // PhoneColumn,
  // SalespersonColumn,
  // EmailColumn,
  // TypeColumn,
  // DateContactedColumn,
  // RatingColumn,
  // ActiveColumn,
  // FaxColumn,
  // WebsiteColumn,
  // EmployeesColumn,
  // TimezoneColumn,
  // IndustryColumn,
  // SICColumn,
  // NAICSColumn,
  // CountryColumn,
  // DoNotCallColumn,
  // RevenueColumn,
  // AlternateSalespersonColumn,
  // RecentNotesColumn,
  // AccountNumberColumn,
  // CurrencyColumn,
  // SourcingEmailColumn,
  // AccountingContactColumn,
  // StateColumn,
  // IconColumn,
  ExpandableColumn,
  ...(window.innerWidth >= 1025 ? [CompanyExpandableColumn] : []),
   CompanyColumn,
   RatingColumn,
   StageColumn,
   ClassColumn,
   PhoneColumn,
   FaxColumn,
   SalespersonColumn,
   WebsiteColumn,
   EmployeesColumn,
   ActiveColumn,
   DateContactedColumn,
   TimezoneColumn,
   IndustryColumn,
   SICColumn,
   NAICSColumn,
   CountryColumn,
   DoNotCallColumn,
   RevenueColumn,
   AlternateSalespersonColumn,
   RecentNotesColumn,
   AccountNumberColumn,
   CurrencyColumn,
   EmailColumn,
   DandBColumn,
   MainContactColumn,
   AccountingContactColumn,
   IconColumn,
  SaleYearColumn,
   StateColumn
 
];
export const ColDefsCodeCrosswalk:AgColDefsI[] = [
  SicCodeColumn,
  SicDescription,
  NaiscCode,
  NaiscDescription,
  NaiscYear,
  CodeCrosswalkIndustry,
  CodeCrosswalkEdit,
  CodeCrosswalkActiveColumn,
  CodeCrosswalkDelete
];

export const ColDefsContactRecycleBin: AgColDefsI[] = [
  // ExpandableColumn,
  // ContactFullNameColumn,
  // ContactCompanyColumn,
  // RatingColumn,
  // DepartmentColumn,
  // TitleColumn,
  // LocationColumn,
  // MainOfficePhoneColumn,
  // ContactTimezoneColumn,
  // ActiveColumn,
  // ContactEmailColumn,
  // ContactFaxColumn,
  // ContactSkypeColumn,
  // ContactXingColumn,
  // ContactLinkedinColumn,
  // ContactNotes,
  ContactNameColumn,
  ExpandableColumn,
  ContactCompanyColumn,
  RatingColumn,
  TitleColumn,
  DepartmentColumn,
  GenderColumn,
  LocationColumn,
  ContactTimezoneColumn,
  MainOfficePhoneColumn,
  DirectNumberColumn,
  MobileNumberColumn,
  ContactEmailColumn,
  MarkAsPrimaryContactColumn,
  MarkeAsAccountingContactColumn,
  DoNotCallColumn,
  DoNotEmail,
  ContactNotes,
  LastContacted,
  DueDate,
  ContactSkypeColumn,
  ContactXingColumn,
  ContactLinkedinColumn,
  ActiveColumn,
];
export const AgGridDataModelCompanyArchived: AgGridI = {
  colDefs: ColDefsCompany,
  rowSelection: "multiple",
  // storageName:GridName.ARCHIVED
  ...(window.innerWidth >= 1025 ? { storageName: GridName.ARCHIVED } : {}),

};


export const AgGridDataModelCompany: AgGridI = {
  colDefs: ColDefsCompany,
  rowSelection: "multiple",
 // storageName:GridName.COMPANIES
 ...(window.innerWidth >= 1025 ? { storageName: GridName.COMPANIES } : {}),
};
export const AgGridDataModelLead: AgGridI = {
  colDefs: ColDefsLead,
  rowSelection: "multiple",
 // storageName:GridName.LEADS
 ...(window.innerWidth >= 1025 ? { storageName: GridName.LEADS } : {}),
};
export const AgGridDataModelProspect: AgGridI = {
  colDefs: ColDefsProspect,
  rowSelection: "multiple",
 // storageName:GridName.PROSPECTS
 ...(window.innerWidth >= 1025 ? { storageName: GridName.PROSPECTS } : {}),
};
export const AgGridDataModelCustomer: AgGridI = {
  colDefs: ColDefsCustomer,
  rowSelection: "multiple",
 // storageName:GridName.CUSTOMERS
 ...(window.innerWidth >= 1025 ? { storageName: GridName.CUSTOMERS } : {}),

};
export const AgGridDataModelContacts: AgGridI = {
  colDefs: ColDefsContacts,
  rowSelection: "multiple",
  storageName:GridName.CONTACTS

};

export const AgGridDataModelVendorList: AgGridI = {
  colDefs: ColDefsVendorList,
  rowSelection: "multiple",
  storageName:GridName.VENDORLISTS

};

export const AgGridDataModelVendorContacts: AgGridI = {
  colDefs: ColDefsVendorContacts,
  rowSelection: "multiple",
  storageName:GridName.VENDORCONTACTS

};
export const AgGridDataModelCompanyRecycleBin: AgGridI = {
  colDefs: ColDefsCompanyRecycleBin,
  rowSelection: "multiple",
  storageName:GridName.COMPANYRECYCLE

};
export const AgGridDataModelCodeCrosswalk: AgGridI = {
  colDefs: ColDefsCodeCrosswalk,
  rowSelection: "multiple",
  storageName:GridName.CODECROSSWALK

};
export const AgGridDataModelContactRecycleBin: AgGridI = {
  colDefs: ColDefsContactRecycleBin,
  rowSelection: "multiple",
  storageName:GridName.CONTACTRECYCLE

};


export class UserSetting {
  companies: TempField[] = [];
  leads: TempField[] = [];
  customers: TempField[] = [];
  prospects: TempField[] = [];
  contacts: TempField[] = [];
  vendorLists: TempField[] = [];
  vendorContacts: TempField[] = [];
  requirementLists: TempField[] = [];
  contactRecycle: TempField[] = [];
  companiesRecycle: TempField[] = [];
  pinnedValues: [] = []
  companiesFilter: any
  leadsFilter: any
  contactFilter: any
  prospectsFilter: any
  customersFilters: any
  vendorListFilters: any
  vendorContactsFilter:any
  requirementsFilter:any
}

export class TempField {
  name: string = '';
  index: number = 0;
}

const ipadMediaQuery = window.matchMedia("(min-width: 767px) and (max-width: 1025px)");
if (ipadMediaQuery.matches) {
 // ExpandableColumn.cellStyle. width = '3934px';
//  ExpandableColumn.cellStyle.background= 'brown'
CompanyColumn.cellStyle = CompanyColumn.cellStyle || {};
CompanyColumn.cellStyle.paddingLeft = '1.7em';
IconColumn.cellStyle = IconColumn.cellStyle || {};
IconColumn.cellStyle.paddingLeft = '2em';
}else{
  CompanyColumn.pinned = 'left';
  CompanyExpandableColumn.pinned = 'left';
  IconColumn.pinned = 'left';
  CompanyColumn.cellStyle = CompanyColumn.cellStyle || {};
  CompanyColumn.cellStyle.paddingLeft = '1.4em';
}
const ipadMediaQuery1 = window.matchMedia("(min-width: 767px) and (max-width: 1025px)");
if (ipadMediaQuery1.matches) {
VendorNameColumn.cellStyle = VendorNameColumn.cellStyle || {};
VendorNameColumn.cellStyle.paddingLeft = '1.7em';
IconColumn.cellStyle = IconColumn.cellStyle || {};
IconColumn.cellStyle.paddingLeft = '2em';
}else{
  VendorNameColumn.pinned = 'left';
  CompanyExpandableColumn.pinned = 'left';
  IconColumn.pinned = 'left';
  VendorNameColumn.cellStyle = VendorNameColumn.cellStyle || {};
  VendorNameColumn.cellStyle.paddingLeft = '1.4em';
}


export { GridName };


export const URLS = {
    user: {
        getUserDetails: "identity/users/getUserProfileDetailsById",
        getAllRoles: "identity/role/getAllRoles",
        getPermissionByRoleIds: "identity/permission/getPermissionByRoleId",
        updatePermissions: "identity/permission/updatePermissionByRoleId",
        roleCreate: "identity/role/createRole",
        roleUpdate: "identity/role/updateRole",
        deleteRole: "identity/role/deleteRole",
        getAllActiveRoles: 'identity/role/getActiveRoles',
        getUserById: "identity/users/getUserById",
        getAllPermissions: "identity/permission/getAllPermissions",
        insertPermissions: "identity/permission/insertPermissions",
        deletePermissions: "identity/permission/deletePermissions",
        getAllUsers: 'identity/users/getUsersList',
        updateUserStatus: 'identity/users/updateUserStatus',
        deleteUser: 'identity/users/deleteUser',
        insertAssignCommission: 'identity/users/addAssignSalesAndPurchaseCommission',
        getAllDropDownList: 'identity/dropDown/getAllDropDownList',
        createUser: 'identity/users/createUser',
        updateUser: 'identity/users/updateUser',
        getData: 'identity/dropDown/getUsersWithSalesPersonRoleList',
        adminResetPassword: 'identity/account/adminResetPassword',
        userDetails:'identity/users/getUserProfileDetailsById',
    },
    login:{
       signIn:"identity/account/signin",
       signOut:"identity/account/signOut" 
    }
};

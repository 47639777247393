<div
  *ngIf="!isSideBarOpend || width > 1024"
  class="d-flex flex-lg-wrap flex-md-wrap py-2 company-header-bar w-100">
  <div class="company-header d-flex col-md-12 col-lg-12 order-xl-1 order-lg-2 order-md-2
    {{currentTab == this.enumTabNames.PARTLIST ? 'col-xl-8':'col-xl-6' }}">
    <!-- <div
      *ngIf="isVisible"
      class="select-wrapper col-1 d-flex align-items-end position-relative">
      <select
        #mySelect
        class="w-100 p-l-l-15"
        [(ngModel)]="paginationBaseModel.stage"
        (change)="getCompanyDataByStageValue()"
        (click)="active = !active"
        [disabled]="currentTab === enumTabNames.REQUIREMENTS">
        <option
          value="All">
          All
        </option>
        <option
          *ngFor="let item of dropdownData"
          [selected]="item.name == 'All'"
          [value]="item.id"  [hidden]="item.name == 'Vendor'">
          {{ item.name }}
        </option>
      </select>
      <app-svg-icon
        class="active"
        [src]="'./assets/SVGIcons/arrow-down-icon.svg'"
        [ngClass]="{ 'arrow-up': active }">
      </app-svg-icon>
    </div> -->
    <div
    *ngIf="isVisible"
      class="select-wrapper col-1 d-flex align-items-end position-relative">
      <div ngbDropdown class="custom-dropdown p-l-l-15">
        <button
        [disabled]="currentTab === enumTabNames.REQUIREMENTS"
          class=" "
          ngbDropdownToggle
        >
          {{ currentSelection }}
          <app-svg-icon
            style="margin: -16px 9px;"
            class="active upIcon"
            [src]="'./assets/SVGIcons/arrow-down-icon.svg'"
          ></app-svg-icon>
        </button>
        <div class="intent custom-dropdown-menu" ngbDropdownMenu    
        >
          <div class="dropdown-item hand  text-gray" (click)="setCurrentSelection('All', 'All')">All</div>
          <div
            class="dropdown-item hand  text-gray"
            *ngFor="let item of dropdownDataList"
            (click)="setCurrentSelection(item.name, item.id)"
            [hidden]="item.name == 'Vendor'"
          >
          {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <app-search-input
    *ngIf="!route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
    [inputSearchPlaceholder]="
    currentTab == enumTabNames.VENDOR_LIST ? 'Search by vendor name...':
    currentTab == enumTabNames.REQUIREMENTS  || currentTab == enumTabNames.PARTLIST ? 'Search...'
    : (currentTab == enumTabNames.CONTACTS || currentTab == enumTabNames.CONTACT_RECYCLEBIN ||currentTab == enumTabNames.VENDOR_CONTACT
    ) ? 'Search by contact name…'
    : 'Search by company name…'"
      class="w-330px px-3 d-flex align-items-end"
      [ngClass]="{ 'ps-0': !isVisible }"
      [ngClass]="{'ps-11':!isVisible }"
      [inputSerachValue]="paginationBaseModel.searchText"
      (inputSerachValueEmit)="searchTextFilter((paginationBaseModel.searchText = $event))">
    </app-search-input>
    <app-search-input
    *ngIf="currentTab == this.enumTabNames.CONTACTS || currentTab == this.enumTabNames. VENDOR_CONTACT"
    [inputSearchPlaceholder]="currentTab == this.enumTabNames.CONTACTS?'Search by company name…':'Search by vendor name...'"    
    class="w-330px px-3 d-flex align-items-end"
    [ngClass]="{ 'ps-0': !isVisible }"
    [inputSerachValue]="paginationBaseModel.searchCompanyText"
    (inputSerachValueEmit)="searchTextFilter((paginationBaseModel.searchCompanyText = $event))">
  </app-search-input>
<!-- **********************Manufacturer List****************************** -->
 <div *ngIf="currentTab == this.enumTabNames.PARTLIST"
 class="d-flex gap-3">
  <label class="text-gray font-12 mb-0 d-flex align-items-center" for="">
    Manufacturer:
  </label>
  <app-common-dropdown
  [data]=""
  [selectValue]="partsListData.manufacturererId"
  (selectValueEmit)="partsListData.manufacturererId = $event"
  ></app-common-dropdown>
 
 </div>
 <app-checkbox
 class="px-3 mt-1"
 *ngIf="currentTab == this.enumTabNames.PARTLIST"
 [lableClass]="'text-light-gray'"
 (change)="partsListData.showConsignment =!partsListData.showConsignment"
 [status]="partsListData.showConsignment"
 [(ngModel)]="partsListData.showConsignment"
 [lableName]="'Show Consignment Only'"
 [dyanamicId]="'showConsignmentOnly'"
>
</app-checkbox>

 <!-- **********************Manufacturer List****************************** -->

    <app-button
      *ngIf="
        currentTab != this.enumTabNames.COMPANY_RECYCLEBIN &&
        currentTab != this.enumTabNames.CONTACT_RECYCLEBIN &&
        currentTab != this.enumTabNames.ARCHIVEDCOMPANIES && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)
      "
      class="mx-3"
      imgUrl="/assets/SVGIcons/Plus.svg"
      [buttonLable]="enumButtonName.FILTERS"
      [buttonClass]="'border-theme'"
      [inputReadonly]="isButtonDisabled"
      (click)="isLoading === false && currentTab !== enumTabNames.REQUIREMENTS && currentTab !== this.enumTabNames.PARTLIST  ? OpenFilterModal() : 0"
    ></app-button>
    <!-- (click)="isLoading === false ? OpenFilterModal() : 0" -->

    <div
      class="d-flex align-items-center px-3"
      (click)="clearAllFilter()"
      *ngIf="selectFilterOptionlist.length > 0">
      <app-svg-icon
        [src]="'./assets/SVGIcons/Clearicon.svg'"
        class="m-r-5">
      </app-svg-icon>
      <span class="text-nowrap clear-color font-13 m-t-2" type="button">
        Clear Filters
        </span>
    </div>
  </div>
  <div *ngIf="currentTab == this.enumTabNames.ARCHIVEDCOMPANIES"
   class="company-header button-bar d-flex col-xl-6 col-md-12 col-lg-12 justify-content-end order-xl-2 order-lg-1 order-md-1">
    <app-button
    class="mx-1"
    imgUrl=""
    [buttonLable]="enumButtonName.UNARCHIVE"
    [inputReadonly]="selectedData?.length == 0"
    [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '"
    (click)="Unarchive()">
   </app-button>

  </div>



  <div *ngIf="currentTab != this.enumTabNames.COMPANY_RECYCLEBIN && currentTab != this.enumTabNames.CONTACT_RECYCLEBIN 
      && currentTab != this.enumTabNames.ARCHIVEDCOMPANIES"
      class="company-header button-bar d-flex  col-md-12 col-lg-12 justify-content-end order-xl-2 order-lg-1 order-md-1
      {{currentTab == this.enumTabNames.PARTLIST ? 'col-xl-4':'col-xl-6' }}">
    <!-- *ngIf="currentTab == 'Contacts' && add_Contact_Button" -->
    <app-button
      *ngIf="currentTab == this.enumTabNames.CONTACTS"
      class="mx-1"
      imgUrl=""
      [buttonLable]="enumButtonName.ADDCONTACT"
      (click)="openAddContactModal()">
    </app-button>

    <!-- *ngIf="currentTab !== 'Contacts' && add_Company_Button" -->
    <app-button
      *ngIf="currentTab != this.enumTabNames.CONTACTS && currentTab != this.enumTabNames.PARTLIST && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
      class="mx-1"
      imgUrl=""
      [buttonLable]="buttonName"
      (click)="currentTab === enumTabNames.REQUIREMENTS ? openAddRequiremnt() 
      : (currentTab !== enumTabNames.VENDOR_CONTACT ? openAddEditCompanyModal() : openAddContactModal())"
        >
        </app-button>
    <!-- *ngIf="currentTab !== 'Contacts' && assign_Button" -->

    <app-button
    *ngIf="currentTab !== this.enumTabNames.REQUIREMENTS && currentTab !== this.enumTabNames.PARTLIST && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
      class="mx-1"
      imgUrl=""
      [buttonLable]="enumButtonName.ADDTASK"
      (click)="selectedData?.length != 0 ? open_AddTaskComponent() : ''"
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
    </app-button>

    <app-button
      *ngIf="currentTab !== this.enumTabNames.CONTACTS && currentTab !== this.enumTabNames.VENDOR_CONTACT  && currentTab !== this.enumTabNames.REQUIREMENTS &&  currentTab !== this.enumTabNames.PARTLIST && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)" 
      class="mx-1"
      imgUrl=""
      [buttonLable]="enumButtonName.ASSIGN"
      (click)="openAssignModal()"
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
    </app-button>

    <app-button 
    *ngIf="currentTab !== this.enumTabNames.REQUIREMENTS &&  currentTab !== this.enumTabNames.PARTLIST && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
      class="mx-1"
      imgUrl=""
      [buttonLable]="enumButtonName.DELETE"
      (click)="openDelteModal()"
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-red' : 'border-grey cursor-notAllowed '">
    </app-button>

    <app-button
    *ngIf="
      !isVisible &&
      currentTab !== this.enumTabNames.REQUIREMENTS &&
      currentTab !== this.enumTabNames.CONTACTS &&
      currentTab !== this.enumTabNames.VENDOR_LIST &&
      currentTab !== this.enumTabNames.VENDOR_CONTACT &&  currentTab !== this.enumTabNames.PARTLIST && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
    class="mx-1"
    imgUrl=""
    buttonLable="Archive"
    (click)="selectedDataArchive()"
    [inputReadonly]="selectedData?.length == 0"
    [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
  </app-button>
  <app-button
  *ngIf=" currentTab == this.enumTabNames.COMPANIES"
  class="mx-1"
  imgUrl=""
  buttonLable="Archive"
  (click)="selectedDataArchive()"
  [inputReadonly]="selectedData?.length == 0"
  [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
</app-button>

    <!-- *******Mailing button add*************** -->
    <app-button
    *ngIf="currentTab == this.enumTabNames.COMPANIES || currentTab == this.enumTabNames.LEADS || currentTab == this.enumTabNames.PROSPECTS || currentTab == this.enumTabNames.CUSTOMERS" 
    class="mx-1"
    imgUrl=""
    [buttonLable]="enumButtonName.ADDMAILINGLIST"
    (click)="openMailingListModal()"
    [inputReadonly]="selectedData?.length == 0"
    [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
  </app-button>
      <!-- *******Mailing button add*************** -->


    <!-- *ngIf="delete_Button" -->
    <!-- <app-button 
    *ngIf="currentTab !== this.enumTabNames.REQUIREMENTS"
      class="mx-1"
      imgUrl=""
      [buttonLable]="enumButtonName.DELETE"
      (click)="openDelteModal()"
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-red' : 'border-grey cursor-notAllowed '">
    </app-button> -->

    <!-- *ngIf="add_Task_Button" -->
    <!-- <app-button
    *ngIf="currentTab !== this.enumTabNames.REQUIREMENTS"
      class="mx-1"
      imgUrl=""
      [buttonLable]="enumButtonName.ADDTASK"
      (click)="selectedData?.length != 0 ? open_AddTaskComponent() : ''"
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
    </app-button> -->
    <!-- *ngIf="currentTab == 'Contacts' && send_Email_Button" -->
    <app-button
      *ngIf="currentTab == this.enumTabNames.CONTACTS || currentTab == this.enumTabNames.VENDOR_CONTACT"
      class="mx-1"
      imgUrl=""
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '"
      [buttonLable]="enumButtonName.SENDEMAIL">
    </app-button>
    <!-- !isVisible && -->

    <!-- <app-button
      *ngIf="
        !isVisible &&
        currentTab !== this.enumTabNames.CONTACTS &&
        currentTab !== this.enumTabNames.VENDOR_LIST &&
        currentTab !== this.enumTabNames.VENDOR_CONTACT"
      class="mx-1"
      imgUrl=""
      buttonLable="Archive"
      (click)="selectedDataArchive()"
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
    </app-button>
    <app-button
    *ngIf=" currentTab == this.enumTabNames.COMPANIES"
    class="mx-1"
    imgUrl=""
    buttonLable="Archive"
    (click)="selectedDataArchive()"
    [inputReadonly]="selectedData?.length == 0"
    [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
  </app-button> -->

    <app-button
    *ngIf="currentTab == this.enumTabNames.REQUIREMENTS"
    class="mx-1"
    imgUrl=""
    [buttonLable]="enumButtonName.ADDTOQUOTE"
    [inputReadonly]="selectedRequirementData?.length == 0"
    [buttonClass]="selectedRequirementData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
  </app-button>
  <app-button
  *ngIf="currentTab == this.enumTabNames.REQUIREMENTS"
  class="mx-1"
  imgUrl=""
  [buttonLable]="enumButtonName.SENDTOSOURCING"
  [inputReadonly]="selectedRequirementData?.length == 0"
  [buttonClass]="selectedRequirementData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
</app-button>
    <span class="download-icon onhover-dropdown onhover-dropdown b-r-8 px-1"  *ngIf="currentTab !== this.enumTabNames.PARTLIST && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
    >
      <app-button
        [button_2]="true"
        [buttonClass]="'button button-primary'"
        [buttonImageUrl]="'./assets/SVGIcons/downloadicon.svg'">
      </app-button>
      <ul class="onhover-show-div">
        <li (click)="downloadPDF('pdf')" class="pb-1 f-11  text-gray text-nowrap">
          Export as PDF
        </li>
        <li (click)="downLoadExcel()" class="f-11  text-gray text-nowrap">
          Export as XLS
        </li>
      </ul>
    </span>
    <!-- *ngIf="currentTab !== 'Contacts' && import_Companies_Button" -->
    <span  
      *ngIf="currentTab == this.enumTabNames.REQUIREMENTS"
      class="download-icon onhover-dropdown onhover-dropdown b-r-8 px-2">
      <app-button
        [button_2]="true"
        [buttonClass]="'button button-primary'"
        [buttonLable]="enumButtonName.ADDOFFER">
      </app-button>
      <ul class="onhover-show-div addoffer">
        <li (click)="addOfferForm('offerForm')" class="pb-1 f-11 text-light-gray text-nowrap">
         Offer Form
        </li>
        <li (click)="addOfferForm('importForm')" class="f-11 text-light-gray text-nowrap">
          Import Offer
        </li>
      </ul>
    </span>
  
    <app-button
      *ngIf="currentTab !== this.enumTabNames.CONTACTS && currentTab != this.enumTabNames.VENDOR_CONTACT  && currentTab !== this.enumTabNames.REQUIREMENTS && currentTab !== this.enumTabNames.PARTLIST && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
      class="mx-1"
      imgUrl=""
      [buttonLable]="buttonImport"
      buttonClass="'border-theme'"
      (click)="navigateToImportCompnaies(buttonImport)">
    </app-button>
 <!-- *****************TASK LIST BUTTONS******************************* -->
    <app-button
    *ngIf="currentTab == this.enumTabNames.PARTLIST"
    class="mx-1"
    imgUrl=""
    [buttonLable]="buttonName" >
 </app-button>
  <div  *ngIf="currentTab == this.enumTabNames.PARTLIST || route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
  appClickOutside (clickOutside)="closeList()"
  class="d-flex position-relative">
    <app-button
    class="mx-1 {{route.includes(routerPath.PARTLISTINFO_ROUTEPATH)?'d-xl-flex d-md-none':''}}"
    imgUrl=""
    [buttonLable]="buttonImport"
    buttonClass="'border-theme'"
    (click)="showlist = !showlist">
  </app-button>
  <div class="list-wrap" style="width: 100px;" *ngIf="showlist">
    <ol
      class="add-list-item"
      *ngFor="let listitem of addiconList; let i = index;let f=first" >
      <li>
        <span
        (click)="seletedItem(i);" 
          [ngClass]="{ activeName: listitem.selected}"
          >{{ listitem.name }}</span
        >
      </li>
    </ol>
  </div>

  </div>
  <app-button
  *ngIf="route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
  class="mx-1 {{route.includes(routerPath.PARTLISTINFO_ROUTEPATH)?'d-xl-flex d-md-none':''}}"
  imgUrl=""
  [buttonLable]="buttonName" >
</app-button>

  <div  *ngIf="currentTab == this.enumTabNames.PARTLIST || route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
  appClickOutside (clickOutside)="closeNewList()"
  class="d-flex position-relative">
    <app-button
    class="mx-1 {{route.includes(routerPath.PARTLISTINFO_ROUTEPATH)?'d-xl-flex d-md-none':''}}"
    imgUrl=""
    [buttonLable]="enumButtonName.NEW"
    [inputReadonly]="selectedData?.length == 0 && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
    [buttonClass]="selectedData?.length != 0? 'border-theme'
    : route.includes(routerPath.PARTLISTINFO_ROUTEPATH)? '': 'border-grey cursor-notAllowed' "
    (click)="(selectedData?.length != 0 || route.includes(routerPath.PARTLISTINFO_ROUTEPATH)) ?showNewlist = !showNewlist:''">
  </app-button>
  <div class="list-wrap" style="width: 120px;" *ngIf="showNewlist">
    <ol
      class="add-list-item"
      *ngFor="let listitem of addNewList; let i = index;let f=first" >
      <li>
        <span
          (click)="seletedNewItem(i);" 
          [ngClass]="{ activeName: listitem.selected}"
          >{{ listitem.name }}</span
        >
      </li>
    </ol>
  </div>

  </div>
  <app-button 
  *ngIf="currentTab == this.enumTabNames.PARTLIST || route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
    class="mx-1 {{route.includes(routerPath.PARTLISTINFO_ROUTEPATH)?'d-xl-flex d-md-none':''}}"
    imgUrl=""
    [buttonLable]="enumButtonName.CLONE"
    [inputReadonly]="selectedData?.length == 0 && !route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
    [buttonClass]="selectedData?.length != 0? 'border-theme'
    : route.includes(routerPath.PARTLISTINFO_ROUTEPATH)? '': 'border-grey cursor-notAllowed' ">
  </app-button>
  <app-button 
  *ngIf="currentTab == this.enumTabNames.PARTLIST"
    class="mx-1"
    imgUrl=""
    [buttonLable]="enumButtonName.DELETE"
    (click)="openDelteModal()"
    [inputReadonly]="selectedData?.length == 0"
    [buttonClass]="selectedData?.length != 0 ? 'border-red' : 'border-grey cursor-notAllowed '">
  </app-button>
  <!-- ******************RESPONSIVE IPAD PRO********************** -->
  <div *ngIf="currentTab == route.includes(routerPath.PARTLISTINFO_ROUTEPATH)"
    class="d-flex d-xl-none d-md-flex">
    <div class="">
      <div ngbDropdown class="">
        <img
          src="./assets/SVGIcons/threedot.svg"
          alt=""
          id="dropdownConfig"
          ngbDropdownToggle
          width="35"
          height="35"
        />

        <div
          ngbDropdownMenu
          aria-labelledby="dropdownConfig"
          style="color:#8B9DAA;font-weight: 600;"
        >
        <span class="px-2 font-11 text-light-gray">Import</span> <br>  
        <span class="px-2 font-11 text-light-gray">Edit Part</span> <br>  
        <span class="px-2 font-11 text-light-gray">New</span> <br>  
        <span class="px-2 font-11 text-light-gray">Clone</span><br>  
        </div>
      </div>
    </div>
  </div>
  <!-- ******************RESPONSIVE IPAD PRO********************** -->


  <!-- *****************TASK LIST BUTTONS******************************* -->


    <app-svg-icon
      [src]="'./assets/SVGIcons/pin.svg'"
      class="p-l-5 d-flex align-items-center"
      (click)="pinned = !pinned; openFavouriteDockPopup(pinned)"
      [ngClass]="pinned == true ? 'pinnedColor' : ''">
    </app-svg-icon>

  </div>
  <!-- for company-recycle-Bin and contact-recycle-Bin start-->
  <div 
    *ngIf="currentTab == this.enumTabNames.COMPANY_RECYCLEBIN || currentTab == this.enumTabNames.CONTACT_RECYCLEBIN"
    class="company-header button-bar d-flex col-xl-6 col-md-12 col-lg-12 justify-content-end order-xl-2 order-lg-1 order-md-1">
    <div class="d-flex">
      <app-button
        class="mx-1"
        imgUrl=""
        [inputReadonly]="selectedData?.length == 0"
        [buttonClass]="selectedData?.length != 0 ? 'border-red' : 'border-grey cursor-notAllowed '"
        (click)="restore(currentTab)"
        [buttonLable]="enumButtonName.RESTORE">
      </app-button>
      <app-button
        class="mx-1"
        imgUrl=""
        [buttonClass]="selectedData?.length != 0 ? 'border-red' : 'border-grey cursor-notAllowed '"
        [buttonLable]="enumButtonName.APPROVEDELETION"
        (click)="approveDeletion(currentTab)"
      ></app-button>
    </div>
  </div>
  <!-- for company-recycle-Bin and contact-recycle-Bin end-->
</div>
<!-- When we open side bar for I Pad Pro-->
<div
  *ngIf="isSideBarOpend && width <= 1024"
  class="d-flex flex-column flex-lg-wrap flex-md-wrap py-2 company-header-bar w-100">
  <div class="d-flex">
    <app-search-input
      inputSearchPlaceholder="Search by company name…"
      class="w-330px px-3 d-flex align-items-end"
      [ngClass]="{ 'ps-0': !isVisible }"
      [inputSerachValue]="paginationBaseModel.searchText"
      (inputSerachValueEmit)="
        searchTextFilter((paginationBaseModel.searchText = $event))">
      </app-search-input>
    <app-button
      class="mx-1"
      imgUrl=""
      [buttonLable]="enumButtonName.ASSIGN"
      (click)="openAssignModal()"
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '"
    ></app-button>
    <app-button
      class="mx-1"
      imgUrl=""
      [buttonLable]="enumButtonName.DELETE"
      (click)="openDelteModal()"
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-red' : 'border-grey cursor-notAllowed '">
    </app-button>
    <app-button
      class="mx-1"
      imgUrl=""
      [buttonLable]="enumButtonName.ADDTASK"
      [inputReadonly]="selectedData?.length == 0"
      [buttonClass]="selectedData?.length != 0 ? 'border-theme' : 'border-grey cursor-notAllowed '">
    </app-button>
  </div>
  <div class="company-header d-flex col-xl-6 col-md-12 col-lg-12 mt-3">
    <div
      *ngIf="isVisible"
      class="select-wrapper col-1 d-flex align-items-end position-relative">
      <select
        #mySelect
        class="w-100 p-l-15"
        [(ngModel)]="paginationBaseModel.stage"
        (change)="getCompanyDataByStageValue()"
        (click)="active = !active">
        <option
          *ngFor="let item of dropdownData"
          [selected]="item.name == 'All'"
          [value]="item.name">
          {{ item.name }}
        </option>
      </select>
      <app-svg-icon
        class="active"
        [src]="'./assets/SVGIcons/arrow-down-icon.svg'"
        [ngClass]="{ 'arrow-up': active }" >
      </app-svg-icon>
    </div>
    <app-button
      class="px-3"
      imgUrl="/assets/SVGIcons/Plus.svg"
      [buttonLable]="enumButtonName.FILTERS"
      [buttonClass]="'border-theme'"
      (click)="isLoading === false ? OpenFilterModal() : 0">
    </app-button>
    <div
      class="d-flex align-items-center px-3"
      (click)="clearAllFilter()"
      *ngIf="selectFilterOptionlist.length > 0">
      <app-svg-icon
        [src]="'./assets/SVGIcons/Clearicon.svg'"
        class="m-r-5">
      </app-svg-icon>
      <span 
      class="text-nowrap clear-color font-13 m-t-2" 
      type="button">Clear Filters</span>
    </div>
  </div>
</div>
<!-- flex-wrap -->

<div
  style="width: 100%"
  [ngClass]="{
    ipadPro: isSideBarOpend && width == 1024,
    ipad: isSideBarOpend && width == 768,
    ipadClose: !isSideBarOpend && width == 678,
    'd-none': selectFilterOptionlist.length == 0}"
  #fileterButton
  class="d-flex justify-content-between mb-2">
  <div
    #fileterButton
    class="d-flex align-items-center filterBar"
    [ngClass]="{
      'flex-wrap': show == selectFilterOptionlist.length,
      'justify-content-evenly':
        show !== selectFilterOptionlist?.length &&
        selectFilterOptionlist.length - show > 0}">
    <span
      class="px-1"
      *ngFor="
        let item of selectFilterOptionlist | slice : 0 : show;
        let i = index"
      #buttonRefs>
      <app-button
      [ngbTooltip]="formatTooltipLabel(item.labelName == 'Class' ? 'Type' : item.labelName)"
        placement="bottom-left"
        class="primary-bg"
        (click)="removeFilterTab(item)"
        imgUrl="/assets/SVGIcons/removeIcon.svg"
        [buttonLable]="item.name">
      </app-button>
    </span>
  </div>
  <span
    class="d-flex align-items-center hand showOther end-0"
    *ngIf="
      show !== selectFilterOptionlist?.length &&
      selectFilterOptionlist.length - show > 0"
      (click)="showOther()">
    <span class="filter-btn ms-2 nowrap" [ngClass]="{ 'p-l-25': width == 768 }">
      +{{selectFilterOptionlist.length - show ? selectFilterOptionlist.length - show : 0 }} other
      </span>
  </span>
  <div *ngIf="!isSideBarOpend || width > 1024 || toggle">
    <div
      *ngIf="selectFilterOptionlist.length - show > 0 || toggle"
      class="d-flex mt-1 py-2">
      <div (click)="seeAllorLess()" class="hand">
        <span class="text-light-gray font-11 me-2">
          {{ toggle == false ? "See All" : " See Less" }}
        </span>
        <img
          [src]="toggle == false ? downArrow : upArrow"
          alt=""
          width="14"
          height="14"
        />
      </div>
    </div>
  </div>
</div>

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import flatpickr from 'flatpickr';
import { Options as FlatpickrOptions } from 'flatpickr/dist/types/options';
import { Instance as FlatpickrInstance } from 'flatpickr/dist/types/instance';
import { UserManagementService } from 'src/app/components/admin/user-management/pages/user-management/user-management.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-single-date-select-picker',
  templateUrl: './single-date-select-picker.component.html',
  styleUrls: ['./single-date-select-picker.component.scss']
})
export class SingleDateSelectPickerComponent implements OnInit {
  @ViewChild('flatpickrInput', { static: true }) flatpickrInput!: ElementRef<HTMLInputElement>;
  private flatpickrInstance!: FlatpickrInstance;
  clearSelectedDate:boolean = false;
  @Input()isActivity:boolean=false;
  selectedDateFormat:string;
  @Output() changeDateExpiration: EventEmitter<string> = new EventEmitter<string>();
  @Output() clearDate: EventEmitter<string> = new EventEmitter<string>();

  selectedDate: string = ''; 
  hideIcon: boolean = false;

  constructor(private renderer: Renderer2,) { }

  ngOnInit(): void {
    this.initFlatpickr();
  }

  private initFlatpickr(): void {
    let selectedDateStr = "";
    const options: FlatpickrOptions = {
      mode: "single",
      dateFormat: "m/d/y",
      onChange: (selectedDates, dateStr, instance) => {
        if (Array.isArray(selectedDates)) {
          const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          };
          const startDateStr = selectedDates[0].toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
          this.changeDateExpiration.emit(startDateStr)
          selectedDateStr = startDateStr;
        } 
        this.flatpickrInput.nativeElement.value = selectedDateStr;
      //  this.hideIcon = this.flatpickrInput.nativeElement.value ? false : true;
      this.selectedDate = this.flatpickrInput.nativeElement.value;


       
      }
    };
    this.flatpickrInstance = flatpickr(this.flatpickrInput.nativeElement, options); 
   }
   openDatepicker(): void {
    // this.flatpickrInstance.open();
    this.renderer.selectRootElement(this.flatpickrInput.nativeElement).click();
  }
  clearSeletedDate(){
    this.flatpickrInstance.clear(); 
    this.flatpickrInput.nativeElement.value = '';
 //   this.userManagementService.selectedDateRange.next( this.flatpickrInput.nativeElement.value);
    this.clearSelectedDate = false;
  }
  clear() {
    //f.value.dateExpiration = '';
    this.selectedDate = '';
 //  this.clearDate.emit("")
 this.flatpickrInput.nativeElement.value = '';
  this.changeDateExpiration.emit(undefined)
  }

  changeDateExpirations(event){
    this.changeDateExpiration.emit(event)
  }

}

<div class="d-flex w-100 input_searchContainer position-relative">
    <app-svg-icon
      [src]="'../../../../../assets/SVGIcons/searchicon.svg'"   
      class="searchIcon position-absolute top-0 start-0">
    </app-svg-icon>
    <!-- <img src="./assets/SVGIcons/searchicon.svg" alt=""> -->
    <input
      type="text"
      id="quickFilter" 
      autocomplete="off"
      [(ngModel)]="inputSerachValue"
      (ngModelChange)="clearSearch()"
      (keyup.enter)="inputSerachValueEmit.emit(inputSerachValue)"
      [placeholder]="inputSearchPlaceholder"
      class="px-4 search_input bg-transparent"
    />
    <app-svg-icon 
    *ngIf="inputSerachValue"
      (click)="inputSerachValue='';inputSerachValueEmit.emit(inputSerachValue)"
      [src]="'../../../../../assets/SVGIcons/close-icon.svg'"    
      class="hand clearSearchInput position-absolute">
    </app-svg-icon>
  </div>
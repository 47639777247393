import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numberFormat]'
})
export class NumberFormatDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any): void {
      const inputElement = event.target;
      const inputValue = inputElement.value;
  
      // Remove non-numeric characters
      const numericInput = inputValue.replace(/[^0-9]/g, '');
  
      // Format the number
      const formattedValue = this.formatNumber(numericInput);
      
      // Set the formatted value back to the input element
      inputElement.value = formattedValue;
  
      // Manually trigger the input event to notify Angular of the change
      const newEvent = new Event('input', { bubbles: true });
      inputElement.dispatchEvent(newEvent);
  }
  
  private formatNumber(value: string): string {
      if (value === '') {
          return value;
      }
  
      const numericValue = parseFloat(value);
      const formattedValue = numericValue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
      });
      return formattedValue.replace(/\.00$/, ''); // Remove trailing '.00'
  }
  
  
}

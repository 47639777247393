  <ng-container [ngSwitch]="title" >
    <!-- mark as Active -->
    <div *ngSwitchCase="enumPopUpTitles.MARK_AS_ACTIVE">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4">
        <div class="text-center">
          <p *ngIf="pageType !=='multilevel'" class="m-0 text-light-gray font-12 my-auto">You are about to make a {{pageType}} active.</p>
          <!-- <p class="m-0 text-light-gray" *ngIf="pageType !=='multilevel'"></p> -->
          <p class="m-0 text-light-gray font-12"> Are you sure you want to mark<br />
            <span class="as-active text-break text-primary-color font-12">{{fullName | titlecase }}</span> as
            active?
          </p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info font-11'" class="me-3" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CANCEL"></app-button>
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-primary font-11'" class="ms-3" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.CONFIRM" (click)="activeModal.close('Close click')"></app-button>
        </div>
      </div>
    </div>
    <!-- Mark as active end  -->

    <!-- mark as InActive -->
    <div *ngSwitchCase="enumPopUpTitles.MARK_AS_INACTIVE">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4">
        <div class="text-center">
          <p *ngIf="pageType !=='multilevel'" class="m-0 text-light-gray font-12">You are about to make a {{pageType}} inactive.</p>
          <!-- <p class="m-0 text-light-gray" *ngIf="pageType !=='multilevel'"></p> -->
          <p class="m-0 text-light-gray font-12"> Are you sure you want to mark<br />
            <span class="as-active text-break text-primary-color font-12">{{fullName | titlecase }}</span> as
            inactive?
          </p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info font-11'" class="me-3" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CANCEL"></app-button>
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-primary font-11'" class="ms-3" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.CONFIRM" (click)="activeModal.close('Close click')"></app-button>
        </div>
      </div>
    </div>
    <!-- Mark as Inactive end  -->

    <!-- Delete PopUp start  -->
    <div *ngSwitchCase="enumPopUpTitles.DELETE">
      <div *ngIf="!deleteForAdmin">
        <div class="modal-header p-4 pb-0 border-0 justify-content-center">
          <h6 class="modal-title mt-3 text-center text-danger-color font-15">{{title}}</h6>
        </div>
        <div class="modal-body p-4">
          <div class="text-center">
            <p *ngIf="pageType !=='multilevel' && pageType !=='addresses' && pageType !=='contacts' && pageType !=='vendor-contacts'" class="m-0 text-light-gray font-12">You are about to delete a {{pageType}} record.</p>
            <!-- <p class="m-0 text-light-gray" *ngIf="pageType !=='multilevel'"></p> -->
            <p class="m-0 text-light-gray font-12"> Are you sure you want to delete
              <span class="as-active text-break text-primary-color font-12 my-auto">{{fullName | titlecase}}</span><br /> 
              <!-- from the {{pageType=='multilevel'?'':pageType}} list? -->
              from the  {{ pageType == 'company' ? 'company' : pageType == 'multilevel' ? '' : pageType }} list?
            </p>
          </div>
        </div>
        <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
          <div class="mb-2">
            <app-button [buttonType]="'button'" class="me-3"  [button_2]="true" [buttonClass]="'button button-info px-2 font-11'" (click)="activeModal.close({title,confirm:false})"[buttonLable]="enumButtonName.CANCEL"></app-button>
            <app-button *ngIf=" pageType !=='addresses'"
             [buttonType]="'button'" class="ms-3" [button_2]="true" [buttonClass]="'button button-danger px-2 font-11'" (click)="deletePopup()" [buttonLable]="enumButtonName.DELETE"></app-button>
            <app-button *ngIf=" pageType =='addresses'"
             [buttonType]="'button'" class="ms-3" [button_2]="true" [buttonClass]="'button button-danger px-2 font-11'" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.DELETE"></app-button>

          </div>
        </div>
      </div>
      <!-- delete confirmation popup for admin And Non-Admin start -->
      <div div *ngIf="deleteForAdmin">
        <div class="modal-header p-4 pb-0 border-0 justify-content-center">
          <h6 class="modal-title mt-3 text-center text-danger-color font-15">{{title}}</h6>
        </div>
        <div class="modal-body p-4">
          <div class="text-center">
            <p class="m-0 text-light-gray font-12 text-truncate my-auto">{{ResMsgFromCompanyOrContact}}</p>
          </div>
        </div>
        <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
          <div class="mb-2">
            <app-button [buttonType]="'button'" class=""  [button_2]="true" [buttonClass]="'button button-info px-2 font-11'" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.OKAY"></app-button>
          </div>
        </div>
      </div>
      <!-- delete confirmation popup for admin And Non-Admin End-->
    </div>
    <!-- Delete PopUp End   -->

    <!-- Password Changed PopUp start -->
    <div *ngSwitchCase="enumPopUpTitles.PASSWORD_CHANGED">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4">
        <div class="text-center">
          <p class="m-0 text-light-gray"> Password was changed for <span class="as-active text-break text-primary-color">{{fullName | titlecase }}</span><br /> 
          </p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button type="button" class="" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.OKAY" (click)="activeModal.close('Close click')"></app-button>
        </div>
      </div>
    </div>
    <!-- Password Changed PopUp End -->

    <!-- Reset PopUp start  -->
    <div *ngSwitchCase="enumPopUpTitles.RESET_PASSWORD">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4 pt-3">
        <div class="text-center">
          <div class="text-start">
            <p class="m-0 text-light-gray p-l-40 font-12">Reset password for <span class="as-active text-break text-primary-color">{{fullName | titlecase}}</span></p>
          </div>
          <p class="m-0 text-light-gray pt-1 font-12">A link was sent to the registered<br>
            email address.
          </p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button type="button" class="" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.OKAY" (click)="activeModal.close('Close click')"></app-button>
        </div>
      </div>
    </div>
    <!-- Reset PopUp end  -->

    <!-- FAVOURITE_DOCKS start  -->
    <div *ngSwitchCase="enumPopUpTitles.FAVOURITE_DOCKS">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4 pt-3">
        <div class="text-center">
          <div class="">
            <p class="m-0 text-light-gray">Are you sure want to remove<br>this page from your Favorites dock?
            </p>
          </div>
          
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button  (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.NO" [buttonType]="'button'" class="me-3"  [button_2]="true" [buttonClass]="'button button-info px-3 font-11'" ></app-button>
          <app-button  (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.YES" [buttonType]="'button'" class="ms-3" [button_2]="true" [buttonClass]="'button button-danger px-3 font-11'"></app-button>

        </div>
      </div>
    </div>
    <!-- FAVOURITE_DOCKS end  -->
    <!-- Take all permission start  -->
    <div *ngSwitchCase="enumPopUpTitles.TAKEPERMISSION">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4 pt-3">
        <div class="text-center">
          <p class="m-0 text-light-gray">Are you sure you want to Take <br>
            all permissions from ? <br>
            This will take all the administrative rights from the role.
          </p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button [buttonType]="'button'" class="me-3" [button_2]="true" [buttonClass]="'button button-info font-11'" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CANCEL"></app-button>
          <app-button [buttonType]="'button'" class="ms-3" [button_2]="true" [buttonClass]="'button button-primary font-11'" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.CONFIRM"></app-button>
        </div>
      </div>
    </div>
    <!-- Take all permission PopUp end  -->

    <!--  grant permission PopUp end  -->
    <div *ngSwitchCase="enumPopUpTitles.GRANTPERMISSION">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4 pt-3">
        <div class="text-center">
          <p class="m-0 text-light-gray">Are you sure you want to grant <br>
            all permissions?<br>
            This will give the role all administrative rights.
          </p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button [buttonType]="'button'" class="me-3" [button_2]="true" [buttonClass]="'button button-info font-11'" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CANCEL"></app-button>
          <app-button [buttonType]="'button'" class="ms-3"  [button_2]="true" [buttonClass]="'button button-primary font-11'" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.CONFIRM"></app-button>
        </div>
      </div>
    </div>
    <!-- grant permission PopUp end  -->


    <!--  UNSAVECHANGES PopUp end  -->
    <div *ngSwitchCase="enumPopUpTitles.UNSAVECHANGES">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4 pt-3">
        <div class="text-center">
          <p class="m-0 text-light-gray">Your changes have not been saved.<br>
            Do you want to save changes<br>
            before closing?
          </p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button [buttonType]="'button'" class="me-3" [button_2]="true" [buttonClass]="'button button-danger font-11'" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CLOSE_TAB"></app-button>
          <app-button [buttonType]="'button'" class="ms-3" [button_2]="true" [buttonClass]="'button button-primary font-11'" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.SAVE"></app-button>
        </div>
      </div>
    </div>

    <!--  UNSAVECHANGES PopUp end  -->


    <!--  SaveChanges PopUp start -->
    <div *ngSwitchCase="enumPopUpTitles.SAVECHANGES">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4 pt-3">
        <div class="text-center">
          <p class="m-0 text-light-gray">Are you sure you want to save these changes</p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button [buttonType]="'button'" class="me-3" [button_2]="true" [buttonClass]="'button button-info px-4 font-11'" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.NO"></app-button>
          <app-button [buttonType]="'button'" class="ms-3" [button_2]="true" [buttonClass]="'button button-primary px-4 font-11'" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.YES"></app-button>
        </div>
      </div>
    </div>
    <!-- SaveChanges PopUp end  -->


    <!-- mark as Active -->
    <div *ngSwitchCase="enumPopUpTitles.REPLACE_PRIMARY_CONTACT">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4">
        <div class="text-center">
          <p  class="m-0 text-light-gray font-12">
            There is already a primary contact for this company.<br>
            Do you want to replace the current<br>
            primary contact with this one?</p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
        <div class="mb-2">
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info font-11'" class="me-3" (click)="activeModal.close({title,confirm:false});closedPopup()" [buttonLable]="enumButtonName.CANCEL"></app-button>
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-primary font-11'" class="ms-3" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.CONFIRM" (click)="activeModal.close('Close click')"></app-button>
        </div>
      </div>
    </div>
    <!-- Mark as active end  -->

        <!--  SaveChanges PopUp start -->
        <div *ngSwitchCase="enumPopUpTitles.POPUP_VALUE">
          <div class="modal-header p-4 pb-0 border-0 justify-content-center">
            <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
          </div>
          <div class="modal-body p-4 pt-3">
            <div class="text-center">
              <p class="m-0 text-light-gray">{{message}}</p>
            </div>
          </div>
          <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
            <div class="mb-2">
              <app-button [buttonType]="'button'" class="ms-3" [button_2]="true" [buttonClass]="'button button-primary px-4 font-11'" (click)="activeModal.close()" [buttonLable]="enumButtonName.OKAY"></app-button>
            </div>
          </div>
        </div>
        <!-- SaveChanges PopUp end  -->
            <!--Delete templateId -->
            <div *ngSwitchCase="enumPopUpTitles.DELETE_TEMPLATEID">
              <div class="modal-header p-4 pb-0 border-0 justify-content-center">
                <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
              </div>
              <div class="modal-body p-4 pt-3">
                <div class="text-center">
                  <p class="m-0 text-light-gray">
                    You are about to delete a mapping template. <br>
                    Are you sure you want to delete <span class="font-14  text-break text-primary-color text-truncate">{{templateName}}</span><br>
                    from the list? This cannot be undone.
                    </p>
                </div>
              </div>
              <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
                <div class="mb-2">
                  <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info font-11'" class="me-3" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CANCEL"></app-button>
                  <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-primary font-11'" class="ms-3" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.DELETE"(click)="activeModal.close('Close click')"></app-button>
                 </div>
              </div>
            </div>
            <!-- Delete templateId-->
            <!-- *edit quicke notes* -->
            <div *ngSwitchCase="enumPopUpTitles.EDIT_QUICKNOTES">
              <div class="modal-header p-4 pb-0 border-0 justify-content-start">
                <h6 class="modal-title mt-3 text-center text-primary-color font-15">{{title}}</h6>
              </div>
              <div class="modal-body p-4 pt-3">
                <textarea class="form-control font-12 text-light-gray"  rows="6"  [ngModel]="quickNotes.notes" (ngModelChange) ='quickNotes.notes =$event'></textarea>
              </div>
              <div class="modal-footer p-4 pt-2 border-0 justify-content-end">
                <div class="mb-2">
                  <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info font-11'" class="me-3" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CANCEL"></app-button>
                  <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-primary font-11'" class="ms-3" (click)="notesUpdate()" [buttonLable]="enumButtonName.SAVE"></app-button>
                 </div>
              </div>
            </div>
            <!-- *edit quicke notes* -->
  <!-- archived companies confirmation popup start-->
   <div *ngSwitchCase="enumPopUpTitles.ARCHIVED_COMPANIES">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center  text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4">
        <div class="text-center">
          <p class="m-0 text-light-gray font-12">
            You are about to archive a company. <br>
           Are you sure you want to archive <br>
           <span class=" text-break text-primary-color">{{fullName}}</span>?
          </p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
         <div class="mb-2">
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info font-11'" class="me-3" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CANCEL"></app-button>
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-primary font-11'" class="ms-3" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.CONFIRM"></app-button>
         </div>
      </div>
    </div>

    <div *ngSwitchCase="enumPopUpTitles.UNARCHIVED_COMPANIES">
      <div class="modal-header p-4 pb-0 border-0 justify-content-center">
        <h6 class="modal-title mt-3 text-center  text-primary-color font-15">{{title}}</h6>
      </div>
      <div class="modal-body p-4">
        <div class="text-center">
          <p class="m-0 text-light-gray font-12">
            You are about to unarchive a company. <br>
           Are you sure you want to unarchive <br>
           <span class="text-break text-primary-color">{{fullName}}</span>?
          </p>
        </div>
      </div>
      <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
         <div class="mb-2">
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info font-11'" class="me-3" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CANCEL"></app-button>
          <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-primary font-11'" class="ms-3" (click)="activeModal.close({title,confirm:true})" [buttonLable]="enumButtonName.CONFIRM"></app-button>
         </div>
      </div>
    </div>

   <!--archived companies confirmation popup end-->
  
  <!--unable archived companies confirmation popup start-->
   <div *ngSwitchCase="enumPopUpTitles.UNABLE_ARCHIVED_COMPANIES">
    <div class="modal-header p-4 pb-0 border-0 justify-content-center">
       <h6 class="modal-title mt-3 text-center  text-primary-color font-15">{{title}}</h6>
    </div>
    <div class="modal-body p-4">
      <div class="text-center">
        <p class="m-0 text-light-gray font-12">
        Company cannot be archived, <br>
        Company has open sales orders
        </p>
      </div>
    </div>
    <div class="modal-footer p-4 pt-2 border-0 justify-content-center">
      <div class="mb-2">
       <app-button [buttonType]="'button'"  [button_2]="true" [buttonClass]="'button button-info font-11'" class="me-3" (click)="activeModal.close({title,confirm:false})" [buttonLable]="enumButtonName.CANCEL"></app-button>
      </div>
    </div>
  </div>
  <!--unable archived companies confirmation popup end-->

   
    
  </ng-container>
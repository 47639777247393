import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  companyNameList: any[] = []; // Assuming you have the company list
  mpnNameList: any[] = []; 
  formatter = (result: string) => result.toUpperCase();

  companySearch: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map((term) => {
      if (term === '') {
        return [];
      }

      const filteredCompanies = this.companyNameList
        .filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
        .map((company) => company.name)
        .slice(0, 10);

      if (filteredCompanies.length === 0) {
        // If no records found, add the "No Records Found" option
        return ['No Records Found'];
      }

      return filteredCompanies;
    }),
  );


    mpnSearch: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === ''
          ? []
          : this.mpnNameList
              .filter((v) => v.mpn.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .map((x) => x.mpn)
              .slice(0, 10),
      ),
    );

}

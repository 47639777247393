<div *ngIf="!time" >
  <div class="form-floating d-flex datepicker_contain" id="datepicker_contain" (keydown.tab)="onTabs($event)" >
    <input
      class="form-control"
      id="floatingInput"
      autocomplete="off"
      [(ngModel)]="inputValue"
      (ngModelChange)="inputValueEmit.emit(inputValue)"
      placeholder="name@example.com"
      ngbDatepicker
      #d="ngbDatepicker"
      readonly="true" 
      [required]="inputReqValidation"
      [dayTemplate]="dayTemplate"
      [minDate]="minDate"
      (keydown.enter)="d.open()"
  
      (keydown.tab)="onTab($event,d)"
    />
  
    <ng-template #dayTemplate let-date let-focused="focused">
      <span 
          class="custom-day" 
          [class.focused]="focused" 
          [class.range]="isRange(date)"
          [class.start-date]="isStartDate(date)" 
          [class.end-date]="isEndDate(date)"
          [class.faded]="isHovered(date) || isInside(date)" 
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null">
          {{ date.day }}
      </span>
  </ng-template>
  
    <label for="floatingInput" class="label-transition d-flex align-items-center"
      >{{ inputLabel
      }}<i *ngIf="inputReqValidation" class="fst-normal ps-1 p-0 text-danger"
        >*</i
      ></label
    >
    <span
      [ngClass]="{ invisible: !inputValue }"
      (click)="clearInput(); inputValueEmit.emit(inputValue)"
      class="position-absolute top-0 start-0 hand"
      ><app-svg-icon
        class="svgIcon"
        [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
      ></app-svg-icon
    ></span>
    <img (click)="d.toggle()" class="hand" style="position: absolute; right: 5%;top: 1.3em;" src="../../../../assets/SVGIcons/dateIcon.svg" alt="">
  </div>
</div>

<div *ngIf="time" >
  <div class="form-floating d-flex">
    <input
      class="form-control"
      id="floatingInput"
      autocomplete="off"
      style="padding-right: 1em;"
      type="time"
      [ngClass]="{noBg:!selectedTime}"
      [(ngModel)]="selectedTime"
      (ngModelChange)="inputValueEmit.emit(inputValue)"
      placeholder=""
      (focus)="onTimeFocus()" (blur)="onTimeBlur()"
    />
  
    <label for="floatingInput" class="label-transition d-flex align-items-center"
      >{{ inputLabel
      }}<i *ngIf="inputReqValidation" class="fst-normal ps-1 p-0 text-danger"
        >*</i
      ></label
    >
    <span
      [ngClass]="{ invisible: !inputValue }"
      (click)="clearInput(); inputValueEmit.emit(inputValue)"
      class="position-absolute top-0 start-0 hand"
      ><app-svg-icon
        class="svgIcon"
        [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
      ></app-svg-icon
    ></span>
  </div>
</div>

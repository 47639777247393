import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivityManagerService } from './../../activity-manager.service';
import { NgbActiveModal, NgbDate, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { AddActivitiesMultipleCompanymodel, AddActivitiesMultipleContactmodel, InsertUpdateToDoActivity } from '../../activit-manager.model';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ActivityTabService } from '../../../companies/pages/company-info-tabs/activity-tab/activity-tab.service';
import { CommonDropdownModel } from '../../../companies/pop-ups/add-company/add-company.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AddActivitTitle } from 'src/app/shared/model/common.model';
import { TabsService } from 'src/app/shared/components/tabs/tabs.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Button, CurrentTab, EnfoRoutePath, PopUpTitles, RoutePath } from 'src/app/shared/model/enum';
import { StingToDateService } from 'src/app/shared/services/stringToDate.service';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit {
  @Input() titleName: string = 'Add';
  @Input() disabledCompnay :boolean = false;
  @Input() disabledContact:boolean = false;
  @Input() addActivityTitle:string ='';
  @Input() selectedCompanyID:string;
  insertUpdateToDoActivity = new InsertUpdateToDoActivity();
  addActivitiesMultipleCompanymodel =  new AddActivitiesMultipleCompanymodel();
  addActivitiesMultipleContactmodel = new AddActivitiesMultipleContactmodel()
  enumAddActivitTitle = AddActivitTitle;
  @Input() ngbTimePickerValue: NgbTimeStruct;
  tabName = CurrentTab;
  enumButtonName = Button;
  enumPopUpTitles        = PopUpTitles;
  routePath = RoutePath;
  enfoRouth  = EnfoRoutePath;

  constructor(public activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    public toaster: ToastrService,
    public commonTabService: CommonTabService, 
    public activityService: ActivityManagerService,
    private storageService: StorageService,
    public activityTabService : ActivityTabService,
    private router: Router,
    public tabsService: TabsService,
    private elementRef: ElementRef,
    public stringToDateService: StingToDateService,
  ) {
      this.routeUrl = this.router.url;
      this.tabsService.currentTab.subscribe(res => {
        if (res !== undefined) {
          this.currentTab = res;
        } 
      })
     }
    submit: boolean=false;
  activityPopupTypesList = [];
  activityTypeList = [];
  priorityList = [];
  activityAssigneeList = [];
  companyNameList = [];
  contactNameList = [];
  contactList           : CommonDropdownModel[] = [];
  typeId = ''
  assignedto = ''
  titleActivity = ''
  company = ''
  contact = ''
  priority  = ''
  description  = ''
  dueDate  :any
  dueTime   =''
  isEdit = false
  currentTab= '';
  routeUrl:string;

  ngOnInit(): void 
  {

    this.addActivityTitle
    this.disabledCompnay
    this.disabledContact
    
    this.activityPopupTypesList = this.commonTabService?.getAllDropdownList?.getValue()?.activityPopupTypesList;
    this.activityTypeList = this.commonTabService?.getAllDropdownList?.getValue()?.activityTypesList;
    this.priorityList = this.commonTabService?.getAllDropdownList?.getValue()?.activityPriorityList;
    this.activityAssigneeList = this.commonTabService?.getAllDropdownList?.getValue()?.activityAssigneeList;
    if(this.routeUrl ==  this.routePath.VENDORLIST_ROUTEPATH || this.routeUrl.includes(this.enfoRouth.PROCUREMENT_VENDOR_INFO)){
      this.companyNameList = this.commonTabService?.getAllDropdownList?.getValue()?.vendorNameList;
    }else if(this.routeUrl == '/crm/activityManager'){
      this.companyNameList = this.commonTabService?.getAllDropdownList?.getValue()?.allNameList;
    } else {
      this.companyNameList = this.commonTabService?.getAllDropdownList?.getValue()?.companyNameList;
    }
    this.contactList = this.  commonTabService?.getAllDropdownList?.getValue()?.contactNameList;
    if (this.dueDate) {
      //this.dueDate = this.fromModel(this.dueDate);
      this.dueDate = this.stringToDateService.convertStringToDate(this.dueDate)

      
    } else {
      //this.dueDate = this.fromModel(this.insertUpdateToDoActivity.dueDate)
      this.dueDate = this.stringToDateService.convertStringToDate(this.insertUpdateToDoActivity.dueDate)
      
    }
    this.selectValueChange(this.insertUpdateToDoActivity?.companyId??'') 
   // this.insertUpdateToDoActivity.dueTime = this.insertUpdateToDoActivity.dueTime.replace(' PM', '');
    this.tabsService.currentTab.subscribe(res => {
      if (res !== undefined) {
        this.currentTab = res;
        if(this.addActivitiesMultipleContactmodel?.contactId?.length !== 0 || this.addActivitiesMultipleCompanymodel?.companyId?.length !== 0){
          this.dropdownShow();
        }
        
      } 
    })

  }
  dropdownShow(){
    if(this.currentTab == this.tabName.VENDORCONTACT_TAB || this.currentTab == this.tabName.CONTACT_TAB || this.currentTab == this.insertUpdateToDoActivity.companyId){
      const list = this.addActivitiesMultipleContactmodel.contactId;
      if(list?.length == 1){
        this.insertUpdateToDoActivity.contactId = list[0];
      }else{
        if(list?.length > 1){
        this.contactList = [];
        const value: CommonDropdownModel = {id: "11",name: "Multiple Contacts",colorCode: null,isActive: false,labelName: 'Contact',selectedTags: false};
        this.contactList = [value];
        this.insertUpdateToDoActivity.contactId = value.id;
        }
      }
      }else{
       const list = this.addActivitiesMultipleCompanymodel.companyId;
            if(list?.length == 1){
              this.insertUpdateToDoActivity.companyId = list[0];
              this.selectValueChange(this.insertUpdateToDoActivity.companyId)
            }else{
              if(list?.length > 1){
              this.companyNameList = [];
              const value ={id:"11",name:"Multiple Companies"}
              this.companyNameList.push(value);
              this.insertUpdateToDoActivity.companyId = value.id;
              }

            }
       
      }
  
  }

  fromModel(value: string): NgbDateStruct {
    if (!value)
      return null
    let parts = value.split('/');
    return { day: +parts[0], month: +parts[1], year: +parts[2], }
  }

  dateConversion(date) {
    // const dateObj = date.year.toString().slice(2,4);
    return (date.day < 10 ? '0' : '') + date.day + '/' + (date.month < 10 ? '0' : '') + date.month + '/' + date.year;
  }


addTask(){
  if( this.addActivityTitle == 'crm/companies' || this.addActivityTitle == 'procurement/vendor-list' || this.addActivityTitle == 'crm/prospects' || this.addActivityTitle == 'crm/leads' || this.addActivityTitle == 'crm/customers'){
    this.addActivitiesMultipleCompanymodel.isMarkComplete = this.insertUpdateToDoActivity.isMarkComplete ? true : false
    this.addTaskwithMultipleComapy()
  }else if(this.addActivityTitle == 'procurement/vendor-contact' || this.addActivityTitle == 'crm/contacts'){
    this.addActivitiesMultipleContactmodel.isMarkComplete = this.insertUpdateToDoActivity.isMarkComplete ? true : false
    this.addMultipleContactTask()
  }else{
    if(this.insertUpdateToDoActivity.id){
      this.editToDoActivity();
    }else{
      this.addToDoActivity();
    }
    
  }
}

  addToDoActivity(){
    if(this.insertUpdateToDoActivity.typeId && this.insertUpdateToDoActivity.assignedToUserId && this.insertUpdateToDoActivity.title && this.insertUpdateToDoActivity.description && this.dueDate){
      if(this.insertUpdateToDoActivity.contactId){
        if (this.insertUpdateToDoActivity.companyId) {
          this.inserActivity()
        }else {
          this.submit = true;
          this.toaster.error('Please Select The Company.');
        }
      }
      else{
      this.inserActivity()
      }
    }else{
      this.submit =true;
      this.toaster.error("Please add required fields.")
    }
   
  }
inserActivity(){
  this.insertUpdateToDoActivity.dueDate = this.stringToDateService.dateConvertedToString(this.dueDate)
 // this.inserUpdateToDoTask.createdBy = this.storageService.retrieve('userId')
  this.activityService.insertToDoActivity(this.insertUpdateToDoActivity).subscribe(res => {
    if (res?.isSuccess == true) {
      this.toaster.success(res.message);
      this.activeModal.close(res?.isSuccess);
      this.activityService.addActivity.next(true);
    } else {
      this.toaster.error(res.message)
    }
  })

}
  editToDoActivity(){
    if(this.insertUpdateToDoActivity.typeId && this.insertUpdateToDoActivity.assignedToUserId && this.insertUpdateToDoActivity.title && this.insertUpdateToDoActivity.description && this.dueDate){
     if(this.insertUpdateToDoActivity.contactId){
      if (this.insertUpdateToDoActivity.companyId) {
        this.updateActivity()
      }else {
        this.submit = true;
        this.toaster.error('Please Select The Company.');
      }
    }
    else{
    this.updateActivity()
    }
    }else{
      this.submit =true;
      this.toaster.error("Please add required fields.")
    }
  }
updateActivity(){
  if(this.insertUpdateToDoActivity.dueTime){
    var timeValue = this.insertUpdateToDoActivity.dueTime;
    if( (typeof timeValue === 'string' && (timeValue.includes('am') || timeValue.includes('pm') || timeValue.includes('AM') || timeValue.includes('PM')))){
     // this.changeTimeExpiration( this.insertUpdateToDoActivity.dueTime);
     this.insertUpdateToDoActivity.dueTime =  this.stringToDateService.changeTimeExpiration(this.insertUpdateToDoActivity.dueTime);
    }
  }
  this.insertUpdateToDoActivity.dueDate = this.stringToDateService.dateConvertedToString(this.dueDate)
  // this.inserUpdateToDoTask.createdBy = this.storageService.retrieve('userId')
  this.activityService.updateToDoActivity(this.insertUpdateToDoActivity).subscribe(res => {
    if (res?.isSuccess == true) {
      this.toaster.success(res.message);
      this.activeModal.close(res?.isSuccess);
      this.activityService.addActivity.next(true);
    } else {
      this.toaster.error(res.message)
    }
  })

}
  addTaskwithMultipleComapy(){
    if(this.insertUpdateToDoActivity.typeId && this.insertUpdateToDoActivity.assignedToUserId && this.insertUpdateToDoActivity.title && this.insertUpdateToDoActivity.description && this.dueDate){
    this.addActivitiesMultipleCompanymodel.title            = this.insertUpdateToDoActivity.title
    this.addActivitiesMultipleCompanymodel.assignedToUserId = this.insertUpdateToDoActivity.assignedToUserId;
    this.addActivitiesMultipleCompanymodel.typeId           = this.insertUpdateToDoActivity.typeId;
    this.addActivitiesMultipleCompanymodel.dueDate          = this.stringToDateService.dateConvertedToString(this.dueDate);
    // this.addTaskMultipleCompanymodel.createdBy        = this.storageService.retrieve('userId');
    this.addActivitiesMultipleCompanymodel.description      = this.insertUpdateToDoActivity.description;
    this.addActivitiesMultipleCompanymodel.priorityId       = this.insertUpdateToDoActivity.priorityId;
    this.addActivitiesMultipleCompanymodel.dueTime          = this.insertUpdateToDoActivity.dueTime;
    this.activityService.insertActivityForMultipleCompanies(this.addActivitiesMultipleCompanymodel).subscribe((res) => {
      if(res?.isSuccess == true){
        this.toaster.success(res.message);
        this.activeModal.close(res?.isSuccess)
      }else{
        this.toaster.error(res.message)
      }
    })
  }else{
    this.submit =true;
    this.toaster.error('Input filed required')
  }
  }

  addMultipleContactTask(){
    if(this.insertUpdateToDoActivity.typeId && this.insertUpdateToDoActivity.assignedToUserId && this.insertUpdateToDoActivity.title && this.insertUpdateToDoActivity.description && this.dueDate){
    // this.addTaskMultipleContactmodel.createdBy= this.storageService.retrieve('userId');
    this.addActivitiesMultipleContactmodel.dueDate = this.stringToDateService.dateConvertedToString(this.dueDate);
    this.addActivitiesMultipleContactmodel.dueTime =this.insertUpdateToDoActivity.dueTime;
    this.addActivitiesMultipleContactmodel.assignedToUserId = this.insertUpdateToDoActivity.assignedToUserId;
    this.addActivitiesMultipleContactmodel.priorityId =  this.insertUpdateToDoActivity.priorityId;
    this.addActivitiesMultipleContactmodel.typeId = this.insertUpdateToDoActivity.typeId;
    this.addActivitiesMultipleContactmodel.title = this.insertUpdateToDoActivity.title;
    this.addActivitiesMultipleContactmodel.description = this.insertUpdateToDoActivity.description;
    this.addActivitiesMultipleContactmodel.companyId=this.insertUpdateToDoActivity.companyId;
    this.activityService.insertActivityForMultipleContacts(this.addActivitiesMultipleContactmodel).subscribe((res) =>{
      if(res?.isSuccess == true){
        this.toaster.success(res.message);
        this.activeModal.close(res?.isSuccess)
      }else{
        this.toaster.error(res.message)
      }
    })
  }else{
    this.submit =true;
    this.toaster.error('Input filed required')
  }
  }

  selectValueChange(event) {
      if(event===''){
        this.contactList = this.  commonTabService?.getAllDropdownList?.getValue()?.contactNameList;
      }else{
        this.contactList = [];
        this.activityTabService.getContactList(event).subscribe((res) => {
          if (res.isSuccess) {
            this.contactList = res?.response?.contactList;
            this. contactInfoList();
          }
        })
    }
  }
  contactInfoList(){
    if(this.currentTab == this.insertUpdateToDoActivity.companyId){
      const list = this.addActivitiesMultipleContactmodel.contactId;
      if(list.length > 1){
        this.contactList = [];
        const value: CommonDropdownModel = {id: "111",name: "Multiple Contacts",colorCode: null,isActive: false,labelName: 'Contact',selectedTags: false};
        this.contactList = [value];
        this.insertUpdateToDoActivity.contactId = value.id;

      }
    }

  }
  selectContactValueChange(event) {
    if(event===''){
      if(this.routeUrl == this.routePath.VENDORLIST_ROUTEPATH || this.routeUrl.includes('procurement/vendor-info/')){
        this.companyNameList = this.commonTabService?.getAllDropdownList?.getValue()?.vendorNameList;
      } else {
        this.companyNameList = this.commonTabService?.getAllDropdownList?.getValue()?.companyNameList;
      }
    }else{
      this.companyNameList = [];
      this.activityTabService.getCompanyList(event).subscribe((res) => {
        if (res.isSuccess) {
          this.companyNameList = res?.response?.companiesList;
         // this.insertUpdateToDoActivity.companyId =  this.companyNameList[0].id;
        }
      })
  }
}
marks(isMarkComplete: boolean){
  this.insertUpdateToDoActivity.isMarkComplete = isMarkComplete;
  if(isMarkComplete){
    this.addStatusId()
  }
  this.addTask();

}
addStatusId(){
  const activityStatusList = this.commonTabService?.getAllDropdownList?.getValue()?.activityStatusList;
  const closedStatus = activityStatusList.find(status => status.name === 'Closed');
  if (closedStatus) {
    this.insertUpdateToDoActivity.statusId = closedStatus.id;
  }}
  onTimeChange(time: NgbTimeStruct) {

  }
}

<!--<p *ngIf="!isVisible" class="companyp mb-0">{{companyName}} </p> -->
<div *ngIf="params?.data?.isExpandable" class="container-fluid mt-5 leftPosition w-100">
    <div class="row my-auto mx-auto w-100">
      <div class="first-col d-xl-none d-md-block">
        <div class="row mt-2 mb-2">
          <div class="col-12 mr-30">
            <div class="row">
              <p for="" class="text-light-gray font-10 mb-0">Parent Company:
              </p>
            </div>
            <div class="row ">
              <p class="text-primary-color mb-2 font-11 text-truncate">
                {{params?.data?.parentCompany?params?.data?.parentCompany:"-"}}
              </p>
            </div>
            <div class="row">
              <p for="" class="text-light-gray font-10 mb-0">Location:</p>
            </div>
            <div class="row ">
              <p title="{{ params?.data?.location }}" class="text-primary-color mb-2 font-11 text-truncate">
                {{ params?.data?.location?params?.data?.location:"-" }}
                <!-- Florida, USA -->
              </p>
            </div>
            <div class="row">
              <p for="" class="text-light-gray font-10 mb-0">Timezone: </p>
            </div>
            <div class="row text-truncate">
              <div class="col-11">
                <p class="text-primary-color text-break text wrap mb-2 font-11 text-truncate">{{ params?.data?.timeZoneName?params?.data?.timeZoneName:"-" }}</p>
              </div>
            </div>
          </div>
        </div>
  
      </div>
        <div class="sec-col " [ngClass]="{'paddingLeft':currentTab == 'companies'}">
        <div class="row m-0">
          <div class="col-xl-6 col-lg-6 col-md-6 mt-2">
            <div class="row">
              <p for="" class="text-light-gray font-10 mb-0">Account Number:
              </p>
            </div>
            <div class="row ">
              <p class="text-primary-color mb-2 font-11 text-truncate">
                {{ params?.data?.accountNumber?params?.data?.accountNumber:"-"}}
              </p>
            </div>
            <div class="row">
              <p for="" class="text-light-gray font-10 mb-0">Accounting Contact:
              </p>
            </div>
            <div class="row">
              <p title="{{ params?.data?.accountContactEmail}}" class="text-primary-color mb-2 font-11 text-truncate">
                {{ params?.data?.accountContactEmail?params?.data?.accountContactEmail:"-"}}
              </p>
            </div>
            <div class="row ">
              <p class="text-primary-color mb-2 font-11 text-truncate">
                {{ params?.data?.accountingContact?params?.data?.accountingContact:"-" }}
              </p>
            </div>
          </div>
  
          <div class="col-xl-6 col-lg-6 col-md-6 mt-2">
            <p for="" class="text-light-gray font-10 mb-0">Main Contact:
            </p>
            <p class="text-primary-color mb-1 font-11 text-truncate">
              {{ params?.data?.mainContact?params?.data?.mainContact:"-"}}
            </p>
            <p class="text-primary-color mb-1 font-11 text-truncate">
              {{ params?.data?.primaryContactEmail?params?.data?.primaryContactEmail:"-"}}
            </p>
            <p class="text-primary-color mb-2 font-11 text-truncate">
              {{ params?.data?.primaryContactPhone?params?.data?.primaryContactPhone:"-"}}
            </p>
          </div>
        </div>
      </div>
      <div class="thrid-col NAICS-pos ">
        <div class="row mt-2 mb-1">
          <div class="col-xl-4 col-lg-4 col-md-4 ">
            <p for="" class="text-light-gray font-10 mb-0">Entity Type:
            </p>
            <p class="text-primary-color mb-2 font-11 text-truncate">
              {{  params?.data?.entityType?params?.data?.entityType:"-" }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 ">
            <p for="" class="text-light-gray font-10 mb-0">Type:</p>
            <p class="text-primary-color mb-2 font-11 text-truncate">{{ params?.data?.typeName?params?.data?.typeName:"-" }}</p>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 ">
            <p for="" class="text-light-gray font-10 mb-0">Scope:</p>
            <p class="text-primary-color mb-2 font-11 text-truncate">{{ params?.data?.scope?params?.data?.scope:"-" }}</p>
          </div>
          <!-- <div class="col-xl-3 col-lg-4 col-md-3 ">
            <p for="" class="text-light-gray font-10 mb-0">Scope:
            </p>
            <p class="text-gray mb-2 font-11 text-truncate">
              {{  params?.data?.scope?params?.data?.scope:"-" }}
            </p>
          </div> -->

        </div>
  
        <div class="row mb-1">
          <div class="col-4 ">
            <p for="" class="text-light-gray font-10 mb-0">D & B Number:
            </p>
            <p title="{{ params?.data?.dandBNumber }}" class="text-primary-color mb-2 font-11 text-truncate">
              {{ params?.data?.dandBNumber?params?.data?.dandBNumber:"-" }}
            </p>
          </div>
  
          <div class="col-4 ">
            <p for="" class="text-light-gray font-10 mb-0">SIC Code:
              <app-svg-icon
                (mouseenter)="toggleHoverSic(true)"
                (mouseleave)="toggleHoverSic(false)"
                [src]="'./assets/SVGIcons/I_button.svg'"    
                class="I-icon hand">
              </app-svg-icon>
            </p>
            <p class="text-primary-color mb-2 font-11 text-truncate">{{ params?.data?.sicCode?params?.data?.sicCode:"-" }}</p>
          </div>
          <div class="col-3  NAICS-pos">
            <p for="" class="text-light-gray font-10 mb-0">NAICS code:
              <app-svg-icon 
                (mouseenter)="toggleHover(true)"
                (mouseleave)="toggleHover(false)"
                [src]="'./assets/SVGIcons/I_button.svg'"    
                class="I-icon hand">
              </app-svg-icon>
            </p>
            <p class="text-primary-color mb-2 font-11 text-truncate">{{ params?.data?.naicsCode?params?.data?.naicsCode:"-" }}</p>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-xl-12 ">
            <p for="" class="text-light-gray font-10 mb-0">Industry:</p>
            <p class="text-primary-color mb-2 font-11 text-truncate nowrap">
              {{ params?.data?.industryName?params?.data?.industryName:"-" }}
            </p>
          </div>
        </div>
      </div>
      <div class="sm-cards description" *ngIf="show;">
        <p class="font-10 m-0 text-wrap text-break multi-line text-light-gray">
          {{ params?.data?.naicsDescription?params?.data?.naicsDescription:"-" }}
        </p>
      </div>
      <div class="sm-cards-Sics description" *ngIf="showSics;">
        <p class="font-10 m-0 text-wrap text-break multi-line text-light-gray">
          {{ params?.data?.sicDescription?params?.data?.sicDescription:"-" }}
        </p>
      </div>
      <div class="fourth-col NAICS-pos">
        <div class="row mr-1 mt-2">
          <div class="col-xl-5">
            <div class="row">
              <div class="col-xl-12 ">
                <p for="" class="text-light-gray font-10 mb-0">Revenue:
                </p>
                <p class="text-primary-color text-truncate  mb-2 font-11 ">{{params?.data?.revenue?params?.data?.revenue:""}}</p>
              </div>
              <div class="col-xl-12 ">
                <p for="" class="text-light-gray font-10 mb-0">Currency:
                </p>
                <p class="text-primary-color mb-2 font-11">{{ params?.data?.currencyName !== undefined && params?.data?.currencyName !== '' ? params.data.currencyName : '-' }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-5">
            <div class="col-xl-12 ">
              <p for="" class="text-light-gray font-10 mb-0">Sales 12 months:
              </p>
              <p title="{{ params?.data?.sales12MonthId }}" class="text-primary-color mb-2 font-11">${{
                params?.data?.sales12MonthId?params?.data?.sales12MonthId:"" }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="fifth-col col-xl-1 col-lg-1 col-md-1">
        <p for="" class="text-light-gray font-10 mt-2 mb-0">Quick Note:</p>
        <p class="text-primary-color mb-2 font-11 text-wrap text-break prewrap text-line"
        [ngClass]="{'fst-italic text-light-gray': !params?.data?.notes}"
        >{{params?.data?.notes?params?.data?.notes:"No note added." }}</p>
      </div>
    </div>
  </div>
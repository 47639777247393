
<app-checkbox
*ngIf="params?.data?.type == 'New Record'"
[status]="params.value"
(check)="onSelectChange($event)"
[lableName]="'Import Anyway'"
[lableClass]="'text-light-gray'"
[dyanamicId]="routePath !== routeUrl.CODECROSSWALK_ROUTEPATH ? params?.data?.companyName + params?.data?.id:params?.data?.industry + params?.data?.rowNo"
>
</app-checkbox>
<!-- [dyanamicId]="params?.data?.companyName + params?.data?.id" -->


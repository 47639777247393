<!-- <button id="hidden" #hiddenElement class="position-absolute top-0 end-0 opacity-0"></button>

 <div class="form-floating" [ngClass]="{ fw: disabled == true }">
  <select tabNext
    class="form-select w-100 m-0 text-truncate"
    [disabled]="disabled"
    [ngClass]="{ 'is-valid': isValid }"
    (ngModelChange)="selectValueChange($event)"
    [(ngModel)]="selectValue"
    id="floatingSelect"
    aria-label="Floating label select example"
  >
    <option selected value=""></option>
    <option
      *ngFor="let item of data"
      [value]="item.id"
      [disabled]="item.id == optionDisable"
    >
      {{ item.name }}
    </option>
  </select>
  <label
    for="floatingSelect"
    class="select_label label-transition d-flex align-items-center hand"
    [ngClass]="{ select_value: selectValue }"
    >{{ selectLabel }}
    <span *ngIf="required" class="required-feild ps-1 p-0">*</span></label
  >
  <span
    *ngIf="!disabled"
    [ngClass]="{ invisible: !selectValue }"
    (click)="selectValue = ''; selectValueChange('')"
    class="position-absolute top-0 start-0 hand"
    ><app-svg-icon
      class="svgIcon"
      [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
    ></app-svg-icon
  ></span>
  <div [ngClass]="{ 'is-valid': isValid }">
    <img
      *ngIf="isSvgVisible"
      src="../../../../assets/SVGIcons/lock.svg"
      alt=""
    />
  </div>
</div>  -->
<button tabindex="-1" id="hidden" #hiddenElement class="position-absolute top-0 end-0 opacity-0"></button>

<div class="form-floating" [ngClass]="{ fw: disabled == true }">
  <div
    class="select-wrapper d-flex align-items-end position-relative">
    <div ngbDropdown class="custom-dropdown">
      <button 
      [(ngModel)]="searchText"
      tabindex="-1"
      [disabled]="disabled"
      [ngClass]="{ 'is-valid': isValid }"
      id="floatingSelect"
      aria-label="Floating label select example"
      class="form-select w-100 m-0 text-truncate"  ngbDropdownToggle
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown)="setUp($event)"
      >
      {{ getCurrentSelectionName()}}
      </button>
      <div class="intent custom-dropdown-menu" ngbDropdownMenu>
        <div class="dropdown-item hand text-gray text-truncate f-11" 
          *ngFor="let item of filteredData"
          [ngClass]="{'disabled': item.id === optionDisable}"
          (click)="isSvgVisible || item.id === optionDisable ?'':
          selectValueChange(item?.id);setCurrentSelection(item?.name, item?.id)" >
      {{ item.name }}  
        </div>
      </div>
    </div>
  </div>
  <label
    for="floatingSelect"
    class="select_label label-transition d-flex align-items-center hand"
    [ngClass]="{ select_value: selectValue , 'label-focused': isFocused}"
    >{{ selectLabel }}
    <span *ngIf="required" class="required-feild ps-1 p-0">*</span></label
  >
  <span
    *ngIf="!disabled"
    [ngClass]="{ invisible: !selectValue }"
    (click)="selectValue = ''; selectValueChange('');"
    class="position-absolute top-0 start-0 hand"
    ><app-svg-icon
      class="svgIcon"
      [src]="'../../../../assets/SVGIcons/undoSVG.svg'"
    ></app-svg-icon
  ></span>
  <div  *ngIf="isSvgVisible"
    class="imgValid"
    [ngClass]="{ 'is-valid': isValid }">
    <img
      src="../../../../assets/SVGIcons/lock.svg"
      alt=""
    />
  </div>
</div>


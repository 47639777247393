import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Path, RoutePath } from 'src/app/shared/model/enum';
import { Menu } from 'src/app/shared/services/nav.service';
import { TabsService } from '../../tabs/tabs.service';
import { Router } from '@angular/router';
import { CompaniesService } from 'src/app/components/crm/companies/pages/companies/companies.service';
import { SignalRService } from 'src/app/shared/services/signal-r.service';

@Component({
  selector: 'app-company-renderer',
  templateUrl: './company-renderer.component.html',
  styleUrls: ['./company-renderer.component.scss']
})
export class CompanyRendererComponent implements OnInit, ICellRendererAngularComp {
  dataToShow = ''
  params: any
  enumPath = Path;
  routePath = RoutePath
  constructor(private tabsService: TabsService, private router: Router, 
    public companiesService: CompaniesService,
    public signalRService: SignalRService,
  ) { }
  agInit(params: ICellRendererParams<any, any>): void {
    this.dataToShow = params.value
    this.params = params
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false
  }

  ngOnInit(): void {
  }



  changeRoute(params) {
    if(this.router.url == this.routePath.PARTLIST_ROUTEPATH){
      this.partListRoute(params)
    }else{
    let url
    this.companiesService.id=params.data.id;
    let id = params.value
     if(params.data.saleStageId == '4'){
     url = `procurement/vendor-info/${this.companiesService.id}`
    }else{
       url = `crm/companies/company-info/${this.companiesService.id}`
    }
    const item: Menu = { path: url, icon: '', title: params.value, type: 'link',id:params.data.id}
    this.tabsService.addTab(item);

    this.router.navigateByUrl(url);
    this.companiesService.activeTab.next(1);
  }
  }  

  partListRoute(params){
    let url
    this.companiesService.id=params.data.id;
    let id = params.value
       url = `parts-and-inventory/parts-list/list-info/${this.companiesService.id}`
    const item: Menu = { path: url, icon: '', title: params.value, type: 'link',id:params.data.id}
    this.tabsService.addTab(item);
    this.router.navigateByUrl(url);

  }
  ngOnDestroy() {
   // this.signalRService.smxStopConnection();
    }

}
